import React from "react";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { Box } from "@material-ui/core";
import { useStyles } from "../styles";

interface Props {
	styleGuidesData: any;
	defaultDesignslist: any;
	setDefaultDesignslist: any;
	selectedDesigns: any;
	allDs: any;
	setAllDs: any;
}
const TeamStyleGuides: React.FC<Props> = ({
	styleGuidesData,
	defaultDesignslist,
	setDefaultDesignslist,
	selectedDesigns,
	allDs,
	setAllDs
}) => {
  const classes = useStyles(); 
  // eslint-disable-next-line
  const [defaultStylelist, setdefaultStylelist] = React.useState<any>([]);
  //const [isCheckAll, setIsCheckAll] = React.useState(false);
  //const URL = window.location.pathname.split('/').pop();

  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "auto",
      },
    },
  };

   const makeJoin = (selected:[]) => {
	  
	  var arrTemp:string[] = []
	  var i=0;
	  selected.forEach((Item:any, index:any) => {
		  styleGuidesData.forEach((InD:any, index1:any) => {
			if(Item ===InD.id){
				if(InD.styleguide_name!==''){
					arrTemp[i]= InD.styleguide_name;
					i++;
				}
			}
		  }); 
		 
	  });
	 
	  return arrTemp.join(',');
  };
  
  
  const handleChange = (event:any) => {
	//setIsCheckAll(false);
    setDefaultDesignslist(typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value);
  };
  
 const handleDsChange = (event:any) => {
	setAllDs(event.target.checked);
  };
  
  const checkboxCheck = () => {
	  if(!styleGuidesData.length)
		  return false;
	  return allDs;
  };
  const selectCheck = () => {
	  if(!styleGuidesData.length)
		  return true;
	  return allDs;
  };
    
  return (
  <Box>
  <FormControlLabel classes={{label:classes.checkboxLabel}} control={<Checkbox checked={checkboxCheck()}  color="primary"  onChange={handleDsChange}/>} label="Automatically add Design Systems" className={classes.captioncheckbox}/>
    <Box className={classes.multipleCheckBox} >
      <FormControl className={classes.teamFormControl} variant="outlined" size="medium">
        <InputLabel id="demo-multiple-checkbox-label">Design Systems</InputLabel>
        <Select
          className={classes.teamFormSelect} 
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={defaultDesignslist}
          onChange={handleChange}
          label={"Design Systems"}
          renderValue={(selected: any) => makeJoin(selected)}
          MenuProps={MenuProps}
		  disabled={selectCheck()}
        >
			
			
          {styleGuidesData.map((Item : any,index:any) => {
              return (
              <MenuItem key={Item.id} value={Item.id}>
                <Checkbox checked={defaultDesignslist.indexOf(Item.id) > -1} color="primary"/>
                <ListItemText primary={Item.styleguide_name} />
              </MenuItem>
              )
          })}
        </Select>
      </FormControl>      
    </Box>
	</Box>
  );
};

export default TeamStyleGuides;
