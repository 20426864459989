import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Box, Paper, Button } from '@material-ui/core';
import MainColors from "./components/MainColors";
import Buttons from "./components/Buttons";
import { useStyles } from "./styles";
import {isAllowMenu, redirectTo} from "../../../../utils/AuthUtility";
import Logo from "./components/Logo";
import Stylename from "./components/Stylename";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import SocialLinks from "./components/SocialLinks";
import StylePrototype from "./components/StylePrototype";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { addPrefilData, addField } from '../../../redux/actions/styleGuideActions';
import { convertToReduxState } from "./utilities/convertApiDataToReduxState";
import TypographyStyle from './components/Typography';
import AxiosInstance from "../../../../utils/AxiosInstance";
import {setFlashMessage} from "../../../../utils/AuthUtility";
import {useState} from 'react';
import Details from './components/Details';
import { DefaultStyleGuide } from './utilities/DefaultStyleGuide';
import MainColorsPallet from './components/MainColorsPallet';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import UpdateStyleGuideConfirm from "./UpdateStyleGuideConfirm";
import CloseStyleGuideConfirm from "./CloseStyleGuideConfirm";
import ErrorConfirm from "./ErrorConfirm";
import FontFamily from "./components/FontFamily";
import {CodemirrorExample} from "./components/codemirror_js";
import ContactDetails from "./components/ContactDetails";
import Layout from "./components/Layout";
import DarkBrandColors from "./components/DarkBrandColors";
import {SaveStyleguide}  from "./SaveStyleGuide";
import {AxiosResponse} from "axios";
import * as yup from "yup";
interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const {children, value, index, ...other} = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{/* {value === index && ( */}
			<Box width="100%">
				<div>{children}</div>
			</Box>
			{/* )} */}
		</div>
	);
}



function a11yProps(index: any) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	};
}
interface Props {
  handleNext?: any;
  save?: any;
  isNotSettingsPage?: boolean;
}

const StyleGuide: React.FC<Props> = ({
  handleNext,
  isNotSettingsPage,
  save,
}) => {
    const dispatch = useDispatch();
    const state = useSelector((state: any) => state.styleGuide);
    const [checkStyle, SetCheckStyle] = useState(false);
    const [activeChild, setActiveChild] = useState(0);
    const [openStyleConfirmDialog, setopenStyleConfirmDialog] = useState<boolean>(false);
    const [assignCampfound, setassignCampfound] = useState<any>(null);
    const [totaldesign, settotaldesign] = useState<any>(null);
    const [CloseConfirmDialog, setCloseConfirmDialog] = useState<boolean>(false);
     const [ErrorConfirmDialog, setErrorConfirmDialog] = useState<boolean>(false);
   const getcompanyFonts = async () => {
      
    try { 
      const request = await AxiosInstance.get(
        `api/company-fonts/${localStorage.getItem("company_id")}`
      );
      dispatch(
        await addPrefilData(
          await convertToReduxState(request.data.styGuide_Detail)
        )
      );
    } catch (err) { }
  };
  const isValidUrl = (str:string)=> {
      var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
      '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
    return !!urlPattern.test(str);
  }
  // CHECK IF IMAGE EXISTS
function checkImage(imageSrc:any, good:any, bad:any) {
    var img = new Image();
    img.onload = good; 
    img.onerror = bad;
    img.src = imageSrc;
}


// USAGE

   /*const checkUrlValidation = (url:any) => {  
    var host = window.location.host;
    host = host.replace("localhost:3000", "localhost");
    var subdomain = host.split('.')[0];
    subdomain = '/' + subdomain + '/';
    if(url && (url.includes(host) || url.includes("data:image") || (url.includes("objectstorage") && url.includes(subdomain))) ){
      return true;
    }
    return false;
  }*/
  function closestyle()
  {
     dispatch(addField("designSystem", "showList"))
  }
  function triggerClose()
  {
     setCloseConfirmDialog(true);
  }
  
  function triggersave() {
      var styleId: any = null;
      if(localStorage.getItem("styleGuideResID")===
    '' || localStorage.getItem("styleGuideResID")===null)
    {
       styleId=localStorage.getItem("rowId")
    }
    else
    {
       styleId = localStorage.getItem("styleGuideResID");
    }
    if(state.styleguideId){
		styleId = state.styleguideId;
	}
    dispatch(addField("styleIdFromres", styleId));
      var flag = false;
      if(state.styleguideName  === '')
      {
          dispatch(addField("styleguidename_error", true));
          flag = true;
      } else {
          dispatch(addField("styleguidename_error", false));
      }
      if(!state?.company_name){
      dispatch(addField("company_name_error", "This field cannot be left blank"));
      flag = true;
    } else dispatch(addField("company_name_error", ""));
    
    if(!state?.company_address){
      dispatch(addField("company_address_error", "This field cannot be left blank"));
      flag = true;
    } else dispatch(addField("company_address_error", ""));
    
     if(!state?.company_website){
      dispatch(addField("company_website_error", "This field cannot be left blank"));
      flag = true;
    }
     if(!isValidUrl(state?.company_website)){
      dispatch(addField("company_website_error", "Please enter a valid website"));
      flag = true;
    } else dispatch(addField("company_website_error", ""));
    
    if(!state?.contact_email){
        dispatch(addField("contact_email_error", "Please enter a valid email"));
       
    }
    let schema = yup.object().shape({
      email: yup.string().email(),
    });
    schema
    .isValid({
      email: state?.contact_email,
    })
    .then((valid) => {
      if(!valid){
        dispatch(addField("contact_email_error", "Please enter a valid email"));
        flag = true;
      } else dispatch(addField("contact_email_error", ""));
    });
    
    if(state.logoUrlInput === '')
    {
        
        dispatch(addField("imageinput", true));
        dispatch(addField("imageinputText", 'This field cannot be left blank'));
        dispatch(addField("setImageStylevalue", 'Error'));
        dispatch(addField("errorInputTextURl", true));
        
        flag = true;
        
    }
  
      
      let socialIconFields:any = {};
      let socialIconFieldsSrc:any = {};
      let menuItemsFields:any =[];
      let menuItemsFieldsLink:any =[];
      dispatch(addField("Socialtitle", socialIconFields));
      dispatch(addField("SocialSrc", socialIconFieldsSrc));
      dispatch(addField("errorSocial", false));
      dispatch(addField("menuItems", menuItemsFields));
      dispatch(addField("menuItemsLink", menuItemsFieldsLink));
      dispatch(addField("menuItemsError", false));
      
      var fontFlag = false;
      let styleItemsFields:any =[];
      dispatch(addField("StyleItemsEmpty", styleItemsFields));
      dispatch(addField("StyleItemsError", false));

      state?.list_custom_fonts?.forEach((item:any) => {
        // Loop through the styles array within each item
        item?.styles?.forEach((style:any) => {
          if(!style?.source){
            styleItemsFields.push(style.key);  
          }
        });
      });

      if(styleItemsFields.length > 0){
        dispatch(addField("StyleItemsError", true));
        dispatch(addField("StyleItemsEmpty", styleItemsFields));
        fontFlag = true;
      }         
      
      var counter = 0;
      state.menuList.forEach((item: any) => {
      if(item.title === '' || item.link === '')
      {
          if(item.title === '')
          {
            menuItemsFields.push(counter);
          }
          if(item.link === '')
          {
            menuItemsFieldsLink.push(counter);
          }
          
      }
      counter++;
    });
    
    if(menuItemsFields.length>0 || menuItemsFieldsLink.length>0 )
    {
        dispatch(addField("menuItemsError", true));
        dispatch(addField("menuItems", menuItemsFields));
        dispatch(addField("menuItemsLink", menuItemsFieldsLink));
        flag = true;
    }
      
  state?.socialLinkList?.forEach((item: any) => {
        if(state.SetSocialStyleValue === undefined || state.SetSocialStyleValue ==='automatic' )
        {
            if(item.link === '') {
            socialIconFields[item.title] = item.title;
            dispatch(addField("errorSocial", true));
            flag = true;
            }
        } else {
            if(item.link === '' || item.src === '') {
            socialIconFieldsSrc[item.title] = item.title;
                if(item.link === '')
                { 
                    socialIconFields[item.title] = item.title;
                }
            dispatch(addField("errorSocial", true));
            flag = true;
            }

        }    
        });
        dispatch(addField("Socialtitle", socialIconFields));
        dispatch(addField("SocialSrc", socialIconFieldsSrc));

        
        if(fontFlag && flag){
          setActiveChild(0);
          dispatch(addField("errorfonttabcolor", true));
          dispatch(addField("errortabbackgroundColor", true));        
          dispatch(addField("errortabcolor", true));
          dispatch(addField("errortabbackgroundColor", true));
          setErrorConfirmDialog(true);
      } else if(fontFlag){        
        setActiveChild(4);
        dispatch(addField("errorfonttabcolor", true));
        dispatch(addField("errortabbackgroundColor", true));
        setErrorConfirmDialog(true);
      } else if(!flag)
     {
            if(state.logoUrlInput !=='' && state.logoUrlInput!==undefined )
            {
                checkImage(state.logoUrlInput, function(){
                dispatch(addField("imageinput", false));
                checkCampExists(styleId,state.styleguideName);
                }, function(){
                flag = true;
                dispatch(addField("imageinput", true));
                dispatch(addField("imageinputText", 'Please put a valid Image URL'));
                dispatch(addField("errortabcolor", true));
                dispatch(addField("errortabbackgroundColor", true));
                setErrorConfirmDialog(true);

                }
                );

            } else {
                checkCampExists(styleId,state.styleguideName);
            }
         
        setErrorConfirmDialog(false);
        dispatch(addField("errortabcolor", false));
        dispatch(addField("errortabbackgroundColor", false));
     } else {
         setActiveChild(0);
          dispatch(addField("errortabcolor", true));
          dispatch(addField("errortabbackgroundColor", true));
          setErrorConfirmDialog(true);
         
     }
}

  const getSavedData = async (styleGuideResID : any) => {
    //const axios = require("axios");
    try { 
      const request = await AxiosInstance.get(
        `api/company-styleGuide/${localStorage.getItem("company_id")}/${styleGuideResID}`
      );
      dispatch(
        await addPrefilData(
          await convertToReduxState(request.data.styGuide_Detail)
        )
      );
   
      if(state?.googleFontList === undefined || state?.googleFontList.length === 0) {
        const getGoogleFontListFromAPI = await AxiosInstance.get('api/getGoogleFontsList');
        dispatch( await addField("googleFontList", JSON.parse(getGoogleFontListFromAPI.data.googleFontList)));    
      }
      // let json = JSON.stringify(request.data.styGuide_Detail);
      // json = JSON.parse(json);
      // console.log(json," 3333333")
      SetCheckStyle(true);
    } catch (err) {
     }
  };
  
  useEffect(() => {
    if(state["styleGuideDefault"] !== undefined && state["styleGuideDefault"]){
      // handleSubmit(state);
      dispatch(addField("styleGuideDefault", false));
    }
    dispatch(addField("styleguide_name", ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[checkStyle]);
  const handleSubmit = (state: any) => {
    var formdata = new FormData();
    const data = localStorage.getItem("loggedInUser")
    let Email = ""
    if (data) {
      const { user_detail } = JSON.parse(data)
      Email = user_detail.Email
    }
    formdata.append(
      "company_id",
      localStorage.getItem("usr_company_id") ||
      localStorage.getItem("company_id") ||
      ""
    );
    formdata.append("Email", Email || "");
    formdata.append("is_dark", state?.is_dark ? state?.is_dark : DefaultStyleGuide.is_dark );
	formdata.append(
	  "email_color_new",
	  state.email_color ? state.email_color : DefaultStyleGuide.email_color
	);
    formdata.append(
      "primary_color_new",
      state.primary_color ? state.primary_color : DefaultStyleGuide.primary_color
    );
    formdata.append(
      "secondary_color_new",
      state.secondary_color ? state.secondary_color : DefaultStyleGuide.secondary_color
    );
    formdata.append(
      "banner_text_color",
      state.header_text_color ? state.header_text_color : ""
    );
    formdata.append(
      "headline_font",
      state.primary_font ? state.primary_font : DefaultStyleGuide.primary_font
    );
    formdata.append(
      "button_font",
      state.button_font ? state.button_font : DefaultStyleGuide.button_font
    );
    formdata.append(
      "fallback_font",
      state.fallback_font ? JSON.stringify(state.fallback_font) : ""
    );
    formdata.append("website_url", state.website_url ? state.website_url : "");
    formdata.append(
      "headline_color",
      state.hedline_color ? state.hedline_color : DefaultStyleGuide.hedline_color
    );
    formdata.append("text_color", state.text_color ? state.text_color : DefaultStyleGuide.text_color);
    formdata.append("text_font", state.text_font ? state.text_font : DefaultStyleGuide.text_font);
    formdata.append("link_color", state.link_color ? state.link_color :  DefaultStyleGuide.link_color);
    let contact_details = 
    { 
     "company_website" : state.company_website ? state.company_website : "",
     "company_tagline" : state.company_tagline ? state.company_tagline : "",
     "company_address" : state.company_address ? state.company_address : "",
     "company_name" : state.company_name ? state.company_name : "",
     "contact_phone" : state.contact_phone ? state.contact_phone : "",
     "contact_email" : state.contact_email ? state.contact_email : "",
    };
    formdata.append(
      "contact_details", JSON.stringify(contact_details)
    );
    let headline_font_size = 
    { 
     "headline_size_h1" : state.headline_size_h1 ? state.headline_size_h1 : DefaultStyleGuide.headline_size_h1,
     "headline_size_h2" : state.headline_size_h2 ? state.headline_size_h2 : DefaultStyleGuide.headline_size_h2,
     "headline_size_h3" : state.headline_size_h3 ? state.headline_size_h3 : DefaultStyleGuide.headline_size_h3,
     "headline_size_h4" : state.headline_size_h4 ? state.headline_size_h4 : DefaultStyleGuide.headline_size_h4,
     "headline_lineheight_h1" : state.headline_lineheight_h1 ? state.headline_lineheight_h1 : DefaultStyleGuide.headline_lineheight_h1,
     "headline_lineheight_h2" : state.headline_lineheight_h2 ? state.headline_lineheight_h2 : DefaultStyleGuide.headline_lineheight_h2,
     "headline_lineheight_h3" : state.headline_lineheight_h3 ? state.headline_lineheight_h3 : DefaultStyleGuide.headline_lineheight_h3,
     "headline_lineheight_h4" : state.headline_lineheight_h4 ? state.headline_lineheight_h4 : DefaultStyleGuide.headline_lineheight_h4,
     "headline_spaceafter_h1" : state.headline_spaceafter_h1 ? state.headline_spaceafter_h1 : DefaultStyleGuide.headline_spaceafter_h1,
     "headline_spaceafter_h2" : state.headline_spaceafter_h2 ? state.headline_spaceafter_h2 : DefaultStyleGuide.headline_spaceafter_h2,
     "headline_spaceafter_h3" : state.headline_spaceafter_h3 ? state.headline_spaceafter_h3 : DefaultStyleGuide.headline_spaceafter_h3,
     "headline_spaceafter_h4" : state.headline_spaceafter_h4 ? state.headline_spaceafter_h4 : DefaultStyleGuide.headline_spaceafter_h4,
     "headline_name_h1" : state.headline_name_h1 ? state.headline_name_h1 : DefaultStyleGuide.headline_name_h1,
     "headline_name_h2" : state.headline_name_h2 ? state.headline_name_h2 : DefaultStyleGuide.headline_name_h2,
     "headline_name_h3" : state.headline_name_h3 ? state.headline_name_h3 : DefaultStyleGuide.headline_name_h3,
     "headline_name_h4" : state.headline_name_h4 ? state.headline_name_h4 : DefaultStyleGuide.headline_name_h4,
     "headline_spacebefore_h1" : state.headline_spacebefore_h1 ? state.headline_spacebefore_h1 : DefaultStyleGuide.headline_spacebefore_h1,
     "headline_spacebefore_h2" : state.headline_spacebefore_h2 ? state.headline_spacebefore_h2 : DefaultStyleGuide.headline_spacebefore_h2,
     "headline_spacebefore_h3" : state.headline_spacebefore_h3 ? state.headline_spacebefore_h3 : DefaultStyleGuide.headline_spacebefore_h3,
     "headline_spacebefore_h4" : state.headline_spacebefore_h4 ? state.headline_spacebefore_h4 : DefaultStyleGuide.headline_spacebefore_h4,
     "headline_letterspacing_h1" : state.headline_letterspacing_h1 ? state.headline_letterspacing_h1 : DefaultStyleGuide.headline_letterspacing_h1,
     "headline_letterspacing_h2" : state.headline_letterspacing_h2 ? state.headline_letterspacing_h2 : DefaultStyleGuide.headline_letterspacing_h2,
     "headline_letterspacing_h3" : state.headline_letterspacing_h3 ? state.headline_letterspacing_h3 : DefaultStyleGuide.headline_letterspacing_h3,
     "headline_letterspacing_h4" : state.headline_letterspacing_h4 ? state.headline_letterspacing_h4 : DefaultStyleGuide.headline_letterspacing_h4,
     "headline_font_h1" : state.headline_font_h1 ? state.headline_font_h1 : DefaultStyleGuide.headline_font_h1,
     "headline_font_h2" : state.headline_font_h2 ? state.headline_font_h2 : DefaultStyleGuide.headline_font_h2,
     "headline_font_h3" : state.headline_font_h3 ? state.headline_font_h3 : DefaultStyleGuide.headline_font_h3,
     "headline_font_h4" : state.headline_font_h4 ? state.headline_font_h4 : DefaultStyleGuide.headline_font_h4,
     "headline_weight_nb_h1" : state.headline_weight_nb_h1 ? state.headline_weight_nb_h1 : DefaultStyleGuide.headline_weight_nb_h1,
     "headline_weight_nb_h2" : state.headline_weight_nb_h2 ? state.headline_weight_nb_h2 : DefaultStyleGuide.headline_weight_nb_h2,
     "headline_weight_nb_h3" : state.headline_weight_nb_h3 ? state.headline_weight_nb_h3 : DefaultStyleGuide.headline_weight_nb_h3,
     "headline_weight_nb_h4" : state.headline_weight_nb_h4 ? state.headline_weight_nb_h4 : DefaultStyleGuide.headline_weight_nb_h4,
     "headline_weight_h1" : state.headline_weight_h1 ? state.headline_weight_h1 : DefaultStyleGuide.headline_weight_h1,
     "headline_weight_h2" : state.headline_weight_h2 ? state.headline_weight_h2 : DefaultStyleGuide.headline_weight_h2,
     "headline_weight_h3" : state.headline_weight_h3 ? state.headline_weight_h3 : DefaultStyleGuide.headline_weight_h3,
     "headline_weight_h4" : state.headline_weight_h4 ? state.headline_weight_h4 : DefaultStyleGuide.headline_weight_h4,
    };

    formdata.append(
      "headline_font_size", JSON.stringify(headline_font_size)
    );
    let text_size = 
      { 
       "text_size_body1" : state.text_size_body1 ? state.text_size_body1 : DefaultStyleGuide.text_size_body1 ,
       "text_size_body2" : state.text_size_body2 ? state.text_size_body2 : DefaultStyleGuide.text_size_body2,
       "text_size_legal" : state.text_size_legal ? state.text_size_legal : DefaultStyleGuide.text_size_legal,
       "text_lineheight_body1" : state.text_lineheight_body1 ? state.text_lineheight_body1 : DefaultStyleGuide.text_lineheight_body1,
       "text_lineheight_body2" : state.text_lineheight_body2 ? state.text_lineheight_body2 : DefaultStyleGuide.text_lineheight_body2,
       "text_lineheight_legal" : state.text_lineheight_legal ? state.text_lineheight_legal : DefaultStyleGuide.text_lineheight_legal,
       "text_name_body1" : state.text_name_body1 ? state.text_name_body1 : DefaultStyleGuide.text_name_body1,
       "text_name_body2" : state.text_name_body2 ? state.text_name_body2 : DefaultStyleGuide.text_name_body2,
       "text_name_legal" : state.text_name_legal ? state.text_name_legal : DefaultStyleGuide.text_name_legal,
       "text_spacebefore_body1" : state.text_spacebefore_body1 ? state.text_spacebefore_body1 : DefaultStyleGuide.text_spacebefore_body1,
       "text_spacebefore_body2" : state.text_spacebefore_body2 ? state.text_spacebefore_body2 : DefaultStyleGuide.text_spacebefore_body2,
       "text_spacebefore_legal" : state.text_spacebefore_legal ? state.text_spacebefore_legal : DefaultStyleGuide.text_spacebefore_legal,
       "text_spaceafter_body1" : state.text_spaceafter_body1 ? state.text_spaceafter_body1 : DefaultStyleGuide.text_spaceafter_body1,
       "text_spaceafter_body2" : state.text_spaceafter_body2 ? state.text_spaceafter_body2 : DefaultStyleGuide.text_spaceafter_body2,
       "text_spaceafter_legal" : state.text_spaceafter_legal ? state.text_spaceafter_legal : DefaultStyleGuide.text_spaceafter_legal,
       "text_letterspacing_body1" : state.text_letterspacing_body1 ? state.text_letterspacing_body1 : DefaultStyleGuide.text_letterspacing_body1,
       "text_letterspacing_body2" : state.text_letterspacing_body2 ? state.text_letterspacing_body2 : DefaultStyleGuide.text_letterspacing_body2,
       "text_letterspacing_legal" : state.text_letterspacing_legal ? state.text_letterspacing_legal : DefaultStyleGuide.text_letterspacing_legal,
       "text_font_body1" : state.text_font_body1 ? state.text_font_body1 : DefaultStyleGuide.text_font_body1,
       "text_font_body2" : state.text_font_body2 ? state.text_font_body2 : DefaultStyleGuide.text_font_body2,
       "text_font_legal" : state.text_font_legal ? state.text_font_legal : DefaultStyleGuide.text_font_legal,
       "text_weight_nb_body1" : state.text_weight_nb_body1 ? state.text_weight_nb_body1 : DefaultStyleGuide.text_weight_nb_body1,
       "text_weight_nb_body2" : state.text_weight_nb_body2 ? state.text_weight_nb_body2 : DefaultStyleGuide.text_weight_nb_body2,
       "text_weight_nb_legal" : state.text_weight_nb_legal ? state.text_weight_nb_legal : DefaultStyleGuide.text_weight_nb_legal,
       "text_weight_body1" : state.text_weight_body1 ? state.text_weight_body1 : DefaultStyleGuide.text_weight_body1,
       "text_weight_body2" : state.text_weight_body2 ? state.text_weight_body2 : DefaultStyleGuide.text_weight_body2,
       "text_weight_legal" : state.text_weight_legal ? state.text_weight_legal : DefaultStyleGuide.text_weight_legal,
      };
    formdata.append(
      "text_size" , JSON.stringify(text_size)
    );

    let button_font_size = 
    { 
     "button_text_size" : state.button_text_size ? state.button_text_size : DefaultStyleGuide.button_text_size,
     "button_text_lineheight" : state.button_text_lineheight ? state.button_text_lineheight : DefaultStyleGuide.button_text_lineheight,
     "button_text_weight" : state.button_text_weight ? state.button_text_weight : DefaultStyleGuide.button_text_weight,
     "button_text_weight_nb" : state.button_text_weight_nb ? state.button_text_weight_nb : DefaultStyleGuide.button_text_weight_nb,
     "button_text_letterspacing" : state.button_text_letterspacing ? state.button_text_letterspacing : DefaultStyleGuide.button_text_letterspacing,
     "button_text_fixed_lineheight" : state.button_text_fixed_lineheight ? state.button_text_fixed_lineheight : DefaultStyleGuide.button_text_fixed_lineheight,
     "button_padding_top" : state.button_padding_top ? state.button_padding_top : DefaultStyleGuide.button_padding_top,
     "button_padding_bottom" : state.button_padding_bottom ? state.button_padding_bottom : DefaultStyleGuide.button_padding_bottom,
     "button_padding_left" : state.button_padding_left ? state.button_padding_left : DefaultStyleGuide.button_padding_left,
     "button_padding_right" : state.button_padding_right ? state.button_padding_right : DefaultStyleGuide.button_padding_right
    };
  formdata.append(
    "button_font_size" , JSON.stringify(button_font_size)
  );

  let layout_details = 
  { 
   "layout_style" : state.layout_style ? state.layout_style : DefaultStyleGuide.layout_style,
   "layout_grid_swicth" : state.layout_grid_swicth ? state.layout_grid_swicth : DefaultStyleGuide.layout_grid_swicth,
   "layout_grid_margins" : state.layout_grid_margins ? state.layout_grid_margins : DefaultStyleGuide.layout_grid_margins,
   "layout_grid_columns" : state.layout_grid_columns ? state.layout_grid_columns : DefaultStyleGuide.layout_grid_columns,
   "layout_grid_gutters" : state.layout_grid_gutters ? state.layout_grid_gutters : DefaultStyleGuide.layout_grid_gutters,
   "layout_grid_width" : state.layout_grid_width ? state?.layout_grid_margins*2 + state?.layout_grid_gutters*13 + state?.layout_grid_columns*12 : DefaultStyleGuide.layout_grid_width,   
   "layout_padding_left" : state.layout_padding_left ? state.layout_padding_left : DefaultStyleGuide.layout_padding_left,
   "layout_padding_top" : state.layout_padding_top ? state.layout_padding_top : DefaultStyleGuide.layout_padding_top,
   "layout_padding_bottom" : state.layout_padding_bottom ? state.layout_padding_bottom : DefaultStyleGuide.layout_padding_bottom,
   "layout_padding_right" : state.layout_padding_right ? state.layout_padding_right : DefaultStyleGuide.layout_padding_right,
  };
  formdata.append(
    "layout_details" , JSON.stringify(layout_details)
  );

    formdata.append(
      "button_padding_top",
      state.button_padding_top ? state.button_padding_top : DefaultStyleGuide.button_padding_top
    );
    formdata.append(
      "button_padding_bottom",
      state.button_padding_bottom ? state.button_padding_bottom : DefaultStyleGuide.button_padding_bottom
    );
    formdata.append(
      "button_padding_left",
      state.button_padding_left ? state.button_padding_left : DefaultStyleGuide.button_padding_left
    );
    formdata.append(
      "button_padding_right",
      state.button_padding_right ? state.button_padding_right : DefaultStyleGuide.button_padding_right
    );
    formdata.append(
      "default_button_color", 
      state.button_background_color ? state.button_background_color : DefaultStyleGuide.button_background_color
    );
    formdata.append(
      "default_button_text_color",
      state.button_text_color ? state.button_text_color : DefaultStyleGuide.button_text_color
    );
    formdata.append(
      "social_accent_color",
      state.social_accent_color ? state.social_accent_color : DefaultStyleGuide.social_accent_color
    );
    formdata.append(
      "button_shape",
      state.button_radius ? state.button_radius : DefaultStyleGuide.button_radius
    );
    formdata.append(
      "button_radius_switch",
      state.button_radius_switch ? state.button_radius_switch : DefaultStyleGuide.button_radius_switch
    );
    state.socialLinkList.forEach((item: any) => {
      formdata.append(item.title, item.link ? item.link : "");
    });
    formdata.append("address", state.address ? state.address : "");
    formdata.append("state", state.store ? state.store : "");
    formdata.append("country", state.country ? state.country : "");
    formdata.append("postal_code", state.zip ? state.zip : "");
    formdata.append("c_logo_width", state.c_logo_width ? state.c_logo_width : 96);
    // if (state.logo && state.logo.file)
    //   formdata.append("c_logo", state.logo.file);
    // else
    
    if (state.logo && state.logo.src)
      formdata.append("old_c_logo", state.logo.src);
    const titleArray: any = [];
    const linkArray: any = [];

    state.menuList.forEach((item: any) => {
      titleArray.push(item.title);
      linkArray.push(item.link);
    });
    formdata.append("m_title", titleArray);
    formdata.append("m_link", linkArray);
    formdata.append(
      "google_font", state.google_font ? JSON.stringify(state.google_font) : JSON.stringify(DefaultStyleGuide.google_font)
    );    
    formdata.append(
      "UploadFontRegularNormal", state.UploadFontRegularNormal ? state.UploadFontRegularNormal : ""
    );

    /*var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };*/

    AxiosInstance.post(`api/template`, formdata)
      .then((result) => {
        if(state["styleGuideDefault"]){
          dispatch(addField("styleGuideDefault", false));
          handleNext();
        } else {
          toast("Saved Successfully");
          handleNext();
        }
      })
      .catch(function (error: any) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.warn(error.response.data.message);
          }
          if (error.response.status === 500) {
            alert("500, Server Error");
            toast.error(error.response.data.message);
          }
        }
      });
  };
  if (save) {
    save.current = handleSubmit;
  }
  useEffect(() => {
      var styleId: any = null;
      localStorage.removeItem("LastSavedTime");
    if(localStorage.getItem("styleGuideResID")==='' || localStorage.getItem("styleGuideResID")==null)
    {
        styleId=localStorage.getItem("rowId")
    }
    else
    {
        styleId = localStorage.getItem("styleGuideResID");
    }
     if(styleId)
      {
           getSavedData(styleId);
      }
      else
      {
         getcompanyFonts(); 
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
   useEffect(() => {
  const interval = setInterval(() => {
      let MsgContainer: HTMLDivElement | null = document.querySelector("#FlsMsgAlert");
if (MsgContainer) {
    var dt = new Date();
    if(setFlashMessage(dt.toUTCString()) === 0)
            {
                return true;
            }
            else if(setFlashMessage(dt.toUTCString())!== undefined)
            {
                MsgContainer.innerHTML = 'All Saved ✔ '+setFlashMessage(dt.toUTCString());

            }
}

  }, 1000);
  return () => clearInterval(interval);
}, []);
  const handleStyleUpdate = async () => {
     await SaveStyleguide();
     redirectTo("/designSystems");       
  };
  
  const checkCampExists = (styleId : any,styleguideName:any) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("style_id", styleId);
    data.append("company_id",localStorage.getItem("company_id"));
    data.append("styleguideName", styleguideName);

    AxiosInstance.post('api/checkAassignCamp', data)
     .then((response: AxiosResponse) => {
        if (response.data.count > 0) {
            setassignCampfound(1);
            settotaldesign(response.data.count);
            setopenStyleConfirmDialog(true);
        }
           else if (response.data.count === '-1') {
            toast.error('Design System names must be unique');
                 return false;
        }        else {
            settotaldesign(0);
            setassignCampfound(0);
            setopenStyleConfirmDialog(true);
        }
      })
      
      
  };
  const classes = useStyles();
 
  return (
    <>
    <UpdateStyleGuideConfirm
         totaldesign={totaldesign}
        handleStyleUpdate={handleStyleUpdate}
        assignCampfound={assignCampfound}
        open={openStyleConfirmDialog}
        setOpen={setopenStyleConfirmDialog}
        
      />
      
      <CloseStyleGuideConfirm
        closestyle={closestyle}
        open={CloseConfirmDialog}
        setOpen={setCloseConfirmDialog}
        
      />
      <ErrorConfirm
        closestyle={closestyle}
        open={ErrorConfirmDialog}
        setOpen={setErrorConfirmDialog}
        styleIdFromres={state.styleIdFromres}
        
      />
      
      
      <Box className={classes.headerInfo}>
        <Box className={classes.headerInfoFirst}><strong>Name:</strong> <Stylename isNotSettingsPage={isNotSettingsPage} /> {/*{state?.styleguideName}*/}</Box> 
        <Box className={classes.headerInfoSecond}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            // minWidth="15%"
            // style={{margin: 10}}
          >
            <Typography id="FlsMsgAlert"
            style={{
            fontSize: "14px",
            fontWeight: 600,
            display:"none",
            float:"left",
            }}            
            className={classes.classFlsMsgAlert}
            >
            </Typography>
            <Button className="edit_close_button" variant="outlined" color="primary"  onClick={() => {
                triggerClose();
            }}>
              Close
            </Button>
            <Button style={{display:"none"}} className="close_button_for_popup" variant="outlined" color="primary"  onClick={() => {
                dispatch(addField("designSystem", "showList"))
            }}>
              Close
            </Button>
            
           <Button
           className={classes.buttonStyle}
            variant="contained"
            color="primary"
            onClick={() => {
                triggersave();
             
            }}
          >
            Save & Close
          </Button>
          </Box>
        </Box>
      </Box>
    	<AppBar
							color="inherit"
							position="static"
							className={classes.appBarStyleDetails}
			>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
              <Tabs
                value={activeChild}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example" 
                className={classes.tabContainerStyle}
                TabIndicatorProps={{
                  style: {
                    marginBottom: "-1px",
                    backgroundColor: (state.errorfonttabcolor && activeChild===4) || (state.errortabcolor && activeChild===0) ? '#dc004e' :''
                  },
                }}
              >
                <Tab
                  label="Details"
                  style={{
                    textTransform: "none",
                    minWidth: "90px",
                    padding:"0 20px",
                    height:66,
                    fontWeight:"normal",
                    color: state.errortabcolor ? '#dc004e':'',
                  }}
                  {...a11yProps(0)}
                  onClick={(e) => {
                    setActiveChild(0);
                  }}
                  className={state.errortabcolor ? classes.tabContentErrorStyle : ""}
                />
                <Tab
                  label="Layout"
                  {...a11yProps(1)}
                  style={{
                    textTransform: "none", 
                    minWidth: "88px",
                    padding:"0 20px",
                    fontWeight:"normal"
                }}
                  onClick={(e) => {
                    setActiveChild(1);
                  }}
                /> 
                <Tab
                  label="Colours"
                  {...a11yProps(2)}
                  style={{
                    textTransform: "none", 
                    minWidth: "96px",
                    padding:"0 20px",
                    fontWeight:"normal"
                }}
                  onClick={(e) => {
                    setActiveChild(2);
                  }}
                />  
                <Tab
                  label="Dark Mode"
                  {...a11yProps(3)}
                  style={{
                    textTransform: "none", 
                    minWidth: "116px",
                    padding:"0 20px",
                    fontWeight:"normal"
                }}
                  onClick={(e) => {
                    setActiveChild(3);
                  }}
                /> 
                <Tab
                  label="Fonts"
                  {...a11yProps(4)}
                  style={{
                    textTransform: "none", 
                    minWidth: "80px",
                    padding:"0 20px",
                    fontWeight:"normal"
                }}
                  onClick={(e) => {
                    setActiveChild(4);
                  }}                  
                  className={state.errorfonttabcolor ? classes.tabContentErrorStyle  : "" }
                />                                              
                <Tab
                  label="Typography"
                  {...a11yProps(5)}
                  style={{
                    textTransform: "none", 
                    minWidth: "128px",
                    padding:"0 20px",
                    fontWeight:"normal"
                }}
                  onClick={(e) => {
                    setActiveChild(5);
                  }}
                />                                             
                <Tab
                  label="Call-To-Actions"
                  {...a11yProps(6)}
                  style={{
                    textTransform: "none", 
                    minWidth: "160px",
                    padding:"0 20px",
                    fontWeight:"normal"
                }}
                  onClick={(e) => {
                    setActiveChild(6);
                  }}
                />

                <Tab
                  label="Coding"
                  {...a11yProps(7)}
                  style={{
                    textTransform: "none", 
                    minWidth: "92px",
                    padding:"0 20px",
                    fontWeight:"normal"
                }}
                  onClick={(e) => {
                    setActiveChild(7);
                  }}
                /> 

                
              </Tabs>
                
            </Box>
          </Box>                               
        </Box>
	 	  </AppBar>

      <Box className={classes.root}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={isNotSettingsPage ? "80%" : "100%"}
          flexDirection="column"
        >
          {isNotSettingsPage && (
            <Box>
              <Typography
                component="h1"
                align="center"
                className={classes.topHeading}
                variant="h5"
              >
                Create Your Style Guide
              </Typography>
              <Typography
                component="p"
                className={classes.caption}
                align="center"
                variant="caption"
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore
              </Typography>
            </Box>
          )}
          <Paper
            className={!isNotSettingsPage ? "" : classes.container}
            elevation={0}
            square={!isNotSettingsPage}
          >
            <Grid container style={{flexWrap: "unset"}}>
              <Grid item md={6} sm={12} className={classes.leftBorder}>
                <Box className={classes.componentLeftContainer}>
                {/* <Stylename isNotSettingsPage={isNotSettingsPage} /> */}
                  <TabPanel value={activeChild} index={0}>
                    <Details />
                    <ContactDetails />
                    <Logo isNotSettingsPage={isNotSettingsPage} />
				          	{isAllowMenu() && (
                    <Menu />
					          )}
                    <SocialLinks />                          
                    {/* <Divider className={classes.dividerStyle} /> */}
                  </TabPanel>
                  <TabPanel value={activeChild} index={1}>
                    <Layout  />
                  </TabPanel>
                  <TabPanel value={activeChild} index={2}>
                    <MainColorsPallet />
                    <MainColors />
                    {/* <Divider className={classes.dividerStyle} style={{marginTop: -10}} /> */}
                  </TabPanel>
                  <TabPanel value={activeChild} index={3}>
                    <DarkBrandColors />
                  </TabPanel>
                  <TabPanel value={activeChild} index={4}>
                    <FontFamily />
                    {/* <Divider className={classes.dividerStyle} style={{marginTop: -10}} /> */}
                  </TabPanel>
                  <TabPanel value={activeChild} index={5}>
                    <TypographyStyle  />
                    {/* <Divider className={classes.dividerStyle} style={{marginTop: -10}} /> */}
                  </TabPanel>
                  <TabPanel value={activeChild} index={6}>
                    <Buttons />
                  </TabPanel>  
                  <TabPanel value={activeChild} index={7}>
                    <CodemirrorExample />
                  </TabPanel> 

                  
                  {/* <FooterInfo /> */}
                </Box>
                {isNotSettingsPage && (
                  <Footer
                    handleNext={() => {
                      handleSubmit(state);
                    }}
                    handleSkip={handleNext}
                  />
                )}
              </Grid>
              {/* <Grid item md={6} sm={12} className={classes.componentRight} style={{backgroundColor: state.email_color ? state.email_color : "#e3e0e0"}}> */}
              <Grid item md={6} sm={12} className={classes.componentRight}>
                <Box className={classes.componentRightContainer}>
                  <StylePrototype isLayoutActive={(activeChild === 1) ? true : false} isDarkActive={(activeChild === 3) ? true : false} />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default StyleGuide;
