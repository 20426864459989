import React ,  {useState} from "react";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import { addField } from '../../../../redux/actions/styleGuideActions';
// import Typography from "@material-ui/core/Typography";
//import { AutoSave } from '../StyleGuideAutosave';
interface Props {
  isNotSettingsPage: any;
}
const Stylename: React.FC<Props> = ({ isNotSettingsPage }) => {
   const dispatch = useDispatch();
  var styleGuideName = useSelector((state: any) => state.styleGuide.styleguideName);
  const state = useSelector((state: any) => state.styleGuide);
 const classes = useStyles();
 const [name, setName] = useState('')

  /*useEffect(() => {
    const delayTypingName = setTimeout(() => {
      handleInputChange(name);
    }, 1200)
    return () => clearTimeout(delayTypingName);

    // eslint-disable-next-line
  }, [name])*/

const handleInputChange = (event:any) => {
      if(event.target.value!=='')
      {
          setName(event.target.value);
         dispatch(addField("styleguideName", event.target.value));
        
        //AutoSave();
      }
      else
      {
          dispatch(addField("styleguideName", ''));
        // dispatch(addField("styleguideName", ''));
      }
  };
  

  return (
    <div className="App">
 
       {/* <Typography style={{marginBottom:"10px"}} className={classes.heading} variant="h6">
                Style Guide Name

      </Typography> */}
      
      <TextField style={{marginLeft:"16px"}} 
        onBlur={handleInputChange}
        defaultValue = {styleGuideName} 
        key={styleGuideName}
        name={name}
        className={classes.inputStyleGuideName}
        title={name}
        error={(state.styleguidename_error === true ? true : false)}
        helperText={(state.styleguidename_error === true ? 'This field cannot be left blank' : '')}
       />

    </div>
  );
};

export default Stylename;
