import React, { useEffect, useState } from "react";
import { Button, ButtonGroup} from "@material-ui/core";
import { useStyles } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../../redux/actions/styleGuideActions";

interface Props {
  data:any;
}

const StyleItemType: React.FC<Props> = ({data}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.styleGuide);  
  const [typeChanged, setTypeChanged] = useState(false);
  const [type, setType] = useState(data?.type ? data?.type : "FILE");

  const handleTypeClick = (type:any) => {
    let arr = state?.list_custom_fonts;
    arr?.forEach((item:any) => {
      // Loop through the styles array within each item
      item?.styles?.forEach((style:any) => {
        if(style.key === data.key && style["type"] !== type){          
          style["type"] = type;
          style["source"] = "";
          dispatch(addField("list_custom_fonts", arr));          
          // updateCustomCssStyleSheet(state?.list_custom_fonts);
        }
      });
    });
  }

  useEffect(()=>{
    if(typeChanged){
      handleTypeClick(type);
      setTypeChanged(false);
    }
  // eslint-disable-next-line
  },[typeChanged]);
  
  return (    
    <ButtonGroup size="medium" className={classes.groupStyleType} aria-label="medium outlined button group">
      <Button 
        className={`${classes.styleButtonItem} ${data?.type === "FILE" ? classes.selectedStyleType : ""}`} 
        onClick={() => {
          setType("FILE");
          setTypeChanged(true);
        }}
      >
        FILE
      </Button>
      <Button 
        className={`${classes.styleButtonItem} ${data?.type === "URL" ? classes.selectedStyleType : ""}`}
        onClick={() => {
          setType("URL");
          setTypeChanged(true);
        }}
      >
        URL
      </Button>
    </ButtonGroup>
  );
};

export default StyleItemType;
