import React, { Dispatch, SetStateAction } from "react";
import {
  Dialog,
  TextField,
  Button,
  Box,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SimpleTree from "../../../../../../../../common/components/SimpleTree";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { toast } from "react-toastify";
import AxiosInstance from "../../../../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";

interface Props {
  open: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
  history: any;
  refreshCampFolders: any;
  brandID: string;
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: 350,
  },
  avatar: {
    width: 80,
    height: 80,
  },
  treeViewDiv: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  treeViewFolder: {
    color: "#e0e0e0",
  },
  treeViewItem: {
    borderRadius: "5px",
  },
  divRoot: {
    "&.Mui-selected  > .MuiTreeItem-content": {
      "& $tickIcon": {
        color: "#73a8e2",
        paddingLeft: "5rem",
        display: "block",
      },
      "& $treeViewFolder": {
        color: "#73a8e2",
      },
    },
  },
  label: {
    fontWeight: "inherit",
    color: "inherit",
    borderRadius: theme.spacing(0.5),
    marginTop: ".3rem",
  },
  tickIcon: {
    display: "none",
  },
  folderHeight: {
    maxHeight: "15rem",
    overflow: "scroll",
    borderBottom: "1px solid #c4c4c4",
  
  },
}));
const CreateNewFolder: React.FC<Props> = ({
  open,
  setOpen,
  brandID,
  history,
  refreshCampFolders,
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    //setSelected([]);
  };
  const [parrentId, setParrentId] = React.useState<string>("");
  const [newFolderName, setNewFolderName] = React.useState<string>("");
  const [expanded, setExpanded] = React.useState<any>([]);
  //const [selected, setSelected] = React.useState([]);
  const [folderList, setFolderList] = React.useState<any>([]);

  const closeDropDownFolder = (event: any) => {
    const selectedFolder = event.currentTarget.id;
    setParrentId(selectedFolder);
  };
  const handleToggle = (event: any, nodeIds: any) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: any, nodeIds: any) => {
    //setSelected(nodeIds);
  };

  React.useEffect(() => {
    let data: any[] = [0];
    const expd = (child: any) => {
      child.forEach((item: any) => {
        data.push(String(item.id));
        if (item.childes) {
          expd(item.childes);
        }
      });
    };
    if (folderList.childes) {
      expd(folderList.childes);
    }
    setExpanded(data);
  }, [folderList]);

  const getCampFolders = () => {
    AxiosInstance.get('api/v1/Campaign/getCampFolders', {
		params: {
			brandID: brandID,
		}
	}).then((response: AxiosResponse) => {
        if (response.data.status === true) {
          setFolderList(response.data.folders[0]);                
          setParrentId('');
          setNewFolderName("");
        }
	})
  };
  const [error, setError] = React.useState<boolean>(false);

  const setFolderToNewFolder = (event: any) => {
    if (!newFolderName) {
      setError(true);
      toast.error("Enter Folder Name");
      return;
    }  else {
      setError(false);
    }
/*   if (selected.length === 0){
    //  setParrentId(nodes.id);
    }*/
    var FormData = require("form-data");
    var data = new FormData();

    data.append("folder_name", newFolderName);
    data.append("parent_id", parrentId);
    data.append("company_id", brandID);

    AxiosInstance.post('api/v1/campaign/campSaveFolder', data)
    	.then((response: AxiosResponse) => {
			if (response.data?.status === "error") {
			  toast.error("Folder names must be unique");
			} else {
			  refreshCampFolders();
			  setOpen(false);
			  setNewFolderName("");              
        //setSelected([]);
			}
    	})
		  .catch((error: any) => {
			toast.error("Enter Folder Name");
		  });
  };

  
  React.useEffect(() => {
   if(open === true) getCampFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleInputChange = (event: any) => {
    setNewFolderName(event.currentTarget.value);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">New Folder</DialogTitle>
        <DialogContent>
          <Box className={classes.root}>
            <TextField
              name="new_name"
              autoFocus
              margin="dense"
              fullWidth
              error={error}
              required
              value={newFolderName}
              onChange={handleInputChange}
            />
          </Box>
          <Box className={classes.folderHeight}>
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              // defaultSelected={["root"]}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              selected=""
              onNodeToggle={handleToggle}
              onNodeSelect={handleSelect}
            >
             
			  <SimpleTree
				fileTreeData={folderList}
				parrentId={parrentId}
				closeDropDownFolder={closeDropDownFolder}
				treeName="Emails"
			>
			</SimpleTree>
            </TreeView>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={setFolderToNewFolder}
            color="primary"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateNewFolder;
