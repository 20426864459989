import { DefaultStyleGuide } from "../app/views/onboarding/styleguide/utilities/DefaultStyleGuide";

type StyleType = {
    key: string;
    type: string;
    source: string;
    fontWeight: string[];
    fontStyle: string;
    order: any;
};

export const changeWeightToNumber = (string:any) => {
    if(string==="normal") return "400";
    if(string==="bold") return "700";

    return string;
}

export const getPaddingForButton = (lineheight:any) => {
    if(lineheight) return (lineheight - 24) / 2;

    return DefaultStyleGuide.button_padding_top;
}

export const getFixedLineHeightForButton = (lineheight:any) => {
    if(lineheight) return "24";

    return lineheight;
}

export const getRandomKey = (length: number) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let key = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      key += characters.charAt(randomIndex);
    }

    return key;
}

export const getNewStyleFont = (option:any) => {
    let randomKey: string;
    let i = 0;
    do {
        randomKey = "style_" + getRandomKey(4);
        i++;
        // eslint-disable-next-line
    } while (option?.styles.some((item: any) => item.key === randomKey) && i < 4);
    
    const newStyle = {
        key: randomKey,
        type: "FILE",
        source: "",
        fontWeight: ["400"],
        fontStyle: ["normal"],
        order: (option.styles.length + 1).toString(),
    };

    return newStyle;
}

export const getNewCustomFont = (data:any) => {  

    let randomKey:string;
    let i = 0;
    do {
        randomKey = "custom_" + getRandomKey(4);
        i++;
        // eslint-disable-next-line
    } while (data.some((item:any) => item.key === randomKey) && i < 4 );

    const customFont = {
        "key": randomKey,
        "type":"googlefonts",
        "family":"",
        "css_link":"",
        "styles": [] as StyleType[]
    }

    // const newStyle: StyleType = getNewStyleFont(customFont);
    // customFont.styles.push(newStyle);

    return customFont;
}
export const insertGoogleLinkToHead = (href:any) => {  
    // Check if a link element with the given href exists in the head
    const linkExists = Array.from(document.head.getElementsByTagName('link')).some(
      (link: HTMLLinkElement) => link.href === href
    );
    
    if (!linkExists) {
      // The link element with the given href does not exist, so create and append it
      const linkElement = document.createElement('link');
      linkElement.rel = 'stylesheet';
      linkElement.href = href;
      document.head.appendChild(linkElement);
    }
}

export const getFontType = (link:any) => {
    if(link)
    {
    var url = link.split('.');
    var type = url.pop();
    switch(type){
     case 'ttf' : return "truetype"; 
     default : return type;
    }
  }
  }
export const updateCustomCssStyleSheet = (list_custom_fonts:any) => {
    let css_custom_style: HTMLElement = document.getElementById("css_custom_style_new") as HTMLElement;
    let styleData ="";

    let arr = list_custom_fonts;
    arr?.forEach((item:any) => {
      if(item?.type === "googlefonts"){
       if(item?.css_link) {
        insertGoogleLinkToHead(item?.css_link + ":wght@100;200;300;400;500;600;700;800;900");
       }
      } else if (item?.type === "customfonts"){
        // Loop through the styles array within each item
        item?.styles?.sort((a:any, b:any) => parseInt(a.order, 10) - parseInt(b.order, 10));
        item?.styles?.forEach((style:any) => {
            style?.fontStyle?.forEach((option:any) => {
                if(item?.family && style?.source){
                styleData += " @font-face {\n"+
                "font-family:'" + (item?.family ? item?.family + "'" : "'") + ";\n" +
                "font-style: " + (option ? option : "normal") + ";\n" +
                "font-weight: " + (style?.fontWeight?.join(" ") ? style?.fontWeight?.join(" ") : "400" ) + ";\n" +
                "src: url('"+ style?.source +"') format('"+ getFontType(style?.source) +"');\n"+
                "}\n";
                }              
            });
        });
      }
    });
    css_custom_style.innerHTML = styleData;
}

export const getDefaultGoogleFontItem = (google_font:any) => {
    return [{
        "key": "custom_" + getRandomKey(4),
        "type":"googlefonts",
        "family": google_font?.family ? google_font?.family : "",
        "css_link": google_font?.css_link ? google_font?.css_link : "",
        "styles": []
    }]
}

export const getStyleDraggable = (snapshot: any, provided: any, draggableId: any,droppableRef:any) => {
    if (draggableId === snapshot.draggingFromThisWith) {
      let transform = provided?.draggableProps?.style?.transform;
      let droppableReff = droppableRef.current;
  
      if (transform) {
        transform = transform?.substring(10)?.split(",");
        let xTranslate = Number(transform[0].replace("px", ""));
        let yTranslate = Number(transform[1].replace("px)", ""));
        let y = yTranslate + provided?.draggableProps?.style?.top;
        let minY = droppableReff.getBoundingClientRect().y;
        let maxY =
          droppableReff.getBoundingClientRect().y +
          droppableReff.getBoundingClientRect().height;
  
        if (xTranslate > 70 || xTranslate < -70 || y < minY || y > maxY) {
          return {
            ...provided?.draggableProps?.style,
            opacity: 0,
            cusrsor: "pointer",
          };
        } else {
          return {
            ...provided?.draggableProps?.style,
            opacity: 1,
          };
        }
      }
    }
    return provided.draggableProps.style;
  };

export const getDefaultCustomFontItem = (custom_font:any) => {
    
    return [{
        "key": "custom_" + getRandomKey(4),
        "type":"customfonts",
        "family": custom_font?.family ? custom_font?.family : "",
        "css_link": "",
        "styles": [
            {
                "key":"style_" + getRandomKey(4),
                "type": "FILE",
                "source": custom_font?.RegularNormal ? custom_font?.RegularNormal: "",
                "fontWeight": ["400"],
                "fontStyle": ["mormal"],
                "order":"1"
            },
            {
                "key":"style_" + getRandomKey(4),
                "type": "FILE",
                "source": custom_font?.RegularItalic ? custom_font?.RegularItalic: "",
                "fontWeight": ["400"],
                "fontStyle": ["italic"],
                "order":"2"
            },
            {
                "key":"style_" + getRandomKey(4),
                "type": "FILE",
                "source": custom_font?.BoldNormal ? custom_font?.BoldNormal: "",
                "fontWeight": ["700"],
                "fontStyle": ["mormal"],
                "order":"3"
            },
            {
                "key":"style_" + getRandomKey(4),
                "type": "FILE",
                "source": custom_font?.BoldItalic ? custom_font?.BoldItalic: "",
                "fontWeight": ["700"],
                "fontStyle": ["italic"],
                "order":"4"
            },
        ]
    }]
}

export const getTotalLineHeightForButton = (state:any) => {
    let top = state && state?.button_padding_top ? parseInt(state?.button_padding_top) : 12;
    let bottom = state && state?.button_padding_bottom ? parseInt(state?.button_padding_bottom) : 12;
    let lineheight = state && state?.button_text_fixed_lineheight ? parseInt(state?.button_text_fixed_lineheight) : 24;

    return top + bottom + lineheight;
}