import React from "react";
import { useStyles } from "../../../../../../styles/searchstyle";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
interface Props {
  querySearch?: string;
  setQuerySearch: any;
  setImagesByComp: any;
  setListNextPage: any;
}

const SearchBar: React.FC<Props> = ({
  querySearch,
  setQuerySearch,
  setImagesByComp,
  setListNextPage,
}) => {
  const classes = useStyles();
  const querryFunc = (event: any) => {
    const selectedStatus = event.currentTarget.value;
    setQuerySearch(selectedStatus);
    setImagesByComp([]);
    setListNextPage(1);
  };
  return (
    <AppBar
      elevation={0}
      position="static"
      color="default"
      className={classes.searchBar}
    >
      <Toolbar className={classes.searchBarToolBox}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            value={querySearch}
            onChange={querryFunc}
          />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default SearchBar;
