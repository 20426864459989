import React, { useEffect } from "react";
import { useStyles } from "./style";
//import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FolderTree from "./components/FolderTree/FolderTree";
import Sidebarcomponents from "./components/FolderTree/Sidebarcomponents";
import FileList from "./components/FileList/FileList";
import FileListSkleton from "./components/skleton/fileList";
import FileTreeSkleton from "./components/skleton/fileTree";
import Typography from "@material-ui/core/Typography";
//import { DragDropContext } from "react-beautiful-dnd";
import {getUserFromToken} from "../../../../../utils/AuthUtility";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import { Container } from "@material-ui/core";
import {AxiosResponse} from "axios";

interface Props {
  history?: any;
  SetOpenModal:any;
  SetMarketoTemplateModal:any;
  getStyleGuideData:any;
  fetchMarketoaccountData:any;
}

const Templates: React.FC<Props> = ({
  history,
  SetOpenModal,
  SetMarketoTemplateModal,
  getStyleGuideData,
  fetchMarketoaccountData

}) => {
  const classes = useStyles();
  const loggedInUser = getUserFromToken();
  const [campFolders, setCampFolders] = React.useState<any>([]);
  const [folderOverlay, setFolderOverlay] = React.useState<any>(false);
  // eslint-disable-next-line
  const [ParamFolder, setParamFolder] = React.useState<any>(0);
  const [TemplateFolderID, setTemplateFolderID] = React.useState<any>(0);
  
  const getCampFolders = () => {
  	AxiosInstance.get('api/v1/Contents/get_templates_all_folders')
      .then((response: AxiosResponse) => {
        if (response.data.data.success === true) {
          setCampFolders(response.data.data.folders[0]);
          setFolderOverlay(true);
        }
      })
  };

  const [loadTemplates, setTemplatesByComp] = React.useState<any>([]);

  const [listnumRows, setListnumRows] = React.useState<any>(0);
  const [listshowing, setListshowing] = React.useState<any>(0);
  const [listNextPage, setListNextPage] = React.useState<any>(1);
  const [listOverlay, setListOverlay] = React.useState<any>(false);
  
  // eslint-disable-next-line
  const [UrlRenderValue, setUrlRenderValue] = React.useState<any>(false);
  const [listCompleteStatus, setListCompleteStatus] =
    React.useState<boolean>(true); 
  const [querySearch, setQuerySearch] = React.useState<string>("");
/*  const [screenShotItem, setScreenShotItem] = React.useState<any>([]);*/
  const brandID = loggedInUser.user_detail.Company;
  const [renderFileList, setRenderFileList] = React.useState<any>([]);
  const [dragOverFolder, setDragOverFolder] = React.useState("");
  const [mktaccountstatus, setmktaccountstatus] = React.useState<any>(false);
  
  const URL = window.location.pathname.split('/').pop();
  // eslint-disable-next-line
    function getParam(param : any){
         return new URLSearchParams(window.location.search).get(param);
    }

  const getTemplatesByComp = () => {
      setRenderFileList([]);
      //setTemplatesByComp([]);
  	AxiosInstance.get('api/v1/Contents/get_templates_by_comp', {
		params: {
			folderID: TemplateFolderID,
			query: querySearch,
			page: listNextPage,
			getTemplatesByComp:1
		},
	}).then((response: AxiosResponse) => {
        if (response.data.status === true) {
          setTemplatesByComp(
            loadTemplates.concat(response.data.data.data.templates)
          );

          setListnumRows(response.data.data.data.numRows);
          setListshowing(response.data.data.data.showing);
          setListNextPage(response.data.data.data.nextPage);
          if (
            response.data.data.data.numRows === response.data.data.data.showing
          ) {
            setListCompleteStatus(false);
          } else {
            setListCompleteStatus(true);
          }
          setListOverlay(true);
        }
      })
  };
  
  
  const checkmktaccstatus = () => {
    
  	 AxiosInstance.get('api/v1/Integration/checkmktaccountstatus', {
		params: {},
	}).then((response: AxiosResponse) => {
        
        if (response.data.data.status === true) {
          setmktaccountstatus(true);
        } else {
             setmktaccountstatus(false);
        }
      })
  
  };
  

  const getTemplatesByCompInitial = () => {
        setRenderFileList([]);
       setTemplatesByComp([]);
     //setParamFolder(tmpFolderID);
  	 AxiosInstance.get('api/v1/Contents/get_templates_by_comp', {
		params: {
			 folderID: TemplateFolderID,
			query: querySearch,
			page: 1,
			getTemplatesByCompInitial:1
		},
	}).then((response: AxiosResponse) => {
        if (response.data.status === true) {
          setTemplatesByComp(response.data.data.data.templates);
          setUrlRenderValue(true);
          setListnumRows(response.data.data.data.numRows);
          setListshowing(response.data.data.data.showing);
          setListNextPage(response.data.data.data.nextPage);
          if (
            response.data.data.data.numRows === response.data.data.data.showing
          ) {
            setListCompleteStatus(false);
          } else {
            setListCompleteStatus(true);
          }
          setListOverlay(true);
        }
      })
  
  };

  // move folder drag and drop
  const moveFolderDragandDrop = (folderId: number, parrent_id: number) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("folder_id", folderId);
    data.append("parent_id", parrent_id);
    data.append("newBrandID", brandID);

    AxiosInstance.post('api/v1/Contents/moveTempFolderToFolder', data)
     .then((response: AxiosResponse) => {
        if (response.data.status === true) {
          getCampFolders();
        }
      })
  };

  const moveTemplateToFolderFunction = (folderId: number, tm_id: number) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("tm_id", tm_id);
    data.append("company_id", brandID);
    data.append("folder_id", folderId);
    setRenderFileList([]);
    AxiosInstance.post('api/v1/Contents/move_template', data)
     .then((response: AxiosResponse) => {
        if (response.data.status === true) {
          getTemplatesByCompInitial();
        }
      })
  };
  
 useEffect(() => {
       checkmktaccstatus();
       if(URL ==='campaign')
       {
        getCampFolders();
        getTemplatesByComp();
       }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandID, URL]);
  
  useEffect(() => {
      if(URL ==='campaign')
      {
	   setTemplatesByComp([]);
       getTemplatesByCompInitial();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySearch, TemplateFolderID]);
  useEffect(() => {
	var TemplateReload = localStorage.getItem('TemplateReload');
    if(URL === "content") {
		setTemplatesByComp([]);
		if(TemplateReload === 'true'){
			setTemplateFolderID([]);
			getTemplatesByCompInitial();
			setListnumRows(0);
			setListshowing(0);
			setListNextPage(1);
			localStorage.setItem('TemplateReload',JSON.stringify(false));
		}
		
	}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [URL]);

  return (
  
    <Container style={{ marginTop: '16px'}} className={`${classes.root} ${localStorage.getItem("trial_mode") === 'true' ? classes.hasTrialInfo: ""}`}>
      
      <div>
        <Grid container>
        
            <Grid item xs={12} sm={12} md={4} >
                <Sidebarcomponents
                querySearch={querySearch}
                setQuerySearch={setQuerySearch}
                setListNextPage={setListNextPage}
                setTemplatesByComp={setTemplatesByComp}
                SetOpenModal={SetOpenModal}
                SetMarketoTemplateModal={SetMarketoTemplateModal}
                getStyleGuideData={getStyleGuideData}
                fetchMarketoaccountData={fetchMarketoaccountData}
                mktaccountstatus={mktaccountstatus}
                />
            {folderOverlay === false ? (
            
             <FileTreeSkleton />
            
            ) : (
              <FolderTree
                fileTreeData={campFolders}
                setFolderID={setTemplateFolderID}
                history={history}
                brandID={brandID}
                refreshCampFolders={getCampFolders}
                moveFolderDragandDrop={moveFolderDragandDrop}
                moveTemplateToFolderFunction={moveTemplateToFolderFunction}
                setDragOverFolder={setDragOverFolder}
                dragOverFolder={dragOverFolder}
                />
              )}
            </Grid>
          
          {listOverlay === false ? (
            <Grid item xs={12} sm={12} md={8} className={`${classes.mainBody} ${localStorage.getItem("trial_mode") === 'true' ? classes.hasTrialInfo: ""}`}>
              <FileListSkleton />
              <FileListSkleton />
              <FileListSkleton />
              <FileListSkleton />
              <FileListSkleton />
            </Grid>
          ) : (
            <>
              <Grid  item xs={12} sm={12} md={8} className={`${classes.mainBody} ${localStorage.getItem("trial_mode") === 'true' ? classes.hasTrialInfo: ""}`}>
                <FileList
                    getTemplatesLoad={getTemplatesByComp}
                    listCompleteStatus={listCompleteStatus}
                    fileMapData={loadTemplates}
                    history={history}
                    getTemplatesByCompInitial={getTemplatesByCompInitial}
                    setTemplatesByComp={setTemplatesByComp}
                    brandID={brandID}
                    renderFileList={renderFileList}
                    setRenderFileList={setRenderFileList}
					setDragOverFolder={setDragOverFolder}
					dragOverFolder={dragOverFolder}
                  />
                 <Grid item xs={12} sm={12} md={4}></Grid>
              <Grid item xs={12} sm={12} md={12} className={classes.listCount}>
                {listshowing !== 0 ? (
                  <Typography>
                    showing {listshowing} out of {listnumRows} results
                  </Typography>
                ) : (
                  <Typography>No results found</Typography>
                )}
              </Grid>

              </Grid>
             
            </>
          )}
        </Grid>
      </div>
    </Container>
  );
};

export default Templates;
