import Routes from "./Routes";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./app/views/error/ErrorPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

 function App() {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onReset={(e) => {
        // debugger;
      }}
    >
      <ToastContainer />
      <Routes />
    </ErrorBoundary>
  );
}

export default App;
