import React, { Dispatch, SetStateAction } from "react";
import {
  Dialog,
  Typography,
  Button,
  Box,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  trialPopupStatus: any;
  setTrialPopupStatus: Dispatch<SetStateAction<boolean>>;
 
}
const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: 350,
  },
  text: {
    margin: "0",
    paddingBottom: "1rem",
    borderBottom: "1px solid #c4c4c4",
  },
  linkcolor: {
    textDecoration: "none",
  },
  textColor: {
    color: "red",
  },
}));
const TrialPopup: React.FC<Props> = ({
  trialPopupStatus,
  setTrialPopupStatus
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setTrialPopupStatus(false);
  };

 
  return (
    <div>
      <Dialog
        open={trialPopupStatus}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Thank you for trialing MashrMail</DialogTitle>
        <DialogContent>
          <Box className={classes.root}>
            <Typography className={classes.text}>
              Your trial period has now expired.<br/>
			  Please contact <a href="https://support.mashrmail.io/" rel="noreferrer" className={classes.linkcolor} target="_blank"> customer support</a> to activate your account.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          
        </DialogActions>
      </Dialog> 
    </div>
  );
};

export default TrialPopup;
