

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0em",
    },
  },
  componentRoot: {
    width: "100%",
    display: "flex",
    paddingTop: "6rem",
    justifyContent: "center",
    background: " #f8f8f8",
  },
  root: {
    padding:"0px",
    paddingTop: "0px",
    background: " #f8f8f8",
    maxWidth: '1236px',
    "&$hasTrialInfo":{
      paddingTop:"152px",
    }
  },
  capmaignEntryDiv: {
    marginTop: "1rem",
  },
  hasTrialInfo:{
  },
  mainBody: {
    height: "calc(100vh - 185px)",
    overflowX: "hidden",
    overflowY: "scroll",
    "&$hasTrialInfo":{
      height:"calc(100vh - 341px)",
    }
  },
  searchBar: {
    border: "1px solid #E1E1E1",
    marginTop: '2px',
    background: "#ffffff",
    borderRadius: "4px",
  },
  searchListItem: {
    color: "#2F2F2F",
    width: "100%",
    cursor: "pointer",
    height: "40px",
    margin: "2px 0",
    display: "flex",
    padding: "2px 11px",
    overflow: "hidden",
    position: "relative",
    wordBreak: "break-all",
    alignItems: "center",
    borderRadius: "4px",
    paddingRight: "4px",
    "&:hover": {
      background: "#DBECFF",
    },
  },
  search: {
    position: "relative",
    marginRight: theme.spacing(2),
    width: "65%",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
  },
  statusDiv: {
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    minWidth: "10rem",
    borderLeft: "1px solid #ECECEC",
    paddingLeft: "1rem",
  },
  brandDiv: {
    minWidth: "10rem",
    fontSize: "1.2rem",
    cursor: "pointer",
    display: "flex",
    borderLeft: "2px solid #ECECEC",
    paddingLeft: ".5rem",
  },
  dropDown: {
    borderTop: "1px solid #ECECEC",
  },
  searchBottomDiv: {
    borderBottom: "1px solid #ECECEC",
  },
  expandIcon: {
    paddingTop: "0.4rem",
    paddingRight: ".1rem",
    color: "#cbcbcb",
  },
  statusResult: {
    paddingLeft: "0.5rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    paddingTop: ".2rem",
    // width: "5rem",
  },
  statusList: {
    width: "100%",
  },
  filetreeName: {
    paddingLeft: ".5rem",
  },
  boxBorder: {
    border: "1px solid #E1E1E1",
    marginBottom: "1rem",
    paddingBottom: ".5rem",
    paddingTop: ".5rem",
  },
  cardImage: {
    height: "4rem !important",
    marginLeft: "1rem",
    marginRight: '1rem',
    width: "4rem",
  },
  boxBody: {
    paddingBottom: ".5rem",
    overflow: "hidden",
    marginLeft: '-5px',
    flex:1
  },
  boxItemTitle: {
    padding: "0 5px 5px 5px",
    fontSize: "20px",
    height: "1.7rem",
    display: "flex",
    alignItems: "center",
  },
  boxItemA: {
    color: "#000000",
    fontSize: "17px",
    lineHeight: "24px",
    letterSpacing: "0.13px",
    cursor: "pointer",    
    textDecoration: "none",
    fontWeight: 500,
    "&:hover": {
      textDecoration: "underline",
    },
    "&:focus": {
      textDecoration: "underline",
    },
  },
  boxHeader: {
    fontSize: "7.5px",
    textTransform: "uppercase",
    color: "#000000",
    padding: "0 5px 1px 5px",
    letterSpacing: "1.27px",
    fontWeight: 500,
  },
  boxDescription: {
    color: "#000000",
    height: "20px",
    padding: "0 5px",
    overflow: "hidden",
    fontSize: "13.6px",
   /* minWidth: "100px",*/
    wordBreak: "break-all",
    letterSpacing: "0.37px",
    fontWeight: 500
  },
  createdBY:{
	// width:140
  },
  lastUpdate:{
	// width:100
  },
  statusTypography:{
    marginLeft: 10
 },
 createDateCamp:{

 },
 threedotcamp:{
  paddingLeft: 20,
  paddingRight: 20
 },
 GridcardImage:{

 },
 campListInfo:{
  width:"19%"
 },
 campListInfoLast:{
  width:"24%"
 },
 campListInfoChild:{
  width:"19%"
 },
  campListInfoChildLast:{
  width:"24%"
 },
 brandTypography:{
	width:90
 },
  statusSpan: {
    display: "inline-block",
    borderRadius: "3px",
    lineHeight: "20px !important",
    height: "20px",
    fontSize: "10px !important",
    textAlign: "center",
    padding: "0 4px",
    background: "#FFF0DF",
    color: "#D27200",
    "&.awaiting-approval":{      
      background: "#FEE2E2",
      color: "#B66666",
    },
    "&.approved":{      
      background: "#AAF2D0",
      color: "#00773F",
    },
    "&.not-approved":{      
      background: "#FFF0DF",
      color: "#FF8A00",
    },
    "&.changes-required":{      
      background: "#FBA6A6",
      color: "#BB0F0F",
    },
    "&.completed":{      
      background: "#82D4FA",
      color: "#0073A9",
    }
  },
  dotIcon: {
    cursor: "pointer",
    "&:active": {
      outline: "0",
      boxShadow: "inset 0 3px 5px rgb(0 0 0 / 13%)",
    },
  },
  deleteItem: {
    color: "red",
  },
  fileEdit: {
    minWidth: "30%",
    padding: "0",
  },
  renameCampaign:{
    position: "fixed",
    bottom: -100
  },
  fileEditIcon: {
    fontSize: "1rem",
  },
  subMenuExport:{
  '& > div[aria-hidden="true"]' : {
    position:"unset",
  },
  "& .MuiMenu-paper":{
    zIndex:"9999"
  }
  },
  boxWidth: {
    [theme.breakpoints.between("xs", "md")]: {
      width: "95% !important",
    },
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
  },
  listCount: {
    display: "flex",
    justifyContent: "center",
    paddingTop:"45px",
  },
  searchH6: {
    color: "#545454",
    fontSize: ".9rem",
    lineHeight: "1.6",
    paddingTop: ".3rem",
    // width: "3.2rem",
  },
  heading: {
    marginBottom: "16px",
    fontStyle: 'normal',
    fontFamily: 'Poppins, sans-serif',
    lineHeight: '120%',
    fontWeight: 500,
    letterSpacing: "1.2px",
    fontSize: '26px',
  },
  previewRoot: {
    margin: 0,
    padding: theme.spacing(2),
  },
  previewCloseButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  campaign_preview_desktop: {
    border: "1px solid #CBD6E2",
    margin: "0 74px 0 0",
    display: "flex",
    overflow: "hidden",
    background: "#f6f8fa",
    alignItems: "center",
    borderRadius: "7px 7px 6px 6px",
    flexDirection: "column",
    width: "50rem",
    ['@media (max-width:1400px)']: { // eslint-disable-line no-useless-computed-key
      width: "42rem",
    }
  },
  campaign_preview_window_header_dsktop: {
    display: "flex",
    height: "32px",
    paddingLeft: "15px",
    alignItems: "center",
    background: "#F6F8FA",
    width: "100%",
  },
  campaign_preview_window_header_mobile: {
    height: "60px",
    display: "flex",
    background: "#F6F8FA",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  campaign_preview_mobile: {
    margin: "0 0 0 13px",
    border: "1px solid #CBD6E2",
    boxSizing: "border-box",
    borderRadius: "36px",
    width: "25rem",
    background: "#f6f8fa",
    ['@media (max-width:1300px)']: { // eslint-disable-line no-useless-computed-key
      marginRight: 30,
    }
  },
  campaign_preview_window_content: {
    height: "calc(100vh - 300px)",
    background: "#fff",
    width: "100%",
  },
  campaign_preview_window_content_mobile: {
    margin: "0 13px 13px 13px",
    border: "1px solid #CBD6E2",
    boxSizing: "border-box",
    borderRadius: "0 0 28px 28px",
    background: "#fff",
    height: "calc(100vh - 300px)",
    overflow: "hidden",
  },

  previewDOT: {
    height: "11px",
    color: "#cbd6e2",
  },
  paper: {
    margin: theme.spacing(8, 4),
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    width: "100%" 
  },
  PreviewContainer:{
    margin:0,
    alignItems: "unset",
    flexDirection: "column",
    justifyContent: "flex-start",
    "& iframe" : {
      borderRadius: 7,
      border: "1px solid #e0e0e0"
    }
  },
  previewImgLogo:{
    display: "flex",
    marginLeft: 12,
    marginRight: 20
  },
  previewIcon:{
    display: "flex",
    alignItems: "center"
  },
  previewContentBody:{

  },
  campaign_preview_body:{

  },
  campaign_preview_window_content_body:{
    margin: 20,
    height: "calc(100vh - 114px)",
    display: "flex",
    justifyContent: "center",
  },
  previewDesktopSelected:{

  },
  previewTableSelected:{
    width:768
  },
  previewPhoneSelected:{
    width:330
  },
  ToggleSelected:{
    "&.MuiToggleButton-root.Mui-selected":{
      backgroundColor: "rgba(25, 118, 210, 0.08)"
    }
  },
  formGrid: {
    display: "flex",
    justifyContent: "center",
    boxShadow: "unset"
  },
  previewH6: {
    fontSize: "28px",
    color: "#5D7186",
  },
  previewHeader:{
    width: "100%",
    // position: "absolute",
    // top: 0,
    // left: 0,
    boxShadow: "1px 3px 10px -5px #b2b2b2"
  },
  previewContent:{
    position: "absolute",
    top: 66,    
    ['@media (max-width:1300px)']: { // eslint-disable-line no-useless-computed-key
      left: 0,
    }
  },
  previewPointer: {
    color: "#5d7186",
    fontSize: "3rem",
    paddingRight: "1rem",
    cursor: "pointer",
    width: 30,
    display: "flex"
  },
  iFrame: {
    border: "0",
  },
  buttonStyle: {
    textTransform: "none",
    fontWeight: 500,
    width:"98%",
    marginBottom:"10px",
    boxShadow: "none",
    "&:hover": {
      background: "#0a6adc",
      boxShadow: "none",
    },
  },
  searchHeader:{
    flexDirection: "row",
    marginBottom:"17px",
  },
  searchHeaderdropdown:{
    flexDirection: "row",
    marginBottom:"2px",
    display:"flex"
  },
  toolbarSearch:{
    minHeight: "unset !important",
    flex: 1,
    border: "1px solid #E1E1E1",
    background: "#ffffff",
    marginTop: 2,
    borderRadius: 4,
    height: 44,
  },
  selectStatus:{
    borderRadius: 4,
    margin: "0px 8px 8px 0px",
    flex: "2",
    "& .MuiOutlinedInput-root":{
      height: 46,
      background: "#ffffff"
    },
    "& .MuiSelect-select.MuiSelect-select:focus":{      
      background: "unset"
    }
  },
  selectStyleGuide:{
    borderRadius: 4,
    flex: "3",
    "& .MuiOutlinedInput-root":{
      height: 46,
      background: "#ffffff"
    },
    "& .MuiSelect-select.MuiSelect-select:focus":{      
      background: "unset"
    }
  },
  styleguideName:{
    // width:80
  },
  campGroupContainer:{
    border: "1px solid #E1E1E1",
    paddingTop: "0.5rem",
    marginBottom: "1rem",
    paddingBottom: "0.7rem",
    background:" #fff",
    borderRadius: 4,
    '& $campChild:nth-child(2)': {
      marginTop: 8,
      "& > .MuiGrid-root" :{
        marginTop: "-6px"
      }
    },
    '& $campChild:last-child': {
      paddingBottom: "unset",
      "& > .MuiGrid-root":{
        marginBottom: "-4px"
      }
    }
  },
  campMaster:{

  },
  campChild:{
    marginLeft: 20,
    borderLeft: "4px solid #E1E1E1",
    borderRadius: "unset",
    paddingTop: 2,
    paddingBottom: 2,
  }
}));

