import React, { Dispatch, SetStateAction } from "react";
import {
  Dialog,
  Typography,
  Button,
  Box,
  DialogContent,
  DialogActions
 } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  accessPopupStatus: any;
  setAccessPopupStatus: Dispatch<SetStateAction<boolean>>;
  popupTitle: any;
}
const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: 350,
  },
  text: {
    margin: "0",
    paddingBottom: "1rem",
    borderBottom: "1px solid #c4c4c4",
  },
  linkcolor: {
    textDecoration: "none",
  },
  textColor: {
    color: "red",
  },
 btnPrimary: {
		background: "#057CFF",
		borderRadius: "3px",
		color: "#ffffff",
		fontSize: 16,
		fontWeight: "normal",
		padding: "5px 30px",
		"&:hover": {
			background: "#0a6adc",
		}
	},
	dialogActions: {
		borderTop: "1px solid #e5e5e5",
		padding: "23px 0 15px 0",
		margin: "0 15px"
	}
}));
const AccessPopup: React.FC<Props> = ({
  accessPopupStatus,
  setAccessPopupStatus,
  popupTitle
  }) => {
  const classes = useStyles();

  const handleClose = () => {
    setAccessPopupStatus(false);
  };

 
  return (
    <div>
      <Dialog
        open={accessPopupStatus}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
	    <DialogContent>
          <Box className={classes.root}>
            <Typography className={classes.text}>
              It looks like you have reached the maximum number of {popupTitle}'s allowed on your current plan. Please contact your account manager to discuss moving to our Enterprise plan or remove any {popupTitle}'s which are no longer required.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" className={classes.btnPrimary}>
            Okay
          </Button>
          
        </DialogActions>
      </Dialog> 
    </div>
  );
};

export default AccessPopup;
