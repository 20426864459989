import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  formGrid: {
    display: "flex",
    justifyContent: "center",
    boxShadow: "unset"
  },
  paper: {
    margin: theme.spacing(8, 4),
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    width: "100%" 
  },
  PreviewContainer:{
    margin:0,
    alignItems: "unset",
    flexDirection: "column",
    justifyContent: "flex-start",
    "& iframe" : {
      borderRadius: 7,
      border: "1px solid #e0e0e0"
    }
  },
  previewHeader:{
    width: "100%",
    // position: "absolute",
    // top: 0,
    // left: 0,
    boxShadow: "1px 3px 10px -5px #b2b2b2"
  },
  previewImgLogo:{
    display: "flex",
    marginLeft: 12,
    marginRight: 20
  },
  previewH6: {
    fontSize: "28px",
    color: "#5D7186",
  },
  ToggleSelected:{
    "&.MuiToggleButton-root.Mui-selected":{
      backgroundColor: "rgba(25, 118, 210, 0.08)"
    }
  },
  campaign_preview_window_content_body:{
    margin: 20,
    height: "calc(100vh - 114px)",
    display: "flex",
    justifyContent: "center",
  },
  iFrame: {
    border: "0",
  },
  previewDesktopSelected:{

  },
  previewTableSelected:{
    width:768
  },
  previewPhoneSelected:{
    width:330
  },
  
}));
