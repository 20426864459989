import AxiosInstance from "../../../../utils/AxiosInstance";
import { toast } from "react-toastify";
import { store } from '../../../redux/store';
import {setFlashMessage} from "../../../../utils/AuthUtility";
import { DefaultStyleGuide } from '../styleguide/utilities/DefaultStyleGuide';

export const  AutoSaveTracking=()=>{
   
    var trackingId: any = null;
    const stateInitial = store.getState();
    const state = stateInitial.styleGuide;
    if(state.tracking_name === '' || state.tracking_name===undefined)
    {
         toast.error('Data not saved! Tracking name can not be empty.');
         return false;
    }
  
    var formdata = new FormData();
    const data = localStorage.getItem("loggedInUser");
    if(localStorage.getItem("TrackingResID")==='' || localStorage.getItem("TrackingResID")===null || localStorage.getItem("TrackingResID")===undefined)
    {
      
       trackingId = localStorage.getItem("TrackingrowId")
    }
    else
    {
        
       trackingId = localStorage.getItem("TrackingResID");
    }
    let Email = ""
    if (data) {
      const { user_detail } = JSON.parse(data)
      Email = user_detail.Email
    }
    formdata.append(
      "company_id",
      localStorage.getItem("usr_company_id") ||
      localStorage.getItem("company_id") ||
      ""
    );
    formdata.append("Email", Email || "");
    formdata.append("tracking_name", state.tracking_name);
	formdata.append(
	  "email_color_new",
	  state.email_color ? state.email_color : DefaultStyleGuide.email_color
	);
    formdata.append(
      "primary_color_new",
      state.primary_color ? state.primary_color : DefaultStyleGuide.primary_color
    );
    formdata.append(
      "secondary_color_new",
      state.secondary_color ? state.secondary_color : DefaultStyleGuide.secondary_color
    );
    formdata.append(
      "banner_text_color",
      state.header_text_color ? state.header_text_color : ""
    );
    formdata.append(
      "headline_font",
      state.primary_font ? state.primary_font : DefaultStyleGuide.primary_font
    );
    formdata.append(
      "button_font",
      state.button_font ? state.button_font : DefaultStyleGuide.button_font
    );
    formdata.append(
      "fallback_font",
      state.fallback_font ? JSON.stringify(state.fallback_font) : ""
    );
    formdata.append("website_url", state.website_url ? state.website_url : "");
    formdata.append(
      "headline_color",
      state.hedline_color ? state.hedline_color : DefaultStyleGuide.hedline_color
    );
    formdata.append("text_color", state.text_color ? state.text_color :  DefaultStyleGuide.text_color);
    formdata.append("text_font", state.text_font ? state.text_font : DefaultStyleGuide.text_font);
    formdata.append("link_color", state.link_color ? state.link_color :  DefaultStyleGuide.link_color);

    formdata.append(
      "headline_size_h1", 
      state.headline_size_h1 ? state.headline_size_h1 : DefaultStyleGuide.headline_size_h1
    );
    formdata.append(
      "headline_size_h2", 
      state.headline_size_h2 ? state.headline_size_h2 : DefaultStyleGuide.headline_size_h2
    );
    formdata.append(
      "headline_size_h3", 
      state.headline_size_h3 ? state.headline_size_h3 : DefaultStyleGuide.headline_size_h3
    );
    formdata.append(
      "headline_size_h4", 
      state.headline_size_h4 ? state.headline_size_h4 : DefaultStyleGuide.headline_size_h4
    );
    formdata.append(
      "headline_lineheight_h1", 
      state.headline_lineheight_h1 ? state.headline_lineheight_h1 : DefaultStyleGuide.headline_lineheight_h1
    );
    formdata.append(
      "headline_lineheight_h2", 
      state.headline_lineheight_h2 ? state.headline_lineheight_h2 : DefaultStyleGuide.headline_lineheight_h2
    );
    formdata.append(
      "headline_lineheight_h3", 
      state.headline_lineheight_h3 ? state.headline_lineheight_h3 : DefaultStyleGuide.headline_lineheight_h3
    );
    formdata.append(
      "headline_lineheight_h4", 
      state.headline_lineheight_h4 ? state.headline_lineheight_h4 : DefaultStyleGuide.headline_lineheight_h4
    );
    formdata.append(
      "text_size_body1", 
      state.text_size_body1 ? state.text_size_body1 : DefaultStyleGuide.text_size_body1
    );
    formdata.append(
      "text_size_body2", 
      state.text_size_body2 ? state.text_size_body2 : DefaultStyleGuide.text_size_body2
    );
    formdata.append(
      "text_size_legal", 
      state.text_size_legal ? state.text_size_legal : DefaultStyleGuide.text_size_legal
    );
    formdata.append(
      "text_lineheight_body1", 
      state.text_lineheight_body1 ? state.text_lineheight_body1 : DefaultStyleGuide.text_lineheight_body1
    );
    formdata.append(
      "text_lineheight_body2", 
      state.text_lineheight_body2 ? state.text_lineheight_body2 : DefaultStyleGuide.text_lineheight_body2
    );
    formdata.append(
      "text_lineheight_legal", 
      state.text_lineheight_legal ? state.text_lineheight_legal : DefaultStyleGuide.text_lineheight_legal
    );    
    formdata.append(
      "button_text_size",
      state.button_text_size ? state.button_text_size : DefaultStyleGuide.button_text_size
    );
    formdata.append(
      "button_text_lineheight",
      state.button_text_lineheight ? state.button_text_lineheight : DefaultStyleGuide.button_text_lineheight
    );    
    formdata.append(
      "button_padding_top",
      state.button_padding_top ? state.button_padding_top : DefaultStyleGuide.button_padding_top
    );
    formdata.append(
      "button_padding_bottom",
      state.button_padding_bottom ? state.button_padding_bottom : DefaultStyleGuide.button_padding_bottom
    );
    formdata.append(
      "button_padding_left",
      state.button_padding_left ? state.button_padding_left : DefaultStyleGuide.button_padding_left
    );
    formdata.append(
      "button_padding_right",
      state.button_padding_right ? state.button_padding_right : DefaultStyleGuide.button_padding_right
    );
    formdata.append(
      "default_button_color",
      state.button_background_color ? state.button_background_color : DefaultStyleGuide.button_background_color
    );
    formdata.append(
      "default_button_text_color",
      state.button_text_color ? state.button_text_color : DefaultStyleGuide.button_text_color
    );
    formdata.append(
      "social_accent_color",
      state.social_accent_color ? state.social_accent_color : DefaultStyleGuide.social_accent_color
    );
    formdata.append(
      "button_shape",
      state.button_radius ? state.button_radius : DefaultStyleGuide.button_radius
    );
    state.socialLinkList.forEach((item: any) => {
      formdata.append(item.title, item.link ? item.link : "");
    });
    formdata.append("address", state.address ? state.address : "");
    formdata.append("state", state.store ? state.store : "");
    formdata.append("country", state.country ? state.country : "");
    formdata.append("postal_code", state.zip ? state.zip : "");
    formdata.append("c_logo_width", state.c_logo_width ? state.c_logo_width : 96);
    if (state.logo && state.logo.file)
      formdata.append("c_logo", state.logo.file);
    else if (state.logo && state.logo.src)
      formdata.append("old_c_logo", state.logo.src);
  
  
    if(trackingId)
    {
        formdata.append("autosave_action", 'save'); 
        formdata.append("TrackingId", trackingId);
    }
    
    else
        
    {
        formdata.append("autosave_action", 'Insert'); 
    }
    //formdata.append("Styleguidevalue", state.Styleguidevalue);
    //formdata.append("StyleStatus", state.StyleStatus);     
   
    const titleArray: any = [];
    const linkArray: any = [];

    state.menuList.forEach((item: any) => {
      titleArray.push(item.title);
      linkArray.push(item.link);
    });
    formdata.append("m_title", titleArray);
    formdata.append("m_link", linkArray);
    
    const ParametertypeArray: any = [];
    const ParameterNameArray: any = [];
    const ParameterValueArray: any = [];
    const ParameterFieldLabelArray: any = [];


    state.ParameterList.forEach((item: any) => {
    if(item.Parametertype){ ParametertypeArray.push(item.Parametertype); }
    ParameterNameArray.push(item.ParameterName);
    ParameterValueArray.push(item.ParameterValue);
    ParameterFieldLabelArray.push(item.ParameterFieldLabel);
    });

    formdata.append("Parameter_type", ParametertypeArray);
    formdata.append("Parameter_Name", ParameterNameArray);
    formdata.append("Parameter_Value", ParameterValueArray);
    formdata.append("Parameter_field_label", ParameterFieldLabelArray);
    
    
    const AttributetypeArray: any = []; 
    const AttributeArray: any = [];
     const AttributeValueArray: any = [];
    const AttributeFieldLabelArray: any = [];
    
    
    state.AttributesList.forEach((item: any) => {
    if(item.Attributetype){ AttributetypeArray.push(item.Attributetype); }
    AttributeArray.push(item.AttributeName);
    AttributeValueArray.push(item.AttributeValue);
    AttributeFieldLabelArray.push(item.AttributeFieldLabel);
    });

    formdata.append("attribute_type", AttributetypeArray);
    formdata.append("Attribute", AttributeArray);
    formdata.append("Attribute_Value", AttributeValueArray);
    formdata.append("Attribute_field_label", AttributeFieldLabelArray);
    

    /*var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };*/

    AxiosInstance.post(`api/add_tracking`, formdata)
   
      .then((result) => {
          if(result.data.status_code==='404')
          {
                 toast.error('Sorry You are not authorized to perform this action');
                 return false;
          }
          
          if (result.data.status_code === '101') {
               toast.error(result.data.message);
               return false;
              }
              if (result.data.status_code === '102') {
               toast.error(result.data.message);
               return false;
              }
              
              
            let MsgContainer: HTMLDivElement | null = document.querySelector("#FlsMsgAlert");
            if (MsgContainer) {
                MsgContainer.setAttribute("style", "display:block;");
            var dt = new Date();
            if(setFlashMessage(dt.toUTCString()) === undefined)
            {
                MsgContainer.setAttribute("style", "padding-left:10px;display:inline-block;float:left;margin-top:3px;margin-right:29px");
                MsgContainer.innerHTML = 'All Saved ✔ a few seconds ago';
            }
            else
            {
               
                MsgContainer.innerHTML = 'Last Saved ✔ '+setFlashMessage(dt.toUTCString());
            }
               localStorage.removeItem("LastSavedTime");
               localStorage.setItem('LastSavedTime', dt.toISOString());
            }
            
            if(result.data.insert_id !==undefined)
            {
                localStorage.setItem('TrackingResID', result.data.insert_id);
                
                    var checkboxes = document.querySelectorAll('.customDefault_checkbox');
                    for (var i = 0, len = checkboxes.length; i < len; i++) {
                    const input = checkboxes[i] as HTMLInputElement;
                    if(input!= null)
                    {
                    input.disabled = false;
                    }
                    //work with checkboxes[i]
                    }
                
                
            }
      })
      .catch(function (error: any) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.warn(error.response.data.message);
          }
          if (error.response.status === 500) {
            alert("500, Server Error");
            toast.error(error.response.data.message);
          }
        }
        
      });
  
  
 //toast("Saved Successfully");
}
