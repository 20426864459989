import React, { useState, useCallback } from "react";
import { useStyles } from "../../templatestyles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import InfiniteScroll from "react-infinite-scroll-component";
import LinearProgress from "@material-ui/core/LinearProgress";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import MoveFile from "./utilities/MoveFile";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import ExportHtmlBox from "../../../../ExportHtml/ExportHtmlBox";
import Iframe from "react-iframe";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import DeleteFile from "./utilities/DeleteFile";
import RenameFile from "./utilities/RenameFile";
import AxiosInstance from "../../../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
import TemplateImage from './TemplateImage';
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import Card from "@material-ui/core/Card";
import TemplateConfirmModal from "./TemplateConfirmModal";
import CampaignModel from '../../../../../../../utils/CampaignModel';

//import ClickAwayListener from "@material-ui/core/ClickAwayListener";
//import Input from "@material-ui/core/Input";
//import InputAdornment from "@material-ui/core/InputAdornment";
//import CreateIcon from "@material-ui/icons/Create";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { getUserFromToken, redirectTo, isTrial, isDesigner, isMarketer} from "../../../../../../../utils/AuthUtility";
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import {Standard,Interactive } from '../../../../../../assets/svgs/SvgDynamic';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface Props {
  fileMapData: {
    c_class?: string;
    c_contact?: string;
    c_icon?: string;
    c_id?: string;
    c_logo?: string;
    c_logo_email?: string;
    c_name?: string;
    folder_id?: string;
    id?: string;
    template_id?: string;
    tm_active?: string;
    tm_company_id?: string;
    tm_date_creation?: string;
    tm_delete_time?: string;
    tm_deleted?: string;
    tm_id?: string;
    tm_is_gallery_adapted?: string;
    tm_name?: string;
    tm_image?: string;
  }[];
  getTemplatesLoad:any;
  listCompleteStatus: any;
  history: any;
  getTemplatesByCompInitial: any;
  setTemplatesByComp: any;
  brandID: string;
  setDragOverFolder: any,
  dragOverFolder: any,
  renderFileList: {
    c_class?: string;
    c_contact?: string;
    c_icon?: string;
     c_id?: string;
    c_logo?: string;
    c_logo_email?: string;
    c_name?: string;
    folder_id?: string;
    id?: string;
    template_id?: string;
    tm_active?: string;
    tm_company_id?: string;
    tm_date_creation?: string;
    tm_delete_time?: string;
    tm_deleted?: string;
    tm_id?: string;
    tm_is_gallery_adapted?: string;
    tm_name?: string;
    tm_image?: string;
  }[];
  setRenderFileList: any;
}

const FileList: React.FC<Props> = ({
  fileMapData,
  getTemplatesLoad,
  listCompleteStatus,
  renderFileList,
  setRenderFileList,
  history,
  getTemplatesByCompInitial,
  brandID,
  setTemplatesByComp,
  setDragOverFolder,
}) => {
  const classes = useStyles();

  const loggedInUser = getUserFromToken();

  // move releted functions
  const isScreenLg = useMediaQuery('(min-width: 1800px)');
  const [openMoveDialog, setOpenMoveDialog] = React.useState<boolean>(false);
  const [selectedFolderName, setSelectedFolderName] = React.useState("");
  const [selectedCampId, setSelectedCampId] = React.useState<number>(0);
  const [ConfirmModalPopupStatus, setConfirmModalPopupStatus] = useState<boolean>(false);
  const [SubMenuExportAnchorEl, setSubMenuExportAnchorEl] = useState<null | HTMLElement>(null);
  const [EmailProviders, setEmailProviders] = React.useState<any>([]);
  const [controller, setcontroller] = React.useState("");
  const openMoveFunc = (event: any) => {
    setSelectedFolderName(event.currentTarget.id);
    setSelectedCampId(event.currentTarget.dataset.id);
    setOpenMoveDialog(true);
  };

  // rename section
    const [openRenameDialog, setOpenRenameDialog] =React.useState<boolean>(false);
    const [renameTextID, setRenameTextID] = useState(0);
    const [newFolderName, setNewFolderName] = React.useState<string>("");
    const [templateId, settemplateId] = React.useState<string>("");
    const [OpenExportHtmlDialog, setOpenExportHtmlDialog] = useState<boolean>(false);
    const [exporttemplateId, setexporttemplateId] = useState("");
    const openRename = (event: any) => {
    const selectedFolderName = event.currentTarget.id;
    const selectedFolderID = event.currentTarget.value;
    setNewFolderName(selectedFolderName);
    setRenameTextID(selectedFolderID);
    setOpenRenameDialog(true);
  };

const openExportHtmlfun = (event: any) => {
        setexporttemplateId(event.currentTarget.id);
        setcontroller('template');
        var FormData = require("form-data");
      var data = new FormData();
      data.append("controller",'template');
      AxiosInstance.post("api/v1/Integration/all_email_providers", data).then(
        (response: AxiosResponse) => {
          if (response) {
            if (response.data.data) {
                setEmailProviders(response.data.data.email_providers);
                    setTimeout(async () => {
                    setOpenExportHtmlDialog(true);
                    }, 100);
               
            } else {
               
            }
          } 
        }
      );
      
      handleCloseExport();
  };
const handleSubMenuExportClick = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation(); // Prevent click event from propagating to parent
    setSubMenuExportAnchorEl(SubMenuExportAnchorEl ? null : event.currentTarget);
  };

  const handleCloseExport = () => {
    setSubMenuExportAnchorEl(null);
  };

  const handleMenuMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation(); // Prevent onMouseEnter event from propagating to MenuItem
    setSubMenuExportAnchorEl(event.currentTarget);
  };

  const handleMenuMouseLeave = () => {
    setSubMenuExportAnchorEl(null);
  };

const calculateNotificationArrivalTime = (createdDateString: string) => {
	  return moment(createdDateString).add(-(new Date().getTimezoneOffset()), 'minutes').fromNow()
  };

   const edit_template = (event: any) => {
        if(event.currentTarget.title === '')
        {
            const editLink = `${window.globalConfig.apiOrigin}${window.globalConfig.subDir && window.globalConfig.subDir.length ? ('/' + window.globalConfig.subDir) : ''}template/build/${event.currentTarget.id}`;
            window.location.href = editLink;

        } else {
            settemplateId(event.currentTarget.id);
            setConfirmModalPopupStatus(true);
        }
  };

  // colne releted functions
  const handlecolne = (event: any) => {
    const selectedCampaignID = event.currentTarget.id;
    const selectedCompanyID = event.currentTarget.value;
    const selectedFolderID = event.currentTarget.dataset.id;
    setTemplatesByComp([]);
    setRenderFileList([]);
    colneCampaign(selectedCampaignID, selectedCompanyID, selectedFolderID);
  };
  const colneCampaign = (
    selectedCampaignID: string,
    selectedCompanyID: string,
    selectedFolderID: string
  ) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("tm_id", selectedCampaignID);
    data.append("company_id", selectedCompanyID);
    data.append("folder_id", selectedFolderID);

    AxiosInstance.post('api/v1/Contents/clone_template', data)
		.then((response: AxiosResponse) => {
        if (response.data.status === true) {
          getTemplatesByCompInitial();
        }
      })
  };
  // preview section
  const [openPreview, setOpenPreview] = React.useState(false);
  const [viewMenu, setViewMenu] = React.useState(false);
  const [viewMenuList, setViewMenuList] = React.useState("");
  const [previewURL, setPreviewURL] = React.useState("");
  // eslint-disable-next-line
  const [darkURL, setDarkURL] = React.useState("");
  // eslint-disable-next-line
  const [lightURL, setLightURL] = React.useState("");
  const [previewName, setPreviewName] = React.useState("");
  const [isDark, setIsDark] = React.useState(false);
  /*const [isLoading, setIsLoading] = React.useState(false);*/

  const handleClickOpenPreview = (event: any) => {
    const selectedCampaignID = event.currentTarget.id;
    setPreviewName(event.currentTarget.dataset.id);
    var FormData = require("form-data");
    var data = new FormData();
    data.append("id", selectedCampaignID);
    document.body.setAttribute("oncontextmenu","return false;");
    AxiosInstance.post('api/v1/Campaign/template_screenshot', data)
     .then((response: AxiosResponse) => {
        if (response.data.status === true) {
          setPreviewURL(response.data.data);
          setDarkURL(response.data.data+"?modePreview=dark");
          setLightURL(response.data.data);
		  if(response.data.viewenabled === true){
			setViewMenu(true);
			setViewMenuList(response.data.viewmode);
			setViewFormats(response.data.viewmode);
			var url = new URL(response.data.data);
			if(!url.searchParams.has("modeView")){
				url.searchParams.append('modeView', response.data.viewmode);
			}else{
				url.searchParams.set('modeView', response.data.viewmode);
			}
			setPreviewURL(url.toString());
		  }
          setOpenPreview(true);
          if(response?.data?.is_dark) setIsDark(response.data?.is_dark);
        }
      })
  };

  const handleViewChange = (event: any) => {
	setViewFormats(event.target.value);
	var url = new URL(previewURL);
	if(!url.searchParams.has("modeView")){
		url.searchParams.append('modeView', event.target.value);
	}else{
		url.searchParams.set('modeView', event.target.value);
	}
    setPreviewURL(url.toString());
	event.persist();
  };

 const handleDragStart = useCallback((e: any, option: any) => {
    e.dataTransfer.setData("imageId", option.tm_id)
  }, [])
const padTo2Digits = (num:number) => {
    return num.toString().padStart(2, '0');
  }
  const formatDateWithSlach = (dateString:string) => {
    const date = new Date(dateString);
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }
  
  const handleDragEnd = useCallback((e: any) => {
    setDragOverFolder("")
  }, [setDragOverFolder])
  const handleClosePreview = () => {
    setOpenPreview(false);
	setViewMenu(false);
	setViewMenuList("");
    document.body.removeAttribute("oncontextmenu");
    setFormats("desktop");

    setMode("light");
	setViewFormats('standard');

  };

  // delete releted functions
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deleteCampaignID, setDeleteCampaignID] = useState<number>(0);

  const openDelete = (event: any) => {
    const selectedCampaignID = event.currentTarget.id;
    setDeleteCampaignID(selectedCampaignID);
    setOpenDeleteDialog(true);
  };

  const handleCampaignDelete = () => {
    setRenderFileList([]);
    
    AxiosInstance
    .delete(`api/v1/Contents/delete_template_ajax/${deleteCampaignID}`)
    .then(
		(response: AxiosResponse) => {
	        if (response.data.status === true) {
	            getTemplatesByCompInitial();
	        }
	      })
  };
  const [formats, setFormats] = React.useState("desktop");
  const [viewFormats, setViewFormats] = React.useState("standard");
  const handleFormat = (event:any, newFormats:any) => {
    if (newFormats !== null) {
      setFormats(newFormats);
    }
  };

  const [mode, setMode] = React.useState("light");
  const handleMode = (event:any, newFormats:any) => {
    var url = new URL(previewURL);
    if (newFormats !== null) {
      setMode(newFormats);
      if(newFormats === "dark"){ 
        if(!url.searchParams.has("modePreview")){
          url.searchParams.append('modePreview', 'dark');
        } else{
          url.searchParams.set('modePreview', 'dark');
        }
          setPreviewURL(url.toString());

        // setPreviewURL(darkURL);
      } else  {
          if(url.searchParams.has("modePreview")){
            url.searchParams.delete("modePreview");
          }
        setPreviewURL(url.toString());
          // setPreviewURL(lightURL);
        }
    }
  };

React.useEffect(()=>{
   
	  
  // eslint-disable-next-line
  },[viewFormats]);
  return (
    <div style={{width: '100%'}}>
        <ExportHtmlBox
        open={OpenExportHtmlDialog}
        setOpen={setOpenExportHtmlDialog}
        EmailProviders={EmailProviders}
        emailId={exporttemplateId}
        controller={controller}
        
      />
      <TemplateConfirmModal
	    ConfirmModalPopupStatus={ConfirmModalPopupStatus}		
		setConfirmModalPopupStatus={setConfirmModalPopupStatus}
        templateId={templateId}
      />
      <MoveFile
        open={openMoveDialog}
        selectedFolderName={selectedFolderName}
        setOpen={setOpenMoveDialog}
        history={history}
        getloadCampsInitial={getTemplatesByCompInitial}
        selectedCampId={selectedCampId}
        brandID={brandID}
      />
      <DeleteFile
        handleCampaignDelete={handleCampaignDelete}
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
      />

      <RenameFile
        open={openRenameDialog}
        renameTextID={renameTextID}
        setNewFolderName={setNewFolderName}
        newFolderName={newFolderName}
        setOpen={setOpenRenameDialog}
        getTemplatesByCompInitial={getTemplatesByCompInitial}
      ></RenameFile>
      <InfiniteScroll
        dataLength={fileMapData.length}
        next={getTemplatesLoad}
        hasMore={listCompleteStatus}
        height={`${localStorage.getItem("trial_mode") === 'true' ? "calc(100vh - 378px)": "calc(100vh - 322px)"}`}
        loader={<LinearProgress />}
        
      >
         <Box  flexWrap="wrap" width="100%">
          {fileMapData.map((option: any, index: any) => {
            return (
                     <div key={option.tm_id} draggable={((isDesigner() || isMarketer()) && isTrial() === false) ? true : false}
                onDragStart={(e) => handleDragStart(e, option)}
                onDragEnd={(e) => handleDragEnd(e)} className ={classes.campGroupContainer}
                        >
                <Card elevation={0} className ={classes.campMaster}  >
                  <Grid container alignItems="center">
                    <Grid item className={classes.GridcardImage} >
                       <TemplateImage
                                  thumb={option.tm_image}
                                  classes={classes.newCampaignCardImage}
                                  tempId={option.tm_id}
                                />   
                    </Grid>
                    <Grid
                      item
                      className={classes.boxBody}
                    >
                      <div className={classes.boxItemTitle}
                      >
                        <Typography
                            variant="subtitle2"
                          >
                            <a href={`${window.globalConfig.apiOrigin}${window.globalConfig.subDir && window.globalConfig.subDir.length ? ('/' + window.globalConfig.subDir) : ''}template/build/${option.tm_id}`} className={classes.boxItemA}>
                            {option.tm_name}
                            </a>
                          </Typography>
                          
                          <Typography
                          style={{display:option.template_type==='1'? 'block': 'none'}}
                            component="p"
                            className={`${classes.boxDescription} ${classes.statusTypography}`}
                          >
                          <Typography component="p" className={`${classes.statusSpan} ${CampaignModel.getStatusClass(option)}`}  >
                                {CampaignModel.getStatus(option)}
                            </Typography>
                        </Typography>
                          
                      </div>

                      <div>
                        <Box
                          display="flex"
                          alignItems="center"
                          flex={1}
                        >
                          <Box className={classes.campListInfo}>
                            <Typography
                              component="p"
                              className={classes.boxHeader}
                            >
                              Created By
                            </Typography>
                            {option.updated_by ? (
                              <Typography
                                component="p"
                                className={`${classes.boxDescription} ${classes.createdBY}`}
                                title={`${option.updated_by} ${option.updated_by}`}
                              >
                                {option.updated_by}
                              </Typography>
                            ) : (
                              <Typography
                                component="p"
                                className={`${classes.boxDescription} ${classes.createdBY}`}
                              >
                                Unknown
                              </Typography>
                            )}
                          </Box>
                          <Box className={classes.campListInfo}>
                            <Typography
                              component="p"
                              className={classes.boxHeader}
                            >
                              CREATED DATE
                            </Typography>
                            <Typography component="p" className={`${classes.boxDescription} ${classes.createDateCamp}`} >                              
                                {formatDateWithSlach(option.tm_date_creation)}
                            </Typography>
                          </Box>
                          <Box className={classes.campListInfolast}>
                            <Typography
                              component="p"
                              className={classes.boxHeader}
                            >
                              LAST UPDATE
                            </Typography>
                            <Typography
                              component="p"
                              className={`${classes.boxDescription} ${classes.lastUpdate}`}
                            >
                              {option.last_updated_time!==null || option.tm_updated_date!==null ? (
                              <Typography
                                component="p"
                                className={`${classes.boxDescriptionlastupdate} ${classes.createdBY}`}
                                title={`${option.updated_by} ${option.updated_by}`}
                              >
                              
                                {calculateNotificationArrivalTime(
                                   option.tm_updated_date!==null?option.tm_updated_date:option.last_updated_time
                                )}
                              </Typography>
                            ) : (
                              <Typography
                                component="p"
                                className={`${classes.boxDescription} ${classes.createdBY}`}
                              >
                              
                                -
                              </Typography>
                            )}
                            </Typography>
                          </Box>
                          <Box className={classes.campListInfo}>
                            <Typography
                              component="p"
                              className={classes.boxHeader}
                            >
                              FOLDER
                            </Typography>
                            {option.name ? (
                              <Typography
                                component="p"
                                className={classes.boxDescription}
                                title={option.name}
                              >
                                {option.name}
                              </Typography>
                            ) : ((option.camp_folders_id === "-1"? (
                                <Typography
                                  component="p"
                                  className={classes.boxDescription}
                                >
                                  Archive
                                </Typography>
                            ) : (
                                <Typography
                                  component="p"
                                  className={classes.boxDescription}
                                >
                                  -
                                </Typography>
                            ))
                             
                            )}
                          </Box>
                          
                           <Box className={classes.campListInfo}>
                            <Typography
                              component="p"
                              className={classes.boxHeader}
                            >
                              DESIGN SYSTEM   
                            </Typography>
                            {option.styleguide_name ? (
                              <Typography
                                component="p"
                                className={`${classes.boxDescription} ${classes.styleguideName}`}
                                title={option.styleguide_name}
                              >
                                {option.styleguide_name}
                              </Typography>
                            ) : (
                              <Typography
                                component="p"
                                className={`${classes.boxDescription} ${classes.styleguideName}`}
                              >
                                -
                              </Typography>
                            )}
                          </Box>
                          
                          
                        </Box>
                      </div>
                    </Grid>
                    <Grid item className={classes.threedotcamp}>
                      <PopupState
                        variant="popover"
                        popupId="demo-popup-menu"
                      >
                        {(popupState: any) => (
                          <React.Fragment>
                            <MoreVertIcon
                              className={classes.dotIcon}
                              {...bindTrigger(popupState)}
                            />
                            <Menu {...bindMenu(popupState)}
                              getContentAnchorEl={null}
                              anchorOrigin={{
                                vertical: 30,
                                horizontal: -60
                              }}
                            >
                              <div>
                                <MenuItem
                                id={option.tm_id}
                                title={option.marketo_template_id}
                                onClick={edit_template}
                                >
                                <Typography>

                                  Edit
                                </Typography>
                                </MenuItem>

                              </div>
                              <div onClick={popupState.close}>
                            <MenuItem
                            id={option.tm_id}
                            data-id={option.tm_name}
                            onClick={
                              handleClickOpenPreview
                            }
                            >
                            <Typography>
                              Preview
                            </Typography>
                            </MenuItem>
                              </div>
                              
                              <div 
                                  onMouseEnter={handleMenuMouseEnter}
                                  onMouseLeave={handleMenuMouseLeave}
                                >
                                  <MenuItem onClick={handleSubMenuExportClick} style={{display:option.template_type==='1'? 'block': 'none'}}>
                                     <Box flex="1">Export</Box> {/*<NavigateNext fontSize="small" /> */}
                                  </MenuItem>
                                  <Menu
                                    id="sub-menu-export"
                                    anchorEl={SubMenuExportAnchorEl}
                                    className={classes.subMenuExport}
                                    keepMounted
                                    open={Boolean(SubMenuExportAnchorEl)}
                                    onClose={handleCloseExport}
                                    style={{ position: "unset" }}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: isScreenLg ? "right" : "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: isScreenLg ? "left" : "right",
                                    }}
                                  >
                                    <div onClick={popupState.close}>
                                    <MenuItem
                                     id={option.tm_id}
                                       value={option.tm_name}
                                        onClick={openExportHtmlfun}
                                      >
                                        <Typography>Export As Html</Typography>
                                      </MenuItem>
                                        
                                    </div>
                                  </Menu>
                                </div>
                           
							  
                              <div onClick={popupState.close}>
                                <MenuItem
                                id={option.tm_name}
                                value={option.tm_id}
                                onClick={openRename}
                                disabled={isTrial()}
                                >
                                <Typography>
                                  Rename
                                </Typography>
                                </MenuItem>
                              </div>
							 
                              <div onClick={popupState.close}>
                                <MenuItem
                                id={option.tm_id}
                                value={option.tm_company_id}
                                data-id={option.folder_id}
                                onClick={handlecolne}
                                disabled={isTrial()}
                                >
                                <Typography>
                                Duplicate
                                </Typography>
                                </MenuItem>
                              </div>
                              
                                <div onClick={popupState.close}>
                                <MenuItem
                                id={option.tm_name}
                                data-id={option.tm_id}
                                onClick={openMoveFunc}
                                disabled={isTrial()}
                                >
                                <Typography>
                                Move to Folder
                                </Typography>
                                </MenuItem>
                                </div>

                              <div onClick={popupState.close}>
                                <MenuItem
                                  id={option.tm_id}
                                  onClick={openDelete}
                                  className={classes.deleteItem}
								  disabled={isTrial()}
                                >
                                  <Typography>Delete</Typography>
                                </MenuItem>
                              </div>
                               
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </Grid>
                  </Grid>
                </Card>
                
              </div>
            );
          })}
        </Box>
      </InfiniteScroll>
	  
	  <Dialog fullScreen open={openPreview} onClose={handleClosePreview}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          component={Paper}
          className={classes.formGrid}
          elevation={4}
          square
        >
          <div className={`${classes.paper} ${classes.PreviewContainer}`}>
            <div className={classes.previewHeader}>
              <Box display="flex">
                <Box p={1} flexGrow={1} display="flex" alignItems="center">
                <Box className={classes.previewImgLogo} style={{ cursor: "pointer" }} onClick={() => redirectTo('/')} >
                    <img
                      alt="logo"
                      style={{ maxHeight:34,maxWidth: 66 }}                  
                      src={loggedInUser?.user_detail?.CompanyImage+"?time=" + new Date().getTime()}
                    />  
                  </Box>
                  <Typography variant="subtitle1" style={{color: "#5D7186"}}>
                    {previewName}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                <Grid container>
                  <Grid style={{display:"flex"}}>

                    {isDark && <Box style={{marginRight:20}}>                      

                      <ToggleButtonGroup
                        size="small"
                        value={mode}
                        exclusive
                        onChange={handleMode}
                        aria-label="light"
                      >
                        <ToggleButton value="light" aria-label="light" classes={{ selected: classes.ToggleSelected}}  >
                          <WbSunnyIcon color={mode === "light" ? "primary" : undefined} />
                        </ToggleButton>
                        <ToggleButton value="dark" aria-label="dark" classes={{ selected: classes.ToggleSelected}}>
                          <Brightness3Icon color={mode === "dark" ? "primary" : undefined} />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>}
					  <Box style={{marginRight:20}}> 
						{viewMenu === true && (
						 <ToggleButtonGroup
							size="small"
							value={formats}
							exclusive
							aria-label="desktop"
						  >
						<TextField
							select
							fullWidth
							style={{width:165}}
							InputProps={{ style: { height: 40 } }}
							name="userRoles"
							id="userRoles"
							variant="outlined"
							label="View"
							defaultValue={viewFormats}
							SelectProps={{
							multiple: false,
							//value: selectedValue,
							onChange: handleViewChange
							}}
							>
							<MenuItem value="fallback">
								<ListItemIcon style={{minWidth:35 ,float:'left'}}>
								<RadioButtonUnchecked color={viewFormats === "fallback" ? "primary" : undefined}/>
							  </ListItemIcon>
							  <ListItemText primary="Fallback" style={{color: (viewFormats === "fallback" ? "#057CFF" : '')}}/>
							</MenuItem>
							<MenuItem value="standard" selected={true}>
							  <ListItemIcon style={{minWidth:35 ,float:'left'}}>
								<Standard color={viewFormats === "standard" ? "#057CFF" : ''}/> 
							  </ListItemIcon>
							  <ListItemText primary="Standard" style={{color: (viewFormats === "standard" ? "#057CFF" : '')}}/>
							  </MenuItem>
							{viewMenuList ==='interactive' && (
							<MenuItem value="interactive">
							  <ListItemIcon style={{minWidth:35 ,float:'left'}}>
								<Interactive color={viewFormats === "interactive" ? "#057CFF" : ''}/> 
							  </ListItemIcon>
							  <ListItemText primary="Interactive" style={{color: (viewFormats === "interactive" ? "#057CFF" : '')}}/>
							</MenuItem>
							)}
							
							</TextField>
						  </ToggleButtonGroup>
						)}
						</Box>

                    <div>

                      <ToggleButtonGroup
                        size="small"
                        value={formats}
                        exclusive
                        onChange={handleFormat}
                        aria-label="desktop"
                      >
                        <ToggleButton value="desktop" aria-label="desktop" classes={{ selected: classes.ToggleSelected}}  >
                          <DesktopMacIcon color={formats === "desktop" ? "primary" : undefined} />
                        </ToggleButton>
                        <ToggleButton value="table" aria-label="table" classes={{ selected: classes.ToggleSelected}}>
                          <TabletMacIcon color={formats === "table" ? "primary" : undefined} />
                        </ToggleButton>
                        <ToggleButton value="phone" aria-label="phone" classes={{ selected: classes.ToggleSelected}}>
                          <PhoneIphoneIcon color={formats === "phone" ? "primary" : undefined} />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </Grid>
                </Grid>
                </Box>
                <Box p={1}>
                  <CloseIcon
                    className={classes.previewPointer}
                    onClick={handleClosePreview}
                  />
                </Box>
              </Box>
            </div>
            <Box display="flex" justifyContent="center" className={classes.previewContentBody}>
              <Box width="100%" className={classes.campaign_preview_body}>
                {/* <div className={classes.campaign_preview_window_header_dsktop}>
                  <FiberManualRecordIcon className={classes.previewDOT} />
                  <FiberManualRecordIcon className={classes.previewDOT} />
                  <FiberManualRecordIcon className={classes.previewDOT} />
                </div> */}
                <div className={classes.campaign_preview_window_content_body}>
                  <Iframe
                    className={`${classes.iFrame}
                    ${formats === "desktop" ? classes.previewDesktopSelected : ""}
                    ${formats === "table" ? classes.previewTableSelected : ""}
                    ${formats === "phone" ? classes.previewPhoneSelected : ""}
                    `}
                    url={previewURL}
                    height="100%"
                    width="100%"
                  ></Iframe>
                </div>
              </Box>
            </Box>
          </div>
        </Grid>
      </Dialog>
	  
    </div>
  );
};

export default FileList;
