import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { MenuItem, Menu } from "@material-ui/core";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { isMarketer, isTrial } from "../../../../utils/AuthUtility";
// import TrialPopup from "../../../../utils/TrialPopup";
// import AccessPopup from "../../../../utils/AccessPopup";
import AxiosInstance from "../../../../utils/AxiosInstance";
import {updateFieldData} from "../../../redux/actions/EspManagerActions";
import { AxiosResponse } from "axios";
import { useDispatch, useSelector } from "react-redux";
import Switch from "@material-ui/core/Switch";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import CreateIcon from "@material-ui/icons/Create";


import DeleteIntegration from "./DeleteIntegration";
import CreateNewIntegration from "./CreateNewIntegration";
import { toast } from "react-toastify";
//import SkeletonTrackingList from "./SkeletonTrackingList";

interface Props {
  data: any;
  reloadLists: any;
  history: any;
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  buttonStyle: {
    textTransform: "none",
    fontWeight: 600,
    boxShadow: "none",
    "&:hover": {
      background: "#0a6adc",
      boxShadow: "none",
    },
  },
  fileEdit: {
    minWidth: "30%",
    padding: "0",
  },
  hasTrialInfo:{

  },
   tableContainerStyleGuide:{
    // margin: 12,
    // marginBottom: 20,
    width: "auto",    
    maxHeight: "calc(100vh - 195px)",
    overflow: "hidden",
    overflowY: "scroll",
    "&$hasTrialInfo":{
      maxHeight: "calc(100vh - 251px)",
    }
  },
  styleLogo:{
    width: 32, 
    height: "32px", 
    margin:"5px 10px 5px 5px",
    float:"left",
    display:"flex",
    alignItems:"center",
    overflow: "hidden",
    borderRadius: "50%",
    border: "1px solid #cdcdcd",
    padding: 2,
    boxSizing: "border-box",
    "& img":{
      width: "100%",
      height: "100%",
      objectFit: "contain"
    }
  },
  menuButton: { cursor: "pointer" },
  deleteOption: {
    color: "red",
  },
  toolbar: {
    backgroundColor: "white",
    paddingLeft: 5,
    minHeight: "unset",
    paddingBottom: 17,
    paddingRight: 0,
  },
  avatar: { marginRight: 10, width: 32, height: 32 },
  tableThead: {
    backgroundColor: "#F3F3F3",
    borderRadius: 3,
    "& th": {
      fontSize: 10,
      fontWeight: "700",
      lineHeight: "unset",
      padding: "12px 0 10px 0",
    },
    "& th:first-child": {
      paddingLeft: 20,
    },
  },
  fileEditIcon: {
    fontSize: "1rem",
  },
  outLineButtonContent: {
    border: "1px solid #057cff",
    borderRadius: "4px",
    color: "#367BF5",
    height: "unset",
    lineHeight: "normal",
    fontWeight: "normal",
    "&:hover": {
      color: "#ffffff",
      background: "#367BF5",
    },
  },
  titleh6: {
    fontSize: 18,
  },
  tableTbodyRow: {
    borderBottom: "1px solid #E1E1E1",
    "& > td": {
      padding: "10px 0 !important",
    },
    "& > td:first-child": {
      paddingLeft: "20px !important",
    },
  },
});

const IntegrationList: React.FC<Props> = ({ data, reloadLists, history }) => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state);
  const classes = useStyles();
  const [listData, setListData] = useState<any>([]);
  //const [isGetListData, setIsGetListData] = useState<boolean>(false);
  //  const [trialPopupStatus, setTrialPopupStatus] = useState<boolean>(false);
  //  const [accessPopupStatus, setAccessPopupStatus] = useState<boolean>(false);
  const [openIntDeleteDialog, setopenIntDeleteDialog] = useState<boolean>(false);
  const [openNewIntDialog, setopenNewIntDialog] = useState<boolean>(false);
  const [IntegrationIdDel, setIntegrationIdDel] = useState<boolean>(false);
  const [IntegrationData, setIntegrationData] = useState<any>([]);
  const [showRename, setShowRename] = useState(false);
  const [widthrenameText, setWidthrenameText] = useState<any>(120);
  const [renameText, setRenameText] = useState("");
  //const [renamedata, setRenameData] = useState<any>(null);
  const [renameTextID, setRenameTextID] = useState<any>(0);
  const [existRenameText, setExistRenameText] = useState("");
  // eslint-disable-next-line
  const [renamedata, setRenameData] = useState<any>(null);
  // eslint-disable-next-line

  
   const changeEspStatus = (rowid: any,currentstatus : any) => {
       const newStatus  = currentstatus === "1" ? "0" : "1";
      /* localStorage.removeItem("mktstatus");
    
    if(currentstatus === '1')
    {
        localStorage.setItem("mktstatus", '0'); 
    } else {
         localStorage.setItem("mktstatus", '1');
    }*/
    const data = new FormData();
    data.append("stats", newStatus);
    AxiosInstance.post(`api/v1/Settings/change_esp_status/${rowid}`, data)
     .then((response: AxiosResponse) => {
		 refreshTrackingData();
         
      });
      
  };
  
   const getTextWidth = (text:string): any => {
        const div = document.createElement('div');
        div.style.cssText = "display: inline-block; visibility: hidden;font-size:1.12rem";
        div.innerText = text;
        const element = window.document.getElementById("rename-campaign-text");
        element?.appendChild(div);
        var width = div.getBoundingClientRect().width;
        element?.removeChild(div);
      return width + 30;
  }
  
    const openRename = (TargetId: any, espnameselected: any) => {
    //const selectedFolderName = event.currentTarget.id;
    //const selectedFolderID = event.currentTarget.value;
    setRenameTextID(TargetId);
    setRenameText(espnameselected);
     setExistRenameText(espnameselected);
    setWidthrenameText(getTextWidth(espnameselected));
    setShowRename(true);
    
  };
  
  const closeRename = () => {
    setShowRename(false);
     if (existRenameText !== renameText) {
        renameEspname();
    }
    
    
  };
  
  const renameEspname = () => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("integration_id", renameTextID);
    data.append("integration_name", renameText);
    //data.append("isCopywriter",isCopywriter());

    AxiosInstance.post('api/update-esp-name', data)
    	.then((response: AxiosResponse) => {
			if (response.data.success) {
			 refreshTrackingData();
			} else
            {
                toast.error(response.data.response_msg);
            }
    	})
  };
  
  
  const handleClickAwayEdit = () => {
    setShowRename(false);
    if (existRenameText !== renameText) {
        renameEspname();
    }
    
    
  };
  
  const refreshTrackingData = () => {
    AxiosInstance.get(
      `api/getEspData/${localStorage.getItem("company_id")}`
    ).then((response: AxiosResponse) => {
      setListData(response.data.usertable);
    });
  };
  
  const getIntegrationData = (rowId: any) => {
    AxiosInstance.get(
      `api/getEspDataById/${rowId}`
    ).then((response: AxiosResponse) => {
      if(response.data)
      {
        dispatch(updateFieldData({fieldName: "esp_name", fieldValue: response.data.usertable.p_name}));
        dispatch(updateFieldData({fieldName: "esp_ID", fieldValue: response.data.usertable.pc_id}));
        dispatch(updateFieldData({fieldName: "esp_action", fieldValue: 'editEspInit'}));
        setTimeout(history.push("/settings/editIntegration"), 5000);
      }
      
    });
  };
  
  /*const level = loggedInUser?.user_detail?.level;*/

 // eslint-disable-next-line
 const editIntegration = (rowid: any,providerId : any) => {
    const rowId = rowid;
    localStorage.setItem('integration_type', providerId);
    localStorage.setItem('editRId', rowId);
    getIntegrationData(rowId);
    //setTimeout(history.push("/settings/editIntegration"), 5000);
  };
  // eslint-disable-next-line

  const handlenewIntsubmit = () => {
    //
  };

  const handleIntegrationDelete = () => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("integration_id", IntegrationIdDel);
    data.append("company_id", localStorage.getItem("company_id"));

    AxiosInstance.post("api/delete-integration", data).then(
      (response: AxiosResponse) => {
        if (response.data.success === 1) {
          refreshTrackingData();
          setopenIntDeleteDialog(false);
        } else {
          toast.error(response.data.response_msg);
        }
      }
    );
  };

  // const getIntegrations = () => {
	// if(!isTrial()){
	// 	let integrations = parseInt(listData.length);
	// 	let max_integrations1=localStorage.getItem('max_integrations') as string;
	// 	let max_integrations = parseInt(max_integrations1);
	// 	if(integrations >=max_integrations && max_integrations!==0){
	// 		setAccessPopupStatus(true);
	// 	}else{
	// 		AxiosInstance.get(
	// 		  `api/getIntegrations/${localStorage.getItem("company_id")}`
	// 		).then((response: AxiosResponse) => {
			   
	// 		  dispatch(updateFieldData({fieldName: "esp_name", fieldValue: ''}));
	// 		  localStorage.removeItem("editRId")
	// 		  setIntegrationData(JSON.stringify(response.data.usertable));
			   
	// 		  setTimeout(() => {
	// 			setopenNewIntDialog(true);
	// 		  }, 400);
	// 		});
	// 	}
	// }else{
	// 	setTrialPopupStatus(true);
	// }
  // };


  const removeIntegration = (rowIndex: any) => {
    setopenIntDeleteDialog(true);
    setIntegrationIdDel(rowIndex);
  };
  useEffect(() => {
    setListData(data);
    
  }, [data]);

  useEffect(()=>{
    if(state?.tabManager?.AddNewIntegration){
      setIntegrationData(state?.tabManager?.setIntegrationData); 
    }
  // eslint-disable-next-line
  },[state?.tabManager?.AddNewIntegration]);

  return (
    <>
	 {/* <TrialPopup
	    trialPopupStatus={trialPopupStatus}		
		setTrialPopupStatus={setTrialPopupStatus}
      />
	  <AccessPopup
	    accessPopupStatus={accessPopupStatus}
		setAccessPopupStatus={setAccessPopupStatus}
		popupTitle="Integration"
      /> */}
      <DeleteIntegration
        handleIntegrationDelete={handleIntegrationDelete}
        open={openIntDeleteDialog}
        setOpen={setopenIntDeleteDialog}
      />

      <CreateNewIntegration
        IntegrationData={IntegrationData}
        handlenewIntsubmit={handlenewIntsubmit}
        open={openNewIntDialog}
        setOpen={setopenNewIntDialog}
      />

      {/* {!isMarketer() && (
        <Toolbar className={classes.toolbar}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography
              variant="h6"
              id="tableTitle"
              component="div"
              className={classes.titleh6}
            ></Typography>
            <Button
              className={classes.buttonStyle}
              variant="contained"
              color="primary"
              onClick={() => {
                setTimeout(() => {
                  getIntegrations();
                }, 50);
              }}
            >
              Add Integration
            </Button>
          </Box>
        </Toolbar>
      )} */}
      <TableContainer component={Paper} square className={`${classes.tableContainerStyleGuide} ${localStorage.getItem("trial_mode") === 'true' ? classes.hasTrialInfo: ""}`}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.tableThead}>
            <TableRow>
              <TableCell align="left">NAME</TableCell>
              <TableCell align="left">TYPE</TableCell>
               <TableCell align="left">STATUS</TableCell>
              {!isMarketer() && <TableCell align="left">ACTIONS</TableCell>}
            </TableRow>
          </TableHead>
          
          <TableBody>
            {listData &&
              listData.map((row: any, index: any) => (
                <TableRow key={row.name} className={classes.tableTbodyRow}>
                  <TableCell align="left" width="30%">
                    
                    <Box className={classes.styleLogo}><img  alt="" src={`${window.globalConfig.apiOrigin}images/emailproviders/${row.logo}`} /></Box>
                           {showRename && String(row.pc_id) === String(renameTextID) ? (
                          <ClickAwayListener
                            onClickAway={handleClickAwayEdit}
                          >
                            <Input
                              className={classes.fileEdit}
                              style={{width:widthrenameText}}
                              id={index}
                              value={renameText}
                              onChange={(e) => {
                                setRenameData({
                                  text: e.currentTarget.value,
                                  rowIndex: e.currentTarget.id,
                                });
                                setRenameText(e.currentTarget.value);
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <CreateIcon
                                    className={classes.fileEditIcon}
                                    onClick={closeRename}
                                  />
                                </InputAdornment>
                              }
                            />
                          </ClickAwayListener>
                        ) : (
                          
                          <Box><span  style={{marginRight:"5px",marginTop:"10px",display:"inline-block"}}>{row.pc_assign_name}</span></Box>
                        )}
                    
                    
                  </TableCell>

                  <TableCell align="left" width="30%">
                    {row.p_name}
                  </TableCell>
                  
                  <TableCell align="left" width="30%">
                     <Switch
                      checked={row.p_active === "1" ? true : false}
                      value={row.pc_id}
                      onChange={() => {
                                    changeEspStatus(row.pc_id,row.p_active);
                                  }}
					disabled={isTrial()}
                    />
                  </TableCell>
                  
                  
                  {!isMarketer() && (
                    <TableCell align="left" width="30%">
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState: any) => (
                          <React.Fragment>
                            <MoreVertIcon
                              className={classes.menuButton}
                              {...bindTrigger(popupState)}
                            />
                            <Menu {...bindMenu(popupState)}>
                              <div onClick={popupState.close}>
                                {<MenuItem
                                  value={row.id}
                                  onClick={() => {
                                    editIntegration(row.pc_id,row.pc_provider_id);
                                  }}
                                >
                                  <Typography>Edit</Typography>
                                </MenuItem>}

                              </div>
                              
                              
                              <div
                                  onClick={popupState.close}
                                  className={classes.menuButton}
                                >
                                  <MenuItem
                                    value={row.id}
                                     onClick={() => {
                                       openRename(row.pc_id,row.pc_assign_name);
                                  }}
								  disabled={isTrial()}
                                  >
                                    <Typography>Rename</Typography>
                                  </MenuItem>
                                </div>
                                
                                
                              
                                <div
                                  onClick={popupState.close}
                                  className={classes.deleteOption}
                                >
                                  <MenuItem
                                    value={row.id}
                                     onClick={() => {
                                       removeIntegration(row.pc_id);
                                  }}
								  disabled={isTrial()}
                                  >
                                    <Typography>Delete</Typography>
                                  </MenuItem>
                                </div>
                             
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default IntegrationList;
