import React, { useEffect } from "react";
import {TextField,Box,Button} from "@material-ui/core";
import { useStyles } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import { addField } from '../../../../redux/actions/styleGuideActions';
import Typography from "@material-ui/core/Typography";
import { AutoSaveTracking } from '../TrackingAutosave';
import { setFlashMessage } from "../../../../../utils/AuthUtility";
interface Props {
  isNotSettingsPage: any;
  history?:any;
}
const TrackingName: React.FC<Props> = ({ isNotSettingsPage,history }) => {
   const dispatch = useDispatch();
   
  var tracking_name = useSelector((state: any) => state.styleGuide.tracking_name);
 const classes = useStyles();
  
const handleInputChange = (Inputvalue : any) => {
      if(Inputvalue.target.value)
      {
        dispatch(addField("tracking_name", Inputvalue.target.value));        
        AutoSaveTracking();
      }
      else
      {
        dispatch(addField("tracking_name", ''));
      }
  };

  const reloadListing = () => {  
    localStorage.removeItem('TrackingrowId');
    localStorage.removeItem("TrackingResID");
    setTimeout(history.push('/settings/tracking'), 1000);
};

  useEffect(() => {
    const interval = setInterval(() => {
        let MsgContainer: HTMLDivElement | null = document.querySelector("#FlsMsgAlert");
  if (MsgContainer) {
      var dt = new Date();
      if(setFlashMessage(dt.toUTCString()) === 0)
              {
                  return true;
              }
              else if(setFlashMessage(dt.toUTCString())!== undefined)
              {
                  MsgContainer.innerHTML = 'All Saved ✔ '+setFlashMessage(dt.toUTCString());
  
              }
  }
  
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  

  return (
    <div className="App">

        <Box className={classes.headerInfo}>
          <Box className={classes.headerInfoFirst}>
            <strong>Name:</strong> 
            <TextField style={{marginBottom:"10px"}} 
                       onMouseOut={(e) => handleInputChange(e) } 
                       defaultValue = {tracking_name} 
                       key={tracking_name}
                       className={classes.inputTrackingName}
                        />
          </Box> 
          <Box className={classes.headerInfoSecond}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              // minWidth="15%"
              // style={{margin: 10}}
            >
              <Typography id="FlsMsgAlert"
              style={{
              fontSize: "14px",
              fontWeight: 600,
              display:"none",
              float:"left",
              }}            
              className={classes.classFlsMsgAlert}
              >
              </Typography>
              <Button variant="outlined" color="primary" onClick={() => { reloadListing(); }}>
                Close
              </Button>
            </Box>
          </Box>
        </Box>

 
       {/* <Typography style={{marginBottom:"10px"}} className={classes.heading} variant="h6">
                Tracking Name

      </Typography>
      
      <TextField style={{marginBottom:"10px"}} onMouseOut={(e) => handleInputChange(e)
                                        } defaultValue = {tracking_name} key={tracking_name} /> */}

    </div>
  );
};

export default TrackingName;
