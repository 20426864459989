import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { useStyles } from "../styles";
import ColorPicker from "../utilities/ColorPicker";

interface Props {}

const MainColors: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" className={classes.section}>
      <Typography className={classes.heading} variant="h6">
        Default Colours
      </Typography>
      <Typography variant="caption" className={classes.caption}>
        Set your default brand colours for the below components
      </Typography>
      <Box display="flex" className={classes.colorsList}>
        <Box className={classes.flexColumn}>
          <ColorPicker fieldName="email_color" />
          <Typography variant="caption" className={classes.iconButtonLabel}>
            Background
          </Typography>
        </Box>
        <Box className={classes.flexColumn}>
          <ColorPicker fieldName="primary_color" />
          <Typography variant="caption" className={classes.iconButtonLabel}>
            Module
          </Typography>
        </Box>
        <Box className={classes.flexColumn}>
          <ColorPicker fieldName="secondary_color" />
          <Typography variant="caption" className={classes.iconButtonLabel}>
            Card
          </Typography>
        </Box>        
        <Box className={classes.flexColumn}>          
          <ColorPicker fieldName="hedline_color" />
          <Typography variant="caption" className={classes.iconButtonLabel}>
            Headlines
          </Typography>
        </Box>        
        <Box className={classes.flexColumn}>          
          <ColorPicker fieldName="text_color" />
          <Typography variant="caption" className={classes.iconButtonLabel}>
            Body
          </Typography>
        </Box>        
        <Box className={classes.flexColumn}>          
          <ColorPicker fieldName="link_color" />
          <Typography variant="caption" className={classes.iconButtonLabel}>
            Hyperlink
          </Typography>
        </Box>        
        <Box className={classes.flexColumn}>          
          <ColorPicker fieldName="button_background_color" />
          <Typography variant="caption" className={classes.iconButtonLabel}>
            Button
          </Typography>
        </Box>        
        <Box className={classes.flexColumn}>
          <ColorPicker fieldName="button_text_color" />
          <Typography variant="caption" className={classes.iconButtonLabel}>
            Button Text
          </Typography>
        </Box>        
        <Box className={classes.flexColumn}>
          <ColorPicker fieldName="social_accent_color" />
          <Typography variant="caption" className={classes.iconButtonLabel}>
            Accent
          </Typography>
        </Box>{" "}
        {/* <Box className={classes.flexColumn}>
          <ColorPicker fieldName="header_text_color" />
          <Typography variant="caption" className={classes.iconButtonLabel}>
            Header Text
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
};

export default MainColors;
