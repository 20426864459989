import React, { useEffect } from "react";
import { useStyles } from "./style";
import Box from "@material-ui/core/Box";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import { CircularProgress } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';

import {
  Button,
  TextField,
  Typography,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";

interface Props {
  history?: any;
  onTextBoxSelect?: any;
  isIframe?: boolean;
  handleClose: any;
  FrontEndEspId:any;
  FrontEndcname:any;
  FrontendversionId:any;
  FrontEndSubject:any;
  FrontEndProfile:any;
}

const BrazeExport: React.FC<Props> = ({
  history,
   onTextBoxSelect,
  isIframe,
  handleClose,
  FrontEndEspId,
  FrontEndcname,
  FrontEndSubject,
  FrontendversionId,
  FrontEndProfile,
}) => {
    const classes = useStyles();
    const [Campaigntitle, setCampaigntitle] = React.useState<string>("");
    const [Subjectvalue, setSubjectvalue] = React.useState<string>(FrontEndSubject);
    const [campaignMode, setcampaignMode] = React.useState<string>("create");
    const [errorFirst, seterrorFirst] = React.useState(false);
    const [errorSecond, seterrorSecond] = React.useState(false);
    const [errorThird, seterrorThird] = React.useState(false);
    const [helperTextFirst, sethelperTextFirst] = React.useState<string>("");
    const [helperTextSecond, sethelperTextSecond] = React.useState<string>("");
    const [helperTextThird, sethelperTextThird] = React.useState<string>("");
    const [Profilevalue, setProfilevalue] = React.useState<string>(FrontEndProfile);
    const [updateDropdowndisable, setupdateDropdowndisable] = React.useState(true);
    const [isdisplay, setisdisplay] = React.useState(true);
    const [brazeDraftCampaignlist, setbrazeDraftCampaignlist] = React.useState<any>([]);
    const [IframeEspId, setIframeEspId] = React.useState<string>("");
    const [versionId, setversionId] = React.useState<string>("");
    const [isProcess, setisProcess] = React.useState(false);
    const [CampaignIdvalue, SetCampaignIdvalue] = React.useState<string>("");

  

    const [value, setValue] = React.useState('1');

  const handleChange = (event:any, newValue:any) => {
      if(newValue === '1')
      {
          CreateDraftCampaign();
      } else {
          UpdateDraftcampaign();
      }
    setValue(newValue);
  };
   function getParam(param : any){
        return new URLSearchParams(window.location.search).get(param);
    }
   

  isIframe = isIframe ? isIframe : false;
  
   const CreateDraftCampaign = () => {
    setcampaignMode('create');
    //event.preventDefault();
   
  };
 const UpdateDraftcampaign = () => {
    setcampaignMode('update');
    
    var sDraftFormData = require("form-data");
            var sDraftdata = new sDraftFormData();
            if(isIframe === true)
                    {
                      sDraftdata.append("espId",IframeEspId);
                    } else {
                        sDraftdata.append("espId",FrontEndEspId);
                    }
            
            AxiosInstance.post("api/v1/Integration/getBrazecampaignList", sDraftdata).then(
            (response: AxiosResponse) => {
                if(response.data.data)
                {
                    if(response.data.data.campaignlist.length>0)
                    {
                        setbrazeDraftCampaignlist(response.data.data.campaignlist);
                        setupdateDropdowndisable(false);
                        setisdisplay(false);

                    }
                }
            }
            );
      
            
   // event.preventDefault();
    
  };
  
   const closeContent = () => {
    onTextBoxSelect("close");
  };
  
const setCampaigntitlefun = (event: any) => {
    setCampaigntitle(event.currentTarget.value);
   
  };
  
  const setProfilefun = (event: any) => {
    setProfilevalue(event.currentTarget.value);
    
  };
  
    const setSubjectlinefun = (event: any) => {
    setSubjectvalue(event.currentTarget.value);
    
  };
 
 
  
  const setCampigndefautvalues = (event: any) => {
       setisProcess(true);
       setisdisplay(true);
        var sDraftFormData = require("form-data");
            var sDraftdata = new sDraftFormData();
            sDraftdata.append("CampaignId",event.target.value);
            if(isIframe === true)
                    {
                         sDraftdata.append("espId",IframeEspId);
                    } else {
                         sDraftdata.append("espId",FrontEndEspId);
                    }
            SetCampaignIdvalue(event.target.value);
            AxiosInstance.post("api/v1/Integration/getBrazecampaignDetail", sDraftdata).then(
            (response: AxiosResponse) => {
                if(response.data.status === true)
                {
                    if(response.data.data.draftcampaigndetail.length>0)
                    {
                        
                        setisProcess(false);
                        setisdisplay(false);
                    } else {
                        setisProcess(false);
                    }
                } else {
                    toast.error('Campaign details not found');
                    setisProcess(false);
                    setisdisplay(false);
                }
            }
            );
            //setisdisplay(false);
       
   
  };
  
  
  
   const openExportEspfun = (espIdvalue:any,versionId:any,cv_name:any,email_subject_value:any,Profilevalue:any) => {
        setCampaigntitle(cv_name);
        setSubjectvalue(email_subject_value);
        setIframeEspId(espIdvalue);
        setversionId(versionId);
        setProfilevalue(Profilevalue);
        
  };
  
const ExportHtml = () => {
    if (Campaigntitle === "") {
      seterrorFirst(true);
      sethelperTextFirst("Required field");
    } else if (Profilevalue === "") {
        seterrorSecond(true);
      sethelperTextSecond("Required field");
     
    } else if (Subjectvalue === "") {
       seterrorThird(true);
      sethelperTextThird("Required field");
    } 
    else {
        setisProcess(true);
                 var sFormData = require("form-data");
                    var sdata = new sFormData();
                    if(isIframe === true)
                    {
                         sdata.append("espId",IframeEspId);
                    } else {
                         sdata.append("espId",FrontEndEspId);
                    }
                   if(isIframe === true)
                    {
                         sdata.append("versionId",versionId);
                    } else {
                         sdata.append("versionId",FrontendversionId);
                    }
                    
                    sdata.append("Campaigntitle",Campaigntitle);
                    sdata.append("Subject",Subjectvalue);
                    sdata.append("Profile",Profilevalue);
                    sdata.append("CampaignMode",campaignMode);
                    sdata.append("CampaignId",CampaignIdvalue);
                    AxiosInstance.post("api/v1/Integration/createBrazeCampaign", sdata).then(
                    (response: AxiosResponse) => {
                        if(response.data.data)
                        {
                            if(response.data.data.draftresponse)
                            {
                                if(isIframe === true)
                                {
                                    setisProcess(false);
                                    onTextBoxSelect("success");

                                } else {
                                    handleClose();
                                    toast.success('Campaign Successfully exported to mailjet');
                                    setisProcess(false);
                                }
                               
                            } else {
                                if(isIframe === true)
                                {
                                    setisProcess(false);
                                    onTextBoxSelect("Failed");

                                } else {
                                    toast.error('Something wrong please try again');
                                    handleClose();
                                    setisProcess(false);
                                }
                               
                            }
                        }
                    }
                    );
                    
    }
  };

  useEffect(() => {
      setCampaigntitle(FrontEndcname);
        if(getParam('selectedEspId')!==null && getParam('selectedEspId')!==''
        && getParam('cv_name')!==null)
        {
            if(isIframe === true)
            {
               openExportEspfun(getParam('selectedEspId'),getParam('versionId'),
               getParam('cv_name'),getParam('email_subject_value'),getParam('preview'));
            }
        }
     
       // eslint-disable-next-line 
  }, [isIframe]);


  return (
    <Box className={classes.componentHeaderBraze}>
      <Box className={classes.componentInnerHeader} style={{ width:"100%" }}>
        <Typography variant="h5" style={{ marginBottom:"8px" }}>Export to Braze</Typography>
        <Typography style={{ marginBottom:"8px" }}>
          Enter the details below to export your email to Braze
        </Typography>
       
                <TabContext value={value} >
            <TabList className={classes.Tabcontentarea} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Create campaign" value="1" />
              <Tab label="Update campaign" value="2" />
            </TabList>
        </TabContext>
        
        
        {campaignMode === 'update' && (
          <Box className={classes.InputField} style={{
              position:"relative"
              
            }}>
            <TextField
              error={errorThird}
              helperText={helperTextThird}
              select
              fullWidth
              onChange={setCampigndefautvalues}
              disabled={updateDropdowndisable}
              defaultValue=""
              variant="outlined"
              label="Select Campaign"
               InputProps={{
					  endAdornment: (
                       <CircularProgress
             style={{
              marginTop: 10,
              width: 18,
              height: 18,
              color: "rgba(0, 0, 0, 0.26)",
              display: isdisplay ? "block" : "none",position:"absolute",top:"10px",right:"40px"
            }}
          />
                      ),
                    }}
            >
              {brazeDraftCampaignlist.map((Item: any, index: any) => {
                return <MenuItem value={Item.ID}>{Item.Title}</MenuItem>;
              })}
            </TextField>
            
          </Box>
        )}
       

        <Box className={classes.InputField}>
          <TextField
            name="Campaign_title"
            autoFocus
            error={errorFirst}
            helperText={helperTextFirst}
            fullWidth
            defaultValue={Campaigntitle}
            key={Campaigntitle}
            onChange={setCampaigntitlefun}
            variant="outlined"
            label="Campaign Name"
            //className={classes.clsNewTemplateName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
        </Box>

        <Box className={classes.InputField}>
          <TextField
            name="Preview"
            autoFocus
            error={errorSecond}
            helperText={helperTextSecond}
            fullWidth
            defaultValue={Profilevalue}
            key={Profilevalue}
            onChange={setProfilefun}
            variant="outlined"
            label="Preview"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
        </Box>

        <Box className={classes.InputField}>
          <TextField
            name="Subject"
            autoFocus
            error={errorThird}
            helperText={helperTextThird}
            fullWidth
            defaultValue={Subjectvalue}
            key={Subjectvalue}
            onChange={setSubjectlinefun}
            variant="outlined"
            label="Subject"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
        </Box>

       

        <Box className={classes.gptFooter}>
          {isIframe === true ? (
            <Button
              onClick={() => {
                closeContent();
              }}
              size="large"
              style={{ opacity: "0.6", marginRight: 16 }}
            >
              CANCEL
            </Button>
          ) : (
            <Button
              onClick={() => {
                handleClose();
              }}
              size="large"
              style={{ opacity: "0.6", marginRight: 16 }}
            >
              CANCEL
            </Button>
          )}

          {isProcess === true ? (
          <CircularProgress
            style={{
              marginTop: 10,
              width: 18,
              height: 18,
              color: "rgba(0, 0, 0, 0.26)",
              display:"block"
            }}
          />
        ) : (
           <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.insertbut}
              onClick={() => {
                ExportHtml();
              }}
            >
              EXPORT
            </Button>
         
        )}
        </Box>
      </Box>
    </Box>
  );
};

export default BrazeExport;
