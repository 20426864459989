import React, { Dispatch, SetStateAction,useEffect,useState } from "react";
import { addField } from "../../../redux/actions/styleGuideActions";
import {
  Dialog,
  Typography,
  Button,
  TextField,
  MenuItem,
  Box,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
   open: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleStyleDelete:any
  setDesigbeforedelete:any
  assignCampfound:any
  defaultStyle:any
  listData:any
  defaultStyleId:any
}
const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: 350,
  },
  text: {
    margin: "0",
    paddingBottom: "1rem",
    borderBottom: "1px solid #c4c4c4",
  },
  textColor:{
      color:"red"
  },
  loader:{
      width:"28px",
      height:"28px"
  },
  topdiv:{
      paddingBottom:"0px",
      paddingLeft:"24px"
  },
  mgtop:{
      marginTop:"25px"
  },
  disabledclass:{
      pointerEvents:"none",
      backgroundColor:"#D0D0D0"
      
  }
}));
const DeleteStyleGuide: React.FC<Props> = ({ open, setOpen,handleStyleDelete,setDesigbeforedelete,assignCampfound,defaultStyle,listData,defaultStyleId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [newstyleguideId, setnewstyleguideId] = useState<any>(null);
  const [deleteflag, setdeleteflag] = useState<any>(null);

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleConfirm = () => {
      setdeleteflag(0);
      if(newstyleguideId)
      {
        setDesigbeforedelete(newstyleguideId);
  } else {
      setDesigbeforedelete(defaultStyleId);
  }
  };
  
   const handleChange = (event: any) => {
   setnewstyleguideId(event.target.value);
  };
  
   useEffect(() => {
  dispatch(addField("editclicked", "true"));
  // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
  setdeleteflag(1); 
  }, [open]);
  

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.topdiv} id="form-dialog-title">Delete Design System</DialogTitle>
        <DialogContent>
          <Box className={classes.root}>
            {assignCampfound ? (
              <Typography className={classes.text}>
              The Design system <b>{defaultStyle}</b> will be <b className={classes.textColor}>deleted</b> and any existing emails assigned
              to this Design system will updated with the below selection.
              
              {listData && (
            <Box className={classes.root}>
            <TextField
            select
            fullWidth											
            name="userRoles"
            id="userRoles"
            defaultValue={defaultStyleId}
            onChange={handleChange}
            variant="outlined"            
            label="Design Systems"
            className={classes.mgtop}
            >
            {listData.map((Item :any) => {
				if(Item.id>0 && defaultStyle!==Item.styleguide_name){
					return (
						<MenuItem value={Item.id}>{Item.styleguide_name}</MenuItem>
					)
				}else{
					return ('');
				}
				
			  
            })}
            </TextField>
            </Box>
            )}
            
            
            </Typography>
            ): (
              <Typography className={classes.text}>
             The Design System <b>{defaultStyle}</b> will be <b className={classes.textColor}>deleted</b>.
            </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        {deleteflag===1 ? (
        <Button  variant="contained" disableElevation onClick={handleConfirm} color="primary">
        Confirm 
        </Button>

        ): (
        <Button className={classes.disabledclass} variant="contained" disableElevation onClick={handleConfirm} color="primary">
        Confirm 
        </Button>
        )}
         
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteStyleGuide;
