import React, { useState } from "react";
import { useStyles } from "../styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import FileListSkleton from "./skleton/fileList";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Iframe from "react-iframe";
import Paper from "@material-ui/core/Paper";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TemplateImage from './TemplateImage';
import {
  Element,
  animateScroll as scroll,
} from "react-scroll";
import AxiosInstance from "../../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { getUserFromToken, redirectTo } from "../../../../../../utils/AuthUtility";
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import {Standard,Interactive } from '../../../../../assets/svgs/SvgDynamic';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

var interval: any = [];
interface Props {
  templates: {
    tm_id: string;
    tm_name?: string;
    tm_active?: string;
    tm_company_id?: string;
    tm_date_creation?: string;
    tm_deleted?: string;
    tm_delete_time?: string;
    tm_is_gallery_adapted?: string;
    tm_image?: string;
  }[];
  setSelectedTemplateID: any;
  screenShotItem: any;
  renderList: {
    tm_id: string;
    tm_name?: string;
    tm_active?: string;
    tm_company_id?: string;
    tm_date_creation?: string;
    tm_deleted?: string;
    tm_delete_time?: string;
    tm_is_gallery_adapted?: string;
    tm_image?: string;
  }[];
  setScreenShotItem: any;
  history: any;
  setRenderedLsit: any;
}

const FileList: React.FC<Props> = ({
  templates,
  setSelectedTemplateID,
  setScreenShotItem,
  screenShotItem,
  history,
  renderList,
  setRenderedLsit,
}) => {
  const classes = useStyles();

  const [selectedCampID, setSelectedCampID] = useState<string>("false");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const loggedInUser = getUserFromToken();
  const handleClickBox = (event: any) => {
  	const parent = event.target.closest(".template-select-container");
    if (parent.id === selectedCampID) {
      setSelectedCampID("");
      setSelectedTemplateID(0);
    } else {
      setSelectedCampID(parent.id);
      setSelectedTemplateID(parent.id);
    }
  };

  // screenshot api releted functions
 
  /* const getCampScreenShots = (screenShotItem: any) => {
	setIsLoading(false);
	setRenderedLsit(templates);
	
	AxiosInstance.get('api/v1/campaign/screenshot', {
		params: {
			items: JSON.stringify(screenShotItem)
		}
	}).then((response: AxiosResponse) => {
		if (response.data.status === true) {
		  templates.forEach((element) => {
			const result = response.data.data.screens.find(
			  (word: any) => word.id === element.tm_id
			);
		   element.tm_image = result.path;
		  });
		 
		}
		setIsLoading(false);
		setRenderedLsit(templates);
	})
	
  };*/
  const assignScreenshot = () => {
    setScreenShotItem([]);
    templates.forEach((element) => {
      screenShotItem.push({
        id: element.tm_id,
        type: "template",
        vw: 600,
        url: `template/template_screenshot/${element.tm_id}?mute=true`,
      });
    });
	setRenderedLsit(templates);
  };

  // preview section
  const [openPreview, setOpenPreview] = React.useState(false);
  const [viewMenu, setViewMenu] = React.useState(false);
  const [viewMenuList, setViewMenuList] = React.useState("");
  const [previewURL, setPreviewURL] = React.useState("");
  const [previewName, setPreviewName] = React.useState("");
  // eslint-disable-next-line
  const [darkURL, setDarkURL] = React.useState("");
  // eslint-disable-next-line
  const [lightURL, setLightURL] = React.useState("");
  const [isDark, setIsDark] = React.useState(false);

  const handleClickOpenPreview = (event: any) => {
    const selectedCampaignID = event.currentTarget.id;
    setPreviewName(event.currentTarget.dataset.tempName);   
    var FormData = require("form-data");
    var data = new FormData();
    data.append("id", selectedCampaignID);
    document.body.setAttribute("oncontextmenu","return false;");
    AxiosInstance.post('api/v1/Campaign/template_screenshot', data)
		.then((response: AxiosResponse) => {
			if (response.data.status === true) {
			  setPreviewURL(response.data.data);
				if(response.data.viewenabled === true){
					setViewMenu(true);
					setViewMenuList(response.data.viewmode);
					setViewFormats(response.data.viewmode);
					var url = new URL(response.data.data);
					if(!url.searchParams.has("modeView")){
						url.searchParams.append('modeView', response.data.viewmode);
					}else{
						url.searchParams.set('modeView', response.data.viewmode);
					}
					setPreviewURL(url.toString());
				}
			  setOpenPreview(true);
        setDarkURL(response.data.data+"?modePreview=dark");
        setLightURL(response.data.data);
        if(response?.data?.is_dark) setIsDark(response.data?.is_dark);
			}
		})
  };

  const handleViewChange = (event: any) => {
	setViewFormats(event.target.value);
	var url = new URL(previewURL);
	if(!url.searchParams.has("modeView")){
		url.searchParams.append('modeView', event.target.value);
	}else{
		url.searchParams.set('modeView', event.target.value);
	}
    setPreviewURL(url.toString());
    event.persist();
  };


  const [mode, setMode] = React.useState("light");
  const handleMode = (event:any, newFormats:any) => {
    var url = new URL(previewURL);
    if (newFormats !== null) {
      setMode(newFormats);
      if(newFormats === "dark"){ 
        if(!url.searchParams.has("modePreview")){
          url.searchParams.append('modePreview', 'dark');
        } else{
          url.searchParams.set('modePreview', 'dark');
        }
          setPreviewURL(url.toString());

        // setPreviewURL(darkURL);
      } else  {
          if(url.searchParams.has("modePreview")){
            url.searchParams.delete("modePreview");
          }
        setPreviewURL(url.toString());
          // setPreviewURL(lightURL);
        }
    }
  };




  const handleClosePreview = () => {
    setOpenPreview(false);
	setViewMenu(false);
	setViewMenuList("");
    document.body.removeAttribute("oncontextmenu");
    setFormats("desktop");

    setMode("light");
	setViewFormats('standard');

  };
  React.useEffect(() => {
    assignScreenshot();
    //getCampScreenShots(screenShotItem);
    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates]);
  const [formats, setFormats] = React.useState("desktop");
  const [viewFormats, setViewFormats] = React.useState("standard");
  const handleFormat = (event:any, newFormats:any) => {
    if (newFormats !== null) {
      setFormats(newFormats);
    }
  };
  
  const defaultstyle = {
        border: "3px solid #057CFF",
        cursor: "pointer",
        borderRadius: "5px",
        marginBottom: "2rem",
        boxShadow: ' 0px 1px 2px rgba(0, 0, 0, 0.32)',
        // height: '407px',
        maxWidth: "248px",
        "& $hoverBox": {
        display: "block",
        }
    };
    
     const borderdisable = {};
React.useEffect(()=>{
   
	  
  // eslint-disable-next-line
  },[viewFormats]);
  return (
    <>
      {isLoading === true ? (
        <FileListSkleton />
      ) : (
        <>
          {/* <Grid container alignItems="center" justify="center" spacing={2} > */}
          {renderList.map((option: any) => (
            <Grid item xs={6} sm={4} md key={option.tm_id}>
              <div
                id={option.tm_id}
                onClick={handleClickBox}
                style={'blank' === option.tm_id && selectedCampID==='false' ? defaultstyle : borderdisable}
                className={
					(selectedCampID === option.tm_id
						? classes.selectedNewCampaignDiv
						: classes.newCampaignDiv) + " template-select-container"
                }
              >
                <Card
                  elevation={1}
                  key={option.tm_id}
                  // onMouseOver={() => {
                  //   scroll.scrollToBottom({
                  //     smooth: "linear",
                  //     ignoreCancelEvents: false,
                  //     duration: 1000,
                  //     containerId: "scrollImage-" + option.tm_id,
                  //   })
                  // }}
                  onMouseOver={() => {
                    const container = document.getElementById(
                      "scrollImage-" + option.tm_id
                    );
                    interval.push(
                      setInterval(() => {
                        container!.scrollTop = container!.scrollTop + 1;
                      }, 7)
                    );
                  }}
                  onMouseLeave={() => {
                    interval.forEach((element: any) => {
                      clearInterval(element);
                    });
                    interval = [];
                    scroll.scrollToTop({
                      duration: 200,
                      containerId: "scrollImage-" + option.tm_id,
                    });
                  }}
                  className={classes.newCampaignCard}
                >
  
						<Element
						  name={"scrollImage-" + option.tm_id}
						  id={"scrollImage-" + option.tm_id}
						  className={classes.scrollImageContainer}
						>
						{option.tm_image != null ? (
							<img
								id={option.tm_id}
								src={option.tm_image}
								className={classes.scrollEffectImage}
								title=""
								alt=""
							/>
							 ) : (
							<TemplateImage
							  thumb={option.tm_image}
							  classes={classes.scrollEffectImage}
							  classeswave={classes.newCampaignCardImage}
							  tempId={option.tm_id}
							/> 
              )}
              
						</Element>
						<div style={{ width: "100%" }}>
							<Box
								display="flex"
								height="50px"
								borderRadius={2}
								alignItems="center"
								justifyContent="space-between"
							>
								<Box flexGrow={1} paddingLeft={1}>
									<Typography className={classes.boxText}>
										{option.tm_name}
									</Typography>
								</Box>
								<VisibilityIcon
									id={option.tm_id}
                  data-tempName={option.tm_name}
									onClick={handleClickOpenPreview}
									className={classes.newCampaignEye}
								/>
							</Box>
						</div>
					
                </Card>
              </div>
            </Grid>
          ))}
          <Grid item md></Grid>
          <Grid item md></Grid>
          {/* </Grid> */}
          <Dialog fullScreen open={openPreview} onClose={handleClosePreview}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              component={Paper}
              className={classes.formGrid}
              elevation={4}
              square
            >
              <div className={`${classes.paper} ${classes.PreviewContainer}`}>
                <div className={classes.previewHeader}>
                  <Box display="flex">
                    <Box p={1} flexGrow={1} display="flex" alignItems="center">
                    <Box className={classes.previewImgLogo} style={{ cursor: "pointer" }} onClick={() => redirectTo('/')} >
                        <img
                          alt="logo"
                          style={{ maxHeight:34,maxWidth: 66 }}                  
                          src={loggedInUser?.user_detail?.CompanyImage+"?time=" + new Date().getTime()}
                        />  
                      </Box>
                      <Typography variant="subtitle1" style={{color: "#5D7186"}}>
                        {previewName}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                    <Grid container>
                      <Grid style={{display:"flex"}}>

                        {isDark && <Box style={{marginRight:20}}>                      

                          <ToggleButtonGroup
                            size="small"
                            value={mode}
                            exclusive
                            onChange={handleMode}
                            aria-label="light"
                          >
                            <ToggleButton value="light" aria-label="light" classes={{ selected: classes.ToggleSelected}}  >
                              <WbSunnyIcon color={mode === "light" ? "primary" : undefined} />
                            </ToggleButton>
                            <ToggleButton value="dark" aria-label="dark" classes={{ selected: classes.ToggleSelected}}>
                              <Brightness3Icon color={mode === "dark" ? "primary" : undefined} />
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Box>}
						  <Box style={{marginRight:20}}> 
							{viewMenu === true && (
							 <ToggleButtonGroup
								size="small"
								value={formats}
								exclusive
								aria-label="desktop"
							  >
							<TextField
								select
								fullWidth
								style={{width:165}}
								InputProps={{ style: { height: 40 } }}
								name="userRoles"
								id="userRoles"
								variant="outlined"
								label="View"
								defaultValue={viewFormats}
								SelectProps={{
								multiple: false,
								//value: selectedValue,
								onChange: handleViewChange
								}}
								>
								<MenuItem value="fallback">
									<ListItemIcon style={{minWidth:35 ,float:'left'}}>
									<RadioButtonUnchecked color={viewFormats === "fallback" ? "primary" : undefined}/>
								  </ListItemIcon>
								  <ListItemText primary="Fallback" style={{color: (viewFormats === "fallback" ? "#057CFF" : '')}}/>
								</MenuItem>
								<MenuItem value="standard" selected={true}>
								  <ListItemIcon style={{minWidth:35 ,float:'left'}}>
									<Standard color={viewFormats === "standard" ? "#057CFF" : ''}/> 
								  </ListItemIcon>
								  <ListItemText primary="Standard" style={{color: (viewFormats === "standard" ? "#057CFF" : '')}}/>
								  </MenuItem>
								{viewMenuList ==='interactive' && (
								<MenuItem value="interactive">
								  <ListItemIcon style={{minWidth:35 ,float:'left'}}>
									<Interactive color={viewFormats === "interactive" ? "#057CFF" : ''}/> 
								  </ListItemIcon>
								  <ListItemText primary="Interactive" style={{color: (viewFormats === "interactive" ? "#057CFF" : '')}}/>
								</MenuItem>
								)}
								
								</TextField>
							  </ToggleButtonGroup>
							)}
						</Box>

                        <div >
                          <ToggleButtonGroup
                            size="small"
                            value={formats}
                            exclusive
                            onChange={handleFormat}
                            aria-label="desktop"
                          >
                            <ToggleButton value="desktop" aria-label="desktop" classes={{ selected: classes.ToggleSelected}}  >
                              <DesktopMacIcon color={formats === "desktop" ? "primary" : undefined} />
                            </ToggleButton>
                            <ToggleButton value="table" aria-label="table" classes={{ selected: classes.ToggleSelected}}>
                              <TabletMacIcon color={formats === "table" ? "primary" : undefined} />
                            </ToggleButton>
                            <ToggleButton value="phone" aria-label="phone" classes={{ selected: classes.ToggleSelected}}>
                              <PhoneIphoneIcon color={formats === "phone" ? "primary" : undefined} />
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </div>
                      </Grid>
                    </Grid>
                    </Box>
                    <Box p={1}>
                      <CloseIcon
                        className={classes.previewPointer}
                        onClick={handleClosePreview}
                      />
                    </Box>
                  </Box>
                </div>
                <Box display="flex" justifyContent="center" className={classes.previewContentBody}>
                  <Box width="100%" className={classes.campaign_preview_body}>
                    {/* <div className={classes.campaign_preview_window_header_dsktop}>
                      <FiberManualRecordIcon className={classes.previewDOT} />
                      <FiberManualRecordIcon className={classes.previewDOT} />
                      <FiberManualRecordIcon className={classes.previewDOT} />
                    </div> */}
                    <div className={classes.campaign_preview_window_content_body}>
                      <Iframe
                        className={`${classes.iFrame}
                        ${formats === "desktop" ? classes.previewDesktopSelected : ""}
                        ${formats === "table" ? classes.previewTableSelected : ""}
                        ${formats === "phone" ? classes.previewPhoneSelected : ""}
                        `}
                        url={previewURL}
                        height="100%"
                        width="100%"
                      ></Iframe>
                    </div>
                  </Box>
                </Box>
              </div>
            </Grid>
          </Dialog>
        </>
      )}
    </>
  );
};

export default FileList;

// empty folder
/* <Grid
            item
            container
            alignItems="center"
            justify="center"
            xs={6}
            sm={4}
            md={4}
          >
            <div
              id="blank"
              onClick={handleClickCheckbox}
              className={
                selectedCampID === "blank"
                  ? classes.selectedNewCampaignDiv
                  : classes.newCampaignDiv
              }
            >
              <img
                id="blank"
                onClick={handleClickCheckbox}
                src="https://edm-ci3.mashrmail.com/images/start-from-scratch.jpg"
                className={classes.scrollEffectImage}
                title="Live from space album cover"
                alt=""
              />
              <div style={{ width: "100%" }}>
                <Box display="flex" style={{ height: "3rem" }}>
                  <Box flexGrow={1}>
                    <Typography className={classes.boxText}>
                      Blank Canvas
                    </Typography>
                  </Box>
                  <Box>
                    <div>
                      <Checkbox
                        id="blank"
                        // className={classes.checkBoxRoot}
                        disableRipple
                        color="primary"
                        inputProps={{ "aria-label": "decorative checkbox" }}
                        checked={selectedCampID === "blank" ? true : false}
                      />
                    </div>
                  </Box>
                </Box>
              </div>
            </div>
          </Grid> */
