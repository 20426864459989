export const cardImageProvider = (accountNumber:any)=>{
  //start without knowing the credit card type
  var result = null;

  //first check for MasterCard
  if (/^5[1-5]/.test(accountNumber))
  {
    result = "mastercard";
  }

  //then check for Visa
  else if (/^4/.test(accountNumber))
  {
    result = "visa02";
  }

  //then check for AmEx
  else if (/^3[47]/.test(accountNumber))
  {
    result = "americanexpress";
  }
  else if (/^3(?:0[0-5])/.test(accountNumber))
  {
    result = "dinersclub";
  }
  else if (/^(5018|5020|5038|6304|6759|6761|6763)/.test(accountNumber))
  {
    result = "maestro";
  }else if (/^65[4-9]/.test(accountNumber))
  {
    result = "discover";
  }

  return result;

}