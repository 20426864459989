import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CreateNewFolderOutlinedIcon from "@material-ui/icons/CreateNewFolderOutlined";

interface Props {}

const useStyles = makeStyles((theme) => ({
  fileTree: {
    
    border: "1px solid #E1E1E1",
  },
  filetreeHeader: {
    padding: ".5rem",
    borderBottom: "1px solid #E1E1E1",
  },
  filetreeInnerHeader: {
    display: "flex",
    verticalAlign: "middle",
    cursor: "pointer",
    padding: ".25rem",
    color: "#238cff",
    "&:hover": {
      background: "#DBECFF",
      borderRadius: "4px",
    },
  },
  filetreeName: {
    paddingLeft: ".5rem",
  },
  cardImage: {
    height: "20rem",
  },
}));

const FileTreeSkleton: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <>
      <Card elevation={0} className={classes.fileTree} style={{ width: '97.5%'}}>
        <div className={classes.filetreeHeader}>
          <div className={classes.filetreeInnerHeader}>
            <CreateNewFolderOutlinedIcon />
            <Typography className={classes.filetreeName}>New Folder</Typography>
          </div>
        </div>
        <CardContent className={classes.cardImage}></CardContent>
      </Card>
    </>
  );
};

export default FileTreeSkleton;
