import React, { Dispatch, SetStateAction,useEffect,useState } from "react";
//import { addField } from "../../../redux/actions/styleGuideActions";
import {
  Dialog,
  Typography,
  Button,
  Box,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
   open: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
  totaldesign:any;
  handleStyleUpdate:any
  assignCampfound:any
}
const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: 350,
  },
  text: {
    margin: "0",
    paddingBottom: "1rem",
    borderBottom: "1px solid #c4c4c4",
  },
  textColor:{
      color:"red"
  },
  loader:{
      width:"28px",
      height:"28px"
  },
  topdiv:{
      paddingBottom:"0px",
      paddingLeft:"24px"
  },
  mgtop:{
      marginTop:"25px"
  },
  disabledclass:{
      pointerEvents:"none",
      backgroundColor:"#D0D0D0"
      
  }
}));
const UpdateStyleGuideConfirm: React.FC<Props> = ({ open, setOpen,handleStyleUpdate,totaldesign,assignCampfound }) => {
  const classes = useStyles();
  //const [newstyleguideId, setnewstyleguideId] = useState<any>(null);
 const [updateflag, setupdateflag] = useState<any>(null);
 const [popupmsg, setpopupmsg] = useState<any>(null);
 //const [styleguidestatus, setstyleguidestatus] = useState<any>(null);

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleConfirm = () => {
      setupdateflag(0);
      handleStyleUpdate();
  };
    useEffect(() => {
        
    /*var styleId: any = null;
    if(localStorage.getItem("styleGuideResID")===
    '' || localStorage.getItem("styleGuideResID")===null)
    {
        styleId=localStorage.getItem("rowId")
    }
    else
    {
        styleId = localStorage.getItem("styleGuideResID");
    }*/
    //setstyleguidestatus(styleId);
    setupdateflag(1);
    var textdynamic ='';
   if(totaldesign>0)
   {       
    textdynamic = 'Please confirm you would like to update this Design System. These changes will be applied to '+totaldesign+' emails currently using this Design System.';
   } else {
       textdynamic = 'Please confirm you would like to update this Design System.';
   }
    setpopupmsg(textdynamic);
  }, [open,totaldesign]);
   /*const handleChange = (event: any) => {
  // setnewstyleguideId(event.target.value);
  };*/

  return (
    <div>
      <Dialog
      PaperProps={{ style: {
    width: '600px'
  }}}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.topdiv} id="form-dialog-title">Update Design System</DialogTitle>
        <DialogContent>
          <Box className={classes.root}>
           
            <Typography>
                {updateflag===1 ? (
                <Typography className={classes.text}>
                    {popupmsg}
                </Typography>
                ) : (
                <Typography className={classes.text}>
                Please wait while we are processing your update request
                </Typography>
                )}
            </Typography>

            
          </Box>
        </DialogContent>
        <DialogActions>
          <Button className={'Confirmcancelbtn'} onClick={handleClose} color="primary">
            Cancel
          </Button>
        {updateflag===1 ? (
        <Button  variant="contained" disableElevation onClick={handleConfirm} color="primary">
        Confirm 
        </Button>

        ): (
        <Button className={classes.disabledclass} variant="contained" disableElevation onClick={handleConfirm} color="primary">
        Confirm 
        </Button>
        )}
         
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UpdateStyleGuideConfirm;
