import React, { Dispatch, SetStateAction } from "react";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DropzoneArea } from "material-ui-dropzone";
import { toast } from "react-toastify";
import AxiosInstance from "../../../../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";

interface Props {
  open: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
  history: any;
  refreshCampImages:any
  openImageID:number;
  imgExt:any;
  imgWidth:any;
  imgHeight:any;
  buttonDisabled:any;
  setButtonDisabled:Dispatch<SetStateAction<boolean>>;
  setImageUpdated:Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: 350,
  },
  avatar: {
    width: 80,
    height: 80,
  },
  treeViewDiv: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  treeViewFolder: {
    color: "#e0e0e0",
  },
  treeViewItem: {
    borderRadius: "5px",
  },
  divRoot: {
    "&.Mui-selected  > .MuiTreeItem-content": {
      "& $tickIcon": {
        color: "#73a8e2",
        paddingLeft: "5rem",
        display: "block",
      },
      "& $treeViewFolder": {
        color: "#73a8e2",
      },
    },
  },
  label: {
    fontWeight: "inherit",
    color: "inherit",
    borderRadius: theme.spacing(0.5),
    marginTop: ".3rem",
  },
  tickIcon: {
    display: "none",
  },
  folderHeight: {
    height: "15rem",
    overflow: "scroll",
    borderBottom: "1px solid #c4c4c4",
    paddingRight: "1rem",
  },
  dropDown: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  actionButton: {
    paddingRight: "1.5rem",
  },
  textColor: {
    color: "#057CFF",
  },
  textColor1: {
    fontSize: '13px',
	color: "#057CFF",
  },
  labelClass: {
    padding: "0px 24px",
	fontSize: '1rem',
  },
}));

const UpdateImage: React.FC<Props> = ({
  open,
  setOpen,
  history,
  refreshCampImages,
  openImageID,
  imgExt,
  imgWidth,
  imgHeight,
  buttonDisabled,
  setButtonDisabled,
  setImageUpdated
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const setFolderToNewFolder = (event: any) => {
    var FormData = require("form-data");
    var data = new FormData();
    if (selectedImage) {
      data.append("qqfile", selectedImage.file);
    }
    data.append("origin_id", openImageID);
	setButtonDisabled(true);
    AxiosInstance.post('api/v1/Contents/add_gallery_new_image', data)
     .then((response: AxiosResponse) => {
        if (response.data.status === true) {
			var data1 = new FormData();
			data1.append("dir", response.data.dir);
			AxiosInstance.post('api/v1/campaign/delete_init', data1);
          setOpen(false);
		  if(!openImageID){
			refreshCampImages();
		  }
		   if(openImageID){ //
			setImageUpdated(true);
		   }
        }
      })
  };
  

  const [selectedImage, setSelectedImage] = React.useState<any>(null);
  
  const handleUploadClick = (files:any) => {
    if(files[0]){
      var file = files[0];
	  setButtonDisabled(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
          setSelectedImage({ file, src: reader.result });
        };
		var img: HTMLImageElement;
		img = document.createElement("img");
		var fileExt = file.name.split('.').pop().toLowerCase();
		img.onload = function () {
			if(img.width!==parseInt(imgWidth) || img.height!==parseInt(imgHeight) || fileExt!==imgExt.toLowerCase()){
				toast.error("Image type or dimension is not correct");
			}else{
				setButtonDisabled(false);
			}
		};

		img.src = URL.createObjectURL(file);
		
		}
  };
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Image</DialogTitle>
		<Typography className={classes.labelClass}>Choose <b className={classes.textColor}>{imgExt}</b> image with dimension <b className={classes.textColor1}> {imgWidth}X{imgHeight} </b></Typography>
        <DialogContent>
          <DropzoneArea
            dropzoneClass={classes.dropDown}
            acceptedFiles={["image/*"]}
			filesLimit={1}
			showAlerts={false}
            dropzoneText={"Drag media or Click here to Upload"}
            onChange={(files) => handleUploadClick(files)}
          />
        </DialogContent>
        <DialogActions className={classes.actionButton}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={setFolderToNewFolder}
            color="primary"
			disabled={buttonDisabled}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UpdateImage;
