import React, { useState } from "react";
import { useStyles } from "../styles";
import SimpleTree from "../../../../../../common/components/SimpleTree";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import TreeView from "@material-ui/lab/TreeView";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { toast } from "react-toastify";
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import CreateNewFolder from "../../campaignList/components/FolderTree/utilities/CreateNewFolder";
import CreateNewFolderOutlinedIcon from "@material-ui/icons/CreateNewFolderOutlined";
import { Container } from "@material-ui/core";
import AxiosInstance from "../../../../../../utils/AxiosInstance";
import {isCopywriter} from "../../../../../../utils/AuthUtility";
import {MenuItem } from "@material-ui/core";
import {AxiosResponse} from "axios";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import moment from "moment";

interface Props {
  campFolders: RenderTree;
  Styleguidedropdown:[];
  defaultStyle:any;
  selectedTemplateID : any;
  brandID: string;
  totalLength:number;
  history: any;
  categoryList: {
    cat_id: string;
    cat_title: string;
    status?: string;
  }[];
  categoryOverlay: boolean;
  setCampFolders: any;
  getCampFolders: any;
  setSelectedList: any;
  selectedList: string;
  folderDropDown: boolean;
  closeFolderDropDown: any;
}
interface RenderTree {
  id: string;
  name?: string;
  type?: string;
  folder_name?: string;
  parent_id?: string;
  company_id?: string;
  state?: {
    opened: boolean;
  };
  childes?: RenderTree[];
}

const NewCampaign: React.FC<Props> = ({
  campFolders,
  defaultStyle,
  totalLength,
  Styleguidedropdown,
  selectedTemplateID,
  brandID,
  history,
  categoryList,
  categoryOverlay,
  getCampFolders,
  setSelectedList,
  folderDropDown,
  closeFolderDropDown,
}) => {
  const classes = useStyles();
  const [showFolder, setShowFolder] = useState(false);
  const [folderName, setFolderName] = useState("Add to a Folder");
  const [selectStatus, setselectStatus] = useState(false);
  const [folderID, setFolderID] = useState("");
   const [showStylelist, setshowStylelist] = useState(false);
  const [selectedStyleGuide, setselectedStyleGuide] = useState<any>(null);
  // const [selectedList, setSelectedList] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const [expanded, setExpanded] = useState<any>([]);
  const [selected, setSelected] = React.useState([]);
  const SelectIconClass = `MuiSvgIcon-root MuiSelect-icon select`

  const handleFolder = () => {
    setShowFolder(!showFolder);
    closeFolderDropDown(!folderDropDown);
  };
  


  const closeDropDownFolder = (event: any) => {
    setShowFolder(!showFolder);
    closeFolderDropDown(!folderDropDown);
    const selectedFolderName = event.currentTarget.dataset.foldername;
	const selectedFolderId = event.currentTarget.id;
    setFolderID(selectedFolderId);
    setFolderName(selectedFolderName);
  };

  const handleClickAwayFolder = () => {
    closeFolderDropDown(false);
    setShowFolder(false);
  };
  const handleToggle = (event: any, nodeIds: any) => {
    setExpanded(nodeIds);
  };
   const handleChangeOnclick = (event: any) => {
     if(showStylelist)
     {
         setshowStylelist(false);
     } else {
         setshowStylelist(true);
     }
     
  };
 const handleChange = (event: any) => {
   setselectedStyleGuide(event.target.value);
  };
  const handleSelect = (event: any, nodeIds: any) => {
    setSelected(nodeIds);
  };
    const selectStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,

  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  folderDropdowncss: {
    position: 'absolute',
    right:'10px',
  },
  icon:{
    left:0
  },
  iconOpen:{
    transform:'none'
  },
  formControlLabel:{
    left:24
  },
  selectSelect:{
    paddingLeft:'24px'
  }
}));
   const selectclasses = selectStyles();
  

  React.useEffect(() => {
    let data: any[] = [0];
    const expd = (child: any) => {
      child.forEach((item: any) => {
        data.push(String(item.id));
        if (item.childes) {
          expd(item.childes);
        }
      });
    };
    if (campFolders.childes) {
      expd(campFolders.childes);
    }
       setExpanded(data);
     
       //setDefaultstyle(Styleguidedropdown[0]['id']);
       // eslint-disable-next-line
   if(totalLength === 1){
       setselectStatus(false);
   } else {
        setselectStatus(false);
   }
  
// eslint-disable-next-line   
  }, [campFolders,Styleguidedropdown,defaultStyle]);
  
 
  const [error, setError] = React.useState<boolean>(false);

  const createNewCampaignHandler = () => {
      
        if (!selectedTemplateID)
        {
          selectedTemplateID = 'blank';
        }
   
    if (!campaignName) {
        toast.error("Please Enter The Campaign Name");
    setError(true)
    return;
    } 
    else if(!selectedStyleGuide)
    {
    toast.error("Please Select The Design System");
    setError(true)
    return;

    }
    else if (!selectedTemplateID) {
    toast.error("Template Not Selected");
    setError(true)
    return;
    } 
     else {
      setError(false)
      var FormData = require("form-data");
      var data = new FormData();
      data.append("name", campaignName);
	  if(selectedStyleGuide)
	  {
       data.append("styleGuideId", selectedStyleGuide);
	  } else {
		  data.append("styleGuideId", localStorage.getItem("defaultStyleId"));
	  }
      data.append("brand", brandID);
      data.append("template", selectedTemplateID);
      data.append("last_updated_time", moment(new Date()));
      
      if (folderName === "Add to a Folder") {
        data.append("folder", "");
      } else {
        data.append("folder", folderID);
	  }
	
      data.append("isCopywriter",isCopywriter());
      AxiosInstance.post('api/v1/campaign/save_campaign_new', data)
      	.then((response: AxiosResponse) => {
          if (response.data.status === true) {
				var data1 = new FormData();
				data1.append("dir", response.data.dir);
				AxiosInstance.post('api/v1/campaign/delete_init', data1).then((resp: AxiosResponse) => {
					const createLink = response.data.redirectURI;
					window.location.href = createLink;
				});
				
          } else
          {
              toast.error(response.data.response_msg);
              setError(true)
          }
        })
    }
  };

/*  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;*/
  const [openNew, setOpenNew] = useState<boolean>(false);
  /*const [folderOverlay, setFolderOverlay] = React.useState<any>(false);*/

  /*const selectedCategoryFunction = (data: any) => {
    setSelectedList(data);
  };*/
  return (
    <Box className={`${classes.newCampaignHeader} ${localStorage.getItem("trial_mode") === 'true' ? classes.hasTrialInfo: ""}`}>
      <CreateNewFolder
        open={openNew}
        setOpen={setOpenNew}
        history={history}
        brandID={brandID}
        refreshCampFolders={getCampFolders}
      />
      <Container className={classes.headerRoot}>
        <Box display="flex">
          <Box width="100%">
            <Typography
              component="h1"
              align="left"
              className={classes.heading}
              variant="h5"
            >
              Create an Email
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Box width="30%">
            <div className={classes.newCampaignName}>
              <TextField
                className={classes.newCampaignTextField}
                value={campaignName}
                placeholder="Enter Email Name"
                variant="outlined"
                fullWidth
                error={error}
                onChange={(event) => {
                  setCampaignName(event.target.value);
                }}
              />
            </div>
          </Box>
          
           
          <Box width="30%">
      <FormControl variant="outlined" style={{width: "100%" }}>
      <InputLabel htmlFor="outlined-StyleGuide-native-simple">Design Systems</InputLabel>    
			<Select
            disabled={selectStatus}
			fullWidth
            error={error}
            placeholder="Brand Name"
			name="StyleGuide"
			id="StyleGuide"
      label="Design Systems"
      inputProps={{
        name: 'StyleGuide',
        id: 'outlined-StyleGuide-native-simple',
      }}
			variant="outlined"
            onMouseDown={handleChangeOnclick}
            IconComponent={() => 
                 showStylelist ? <ExpandLessIcon style={{right:"9px",color:'#000'}} className={SelectIconClass} /> : <ExpandMoreIcon style={{right:"9px",color:'#000'}} className={SelectIconClass} />
           }
            style={{backgroundColor:"#ffffff"}}
            onChange={handleChange}
            classes={{icon:selectclasses.icon, iconOpen:selectclasses.iconOpen,select:selectclasses.selectSelect}}
			
			>
            
            {Styleguidedropdown.map((Item : any,index:any) => {
			return (
			  <MenuItem value={Item.id}>{Item.styleguide_name}</MenuItem>
			)
			})}
			
			 </Select>
      </FormControl>
		  </Box>
          
         
          {/*<Box width="30%">*/}
          {/*  {categoryOverlay === false ? (*/}
          {/*    <HeaderInput />*/}
          {/*  ) : (*/}
          {/*    <Autocomplete*/}
          {/*      multiple*/}
          {/*      id="checkboxes-tags-demo"*/}
          {/*      limitTags={1}*/}
          {/*      options={categoryList}*/}
          {/*      className={classes.newCampaignText}*/}
          {/*      disableCloseOnSelect*/}
          {/*      getOptionLabel={(option) => option.cat_title}*/}
          {/*      onChange={(event, value) => {*/}
          {/*        selectedCategoryFunction(value.map((e) => e.cat_id).join());*/}
          {/*      }}*/}
          {/*      renderOption={(option, { selected }) => (*/}
          {/*        <React.Fragment>*/}
          {/*          <Checkbox*/}
          {/*            icon={icon}*/}
          {/*            checkedIcon={checkedIcon}*/}
          {/*            checked={selected}*/}
          {/*          />*/}
          {/*          {option.cat_title}*/}
          {/*        </React.Fragment>*/}
          {/*      )}*/}
          {/*      renderInput={(selectedList) => (*/}
          {/*        <TextField*/}
          {/*          className={classes.newCampaignTextField}*/}
          {/*          {...selectedList}*/}
          {/*          variant="outlined"*/}
          {/*          placeholder="Brand"*/}
          {/*        />*/}
          {/*      )}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*</Box>*/}
          <Box width="30%">
            <ClickAwayListener onClickAway={handleClickAwayFolder}>
              <div style={{ position: "relative" }}>
                <div
                  onClick={handleFolder}
                  className={classes.newCampaignBrandDiv}
                >
                  
                  <Typography style={{height:'23px'}}
                    component="p"
                    className={classes.newCampaignHearderOverflow}
                  >
                  {showFolder === true ? (
                    <ExpandLessIcon className={selectclasses.folderDropdowncss} />
                  ) : (
                    <ExpandMoreIcon className={selectclasses.folderDropdowncss} />
                  )}
                    {folderName}
                  </Typography>
                </div>
                {folderDropDown && (
                  <div className={classes.folderDropdownList}>
                    <div className={classes.folderDropdownListTreeview}>
                      <TreeView
                        style={{ padding: "1rem" }}
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        expanded={expanded}
                        selected={selected}
                        onNodeToggle={handleToggle}
                        onNodeSelect={handleSelect}
                      >
                        
						 <SimpleTree
							fileTreeData={campFolders}
							parrentId={folderID}
							closeDropDownFolder={closeDropDownFolder}
							treeName="Emails"
						>
						</SimpleTree>
                      </TreeView>
                    </div>
                    <div
                      className={classes.filetreeHeader}
                      onClick={() => setOpenNew(true)}
                    >
                      <div className={classes.filetreeInnerHeader}>
                        <CreateNewFolderOutlinedIcon />
                        <Typography className={classes.filetreeName}>
                          New Folder
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </ClickAwayListener>
          </Box>
          <Box flexShrink={0}>
            <Button
              className={classes.newCampaignButtonDiv}
              variant="contained"
              color="primary"
              onClick={createNewCampaignHandler}
            >
              Create New
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default NewCampaign;
