import React, { Dispatch, SetStateAction } from "react";
import {
  Dialog,
  Typography,
  Button,
  Box,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  ConfirmModalPopupStatus: any;
  templateId:any;
  setConfirmModalPopupStatus: Dispatch<SetStateAction<boolean>>;
 
}
const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: 350,
  },
  text: {
    margin: "0",
    paddingBottom: "1rem",
    borderBottom: "1px solid #c4c4c4",
  },
  dialogbox: {
    marginBottom: "18px",
  },
  linkcolor: {
    textDecoration: "none",
  },
  textColor: {
    color: "red",
  },
}));
const TemplateConfirmModal: React.FC<Props> = ({
  ConfirmModalPopupStatus,
  templateId,
  setConfirmModalPopupStatus
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setConfirmModalPopupStatus(false);
  };
  const handleConfirm = () => {
    const editLink = `${window.globalConfig.apiOrigin}${window.globalConfig.subDir && window.globalConfig.subDir.length ? ('/' + window.globalConfig.subDir) : ''}template/build/${templateId}`;
      window.location.href = editLink;
  };

 
  return (
    <div>
      <Dialog 
        open={ConfirmModalPopupStatus}
        onClose={handleClose}
       
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update Marketo Template</DialogTitle>
        <DialogContent>
          <Box className={classes.root}>
            <Typography className={classes.text}>
              Your template will be updated in Marketo when editing in Mashrmail. Please be aware that any manual changes made in Marketo will be overriden when you 
              select the update button
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogbox} >
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button  variant="contained" color="primary" onClick={handleConfirm}>
            Confirm
          </Button>
          
        </DialogActions>
      </Dialog> 
    </div>
  );
};

export default TemplateConfirmModal;
