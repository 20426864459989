import React from "react";
import Mailchimp from "./components/Mailchimp";
import CampaignMonitor from "./components/CampaignMonitor";
import Pardot from "./components/Pardot";
import Saleforce from "./components/Saleforce";
import Klaviyo from "./components/Klaviyo";
import Marketo from "./components/Marketo";
import Sendgrid from "./components/Sendgrid";
import Mailjet from "./components/Mailjet";
import {useSelector } from "react-redux";


interface Props {
  handleNext?: any;
  save?: any;
  isNotSettingsPage?: boolean;
}

const EditespIntegration: React.FC<Props> = () => {
     const state = useSelector((state: any) => state);
  return (
    <>
      {(state.EspManager.esp_name === 'Mailchimp') && (
        <Mailchimp isNotSettingsPage={false} />
      )}
	  {(state.EspManager.esp_name === 'Campaign Monitor') && (
        <CampaignMonitor isNotSettingsPage={false} />
      )}
      {(state.EspManager.esp_name === 'Pardot') && (
        <Pardot isNotSettingsPage={false} />
      )}
      
      {(state.EspManager.esp_name === 'Salesforce Cloud') && (
        <Saleforce isNotSettingsPage={false} />
      )}
      {(state.EspManager.esp_name === 'Klaviyo') && (
        <Klaviyo isNotSettingsPage={false} />
      )}
       {(state.EspManager.esp_name === 'Marketo') && (
        <Marketo isNotSettingsPage={false} />
      )}
      {(state.EspManager.esp_name === 'Send Grid') && (
        <Sendgrid isNotSettingsPage={false} />
      )}
      {(state.EspManager.esp_name === 'Mailjet') && (
        <Mailjet isNotSettingsPage={false} />
      )}
      
    </>
  );
};

export default EditespIntegration;