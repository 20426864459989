import React from "react";
import { Box } from "@material-ui/core";
import { useStyles } from "../../styles";
import StyleItemType from "./StyleItemType";
import StyleItemSource from "./StyleItemSource";
import StyleItemWeight from "./StyleItemWeight";
import StyleItemFormatStyle from "./StyleItemFormatStyle";

interface Props {
  data:any;
}

const StyleItem: React.FC<Props> = ({data}) => {
  const classes = useStyles();  

  return (    
    <>
      <Box className={`${classes.StyleItemCol}`}>
        <StyleItemType data={data} />
      </Box>
      <Box className={`${classes.StyleItemCol}`} flex="1">
        <StyleItemSource data={data} />       
      </Box>
      {(data?.source || data?.type === "URL") && <Box className={`${classes.StyleItemCol}`}>
        <StyleItemWeight data={data} />
      </Box>}
      {(data?.source || data?.type === "URL") && <Box className={`${classes.StyleItemCol}`}>
        {/* <StyleItemFormatItalic data={data} /> */}
        <StyleItemFormatStyle data={data} />
      </Box>}
      {/* <Box className={`${classes.StyleItemCol}`}>
        <StyleItemActionIcons data={data} />
      </Box> */}
    </>
  );
};

export default StyleItem;
