import React, { useEffect,useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import LogoSVG from "../../assets/svgs/Svgs";
import GettingStarted from "./gettingStarted/GettingStarted";
import InfoIcon from '@material-ui/icons/Info';
import CampaignIndex from "./campaigns/Index";
import ContentIndex from "./content/Index";
import Settings from "./settings/Settings";
import ActionMenu from "./userActions/ActionMenu";
// import ChoosePlan from "./settings/billing/ChoosePlan";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveTabs } from "../../redux/actions/TabManagerActions";
import Details from "./details/Details";
import {getUserFromToken, redirectTo} from "../../../utils/AuthUtility";
// import StyleGuide from '../onboarding/styleguide/StyleGuidePage';
// import StyleGuideTab from '../onboarding/styleguide/Index';
import DesignSystems from './designSystems/Index';
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
	// const getTrial = () => {
	// 	if(localStorage.getItem("trial_mode")==='true'){
	// 		return 56;
	// 	}
	// 	return 0;
	// };
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
	  // style={{marginTop:getTrial()}}
    >
      {
        <Box>
          <div>{children}</div>
        </Box>
      }
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      "-webkit-border-radius": "10px",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#C4C4C4",
      "-webkit-border-radius": "10px",
    },
    ".MuiTouchRipple-root span":{
      backgroundColor: "unset",
    },
  },
  TabsRoot:{   
    "& button.MuiButtonBase-root":{ 
      flexBasis: "unset",
      minWidth: "unset",
      padding: "6px 20px"
    }
  },
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: "#f8f8f8",
  },
  header: {
    boxShadow: "0px 2px 6px rgb(0 0 0 / 10%)",
  },
  tabElement: {
    fontSize: "16px",
    fontWeight: 600,
    letterSpacing: "0.63px",
    cursor: "pointer",
    color: "#a4a4a4",
    paddingTop: theme.spacing(1.6),
    paddingBottom: theme.spacing(1.6),
    "&:hover": {
      color: theme.palette.secondary.light,
    },
  },
  tabActiveElement: {
    fontSize: "16px",
    fontWeight: 600,
    letterSpacing: "0.63px",
    cursor: "pointer",
    borderBottom: "red",
  },
  appBar: {
    boxShadow: "0px 2px 6px rgb(0 0 0 / 10%)",
  },
  indicatorTab: {
    color: "primary",
  },
  tabContentStyles:{
    paddingTop:120
  },
  trialch: {
    display: 'flex',
    alignitems: 'center',
    background: '#234FBD',
    height: '56px',
},
hasTrialInfo: {

},
trialchcontainer: {
    display: 'flex',
   
    color: '#fff',
    alignself: 'stretch',
    position: 'relative',
	padding: '13px',

},
trialchcontainerdiv: {
	display: 'flex',
	fontFamily:"Poppins, sans-serif"
},
linkcolor: {
    color: "#FFFFFF",
  },
}));

interface Props {
  history?: any;
  match?: any;
}
const TabsPanel: React.FC<Props> = ({ history, match }) => {
  const classes = useStyles();
  const tabManager = useSelector((state: any) => state.tabManager);
  const dispatch = useDispatch();
  const [timeStamp, setTimeStamp] = useState<any>(0);
  useEffect(() => {
    setTimeStamp(new Date().getTime());
  },[])

  useEffect(() => {
     /*if(isCopywriter())
     {
          redirectTo('/campaign');
          
     }*/
    dispatch(
      updateActiveTabs({
        fieldName: "activeParent",
        fieldValue: match?.params?.activeParent,
      })
    );
    dispatch(
      updateActiveTabs({
        fieldName: "activeChild",
        fieldValue: match?.params?.activeChild,
      })
    );
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match?.params?.activeParent, match?.params?.activeChild]);

  const loggedInUser = getUserFromToken();
  /*const level = loggedInUser?.user_detail?.level;*/
	
  const updateURL = (event: any) => {
    const tab = event.currentTarget.getAttribute("data-tab");
    if (tab === "campaign") {
      setCampaignTabValue(0);
    }
      let MsgContainer: HTMLDivElement | null = document.querySelector("#FlsMsgAlert");
    if(MsgContainer)
    {
        MsgContainer.setAttribute("style", "display:none;");
    }
    dispatch(updateActiveTabs({ fieldName: "activeParent", fieldValue: tab }));
    history.push(`/${tab === "settings" ? "settings/users" : tab || ""}`);
  };
  const [campaignTabValue, setCampaignTabValue] = React.useState(
    match?.params?.activeParent === "landing" ? 1 : 0
  );
  const gotoLanding: any = () => {
    redirectTo('/campaign');
  };
  return (
    <Box className={`${classes.root} ${localStorage.getItem("trial_mode") === 'true' ? classes.hasTrialInfo: ""}`}>
      <AppBar color="inherit" position="fixed" className={classes.appBar}>
	  {(localStorage.getItem("trial_mode") === 'true') && (
	  
	  <div className={classes.trialch}>
	   <InfoIcon htmlColor="#ffffff" style={{width: 35,height: 35,marginTop:'8px',marginLeft:'10px'}} ></InfoIcon>
		<div className={classes.trialchcontainer}>
	 		
			{localStorage.getItem("numberDays") && String(localStorage.getItem("numberDays")) !== '0' ? (
			<div className={classes.trialchcontainerdiv}>
			Thank you for trialing MashrMail. You have&nbsp;<b>{localStorage.getItem("numberDays")}</b>&nbsp;{localStorage.getItem("day_msg")}&nbsp;remaining in your trial which&nbsp;{localStorage.getItem("end_msg")}&nbsp;on &nbsp;<b>{localStorage.getItem("date_ended")}</b>
			</div>
			) : (
			<div className={classes.trialchcontainerdiv}>
			Thank you for trialing MashrMail. Your trial period has now expired. Please contact&nbsp;<a href="https://support.mashrmail.io/" className={classes.linkcolor} rel="noreferrer" target="_blank">customer support</a>&nbsp;to activate your account.
			</div>
			)}
			
		</div>
	  </div>
	  
	  )}
        <Box
          display="flex"
          justifyContent="space-between"
          style={{
            height: "64px",
          }}
        >
          <Box display="flex">
            <Box
              justifyContent="center"
              width={66}
              style={{marginRight:10,marginLeft:10,}}
              alignItems="center"
              display="flex"
            >
              {loggedInUser?.user_detail?.settings?.logo ? (
                <Box
                  // maxWidth={86}
                  // height={34}
                  style={{ cursor: "pointer" }}
                  onClick={() => gotoLanding()}
                >
                  <img
                    alt="logo"
                    style={{ maxHeight:34,maxWidth: 66 }}                  
                    src={loggedInUser?.user_detail?.CompanyImage+"?time=" + timeStamp}
                  />
                </Box>
              ) : (
                <LogoSVG />
              )}
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ paddingLeft: "20px" }}
            >
              <Tabs
                value={getActiveTab(tabManager.activeParent)}
                variant="fullWidth"
                TabIndicatorProps={{ style: { background: "#BDBDBD" } }}
                textColor="inherit"
                aria-label="full width tabs example"
                classes={{
                  root: classes.TabsRoot
                }}
              >
                
                  <Tab
                    data-tab="campaign"
                    label={
                      <span
                        className={
                          getActiveTab(tabManager.activeParent) === 0
                            ? classes.tabActiveElement
                            : classes.tabElement
                        }
                      >
                        emails
                      </span>
                    }
                    onClick={updateURL}
                    {...a11yProps(0)}
                  />
                

                  <Tab
                    data-tab="content"
                    label={
                      <span
                        className={
                          getActiveTab(tabManager.activeParent) === 1
                            ? classes.tabActiveElement
                            : classes.tabElement
                        }
                      >
                        content
                      </span>
                    }
                    onClick={updateURL}
                    {...a11yProps(1)}
                  />
 
                  <Tab
                    data-tab="designSystems"
                    label={
                      <span
                        className={
                          getActiveTab(tabManager.activeParent) === 2
                            ? classes.tabActiveElement
                            : classes.tabElement
                        }
                      >
                        design&nbsp;systems
                      </span>
                    }
                    onClick={updateURL}
                    {...a11yProps(2)}
                  />

                  <Tab
                    data-tab="settings"
                    label={
                      <span
                        className={
                          getActiveTab(tabManager.activeParent) === 3
                            ? classes.tabActiveElement
                            : classes.tabElement
                        }
                      >
                        settings
                      </span>
                    }
                    onClick={updateURL}
                    {...a11yProps(3)}
                  />
               
                {/* {level === "0" && (
                  <Tab
                    data-tab="details"
                    label={
                      <span
                        className={
                          getActiveTab(tabManager.activeParent) === 4
                            ? classes.tabActiveElement
                            : classes.tabElement
                        }
                      >
                        Details
                      </span>
                    }
                    onClick={updateURL}
                    {...a11yProps(0)}
                  />
                )} */}
              </Tabs>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mr="20px"
            flex="1"
          >
            {/* <Badge color="primary" badgeContent={5} style={{ marginRight: 30 }}>
              <NotificationsIcon />
            </Badge> */}

            <ActionMenu />
          </Box>
        </Box>
      </AppBar>
      <TabPanel value={getActiveTab(tabManager.activeParent)} index={undefined}>
        <GettingStarted />
      </TabPanel>
      <TabPanel value={getActiveTab(tabManager.activeParent)} index={0}>
        <CampaignIndex
          setCampaignTabValue={setCampaignTabValue}
          campaignTabValue={campaignTabValue}
          history={history}
        />
      </TabPanel>
      {/* <TabPanel value={getActiveTab(tabManager.activeParent)} index={-1}>
        <ChoosePlan />
      </TabPanel> */}

      
        <TabPanel value={getActiveTab(tabManager.activeParent)} index={1}>
          <ContentIndex />
        </TabPanel>
     
      
        <TabPanel value={getActiveTab(tabManager.activeParent)} index={2}>
          <DesignSystems history={history} />          
        </TabPanel>
      
      
        <TabPanel value={getActiveTab(tabManager.activeParent)} index={3}>
          <Settings history={history} />
        </TabPanel>
     
      <TabPanel value={getActiveTab(tabManager.activeParent)} index={4}>
        <Details history={history} />
      </TabPanel>
    </Box>
  );
};
const getActiveTab = (activeParent: any) => {
	
	switch (activeParent) {
		case 'content': 
		case 'scontent': return 1;
		case 'choosePlan': return -1;
		case 'designSystems':
		case 'sdesignSystems': return 2;
		case 'settings': return 3;
		case 'details': return 4;
		case 'slanding': return 0;
		case 'steams': return 3;
		case 'susers': return 3;
		case 'sintegrations': return 3;
		case 'campaign': case 'landing': default: return 0;
		// default: return undefined; // for getting started page.
	}
};
export default TabsPanel;
