import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Formik, Form, Field } from "formik";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "../styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import FolderIcon from "@material-ui/icons/Folder";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";



import { toast } from "react-toastify";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  } from "@material-ui/core";

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import { StepLabel } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


interface Props {
  handleClose: any;
  isNotSettingsPage: boolean;
  openDialog: any;
  selectedData: any;
  cloudFolders: any;
  setSelectedData: (data: any) => any;
  integrateSelected:boolean;
  integrateflag:boolean;
  setIntegrateSelected:(data: any) => any;
  setintegrateflag:(data: any) => any;
}

interface RenderTree {
  id: string;
  name?: string;
  type?: string;
  folder_name?: string;
  parent_id?: string;
  company_id?: string;
  state?: {
    opened: boolean;
  };
  childes?: RenderTree[];
}

const DialogBox: React.FC<Props> = ({
  handleClose,
  openDialog,
  selectedData,
  isNotSettingsPage,
  setSelectedData,
  integrateSelected,
  integrateflag,
  setIntegrateSelected,
  setintegrateflag,
  cloudFolders
}) => {

const handleChangePardotFormatting = (event: any) => {
	selectedData.pardot_formatting = event.target.value;
	};
     // eslint-disable-next-line 
  const [decrypt_pc_api_id, setApiID] = useState('');
 
  const [cloudexport, setcloudexport] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<any>([]);
  const [clientid, setclientid] = useState('');
  const [clientSecret, setclientSecret] = useState('');
  const [authBaseUrl, setauthBaseUrl] = useState('');
  const [BaseUrl, setBaseUrl] = useState('')
  const [error, setError] = React.useState<boolean>(false);
  // eslint-disable-next-line 
  const [ExportFolderId, setExportFolderId] = useState('');
  const [cloudFoldersnew, setcloudFoldersnew] = React.useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [sfmcdefaultFolder, setsfmcdefaultFolder] = useState('');
  
  
  const handleChange = (event: any) => {
        if(event.target.name ==='client_id')
        {
          setclientid(event.target.value); 
            
        } else if(event.target.name ==='client_secret')
        {
            setclientSecret(event.target.value);
        } else if(event.target.name ==='auth_base_url')
        {
            setauthBaseUrl(event.target.value);
        } else if(event.target.name ==='rest_base_url')
        {
            setBaseUrl(event.target.value);
        }
  };
  


  const useComponentStyles = makeStyles((theme) => ({
    cancelButton: {
      marginRight: selectedData.selected === true ? "auto" : "",
	  fontSize: 15,
	  border: "unset",
	  textTransform: "uppercase",
	  letterSpacing: "0.46px",
	  "&:hover":{
		background: "unset"
	  }
    },
	revealKey:{
        background: "#C4C4C4",
        padding:"8px",
        position: "relative",
        overflow: "hidden",
        "&:after":{
            position: "absolute",
            paddingLeft: 0,
            content: '" * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * "',
            top: 10,
            paddingRight: 6,
            paddingInlineStart: "0.25ch",
        }
    },
  }));
  // eslint-disable-next-line 
  const onSubmit = (data: any) => {

	// eslint-disable-next-line 
    {/*  if(selectedData.p_name === "Salesforce Cloud" && ExportFolderId==='')
    {
         toast.error('Please select the Export Folder');
        data.preventDefault();
         
    } */}
	if(!data.pc_api_key){
		data.pc_api_key='';
	}
	if(!data.pc_api_id){
		data.pc_api_id='';
	}
	if(!data.pc_username){
		data.pc_username='';
	}
	if(!data.pc_password){
		data.pc_password='';
	}
	if(!data.pc_confirmation_email){
		data.pc_confirmation_email='';
	}
	if(!data.pc_account_id){
		data.pc_account_id='';
	}
	if(!selectedData.pardot_formatting){
		selectedData.pardot_formatting='PML';
	}
	if(!data.pc_account_domain){
		data.pc_account_domain='';
	}
    if(selectedData.p_name!==''){
		data.esp_name = selectedData.p_name;
	}
    
    selectedData.selected = true;
    selectedData.pc_api_key = data.pc_api_key;
    selectedData.pc_api_id = data.pc_api_id;
    selectedData.pc_username = data.pc_username;
    selectedData.pc_password = data.pc_password;
    selectedData.pc_confirmation_email = data.pc_confirmation_email;
    selectedData.pc_account_id = data.pc_account_id;
    selectedData.pc_account_domain = data.pc_account_domain;
	//selectedData.pardot_formatting = pardot_formatting;

    if (isNotSettingsPage) {
      connectWhenOnboarding(data);
    } else {
      connectWhenSettings(data);
    }
  };

  const OpenExportview = (event:any) => {
      event.preventDefault();
      
        if(clientid ==='')
        {
            toast.error('all fields are required');
            setcloudexport(false);       
            setError(true)       
        }
        else if(clientSecret ==='')
        {
            toast.error('all fields are required');
            setcloudexport(false);       
            setError(true)       
        }
        else if(authBaseUrl ==='')
        {
            toast.error('all fields are required');
            setcloudexport(false);       
            setError(true)       
        }
        else if(BaseUrl ==='')
        {
            toast.error('all fields are required');
            setcloudexport(false);       
            setError(true)       
        }     

        else {
            getCloudFolders();
            
        }
  }
  const getCloudFolders = () => {
      var brandID='66';
      setShowLoader(true)
    AxiosInstance.get('api/v1/Campaign/getexportFolders', {
		params: {
			brandID: brandID,
            clientid:clientid,
            clientSecret:clientSecret,
            authBaseUrl:authBaseUrl,
            BaseUrl:BaseUrl,
		}
	}).then((response: AxiosResponse) => {
        if (response.data.status === true) {
            setcloudFoldersnew(response.data.folders[0]);
            setcloudexport(true);
            setActiveStepSalesForceCloud((prevActiveStep) => prevActiveStep + 1);
        } else {
            setShowLoader(false)
            setcloudexport(false);
            toast.error("Invalid credentials supplied for Salesforce Cloud Settings");
        }
      }).catch(function(error) {
});
  };
  
  const CloseExportview = (event:any) => {
      event.preventDefault();
      setShowLoader(false);
      setcloudexport(false);
	  setActiveStepSalesForceCloud((prevActiveStep) => prevActiveStep - 1);
  }
  
  
  const connectWhenOnboarding = (data: any) => {
    var axios = require("axios");

    var formdata = new FormData();
    formdata.append("user_id", localStorage.getItem("usr_id") || "");
    formdata.append("pc_provider_id", selectedData.p_id);
    formdata.append("pc_api_key", data.pc_api_key);
    formdata.append("pc_confirmation_email", data.pc_confirmation_email);
    formdata.append("esp_name", data.esp_name);
    formdata.append("pc_api_id", data.pc_api_id);
    formdata.append("pc_username", data.pc_username);
    formdata.append("pc_password", data.pc_password);
    formdata.append("pc_account_id", data.pc_account_id);
    formdata.append("pc_account_domain", data.pc_account_domain);
	formdata.append("pardot_formatting", data.pardot_formatting);

    var config = {
      method: "post",
      url: `api/intregation`,
      headers: {},
      data: formdata,
    };

    axios(config)
      .then(function (response: any) {
        selectedData.pc_id = response.data.pc_id;
        setSelectedData(selectedData);
        handleClose();
        toast.success("Email Provider Connected Successfully");
      })
      .catch(function (error: any) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.warn(error.response.data.message);
          }
          if (error.response.status === 500) {
            toast.error(error.response.data.message);
          }
          selectedData.selected = "";
          setSelectedData(selectedData);
        }
      });
  };
  
   const setFolderId = (event: any) => {
       setsfmcdefaultFolder(event.target.id);
  };
  
  
  const connectWhenSettings = (data: any) => {
    const FormData = require("form-data");
    let formdata = new FormData();
    formdata.append("update_user_id", localStorage.getItem("usr_id") || "");
    formdata.append("pc_provider_id", selectedData.p_id);
    formdata.append("client_api", data.pc_api_key);
    formdata.append("esp_name", data.esp_name);
    formdata.append("confirmation_email", data.pc_confirmation_email);
    formdata.append("pc_api_id", data.pc_api_id);
    formdata.append("username", data.pc_username);
    formdata.append("password", data.pc_password);
    formdata.append("account_name", "");
    formdata.append("account_id", data.pc_account_id);
    formdata.append("account_domain", data.pc_account_domain);
	formdata.append("pardot_formatting", selectedData.pardot_formatting);
    formdata.append("saleforce_cloud_clientId", clientid);
    formdata.append("saleforce_cloud_clientSecret", clientSecret);
    formdata.append("saleforce_cloud_authURL", authBaseUrl);
    formdata.append("saleforce_cloud_baseURL", BaseUrl);
    formdata.append("saleforce_default_folder", sfmcdefaultFolder);
	setApiID('');
	
    AxiosInstance.post('api/v1/Integration/save_configuration_ajax', formdata)
     .then((response: AxiosResponse) => {
         
        if (response?.data?.status) {
          //setIntegrateSelected(true);
          setintegrateflag(true);
          setcloudexport(false);   
          selectedData.pc_id = response?.data?.data?.pc_id;
          setSelectedData(selectedData);
          
           
          if (selectedData.p_name === "Hubspot") {
            let hubSpot = window.open(
              `EmailProvider/redirect_to_hubspot`,
              "_blank",
              "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=300,width=850,height=650"
            );

            const checkChild = () => {
              if (hubSpot?.closed) {
                handleClose();
                clearInterval(timer);
              }
            };
            var timer = setInterval(checkChild, 500);
          } else {
            handleClose();
          }
          toast.success(response?.data?.data?.message);
          setShowLoader(false);
        
     }
      })
      .catch(function (error: any) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.message);
          }
          selectedData.selected = "";
          setSelectedData(selectedData);
        }
      });
  };

  const classes = useStyles();
  const componetClasses = useComponentStyles();
  
    const renderTree = (nodes: RenderTree) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={
        <div
          id={nodes.folder_name}
          data-id={nodes.id}
           className={classes.treeViewDiv}
        >
          <FolderIcon className={classes.treeViewFolder} />
          <Typography variant="body1" onClick={setFolderId} id={nodes.id} style={{ paddingLeft: "1rem" }}>
            {nodes.folder_name}
          </Typography>
        </div>
      }
    >
      {Array.isArray(nodes.childes)
        ? nodes.childes.map((node) => renderTree(node))
        : ""}
    </TreeItem>
  );

  const disconnectWhenSettings = () => {
      
  	AxiosInstance.delete(`api/v1/Integration/delete_configure_ajax/${selectedData.pc_id}`)
     .then((response: AxiosResponse) => {
        if (response?.data?.status) {
          selectedData.selected = false;
          selectedData.pc_api_key = "";
          selectedData.pc_api_id = "";
          selectedData.pc_username = "";
          selectedData.pc_password = "";
          selectedData.pc_confirmation_email = "";
          //selectedData.pc_id = selectedData.pc_id;
          selectedData.pc_account_id = "";
          selectedData.pc_account_domain = "";
		  selectedData.pardot_formatting = "PML";
          setSelectedData(selectedData);
		  setIntegrateSelected(false);
          setintegrateflag(true);
          handleClose();
        }
      })
  };
  const disconnectWhenOnboarding = () => {
	
    var axios = require("axios");
    var formdata = new FormData();
    formdata.append("pc_id", selectedData.pc_id);

    var config = {
      method: "post",
      url: `api/disconect`,
      headers: {},
      data: formdata,
    };

    axios(config)
      .then(function (response: any) {
        selectedData.selected = false;
        selectedData.pc_api_key = "";
        selectedData.pc_api_id = "";
        selectedData.pc_username = "";
        selectedData.pc_password = "";
        selectedData.pc_confirmation_email = "";
        //selectedData.pc_id = "";
        selectedData.pc_account_id = "";
        selectedData.pc_account_domain = "";
		selectedData.pardot_formatting = "PML";
        setSelectedData(selectedData);
		setIntegrateSelected(false);
        handleClose();
        toast.success("Email Provider Disconnected Successfully");
      })
      .catch(function (error: any) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.warn(error.response.data.message);
          }
          if (error.response.status === 500) {
            toast.error(error.response.data.message);
          }
        }
      });
  };
  const handleDisconnect = (data: any) => {
    if (isNotSettingsPage) {
      disconnectWhenOnboarding();
    } else {
      disconnectWhenSettings();
    }
	setActiveStepSalesForceCloud(0);
  };
  
   useEffect(() => {
    if(!selectedData.pardot_formatting){
		selectedData.pardot_formatting='PML';
	}
    if(selectedData.p_name === "Salesforce Cloud" && selectedData.selected === true) {
        setActiveStepSalesForceCloud(1);
        setShowLoader(false);
    }
    
  }, [selectedData]);

 // eslint-disable-next-line

  
  const handleToggle = (event: any, nodeIds: any) => {
    setExpanded(nodeIds);
  };
  
   const handleSelect = (event: any, nodeIds: any) => {
    setExportFolderId(nodeIds);
  };
  
  React.useEffect(() => {
    let data: any[] = [0];
   
       setExpanded(data);
       // eslint-disable-next-line 
  }, [cloudFoldersnew]);
  
  const [activeStepSalesForceCloud, setActiveStepSalesForceCloud] = React.useState(0);
  const stepSalesForceCloud = ['API Connection Settings', 'Default Export Folder'];
  

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" className={classes.modalTitle}>
        {selectedData.p_name === "Salesforce Cloud" ? ("Salesforce Marketing Cloud Settings") : (`${selectedData.p_name} Integration`)}
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            pc_api_key: '',
            pc_api_id: '',
            pc_username: '',
            pc_password: '',
            pc_confirmation_email: '',
		    pc_account_id: '',
		    pc_account_domain: '',
			pardot_formatting: selectedData.pardot_formatting,
          }}
          onSubmit={onSubmit}
        >
          {({ errors }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  {selectedData.p_name === "Campaign Monitor" ? (
                    <>
						<Typography
						variant="subtitle2"
						className={classes.InputLabel}
						style={{
							marginTop: "12px",
							float:"left",
							width:"100px"
						}}
						>
						Client Id
						</Typography>
						{selectedData.selected === true? (
							<Box>
							
							<Typography

							className={componetClasses.revealKey}
							style={{
								clear:"both",
								minHeight:"20px"
							}}
							>
							
							</Typography>
							</Box>
						):(<Field
							name="pc_api_id"
							autoComplete="pc_api_id"
							as={TextField}
							type="text"
							variant="outlined"
							fullWidth
							className={classes.InputText}
							placeholder="Client Id"
							required
						/>)}
					 
                      
						<Typography
						variant="subtitle2"
						className={classes.InputLabel}
						style={{
							float:"left",
							width:"100px",
							
						}}
						>
						Client API Key
						</Typography>


						{selectedData.selected === true? (
							<Box>
							
							<Typography

							className={componetClasses.revealKey}
							style={{
								clear:"both",
								height:"auto",
								minHeight:"20px",
								wordBreak:"break-all"
							}}
							>
							
							</Typography>
							</Box>
						):(<Field
							name="pc_api_key"
							autoComplete="pc_api_key"
							as={TextField}
							type="text"
							variant="outlined"
							fullWidth
							placeholder="Client API Key"
							required
						/>)}
					  
                      <Typography
                        variant="subtitle2"
                        className={classes.InputLabel}
                      >
                        Confirmation Email
                      </Typography>
					  {selectedData.selected === true? (
							<Box>
							
							<Typography

							className={componetClasses.revealKey}
							style={{
								clear:"both",
								height:"auto",
								minHeight:"20px",
								wordBreak:"break-all"
							}}
							>
							
							</Typography>
							</Box>
						):(<Field
                        name="pc_confirmation_email"
                        autoComplete="pc_confirmation_email"
                        as={TextField}
                        type="email"
                        className={classes.InputText}
                        variant="outlined"
                        fullWidth
                        placeholder="Confirmation Email"
                        required
                      />)}
                      
                    </>
                  ) : (
                    ""
                  )}
                  {selectedData.p_name === "Mailchimp" ? (
                    <>
						<Typography
						variant="subtitle2"
						className={classes.InputLabel}
						style={{
							marginTop: "12px",
							float:"left",
							width:"100px",
						}}
						>
						Client API Key
						</Typography>
					  
						{selectedData.selected === true? (
							<Box>
							
							<Typography

							className={componetClasses.revealKey}
							style={{
								clear:"both",
								minHeight:"20px"
							}}
							>
							
							</Typography>
							</Box>
						):(<Field
							name="pc_api_key"
							autoComplete="pc_api_key"
							as={TextField}
							type="text"
							variant="outlined"
							margin="normal"
							className={classes.InputText}
							fullWidth
							placeholder="Client API Key"
							required
						/>)}
					  
                    </>
                  ) : (
                    ""
                  )}
                  {selectedData.p_name === "Hubspot" ? (
                    <>
						<Typography
						variant="subtitle2"
						className={classes.InputLabel}
						style={{
							marginTop: "12px",
							float:"left",
							width:"100px",
						}}
						>
						Client API Key
						</Typography>
					  
						{selectedData.selected === true? (
							<Box>
							<Typography

							className={componetClasses.revealKey}
							style={{
								clear:"both",
								minHeight:"20px"
							}}
							>
							
							</Typography>
							</Box>
						):(<Field
							name="pc_api_key"
							autoComplete="pc_api_key"
							as={TextField}
							type="text"
							variant="outlined"
							margin="normal"
							className={classes.InputText}
							fullWidth
							placeholder="Client API Key"
							required
						/>)}
					 
                    </>
                  ) : (
                    ""
                  )}
                  {selectedData.p_name === "Klaviyo" ? (
                    <>
						<Typography
						variant="subtitle2"
						className={classes.InputLabel}
						style={{
							marginTop: "12px",
							float:"left",
							width:"100px",
						}}
						>
						Client API Key
						</Typography>
					  
						{selectedData.selected === true? (
							<Box>
							<Typography

							className={componetClasses.revealKey}
							style={{
								clear:"both",
								minHeight:"20px"
							}}
							>
							</Typography>
							</Box>
						):(<Field
							name="pc_api_key"
							autoComplete="pc_api_key"
							as={TextField}
							type="text"
							error={!!errors.pc_api_key}
							helperText={errors.pc_api_key}
							variant="outlined"
							margin="normal"
							fullWidth
							className={classes.InputText}
							placeholder="Client API Key"
							required
						/>)}
					  
                    </>
                  ) : (
                    ""
                  )}
                  {selectedData.p_name === "Send Grid" ? (
                    <>
                      <Typography
                        variant="subtitle2"
                        className={classes.InputLabel}
                        style={{
                          marginTop: "12px",
						  float:"left",
						  width:"100px",
						  
                        }}
                      >
                        Client API Key
                      </Typography>
					  
						{selectedData.selected === true? (
							<Box>
							<Typography

							className={componetClasses.revealKey}
							style={{
								clear:"both",
								minHeight:"20px"
							}}
							>
							</Typography>
							</Box>
						):(<Field
							name="pc_api_key"
							autoComplete="pc_api_key"
							as={TextField}
							type="text"
							className={classes.InputText}
							error={!!errors.pc_api_key}
							helperText={errors.pc_api_key}
							variant="outlined"
							margin="normal"
							fullWidth
							placeholder="Client API Key"
							required
						/>)}

                    </>
                  ) : (
                    ""
                  )}
                  {selectedData.p_name === "Responsys" ? (
                    <>
						<Typography
						variant="subtitle2"
						className={classes.InputLabel}
						style={{
							marginTop: "12px",
							float:"left",
							width:"100px",
						}}
						>
						User Name
						</Typography>
					  
						{selectedData.selected === true? (
							<Box>
							<Typography

							className={componetClasses.revealKey}
							style={{
								clear:"both",
								minHeight:"20px"
							}}
							>
							</Typography>
							</Box>
						):(<Field
							name="pc_username"
							autoComplete="pc_username"
							as={TextField}
							type="text"
							variant="outlined"
							margin="normal"
							fullWidth
							className={classes.InputText}
							placeholder="User Name"
							required
						/>)}
					  
					  
						<Typography
						variant="subtitle2"
						className={classes.InputLabel}
						style={{
							float:"left",
							width:"100px"
						}}
						>
						Client API Key
						</Typography>
					  
						{selectedData.selected === true? (
							<Box>
							<Typography

							className={componetClasses.revealKey}
							style={{
								clear:"both",
								minHeight:"20px"
							}}
							>
							</Typography>
							</Box>
						):(<Field
							name="pc_password"
							autoComplete="pc_password"
							as={TextField}
							type="password"
							variant="outlined"
							margin="normal"
							fullWidth
							className={classes.InputText}
							required
							placeholder="Password"
						/>)}

                    </>
                  ) : (
                    ""
                  )}
					{selectedData.p_name === "Pardot" ? (
						<>
							<Typography
								variant="subtitle2"
								className={classes.InputLabel}
								style={{
									marginTop: "12px",
								}}
							>
								Email
							</Typography>
							{selectedData.selected === true? (
								<Box>
								<Typography

								className={componetClasses.revealKey}
								style={{
									clear:"both",
									minHeight:"20px"
								}}
								>
								</Typography>
								</Box>
							):(<Field
								name="pc_username"
								autoComplete="pc_username"
								as={TextField}
								type="text"
								variant="outlined"
								margin="normal"
								fullWidth
								className={classes.InputText}
								placeholder="Email"
								required
							/>)}
							
							<Typography
								variant="subtitle2"
								className={classes.InputLabel}
								style={{
									  float:"left",
									  width:"100px"
									}}
							>
								Password
							</Typography>
							
							{selectedData.selected === true? (
								<Box>
								<Typography

								className={componetClasses.revealKey}
								style={{
									clear:"both",
									minHeight:"20px"
								}}
								>
								</Typography>
								</Box>
							):(<Field
								name="pc_password"
								autoComplete="pc_password"
								as={TextField}
								type="password"
								variant="outlined"
								margin="normal"
								fullWidth
								className={classes.InputText}
								required
								placeholder="Password"
							/>)}
							
							<Typography
								variant="subtitle2"
								className={classes.InputLabel}
								style={{
										float:"left",
										
									}}
							>
								Consumer key
							</Typography>
							
							{selectedData.selected === true? (
								<Box>
								<Typography
								
								className={componetClasses.revealKey}
								style={{
									clear:"both",
									minHeight:"20px"
								}}
								>
								</Typography>
								</Box>
								):(<Field
								name="pc_api_id"
								autoComplete="pc_api_id"
								as={TextField}
								type="text"
								variant="outlined"
								margin="normal"
								fullWidth
								className={classes.InputText}
								required
								placeholder="Consumer key"
							/>)}
							
							<Typography
								variant="subtitle2"
								className={classes.InputLabel}
								style={{
										float:"left",
										
									}}
							>
								Consumer secret
							</Typography>
							
							{selectedData.selected === true? (
								<Box>
								<Typography
								
								className={componetClasses.revealKey}
								style={{
									clear:"both",
									minHeight:"20px"
								}}
								>
								</Typography>
								</Box>
								):(<Field
								name="pc_api_key"
								autoComplete="pc_api_key"
								as={TextField}
								type="text"
								variant="outlined"
								margin="normal"
								fullWidth
								className={classes.InputText}
								required
								placeholder="Consumer secret"
							/>)}
							
							<Typography
								variant="subtitle2"
								className={classes.InputLabel}
							>
								Business Unit ID
							</Typography>
							
							{selectedData.selected === true? (
								<Box>
								<Typography
								
								className={componetClasses.revealKey}
								style={{
									clear:"both",
									minHeight:"20px"
								}}
								>
								</Typography>
								</Box>
								):(<Field
								name="pc_account_id"
								autoComplete="pc_account_id"
								as={TextField}
								type="text"
								variant="outlined"
								margin="normal"
								fullWidth
								className={classes.InputText}
								required
								placeholder="Business Unit ID"
							/>)}
							
							<Typography
								variant="subtitle2"
								className={classes.InputLabel}
								style={{
										float:"left",
										width:"140px"
									}}
							>
								Formatting
							</Typography>
							<FormControl>
							
							<RadioGroup
							row
							aria-labelledby="demo-row-radio-buttons-group-label"
							name="pardot_formatting"
							defaultValue={selectedData.pardot_formatting}
							onChange={handleChangePardotFormatting}
							style={{
									marginTop: "9px",
									}}
							>
							<FormControlLabel value="PML" control={<Radio />} label="PML" />
							<FormControlLabel value="HML" control={<Radio />} label="HML" />
							
							</RadioGroup>
							</FormControl>
						</>
					) : (
						""
					)}
					{selectedData.p_name === "Marketo" ? (
						<>

							<Typography
								variant="subtitle2"
								className={classes.InputLabel}
							>
								API Host
							</Typography>
							{selectedData.selected === true? (
								<Box>
								
								<Typography
								
								className={componetClasses.revealKey}
								style={{
									clear:"both",
									minHeight:"20px"
								}}
								>
								</Typography>
								</Box>
								):(<Field
								name="pc_account_domain"
								autoComplete="pc_account_domain"
								as={TextField}
								type="text"
								variant="outlined"
								margin="normal"
								fullWidth
								className={classes.InputText}
								required
								placeholder="API Host"
							/>)}
							
							<Typography
								variant="subtitle2"
								className={classes.InputLabel}
								style={{
									  float:"left",
									  width:"100px"
									}}
							>
								Client ID
							</Typography>
							
							{selectedData.selected === true? (
								<Box>
								
								<Typography
								
								className={componetClasses.revealKey}
								style={{
									clear:"both",
									minHeight:"20px"
								}}
								>
								</Typography>
								</Box>
								):(<Field
								name="pc_api_id"
								autoComplete="pc_api_id"
								as={TextField}
								type="text"
								variant="outlined"
								margin="normal"
								fullWidth
								className={classes.InputText}
								required
								placeholder="Client ID"
							/>)}
							
							<Typography
								variant="subtitle2"
								className={classes.InputLabel}
								style={{
									  float:"left",
									  width:"100px"
									}}
							>
								Client Secret
							</Typography>
							
							{selectedData.selected === true? (
								<Box>
								<Typography
								
								className={componetClasses.revealKey}
								style={{
									clear:"both",
									minHeight:"20px"
								}}
								>
								</Typography>
								</Box>
								):(<Field
								name="pc_api_key"
								autoComplete="pc_api_key"
								as={TextField}
								type="text"
								variant="outlined"
								margin="normal"
								fullWidth
								className={classes.InputText}
								required
								placeholder="Client Secret"
							/>)}

						</>
					) : (
						""
					)}
               	   {selectedData.p_name === "Salesforce Cloud"? (  
					  <Stepper activeStep={activeStepSalesForceCloud} classes={{ root: classes.stepperContainer }}>
								{stepSalesForceCloud.map((label, index) => {
								const stepProps = {};
								const labelProps = {};
								return (
									<Step key={label} {...stepProps}>
										<StepLabel {...labelProps}>{label}</StepLabel>
									</Step>
								);
								})}
                                
					</Stepper>
                    
					) : ""
					}

                    {selectedData.p_name === "Salesforce Cloud" && cloudexport === false  ? (
						<>
						{selectedData.selected !== true ? (<Typography
								variant="subtitle2"
								style={{paddingBottom:25}}
							>
								Enter Salesforce Marketing Cloud package API settings below.
                                
							</Typography>) : ""}
                            <Box
                  top={50}
                  left={0}
                  // bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >{showLoader && <CircularProgress />}  </Box>
														
                                {selectedData.selected === true? (
                                <Field
                                name="client_id"
                                autoComplete="client_id"
                                as={TextField}
                                label="Client ID"
                                type="text"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                error={error}
                                value={'* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *'}
                                disabled = {true}
                                onChange={handleChange}
                                className={classes.InputTextOutlined}
                                required
                                 placeholder="Client ID"
                                />
                                ):(
                                <Field
                                name="client_id"
                                autoComplete="client_id"
                                as={TextField}
                                label="Client ID"
                                type="text"
                                variant="outlined"
                                margin="normal"
                                value={clientid}
                                fullWidth
                                error={error}
                                onChange={handleChange}
                                className={classes.InputTextOutlined}
                                required
                                placeholder="Client ID"
                                />

                                )}
							
							{selectedData.selected === true? (
								<Field
								name="client_secret"
                                 error={error}
								autoComplete="client_secret"
								as={TextField}
								type="text"
								variant="outlined"
								margin="normal"
								fullWidth
                                onChange={handleChange}
								className={classes.InputTextOutlined}
                                value={'* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *'}
                                disabled = {true}
								required
								placeholder="Client Secret"
								label="Client Secret"
							/>
								):(<Field
								name="client_secret"
                                 error={error}
								autoComplete="client_secret"
								as={TextField}
                                value={clientSecret}
								type="text"
								variant="outlined"
								margin="normal"
								fullWidth
                                onChange={handleChange}
								className={classes.InputTextOutlined}
								required
								placeholder="Client Secret"
								label="Client Secret"
							/>)}
							

                            {selectedData.selected === true? (
                            <Field
                            name="auth_base_url"
                            autoComplete="auth_base_url"
                            as={TextField}
                            type="text"
                            error={error}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={'* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *'}
                                disabled = {true}
                            className={classes.InputTextOutlined}
                            required
                            placeholder="Authentication Base URI"								
                            label="Authentication Base URI"
                            />
                            ):(
                            <Field
                            name="auth_base_url"
                            autoComplete="auth_base_url"
                            as={TextField}
                            type="text"
                            error={error}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                            value={authBaseUrl}
                            fullWidth
                            className={classes.InputTextOutlined}
                            required
                            placeholder="Authentication Base URI"								
                            label="Authentication Base URI"
                            />

                            )}
                            
                            {selectedData.selected === true? (
                            <Field
                            name="rest_base_url"
                            autoComplete="rest_base_url"
                            as={TextField}
                            onChange={handleChange}
                             type="text"
                            error={error}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={'* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *'}
                            disabled = {true}
                            className={classes.InputTextOutlined}
                            required
                            placeholder="Rest Base URI"								
                            label="Rest Base URI"
                            />
                            ):(
                            <Field
                            name="rest_base_url"
                            autoComplete="rest_base_url"
                            as={TextField}
                            onChange={handleChange}
                             type="text"
                            error={error}
                            value={BaseUrl}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            className={classes.InputTextOutlined}
                            required
                            placeholder="Rest Base URI"								
                            label="Rest Base URI"
                            />

                            )}
                            

						</>
					) : (
						""
					)}
                    
                    
                    
                    {selectedData.p_name === "Salesforce Cloud" && cloudexport === true ? (
                        <div>
							<Typography
								variant="subtitle2"
							>
								Select the default export folder for all email exports.
							</Typography>
							<TreeView
							defaultCollapseIcon={<ExpandMoreIcon />}
							defaultExpandIcon={<ChevronRightIcon />}
							expanded={expanded}
							onNodeToggle={handleToggle}
							onNodeSelect={handleSelect}
							className={classes.salesforceTree}
							>
							{renderTree(cloudFoldersnew)}
							</TreeView>
                        </div>
					) : (
						""
					)}
                    
                    
                    
                </Grid>
              </Grid>

              <DialogActions className={classes.modelFooter}>
                <Button
                  onClick={handleClose}
                  className={componetClasses.cancelButton}
                  variant="outlined"
                >
                  Cancel
                </Button>
                
                    { selectedData.selected === true
                    ? <Button
                      variant="contained"
                      onClick={handleDisconnect}
                      className={classes.errorButton}
                    >
                      Disconnect
                    </Button>
                    : <>
                    {selectedData.p_name === "Salesforce Cloud" && cloudexport===false
                    ?  <div><Button 
								endIcon={<ArrowForwardIcon />} 
								type="submit" 
								onClick={OpenExportview} 
								variant="contained" 
								color="primary"
								className={classes.nextBtn}
							>
                          NEXT
                      </Button>
                      </div>
                      
                    : <>
                    {selectedData.p_name === "Salesforce Cloud"
                    ?  <div><Button  
								startIcon={<ArrowBackIcon />}
								className={classes.backBtn}
								type="button" 
								onClick={CloseExportview} 
								variant="outlined" 
								color="primary"
							 >
                          BACK
                      </Button>
                       <Button className={classes.completeBtn} type="submit" variant="contained" color="primary">
					   		COMPLETE
                       </Button>
                       
                      </div>
                      
                    :  <div>
                      <Button type="submit" variant="contained" color="primary">
                          CONNECT
                       </Button>
                      </div>
                      
                    }
                    </>
                      
                    }
                    </>
                    }


                
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default DialogBox;
