import React, { useState, useCallback} from "react";
import { useStyles } from "../../style";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardMedia from "@material-ui/core/CardMedia";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import InfiniteScroll from "react-infinite-scroll-component";
import LinearProgress from "@material-ui/core/LinearProgress";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoveFile from "./utilities/MoveFile";
import DeleteFile from "./utilities/DeleteFile";
import RenameFile from "./utilities/RenameFile";
import UpdateImage from "./utilities/UpdateImage";
import AxiosInstance from "../../../../../../../utils/AxiosInstance";
import {isDesigner, isMarketer, isTrial} from "../../../../../../../utils/AuthUtility";
import {AxiosResponse} from "axios";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
interface Props {
  fileMapData: {
    company_id?: string;
    file_name?: string;
    file_size?: string;
    id?: string;
    image_attr?: [];
    image_id?: string;
    orgpath?: string;
    path?: string;
    tag_id?: string;
  }[];
  getImagesByComp: any;
  listCompleteStatus: any;
  screenShotItem: any;
  setScreenShotItem: any;
  history: any;
  getImagesByCompInitial: any;
  getImagesByCompInitial1: any;
  setImagesByComp: any;
  brandID: string;
  setDragOverFolder: any,
  dragOverFolder: any,
  onSelect?: (path: string, originId?: number) => void;
  isHeadless: boolean;
  selectedImages: any;
}

const FileList: React.FC<Props> = ({
  fileMapData,
  getImagesByComp,
  listCompleteStatus,
  history,
  getImagesByCompInitial,
  getImagesByCompInitial1,
  brandID,
  setImagesByComp,
  setDragOverFolder,
  onSelect,
  isHeadless,
  selectedImages
}) => {
  const classes = useStyles();

  // move releted functions
  const [openMoveDialog, setOpenMoveDialog] = React.useState<boolean>(false);
  const [selectedCampId, setSelectedCampId] = React.useState<number>(0);
  const [selectedImageLink, setSelectedImageLink] = React.useState<string>("");
  const [selectedImageName, setSelectedImageName] = React.useState<string>("");
 
  const openMoveFunc = (event: any) => {
    setSelectedCampId(event.currentTarget.dataset.id);
    setSelectedImageLink(event.currentTarget.dataset.value);
    setSelectedImageName(event.currentTarget.dataset.name)
    setOpenMoveDialog(true);
  };
  const copyURL = (event: any) => {
	var rowIndex= event.currentTarget.dataset.index;
    var id =event.currentTarget.dataset.id;
    var path=event.currentTarget.dataset.value;
	var FormData = require("form-data");
    var data = new FormData();
	data.append("origin_id", id);
	if(path.indexOf('objectstorage') === -1){
		var toastId = toast.success('Please wait....processing your request.',{autoClose: false});
		setOpenImageID(id);
		AxiosInstance.post('api/v1/Contents/update_gallery',data)
		.then((response: AxiosResponse) => {
			if(response.data.data.src){
				navigator.clipboard.writeText(response.data.data.src);
				//var src=response.data.data.src;
				fileMapData[rowIndex].path = response.data.data.src;
				setImagesByComp([...fileMapData]);
				toast.update(toastId, {
					render: "URL copeid into your clipboard",
					autoClose: 3000
				});

			}
		});
	}else{
		navigator.clipboard.writeText(path);
		toast.success('URL copeid into your clipboard',{autoClose: 3000});
	}
  };

  const handleDragStart = useCallback((e: any, option: any) => {
    e.dataTransfer.setData("imageId", option.id)
  }, [])

  const handleDragEnd = useCallback((e: any) => {
    setDragOverFolder("")
  }, [setDragOverFolder])


  // rename section
  const [openRenameDialog, setOpenRenameDialog] =
    React.useState<boolean>(false);
  const [renameTextID, setRenameTextID] = useState(0);
  const [newImageName, setNewImageName] = React.useState<string>("");

  const openRename = (event: any) => {
    const selectedFolderName = event.currentTarget.id;
    const selectedFolderID = event.currentTarget.value;
    setNewImageName(selectedFolderName);
    setRenameTextID(selectedFolderID);
    setOpenRenameDialog(true);
  };

  // delete releted functions
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openDeleteType, setOpenDeleteType] = useState<boolean>(true);
  const [deleteImageID, setDeleteImageID] = useState<number>();

  const openDelete = (event: any) => {
    const selectedImageID = event.currentTarget.dataset.id;
    setDeleteImageID(selectedImageID);
	AxiosInstance.get('api/v1/Contents/confirm_delete_image', {
		params: {
			image_id: selectedImageID,
		},
	}).then((response: AxiosResponse) => {
		setOpenDeleteType(response.data.data.success);
		setOpenDeleteDialog(true);
    });
    
  };

  const handleCampaignDelete = () => {
  	AxiosInstance.delete('api/v1/Contents/delete_image', {
		params: {
			image_id: deleteImageID,
		},
	}).then((response: AxiosResponse) => {
        if (response.data.status === true) {
          getImagesByCompInitial();
        }
      })
  };
  const validateUpdate = (id:any) => {
	  if(isHeadless === true){
		 return '';
	  }else{
		  if(imageUpdated ===true && id===openImageID){
			  return '?time='+Date.now();
		  }
	  }
	  return '';
  };
 
	const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(true);
	const [imageUpdated, setImageUpdated] = React.useState<boolean>(false);
	const [openUpdateImagePopUp, setOpenUpdateImage] =
	React.useState<boolean>(false);
	const [openImageID, setOpenImageID] = React.useState<number>(0);
	const [imgExt, setImgExt] = React.useState<string>("");
	const [imgWidth, setImgWidth] = React.useState<number>(0);
	const [imgHeight, setImgHeight] = React.useState<number>(0);
	const openUpdateImage = (event: any) => {
	const selectedFolderName = event.currentTarget.id;
		setImageUpdated(false);
		setOpenImageID(selectedFolderName);
		setImgExt(event.currentTarget.dataset.img_ext);
		setImgWidth(event.currentTarget.dataset.img_width);
		setImgHeight(event.currentTarget.dataset.img_height);
		setOpenUpdateImage(true);
		setButtonDisabled(true);
	};
	
  return (
    <div style={{width: '100%'}}>
      <MoveFile
        open={openMoveDialog}
        setOpen={setOpenMoveDialog}
        history={history}
        getloadCampsInitial={getImagesByCompInitial}
        selectedCampId={selectedCampId}
        brandID={brandID}
        selectedImageLink={selectedImageLink}
        selectedImageName={selectedImageName}
      />
      <DeleteFile
        handleCampaignDelete={handleCampaignDelete}
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
		deleteType={openDeleteType}
      />
      <UpdateImage
        open={openUpdateImagePopUp}
        setOpen={setOpenUpdateImage}
        history={history}
        refreshCampImages={getImagesByCompInitial}
        openImageID={openImageID}
		imgExt={imgExt}
		imgWidth={imgWidth}
		imgHeight={imgHeight}
		buttonDisabled={buttonDisabled}
		setButtonDisabled={setButtonDisabled}
		setImageUpdated={setImageUpdated}
      />
      <RenameFile
        open={openRenameDialog}
        renameTextID={renameTextID}
        setNewImageName={setNewImageName}
        newImageName={newImageName}
        setOpen={setOpenRenameDialog}
        getImagesByCompInitial={getImagesByCompInitial}
      ></RenameFile>
      <InfiniteScroll
        dataLength={fileMapData.length}
        next={getImagesByComp}
        hasMore={listCompleteStatus}
        height={`${localStorage.getItem("trial_mode") === 'true' ? "calc(100vh - 378px)": "calc(100vh - 322px)"}`}
        loader={<LinearProgress />}
        style={{ paddingTop: "14px", paddingLeft: "5px" }}
      >
	  
      <Box display="flex" flexWrap="wrap" width="100%" className={classes.galleryGridLayout}>
	  {selectedImages.map((option: any, index: any) => {
		return (
		<Box p={1} style={{ height: "172px"}}>
			<div className={classes.skeltonView} style={{ height: "172px"}}>
				<div style={{ width: "100%", marginTop:"30%",textAlign:'center' }}>
					<Box position="relative" display="inline-flex">
					 <CircularProgress color="primary" />
					</Box>
				</div>
			</div>
		</Box>
		);
		})}
		
          {fileMapData.map((option: any, index: any) => {

            return (
              <Box p={1} key={option.id}  draggable={((isDesigner() || isMarketer()) && isTrial() === false) ? true : false}
                onDragStart={(e) => handleDragStart(e, option)}
                onDragEnd={(e) => handleDragEnd(e)}
              >
                <div className={classes.newCampaignDiv}>
                  <div className={classes.fileHeader}>
                    <div style={{ width: "100%" }}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <Typography
                            variant="body1"
                            className={classes.fileTypo}
                          >
                            {option.file_name}
                          </Typography>
                          <Typography
                            variant="body1"
                            className={classes.fileTypoText}
                          >
                            {option.image_attr[0]}px X{" "}
                            {option.image_attr[1]}px{" "}
                            {option.file_size}
                          </Typography>
                        </Box>
						{ (isDesigner() || isMarketer()) &&
                        <Box p={1}>
                          <PopupState
                            variant="popover"
                            popupId="demo-popup-menu"
                          >
                            {(popupState: any) => (
                              <React.Fragment>
                                <MoreHorizIcon
                                  className={classes.dotIcon}
                                  {...bindTrigger(popupState)}
                                />
                                <Menu {...bindMenu(popupState)}>
                                  <div onClick={popupState.close}>
                                    <MenuItem
                                      id={option.id}
									  data-img_ext={option.img_ext}
									  data-img_width={option.img_width}
									  data-img_height={option.img_height}
                                      onClick={openUpdateImage}
									  disabled={isTrial()}
                                    >
                                      <Typography>
                                        Replace
                                      </Typography>
                                    </MenuItem>
                                  </div>
                                  <div onClick={popupState.close}>
                                    <MenuItem
                                      id={option.file_name}
                                      value={option.id}
                                      onClick={openRename}
									  disabled={isTrial()}
                                    >
                                      <Typography>
                                        Rename
                                      </Typography>
                                    </MenuItem>
                                  </div>
                                  <div onClick={popupState.close}>
                                    <MenuItem
                                      data-id={option.id}
                                      data-value={option.path}
                                      data-name={option.file_name}
                                      onClick={openMoveFunc}
									  disabled={isTrial()}
                                    >
                                      <Typography>
                                        Move To Folder
                                      </Typography>
                                    </MenuItem>
                                  </div>
								  <div onClick={popupState.close}>
                                    <MenuItem
									  data-index={index}
                                      data-id={option.id}
                                      data-value={option.path}
									  onClick={copyURL}
                                    >
                                      <Typography>
                                       Copy URL
                                      </Typography>
                                    </MenuItem>
                                  </div>
                                  <div onClick={popupState.close}>
                                    <MenuItem
                                      data-id={option.id}
                                      onClick={openDelete}
                                      className={
                                        classes.deleteItem
                                      }
									  disabled={isTrial()}
                                    >
                                      <Typography>
                                        Delete
                                      </Typography>
                                    </MenuItem>
                                  </div>
                                </Menu>
                              </React.Fragment>
                            )}
                          </PopupState>
                        </Box>
						}
                      </Box>
                    </div>
                  </div>
                  <Card elevation={0}>
                    <CardMedia
                      image={option.path+validateUpdate(option.id)}
                      className={`
                      ${classes.newCampaignCardImage}
                      ${(option.image_attr[0] >= 242 &&  option.image_attr[1] >= 172) ? classes.newCampaignCardImageCover : "" }
                      `}
                    />
					  { onSelect && <Button variant="contained"
											color="primary"
											className={classes.selectButton}
											onClick={() => {
											  onSelect(option.path);
											}
					  }>
						  Select
					  </Button>}
                  </Card>
                </div>
              </Box>
            );
          })}
        </Box>
      </InfiniteScroll>
    </div>
  );
};
export default FileList;
