import AxiosInstance from "../../../../utils/AxiosInstance";
import { toast } from "react-toastify";
import { store } from '../../../redux/store';
//import { useDispatch } from "react-redux";
import {setFlashMessage} from "../../../../utils/AuthUtility";
//import {addField } from '../../../redux/actions/styleGuideActions';

 const LogoImageAutosave = async (file:any) => {
  var styleId: any = null;
  const stateInitial = store.getState();
  const state = stateInitial.styleGuide;
  
 
  var formdata = new FormData();
  const data = localStorage.getItem("loggedInUser")
  if(localStorage.getItem("styleGuideResID")==='' || localStorage.getItem("styleGuideResID")===null)
  {
     styleId=localStorage.getItem("rowId")
  }
  else
  {
     styleId = localStorage.getItem("styleGuideResID");
  }
  let Email = ""
  if (data) {
    const { user_detail } = JSON.parse(data)
    Email = user_detail.Email
  }

  formdata.append(
    "company_id",
    localStorage.getItem("usr_company_id") ||
    localStorage.getItem("company_id") ||
    ""
  );

  formdata.append(
    "file", file
  );


  formdata.append("Email", Email || "");
  formdata.append("styleguide_name", state.styleguideName);
  if(state.styleguideId){
		styleId = state.styleguideId;
	}
  if(styleId)
  {
    formdata.append("autosave_action", 'save'); 
    formdata.append("styleguideId", styleId);
  }

  return await AxiosInstance.post(`api/styleguide/replacelogofile`, formdata)
    .then((result) => {
        if(result.data.status_code === '404')
        {
               toast.error('Sorry You are not authorized to perform this action');
               return false;
        }
        
          let MsgContainer: HTMLDivElement | null = document.querySelector("#FlsMsgAlert");
          if (MsgContainer) {
              MsgContainer.setAttribute("style", "display:none;");
          var dt = new Date();
          if(setFlashMessage(dt.toUTCString()) === undefined)
          {
              MsgContainer.setAttribute("style", "display:none;float:left;margin-top:3px;margin-right:29px");
              MsgContainer.innerHTML = 'All Saved ✔ a few seconds ago';
          }
          else
          {
             
               MsgContainer.innerHTML = 'Last Saved ✔ '+setFlashMessage(dt.toUTCString());
          }
             localStorage.removeItem("LastSavedTime");
             localStorage.setItem('LastSavedTime', dt.toISOString());
          }
          let styleCloseBox: HTMLDivElement | null = document.querySelector("#styleClosebut");
          if (styleCloseBox) {
              styleCloseBox.setAttribute("style", "background:#0a6adc;");
              
          }
          return result.data.src_url;
          // localStorage.setItem('styleGuideResID', result.data.insert_id);
    })
    .catch(function (error: any) {
      if (error.response) {
        if (error.response.status === 400) {
          toast.warn(error.response.data.message);
        }
        if (error.response.status === 500) {
          alert("500, Server Error");
          toast.error(error.response.data.message);
        }
        return "";
      }
      
    });


//toast("Saved Successfully");
}

export const LogoURLAutosave = async (logoUrl:any) => {
  var styleId: any = null;
  const stateInitial = store.getState();
  const state = stateInitial.styleGuide;
  if(state.styleguideName === '' || state.styleguideName===undefined)
  {
       toast.error('Data not saved! Design system name can not be empty.');
       return false;
  }
 
  var formdata = new FormData();
  const data = localStorage.getItem("loggedInUser")
  if(localStorage.getItem("styleGuideResID")==='' || localStorage.getItem("styleGuideResID")===null)
  {
     styleId=localStorage.getItem("rowId")
  }
  else
  {
     styleId = localStorage.getItem("styleGuideResID");
  }
  let Email = ""
  if (data) {
    const { user_detail } = JSON.parse(data)
    Email = user_detail.Email
  }

  formdata.append(
    "company_id",
    localStorage.getItem("usr_company_id") ||
    localStorage.getItem("company_id") ||
    ""
  );

  formdata.append(
    "url", logoUrl
  );


  formdata.append("Email", Email || "");
  formdata.append("styleguide_name", state.styleguideName);
	if(state.styleguideId){
		styleId = state.styleguideId;
	}
  if(styleId)
  {
    formdata.append("autosave_action", 'save'); 
    formdata.append("styleguideId", styleId);
  }

  await AxiosInstance.post(`api/styleguide/replacelogourl`, formdata)
    .then((result) => {
        if(result.data.status_code === '404')
        {
               toast.error('Sorry You are not authorized to perform this action');
               return false;
        }
        state.logo.src_url = result.data.src_url;
        
          let MsgContainer: HTMLDivElement | null = document.querySelector("#FlsMsgAlert");
          if (MsgContainer) {
              MsgContainer.setAttribute("style", "display:block;");
          var dt = new Date();
          if(setFlashMessage(dt.toUTCString()) === undefined)
          {
              MsgContainer.setAttribute("style", "display:inline-block;float:left;margin-top:3px;margin-right:29px");
              MsgContainer.innerHTML = 'All Saved ✔ a few seconds ago';
          }
          else
          {
             
              MsgContainer.innerHTML = 'Last Saved ✔ '+setFlashMessage(dt.toUTCString());
          }
             localStorage.removeItem("LastSavedTime");
             localStorage.setItem('LastSavedTime', dt.toISOString());
          }
          let styleCloseBox: HTMLDivElement | null = document.querySelector("#styleClosebut");
          if (styleCloseBox) {
              styleCloseBox.setAttribute("style", "background:#0a6adc;");
              
          }
          
          // localStorage.setItem('styleGuideResID', result.data.insert_id);
    })
    .catch(function (error: any) {
      if (error.response) {
        if (error.response.status === 400) {
          toast.warn(error.response.data.message);
        }
        if (error.response.status === 500) {
          alert("500, Server Error");
          toast.error(error.response.data.message);
        }
      }
      
    });


//toast("Saved Successfully");
}


export default LogoImageAutosave;