import React, { useEffect, useState } from "react";
import { Box, Button, InputAdornment, TextField, Typography } from "@material-ui/core";
import { useStyles } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../../redux/actions/styleGuideActions";
import { DefaultCustomNameList } from "../../utilities/DefaultStyleGuide";
import { CustomFontUploadAutosave } from "./CustomFontUploadAutosave";
import { updateCustomCssStyleSheet } from "../../../../../../utils/Functions";

interface Props {
  data:any;
}

const StyleItemSource: React.FC<Props> = ({data}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.styleGuide);  
  const [sourceLink, setSourceLink] = useState(data?.source ? data?.source : "");
  const [sourceChanged, setSourceChanged] = useState(false);

  useEffect(() => {
    const delayTypingName = setTimeout(() => {
      if(sourceChanged) {
        setSourceChanged(false);
        handleURLChange(sourceLink);
      }
    }, 1200);
  
    // Clear the timeout if customName changes before 1200ms
    return () => clearTimeout(delayTypingName);
    // eslint-disable-next-line
  }, [sourceLink]);


  useEffect(() => {
    if(sourceLink !== data?.source ){
      setSourceLink(data?.source);
    }
    // eslint-disable-next-line
  }, [data?.source]);

  const removeStyleFromListError = () => {
    if(state?.StyleItemsEmpty){
      const newList: any[] = state?.StyleItemsEmpty?.filter((item:any) => item !== data.key);
      dispatch(addField("StyleItemsEmpty", newList));
    }
  }

  const handleURLChange = (source:any) => {   
    removeStyleFromListError();

    let arr = state?.list_custom_fonts;
    arr?.forEach((item:any) => {      
      // Loop through the styles array within each item
      item?.styles?.forEach((style:any) => {
        if(style.key === data.key && style["source"] !== source){
          style["source"] = source;
          if(item.type === "googlefonts"){
            item["type"] = "customfonts";
            item["css_link"] = "";
            item["family"] = "Custom New";
          }
          dispatch(addField("list_custom_fonts", arr));          
          updateCustomCssStyleSheet(arr);
        }
      });
    });
  }
  // const addNewAttrToArrStyle = async (arr:any, attr:string,value:any) => {
  //   let newArr = arr;
  //   arr?.forEach((item:any) => {
  //     item?.styles?.forEach((style:any) => {
  //       if(style.key === data.key){
  //         style[attr] = value;
  //       }
  //     });
  //   });

  //   return newArr;
  // }

  const handleUploadFontClick = async (event:any) => {
    removeStyleFromListError();
    var file = event.target.files[0];
    const reader = new FileReader(); 
    reader.readAsDataURL(file);
    reader.onloadend = async function (e) {
      if(file){
        let arr = state?.list_custom_fonts;
        const name = file.name;
        arr?.forEach((item:any) => {
          item?.styles?.forEach((style:any) => {
            if(style.key === data.key){
              style["source"] = name;
            }
          });
        });
        dispatch(addField("list_custom_fonts", arr)); 
        
        arr?.forEach((item:any) => {
          item?.styles?.forEach((style:any) => {
            if(style.key === data.key){
              style["source"] = name;
              if(item.type === "googlefonts"){
                item["type"] = "customfonts";
                item["css_link"] = "";
                item["family"] = "Custom New";
                DefaultCustomNameList?.forEach((option:any) => {
                  if(item.key === state["key_" + option]) {
                    dispatch(addField(option, "Custom New"));
                  }
                });
              }
            }
          });
        });

        const customFont = await CustomFontUploadAutosave(file, "fontstyle", "fontName");   
        arr?.forEach((item:any) => {
          item?.styles?.forEach((style:any) => {
            if(style.key === data.key){
              style["source"] = customFont;
            }
          });
        });
        dispatch(addField("list_custom_fonts", arr));        
        updateCustomCssStyleSheet(arr);

      }
    }   
    
  }
  


  return (    
    <>
      {
        data.type === "FILE" && (
          <Box display="flex">              
            {!data?.source && <Button
              component="label"                    
              variant="outlined"
              color="primary"
              className={`${classes.styleBtnUpload} ${classes.styleButtonItem}
              `}
              style={{
                color: state?.StyleItemsError === true && state?.StyleItemsEmpty.includes(data.key) ? "#dc004e" : "",
                borderColor: state?.StyleItemsError === true && state?.StyleItemsEmpty.includes(data.key) ? "#dc004e" : ""
              }}
            >
              { !data?.source ? (<>UPLOAD</>) : (<>REPLACE</>) }
              <input
                type="file"
                accept=".eot,.ttf,.otf,.woff,.woff2,.svg"
                hidden
                onChange={handleUploadFontClick}
                />
            </Button>}
            {state?.StyleItemsError === true && state?.StyleItemsEmpty.includes(data.key) && (
              <Typography variant="caption" color="error" style={{display:"flex",alignItems:"center"}}>
                This field cannot be left blank
              </Typography>
            )}
            {data?.source && <TextField
              value={data?.source?.split('/').pop() || ""}
              className={classes.styleFontsInput}
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box></Box>
                  </InputAdornment>
                ),
              }}
            />}
          </Box>
        )
      }
      {
        data.type === "URL" && (
          <Box display="flex">
            <TextField
              className={classes.styleUrlSource}
              size="small"
              value={sourceLink}
              label="Source"
              style={{ marginTop: 0,marginBottom: 0 }}
              fullWidth
              error={(state?.StyleItemsError === true && state?.StyleItemsEmpty.includes(data.key) ? true : false)}
              helperText={(state?.StyleItemsError === true && state?.StyleItemsEmpty.includes(data.key) ? 'This field cannot be left blank' : '')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) =>{
                setSourceChanged(true);
                setSourceLink(e.target.value);
              }}
              variant="outlined"
            />
          </Box>
        )
      }
    </>
  );
};

export default StyleItemSource;
