import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {isMarketer}  from "../../../../utils/AuthUtility";
import { Skeleton } from "@material-ui/lab";

interface Props {
} 

const SkeletonTrackingList: React.FC<Props> = () => {
  
  return (
    <>
          <TableBody>
            <TableRow >
              <TableCell align="left" width="90%" >                   
                <Skeleton
                  animation="wave"
                  variant="rect"
                />
              </TableCell>
              {(!isMarketer()) && (
                <TableCell align="left" >
                   <Skeleton
                      animation="wave"
                      variant="rect"
                    />
                </TableCell>
              )}
            </TableRow>
            <TableRow >
              <TableCell align="left" width="90%" >                   
                <Skeleton
                  animation="wave"
                  variant="rect"
                />
              </TableCell>
              {(!isMarketer()) && (
                <TableCell align="left" >
                   <Skeleton
                      animation="wave"
                      variant="rect"
                    />
                </TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell align="left" width="90%" >                   
                <Skeleton
                  animation="wave"
                  variant="rect"
                />
              </TableCell>
              {(!isMarketer()) && (
                <TableCell align="left" >
                   <Skeleton
                      animation="wave"
                      variant="rect"
                    />
                </TableCell>
              )}
            </TableRow>
          </TableBody>
    </>
  );
};

export default SkeletonTrackingList;
