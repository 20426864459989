import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { useStyles } from "./style";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Gallery from "./images/Gallery";
import CreateNewImage from "./utilities/CreateNewImage";
import { getUserFromToken,isTrial} from "../../../../utils/AuthUtility";
import TrialPopup from "../../../../utils/TrialPopup";
//import AxiosInstance from "../../../../utils/AxiosInstance";
//import {AxiosResponse} from "axios";
import Gpt from "./openAI/gpt";


 interface Props {
  history?: any;
  isHeadless?: boolean;
  enableTemplate?: boolean;
  enableGallery?: boolean;
  enableOpenAI?: boolean;
  onSelect?: (path: string, originId?: number) => void;
  onTextBoxSelect?: (path: string, originId?: number) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

 function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}


  
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
const ContentIndex: React.FC<Props> = ({ history, isHeadless, enableTemplate,enableGallery,enableOpenAI, onSelect,onTextBoxSelect }) => {
	isHeadless = isHeadless !== undefined ? isHeadless : false;
	enableTemplate = enableTemplate !== undefined ? enableTemplate : true;
    enableGallery = enableGallery !== undefined ? enableGallery : true;
    enableOpenAI = enableOpenAI ? enableOpenAI : false;

  const classes = useStyles();
  const loggedInUser = getUserFromToken();

  const [contentTab, setContentTab] = React.useState(0);
  const brandID = loggedInUser?.user_detail?.Company;
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setContentTab(newValue);
  };

    const [trialPopupStatus, setTrialPopupStatus] = React.useState<boolean>(false);
	//const [openTemplate, setOpenTemplate] = React.useState<boolean>(false);
    const [openImage, setOpenImage] = React.useState<boolean>(false);
	const [buttonDisabledAdd, setButtonDisabledAdd] = React.useState<boolean>(false);
    //const [templateFolderID, setTemplateFolderID] = React.useState<any>([]);
    const [imageFolderID, setImageFolderID] = React.useState<any>([]);
    const [createImage, setImagesCount] = React.useState<number>(0);

	const [selectedImages, setSelectedImages] = React.useState<any>([]);

    //const [openFlag, setopenFlag] = React.useState<number>(0);
    //const [styleFetchData, setstyleFetchData] = React.useState<any>([]);
    //const [stylecount, setstylecount] = React.useState<any>(0);


	const [progress, setProgress] = React.useState(10);
	const [timer, setTimer] = React.useState<any>();
	function setProgressBar(){
		setTimer(setInterval(() => {
			setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));
		}, 1000));
		
	}
    //const [openFlag, setopenFlag] = React.useState<number>(0);

    //const [styleFetchData, setstyleFetchData] = React.useState<any>([]);
    //const [stylecount, setstylecount] = React.useState<any>(0);
    ///const [mtemplateStatus, setmtemplateStatus] = React.useState<boolean>(false);
    //const [marketoFetchData, setmarketoFetchData] = React.useState<any>([]);

    //const [styleFetchData, setstyleFetchData] = React.useState<any>([]);
    //const [stylecount, setstylecount] = React.useState<any>(0);



	useEffect(() => {
	
	 if(progress>=100){
		clearInterval(timer);
	 }
	 // eslint-disable-next-line
	}, [progress]);
    function getParam(param : any){
        return new URLSearchParams(window.location.search).get(param);
    }
  const funcsetOpenImage = () => {
	if(!isTrial()){
		setOpenImage(true);
		setButtonDisabledAdd(false);
	}else{
		setTrialPopupStatus(true);
	}
  };
  const refreshCampImages = () => {
    //setImagesCount(createImage + 1);
  };
   const refreshCampImages1 = (cnt:any) => {
    setImagesCount(cnt);
  };
  
   /*const SetOpenModal = () => {
	if(!isTrial()){
		//setOpenTemplate(true);
		if(openFlag)
		{
		setopenFlag(0);
		} else { 
		setopenFlag(1);
		}
	}else{
		setTrialPopupStatus(true);
	}
  };
  */
  /*const loadStyleGuideData = () => {
       var totalLength = 0;
  	AxiosInstance.get(`api/getStyleguide/${localStorage.getItem("company_id")}`)
      .then((response: AxiosResponse) => {
          if(Object.keys(response.data.usertable).length>0)
          {
              totalLength = Object.keys(response.data.usertable).length;
              //setstyleFetchData(response.data.usertable);
              //setdefaultStyle(response.data.usertable[0]['id']);
              //localStorage.setItem('defaultStyleId', response.data.usertable[0]['id']);
              setstylecount(totalLength);
          }
      });
  };
  const getDisabled = () => {
	  if(!isDesigner()){
		  return classes.disabledList;
	  }
	  return '``';
  };

  */

  useEffect(() => {
   //loadStyleGuideData();
   if(getParam('tab')!=null)
   {
         setContentTab(0);
   }
        var str = window.location.href;
        var tmp = str.split("/");
		var pop = tmp.pop();
		
        if(pop === 'openai')
        {
            setContentTab(2);
        }
		
	
   
    // eslint-disable-next-line 
  }, []);

  return (
    <Box className={`${classes.componentRoot} ${enableOpenAI ? classes.componentOpenAI : ""} ${localStorage.getItem("trial_mode") === 'true' ? classes.hasTrialInfo: ""}`} style={{paddingTop: isHeadless ? '30px' : 'auto', height: isHeadless ? 'calc(100vh - 30px)' : 'auto'}}>
		
           
        <TrialPopup
	    trialPopupStatus={trialPopupStatus}		
		setTrialPopupStatus={setTrialPopupStatus}
      />
		
      {enableGallery && <CreateNewImage
        open={openImage}
        setOpen={setOpenImage}        
        imageFolderID={imageFolderID}
        history={history}
        brandID={brandID}
        refreshCampImages={refreshCampImages}
		refreshCampImages1={refreshCampImages1}
		buttonDisabledAdd={buttonDisabledAdd}
		setButtonDisabledAdd={setButtonDisabledAdd}
		setSelectedImages={setSelectedImages}
		selectedImages={selectedImages}
		setProgress={setProgress}
		setProgressBar={setProgressBar}
      />}
      <Box className={classes.boxWidth} style={{maxWidth: isHeadless ? "calc(100% - 50px)" : '1236px'}}>
      {isHeadless ?(<Box display="flex">
          <Box width="100%">
            <Typography
              component="h1"
              align="left"
              className={classes.heading}
              variant="h5"
            >
				      Select an Image
            </Typography>
          </Box>
        </Box>) : "" }
        {enableGallery || enableTemplate ?(
        <div className={classes.componentHeader}>
          <div>
            <Tabs
              style={{ height: "100%" }}
              value={contentTab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >          
                 <Tab style={{pointerEvents: isHeadless ? 'none' : 'all'}}
                className={classes.TabName}
                label="Images"
                {...a11yProps(1)}
                 />
            </Tabs>
          </div>
          
        </div>
         ) : "" }

        {enableGallery && <TabPanel value={contentTab} index={0}>
          <Gallery
            setImageFolderID={setImageFolderID}
            imageFolderID={imageFolderID}
            createImage={createImage}
			onSelect={onSelect}
			isHeadless={isHeadless}
            funcsetOpenImage={funcsetOpenImage}
			selectedImages={selectedImages}
          />
        </TabPanel>}
        
        {enableOpenAI && <TabPanel value={contentTab} index={2}>
            <Gpt
            setImageFolderID={setImageFolderID}
            imageFolderID={imageFolderID}
            createImage={createImage}
			onTextBoxSelect={onTextBoxSelect}
			isHeadless={isHeadless}
          />
        </TabPanel>}
         
      </Box>
    </Box>
  );
};

export default ContentIndex;
