import React, { Dispatch, SetStateAction, useEffect,useState } from "react";
import {
  Dialog,
  Button,
  Box,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SimpleTree from "../../../../../../../../common/components/SimpleTree";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AxiosInstance from "../../../../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedFolderName: string;
  history: any;
  getloadCampsInitial: any;
  selectedCampId:number;
  brandID:string
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  treeViewDiv: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  treeViewFolder: {
    color: "#e0e0e0",
  },
  treeViewItem: {
    borderRadius: "5px",
  },
  divRoot: {
    "&.Mui-selected  > .MuiTreeItem-content": {
      "& $tickIcon": {
        color: "#73a8e2",
        paddingLeft: "5rem",
        display: "block",
      },
      "& $treeViewFolder": {
        color: "#73a8e2",
      },
    },
  },
  label: {
    fontWeight: "inherit",
    color: "inherit",
    borderRadius: theme.spacing(0.5),
    marginTop: ".3rem",
  },
  tickIcon: {
    display: "none",
  },
  folderHeight: {
    maxHeight: "15rem",
    overflow: "scroll",
    borderBottom: "1px solid #c4c4c4",
    display: "flex",
    flexDirection: "column",
    minWidth: 350,
  },
  header: {
    borderBottom: "1px solid #c4c4c4",
    paddingLeft: "0",
    paddingRight: "0",
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
    paddingBottom: ".5rem",
  },
}));
const MoveFile: React.FC<Props> = ({
  open,
  setOpen,
  selectedFolderName,
  history,
  getloadCampsInitial,
  selectedCampId,
  brandID
}) => {
  const classes = useStyles();

  const [fileTreeData, setCampFolders] = React.useState<any>([]);
  
    const [expanded, setExpanded] = React.useState<any>([]);
  
  useEffect(() => {
    let data: any[] = [0];
    const expd = (child: any) => {
      child.forEach((item: any) => {
        data.push(String(item.id));
        if (item.childes) {
          expd(item.childes);
        }
      });
    };
    if (fileTreeData.childes) {
      expd(fileTreeData.childes);
    }
    setExpanded(data);
  }, [fileTreeData]);
   const handleToggle = (event: any, nodeIds: any) => {
    setExpanded(nodeIds);
  };
  
  
  const getCampFolders = () => {
  	AxiosInstance.get('api/v1/Contents/get_templates_all_folders', {
		params: {
			brandID: brandID,
		},
	}).then((response: AxiosResponse) => {
        if (response.data.status === true) {
          setCampFolders(response.data.data.folders[0]);
		  setSelectedFolderID('');
        }
      })
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    getCampFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  const [selectedFolderID, setSelectedFolderID] = useState<any>(null);
  const [selectedCompanyID, setSelectedCompanyID] = useState<any>(null);
  const moveToFolder = (event: any) => {
    setSelectedFolderID(event.currentTarget.id)
    setSelectedCompanyID(event.currentTarget.dataset.id)
  };

  const moveToFOlderFunction = () => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("tm_id", selectedCampId );
    data.append("company_id", selectedCompanyID);
    data.append("folder_id", selectedFolderID);

    AxiosInstance.post('api/v1/Contents/move_template', data)
    .then((response: AxiosResponse) => {
        if (response.data.status === true) {
          setOpen(false);
          getloadCampsInitial();
        }
      })
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.header}>
          Move {selectedFolderName} to
        </DialogTitle>
        <DialogContent>
          <Box className={classes.folderHeight}>
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
			  selected=""
              onNodeToggle={handleToggle}
            >
               <SimpleTree
				fileTreeData={fileTreeData}
				parrentId={selectedFolderID}
				closeDropDownFolder={moveToFolder}
				treeName="Templates"
			>
			</SimpleTree>
            </TreeView>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={moveToFOlderFunction}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MoveFile;
