import { Box, Container, Typography, makeStyles } from "@material-ui/core";
//import errorImage from "../../assets/svgs/not_found.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgb(200 197 255)",
    height: "100%",
  }
}));

const ErrorPage = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h3">
            Something went wrong
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            Don't worry our developers are already working on it
          </Typography>
          
        </Container>
      </Box>
    </Box>
  );
};

export default ErrorPage;
