import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Box, Paper,} from '@material-ui/core';
import { useStyles } from "./styles";
import TrackingName from "./components/TrackingName";
import TrackingAttributes from "../styleguide/components/TrackingAttributes";
import TrackingParameters from "../styleguide/components/TrackingParameters";
import TrackingStyleLists from "../styleguide/components/TrackingStyleLists";
import Footer from "../styleguide/components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { addPrefilData, addField } from '../../../redux/actions/styleGuideActions';
import { convertReduxState } from "./convertDbDataToReduxState";
import AxiosInstance from "../../../../utils/AxiosInstance";
// import {setFlashMessage} from "../../../../utils/AuthUtility";
import {useState} from 'react';


interface Props {
  handleNext?: any;
  save?: any;
  history?: any;
  isNotSettingsPage?: boolean;
}

const TrackingPage: React.FC<Props> = ({
  handleNext,
  isNotSettingsPage,
  save,
  history
}) => {
    const dispatch = useDispatch();
    const state = useSelector((state: any) => state.styleGuide);
    const [checkStyle, SetCheckStyle] = useState(false);

  const getSavedData = async (trkResID : any) => {
    try { 
	
      const request = await AxiosInstance.get(
        `api/getTracking/${localStorage.getItem("company_id")}/${trkResID}`
      );
      dispatch(
        await addPrefilData(
          await convertReduxState(request.data.parameters)
        )
      );
      SetCheckStyle(true);
    } catch (err) {
     }
  };

  
  useEffect(() => {
    if(state["styleGuideDefault"] !== undefined && state["styleGuideDefault"]){
      handleSubmit(state);
      dispatch(addField("styleGuideDefault", false));
    }
    dispatch(addField("styleguide_name", ''));
    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[checkStyle] );
  

  
  
  const handleSubmit = (state: any) => {
   return true;
  };
  
  useEffect(() => {
      var trackingId: any = null;
      localStorage.removeItem("LastSavedTime");
    if(localStorage.getItem("TrackingResID")==='' || localStorage.getItem("TrackingResID")==null)
    {
        trackingId=localStorage.getItem("TrackingrowId")
    }
    else
    {
        trackingId = localStorage.getItem("TrackingResID");
    }
     if(trackingId)
      {
           getSavedData(trackingId);
      }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const classes = useStyles();
 
  return (
    <Box className={classes.root}>
   
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={isNotSettingsPage ? "80%" : "100%"}
        flexDirection="column"
      >
        {isNotSettingsPage && (
          <Box>
            <Typography
              component="h1"
              align="center"
              className={classes.topHeading}
              variant="h5"
            >
              Create Your Style Guide
            </Typography>
            <Typography
              component="p"
              className={classes.caption}
              align="center"
              variant="caption"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore
            </Typography>
          </Box>
        )}
        <Paper
          className={!isNotSettingsPage ? "" : classes.container}
          elevation={0}
          square={!isNotSettingsPage}
          style={{borderRadius:4}}
        >
          <Grid container style={{flexWrap: "unset"}}>
            <Grid item md={12} sm={12} className={classes.leftBorder}>
              <TrackingName isNotSettingsPage={isNotSettingsPage} history={history} />
              <Box className={classes.componentLeftContainer}>
               
                    <Box display="flex" flexDirection="column" className={classes.section}>
                    <Typography className={classes.heading} variant="h6">
                    Design Systems
                    </Typography>
                    <Typography variant="caption" className={classes.caption}>     
                    Assign this tracking profile to Design Systems to make it available in your email settings.
                    </Typography>
                    <div>
                    <TrackingStyleLists />
                  
                    </div>
                    </Box>
    
    
                 <TrackingParameters />                                   
                <TrackingAttributes />
                
                {/* <FooterInfo /> */}
              </Box>
              {isNotSettingsPage && (
                <Footer
                  handleNext={() => {
                    handleSubmit(state);
                  }}
                  handleSkip={handleNext}
                />
                
              )}
            </Grid>
            
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

export default TrackingPage;
