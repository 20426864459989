import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Iframe from "react-iframe";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {AxiosResponse} from "axios";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import AxiosInstance from "../../../utils/AxiosInstance";
import { redirectTo } from "../../../utils/AuthUtility";
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import {Standard,Interactive } from '../../assets/svgs/SvgDynamic';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";


interface Props {
  history?: any;
  match?: any;
}

const ScreenShoot: React.FC<Props> = ({ 
   history,
   match }) => {
  const classes = useStyles();
  //const loggedInUser = getUserFromToken();
  const screenType = match?.params?.screenType;
  const screenID = match?.params?.screenID;
  const [previewURL, setPreviewURL] = useState("");
  // eslint-disable-next-line
  const [darkURL, setDarkURL] = React.useState("");
  // eslint-disable-next-line
  const [lightURL, setLightURL] = React.useState("");
  const [isDark, setIsDark] = React.useState(false);
  const [previewName, setPreviewName] = useState("");
  const [viewMenu, setViewMenu] = useState(false);
  const [viewMenuList, setViewMenuList] = useState("");
  const [viewFormats, setViewFormats] = useState("standard");
  const [companyLogo, setCompanyLogo] = useState("");
  const [formats, setFormats] = useState("desktop");

  useEffect(()=>{
    setPreviewName("");
    var FormData = require("form-data"); 
    var data = new FormData();
    data.append("id", screenID);
    data.append("version_id", screenID);
    document.body.setAttribute("oncontextmenu","return false;");
    AxiosInstance.post('api/v1/Campaign/' + screenType + '_screenshot', data)
     .then((response: AxiosResponse) => {
        if (response.data.status === true) {
		  setCompanyLogo(response.data.company_logo);
          setPreviewURL(response.data.data);
          setPreviewName(response.data.name);
          setDarkURL(response.data.data+"?modePreview=dark");
          setLightURL(response.data.data);
          if(response?.data?.is_dark) setIsDark(response.data?.is_dark);
		  if(response.data.viewenabled === true){
			setViewMenu(true);
			setViewMenuList(response.data.viewmode);
			setViewFormats(response.data.viewmode);
			var url = new URL(response.data.data);
			if(!url.searchParams.has("modeView")){
				url.searchParams.append('modeView', response.data.viewmode);
			}else{
				url.searchParams.set('modeView', response.data.viewmode);
			}
			setPreviewURL(url.toString());
		  }
        }
      })
	  
	  
  // eslint-disable-next-line
  },[]);

  const [mode, setMode] = React.useState("light");
  const handleMode = (event:any, newFormats:any) => {
    var url = new URL(previewURL);
    if (newFormats !== null) {
      setMode(newFormats);
      if(newFormats === "dark"){ 
        if(!url.searchParams.has("modePreview")){
          url.searchParams.append('modePreview', 'dark');
        } else{
          url.searchParams.set('modePreview', 'dark');
        }
          setPreviewURL(url.toString());

        // setPreviewURL(darkURL);
      } else  {
          if(url.searchParams.has("modePreview")){
            url.searchParams.delete("modePreview");
          }
        setPreviewURL(url.toString());
          // setPreviewURL(lightURL);
        }
    }
  };
  useEffect(()=>{
   
  // eslint-disable-next-line
  },[viewFormats]);
  
  const handleFormat = (event:any, newFormats:any) => {
    if (newFormats !== null) {
      setFormats(newFormats);
    }
  };
  const handleViewChange = (event: any) => {
	setViewFormats(event.target.value);
	var url = new URL(previewURL);
	if(!url.searchParams.has("modeView")){
		url.searchParams.append('modeView', event.target.value);
	}else{
		url.searchParams.set('modeView', event.target.value);
	}
    setPreviewURL(url.toString());
	event.persist();
  };

  return (
    <>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          component={Paper}
          className={classes.formGrid}
          elevation={4}
          square
        >
          <div className={`${classes.paper} ${classes.PreviewContainer}`}>
            <div className={classes.previewHeader}>
              <Box display="flex">
                <Box p={1} flexGrow={1} display="flex" alignItems="center">
                <Box className={classes.previewImgLogo} style={{ cursor: "pointer" }} onClick={() => redirectTo('/')} >
                     {(companyLogo) && (
					<img
                      alt="logo"
                      style={{ maxHeight:34,maxWidth: 66 }}                  
                      src={companyLogo+"?time=" + new Date().getTime()}
                    />
					)}
                  </Box>
                  <Typography variant="subtitle1" style={{color: "#5D7186"}}>
                    {previewName}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                <Grid container>
                  <Grid style={{display:"flex"}}>

                    {isDark && <Box style={{marginRight:20}}>                      

                      <ToggleButtonGroup
                        size="small"
                        value={mode}
                        exclusive
                        onChange={handleMode}
                        aria-label="light"
                      >
                        <ToggleButton value="light" aria-label="light" classes={{ selected: classes.ToggleSelected}}  >
                          <WbSunnyIcon color={mode === "light" ? "primary" : undefined} />
                        </ToggleButton>
                        <ToggleButton value="dark" aria-label="dark" classes={{ selected: classes.ToggleSelected}}>
                          <Brightness3Icon color={mode === "dark" ? "primary" : undefined} />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>}
					  <Box style={{marginRight:20}}> 
						{viewMenu === true && (
						 <ToggleButtonGroup
							size="small"
							value={formats}
							exclusive
							aria-label="desktop"
						  >
						<TextField
							select
							fullWidth
							style={{width:165}}
							InputProps={{ style: { height: 40 } }}
							name="userRoles"
							id="userRoles"
							variant="outlined"
							label="View"
							defaultValue={viewFormats}
							SelectProps={{
							multiple: false,
							value: viewFormats,
							onChange: handleViewChange
							}}
							>
							<MenuItem value="fallback">
								<ListItemIcon style={{minWidth:35 ,float:'left'}}>
								<RadioButtonUnchecked color={viewFormats === "fallback" ? "primary" : undefined}/>
							  </ListItemIcon>
							  <ListItemText primary="Fallback" style={{color: (viewFormats === "fallback" ? "#057CFF" : '')}}/>
							</MenuItem>
							<MenuItem value="standard" selected={true}>
							  <ListItemIcon style={{minWidth:35 ,float:'left'}}>
								<Standard color={viewFormats === "standard" ? "#057CFF" : ''}/> 
							  </ListItemIcon>
							  <ListItemText primary="Standard" style={{color: (viewFormats === "standard" ? "#057CFF" : '')}}/>
							  </MenuItem>
							{viewMenuList ==='interactive' && (
							<MenuItem value="interactive">
							  <ListItemIcon style={{minWidth:35 ,float:'left'}}>
								<Interactive color={viewFormats === "interactive" ? "#057CFF" : ''}/> 
							  </ListItemIcon>
							  <ListItemText primary="Interactive" style={{color: (viewFormats === "interactive" ? "#057CFF" : '')}}/>
							</MenuItem>
							)}
							
							</TextField>
						  </ToggleButtonGroup>
						)}
						</Box>
                    <div >
                      <ToggleButtonGroup
                        size="small"
                        value={formats}
                        exclusive
                        onChange={handleFormat}
                        aria-label="desktop"
                      >
                        <ToggleButton value="desktop" aria-label="desktop" classes={{ selected: classes.ToggleSelected}}  >
                          <DesktopMacIcon color={formats === "desktop" ? "primary" : undefined} />
                        </ToggleButton>
                        <ToggleButton value="table" aria-label="table" classes={{ selected: classes.ToggleSelected}}>
                          <TabletMacIcon color={formats === "table" ? "primary" : undefined} />
                        </ToggleButton>
                        <ToggleButton value="phone" aria-label="phone" classes={{ selected: classes.ToggleSelected}}>
                          <PhoneIphoneIcon color={formats === "phone" ? "primary" : undefined} />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </Grid>
                </Grid>
                </Box>
                <Box p={1}>

                </Box>
              </Box>
            </div>
            <Box display="flex" justifyContent="center" >
              <Box width="100%" >
                <div className={classes.campaign_preview_window_content_body}>
                  <Iframe
                    className={`${classes.iFrame}
                    ${formats === "desktop" ? classes.previewDesktopSelected : ""}
                    ${formats === "table" ? classes.previewTableSelected : ""}
                    ${formats === "phone" ? classes.previewPhoneSelected : ""}
                    `}
                    url={previewURL}
                    height="100%"
                    width="100%"
                  ></Iframe>
                </div>
              </Box>
            </Box>
          </div>
        </Grid>
    </>
  );
};

export default ScreenShoot;
