import React from "react";
import { Box, MenuItem, TextField } from "@material-ui/core";
import { useStyles } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../../redux/actions/styleGuideActions";
import { AutoSave } from "../../StyleGuideAutosave";

interface Props {
  title?:string;
  label?:string;
  fieldName:string;
}

const CustomFontSelection: React.FC<Props> = ({title,label, fieldName}) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.styleGuide);

  const handleSelectItem = (event:any) => {
    const selectedKey = event.currentTarget.getAttribute("data-key");
    const selectedValue = event.target.value;
    dispatch(addField(fieldName, selectedValue));
    dispatch(addField("key_"+fieldName, selectedKey));
    AutoSave();
  }

  return (
        <>
          {(title) &&(<Box className={`${classes.flexColumn1} ${classes.TitleDescription3}`}>          
            {title} Custom Font:
          </Box>)}
          <Box flex="1">  
              <TextField
                select
                size="small"
                label={label}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  },
                }}
                placeholder="None"
                value={state[fieldName] || "none"}
                fullWidth
                onChange={handleSelectItem}
              >
                <MenuItem value="none">
                  None
                </MenuItem>
                {state?.list_custom_fonts?.map((item:any) => {
                    if(item?.family) return <MenuItem key={item.key} data-key={item.key} value={item.family}>
                       <span style={{ fontFamily: item.family }}>{item.family}</span>
                    </MenuItem>
                    return <></>
                })}
              </TextField>
          </Box>
        </>
  );
};

export default CustomFontSelection;