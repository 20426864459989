import React from "react";
import { useStyles } from "../../../../../../../styles/styles";
import Card from "@material-ui/core/Card";
import SearchBar from "../../components/SearchBar";
import Button from "@material-ui/core/Button";
import {isDesigner, isMarketer} from "../../../../../../../utils/AuthUtility";

interface Props {
querySearch:any;
setQuerySearch:any;
setListNextPage:any;
setImagesByComp:any;
funcsetOpenImage:any;
}

const Sidebarcomponents: React.FC<Props> = ({
querySearch,
setQuerySearch,
setListNextPage,
setImagesByComp,
funcsetOpenImage,
}) => {
  const classes = useStyles();
  
 const getDisabledImage = () => {
	  if(!isDesigner() && !isMarketer()){
		  return classes.disabledList;
	  }
	  return '``';
  };
  
  return (
  <div>
    <Card elevation={0} className={getDisabledImage()} style={{ backgroundColor: 'transparent'}}>
        <Button
        variant="contained"
        color="primary"
        className={classes.PrimarybuttonStyle}
        onClick={() => funcsetOpenImage()}
        >
        Add Image
        </Button>
    </Card>

    <Card elevation={0} style={{ backgroundColor: 'transparent'}}>
        <SearchBar
        querySearch={querySearch}
        setQuerySearch={setQuerySearch}
        setListNextPage={setListNextPage}
        setImagesByComp={setImagesByComp}
        />
    </Card>
   </div>

  );
};

export default Sidebarcomponents;
