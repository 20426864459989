import React, { useState, useEffect } from "react";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
import FolderTreeList from "./FolderTreeList";
import {useDispatch} from "react-redux";
import {updateActiveTabs} from "../../../../redux/actions/TabManagerActions";

interface Props {
  history: any;
  brandID: any;
  templateFolders: any;
  setTemplateFolders: any;
}

const TemplateFolderTree: React.FC<Props> = ({
  brandID,
  history,
  templateFolders,
  setTemplateFolders
}) => {
  
  // start needed  
  const [listFolders, setListFolders] = useState<any>([]);
  const URL = window.location.pathname.split('/').pop();  
  const dispatch = useDispatch();
  useEffect(() => {
	if(URL ==='TeamDetails'){
		getListFolders();
	}
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [URL]);
  const getListFolders = () => {
  	AxiosInstance.get('api/v1/Contents/get_templates_all_folders', {
		params: {
			full:1
		},
	}).then((response: AxiosResponse) => {
        if (response.data.status === true) {
          setListFolders(response.data.data.folders[0]);
		  dispatch(updateActiveTabs({fieldName: "temp_folder_tree", fieldValue: response.data.data.folders[0]}));
        }
      })
  };
 
  return (
    <>
      <FolderTreeList name="Template" history={history} listFolders={listFolders} selectedFolders={templateFolders} setFolders={setTemplateFolders}/>
    </>
  );
};

export default TemplateFolderTree;
