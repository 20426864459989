import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { MenuItem, Menu } from "@material-ui/core";
import {toast} from "react-toastify";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Switch from "@material-ui/core/Switch";
import CreateNewUser from "./CreateNewUser";
import Avatar from "@material-ui/core/Avatar";
import {isPowerAdmin, getInitialsFromName, getUserLevel,isMarketer,getUserId, isTrial} from "../../../../../utils/AuthUtility";
// import TrialPopup from "../../../../../utils/TrialPopup";
// import AccessPopup from "../../../../../utils/AccessPopup";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
import { useSelector } from "react-redux";


interface Props {
  data: any;
  reloadLists: any;
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  menuButton: { cursor: "pointer" },
    chiplabel:  {color:'#fff',fontWeight:'bold',backgroundColor:'#f5c242',paddingTop:'6px',paddingRight:'10px',paddingBottom:'6px',paddingLeft:'10px',marginLeft:'5px',borderRadius:'10px'},
  deleteOption: {
    color: "red",
  },
  inviteOption: {
    color: "black",
  },
  toolbar: { 
    backgroundColor: "white",
    paddingLeft:5,
    minHeight: "unset",
    // paddingBottom:17,
    paddingRight:0
  },
  avatar: { marginRight: 10 ,width:32 , height:32},
  tableThead:{
    backgroundColor:"#F3F3F3",
    borderRadius:3,
    "& th":{
      fontSize:10,
      fontWeight:"600",
      lineHeight:"unset",
      padding: "12px 0 10px 0"
    },
    "& th:first-child":{
      paddingLeft:20
    }
  },
  outLineButtonContent: {
    border: "1px solid #057cff",
    borderRadius: "4px",
    color: "#367BF5",
    height: "unset",
    lineHeight: "normal",
    fontWeight:"normal",
    "&:hover": {
      color: "#ffffff",
      background: "#367BF5",
    },
  },
  titleh6:{
    fontSize:18
  },
  tableTbodyRow:{
    borderBottom: "1px solid #E1E1E1",
    "& > td" : {
      padding:"10px 0 !important"
    },
    "& > td:first-child" : {
      paddingLeft: "20px !important"
    }
  },
  disabledList: { 
    pointerEvents:"none",
    opacity:0.4
  },
});

const UsersList: React.FC<Props> = ({ data, reloadLists }) => {
  const state = useSelector((state: any) => state);
  const classes = useStyles();
  const [listData, setListData] = useState<any>([]);
  // const [trialPopupStatus, setTrialPopupStatus] = useState<boolean>(false);
  // const [accessPopupStatus, setAccessPopupStatus] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [editRecord, setEditRecord] = useState<any>(null);
  const [StyleData, setStyleData] = useState<any>([]);
 
  const changeUserStatus = (event: any) => {
    const currentTarget = event.currentTarget;
    const rowIndex = currentTarget.value;
    const selectedRecord = listData[rowIndex];
    const userId = selectedRecord.usr_id;
    const newStatus = selectedRecord.usr_active === "1" ? "0" : "1";
    const data = new FormData();
    listData[rowIndex].usr_active = currentTarget.checked ? "1" : "0";
    data.append("stats", newStatus);

    AxiosInstance.post(`api/v1/Settings/change_status_ajax/${userId}`, data)
     .then((response: AxiosResponse) => {
		 setListData([...listData]);
      });
  };

  
  const getlistStyles = (record:any) => {
  	AxiosInstance.get(`api/getStyleguidelisting/${localStorage.getItem("company_id")}`)
      .then((response: AxiosResponse) => {
		  setStyleData(JSON.stringify(response.data.usertable));
    setEditRecord(record);
     setTimeout(() => {
    setOpen(true)
  }, 600);
      });         

  };

  //  const getlistStylesCreateUser = () => {
	// if(!isTrial()){
	// let users = parseInt(listData.length);
	// let max_users1=localStorage.getItem('max_users') as string;
	// let max_users = parseInt(max_users1);
	// if(users >=max_users && max_users!==0){
	// 	setAccessPopupStatus(true);
	// }else{
	// 	AxiosInstance.get(`api/v1/Teams/listall`)
	// 	  .then((response: AxiosResponse) => {
	// 		  setStyleData(JSON.stringify(response.data.usertable));
	// 		   setEditRecord('');
	// 	 setTimeout(() => {
	// 	setOpen(true)
	//   }, 600);
	// 	  });
	// }	  
	// }else{
	// 	setTrialPopupStatus(true);
	// }
  // };

 const editUser = (event: any) => {
    const rowIndex = event.currentTarget.value;
    const selectedRecord = listData[rowIndex];
       getlistStyles(selectedRecord);
     
  };
  
    const sendInvite = (event: any) => {
    var FormData = require("form-data");
    var data = new FormData();
   const rowIndex = event.currentTarget.value;
    const selectedRecord = listData[rowIndex];
    const userId = selectedRecord.usr_id;
    data.append("userId", userId);

    AxiosInstance.post('api/resend-invite', data)
     .then((response: AxiosResponse) => {
        if (response.data.success === 1) {
            toast.success(response.data.message);
        } else
        {
            toast.error(response.data.message);
        }
      })
  };
  const getDisabled = () => {
	  if(!isPowerAdmin()){
		  return classes.disabledList;
	  }
	  return '``';
  };
  const deleteUser = (event: any) => {
    const rowIndex = event.currentTarget.value;
    const selectedRecord = listData[rowIndex];
    const userId = selectedRecord.usr_id;
    AxiosInstance.delete(`api/v1/settings/delete_user_ajax?id=${userId}`)
     .then((response: AxiosResponse) => {
		 listData.splice(rowIndex, 1);
		 setListData([...listData]);
      }).catch((err) => {
				if (err.response)
					toast.warning(err.response?.data?.data?.message);
			});
  };
  useEffect(() => {
    setListData(data);
    // listStyles();

  }, [data]);
  useEffect(()=>{
    if(state?.tabManager?.AddNewUser){
      setStyleData(state?.tabManager?.setUserStyleData);
      setEditRecord('');
      setTimeout(() => {
        setOpen(true);
      }, 600);
    }
  // eslint-disable-next-line
  },[state?.tabManager?.AddNewUser]);
  return (
    <>
      <Toolbar className={classes.toolbar}>
	  {/* <TrialPopup
	    trialPopupStatus={trialPopupStatus}		
		setTrialPopupStatus={setTrialPopupStatus}
      />
	   <AccessPopup
	    accessPopupStatus={accessPopupStatus}
		setAccessPopupStatus={setAccessPopupStatus}
		popupTitle="User"
      />
      /> */}

        {open && (
          <CreateNewUser
            reloadLists={reloadLists}
             StyleData={StyleData}
            open={open}
            userId={editRecord && editRecord.usr_id}
            setOpen={setOpen}
          />
        )}

        {/* <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="h6" id="tableTitle" component="div" className={classes.titleh6}>
            Users
          </Typography>
          {!isMarketer() &&
          <Button
            className={classes.outLineButtonContent}
            variant="outlined"
            color="primary"
            onClick={() => {
              getlistStylesCreateUser();
               
            }}
          >
            Add New User
          </Button>
          }
        </Box> */}
      </Toolbar>
      <TableContainer component={Paper} square>
        <Table className={classes.table +` `+ getDisabled()} aria-label="simple table">
          <TableHead className={classes.tableThead}>
            <TableRow >
              
              <TableCell align="left">NAME</TableCell>
              <TableCell align="left">EMAIL</TableCell>
             {(!isMarketer()) && ( <TableCell align="left">STATUS</TableCell> )}
              <TableCell align="left">ACCESS</TableCell>
              {(!isMarketer()) && (<TableCell align="left">ACTIONS</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {listData &&
              listData.map((row: any, index: any) => (
                <TableRow key={row.name} className={classes.tableTbodyRow}>
                  
                  <TableCell align="left">
                    {" "}
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      {row?.profile_image === "" ? (
                          <Avatar
                            style={{
                              width: "32px",
                              height: "32px",
                              fontSize: "14px",
							  backgroundColor: `${row.hex}`,
                              marginRight: 10,
                              fontWeight: 600
                            }}
                          >
                            {getInitialsFromName(row.usr_name, row.usr_surname)}
                          </Avatar>
                        ) : (
                          <Avatar
                            src={`${row.profile_image}`}
                            className={classes.avatar}
                          />
                      )}
                      <span>{`${row.usr_name} ${row.usr_surname}`} {row?.is_pending === "1" && (
                            
                              <code className={classes.chiplabel}>Pending</code>
                           
                            )}</span>
                    </Box>
                  </TableCell>
                  <TableCell align="left">{row.usr_email}</TableCell>
                  {(!isMarketer()) && (<TableCell align="left">
                    <Switch
                      checked={row.usr_active === "1" ? true : false}
                      value={index}
                      onChange={changeUserStatus}
					  disabled={isTrial()}
                    />
                  </TableCell>)}
                  <TableCell align="left">
                    {getUserLevel(row.usr_level, row.usr_role)}
                  </TableCell>
                 {(!isMarketer()) && ( <TableCell align="center">
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState: any) => (
                        <React.Fragment>
                          <MoreVertIcon
                            className={classes.menuButton}
                            {...bindTrigger(popupState)}
                          />
                          <Menu {...bindMenu(popupState)}>
                            <div onClick={popupState.close}>
                              <MenuItem value={index} onClick={editUser} disabled={isTrial()}>
                                <Typography>Edit</Typography>
                              </MenuItem>
                            </div>
                            <div
                              onClick={popupState.close}
                              className={classes.deleteOption}
                            >
                            {row?.is_pending === "1" && (
                            <div
                            className={classes.inviteOption}
                            >
                               <MenuItem value={index} onClick={sendInvite} disabled={isTrial()}>
                                <Typography>Resend invite</Typography>
                              </MenuItem>
                              
                            </div>
                            )}
                             
                              {getUserId() !== row.usr_id && (
                              <MenuItem value={index} onClick={deleteUser} disabled={isTrial()}>
                                <Typography>Delete</Typography>
                              </MenuItem>
							  )}
							  
                            </div>
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                  </TableCell>
                 )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default UsersList;
