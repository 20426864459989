import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import AppLists from "./components/AppLists";
import DialogBox from "./components/DialogBox";
import Footer from "./components/Footer";
import { useStyles } from "./styles";
import { toast } from "react-toastify";
import AxiosInstance from "../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
import {isPowerAdmin, isArvatoEnabled} from "../../../../utils/AuthUtility";
import { Skeleton } from "@material-ui/lab";
import { Box } from "@material-ui/core";

interface Props {
  handleNext?: any;
  isNotSettingsPage: boolean;
}

  
  
const Intregrate: React.FC<Props> = ({ handleNext, isNotSettingsPage }) => {
  const classes = useStyles();
  const URL = window.location.pathname.split('/').pop();
  const [selectedData, setSelectedData] = useState<
    { p_name: string; logo: string } | undefined
  >({ p_name: "", logo: "" });

  const [appDatas, setAppDatas] = React.useState<any>([]);
  const [cloudFolders, setcloudFolders] = React.useState<any>([]);

const getCloudFolders = () => {
      var brandID='66';
    AxiosInstance.get('api/v1/Campaign/getexportFolders', {
		params: {
			brandID: brandID,
		}
	}).then((response: AxiosResponse) => {
        if (response.data.status === true) {
          setcloudFolders(response.data.folders[0]);
        }
      })
  };
  
  
  const loadEmailProviderForOnboardingPage = () => {
    fetch(
      `api/v1/users/email_providers/${localStorage.getItem("usr_id")}`
    )
      .then((response) => response.json())
      .then((response) => setAppDatas(response.Userlist))
      .catch(function (error: any) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.warn(error.response.data.message);
          }
          if (error.response.status === 500) {
            alert("500, Server Error");
            toast.error(error.response.data.message);
          }
        }
      });
  };

  const loadEmailProviderForSettingsPage = () => {
  	AxiosInstance.get('api/v1/Integration/get_list').then((response: AxiosResponse) => {
        setAppDatas(response.data.data.email_providers);
      });
  };
  const [integrateSelected, setIntegrateSelected] = useState(false);
  const [integrateflag, setintegrateflag] = useState(false);
  const getDisabled = () => {
	  if(!isPowerAdmin()){
		  return classes.disabledList;
	  }
	  return '``';
  };
  useEffect(() => {
    appDatas.forEach((appData:any) => {
      if (appData.selected) {
        setIntegrateSelected(true);
      }
    });
   if(URL === "integrations") getCloudFolders();
  },[appDatas,URL]);
  useEffect(() => {
    //implemented two different functions for fetching email providers as we are reusing this component on landing/settings page
    // same applies for the other apis on this page
    if (isNotSettingsPage) {
      loadEmailProviderForOnboardingPage();
    } else {
      if(URL === "integrations") loadEmailProviderForSettingsPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [URL]);
  
  
  useEffect(() => {
   
    loadEmailProviderForSettingsPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrateflag]);

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = (event: any) => {
      setintegrateflag(false);
  	if (isArvatoEnabled()) return;
    const selectedId = event.currentTarget.id;
    const result = appDatas.find((word: any) => word.p_name === selectedId);
   

    const result_selected = appDatas.find(
      (word: any) => word.selected === true
    );
    if (result_selected === undefined) {
      setSelectedData(result);
    } else {
      setSelectedData(result);
    }
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Grid container component="main">
      {/* <CssBaseline /> */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        container
        spacing={0}
        alignItems="center"
        justify="center"
      >
        <div className={classes.mainDiv + ' ' + getDisabled()}>
          <Typography component="h1" variant="h4" className={classes.header}>
            Connect To Your Favourite Apps
          </Typography>
         {appDatas.length === 0 ? (<Box className={classes.integrateSkeleton}>
            <Skeleton variant="rect" />
            <Skeleton variant="rect" />
            <Skeleton variant="rect" />
            <Skeleton variant="rect" />
            <Skeleton variant="rect" />
            <Skeleton variant="rect" />
            <Skeleton variant="rect" />
            <Skeleton variant="rect" />
            <Skeleton variant="rect" />
          </Box>)
           : (<AppLists appDatas={appDatas} handleClickOpen={handleClickOpen} integrateSelected={integrateSelected} selectedData={selectedData} />)
          }
          <DialogBox
            isNotSettingsPage={isNotSettingsPage}
            openDialog={openDialog}
            setSelectedData={setSelectedData}
            handleClose={handleClose}
            selectedData={selectedData}
            integrateSelected={integrateSelected}
            setIntegrateSelected={setIntegrateSelected}
            integrateflag={integrateflag}
            setintegrateflag={setintegrateflag}
            cloudFolders={cloudFolders}
          />
        </div>
      </Grid>
      {isNotSettingsPage && (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          container
          spacing={0}
          alignItems="center"
          justify="center"
        >
          <Footer handleNext={handleNext} />
        </Grid>
      )}
    </Grid>
  );
};

export default Intregrate;
