import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app/redux/store";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "./styles/theme";

declare global {
	interface Window {
		globalConfig: {
			subDir: string,
			apiOrigin: string,
			loginPoster: string,
			loginWelcome: string,
			arvEnabled: string,
			buildDir: string,
			
		};
		dataLayer: any;
		Intercom: any;
	}
}

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
