import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { useStyles } from '../styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

interface Props {}
const StyleGuideListSkeleton: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <TableRow >
      <TableCell >
        <Skeleton
          animation="wave"
          variant="rect"
        />
      </TableCell>
      <TableCell className={classes.skeletonGrid2} >
        <Skeleton
          animation="wave"
          height={30}
          style={{ marginBottom: 6 }}
        />
      </TableCell>          
      <TableCell className={classes.skeletonGrid3} >
        <Skeleton
          animation="wave"
          height={30}
          style={{ marginBottom: 6 }}
        />
      </TableCell>
      <TableCell className={classes.skeletonGrid4} >
        <Skeleton
          animation="wave"
          height={30}
          style={{ marginBottom: 6 }}
        />
      </TableCell>          
      <TableCell className={classes.skeletonGrid5} >
        <Skeleton
          animation="wave"
          height={30}
          style={{ marginBottom: 6 }}
        />
      </TableCell>          
      <TableCell className={classes.skeletonGrid6} >
        <Skeleton
          animation="wave"
          height={30}
          style={{ marginBottom: 6 }}
        />
      </TableCell>
      
       <TableCell className={classes.skeletonGrid6} >
        <Skeleton
          animation="wave"
          height={30}
          style={{ marginBottom: 6 }}
        />
      </TableCell>
      
       <TableCell className={classes.skeletonGrid6} >
        <Skeleton
          animation="wave"
          height={30}
          style={{ marginBottom: 6 }}
        />
      </TableCell>
      
      
      
    </TableRow>
  );
};

export default StyleGuideListSkeleton;
