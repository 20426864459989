import React, { useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "../styles";
import { Box, InputAdornment, RadioGroup, FormControlLabel, Radio, Typography } from "@material-ui/core/";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import MenuItem from "@material-ui/core/MenuItem";
import { styled } from "@material-ui/core/styles";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../redux/actions/styleGuideActions";
import Select from "@material-ui/core/Select";
import { toast } from "react-toastify";
//import CheckValidationImageUrl from "./CheckValidationImageUrl";
import DynamicSocialLinksAutosave from "../DynamicSocialLinksAutosave";
interface Props {}

const IconSelect = styled(Select)(() => ({
  "& fieldset": {
    borderRadius: 8,
  },
}));
const LinkTextField = styled(TextField)(() => ({
  "& fieldset": {
    // borderLeft: "none",
    borderRadius: 8,
  },
}));
const DynamicSociallinks: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
    //const [error, seterror] = React.useState(false);
  // const [inputList, setInputList] = useState([{ title: "", link: "" }]);
  const state = useSelector((state: any) => state.styleGuide);
  const droppableRef = useRef<any>();
  const inputList = state.socialLinkList;
  const checkHasSrcUrl = () => {
    inputList?.forEach((element:any) => {
      if (element.src){
        SetSocialStyle("custom");
      }
    });
  }

  const showFields = inputList.length > 0 ? true : false;
  const[socialStyle, SetSocialStyle] = useState<any>("automatic");
  const handleChangeSocialStyle = (event:any) => {
    SetSocialStyle(event.target.value);
    dispatch(addField("SetSocialStyleValue", event.target.value));
  }
  const setInputList = (list: any) => {
    dispatch(addField("socialLinkList", list));
  };
  
  // eslint-disable-next-line
  const showListFields = () => {
    // setShowFields({ displayField: true });
    if (inputList.length < 1) setInputList([{ title: "", link: "" }]);
  };

  const handleHideClick = () => {
    // setShowFields({ displayField: false });
    dispatch(addField("socialLinkList", []));
    // AutoSave();
    DynamicSocialLinksAutosave();
  };

  const handleInputChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleInputSrcSocialChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleBlur = () => {
    //  AutoSave();
    DynamicSocialLinksAutosave();
  };

  const handleBlurSrc = async (e:any, index: any) => {
    const list: any = [...inputList];
    let URL = e.target.value;
    if(URL === "") {
      list[index]["isvalid"] = "";
      DynamicSocialLinksAutosave();
      return;
    };
    /*let isImage = await CheckValidationImageUrl(URL);
    if(!isImage) {        
      list[index]["isvalid"] = false;      
      toast.error(URL + ' is not good URL');
      return false;
    } else list[index]["isvalid"] = true; 
*/
    setInputList(list);
    DynamicSocialLinksAutosave();
   // AutoSave();
 };
  
  const handleRemoveClick = (index: any) => {
    const list = [...inputList];
    setInputList(list);
    list.splice(index, 1);
    // AutoSave();
    DynamicSocialLinksAutosave();
  };

  const getLabelNameFromUrl = (label:string) => {
    label = label.replace("_url","");
    return label.charAt(0).toUpperCase() + label.slice(1);
  }
  const hadleClickSocialItem = (value:any) => {
    if(inputList.length > 6) {      
      toast.error('Maximum 7 social icons are allowed');
      return false;
    }
    setInputList([...inputList, { title: value, link: "" }]);
  }

  const handleClickAutomatic = () => {
    const list = [...inputList];
    var isChange = false;
    list?.forEach(item => {
      if(item.src || item.isvalid){
        isChange = true;
      }
      item.src = '';
      item.isvalid = '';
    });
    if(isChange){
      setInputList(list);
      DynamicSocialLinksAutosave();
    }
  }
  useEffect(()=>{
    if(socialStyle !== "custom") {
      checkHasSrcUrl();
    }
    // eslint-disable-next-line
  },[inputList]);
  const titleOptions = [
    {
      icon: (
        <>
          <svg
            width="15"
            height="15"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.1159 0.000976562H0.883064C0.395693 0.000976562 0 0.395885 0 0.884055V15.1171C0 15.6053 0.395693 16.0007 0.883064 16.0007H8.54559V9.80468H6.4607V7.38965H8.54559V5.60874C8.54559 3.54251 9.80722 2.4168 11.6508 2.4168C12.5347 2.4168 13.2926 2.48292 13.5136 2.51164V4.67139L12.2349 4.67191C11.2325 4.67191 11.0391 5.14849 11.0391 5.84742V7.38885H13.4306L13.1179 9.80362H11.0388V15.9999H15.1156C15.6035 15.9999 15.9995 15.604 15.9995 15.1171V0.883528C15.9992 0.395885 15.6038 0.000976562 15.1159 0.000976562Z"
              fill="#989898"
            />
          </svg>
        </>
      ),
      text: (
        <>
          &nbsp;&nbsp;&nbsp; <span className={classes.iconName}>Facebook</span>
        </>
      ),
      value: "facebook_url",
    },
    {
      text: (
        <>
          &nbsp;&nbsp;&nbsp; <span className={classes.iconName}>Tiktok</span>
        </>
      ),

      icon: (
        <>
          <svg
            width="15"
            height="15"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.0952 0H1.90477C0.854464 0 0 0.854464 0 1.90477V14.0952C0 15.1455 0.854464 16 1.90477 16H14.0952C15.1455 16 16 15.1455 16 14.0952V1.90477C16 0.854464 15.1455 0 14.0952 0ZM12.5737 6.98016C12.4863 6.98867 12.3986 6.99309 12.3108 6.99347C11.348 6.9936 10.45 6.50829 9.92269 5.70278V10.0979C9.92269 11.8919 8.46835 13.3462 6.6743 13.3462C4.88026 13.3462 3.42592 11.8919 3.42592 10.0979C3.42592 8.30394 4.88026 6.84954 6.6743 6.84954C6.74214 6.84954 6.80838 6.85562 6.87507 6.85984V8.46061C6.80838 8.45261 6.74291 8.44045 6.6743 8.44045C5.75866 8.44045 5.01638 9.18272 5.01638 10.0984C5.01638 11.014 5.75866 11.7563 6.6743 11.7563C7.59014 11.7563 8.39885 11.0348 8.39885 10.119L8.41485 2.65459H9.9463C10.0907 4.0279 11.1981 5.10061 12.5752 5.20128V6.98035"
              fill="#989898"
            />
          </svg>
        </>
      ),
      value: "tiktok_url",
    },
    {
      text: (
        <>
          &nbsp;&nbsp;&nbsp; <span className={classes.iconName}>Instagram</span>
        </>
      ),

      icon: (
        <>
          <svg
            width="15"
            height="15"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.44444 0C3.2657 0 2.13524 0.468253 1.30175 1.30175C0.468253 2.13524 0 3.2657 0 4.44444V11.5556C0 12.7343 0.468253 13.8648 1.30175 14.6983C2.13524 15.5317 3.2657 16 4.44444 16H11.5556C12.7343 16 13.8648 15.5317 14.6983 14.6983C15.5317 13.8648 16 12.7343 16 11.5556V4.44444C16 3.2657 15.5317 2.13524 14.6983 1.30175C13.8648 0.468253 12.7343 0 11.5556 0H4.44444ZM13.3333 1.77778C13.8222 1.77778 14.2222 2.17778 14.2222 2.66667C14.2222 3.15556 13.8222 3.55556 13.3333 3.55556C12.8444 3.55556 12.4444 3.15556 12.4444 2.66667C12.4444 2.17778 12.8444 1.77778 13.3333 1.77778ZM8 3.55556C9.17874 3.55556 10.3092 4.02381 11.1427 4.8573C11.9762 5.6908 12.4444 6.82126 12.4444 8C12.4444 9.17874 11.9762 10.3092 11.1427 11.1427C10.3092 11.9762 9.17874 12.4444 8 12.4444C6.82126 12.4444 5.6908 11.9762 4.8573 11.1427C4.02381 10.3092 3.55556 9.17874 3.55556 8C3.55556 6.82126 4.02381 5.6908 4.8573 4.8573C5.6908 4.02381 6.82126 3.55556 8 3.55556ZM8 5.33333C7.64969 5.33345 7.30284 5.40256 6.97924 5.53673C6.65564 5.67089 6.36164 5.86748 6.11401 6.11527C5.86639 6.36306 5.67 6.65719 5.53605 6.98088C5.4021 7.30457 5.33322 7.65147 5.33333 8.00178C5.33345 8.35209 5.40256 8.69894 5.53673 9.02254C5.67089 9.34614 5.86748 9.64014 6.11527 9.88776C6.36306 10.1354 6.65719 10.3318 6.98088 10.4657C7.30457 10.5997 7.65147 10.6686 8.00178 10.6684C8.70926 10.6682 9.38767 10.3869 9.88776 9.88651C10.3879 9.38608 10.6687 8.70748 10.6684 8C10.6682 7.29252 10.3869 6.61411 9.88651 6.11401C9.38608 5.61392 8.70748 5.3331 8 5.33333Z"
              fill="#989898"
            />
          </svg>
        </>
      ),
      value: "instagram_url",
    },
    {
      text: (
        <>
          &nbsp;&nbsp;&nbsp; <span className={classes.iconName}>Youtube</span>
        </>
      ),

      icon: (
        <>
          <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.9502 0.344118C14.6207 0.529412 15.1493 1.07206 15.3298 1.76029C15.665 3.01765 15.6521 5.63824 15.6521 5.63824C15.6521 5.63824 15.6521 8.24559 15.3298 9.50294C15.1493 10.1912 14.6207 10.7338 13.9502 10.9191C12.7254 11.25 7.82605 11.25 7.82605 11.25C7.82605 11.25 2.9396 11.25 1.70188 10.9059C1.03144 10.7206 0.502827 10.1779 0.322325 9.48971C0 8.24559 0 5.625 0 5.625C0 5.625 0 3.01765 0.322325 1.76029C0.502827 1.07206 1.04433 0.516177 1.70188 0.330882C2.92671 0 7.82605 0 7.82605 0C7.82605 0 12.7254 0 13.9502 0.344118ZM6.26603 3.21756L10.3402 5.62638L6.26603 8.03521V3.21756Z"
              fill="#989898"
            />
          </svg>
        </>
      ),
      value: "youtube_url",
    },
    {
      text: (
        <>
          &nbsp;&nbsp;&nbsp; <span className={classes.iconName}>Linkedin</span>
        </>
      ),

      icon: (
        <>
          <svg
            width="15"
            height="15"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.6328 13.6331H11.2621V9.92034C11.2621 9.035 11.2463 7.89529 10.0291 7.89529C8.79431 7.89529 8.6054 8.85991 8.6054 9.85588V13.6328H6.23468V5.99793H8.51056V7.04132H8.54242C8.77018 6.65188 9.09931 6.33151 9.49473 6.11433C9.89016 5.89714 10.3371 5.79129 10.7879 5.80802C13.1907 5.80802 13.6338 7.38854 13.6338 9.4447L13.6328 13.6331ZM3.55975 4.95429C3.28765 4.95434 3.02165 4.8737 2.79538 4.72257C2.56911 4.57143 2.39275 4.35659 2.28858 4.10522C2.18441 3.85385 2.15711 3.57722 2.21014 3.31034C2.26318 3.04345 2.39417 2.79828 2.58653 2.60584C2.7789 2.4134 3.02401 2.28232 3.29087 2.22919C3.55773 2.17606 3.83435 2.20325 4.08575 2.30734C4.33715 2.41142 4.55205 2.58772 4.70326 2.81394C4.85447 3.04016 4.9352 3.30615 4.93525 3.57825C4.93529 3.75892 4.89973 3.93783 4.83062 4.10476C4.76151 4.2717 4.66021 4.42338 4.53249 4.55116C4.40476 4.67893 4.2531 4.7803 4.0862 4.84947C3.9193 4.91864 3.74042 4.95426 3.55975 4.95429ZM4.7451 13.6331H2.37193V5.99793H4.7451V13.6331ZM14.8147 0.00109011H1.18066C0.871207 -0.00240219 0.573012 0.117109 0.351627 0.333362C0.130241 0.549614 0.00377671 0.844915 0 1.15438V14.8454C0.00364745 15.155 0.130038 15.4505 0.351416 15.667C0.572793 15.8835 0.871053 16.0032 1.18066 15.9999H14.8147C15.1249 16.0038 15.424 15.8844 15.6462 15.6679C15.8685 15.4515 15.9957 15.1556 16 14.8454V1.15339C15.9956 0.843314 15.8683 0.547677 15.646 0.331429C15.4237 0.115182 15.1247 -0.00398762 14.8147 0.000101881"
              fill="#989898"
            />
          </svg>
        </>
      ),
      value: "linkedin_url",
    },
    {
      text: (
        <>
          &nbsp;&nbsp;&nbsp; <span className={classes.iconName}>Twitter</span>
        </>
      ),

      icon: (
        <>
          <svg
            width="15"
            height="15"
            viewBox="0 0 16 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.03181 12.9932C11.0698 12.9932 14.3718 7.99487 14.3718 3.66048C14.3718 3.51851 14.3689 3.37718 14.3625 3.2365C15.0035 2.7735 15.5606 2.19571 16 1.53798C15.4118 1.79922 14.7789 1.97508 14.1149 2.05437C14.7926 1.64829 15.3129 1.00591 15.5584 0.240133C14.9241 0.61584 14.2218 0.888907 13.4739 1.03633C12.8749 0.398772 12.0221 0 11.0778 0C9.26495 0 7.79491 1.46896 7.79491 3.27966C7.79491 3.53705 7.8237 3.78742 7.88003 4.02757C5.15174 3.8904 2.7325 2.58517 1.11341 0.60049C0.831492 1.08523 0.668933 1.64831 0.668933 2.24912C0.668933 3.38712 1.24847 4.39174 2.12975 4.97946C1.59118 4.96284 1.08525 4.81511 0.643054 4.56922C0.642571 4.58298 0.642569 4.5964 0.642569 4.61111C0.642569 6.19965 1.7741 7.52595 3.27614 7.82649C3.0003 7.90163 2.71008 7.94192 2.41055 7.94192C2.19935 7.94192 1.99358 7.92115 1.79359 7.88277C2.2115 9.18606 3.42336 10.1345 4.86012 10.161C3.73659 11.0409 2.32126 11.565 0.783025 11.565C0.518384 11.565 0.256947 11.55 0 11.5196C1.45281 12.4501 3.17789 12.993 5.03199 12.993"
              fill="#989898"
            />
          </svg>
        </>
      ),
      value: "twitter_url",
    },
    {
      text: (
        <>
          &nbsp;&nbsp;&nbsp; <span className={classes.iconName}>Pinterest</span>
        </>
      ),

      icon: (
        <>
          <svg
            width="15"
            height="15"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.99997 3.8147e-06C3.58173 3.8147e-06 0 3.58175 0 8C0 11.3892 2.10865 14.2865 5.08457 15.4522C5.01467 14.8192 4.95145 13.8458 5.11231 13.1548C5.2577 12.5304 6.05041 9.17824 6.05041 9.17824C6.05041 9.17824 5.81111 8.699 5.81111 7.99049C5.81111 6.87809 6.45587 6.04761 7.25868 6.04761C7.94121 6.04761 8.27087 6.56004 8.27087 7.17451C8.27087 7.86091 7.83386 8.88714 7.60834 9.83816C7.41987 10.6344 8.00764 11.2838 8.793 11.2838C10.2149 11.2838 11.3078 9.78451 11.3078 7.62037C11.3078 5.7049 9.9315 4.36567 7.96619 4.36567C5.68992 4.36567 4.35384 6.07299 4.35384 7.83743C4.35384 8.52502 4.61871 9.26231 4.94922 9.66313C5.0146 9.74235 5.02418 9.81173 5.0047 9.89258C4.94398 10.1453 4.80902 10.6886 4.78252 10.7999C4.74764 10.9463 4.66658 10.9774 4.51497 10.9068C3.51582 10.4417 2.89113 8.98092 2.89113 7.8076C2.89113 5.28402 4.72468 2.96643 8.17703 2.96643C10.9522 2.96643 13.1089 4.944 13.1089 7.58686C13.1089 10.344 11.3705 12.5629 8.9576 12.5629C8.14693 12.5629 7.38485 12.1417 7.12398 11.6442C7.12398 11.6442 6.72284 13.1717 6.62559 13.546C6.44499 14.2409 5.95742 15.1119 5.6313 15.6433C6.3798 15.875 7.17526 16 7.99997 16C12.4183 16 16 12.4183 16 8C16 3.58175 12.4183 3.8147e-06 7.99997 3.8147e-06Z"
              fill="#989898"
            />
          </svg>
        </>
      ),
      value: "pinterest_url",
    },
    {
      text: (
        <>
          &nbsp;&nbsp;&nbsp; <span className={classes.iconName}>Messenger</span>
        </>
      ),
      icon: (<></>),
      value: "messenger_url",
    },
    {
      text: (
        <>
          &nbsp;&nbsp;&nbsp; <span className={classes.iconName}>Snapchat</span>
        </>
      ),
      icon: (<></>),
      value: "snapchat_url",
    },
  ];
  const newIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 45 45"
        className={classes.menuArrowDown}
      >
        <path
          fill="none"
          fillRule="evenodd"
          stroke="#979797"
          strokeWidth="2"
          d="M17 20l5.467 5.467L27.934 20"
        />
      </svg>
    );
  };
  // handle click event of the Add button
  // eslint-disable-next-line
  const handleAddClick = () => {
    setInputList([...inputList, { title: "", link: "" }]);
  };
  const getStyle = (snapshot: any, provided: any, draggableId: any) => {
    if (draggableId === snapshot.draggingFromThisWith) {
      let transform = provided?.draggableProps?.style?.transform;
      let droppableReff = droppableRef.current;

      if (transform) {
        transform = transform?.substring(10)?.split(",");
        let xTranslate = Number(transform[0].replace("px", ""));
        let yTranslate = Number(transform[1].replace("px)", ""));
        let y = yTranslate + provided?.draggableProps?.style?.top;
        let minY = droppableReff.getBoundingClientRect().y;
        let maxY =
          droppableReff.getBoundingClientRect().y +
          droppableReff.getBoundingClientRect().height;

        if (xTranslate > 70 || xTranslate < -70 || y < minY || y > maxY) {
          return {
            ...provided?.draggableProps?.style,
            opacity: 0,
            cusrsor: "pointer",
          };
        } else {
          return {
            ...provided?.draggableProps?.style,
            opacity: 1,
          };
        }
      }
    }
    return provided.draggableProps.style;
  };
  
  return (<>
            <Box style={{display:"flex", alignItems: "center"}}>
              <Typography className={classes.heading} style={{marginBottom: "unset", flex: 1}} variant="h6">
                Asset Type
              </Typography>
              <Box>
                <RadioGroup row aria-label="image" name="image" value={socialStyle} onChange={handleChangeSocialStyle}>
                  <FormControlLabel value="automatic" control={<Radio color="primary" />} label="Automatic" onClick={handleClickAutomatic} />
                  <FormControlLabel value="custom" control={<Radio color="primary" />} label="Custom" />
                </RadioGroup>
              </Box>
            </Box>              
            <Typography variant="caption" className={classes.caption} style={{marginBottom:12}}>
                We will automatically generate social icons based upon your Design System Main Colour Pallet.
            </Typography>            

            { inputList.length < 9 && (<IconSelect
                name="title11"
                variant="outlined"
                IconComponent={newIcon}
                className={classes.selectIcon}
                color="primary"
                style={{marginBottom:20}}
                label="ADD SOCIAL"
                defaultValue=""
              >
                {titleOptions
                  .filter((ele) => {
                    let flag = true;
                    inputList.forEach((e: any) => {
                      if (
                        e.title === ele.value 
                      ) {
                        flag = false;
                      }
                    });
                    return flag;
                  })
                  .map((option) => (
                    <MenuItem
                      style={{ overflow: "hidden" }}
                      key={option.value}
                      value={option.value}
                      onClick={() => {hadleClickSocialItem(option.value)} }
                    >
                      {/* {option.icon} */}
                      {option.text}
                    </MenuItem>
                  ))}
            </IconSelect>)}
    <div ref={droppableRef} className={classes.dragDropSocialsConatiner}>
      {showFields ? (
        <DragDropContext
          onDragEnd={(params: any) => {
            if (!(params.source && params.destination)) return;

            const sourceIndex: number = params.source.index;
            const destinationIndex: number = params.destination.index;

            inputList.splice(
              destinationIndex,
              0,
              inputList.splice(sourceIndex, 1)[0]
            );
            setInputList(inputList);
          }}
        >
          <Droppable droppableId="droppable-1">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {inputList.map((x: any, i: any) => {
                   
                  return (
                    <Draggable key={i} draggableId={"draggable-" + i} index={i}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getStyle(snapshot, provided, "draggable-" + i)}
                        >
                          <div className="box">
                            <>
                              <Grid
                                container
                                spacing={1}
                                className={classes.footerGrid}
                              >
                                <Grid item xs={12} sm={2} md={12}>
                                  <Box width="100%" display="flex">
                                    {/* <IconSelect
                                      name="title"
                                      variant="outlined"
                                      IconComponent={newIcon}
                                      className={classes.selectIcon}
                                      value={x.title}
                                      onBlur={handleBlur}
                                      onChange={(e) => {
                                        handleInputChange(e, i);
                                      }}
                                    >
                                      {titleOptions
                                        .filter((ele) => {
                                          let flag = true;
                                          inputList.forEach((e: any) => {
                                            if (
                                              e.title === ele.value &&
                                              e.title !== x.title
                                            ) {
                                              flag = false;
                                            }
                                          });
                                          return flag;
                                        })
                                        .map((option) => (
                                          <MenuItem
                                            style={{ overflow: "hidden" }}
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.icon}
                                            {option.text}
                                          </MenuItem>
                                        ))}
                                    </IconSelect> */}
                                    {socialStyle === "custom" && (<LinkTextField
                                      name="src"
                                      autoComplete="src"
                                      type="text"
                                      variant="outlined"
                                      label={`${getLabelNameFromUrl(x.title)} Src`}
                                      margin="normal"
                                      fullWidth
                                      value={x.src}
                                      error={(state.errorSocial === true && state.SocialSrc.hasOwnProperty(x.title) ? true : false)}
                                      helperText={(state.errorSocial === true && state.SocialSrc.hasOwnProperty(x.title) ? 'This field cannot be left blank' : '')}
                                      onBlur={(e) => handleBlurSrc(e,i)}
                                      onChange={(e) => handleInputSrcSocialChange(e, i)}
                                      InputProps={{
                                        startAdornment: <InputAdornment position="start"><Box></Box></InputAdornment>,
                                      }}
                                      style={{marginRight:15}}
                                    />)}
                                    <LinkTextField
                                      name="link"
                                      autoComplete="link"
                                      type="text"
                                      style={{position:"relative"}}
                                      variant="outlined"
                                      label={`${getLabelNameFromUrl(x.title)} Link`}
                                      margin="normal"
                                      fullWidth
                                      error={(state.errorSocial === true && state.Socialtitle.hasOwnProperty(x.title) ? true : false)}
                                      helperText={(state.errorSocial === true && state.Socialtitle.hasOwnProperty(x.title) ? 'This field cannot be left blank' : '')}
                                      value={x.link}
                                      onBlur={handleBlur}
                                      onChange={(e) => handleInputChange(e, i)}
                                      InputProps={{
                                        startAdornment: <InputAdornment position="start"><Box></Box></InputAdornment>,
                                      }}
                                    />
                                    
                                    {inputList.length !== 1 ? (
                                      <div className={classes.fieldSideIconSocialDiv}>
                                        <HighlightOffRoundedIcon
                                          fontSize="default"
                                          className={classes.fieldSideIcon}
                                          onClick={() => handleRemoveClick(i)}
                                        ></HighlightOffRoundedIcon>
                                        <div {...provided.dragHandleProps}>
                                          <MenuRoundedIcon
                                            className={classes.fieldSideIcon}
                                            fontSize="default"
                                          ></MenuRoundedIcon>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className={classes.fieldSideIconSocialDiv}>
                                        <HighlightOffRoundedIcon
                                          fontSize="default"
                                          className={classes.fieldSideIcon}
                                          onClick={() => handleHideClick()}
                                        ></HighlightOffRoundedIcon>
                                        <div {...provided.dragHandleProps}>
                                          <MenuRoundedIcon
                                            className={classes.fieldSideIcon}
                                            fontSize="default"
                                          ></MenuRoundedIcon>
                                        </div>
                                      </div>
                                    )}
                                    
                                  </Box>
                                </Grid>
                              </Grid>
                              {/* {inputList.length - 1 === i &&
                                !snapshot.isDraggingOver && (
                                  <Button
                                    className={classes.addNewSocialButton}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={handleAddClick}
                                  >
                                    Add
                                  </Button>
                                )} */}
                            </>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (""
        // <Button
        //   variant="outlined"
        //   color="primary"
        //   size="small"
        //   onClick={showListFields}
        // >
        //   Add
        // </Button>
      )}
    </div>
    </>
  );
};

export default DynamicSociallinks;
