import {UPDATE_FIELD} from '../enums/Enums'
const initialState ={
}


 const EspManagerReducer = (state=initialState,action:any)=>{
    switch(action.type){
        case UPDATE_FIELD: return {...state,[action.payload.fieldName]:action.payload.fieldValue} 
        default: return state
    }
}
export default EspManagerReducer