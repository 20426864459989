
import Button from "@material-ui/core/Button";
// import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
// import LogoSVG, { SignInSVG } from "../../../assets/svgs/Svgs";
import { Box, IconButton, Slide } from "@material-ui/core";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { useState } from "react";
import { useEffect } from "react";
import { Field, Form, Formik } from "formik";
import { Link } from "react-router-dom";
import * as yup from "yup";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';
import {Switch } from "@material-ui/core";
import AxiosInstance, {setAuthToken} from "../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
import {isEnterprise} from "../../../../utils/AuthUtility";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { ShapesHerolgt, LogoMashrmail } from '../../../assets/svgs/Svgs';
import moment from "moment";
import {useDispatch} from "react-redux";
import {updateActiveTabs} from "../../../redux/actions/TabManagerActions";

const useStyles = makeStyles((theme) => ({ 
  root: {
    height: "100vh",
  },
  captionText: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "120%",
    marginRight: "5px",
    letterSpacing: "0.005em",
    color: "#646464",
  },
  caption: {
    marginBottom: "20px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "26px",
    /* or 162% */

    letterSpacing: "0.005em",

    color: "#646464",
  },
  switchCustom:{
    paddingBottom: 10,
    display: "flex",
    alignItems: "right",
  },
  signUpLink: {
    color: "#057CFF",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "120%",
    letterSpacing: "0.005em",
    fontStyle: "normal",
  },
  fogotPasswordLink: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "16px",
    lineHeight: "120%",
    letterSpacing: " 0.46px",
    textTransform: "uppercase",
    fontWeight: 500,
    marginRight: 27,

  },
  loginbottomOptions: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "15px",
    lineHeight: "120%",
    letterSpacing: " 0.46px",
    textTransform: "uppercase",
    fontWeight: 500,
    marginRight: 27,

  },
  forgotPasswordlinkStyle: {
    textDecoration: "none",
    marginTop: "15px",
    marginBottom: "15px",
  },
  linkStyle: { textDecoration: "none" },
  svg: {
    display: "flex",
    alignItems: "center",
    background: "#f7f7fc",
  },
  errorContainer: {
    margin: 10,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  errorContainer400: {
    margin: 10,
    display: "flex",
    alignItems: "center",
  },
  errorContainerText400: {
    paddingLeft: "1rem",
  },
  link: {
    color: "#FFE8E8",
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: "14px",
  },
  errorIcon: {
    color: "#fff",
    width: "26.67px",
    height: "26.67px",
  },
  heading: {
    marginBottom: 10,
    fontStyle: "normal",
    // fontWeight: "bold",
    fontSize: "31px",
    lineHeight: "120%",
    letterSpacing: "0.005em",
    color: "#11142D",
  },
  errorPaper: {
    // marginTop: "-5px",
    marginBottom: 24,
    width: "100%",
    background: "#EB5757",
    padding: "3px",
  },
  oTpSend: {
    // marginTop: "-5px",
    marginBottom: 24,
    width: "100%",
    color:"#fff",
    background: "#39ac39",
    padding: "3px",
  },
  errorPaperDeactivated: {
    // marginTop: "-5px",
    marginBottom: 24,
    width: "100%",
    background: "#4da6ff",
    padding: "3px",
  },
  headingswitch: {
    marginBottom: theme.spacing(1),
    color: "#2F2F2F",
    fontSize: "18px",
    lineHeight: "23px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  customFontsLabel:{
    flex: 1,
    marginBottom: 0,
    fontSize:"15px",
  },
  errorText: {
    color: "#fff",
    fontSize: "16px",
    lineHeight: "20px",
    fontStyle: "normal",
  },
   errorTextDeactive: {
    color: "#fff",
    
    fontFamily: "Montserrat",
    fontSize: "16px",
    lineHeight: "20px",
    fontStyle: "normal",
  },
  radioCustomFonts:{
    padding: "0 9px 0 0"
  },
  formGrid: {
    display: "flex",
    justifyContent: "center",
    // flexDirection: "column",
    alignItems: "center",
    fontFamily: 'Poppins, sans-serif'
  },
  loginTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "left",
    width: "100%",
  },
  paper: {
    margin: "85px 32px",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth:350,
    maxWidth: 514,
    width: "100%",
    position: "relative",
    height: "100%"
  },
  logo: {
    position: "absolute",
    top: 25,
    left: 30,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    // margin: theme.spacing(3, 0, 2),
    textTransform: "uppercase",
    padding: "16px 23px",
    borderRadius: 4,
    boxShadow: "none",
    width: "unset",
    "&:hover": {
      backgroundColor: "rgb(5 124 255)",
    },
  },
  InputLabel: {
    marginTop: ".7rem",
    marginBottom: ".2rem",
    fontFamily: 'Poppins, sans-serif',
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "120%",
  },
  InputText: {
    marginTop: "0",
    boxSizing: 'border-box',
    "& .MuiOutlinedInput-root": {
      "& input:-webkit-autofill": {
        transition: 'background-color 5000s ease-in-out 0s',
        WebkitBoxShadow: '0 0 0px 1000px white inset'
      },
      "& input": {
        fontFamily: 'Poppins, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        letterSpacing: '0.005em',
        lineHeight: '120%',
        color: "#11142D",
      },
    },
  },
  errortextBorder: {
    marginTop: "0",
    boxSizing: 'border-box',
    "& .MuiOutlinedInput-root": {
      "& input": {
        fontFamily: 'Poppins, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        letterSpacing: '0.005em',
        lineHeight: '120%',
        color: "#11142D",
      },
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "& input:-webkit-autofill": {
        transition: 'background-color 5000s ease-in-out 0s',
        WebkitBoxShadow: '0 0 0px 1000px white inset'
      },
    },
  },
  eyeBtn: {
    padding: '8px'
  },
  customPoster: {
	  backgroundRepeat: "no-repeat",
	width: "100%",
	height: "100%",
	objectFit: "cover",
  },
  logoImage:{
    position: "absolute",
    top: 40,
    left: 0
  },
  switchCustomLabelbox:{
    paddingBottom: 10,
    display: "flex",
    alignItems: "center"
  },
  boxImgVisual:{
    background: "#234FBD",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonOption:{
    display: "flex",
    alignItems: "center",
    paddingTop: 24,
    marginTop: 14,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)"
  }
}));

interface Props {
  history: any;
}
const validationSchema = yup.object({
  email: yup
    .string()
    .required("Email is missing")
    .email("Must be a Valid Email"),
  password: yup.string().required("Password is missing"),
});

const Login: React.FC<Props> = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [csrfName, setCsrfName] = useState<boolean>(false);
  const [csrf, setCsrfHash] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, seterrorMsg] = useState<string>("");
  const [deactivate, setdeactivate] = useState<boolean>(false);
  const [securityStep1, setsecurityStep1] = useState<boolean>(false);
  const [userEmail, setuserEmail] = useState<string>("");
  const [userPassword, setuserPassword] = useState<string>("");
  // eslint-disable-next-line
  const [oTp, setoTp] = useState<string>("");
  const [oTpResend, setoTpResend] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(true);
   const [otpstep, setotpstep] = useState<boolean>(false);
  
  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };
  const checkboxhandleClick = (event: any) => {
      if(event.target.checked)
      {
        setChecked(true);
        let usersarr: any = {};
        let getUsers: any = {};
        getUsers  =  JSON.parse(localStorage.getItem('registerusersondevice') || '{}');
        var length = Object.keys(getUsers).length;
        var arr = { "rememberdevice": "1", "registerTime": getCurrentTime()};
        if(length<=0)
        {
            if(userEmail !==null )
            {
                usersarr[userEmail] = arr;
                localStorage.setItem('registerusersondevice', JSON.stringify(usersarr));
            } 
      } else {
            getUsers[userEmail] = arr;
            localStorage.setItem('registerusersondevice', JSON.stringify(getUsers));
      }
        
      } else {
         setChecked(false);
         let getUsers: any = {};
         if(localStorage.getItem('registerusersondevice') !==null )
         {
         getUsers  =  JSON.parse(localStorage.getItem('registerusersondevice') || '{}');
         var lengthofarr = Object.keys(getUsers).length;
         if(lengthofarr>0)
         {
             
             //delete getUsers[userEmail];
             getUsers[userEmail].rememberdevice = '0';
             localStorage.setItem('registerusersondevice', JSON.stringify(getUsers));
         }
        
         }
         //localStorage.removeItem(userEmail+"-registerTime");
         //localStorage.setItem(userEmail+"-rememberdevice", '0');
         //localStorage.removeItem(userEmail+"-authverify");
         
      }
    
  };
  if (localStorage.getItem("X-Token")) history.push("/campaign");
   const resendCode = () => {
       setError(false);
	var FormData = require("form-data");
    var sendData = new FormData();
    sendData.append("usr_email", userEmail);
    sendData.append(csrfName, csrf);
    AxiosInstance.post('api/v1/users/resendcode', sendData, {
    	withCredentials: false
	}).then(function (response: AxiosResponse) {
        if(response.data.factorResult === 'SUCCESS')
        {
            setoTpResend(true);
        } else {
            setoTpResend(false);
        }
        
      })
       .catch(function (error: any) {
		  if(error.response.data.message === 'expired'){
			localStorage.setItem("userToken", error.response.data.token);
			history.push("/set-new-password");  
		  }
          seterrorMsg(error.response.data.message);
          let result = error.response.data.message.includes("deactivated");
          setdeactivate(result);
           setError(true);
      });
   };
   function getCurrentTime(){
    return moment().format('DD MMM YYYY HH:mm:ss'); 
 }
 
   const loginaction = () => {
       
     setError(false);
	var FormData = require("form-data");
    var sendData = new FormData();
    sendData.append("usr_email", userEmail);
    sendData.append("usr_password", userPassword);
    sendData.append("e2factor", '0');
    sendData.append(csrfName, csrf);
	localStorage.removeItem("userToken");
    AxiosInstance.post('api/v1/users/login', sendData, {
    	withCredentials: false
	}).then(function (response: AxiosResponse) {
        localStorage.setItem("X-Token", response.data.access_token);
		localStorage.setItem("trial_mode", response.data.trial_mode);
        localStorage.setItem("team_active", response?.data?.team_active);
		localStorage.setItem("numberDays", response.data.numberDays);
		localStorage.setItem("day_msg", response.data.day_msg);
		localStorage.setItem("end_msg", response.data.end_msg);
		localStorage.setItem("date_ended", response.data.date_ended);
		localStorage.setItem("max_users", response.data.max_users);
		localStorage.setItem("max_ds", response.data.max_ds);
		localStorage.setItem("max_integrations", response.data.max_integrations);
		localStorage.setItem("max_teams", response.data.max_teams);
        localStorage.setItem("loggedInUser", JSON.stringify(response.data));
        localStorage.setItem("usr_id", response?.data?.usr_id);
        localStorage.setItem("my_teams", response.data.my_teams);
		dispatch(updateActiveTabs({fieldName: "my_teams", fieldValue: response.data.my_teams}));
		localStorage.setItem('campReload',JSON.stringify(false)); 
		localStorage.setItem('ImageReload',JSON.stringify(false));
		localStorage.setItem('TemplateReload',JSON.stringify(false));
		localStorage.setItem('DSReload',JSON.stringify(false));
		localStorage.setItem('LandedReload',JSON.stringify(false));
        localStorage.setItem("mktstatus", response?.data?.mktstatus);
         localStorage.setItem(
          "company_id",
          response?.data?.user_detail?.Company
        );
        localStorage.setItem(
          "defaultStyleId",
          response?.data?.user_detail?.defaultStyleId
        );
        
		var dataconifg = {company_id: response?.data?.user_detail?.CompanyName , name: response?.data?.user_detail?.CompanyName, website:response?.data?.base_url};
		window.dataLayer.push({
			'name': response?.data?.user_detail?.display_name, 
			'email': response?.data?.user_detail?.Email,
			'user_hash': response?.data?.user_detail?.user_hash,
			'company_id':response?.data?.user_detail?.CompanyName,
			'company_name':response?.data?.user_detail?.CompanyName,
			'company_website':response?.data?.base_url
		});
		window.Intercom('update');
		window.Intercom('boot', {  
			app_id: 'v9dbixsy',  
			'name': response?.data?.user_detail?.display_name, 
			'email': response?.data?.user_detail?.Email,
			'user_hash': response?.data?.user_detail?.user_hash,
			'company': dataconifg
		});

        setAuthToken(response.data.access_token);
        history.push("/campaign");
        
      })
      .catch(function (error: any) {
		  if(error.response.data.message === 'expired'){
			localStorage.setItem("userToken", error.response.data.token);
			history.push("/set-new-password");  
		  }
          seterrorMsg(error.response.data.message);
          let result = error.response.data.message.includes("deactivated");
          setdeactivate(result);
           setError(true);
      });
   };
   
  const Verifycode = (data: any, { setSubmitting }: any) => {
        setError(false);
        var rememberdevice;
	var FormData = require("form-data");
    var sendData = new FormData();
    sendData.append("usr_email", userEmail);
    sendData.append("auth_code", data.auth_code);
    sendData.append(csrfName, csrf);
	//localStorage.removeItem("userToken");
    AxiosInstance.post('api/v1/users/verifcode', sendData, {
    	withCredentials: false
	}).then(function (response: AxiosResponse) {
        if(response.data.factorResult === 'SUCCESS')
        {
            loginaction();
            let getUsers: any = {};
            getUsers  =  JSON.parse(localStorage.getItem('registerusersondevice') || '{}');
            var length = Object.keys(getUsers).length;
            if(length>0){
                getUsers[userEmail].authverify = 'SUCCESS';
                localStorage.setItem('registerusersondevice', JSON.stringify(getUsers));
            }
            if(getUsers[userEmail].rememberdevice!== null)
            {
                rememberdevice = getUsers[userEmail].rememberdevice;
                if(rememberdevice === '1')
                {
                    getUsers[userEmail].authCode = response.data.authCode;
                    localStorage.setItem('registerusersondevice', JSON.stringify(getUsers));
                }

            }
    
    
            
        } else {
            setoTpResend(false);
        }
        
      })
      .catch(function (error: any) {
		  if(error.response.data.message === 'expired'){
			localStorage.setItem("userToken", error.response.data.token);
			history.push("/set-new-password");  
		  }
          seterrorMsg(error.response.data.message);
          let result = error.response.data.message.includes("deactivated");
          setdeactivate(result);
          setoTpResend(false);
           setError(true);
      });
	 
  };
  

const onBackButtonEvent = (e:any) => {
    e.preventDefault();
     setsecurityStep1(false);
}
  
  const onSubmit = (data: any, { setSubmitting }: any) => {
    
    setError(false);
    var rememberdevice ;
    var authverify ;    
	var FormData = require("form-data");
    var sendData = new FormData();
    sendData.append("usr_email", data.email);
    sendData.append("usr_password", data.password);
    let getUsers: any = {};
    getUsers  =  JSON.parse(localStorage.getItem('registerusersondevice') || '{}');
    var length = Object.keys(getUsers).length;
    if(length>0)
    {
        if(getUsers[data.email])
        {
            if(getUsers[data.email].hasOwnProperty('rememberdevice'))
            {
                if(getUsers[data.email].rememberdevice!==null)
                {
                    rememberdevice = getUsers[data.email].rememberdevice;
                }
            } else {
                rememberdevice = '0';
            }
            if(getUsers[data.email].hasOwnProperty('authverify'))
            {
                if(getUsers[data.email].authverify !== null)
                {
                    authverify = getUsers[data.email].authverify;
                }
            } else {
                authverify = 'FAILED';
            }
            
            if(rememberdevice==='1' && authverify === 'SUCCESS' )
            {
                sendData.append("e2factor", '0');
                sendData.append("rememberdevice", rememberdevice);
            } else {
                sendData.append("e2factor", '1');
                sendData.append("rememberdevice", '0');
            }
            
            if(getUsers[data.email].hasOwnProperty('authCode'))
            {
                if(getUsers[data.email].authCode !== null)
                {
                    sendData.append("authCode", getUsers[data.email].authCode);
                }
            }
    } else {
        sendData.append("e2factor", '1');
        sendData.append("rememberdevice", '0');
    }
  } else {
        sendData.append("e2factor", '1');
        sendData.append("rememberdevice", '0');
  }
    setuserEmail(data.email);
    setuserPassword(data.password);
    sendData.append(csrfName, csrf);
    var currentDate = new Date(getCurrentTime());
     if(length>0)
     {
         if(getUsers[data.email])
         {
         if(getUsers[data.email].hasOwnProperty('registerTime'))
         {
        const registerTime = String(getUsers[data.email].registerTime || '');
        let saveDate = new Date(registerTime);
        var time_difference = currentDate.getTime() - saveDate.getTime(); 
        var days_difference = Math.round(time_difference / (1000 * 60 * 60 * 24));
        if(isNaN(days_difference))
        {
            sendData.append("days_difference", 0);
        } else {
            sendData.append("days_difference", days_difference);
        }
        
        if(days_difference>30)
        {
            delete getUsers[data.email];
            localStorage.setItem('registerusersondevice', JSON.stringify(getUsers));
            sendData.append("e2factor", '1');
            setChecked(false); 
           
        }
     }
  }
  }
    
	localStorage.removeItem("userToken");
    AxiosInstance.post('api/v1/users/login', sendData, {
    	withCredentials: false
	}).then(function (response: AxiosResponse) {
        if(response.data.access_token!=='' && (response.data.rememberdevice === '0' || response.data.authdata === 'FAILED' ) && response.data.two_factor_auth_db === '1' )
        {
            
           setsecurityStep1(true);
            history.push("/login/otp");
            setotpstep(true);
            
           
           
        } else if(response.data.authdata === 'SUCCESS') {
            let getUsers: any = {};
            getUsers  =  JSON.parse(localStorage.getItem('registerusersondevice') || '{}');
            var length = Object.keys(getUsers).length;
            if(length>0 && getUsers[response.data.usr_email]){
                 getUsers[response.data.usr_email].authverify = response.data.authdata;
        }
            
        localStorage.setItem("X-Token", response.data.access_token);
		localStorage.setItem("trial_mode", response.data.trial_mode);
		localStorage.setItem("team_active", response?.data?.team_active);
		localStorage.setItem("numberDays", response.data.numberDays);
		localStorage.setItem("day_msg", response.data.day_msg);
		localStorage.setItem("end_msg", response.data.end_msg);
		localStorage.setItem("date_ended", response.data.date_ended);
		localStorage.setItem("max_users", response.data.max_users);
		localStorage.setItem("max_ds", response.data.max_ds);
		localStorage.setItem("max_integrations", response.data.max_integrations);
		localStorage.setItem("max_teams", response.data.max_teams);
        localStorage.setItem("loggedInUser", JSON.stringify(response.data));
        localStorage.setItem("usr_id", response?.data?.usr_id);
		localStorage.setItem("my_teams", response.data.my_teams);
		dispatch(updateActiveTabs({fieldName: "my_teams", fieldValue: response.data.my_teams}));
		localStorage.setItem('campReload',JSON.stringify(false)); 
		localStorage.setItem('ImageReload',JSON.stringify(false));
		localStorage.setItem('TemplateReload',JSON.stringify(false));
		localStorage.setItem('DSReload',JSON.stringify(false));
		localStorage.setItem('LandedReload',JSON.stringify(false));
        localStorage.setItem("mktstatus", response?.data?.mktstatus);
        localStorage.setItem(
                 "company_id",
          response?.data?.user_detail?.Company
        );
        localStorage.setItem(
          "defaultStyleId",
          response?.data?.user_detail?.defaultStyleId
        );
		var dataconifg = {company_id: response?.data?.user_detail?.CompanyName , name: response?.data?.user_detail?.CompanyName, website:response?.data?.base_url};
		window.dataLayer.push({
			'name': response?.data?.user_detail?.display_name, 
			'email': response?.data?.user_detail?.Email,
			'user_hash': response?.data?.user_detail?.user_hash,
			'company_id':response?.data?.user_detail?.CompanyName,
			'company_name':response?.data?.user_detail?.CompanyName,
			'company_website':response?.data?.base_url
		});
		window.Intercom('update');
		window.Intercom('boot', {  
			app_id: 'v9dbixsy',  
			'name': response?.data?.user_detail?.display_name, 
			'email': response?.data?.user_detail?.Email,
			'user_hash': response?.data?.user_detail?.user_hash,
			'company': dataconifg
		});

        setAuthToken(response.data.access_token);
        history.push("/campaign");
        }
        
      })
      .catch(function (error: any) {
          
		  if(error.response.data.message === 'expired'){
			localStorage.setItem("userToken", error.response.data.token);
			history.push("/set-new-password");  
		  }
          seterrorMsg(error.response.data.message);
          let result = error.response.data.message.includes("deactivated");
          setdeactivate(result);
           setError(true);
      });
	 
  };
  const getCSRFToken = async () => {
     AxiosInstance.get('api/v1/users/getcsrftoken', {
    	withCredentials: false
	}).then(function (response: AxiosResponse) {
		setCsrfName(response.data.csrfName);
		setCsrfHash(response.data.csrfHash);
		localStorage.setItem("csrfName", response.data.csrfName);
		localStorage.setItem("csrfHash", response.data.csrfHash);
      })
 };

   useEffect(()=>{ 
	   getCSRFToken();
        let getUsers: any = {};
        getUsers  =  JSON.parse(localStorage.getItem('registerusersondevice') || '{}');
        if(userEmail!==undefined && userEmail!==null)
        {
             var length = Object.keys(getUsers).length;
             if(length>0 && getUsers[userEmail])
             {
                  var Innerlength = Object.keys(getUsers[userEmail]).length;
                  if(Innerlength>0)
                  {
                      if(getUsers[userEmail].rememberdevice === '1')
                      {
                         
                          setChecked(true); 
                      } else {
                          setChecked(false); 
                      }
                      
                  }
                 //alert(Object.keys(getUsers[userEmail]).length);
             } else {
                 setChecked(false); 
             }
            
        } else {
            setChecked(false); 
        }
		
    },[userEmail]);
    
    
    useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);  
    };
  }, []);
  
  
    useEffect(() => {
   if(!otpstep)
   {
       history.push("/login");
   }
	var uID= localStorage.getItem("usr_id");
	if(!uID){
		window.onload = () => {
			setTimeout(function(){
			if(window.Intercom){
				window.Intercom('shutdown');
			}
			} , 2000);
		};
	}
   // eslint-disable-next-line 
  }, []);
 
  
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Grid container component="main" className={classes.root}>
        {/* <CssBaseline /> */}
        {/* <Hidden smDown>
          <Grid item xs={false} sm={5} md={4} className={classes.svg}>
            <span className={classes.logo}>
              <Link to="/">
                <LogoSVG />
              </Link>
            </span>
		   {window.globalConfig.loginPoster ? (
			   <img src={window.globalConfig.apiOrigin + window.globalConfig.loginPoster} className={classes.customPoster} alt=""/>
		   ) : <SignInSVG />}

          </Grid>
        </Hidden> */}
        {/* <Box className={classes.logo}>
          <Link to="/">
            <LogoSVG />
          </Link>
        </Box> */}
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          component={Paper}
          className={classes.formGrid}
          elevation={1}
          square
        >
          
          <div className={classes.paper}>
            <Box className={classes.logoImage}>
               <Link to="/login" >
                  <LogoMashrmail />                 
              </Link>
            </Box>
            <Box className={classes.loginTextContainer}>
              <Typography
                component="h1"
                variant="h5"
                className={classes.heading}
              >
                Login
              </Typography>
               {(securityStep1 === true) && (
               <p>We've sent a one-time passcode to your email address.
                   Please enter the passcode below to continue.</p>
                   
               )}
                   
              {/* <Typography
                component="h1"
                variant="caption"
                className={classes.caption}
              >
			    {window.globalConfig.loginWelcome ?? 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa'}.
              </Typography> */}
            </Box>
            {securityStep1 === true ? (
            <Formik
            initialValues={{ auth_code: ""}}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={Verifycode}
            >
              {({
                errors
              }) => (
                <Form className={classes.form}>
                  {(Object.keys(errors).length > 0 || error) && (
                    <Paper className={
                  deactivate === true
                    ? classes.errorPaperDeactivated
                    : classes.errorPaper
                }>
                      <Box className={classes.errorContainer}>

                            {deactivate ? (
                            <ErrorTwoToneIcon className={classes.errorIcon} />
                            ) : (
                            <NotInterestedIcon className={classes.errorIcon} />
                            )}
                        {/* <Box mx={1.5}> */}
                        <Box display="flex" flexDirection="column" mx={2}>
                        <Typography
                        component="h1"
                        variant="caption"
                        className={classes.errorText}
                          >
                          {errorMsg}
                          </Typography>
                          
                        </Box>
                      </Box>
                    </Paper>
                  )}
                  
                  {(oTpResend) && (
                    <Paper className={
                  classes.oTpSend
                }>
                      <Box className={classes.errorContainer}>

                        {/* <Box mx={1.5}> */}
                        <Box display="flex" flexDirection="column" mx={2}>
                        <Typography
                        component="h1"
                        variant="caption"
                        className={classes.errorText}
                          >
                          OTP sent successfully
                          </Typography>
                          
                        </Box>
                      </Box>
                    </Paper>
                  )}
                  
				  <Field
                    label="csrf_name"
                    name={csrfName}
                    type="hidden"
					value={csrf}
                  />
                        <Field
                        as={TextField}
                        className={classes.InputText}
                        placeholder="OTP"
                        key={oTp}
                        name="auth_code"
                        defaultvalue={oTp}
                        fullWidth
                        />
                 <Box className={classes.switchCustomLabelbox}>
            <Typography className={`${classes.headingswitch} ${classes.customFontsLabel}`} variant="h6"> 
                Trust this computer for 30 days 
            </Typography>
            <Switch
              checked={checked}
              onChange={checkboxhandleClick}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Box>
               
                  {/* <Typography
                    variant="subtitle2"
                    className={classes.InputLabel}
                  >
                    Password
                  </Typography> */}
                  
                  <Box className={classes.buttonOption}>
                  <Box display="flex" justifyContent="flex-end" width="50%">
                      
                        <span onClick={resendCode}
                          className={classes.loginbottomOptions} style={{cursor:'pointer'}}
                          // style={{ color: values.email ? "#646464" : "#9A9AB0" }}
                        >
                          Resend Code
                        </span>
                      
                    </Box>
                    <Box display="flex" justifyContent="flex-end" width="50%">
                      <Link
                        to="/sendmail-forget"
                        className={classes.forgotPasswordlinkStyle}
                      >
                        <span
                          className={classes.loginbottomOptions}
                          // style={{ color: values.email ? "#646464" : "#9A9AB0" }}
                        >
                          Forgot password?
                        </span>
                      </Link>
                    </Box>
                    <Button
                      type="submit"
                      fullWidth
                      size="large"
                      variant="contained"
                      // disabled={isSubmitting}
                      color="primary"
                      className={classes.submit}
                      endIcon={<ArrowForwardIcon/>}
                    >
                      Submit
                    </Button>
                  </Box>
				
                </Form>
              )}
            </Formik>
            
             ) : (

            <Formik
              initialValues={{ email: "", password: "", csrf_name:csrf }}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={onSubmit}
            >
              {({
                values,
                isSubmitting,
                errors,
                handleChange,
                handleBlur,
                touched,
              }) => (
                <Form className={classes.form}>
                  {(Object.keys(errors).length > 0 || error) && (
                    <Paper className={
                  deactivate === true
                    ? classes.errorPaperDeactivated
                    : classes.errorPaper
                }>
                      <Box className={classes.errorContainer}>

                            {deactivate ? (
                            <ErrorTwoToneIcon className={classes.errorIcon} />
                            ) : (
                            <NotInterestedIcon className={classes.errorIcon} />
                            )}
                        {/* <Box mx={1.5}> */}
                        <Box display="flex" flexDirection="column" mx={2}>
                        <Typography
                        component="h1"
                        variant="caption"
                        className={classes.errorText}
                          >
                          {errorMsg}
                          </Typography>
                          <Link to="/sendmail-forget">
                           {(!deactivate) && (
                            <Typography
                              component="h5"
                              className={classes.link}
                              variant="caption"
                            >
                                recover your password{" "}
                              
                            </Typography>
                             )}
                          </Link>
                        </Box>
                      </Box>
                    </Paper>
                  )}
                  {/* <Typography
                    variant="subtitle2"
                    className={classes.InputLabel}
                  >
                    Email
                  </Typography> */}
				  <Field
                    label="csrf_name"
                    name={csrfName}
                    type="hidden"
					value={csrf}
                  />
                  <Field
                    label="Email"
                    name="email"
                    autoComplete="email"
                    style={{ marginBottom: 20 }}
                    as={TextField}
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                    type="email"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    className={
                      Boolean((errors.email && touched.email) || error)
                        ? classes.errortextBorder
                        : classes.InputText
                    }
                    placeholder="example@email.com"
                  />
                  {/* <Typography
                    variant="subtitle2"
                    className={classes.InputLabel}
                  >
                    Password
                  </Typography> */}
                  <TextField
                    label="Password"
                    variant="outlined"
                    autoComplete="off"
                    // InputLabelProps={{ shrink: true }}
                    margin="normal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      Boolean((errors.password && touched.password) || error)
                        ? classes.errortextBorder
                        : classes.InputText
                    }
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={handleClick} edge="end" className={classes.eyeBtn}>
                          {showPassword ? (
                            <VisibilityOutlinedIcon
                              style={{ color: "#DADADA" }}
                            />
                          ) : (
                            <VisibilityOffOutlinedIcon
                              style={{ color: "#DADADA" }}
                            />
                          )}
                        </IconButton>
                      ),
                    }}
                    fullWidth
                    name="password"
                    type={showPassword ? "text" : "password"}
                  />
                   
                  <Box className={classes.buttonOption}>
                    <Box display="flex" justifyContent="flex-end" width="100%">
                      <Link
                        to="/sendmail-forget"
                        className={classes.forgotPasswordlinkStyle}
                      >
                        <span
                          className={classes.fogotPasswordLink}
                          // style={{ color: values.email ? "#646464" : "#9A9AB0" }}
                        >
                          Forgot password?
                        </span>
                      </Link>
                    </Box>
                    <Button
                      type="submit"
                      fullWidth
                      size="large"
                      variant="contained"
                      // disabled={isSubmitting}
                      color="primary"
                      className={classes.submit}
                      endIcon={<ArrowForwardIcon/>}
                    >
                      Sign&nbsp;in
                    </Button>
                  </Box>
                  
                  

				{isEnterprise() && <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <span className={classes.captionText}>
                      Doesn't have an account?
                    </span>
                    <Link to="/signup" className={classes.linkStyle}>
                      <span className={classes.signUpLink}>Sign up now</span>
                    </Link>
                  </Box>}
                </Form>
              )}
            </Formik>
            )}
            
            
          </div>
        </Grid>
        <Grid item xs={false} sm={12} md={6} className={classes.boxImgVisual}>
          <ShapesHerolgt />
        </Grid>
      </Grid>
    </Slide>
  );
};

export default Login;
