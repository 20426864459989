import { useState } from "react";
import Button from "@material-ui/core/Button";
// import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import LogoSVG, { SignUpSVG } from "../../../../assets/svgs/Svgs";
import { Box, Slide } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import { ShapesHerolgt, LogoMashrmail } from '../../../../assets/svgs/Svgs';


const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    fontFamily: "Poppins, sans-serif"
  },
  svg: {
    display: "flex",
    alignItems: "center",
    background: "#f7f7fc",
  },
  formGrid: {
    display: "flex",
    justifyContent: "center",
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);',
    alignItems: "center"
  },
  paper: {
    margin: "85px 32px",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth:350,
    maxWidth: 450,
    width: "100%",
    position: "relative",
    height: "100%"
  },
  logo: {
    position: "absolute",
    top: 25,
    left: 30,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  caption: {
    marginBottom: theme.spacing(1),
    fontSize: '16px',
    lineHeight: '26px',
    letterSpacing: '0.005em'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: "12px 16px"
  },
  heading: {
    marginBottom: theme.spacing(1),
    fontWeight: 600,
    fontSize: '31px',
    lineHeight: '120%',
    letterSpacing: '0.005em'

  },
  linkStyle: { textDecoration: "none" },
  signUpLink: {
    color: "#057CFF",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "120%",
    letterSpacing: " 0.005em",
  },
  InputLabel: {
    marginTop: ".7rem",
    fontWeight: 600,
    marginBottom: ".2rem",
    fontSize: '16px',
    lineHeight: '120%'
  },
  InputText: {
    marginTop: "0",
  },
  errortextBorder: {
    marginTop: "0",
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  errorContainer400: {
    margin: 10,
    display: "flex",
    alignItems: "center",
  },
  errorContainerText400: {
    paddingLeft: "1rem",
  },
  errorIcon: {
    color: "#fff",
    width: '26.67px',
    height: '26.67px',
  },
  errorPaper: {
    // marginTop: "-5px",
    marginBottom: 24,
    width: "100%",
    background: "#EB5757",
    padding: '2px 0 2px 2px',
  },
  errorText: {
    color: "#fff",
    fontWeight: 500,
    fontFamily: "Montserrat",
    fontSize: "0.90rem",
  },
  link: {
    color: "#FFE8E8",
    textDecoration: "underline",
    cursor: "pointer",
  },
  bulletsize: {
    fontsize: '2px'
  },
  errorContainer: {
    margin: 10,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  logoImage:{
    position: "absolute",
    top: 40,
    left: 0
  },
  boxImgVisual:{
    background: "#234FBD",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonOption:{
    display: "flex",
    alignItems: "center",
    paddingTop: 24,
    marginTop: 14,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)"
  }
}));

interface Props {
  history: any;
}
const validationSchema = yup.object({
  email: yup.string().required("Please enter a valid email").email(),
});
const SendMail: React.FC<Props> = ({ history }) => {
  const classes = useStyles();
  if (localStorage.getItem("X-Token")) history.push("/campaign");
  const [error, setError] = useState<any>(null)
  const onSubmit = async (data: any, { setSubmitting }: any) => {
    let newData = {
      usr_email: data.email
    }
    setError(null)
    var axios = require("axios");
    var config = {
      method: "post",
      url: `api/v1/users/forgotpassword`,
      data: JSON.stringify(newData),
    };

    try {
      await axios(config);
      localStorage.setItem("usr_forget_email_id", data.email);
      setTimeout(() => {
        history.push({
          pathname: "/checkmail-forget",
        });
      }, 1000);
      setSubmitting(false);
    } catch (err) {
      setError("Incorrect email or password")
      setSubmitting(false);
    }
  };
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Grid container component="main" className={classes.root}>
        {/* <CssBaseline />
        <Hidden smDown>
          <Grid item xs={false} sm={5} md={4} className={classes.svg} style={{
            paddingLeft: "30px",
            paddingRight: "50px",
          }}>
            <SignUpSVG />
          </Grid>
        </Hidden>
        <Box className={classes.logo}>
          <Link to="/">
            <LogoSVG />
          </Link>
        </Box> */}
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          component={Paper}
          className={classes.formGrid}
          elevation={4}
          square
        >
          <div className={classes.paper}>
            <Box className={classes.logoImage}>
               <Link to="/login" >
                  <LogoMashrmail />                 
              </Link>
            </Box>
            <Box>
              <Typography
                component="h1"
                align="left"
                className={classes.heading}
                variant="h5"
              >
                Forgot Your Password?
              </Typography>
              <Typography
                component="p"
                className={classes.caption}
                align="left"
                variant="caption"
              >
                Enter the email address associated with your account and we'll
                send you a link to reset your password.
              </Typography>
            </Box>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form className={classes.form}>

                  {(Object.keys(errors).length > 0 || error) && (
                    <Paper className={classes.errorPaper}>
                      <Box className={classes.errorContainer}>
                        <NotInterestedIcon className={classes.errorIcon} />
                        <Box display='flex' flexDirection='column' mx={1.5}>
                          <Typography
                            component="h1"
                            variant="caption"
                            className={classes.errorText} >
                             {errors.email ? errors.email : error}
                          </Typography>
                        </Box>
                      </Box>
                    </Paper>
                  )}
                  {/* <Typography
                    variant="subtitle2"
                    className={classes.InputLabel}
                  >
                    Email Address
                  </Typography> */}
                  <Field
                    name="email"
                    label="Email Address"
                    as={TextField}
                    variant="outlined"
                    // margin="dense"
                    className={Boolean(errors.email && touched.email) ? classes.errortextBorder : classes.InputText}
                    fullWidth
                    placeholder="example@email.com"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isSubmitting}
                    color="primary"
                    className={classes.submit}
                  >
                    Reset My Password
                  </Button>
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <span style={{ color: "#9A9AB0", marginRight: 5 }}>
                      Remember password?
                    </span>
                    <Link to="/login" className={classes.linkStyle}>
                      <span className={classes.signUpLink}>Sign in now</span>
                    </Link>
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
        <Grid item xs={false} sm={12} md={6} className={classes.boxImgVisual}>
          <ShapesHerolgt />
        </Grid>
      </Grid>
    </Slide>
  );
};

export default SendMail;
