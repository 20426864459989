import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import Box from "@material-ui/core/Box";

interface Props {
  handleNext: any;
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0",
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "2rem",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: "none",
    fontWeight: 500,
    boxShadow: "none",
    letterSpacing: ".5px",
    "&:hover": {
      background: "#0a6adc",
      boxShadow: "none",
    },
  },
  mainDiv: {
    margin: theme.spacing(4, 4),
    justifyContent: "center",
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  listIcon: {
    minWidth: "5px",
  },
  svg: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f7f7fc",
    justifyContent: "center",
    [theme.breakpoints.between("xs", "lg")]: {
      padding: "0em 2em",
    },
  },
  listDot: {
    color: "#057CFF",
    fontSize: "15px",
  },
  listItem: {
    paddingLeft: "0px",
  },
  listText: {
    paddingLeft: "10px",
  },
  signInLink: {
    textAlign: "center",
  },
  signUpGrid: {
    minHeight: "100vh",
  },
  logo: {
    position: "absolute",
    top: "20px",
    left: "20px",
  },
  formGrid: {
    display: "flex",
    justifyContent: "center",
  },
  InputLabel: {
    marginTop: "0",
    fontWeight: 500,
    marginBottom: ".2rem",
  },
  InputLabelleft: {
    marginTop: ".7rem",
    fontWeight: 400,
    marginBottom: ".2rem",
    color: "#9A9AB0",
  },
  InputText: {
    marginTop: "0",
  },
  loginTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "left",
    width: "100%",
  },
  heading: {
    fontWeight: 600,
    marginBottom: 10,
    fontSize: "1rem",
  },
  text: {
    "fontSize": "1rem"
  }
}));

const expression =
  /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi;

const validationSchema = yup.object({
  firstName: yup
    .string()
    .required("FirstName is a required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  lastName: yup
    .string()
    .required("LastName is a required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  businessName: yup.string().required("Company Name is a required"),
  website: yup
    .string()
    .required()
    .matches(expression, "Enter a Proper Website URL"),
  phone: yup.number(),
});

const Profile: React.FC<Props> = ({ handleNext }) => {
  const [usrName, setUsrName] = useState("");
  const [usrSurName, setUsrSurName] = useState("");
  const [cName, setCName] = useState("");
  const [webURL, setWebURL] = useState("");
  const [phone, setPhone] = useState("");

  const [showProfileDiv, setShowProfileDiv] = useState(false);

  useEffect(() => {
    var axios = require("axios");

    var config = {
      method: "get",
      url: `api/company-profile/${localStorage.getItem("usr_id")}`,
      headers: {},
    };

    axios(config)
      .then(function (response: any) {
        setUsrName(response.data.company_profile[0].usr_name);
        setUsrSurName(response.data.company_profile[0].usr_surname);
        setCName(response.data.company_profile[0].c_name);
        setWebURL(response.data.company_profile[0].website_url);
        setPhone(response.data.company_profile[0].c_contact);
        setShowProfileDiv(true);
      })
      .catch(function (error: any) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.warn(error.response.data.message);
          }
          if (error.response.status === 500) {
            alert("500, Server Error");
            toast.error(error.response.data.message);
          }
        }
        setShowProfileDiv(true);
      });
  }, []);

  const classes = useStyles();
  const onSubmit = (data: any) => {
    var axios = require("axios");
    var sendData = JSON.stringify({
      usr_id: localStorage.getItem("usr_id"),
      usr_name: data.firstName,
      usr_surname: data.lastName,
      c_name: data.businessName,
      website_url: data.website,
      c_contact: data.phone,
    });

    var config = {
      method: "post",
      url: `api/v1/users/update_profile`,
      headers: {},
      data: sendData,
    };

    axios(config)
      .then(function (response: any) {
        localStorage.setItem("usr_company_id", response.data.usr_company_id);
        handleNext();
      })
      .then((response: any) => toast.success(response.data.message))
      .catch(function (error: any) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.warn(error.response.data.message);
          }
          if (error.response.status === 500) {
            toast.error(error.response.data.message);
          }
        }
      });
  };
  return (
    <Grid container component="main">
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <div className={classes.mainDiv}>
          <Box className={classes.loginTextContainer}>
            <Typography component="h1" variant="h5" className={classes.heading}>
              Let’s set up your account
            </Typography>
            <Typography component="h1" variant="caption" className={classes.text}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa.
            </Typography>
          </Box>
          {showProfileDiv === false ? (
            <CircularProgress />
          ) : (
            <Formik
              initialValues={{
                website: webURL,
                businessName: cName,
                lastName: usrSurName,
                firstName: usrName,
                phone: phone,
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ errors }) => (
                <Form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.InputLabel}
                      >
                        First Name
                      </Typography>
                      <Field
                        className={classes.InputText}
                        name="firstName"
                        autoComplete="firstName"
                        as={TextField}
                        type="text"
                        error={!!errors.firstName}
                        helperText={errors.firstName}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        placeholder="Enter Your First Name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.InputLabel}
                      >
                        Last Name
                      </Typography>
                      <Field
                        className={classes.InputText}
                        name="lastName"
                        autoComplete="lastName"
                        as={TextField}
                        type="text"
                        error={!!errors.lastName}
                        helperText={errors.lastName}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        placeholder="Enter Your Last Name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography
                        variant="subtitle2"
                        className={classes.InputLabel}
                      >
                        Company Name
                      </Typography>
                      <Field
                        className={classes.InputText}
                        name="businessName"
                        autoComplete="businessName"
                        as={TextField}
                        type="text"
                        error={!!errors.businessName}
                        helperText={errors.businessName}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        placeholder="User Can Set The Company Name Once"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography
                        variant="subtitle2"
                        className={classes.InputLabel}
                      >
                        Website URL
                      </Typography>
                      <Field
                        className={classes.InputText}
                        name="website"
                        autoComplete="website"
                        as={TextField}
                        type="text"
                        error={!!errors.website}
                        helperText={errors.website}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        placeholder="www.website.com"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div style={{ width: "100%" }}>
                        <Box display="flex">
                          <Box flexGrow={1}>
                            <Typography
                              variant="subtitle2"
                              className={classes.InputLabel}
                              style={{ marginTop: 10 }}
                            >
                              Phone Number
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              className={classes.InputLabelleft}
                            >
                              Optional
                            </Typography>
                          </Box>
                        </Box>
                      </div>

                      <Field
                        className={classes.InputText}
                        name="phone"
                        autoComplete="phone"
                        as={TextField}
                        type="number"
                        error={!!errors.phone}
                        helperText={errors.phone}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        placeholder="Enter Phone Number"
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Continue
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default Profile;
