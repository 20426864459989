import React from "react";
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select} from "@material-ui/core";
import { useStyles } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../../redux/actions/styleGuideActions";
import { DefaultFontStyleList } from "../../utilities/DefaultStyleGuide";
import { updateCustomCssStyleSheet } from "../../../../../../utils/Functions";

interface Props {
  data:any;
}

const StyleItemFormatStyle: React.FC<Props> = ({data}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.styleGuide);  

  // const ITEM_HEIGHT = 48;
  // const ITEM_PADDING_TOP = 8;
  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       maxHeight: ITEM_HEIGHT * 2 + ITEM_PADDING_TOP,
  //       width: 70,
  //     },
  //   },
  // };

  const handleFontStyleClick = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string[];
    if(value.length === 0) return;
    let arr = state?.list_custom_fonts;
    arr?.forEach((item:any) => {      
      // Loop through the styles array within each item
      item?.styles?.forEach((style:any) => {
        if(style?.key === data?.key ){
          style["fontStyle"] = value;    
        }
      });
    });
    dispatch(addField("list_custom_fonts", arr));    
    updateCustomCssStyleSheet(arr);
  }




  return (    
    <Box className={`${classes.styleTypographyWeight}`}>
      <FormControl variant="outlined" className={classes.FontStyleFormControl}>
        <InputLabel id="demo-mutiple-fontStyle-label">Style</InputLabel>
        <Select
          // variant="outlined"
          labelId="demo-mutiple-fontStyle-label"
          id="demo-mutiple-fontStyle"
          multiple
          value={data?.fontStyle}
          onChange={handleFontStyleClick}
          input={<OutlinedInput label="Style" />}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null, // Add this line to position the menu items at the bottom
          }}
        >
          {DefaultFontStyleList?.map((fontStyle:any) => (
            <MenuItem key={fontStyle} value={fontStyle} >
              {fontStyle}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default StyleItemFormatStyle;
