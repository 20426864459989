import React  from "react";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { useStyles } from "../styles";
import TypographyHeadline from './TypographyHeadline';
import TypographyBody from './TypographyBody';
interface Props {}

const TypographyStyle: React.FC<Props> = () => {
  const classes = useStyles();


  return (
        <Box display="flex" flexDirection="column" className={classes.section2}>
          <Typography className={classes.heading} variant="h6"> 
              Typography 
          </Typography>
          <Typography variant="caption" className={classes.typographyText}>
             Set your default fonts, sizes and line-heights.
          </Typography>

          <TypographyHeadline />
          <TypographyBody />
          {/* <TypographyHyperlink /> */}
        </Box>
  );
};

export default TypographyStyle;
