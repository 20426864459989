import React, { useState, useEffect } from "react";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
import FolderTreeList from "./FolderTreeList";
import {useDispatch} from "react-redux";
import {updateActiveTabs} from "../../../../redux/actions/TabManagerActions";

interface Props {
  history: any;
  brandID: any;
  imageFolders: any;
  setImageFolders: any;
}

const ImageFolderTree: React.FC<Props> = ({
  brandID,
  history,
  imageFolders,
  setImageFolders
}) => {
  
  // start needed  
  const [listFolders, setListFolders] = useState<any>([]);  
  const URL = window.location.pathname.split('/').pop();
  const dispatch = useDispatch();
  
  useEffect(() => {
	if(URL ==='TeamDetails'){
		getListFolders();
	}
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [URL]);

  const getListFolders = () => {
  	AxiosInstance.get('api/v1/Contents/getGalleryFolders', {
		params: {
			brandID: brandID,
			full:1
		},
	}).then((response: AxiosResponse) => {
        if (response.data.status === true) {
          setListFolders(response.data.data.folders[0]);
		  dispatch(updateActiveTabs({fieldName: "image_folder_tree", fieldValue: response.data.data.folders[0]}));
        }
      })
  };

  return (
    <>
      <FolderTreeList name="Image" history={history} listFolders={listFolders} selectedFolders={imageFolders} setFolders={setImageFolders}/>
    </>
  );
};

export default ImageFolderTree;
