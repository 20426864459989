import React from "react";
import { useStyles } from "../../../../../../../styles/styles";
import Card from "@material-ui/core/Card";
import SearchBar from "../../components/SearchBar";
import Button from "@material-ui/core/Button";
import {isDesigner, isMarketer,checkmarketostatus,checkmarketostatusafterload} from "../../../../../../../utils/AuthUtility";
import { Menu, MenuItem } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


 interface Props {
querySearch:any;
setQuerySearch:any;
setListNextPage:any;
setTemplatesByComp:any;
   SetOpenModal:any;
   SetMarketoTemplateModal:any;
   getStyleGuideData:any;
   fetchMarketoaccountData:any;
   mktaccountstatus:any;
}

const Sidebarcomponents: React.FC<Props> = ({
querySearch,
setQuerySearch,
setListNextPage,
setTemplatesByComp,
SetOpenModal,
SetMarketoTemplateModal,
getStyleGuideData,
fetchMarketoaccountData,
mktaccountstatus
}) => {
  const classes = useStyles();
  
const getDisabled = () => {
	  if(!isDesigner() && !isMarketer()){
		  return classes.disabledList;
	  }
	  return '``';
  };

  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleClick = (event:any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  /*const handleMarketo = () => {
      getStyleGuideData();
      fetchMarketoaccountData();
      setTimeout(function() {SetMarketoTemplateModal();}, 1000);
      
    setAnchorEl(null);
  };*/
  
  const handleMashrmail = () => {
    SetOpenModal('mashrmailTemplate');
    setAnchorEl(null);
  };
  
  const handleMarketo = () => {
    SetOpenModal('marketoTemplate');
    setAnchorEl(null);
  };
  
  
  return (
  <div>
     {(checkmarketostatus() === true || checkmarketostatusafterload()===true) ? (
         <Card elevation={0} className={getDisabled()} style={{ backgroundColor: 'transparent'}}>
     <Button
      variant="contained"
      color="primary"
      className={classes.PrimarybuttonStyle}
      aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}
      endIcon={<KeyboardArrowDownIcon />}
    >
     Add Template 
    </Button>
    <Menu
      id="simple-menu-add-template"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: -50,
        horizontal: 100,
      }}
    >
      <MenuItem onClick={handleMashrmail} >New MashrMail Template</MenuItem>
      <MenuItem onClick={handleMarketo}>New Marketo Template</MenuItem>
    </Menu>
</Card>
        ) : (
         <Card elevation={0} className={getDisabled()} style={{ backgroundColor: 'transparent'}}>
         <Button
    variant="contained"
    color="primary"
    className={classes.PrimarybuttonStyle}
    onClick={() =>SetOpenModal() }
    >
     Add Template
    </Button>
    </Card>
        )}
   <Card elevation={0} style={{ backgroundColor: 'transparent'}}>
  <SearchBar
    querySearch={querySearch}
    setQuerySearch={setQuerySearch}
    setListNextPage={setListNextPage}
    setTemplatesByComp={setTemplatesByComp}
/>
 </Card>
   </div>

  );
};

export default Sidebarcomponents;

