import React, { useEffect, useState } from "react";
import { makeStyles }  from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { MenuItem, Menu } from "@material-ui/core";
import PopupState, {  bindTrigger, bindMenu } from "material-ui-popup-state";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from  "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {isMarketer, isTrial}  from "../../../../utils/AuthUtility";
import TrialPopup from "../../../../utils/TrialPopup";
import AxiosInstance from "../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
import CreateNewStyle from "./CreateNewStyle";
import { useDispatch} from "react-redux";
import { addPrefilData,removedata } from '../../../redux/actions/styleGuideActions';
import { convertReduxState } from "./convertDbDataToReduxState";
import DeleteTracking from "./DeleteTracking";
import { toast } from "react-toastify";
import SkeletonTrackingList from "./SkeletonTrackingList";

interface Props {
  data: any;
  reloadLists: any;
  history: any;
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
   buttonStyle: {
    textTransform: "none",
    fontWeight: 600,
    boxShadow: "none",
    "&:hover": {
      background: "#0a6adc",
      boxShadow: "none",
    },
  },
  menuButton: { cursor: "pointer" },
  deleteOption: {
    color: "red",
  },
  toolbar: { 
    backgroundColor: "white",
    paddingLeft:5,
    minHeight: "unset",
    // paddingBottom:17,
    paddingRight:0
  },
  avatar: { marginRight: 10 ,width:32 , height:32},
  tableThead:{
    backgroundColor:"#F3F3F3",
    borderRadius:3,
    "& th":{
      fontSize:10,
      fontWeight:"700",
      lineHeight:"unset",
      padding: "12px 0 10px 0"
    },
    "& th:first-child":{
      paddingLeft:20
    }
  },
  outLineButtonContent: {
    border: "1px solid #057cff",
    borderRadius: "4px",
    color: "#367BF5",
    height: "unset",
    lineHeight: "normal",
    fontWeight:"normal",
    "&:hover": {
      color: "#ffffff",
      background: "#367BF5",
    },
  },
  titleh6:{
    fontSize:18
  },
  tableTbodyRow:{
    borderBottom: "1px solid #E1E1E1",
    "& > td" : {
      padding:"10px 0 !important"
    },
    "& > td:first-child" : {
      paddingLeft: "20px !important"
    }
  },
});
  

const TrackingList: React.FC<Props> = ({ data, reloadLists ,history}) => {
    const dispatch = useDispatch();
  const classes = useStyles();
  const [listData, setListData] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [trialPopupStatus, setTrialPopupStatus] = useState<boolean>(false);
  const [isGetListData, setIsGetListData] = useState<boolean>(false);
  const [openTrackingDeleteDialog, setopenTrackingDeleteDialog] = useState<boolean>(false);
  const [TrackingIdDel, setTrackingIdDel] = useState<boolean>(false);
  const [assignTrackingfound, setassignTrackingfound] = useState<any>(null);

const refreshTrackingData = () => {
  	AxiosInstance.get(`api/getTrackingData/${localStorage.getItem("company_id")}`)
      .then((response: AxiosResponse) => {
		  setListData(response.data.usertable);
      });
  };
  /*const level = loggedInUser?.user_detail?.level;*/
  const getTrackingSavedData = async (rowId : any) => {
    //const axios = require("axios");
    try { 
      const request = await AxiosInstance.get(
        `api/getTracking/${localStorage.getItem("company_id")}/${rowId}`
      );
      dispatch( 
        await addPrefilData(
          await convertReduxState(request.data.parameters)
        ) 
      );
      
    } catch (err) {}
  };
 
 const checkTrackingExists = (TrackingId : any) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("TrackingId", TrackingId);
    data.append("company_id",localStorage.getItem("company_id"))

    AxiosInstance.post('api/checkAassignTracking', data)
     .then((response: AxiosResponse) => {
        if (response.data.count > 0) {
            setassignTrackingfound(1);
            setopenTrackingDeleteDialog(true);
        } else {
            setassignTrackingfound(0);
            setopenTrackingDeleteDialog(true);
        }
      })
      
      
  };
  
  
  const editTracking = (rowid: any,defaultid:any) => {
    const rowId= rowid;
    localStorage.removeItem('default_tracking');
    if(defaultid===1)
    {
        localStorage.setItem('default_tracking', 'yes');
    } else {
        localStorage.setItem('default_tracking', 'No');
    }
     dispatch(removedata());
    localStorage.removeItem('TrackingrowId');
    localStorage.setItem('TrackingrowId', rowId);
    getTrackingSavedData(rowId);
    localStorage.removeItem("TrackingResID");
    setTimeout(history.push('/settings/Newtracking'), 5000);
  };
  // eslint-disable-next-line 
   const newstracking = () => {
	   if(!isTrial()){
       dispatch(removedata());
       
        localStorage.removeItem('TrackingrowId');
        localStorage.removeItem("TrackingResID");
        setTimeout(history.push('/settings/Newtracking'), 5000);
	   }else{
		   setTrialPopupStatus(true);
	   }
  };
  
   const handleTrackingDelete = () => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("tracking_id", TrackingIdDel);
    data.append("company_id",localStorage.getItem("company_id"))

    AxiosInstance.post('api/delete-tracking', data)
     .then((response: AxiosResponse) => {
        if (response.data.success === 1) {
            refreshTrackingData();
            setopenTrackingDeleteDialog(false);
        } else
        {
            toast.error(response.data.response_msg);
        }
      })
  };
  
 
  
  
  const deleteTracking = (event: any) => {
    const rowIndex = event.currentTarget.value;
    //setopenTrackingDeleteDialog(true);
    setTrackingIdDel(rowIndex);
    checkTrackingExists(rowIndex);
    
      
  };
  useEffect(() => {
      setListData(data); 
      if(window.location.pathname.split('/').pop() === "tracking") setIsGetListData(true);
  }, [data]);
  
  return (
    <>
	<TrialPopup
	trialPopupStatus={trialPopupStatus}		
	setTrialPopupStatus={setTrialPopupStatus}
	/>
     <DeleteTracking
      assignTrackingfound={assignTrackingfound}
        handleTrackingDelete={handleTrackingDelete}
        open={openTrackingDeleteDialog}
        setOpen={setopenTrackingDeleteDialog}
      />
     {(!isMarketer()) && (
      <Toolbar className={classes.toolbar}>
        {open && (
          <CreateNewStyle
            open={open}
            setOpen={setOpen}
          />
        )}
        {/* <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="h6" id="tableTitle" component="div" className={classes.titleh6}>
          </Typography>
          <Button
           className={classes.buttonStyle}
            variant="contained"
            color="primary"
            onClick={() => {
              newstracking();
            }}
          >
           Add Tracking
          </Button>
        </Box> */}
        
        
      </Toolbar>
       )}
      <TableContainer component={Paper} square >
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.tableThead}>
            <TableRow >
              
              <TableCell align="left">TRACKING</TableCell>
               {(!isMarketer()) && ( <TableCell align="left">ACTIONS</TableCell> )}
            </TableRow>
          </TableHead>
          {isGetListData !== true ? (<SkeletonTrackingList />) : ""}
          <TableBody>
            {listData &&
              listData.map((row: any, index: any) => (
              
                <TableRow key={row.name} className={classes.tableTbodyRow}>
                  
                  <TableCell align="left" width="90%">
                   {row.tracking_name}
				   {row.defaultTracking===1 && (
                      <code style={{ backgroundColor:'#e5e5e5',paddingTop:'2px',paddingRight:'8px',paddingBottom:'2px',paddingLeft:'8px',marginLeft:'5px',borderRadius:'10px'}}>Default</code>
					  )}
					  
                  </TableCell>
                  
                  {(!isMarketer()) && (
                  <TableCell align="left">
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState: any) => (
                        <React.Fragment>
                          <MoreVertIcon
                            className={classes.menuButton}
                            {...bindTrigger(popupState)}
                          />
                          <Menu {...bindMenu(popupState)}>
                            <div onClick={popupState.close}>
                                <MenuItem value={row.id} onClick={() => {
                                editTracking(row.id,row.defaultTracking);
                                }}
								disabled={isTrial()}
								>
                                <Typography>Edit</Typography>
                              </MenuItem>
                            </div>
                            {row.defaultTracking!==1 && (
                            <div
                              onClick={popupState.close}
                              className={classes.deleteOption}
                            > 
                               <MenuItem value={row.id} onClick={deleteTracking} disabled={isTrial()}>
                                <Typography>Delete</Typography>
                              </MenuItem>
                            </div>
                            )}
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                  </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TrackingList;
