// import React from "react";
import React from "react";
import LogoSVG, { SignUpSVG } from "../../../assets/svgs/Svgs";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Paper from "@material-ui/core/Paper";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { Formik, Form, Field } from "formik";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Box, Hidden, IconButton, Slide } from "@material-ui/core";
import { toast } from "react-toastify";
import * as yup from "yup";
import Checkbox from "@material-ui/core/Checkbox";
import NotInterestedIcon from "@material-ui/icons/NotInterested";

interface Props {
  history: any;
}
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0",
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  signUpDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    maxWidth: 450,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  listIcon: {
    minWidth: "5px",
  },
  svg: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f7f7fc",
    // justifyContent: "center",
    // [theme.breakpoints.between("xs", "lg")]: {
    //   padding: "0em 2em",
    // },
  },
  inputList: {
    margin: "20px",
  },
  listDot: {
    color: "#057CFF",
    fontSize: "12px", // 15
  },
  listDotFaded: {
    color: "#9A9AB0",
    fontSize: "12px", //15
  },
  listItem: {
    paddingLeft: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  listText: {
    paddingLeft: "10px",
    "& .MuiTypography-body2": {
      fontSize: "12px",
    },
  },
  listTextColor: {
    color: "#9A9AB0",
    fontWeight: 100,
  },
  signInLink: {
    textAlign: "center",
  },
  formGrid: {
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    position: "absolute",
    top: 25,
    left: 30,
  },
  invalidFeedback: {
    color: "red",
    marginLeft: "14px",
    marginRight: "14px",
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: "left",
  },
  linkStyle: { textDecoration: "none" },
  heading: {
    marginBottom: 10,
    fontFamily: 'Poppins, sans-serif',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "29px",
    lineHeight: "100%",
    letterSpacing: "0.005em",
  },
  signUpText: {
    color: theme.typography.caption.color,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "120%",
    letterSpacing: " 0.005em",
  },
  signUpLink: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "120%",
    letterSpacing: " 0.005em",
    marginLeft: "6px",
  },
  loginTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "left",
    width: "100%",
  },
  InputLabel: {
    marginTop: ".3rem",
    color: "#11142D",
    marginBottom: ".2rem",
    fontFamily: 'Poppins, sans-serif',
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "120%",
  },
  InputText: {
    marginTop: "0",
    "& .MuiOutlinedInput-root": {
      "& input": {
        paddingTop: "12.5px",
        paddingBottom: "12.5px",
      },
      "& input:-webkit-autofill": {
        transition: 'background-color 5000s ease-in-out 0s',
        WebkitBoxShadow: '0 0 0px 1000px white inset'
      },
    },
  },
  checkBoxRoot: {
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    paddingTop: "0",
    paddingLeft: "0",
    paddingRight: ".5rem",
    paddingBottom: "0",
    color: "#057cff",
    right: "2px",
  },
  ButtonDiv: {
    margin: theme.spacing(3, 0, 2),
    textTransform: "none",
    height: "40px",
    borderRadius: "8px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "rgb(5 124 255)",
      boxShadow: "none",
    },
  },
  errortextBorder: {
    marginTop: "0",
    "& .MuiOutlinedInput-root": {
      "& input": {
        paddingTop: "12.5px",
        paddingBottom: "12.5px",
      },
      "& input:-webkit-autofill": {
        transition: 'background-color 5000s ease-in-out 0s',
        WebkitBoxShadow: '0 0 0px 1000px white inset'
      },
      "& fieldset": {
        borderColor: '#FF0000',
        borderWidth:2
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  termsCaption: {
    fontFamily: 'Poppins, sans-serif',
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "130%",
    letterSpacing: "0.008em",
    color: theme.typography.caption.color,
  },
  termLink: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    marginLeft: "5px",
    borderColor: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  errorContainer400: {
    margin: 10,
    display: "flex",
    alignItems: "center",
  },
  errorContainerText400: {
    paddingLeft: "1rem",
  },
  errorIcon: {
    color: "#fff",
    width: "26.67px",
    height: "26.67px",
  },
  errorPaper: {
    marginTop: "-5px",
    marginBottom: "5px",
    width: "100%",
    background: "#EB5757",
    padding: "2px",
  },
  errorText: {
    color: "#fff",
    fontWeight: 500,
    fontFamily: "Montserrat",
    fontSize: "0.90rem",
  },
  link: {
    color: "#FFE8E8",
    textDecoration: "underline",
    cursor: "pointer",
  },
  bulletsize: {
    fontsize: "2px",
  },
  errorContainer: {
    margin: 10,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  eyeBtn: {
    padding: '8px'
  }
}));

const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;
const specialRegex = /(?=.*[@#$%^&!+=])/;
const weburl =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

const validationSchema = yup.object({
  email: yup
    .string()
    .required("Email is missing")
    .email("Must be a Valid Email"),
  password: yup
    .string()
    .matches(lowercaseRegex, "One lowercase characters is missing in password")
    .matches(uppercaseRegex, "One uppercase characters is missing in password")
    .matches(numericRegex, "One number is missing in password")
    .matches(specialRegex, "One special characters is missing in password")
    .min(8, "Minimum 8 characters is missing in password ")
    .required("Password is missing"),
  firstName: yup
    .string()
    .required("First name is missing")
    .max(160, "First name can not be more than 80 Character"),
  lastName: yup
    .string()
    .required("Last name is missing")
    .max(160, "Last name can not be more than 80 Character"),
  businessName: yup.string().required("Business name is missing"),
  websiteUrl: yup
    .string()
    .matches(weburl, "Enter correct url")
    .required("Website is missing"),
  terms: yup.bool().oneOf([true], "You must agree with the terms"),
});

const SignUp: React.FC<Props> = ({ history }) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };
  if (localStorage.getItem("X-Token")) history.push("/campaign");
  const onSubmit = (data: any, { setSubmitting }: any) => {
    setError(null);
    let axios = require("axios");
    let sendData: any = JSON.stringify({
      usr_name: data.firstName,
      usr_surname: data.lastName,
      c_name: data.businessName,
      website_url: data.websiteUrl,
      usr_password: data.password,
      usr_email: data.email,
    });

    const config = {
      method: "post",
      url: `api/v1/users/signup`,
      headers: {},
      data: sendData,
    };

    axios(config)
      .then(function (response: any) {
        localStorage.setItem("usr_check_email_id", data.email);

        history.push({
          pathname: "/checkmail",
          usr_email: data.email,
        });
        // toast.success("User Created");
      })
      .catch(function (error: any) {
        if (error.response) {
          if (error.response.status === 400) {
            // toast.warn(error.response.data.message);
            setError(error.response.data.message);
          }
          if (error.response.status === 500) {
            alert("500, Server Error");
            toast.error(error.response.data.message);
          }
        }
      });
  };

  const [showLowercase, setShowLowercase] = useState(true);
  const [showUppercase, setShowUppercase] = useState(true);
  const [showNumeric, setShowNumeric] = useState(true);
  const [showSpecial, setShowSpecial] = useState(true);
  const [showMin8, setShowMin8] = useState(true);

  function validatePassword(value: any) {
    if (lowercaseRegex.test(value)) {
      setShowLowercase(false);
    }
    if (!lowercaseRegex.test(value)) {
      setShowLowercase(true);
    }
    if (uppercaseRegex.test(value)) {
      setShowUppercase(false);
    }
    if (!uppercaseRegex.test(value)) {
      setShowUppercase(true);
    }
    if (numericRegex.test(value)) {
      setShowNumeric(false);
    }
    if (!numericRegex.test(value)) {
      setShowNumeric(true);
    }
    if (specialRegex.test(value)) {
      setShowSpecial(false);
    }
    if (!specialRegex.test(value)) {
      setShowSpecial(true);
    }
    if (value.length >= 8) {
      setShowMin8(false);
    }
    if (value.length < 8) {
      setShowMin8(true);
    }
  }

  const sequenceError = (errors: {}) => {
    let seqArr: any = [];
    let arr = [
      "firstName",
      "lastName",
      "businessName",
      "websiteUrl",
      "email",
      "terms",
      "password",
    ];
    arr.forEach((item) => {
      if (Object.keys(errors).includes(item)) {
        seqArr.push(item);
      }
    });
    return seqArr;
  };

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Hidden smDown>
          <Grid
            item
            xs={false}
            sm={5}
            md={4}
            className={classes.svg}
            style={{
              paddingLeft: "30px",
              paddingRight: "50px",
            }}
          >
            <SignUpSVG />
          </Grid>
        </Hidden>
        <Box className={classes.logo}>
          <Link to="/">
            <LogoSVG />
          </Link>
        </Box>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          component={Paper}
          className={classes.formGrid}
          elevation={1}
          square
        >
          <div className={classes.signUpDiv}>
            <Box className={classes.loginTextContainer}>
              <Typography
                component="h1"
                variant="h5"
                className={classes.heading}
              >
                Welcome to MashrMail
              </Typography>
            </Box>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                businessName: "",
                websiteUrl: "",
                email: "",
                password: "",
                terms: false,
              }}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={onSubmit}
            >
              {({ touched, values, errors, handleChange, handleBlur }) => (
                <Form className={classes.form}>
                  {(Object.keys(errors).length > 0 || error) && (
                    <Paper className={classes.errorPaper}>
                      <Box className={classes.errorContainer}>
                        <NotInterestedIcon className={classes.errorIcon} />
                        <Box mx={1.5}>
                          {Object.keys(errors).length > 0 ? (
                            (
                              sequenceError(errors) as Array<
                                keyof typeof errors
                              >
                            ).map((item, i) => {
                              return (
                                <Typography
                                  component="h1"
                                  variant="caption"
                                  className={classes.errorText}
                                  key={item}
                                >
                                  . {errors[item]}
                                </Typography>
                              );
                            })
                          ) : (
                            <Typography
                              component="h1"
                              variant="caption"
                              className={classes.errorText}
                            >
                              . {error}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Paper>
                  )}

                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="start"
                  >
                    <Box mr={2}>
                      <Typography
                        variant="subtitle2"
                        className={classes.InputLabel}
                      >
                        First Name
                      </Typography>
                      <Field
                        name="firstName"
                        autoComplete="firstName"
                        as={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        placeholder="Your First Name"
                        className={
                          Boolean(errors.firstName && touched.firstName)
                            ? classes.errortextBorder
                            : classes.InputText
                        }
                      />
                    </Box>
                    <div>
                      <Typography
                        variant="subtitle2"
                        className={classes.InputLabel}
                      >
                        Last Name
                      </Typography>
                      <Field
                        name="lastName"
                        autoComplete="lastName"
                        className={
                          Boolean(errors.businessName && touched.businessName)
                            ? classes.errortextBorder
                            : classes.InputText
                        }
                        as={TextField}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        placeholder="Your Last Name"
                      />
                    </div>
                  </Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.InputLabel}
                  >
                    Business Name
                  </Typography>
                  <Field
                    name="businessName"
                    autoComplete="businessName"
                    as={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    className={
                      Boolean(errors.businessName && touched.businessName)
                        ? classes.errortextBorder
                        : classes.InputText
                    }
                    fullWidth
                    placeholder="Business Name"
                  />
                  <Typography
                    variant="subtitle2"
                    className={classes.InputLabel}
                  >
                    Website Url
                  </Typography>
                  <Field
                    name="websiteUrl"
                    autoComplete="websiteUrl"
                    as={TextField}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    className={
                      Boolean(errors.websiteUrl && touched.websiteUrl)
                        ? classes.errortextBorder
                        : classes.InputText
                    }
                    fullWidth
                    placeholder="www.example.com"
                  />
                  <Typography
                    variant="subtitle2"
                    className={classes.InputLabel}
                  >
                    Email Address
                  </Typography>
                  <Field
                    name="email"
                    autoComplete="email"
                    as={TextField}
                    type="email"
                    variant="outlined"
                    margin="normal"
                    className={
                      Boolean(errors.email && touched.email)
                        ? classes.errortextBorder
                        : classes.InputText
                    }
                    fullWidth
                    placeholder="example@email.com"
                  />
                  <Typography
                    variant="subtitle2"
                    className={classes.InputLabel}
                  >
                    Password
                  </Typography>
                  <Field
                    as={TextField}
                    variant="outlined"
                    autoComplete="password"
                    margin="normal"
                    className={
                      Boolean(errors.password && touched.password)
                        ? classes.errortextBorder
                        : classes.InputText
                    }
                    value={values.password}
                    onChange={(e: any) => {
                      setError(null);
                      handleChange(e);
                      validatePassword(e.currentTarget.value);
                    }}
                    onBlur={handleBlur}
                    // validate={validatePassword}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={handleClick} edge="end" className={classes.eyeBtn}>
                          {showPassword ? (
                            <VisibilityOutlinedIcon
                              style={{ color: "#DADADA" }}
                            />
                          ) : (
                            <VisibilityOffOutlinedIcon
                              style={{ color: "#DADADA" }}
                            />
                          )}
                        </IconButton>
                      ),
                    }}
                    fullWidth
                    name="password"
                    // label="Password"
                    type={showPassword ? "text" : "password"}
                  />
                  <List dense>
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listIcon}>
                            {showLowercase === false ? (
                              <FiberManualRecordIcon
                                className={classes.listDotFaded}
                              />
                            ) : (
                              <FiberManualRecordIcon
                                className={classes.listDot}
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText className={classes.listText}>
                            {showLowercase === false ? (
                              <span className={classes.listTextColor}>
                                One lowercase character
                              </span>
                            ) : (
                              <span>One lowercase character</span>
                            )}
                          </ListItemText>
                        </ListItem>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listIcon}>
                            {showSpecial === false ? (
                              <FiberManualRecordIcon
                                className={classes.listDotFaded}
                              />
                            ) : (
                              <FiberManualRecordIcon
                                className={classes.listDot}
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText className={classes.listText}>
                            {showSpecial === false ? (
                              <span className={classes.listTextColor}>
                                One special character
                              </span>
                            ) : (
                              <span>One special character</span>
                            )}
                          </ListItemText>
                        </ListItem>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listIcon}>
                            {showUppercase === false ? (
                              <FiberManualRecordIcon
                                className={classes.listDotFaded}
                              />
                            ) : (
                              <FiberManualRecordIcon
                                className={classes.listDot}
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText className={classes.listText}>
                            {showUppercase === false ? (
                              <span className={classes.listTextColor}>
                                One uppercase character
                              </span>
                            ) : (
                              <span>One uppercase character</span>
                            )}
                          </ListItemText>
                        </ListItem>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listIcon}>
                            {showMin8 === false ? (
                              <FiberManualRecordIcon
                                className={classes.listDotFaded}
                              />
                            ) : (
                              <FiberManualRecordIcon
                                className={classes.listDot}
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText className={classes.listText}>
                            {showMin8 === false ? (
                              <span className={classes.listTextColor}>
                                8 characters minimum
                              </span>
                            ) : (
                              <span>8 characters minimum</span>
                            )}
                          </ListItemText>
                        </ListItem>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ListItem className={classes.listItem}>
                          <ListItemIcon className={classes.listIcon}>
                            {showNumeric === false ? (
                              <FiberManualRecordIcon
                                className={classes.listDotFaded}
                              />
                            ) : (
                              <FiberManualRecordIcon
                                className={classes.listDot}
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText className={classes.listText}>
                            {showNumeric === false ? (
                              <span className={classes.listTextColor}>
                                One number
                              </span>
                            ) : (
                              <span>One number</span>
                            )}
                          </ListItemText>
                        </ListItem>
                      </Grid>
                    </Grid>
                  </List>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="start"
                  >
                    <Field
                      as={Checkbox}
                      color="primary"
                      disableRipple
                      className={classes.checkBoxRoot}
                      inputProps={{ "aria-label": "decorative checkbox" }}
                      name="terms"
                    />
                    <Typography
                      component="p"
                      className={classes.termsCaption}
                      align="left"
                      variant="caption"
                    >
                      I have read and agree to the
                      <Link to="#" className={classes.linkStyle}>
                        <span className={classes.termLink}>
                          Terms of Service
                        </span>
                      </Link>
                    </Typography>
                  </Box>
                  <Button
                    type="submit"
                    fullWidth
                    onClick={() => window.scrollTo(0, 0)}
                    variant="contained"
                    color="primary"
                    className={classes.ButtonDiv}
                  >
                    Sign up
                  </Button>
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <span className={classes.signUpText}>
                      Doesn't have an account?
                    </span>
                    <Link to="/login" className={classes.linkStyle}>
                      <span className={classes.signUpLink}>Sign in now</span>
                    </Link>
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
      </Grid>
    </Slide>
  );
};
export default SignUp;
