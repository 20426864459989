import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: "20px",
    marginTop: "1rem",
    fontStyle: "normal",
    fontFamily: 'Poppins, sans-serif',
    lineHeight: "120%",
    fontWeight: 500,
    letterSpacing: "1.2px",
    fontSize: "26px",
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.2em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      "-webkit-border-radius": "10px",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#C4C4C4",
      "-webkit-border-radius": "10px",
    },
  },
  root: {
    width: "100%",
    display: "flex",
    paddingTop: "5rem",
    justifyContent: "center",
    background: " #f8f8f8",
  },
  selectStyle: {
    width: "20px",
    color: "white",
    background: "white",
    "&:focus": {
      backgroundColor: "white",
    },
  },
  backgroundupgrade: {
    marginTop: "3.5rem",
    display: "flex",
    justifyContent: "center",
    padding: "0.3rem",
    background: "#444444",
  },
  freeTrail: {
    color: "#FFFFFF",
    padding: "5px",
    paddingRight: "20px",
  },
  menu: {
    paddingLeft: "31px",
    paddingRight: "31px",
    paddingTop: "8px",
    paddingBottom: "8px",
    fontSize: "14px",
    fontFamily: 'Poppins, sans-serif',
  },
  paper: {
    width: "152px",
  },
  container: {
    maxWidth: "1250px",
  },
  invoiceRoot: {
    border: "1px solid #E2E5E6",
    padding: "20px",
    flexGrow: 1,
    background: "#FFFFFF",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    borderBottom: "0px",
  },
  chartRoot: {
    border: "1px solid #E2E5E6",
    padding: "20px",
    flexGrow: 1,
    background: "#FFFFFF",
    borderTop: "0px",
    borderBottomRightRadius: "4px",
    borderBottomLeftRadius: "4px",
    minHeight: "325px",
  },
  table: {
    minWidth: 500,
  },
  invtable: {
    borderRadius: "10px",
    maxHeight: "300px",
  },
  invtablehead: {
    backgroundColor: "#F9FAFB",
  },
  invicongreen: {
    color: "#00C85C",
  },
  inviconred: {
    color: "#F20000",
  },
  inviconbtn: {
    border: "#D1D5DB solid 1px",
  },
  cardRoot: {
    flexGrow: 1,
    padding: "20px",
    maxWidth: "700px",
    maxHeight: "90%",
    border: "none",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  viewpdf: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  buttonStyle: {
    background: "none",
    border: "#367BF5 1px solid",
    color: "#367BF5",
    textTransform: "none",
    "&:hover": {
      background: "#367BF5",
      color: "#ffffff",
    },
  },
  CancelButtonStyle: {
    background: "none",
    border: "#969696 1px solid",
    color: "#676767",
    textTransform: "none",
    "&:hover": {
      background: "#969696",
      color: "#ffffff",
    },
  },
  cellText: {
    fontWeight: "bold",
  },
}));
