import React from "react";
// import { Box, MenuItem, TextField } from "@material-ui/core";
// import { useStyles } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../redux/actions/styleGuideActions";
// import {AutoSave} from "../StyleGuideAutosave";
import { DefaultStyleGuide } from '../utilities/DefaultStyleGuide';
// import FontSizeLineHeight from './Typography/FontSizeLineHeight';
// import CustomFontSelection from "./Typography/CustomFontSelection";
import EditorStyle from "./Typography/EditorStyle";

interface Props {}

 
const TypographyBody: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.styleGuide);
  // const classes = useStyles();
  //const [addFallBack, SetAddFallBack] = useState(false);


  if(!state["text_size"] && state["text_size"] !== undefined){
    dispatch(addField("text_size", DefaultStyleGuide["text_size"]));
    dispatch(addField("styleGuideDefault", true));
  }

  if(!state["text_font"] && state["text_font"] !== undefined){
    dispatch(addField("text_font", DefaultStyleGuide["text_font"]));
    dispatch(addField("styleGuideDefault", true));
  }


  return (
  <>    
    <EditorStyle name="text" code="body1" />
    <EditorStyle name="text" code="body2" />
    <EditorStyle name="text" code="legal" />
    {/*<Box display="flex" flexDirection="column" className={classes.section3} >
    {(state?.custom_font_visible === true) && (<Box display="flex" className={classes.styleList3} >
        <CustomFontSelection title="Body" fieldName="body_custom_font" />
      </Box>)}
      <Box display="flex" className={(!state?.custom_font_visible) ? (classes.paddingTop15) : ""}>
      <Box className={`${classes.flexColumn1} ${classes.TitleDescription3}`}>         
          Body{(state?.custom_font_visible) ? " Fallback" : ""} Font:
        </Box>  
        <Box flex="1">
          <Box >  
            <TextField
              select
              size="small"
              // className={classes.fontCombo}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              }}
              placeholder="Select your primary font"
              value={state["text_font"] || "none"}
              fullWidth
              onChange={(event) => {
                dispatch(addField("text_font", event.target.value));
                state?.fontsList?.forEach((option:any) => {
                    if(option.name === event.target.value){  
                      dispatch(addField("text_font_full", option.family));
                    }
                });
                AutoSave();
              }}
            >
              {!state["text_font"] && (
                <MenuItem value="none" disabled style={{ color: "#545454" }}>
                  Select your primary font
                </MenuItem>
              )}
              {state.fontsList && state.fontsList.map((option:any) => (
                <MenuItem key={option.name} value={option.name}>
                <span style={{ fontFamily: option.name }}> {option.name === "Trebuchet MS" ? "Trebuchet" : option.name} </span>
                </MenuItem>
              ))}
            </TextField>
            START TO HIDE
             {!addFallBack ? (
                <Box onClick={()=>SetAddFallBack(true)} className={classes.fallbackText}>
                  Add fallback
                </Box>
            ) : (  
              <TextField
                select
                className={classes.fontFallbackFamilyDrop}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  },
                }}
                placeholder="Select your fallback font"
                size="small"
                value= {state.fallback_font.body || "none"}
                fullWidth
                onChange={(e)=>{               
                  setFallBack({...state.fallback_font, body: e.target.value}); 
                }}
              >
                {!state.fallback_font?.body && (
                  <MenuItem value="none" disabled style={{ color: "#545454" }}>
                    Select your fallback font
                  </MenuItem>
                )}
                {state.fontsList && state.fontsList.map((option:any) => (
                  <MenuItem key={option.name} value={option.name}>
                  <span style={{ fontFamily: option.name }}> {option.name}</span>
                  </MenuItem>
                ))} 
              </TextField>
            )}
            END TO HIDE
          </Box> 
        </Box>
      </Box>         
    </Box>*/}
    {/* <FontSizeLineHeight title="Body 1:" fieldNameFontSize="text_size_body1" fieldNameLineHeight="text_lineheight_body1" fieldNameSpaceAfter="text_spaceafter_body1" fieldNameBold="text_weight_body1" />
    <FontSizeLineHeight title="Body 2:" fieldNameFontSize="text_size_body2" fieldNameLineHeight="text_lineheight_body2" fieldNameSpaceAfter="text_spaceafter_body2" fieldNameBold="text_weight_body2" />
    <FontSizeLineHeight title="Legal:" fieldNameFontSize="text_size_legal" fieldNameLineHeight="text_lineheight_legal" fieldNameSpaceAfter="text_spaceafter_legal" fieldNameBold="text_weight_legal" /> */}
  </>
  );
};

export default TypographyBody;
// const textSizes = [
//   {
//     value: 13,
//     label: "13",
//   },
//   {
//     value: 14,
//     label: "14",
//   },
//   {
//     value: 15,
//     label: "15",
//   }
// ];
/*const fonts = [
  { title: "Arial" },
  { title: "Verdana" },
  { title: "Helvetica" },
  { title: "Trebuchet MS" },
  { title: "Times New Roman" },
  { title: "Georgia" },
  { title: "Garamond" },
  { title: "Courier New" },
  { title: "Brush Script MT" },
];*/