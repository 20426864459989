import React, { useEffect } from "react";
import {
	BrowserRouter as Router,
	Route,
	Switch, BrowserRouter,
} from "react-router-dom";
import Login from "./app/views/auth/login/Login";
import ResendActivation from "./app/views/auth/accountActivation/resendActivation/ResendActivation";
import SignUp from "./app/views/auth/signup/SignUp";
import CheckYourMail from "./app/views/auth/accountActivation/checkYourMail/CheckYourMail";
import Onboarding from "./app/views/onboarding/IndexOnboarding";
import ActivateAccount from "./app/views/auth/accountActivation/activateAccount/ActivateAccount";
import SendMail from "./app/views/auth/forgetPassword/sendToMail/SendToMail";
import CheckYourMailForget from "./app/views/auth/forgetPassword/checkYourMail/CheckYourMail";
import SetNewPassword from "./app/views/auth/forgetPassword/setNewPassword/SetNewPassword";
import RedirectSetPassword from "./app/views/auth/forgetPassword/redirectSetPassword/RedirectSetPassword";
import ChoosePlan from "./app/views/Landing/settings/billing/ChoosePlan";
import TabsPanel from "./app/views/Landing/Tabs";
import Logout from "./app/views/auth/logout/Logout";
import {isLocalHost, isLoginRequired, redirectToLogin} from "./utils/AuthUtility";
import ContentIndex from "./app/views/Landing/content/Index";
import ScreenShoot from "./app/views/screen/ScreenShoot";
import ExportHtml from "./app/views/Landing/ExportHtml/ExportHtml";
import MailjetExport from "./app/views/onboarding/espIntegrations/components/MailjetExport";
import BrazeExport from "./app/views/onboarding/espIntegrations/components/BrazeExport";
import CmExport from "./app/views/onboarding/espIntegrations/components/CampaignMonitorExport";


const Routes: React.FC = () => {
	const [initAction, setinitAction] = React.useState<any>(true);	
	const [basename, setBaseName] = React.useState<string>(window.globalConfig.subDir);

    /*const validateAuth = (redirect:any) => {
    	AxiosInstance
			.get('api/v1/users/checkloggedin', {
				params: {},
			}).then((response: AxiosResponse) => {
				if(response.data.success===1){
					setinitAction(false);
				}else{
					if(redirect===1){
						localStorage.clear();
						setinitAction(false);
						
					}else{
						setinitAction(true);
						redirectToLogin();
					}
				}
			})
    };*/
	useEffect(() => {
		var cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)app_logged_in\s*=\s*([^;]*).*$)|^.*$/, "$1");
		
		if(isLocalHost()===false && isLoginRequired()===true){
			//validateAuth('');
			if(!cookieValue){
				setinitAction(true);
				redirectToLogin();
			}else{
				setinitAction(false);
			}
		}else{
			if(isLocalHost()===false){
				//validateAuth(1);
				if(!cookieValue){
                    let getUsers: any = {};
                   getUsers  =  JSON.parse(localStorage.getItem('registerusersondevice') || '{}');
                   localStorage.clear();
                   localStorage.setItem('registerusersondevice', JSON.stringify(getUsers));
                   
            }
				setinitAction(false);
			}else{
				setinitAction(false);
			}
		}
	}, []);

	useEffect(() => {
		if (basename !== window.globalConfig.subDir) {
			setBaseName(window.globalConfig.subDir);
		}
	}, [basename]);

  return (
  	<BrowserRouter basename={`/${basename}`}>
	{initAction===false ? (
		<Router basename={`/${basename}`}>
		  <Switch>
			<Route path="/login" exact component={Login} />
            <Route path="/login/otp" exact component={Login} />
			<Route
			  path="/activate-account/:userToken"
			  exact
			  component={ActivateAccount}
			/>
			<Route path="/signup" component={SignUp} />
			<Route path="/resend-activation" component={ResendActivation} />
			<Route path="/checkmail" component={CheckYourMail} />
			<Route path="/onboarding" component={Onboarding} />
			<Route path="/sendmail-forget" component={SendMail} />
			<Route path="/checkmail-forget" component={CheckYourMailForget} />
			<Route path="/choose-plan" component={ChoosePlan} />
			<Route path="/set-new-password" component={SetNewPassword} />
			<Route path="/logout" component={Logout} />
			<Route path="/preview/:screenType?/:screenID?" component={ScreenShoot}  />
			<Route
			  path="/redirect-reset-password/:userToken"
			  exact
			  component={RedirectSetPassword}
			/>
			<Route path="/gallery">
				<ContentIndex isHeadless={true} enableOpenAI={false} enableTemplate={false} onSelect={(path: string) => {
					window.parent.postMessage('selectedGalleryImage=>' + path, '*');
				}}/>
			</Route>
            
            <Route path="/openai">
				<ContentIndex isHeadless={false} enableOpenAI={true} enableGallery={false} enableTemplate={false} onTextBoxSelect={(path: string) => {
					window.parent.postMessage('customtext=>' + path, '*');
				}}/>
			</Route>
			
			<Route path="/exportHtml">
				<ExportHtml   controller={''} EmailProviders={''} emailId={false} handleClose={false} onTextBoxSelect={(path: string) => {
					window.parent.postMessage('ExportText=>' + path, '*');
				}}/>
			</Route>
            <Route path="/exportHtmlIframe/:controller?">
				<ExportHtml controller={''} EmailProviders={''} emailId={false} isIframe={true} handleClose={false} onTextBoxSelect={(path: string) => {
					window.parent.postMessage('ExportText=>' + path, '*');
				}}/>
			</Route>
            
            <Route path="/exportMailjet/:selectedEspId?/:versionId?/:cv_name?/:email_subject_value?">
               <MailjetExport FrontEndSubject={''} isIframe={true} handleClose={false} FrontEndcname={''} FrontendversionId={''}  FrontEndEspId={''} mailjetsenderlist={''}
                 mailjetcontactlist={''} onTextBoxSelect={(path: string) => {
					window.parent.postMessage('ExportEsp=>' + path, '*');
				}}/>
			</Route>

            <Route path="/exportBraze/:selectedEspId?/:versionId?/:cv_name?/:email_subject_value?/:preview?">
               <BrazeExport FrontEndSubject={''} FrontEndProfile={''} isIframe={true} handleClose={false} FrontEndcname={''} FrontendversionId={''}  FrontEndEspId={''} 
                  onTextBoxSelect={(path: string) => {
					window.parent.postMessage('ExportEsp=>' + path, '*');
				}}/>
			</Route>
            
            <Route path="/exportCampaignMonitor/:selectedEspId?/:versionId?/:cv_name?/:email_subject_value?">
               <CmExport FrontEndSubject={''} isIframe={true} handleClose={false} FrontEndcname={''} FrontendversionId={''}  FrontEndEspId={''} 
                    campaignMonitorlist={''} 
                    campaignMonitortimezone={''}
                    campaignMonitorTemplates={''} onTextBoxSelect={(path: string) => {
					window.parent.postMessage('ExportEsp=>' + path, '*');
				}}/>
			</Route>
            
            
			<Route path="/:activeParent?/:activeChild?" component={TabsPanel} />
			{/* <Route path="" component={NotFoundPage} /> */}
		  </Switch>
		</Router>
		):('')}
	</BrowserRouter>
  );
};

export default Routes;
