import React from "react";
import { useStyles } from "../styles";
import Button from "@material-ui/core/Button";
import {isCopywriter} from "../../../../../../utils/AuthUtility";
interface Props {
  handleClickNewCampaign: any;
}

const Header: React.FC<Props> = ({ handleClickNewCampaign }) => {
  const classes = useStyles();

  return (
    <>
      {(!isCopywriter()) && (
        <Button
            variant="contained"
            color="primary"
            onClick={handleClickNewCampaign}
            className={classes.buttonStyle}
          >
            Add Email
          </Button>
        )}
    </>
  );
};

export default Header;
