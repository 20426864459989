import React from "react";
import { useStyles } from "./style";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Button, Typography, TextField, CircularProgress } from "@material-ui/core";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
import { ArrowForward } from "@material-ui/icons";

interface Props {
  history?: any;
  imageFolderID: string;
  setImageFolderID: any;
  createImage: any;
  onTextBoxSelect?: any;
  isHeadless: boolean;
}

const Gpt: React.FC<Props> = ({
  history,
  imageFolderID,
  setImageFolderID,
  createImage,
  onTextBoxSelect,
  isHeadless,
}) => {
  const classes = useStyles();
  const [count, setCount] = React.useState(0);
  const [disabled, setdisabled] = React.useState(false);
  const [error, seterror] = React.useState(false);
  const [helperText, sethelperText] = React.useState<string>("");
  const [Prompt, setPrompt] = React.useState<string>("");
  const [GeneratedContent, setGeneratedContent] = React.useState<string>("");
  const [InputContent, setInputContent] = React.useState<string>("");
  const [isLoading, setisLoading] = React.useState(false);
  const [color, setcolor] = React.useState<string>("");
  //const loggedInUser = getUserFromToken();
  //const URL = window.location.pathname.split('/').pop();
  
  const setCountFun = async (e:any) => {
      setInputContent(e.target.value)
     setCount(e.target.value.length);
     setPrompt(e.target.value);
     if(e.target.value.length>=257)
     {
         setdisabled(true);
         setcolor('red');
     } else {
         setdisabled(false);
         setcolor('');
     }
  };
  
  const pasteContent = async (content:any) => {
     setGeneratedContent(content);
     setisLoading(false);
  };
  
  
  const insertContent = () => {
      if(GeneratedContent!=='')
      {
		onTextBoxSelect(GeneratedContent.replace(/^\s+|\s+$/gm,''));
        setInputContent('');
        setGeneratedContent('');
        setisLoading(false);
        seterror(false);
        sethelperText('');
        
      } else {
        seterror(true);
        sethelperText('Required Field.');
      }
  };
  const closeContent = () => {
		onTextBoxSelect('close')
  };
  const generateContent = () => {
      if(InputContent!=='')
      {
      setisLoading(true);
      seterror(false);
      sethelperText('');
		const FormData = require("form-data");
		let data = new FormData();
		data.append("Prompt", Prompt);
			AxiosInstance.post('api/v1/settings/generate_content', data)
				.then((response: AxiosResponse) => {
					if(response.data.data.content!=='')
                    {
                        pasteContent(response.data.data.content);
                    }
				});
  } else {
      seterror(true);
      sethelperText('Required Field.');
  }
	};
    
   const textChanged = (event:any) => {
        setGeneratedContent(event.target.value)
    }
 
  return (
    <Box className={classes.templateHeader}>
      <Box className={classes.gptHeaderText}>
        <Typography variant="h5">
          Content Creator
        </Typography>
      </Box>
    
      <Box className={classes.gptBodyText}>
        <Typography variant="body2">
          Enter your text prompt below to generate a unique piece of content for you email.
        </Typography>
      </Box>
      <Grid container className={classes.gridTextAreaContainer}>
        <Grid className={classes.gridTextAreaWrapper}>
          <Box className={classes.gridContentArea}>
            <Box className={classes.textAreaFirstContainer}>              
              <TextField
              error={error}
                 helperText={helperText}
                id="outlined-multiline-static"
                label="Input"
                multiline
                rows={4}
                value={InputContent}
                variant="outlined"
                className={classes.textFieldMultiLine}
                onChange={e => setCountFun(e)} 
              />
              {/* <TextareaAutosize className={classes.textareaAutosize} style={{ overflowY: "scroll"}} onChange={e => setCountFun(e)} /> */}
              <Box className={classes.textAreaCount}>
                 <Typography variant="body2" style={{textAlign:"right",color: color}}>
                {count}/256
                </Typography>
              </Box>
              {/* <b style={{ float: "left",marginRight:'20px' }}>{count}/256</b> */}
            </Box>
                            
            <Box className={classes.textAreaButtonBetween}>
              <Button
                variant="outlined"
                disabled={disabled || isLoading}
                size="small"
                color="primary"
                endIcon={isLoading ? <CircularProgress style={{ width:18,height:18, color: "rgba(0, 0, 0, 0.26)"}} /> : <ArrowForward />}
                onClick={() => {
                  generateContent();
                }}
              >
                GENERATE
              </Button>
              <Box className={classes.textAreaCount}>
                <Typography variant="body2">
                  &nbsp;
                </Typography>
              </Box>
            </Box>

            <Box className={classes.textAreaSecondContainer}>
              <TextField
                id="outlined-multiline-static1"
                label="Output"
                error={error}
                 helperText={helperText}
                multiline
                rows={4}
                variant="outlined"
                className={`${classes.textFieldMultiLine} ${classes.textFieldMultiLineOutput}`}
                onChange={textChanged} 
                value={GeneratedContent}
              />
             
              {/* <TextareaAutosize aria-label="Input" className={classes.textareaAutosize} style={{ overflowY: "scroll"}} onChange={textChanged} value={GeneratedContent}  id="dialogTextArea"/> */}
              <Box className={classes.textAreaCount}>
                <Typography variant="body2">
                  &nbsp;
                </Typography>
              </Box>
            </Box>            
          </Box>        
        </Grid>
      </Grid>
      <Box className={classes.gptFooter}>
        <Button onClick={() => {
            closeContent();
          }} size="large" style={{opacity:"0.6",marginRight: 16}}>
          CANCEL
        </Button>
        <Button
          variant="contained"
          size="large" 
          color="primary"
          className={classes.insertbut}
          onClick={() => {
            insertContent();
          }}
        >
          INSERT
        </Button>
      </Box>
    </Box>
  );
};

export default Gpt;
