import {ADD_FIELD,REMOVE_DATA, ADD_LOGO, ADD_PREFIL_DATA, REMOVE_LOGO} from '../enums/Enums'


export const addLogo=(logo:any)=>({
        payload:logo,
        type:ADD_LOGO
    })
export const removeLogo=()=>({
        payload:null,
        type:REMOVE_LOGO
    })
export const addField=(fieldName:string | undefined,value:string | undefined | any)=>{

    return {type:ADD_FIELD,payload:{fieldName,value}}
}


export const addPrefilData=(data:any)=>{

    return {type:ADD_PREFIL_DATA,payload:data}
}
export const removedata=()=>{

    return {type:REMOVE_DATA}
}
