import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.5em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      "-webkit-border-radius": "10px",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#C4C4C4",
      "-webkit-border-radius": "10px",
    },
    "input[type=number]::-webkit-inner-spin-button":{
      "-webkit-appearance": "auto",
      opacity:1
    },
    "input[type=number]::-webkit-outer-spin-button": { 
      "-webkit-appearance": "auto",
      opacity:1
    }
  },
  container: {
    width: "100%",
    height: "100%",
    border: "1px solid #D3D3D3",
    borderRadius: "14px",
    // padding: '0px 0px 0px 30px',
    // borderRadius: "12px",
  },
  dragDropMenuConatiner:{
    "& .MuiOutlinedInput-input":{
      padding:"10.5px 14px"
    }
  },
  dragDropSocialsConatiner:{
    "& .MuiOutlinedInput-input":{
      padding:"10.5px 14px"
    }
  },
  logoSlider:{
    display: "flex",
    alignItems: "flex-end",
  },
  sliderContainer:{
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  sliderWrapper:{
    width: "60%",
    display: "flex",
    justifyContent: "center",
  },
  slider:{
    flex: 1
  },
  sliderValue:{
    display: "flex",
    alignItems: "center",
    paddingBottom: 3,
    marginLeft: 10,
    color: "#646464",
    fontSize: 14,
    fontFamily:"Milush",
    fontWeight: 400
  },
  caption: {
    marginBottom: "25px",
    fontSize: "14px",
    color: "#646464",
    lineHeight: "18px",
  },
  captionDetails:{    
    marginBottom: "25px",
    fontSize: "14px",
    color: "#646464",
    lineHeight: "13px",
  },
  typographyText: {
    marginBottom: "17px",
    fontSize: "14px",
    color: "#646464",
    lineHeight: "18px",
  },
  styleList:{
    paddingTop:15,
    width:"93%",
  },
  styleList1:{
    width:"93%",
  },
  fontFamilywithFallback:{
    display:"flex",
    flexDirection: "column",
    paddingLeft: 20,
    paddingRight: 10,
    flex:1
  },
  fontFallbackFamilyDrop:{
    paddingTop:20
  },
  fallbackText:{
    color: "#2b7ff6",
    textDecoration: "underline",
    textAlign: "right",
    fontSize: 12,
    paddingTop:8
  },
  heading: {
    marginBottom: theme.spacing(1),
    color: "#2F2F2F",
    fontSize: "18px",
    lineHeight: "23px",
    fontStyle: "normal",
    fontWeight: "bold",
  },
  topHeading: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "38px",
    lineHeight: "39px",
    marginBottom: 20,
    color: "#11142D",
  },

  logoCrossButton: {
    position: "absolute",
    border: "1px solid #D3D3D3",
    padding: 1,
    right: -33,
    top: -0,
  },
  InputLabel: {
    marginTop: "-.5rem",
    fontWeight: 600,
    // marginLeft: "5px",
    // marginBottom: ".2rem",
  },
  InputLabel1: {
    fontWeight: 600,
    fontSize: 12
  },
  textfield: {
    width: "15rem",
  },
  fontCombo: {
    width: "40%",
    marginBottom: "1rem",
    // margintTop:'10px'
  },
  footerInfoTextField: {
    width: 216,
    borderRadius: 7,
    marginBottom: "-1px",
  },
  "MuiInputBase-input MuiOutlinedInput-input": {
    borderRadius: 70,
  },
  section: {
    marginBottom: "2rem",
  },
  section1: {
    marginBottom: "22px",
  },
  section2:{
    marginBottom: "2rem",
    "& > .MuiBox-root":{
      paddingLeft:5
    }
  },
  section3: {
    marginBottom: 10,
    paddingLeft:0
  },
  section4: {
    marginBottom: 20,
    paddingLeft:0
  },
  hyperlinkSection:{
    paddingTop:30
  },
  iconButton: {
    width: "fit-content",
    border: "1px solid #CDCDCD",
    margin: 10,
  },
  iconButtonLabel: {
    color: "#11142D",
    fontWeight: 400,
    fontSize:11
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  flexColumn1:{    
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    fontFamily: 'Mulish',
    height:40
  },
  TitleDescription1:{
    width:"45%"
  },
  TitleDescription2:{
    width:"45%",
    display: "flex",
    alignItems: "center",
  },
  componentLeftContainer: {
    display: "flex",
    overflow: "auto",
    maxHeight: "70vh",
    flexDirection: "column",
    paddingTop: "30px",
    marginTop: "10px",
    marginLeft: "40px",
    paddingRight: 48
  },
  componentRight:{
    maxWidth: "unset",
    width: "58%",
    flexBasis: "unset",
    flexGrow: "unset",    
    // paddingLeft: 20,
    // paddingRight: 20
  },
  componentRightContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    maxHeight: "80vh",
    alignItems: "center",
  },
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    "& .MuiPaper-root":{
      width:"100%"
    }
  },
  textFieldBody: {
    // width:'7rem',
    width: "103px",
    // margin: "0.3rem 1rem .3rem .3rem",
    "& .MuiOutlinedInput-input":{
      padding:"10.5px 14px"
    }
  },
  ContainerFontSize:{
    flex:1,
    marginRight:5
  },
  ContainerLineHeight:{
    flex:1
  },
  StyleFontSize: {
    width: "100%",
    "& .MuiOutlinedInput-input":{
      padding:"10.5px 14px"
    }
  },
  StyleLineHeight: {
    width: "100%",
    "& .MuiOutlinedInput-input":{
      padding:"10.5px 14px"
    }
  },
  inputNumber:{
    "& .MuiOutlinedInput-input":{
      padding: "11px 12px"
    },
  },
  containerPadding:{
    flex:1
  },
  containerPaddingRow:{
    display: "flex",
  },
  textSizeSelect: {
    width: "8rem",
  },
  footerGrid: {
    width: "470px",
  },
  subTitle: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  noRadius: {
    borderRadius: "0%",
    backgroundColor: "#C4C4C4",
    "&:hover": {
      backgroundColor: "rgba(5, 124, 255, 0.15)",
    },
  },
  normalRadius: {
    backgroundColor: "#C4C4C4",
    "&:hover": {
      backgroundColor: "rgba(5, 124, 255, 0.15)",
    },
  },
  headerInfo:{
    display: "flex",
    padding: "19px 20px 19px 31px",
    alignItems: "center",
    borderBottom: "1px solid #c4c4c4",
    fontFamily: 'Mulish'
  },
  headerInfoFirst:{
    flex:"1",
    display: "flex",
    alignItems: "center",
  },
  headerInfoSecond:{
  },
  classFlsMsgAlert:{
    marginTop:0,
    marginRight:17,
  },
  inputTrackingName:{
    marginLeft: 16,
    "& .MuiOutlinedInput-input":{
      paddingLeft:0,
      paddingRight:0,
      borderBottom: "1px solid #c4c4c4",
      width: 300,
    },
    "& .MuiOutlinedInput-notchedOutline":{
      border:"unset"
    }
  },
  halfRadius: {
    borderRadius: "20px",
    backgroundColor: "#C4C4C4",
    "&:hover": {
      backgroundColor: "rgba(5, 124, 255, 0.15)",
    },
  },
  selectedNoRadius: {
    borderRadius: "0%",
  },
  selectedHalfRadius: {
    borderRadius: "20px",
  },
  popover: {
    position: "absolute",
    zIndex: 2,
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  iconName: { fontSize: 15, color: "#333333" },
  fieldSideIconDiv: {
    width: 20,
    marginLeft: 5,
    color: "rgba(211, 211, 211, 1)",
  },
  fieldSideIconSocialDiv: {
    width: "1em",
    marginLeft: 5,
    marginTop: "14px",
    color: "rgba(211, 211, 211, 1)",
  },
  fieldSideIcon: {
    cursor: "pointer",
    width:20,
    height:20
  },
  socialIconSelect: {
    paddingBottom: ".8rem",
    paddingTop: ".9rem",
  },
  addNewMenuButton: {
    marginTop: "1rem",
    border: "2px solid #057CFF",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    fontWeight: 600,
    "&:hover": {
      border: "2px solid #057CFF",
    },
  },
  addNewSocialButton: {
    marginTop: "1rem",
    border: "2px solid #057CFF",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    fontWeight: 600,
    "&:hover": {
      border: "2px solid #057CFF",
    },
  },
  leftBorder: {
    // borderRight: "1px solid #CDCDCD",
    minWidth: 507,
    flex: "unset",
    flexGrow: "unset",
    width: "100%",
    maxWidth: "unset",
    flexBasis: "unset",
  },
  logoButton: {
    border: "2px solid #057CFF",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    fontWeight: 600,
    "&:hover": {
      border: "2px solid #057CFF",
    },
  },
  selectIcon: {
    width: "100px",
    height: "40px",
    marginTop: "16px",
    "& .MuiOutlinedInput-input": {
      width: "47px",
      overflow: "hidden",
      textIndent: "15px",
      paddingLeft: "4px",
      textOverflow: "clip",
      paddingRight: "20px",
    },
    "& .MuiOutlinedInput-input span": {
      display: "none",
    },
  },
  buttonsStyle:{
    paddingBottom:10
  },
  menuArrowDown: {
    position: "absolute",
    right: "0",
    pointerEvents: "none"
  },
  dividerStyle:{
    marginBottom: 20
  },
  colorsList:{
    flexWrap:"wrap",
    "& $flexColumn":{
      marginBottom:10,
      width: 70
    },
    // "& $flexColumn:nth-child(6)":{      
    //   marginRight:0,
    //   ['@media (max-width:1270px)']: { // eslint-disable-line no-useless-computed-key
    //     marginRight: 20,
    //   }
    // },    
    "& $flexColumn:nth-child(6n) $popover":{  
      left:-122,
      ['@media (max-width:1261px)']: { // eslint-disable-line no-useless-computed-key
        left:2
      }
    },    
    "& $flexColumn:nth-child(6n - 1) $popover":{  
      left:-50,
      ['@media (max-width:1261px)']: { // eslint-disable-line no-useless-computed-key
        left:-65
      }
    }
  },
  
}));
