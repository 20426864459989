import React from "react";
// import { SketchPicker } from "react-color";
import { makeStyles } from "@material-ui/core/styles";
import { useStyles } from "../styles";
import { IconButton, Box } from '@material-ui/core';
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../redux/actions/styleGuideActions";
import {AutoSave} from "../StyleGuideAutosave";
import { DefaultStyleGuide } from './DefaultStyleGuide';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

interface Props {
  fieldName: string;
}

const ColorPicker: React.FC<Props> = ({ fieldName }) => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state: any) => state.styleGuide);
  const [state, setState] = React.useState<any>({
    displayColorPicker: false,
  });
  const [color, setColor] = React.useState<any>({
    color: reduxState[fieldName] || "#fff",
  });
  if(!reduxState[fieldName] && reduxState[fieldName] !== undefined){
    dispatch(addField(fieldName, DefaultStyleGuide[fieldName]));
    setColor({ color: DefaultStyleGuide[fieldName] });
    dispatch(addField("styleGuideDefault", true));
  }
  const handleClick = () => {
    setState({ displayColorPicker: true });
  };

  const handleClose = (color: any) => {
    AutoSave();
    setState({ displayColorPicker: false });
  };
  // const handleChange = (color: any) => {
  //   dispatch(addField(fieldName, color.hex));
  //   setColor({ color: color.hex });
    
  // };

  const selectColorPallet = (event:any,value: any,key: any) => {  
    dispatch(addField(fieldName, value));
    setColor({ color: color.hex });
    setState({ displayColorPicker: false });    
    changeParentColorPallet(fieldName,key);
    AutoSave();
  }

  const changeParentColorPallet = (fieldName:string, key:string) => {

    // remove color main pallet
    reduxState?.mainColorPallet?.forEach((item: any,index: any) => {
      let indexColor = item.list_default?.indexOf(fieldName);    
        if (indexColor > -1) {
          item.list_default.splice(indexColor,1)
        }
    });

    // add to related ColorPicker
    reduxState?.mainColorPallet?.forEach((item: any,index: any) => {
      var list:any[] = [];
      if(item.key === key){
        if(item.list_default !== undefined){
          item.list_default.push(fieldName);
        } else { 
          list.push(fieldName)
          item.list_default = list;
        }
    }
    });

    dispatch(addField("mainColorPallet", reduxState?.mainColorPallet));
  }

  const styles = makeStyles((theme) => ({
    color: {
      width: "fit-content",
      border: "1px solid #CDCDCD",
      // margin: 10,
      background: reduxState[fieldName] || "#F8F8F8",
      "&:hover": {
        background: reduxState[fieldName] || "#F8F8F8",
      },
    },

    colorBase: {
      position: "relative",
    },
  }));

  const classes = useStyles();
  const classCss = styles();

  return (
    <div className={fieldName}>
      <div className={classCss.colorBase}>
        <IconButton className={classCss.color} onClick={handleClick}>
          <AddOutlinedIcon
            fontSize="inherit"
            style={{ color: reduxState[fieldName] || "#C4C4C4" }}
          />
        </IconButton>
      </div>
      {state.displayColorPicker ? (
        <div className={`${classes.popover} ${classes.plPopover}`}>
          <div className={classes.cover} onClick={handleClose} />
          {/* <SketchPicker color={color.color} onChange={handleChange} /> */}
           <List className={classes.ulMainColorPalley}>
                { reduxState?.mainColorPallet?.map(function(element:any, index: any) { 
                  return(                                
                          <ListItem className={classes.listColorPallet} onClick={(e) => {
                                    selectColorPallet(e, element.value,element.key);
                                }}>
                            <Box className={`${classes.colorPalletValue} ${classes.colorPalletwihte}` } style={{ background:element.value}}>
                            </Box>
                            <ListItemText className={classes.colorPalettName} primary={element.name} title={element.name} />
                          </ListItem>                    
                  // <Box className={classes.flexColumn} key={index}>
                  //   <IconButton className={classCss.color} onClick={handleClick}>
                  //   </IconButton>
                  //   <ColorPallet fieldName={element.key} fieldColorName={element.name} fieldColorValue={element.value} />
                  //   <Typography variant="caption" className={classes.iconButtonLabel}>
                  //     {element.name}
                  //   </Typography>
                  // </Box>
                  )
                  })                  
                }   
            </List>
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
