import React, { useEffect } from "react";
import { useStyles } from "./style";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  CircularProgress,
} from "@material-ui/core";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Autocomplete } from "@material-ui/lab";

import {
  Button,
  TextField,
  Typography,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";

interface Props {
  history?: any;
  onTextBoxSelect?: any;
  isIframe?: boolean;
  handleClose: any;
  campaignMonitorlist: any;
  campaignMonitortimezone: any;
  campaignMonitorTemplates: any;
  FrontEndEspId: any;
  FrontEndcname: any;
  FrontendversionId: any;
  FrontEndSubject: any;
}

const CmExport: React.FC<Props> = ({
  history,
  onTextBoxSelect,
  isIframe,
  handleClose,
  campaignMonitorlist,
  campaignMonitortimezone,
  campaignMonitorTemplates,
  FrontEndEspId,
  FrontEndcname,
  FrontEndSubject,
  FrontendversionId,
}) => {
    const classes = useStyles();
    const [template, settemplate] = React.useState(false);
    const [TemplateNameError, setTemplateNameError] = React.useState(false);
    const [CampaignNameError, setCampaignNameError] = React.useState(false);
    const [senderNameError, setsenderNameError] = React.useState(false);
    const [errorSubject, seterrorSubject] = React.useState(false);
    const [errorCampaignList, seterrorCampaignList] = React.useState(false);
    const [errorTimezone, seterrorTimezone] = React.useState(false);
    const [SenderEmailError, setSenderEmailError] = React.useState(false);

    const [helperTexttemplate, sethelperTexttemplate] = React.useState<string>("");
    const [helperTextTemplateName, sethelperTextTemplateName] = React.useState<string>("");
    const [helperTextCampaignName, sethelperTextCampaignName] = React.useState<string>("");
    const [helperTextsenderName, sethelperTextsenderName] = React.useState<string>("");
    const [helperTextSubject, sethelperTextSubject] = React.useState<string>("");
    const [helperTextCampaignList, sethelperTextCampaignList] = React.useState<string>("");
    const [helperTextTimezone, sethelperTextTimezone] = React.useState<string>("");
    const [helperTextSenderEmail, sethelperTextSenderEmail] = React.useState<string>("");
    const [helperTextdatepicker, sethelperTextdatepicker] = React.useState<string>("");


  const [isProcess, setisProcess] = React.useState(false);
  const [IframeEspId, setIframeEspId] = React.useState<string>("");
  const [versionId, setversionId] = React.useState<string>("");
 const [campaignMode, setcampaignMode] = React.useState<string>("create");
  
  const [CampaignName, setCampaignName] =React.useState<string>(FrontEndcname);
  const [SenderName, setSenderName] = React.useState<string>("");
  const [SenderEmail, setSenderEmail] = React.useState<string>("");
  const [Subject, setSubject] =React.useState<string>(FrontEndSubject);
  const [CampaignList, setCampaignList] =React.useState<string>("");
  const [TemplateName, setTemplateName] =React.useState<string>('');
  const [TemplateId, setTemplateId] =React.useState<string>("");
   const [TimeZone, setTimeZone] =React.useState<string>("");
   // eslint-disable-next-line 
  const [isdisplay, setisdisplay] = React.useState(false);
  const [checkedvalue, setcheckedvalue] = React.useState(true);
  const [startDate, setStartDate] = React.useState<any>(new Date());
  const [startDatevariable, setstartDatevariable] = React.useState<string>("");
  const [schdule, setschdule] = React.useState(false);
  const [createCampaign, setcreateCampaign] = React.useState(true);
  const [templatepart, settemplatepart] = React.useState(false);
  const [campaignmonitoroptionstate, setcampaignmonitoroptionstate] = React.useState<string>("2");
 const [value, setValue] = React.useState("1");
 
 const [campaignMonitorlistFrontend, setcampaignMonitorlistFrontend] = React.useState<any>([]);
 const [campaignMonitortimezoneFrontend, setcampaignMonitortimezoneFrontend] = React.useState<any>([]);
 const [campaignMonitorTemplatesFrontend, setcampaignMonitorTemplatesFrontend] = React.useState<any>([]);
 const [campaignMonitorOption, setcampaignMonitorOption] = React.useState<string>("create");
 const [iframetemplateload, setiframetemplateload] = React.useState(false);
    const optionsarr = 
    {label: "", value: "" }
  const handleChange = (event: any, newValue: any) => {
    if (newValue === "1") {
      CreateEmail();
      settemplatepart(false);
      setcreateCampaign(true);
      setcampaignmonitoroptionstate('2');
    } else if (newValue === "2") {
      CreateTemplate();
      settemplatepart(true);
      setcreateCampaign(false);
       setcampaignmonitoroptionstate('1');
    }
    else if (newValue === "3") {
      updateTemplate();
      settemplatepart(true);
      setcreateCampaign(false);
       setcampaignmonitoroptionstate('3');
       if (
      getParam("selectedEspId") !== null &&
      getParam("selectedEspId") !== "" &&
      getParam("cv_name") !== null &&
      getParam("email_subject_value") !== null
    ) {
      if (isIframe === true && iframetemplateload===false) {
        Templateloadfun(
          getParam("selectedEspId"),
          getParam("versionId"),
          getParam("cv_name"),
          getParam("email_subject_value")
        );
      }
    }
    
    }
    setValue(newValue)
  };
  function getParam(param: any) {
    return new URLSearchParams(window.location.search).get(param);
  }

  isIframe = isIframe ? isIframe : false;

  const setcmpdate = (date:any) => {
      //var datevalue = '';
      let datevalue: any = '';
      datevalue =  moment(date).format("YYYY/MM/DD");
      if(datevalue != null)
      {
          setstartDatevariable(datevalue)
          setStartDate(new Date(Date.parse(datevalue)));
      }
       
  };
  
  const CreateEmail = () => {
    setcampaignMode("createEmail");
    setcheckedvalue(false);
  };
  const CreateTemplate = () => {
    setcampaignMode("createTemplate");
    setcheckedvalue(false);
  };
  const updateTemplate = () => {
    setcampaignMode("updateTemplate");
    setcheckedvalue(false);
  };
  
 
  const closeContent = () => {
    onTextBoxSelect("close");
  };

  const handleChangeOption = (event: any) => {
      setcampaignMonitorOption(event.currentTarget.value);
    setcheckedvalue(false);
    if (event.currentTarget.value === "create") {
      setcheckedvalue(true);
    }
    if (event.currentTarget.value === "schedule") {
      setschdule(true);
    } else {
      setschdule(false);
    }
  };

  const CampaignNamefun = (event: any) => {
      setCampaignName(event.currentTarget.value);
  };
  const setSenderNamefun = (event: any) => {
   setSenderName(event.currentTarget.value);
  };
  const setSenderEmailfun = (event: any) => {
   setSenderEmail(event.currentTarget.value);
  };
  const setSubjectfun = (event: any) => {
   setSubject(event.currentTarget.value);
  };
   const setCampaignListfun = (event: any) => {
   setCampaignList(event.target.value);
  };
   const setTimeZonefun = (event: any) => {
   setTimeZone(event.target.value);
  };
  const setTemplateNamefun = (event: any) => {
   setTemplateName(event.target.value);
  };
  

  function isValidDate(d:any) {
  return d instanceof Date;
}
  const ExportHtml = () => {
      var datetosent = '';
      if(startDatevariable === '')
      {
          datetosent = moment(new Date()).format("YYYY/MM/DD");
      } else {
          datetosent = startDatevariable;
      }
      var flag = false;
    if(campaignmonitoroptionstate === '2')
    {
        if (CampaignName === "") {
          setCampaignNameError(true);
          sethelperTextCampaignName('Required Field');
          flag = true;
        } if (SenderName === "") {
          setsenderNameError(true);
          sethelperTextsenderName('Required Field');
          flag = true;
        } if (SenderEmail === "") {
          setSenderEmailError(true);
          sethelperTextSenderEmail('Required Field');
          flag = true;
        } if (Subject === "") {
          seterrorSubject(true)
          sethelperTextSubject('Required Field');
          flag = true;
        } if (CampaignList === "") {
          seterrorCampaignList(true)
          sethelperTextCampaignList('Required Field');
          flag = true;
        }
        if(schdule === true)
        {
            if(!isValidDate(startDate))
            {
                sethelperTextdatepicker('Required Field');
                flag = true;
                
            } else {
                sethelperTextdatepicker('');
            }
            if(TimeZone === '')
            {
                seterrorTimezone(true);
                sethelperTextTimezone('Required Field');
                flag = true;
                
            } else {
                seterrorTimezone(false);
                sethelperTextTimezone('');
            }
        }
        
        if (CampaignName !== "") {
          setCampaignNameError(false);
          sethelperTextCampaignName('')
        }  if (SenderName !== "") {
          setsenderNameError(false);
          sethelperTextsenderName('');
        } if (SenderEmail !== "") {
          setSenderEmailError(false);
          sethelperTextSenderEmail('');
        }  if (Subject !== "") {
          seterrorSubject(false)
          sethelperTextSubject('');
        }  if (CampaignList !== "") {
          seterrorCampaignList(false)
          sethelperTextCampaignList('');
        } 
        
    } 
    else if(campaignmonitoroptionstate === '1')
    {
       if(TemplateName === '')
       {
           setTemplateNameError(true);
           sethelperTextTemplateName('Required Field');
           flag = true;
       } else {
           setTemplateNameError(false);
           sethelperTextTemplateName('')
       }  
    }
    else if(campaignmonitoroptionstate === '3')
    {
       if(TemplateId === '')
       {
           settemplate(true);
           sethelperTexttemplate('Required Field');
           flag = true;
       } else {
           settemplate(false);
           sethelperTexttemplate('')
       }
        if(TemplateName === '')
           {
               setTemplateNameError(true);
               sethelperTextTemplateName('Required Field');
               flag = true;
           } else {
               setTemplateNameError(false);
               sethelperTextTemplateName('')
           }
           
        }
        
    
    if(flag === false)
    {
        
        setisProcess(true);
      var sFormData = require("form-data");
      var sdata = new sFormData();
      sdata.append("campaign_name",CampaignName);
      sdata.append("sender",SenderName);
      sdata.append("sender_email",SenderEmail);
      sdata.append("subject",Subject);
      sdata.append("campaignmonitor_list_id",CampaignList);
      sdata.append("timeToSent",datetosent);
      sdata.append("timezone",TimeZone);
      sdata.append("template_name",TemplateName);
      sdata.append("templateId",TemplateId);
      sdata.append("cm_active_state",campaignmonitoroptionstate);
      sdata.append("option",campaignMonitorOption);
      
      if (isIframe === true) {
        sdata.append("espId", IframeEspId);
      } else {
        sdata.append("espId", FrontEndEspId);
      }
      if (isIframe === true) {
        sdata.append("version_id", versionId);
      } else {
        sdata.append("version_id", FrontendversionId);
      }

      //sdata.append("Campaigntitle", Campaigntitle);
      
      AxiosInstance.post(
        "api/v1/Integration/createCampaignMonitorCampaign",
        sdata
      ).then((response: AxiosResponse) => {
          //console.log(response);
          //console.log(response.data.status);
          if (response.data.status === true) {
            if (isIframe === true) {
              setisProcess(false);
              onTextBoxSelect("success_"+response.data.data.msg);
            } else {
              handleClose();
              toast.success(response.data.data.msg);
              setisProcess(false);
            }
          } else {
            if (isIframe === true) {
              setisProcess(false);
              onTextBoxSelect("Failed_"+response.data.data.msg);
            } else {
              toast.error(response.data.data.msg);
              handleClose();
              setisProcess(false);
            }
          }
        
      });
    }
    
    
  };

  const setTemplatedefautvalues = (e: any, Dropdownvalue: any) => {
    setisProcess(true);
    setisdisplay(true);
        settemplate(false);
        sethelperTexttemplate('')
        setTemplateId(Dropdownvalue.value);
    var sDraftFormData = require("form-data");
    var sDraftdata = new sDraftFormData();
    sDraftdata.append("TemplateId", Dropdownvalue.value);
    if (isIframe === true) {
      sDraftdata.append("espId", IframeEspId);
    } else {
      sDraftdata.append("espId", FrontEndEspId);
    }
    setTemplateId(Dropdownvalue.value);
    AxiosInstance.post(
      "campaigns/get_cm_template_byid",
      sDraftdata
    ).then((response: AxiosResponse) => {
      if (response.data.Name!=='') {
        setTemplateName(response.data.Name);
        setisProcess(false);
        setisdisplay(false);
      } else {
        toast.error("Campaign details not found");
        setisProcess(false);
        setisdisplay(false);
      }
    });
    //setisdisplay(false);
  };
  
  const Templateloadfun = (espIdvalue: any,
    versionId: any,
    cv_name: any,
    email_subject_value: any) => {
        setCampaignName(cv_name);
        setSubject(email_subject_value);
        var FormData = require("form-data");
        var data = new FormData();
        data.append("espId",espIdvalue);
        setIframeEspId(espIdvalue);
        setversionId(versionId);
        AxiosInstance.post("api/v1/Integration/load_camp_monitor_templates", data).then(
        (response: AxiosResponse) => {
            if(response.data)
            {
           if(response.data.status === true)
                {
                    if(response.data.data.CmTemplates.length>0)
                    {
                        setcampaignMonitorTemplatesFrontend(response.data.data.CmTemplates);
                        setiframetemplateload(true);
                    } 
                } else {
                    toast.error('Campaign Monitor Contatlist list not found');
                }
            }
        }
        );
  };

   const CampaignExportEspfunProcess = (espIdvalue: any,
    versionId: any,
    cv_name: any,
    email_subject_value: any) => {
        setCampaignName(cv_name);
        setSubject(email_subject_value);
        var FormData = require("form-data");
        var data = new FormData();
        data.append("espId",espIdvalue);
        setIframeEspId(espIdvalue);
        setversionId(versionId);
        AxiosInstance.post("campaigns/load_camp_monitor_lists", data).then(
        (response: AxiosResponse) => {
            if(response.data)
            {
                if(response.data.status === true)
                {
                    if(response.data.listCM.length>0)
                    {
                        setcampaignMonitorlistFrontend(response.data.listCM);
                        setcampaignMonitortimezoneFrontend(response.data.timezonesCM);
                    var sFormData = require("form-data");
                    var sdata = new sFormData();
                    sdata.append("espId",espIdvalue);
                       AxiosInstance.post("api/v1/Integration/load_camp_monitor_templates", sdata).then(
                    (response: AxiosResponse) => {
                        if(response.data.status === true)
                        {
                            if(response.data.data.CmTemplates.length>0)
                            {
                                setcampaignMonitorTemplatesFrontend(response.data.data.CmTemplates);
                                //setOpenCmDialog(true);
                                  
                            } 
                        } else {
                            toast.error('Campaign Monitor Contatlist list not found');
                        }
                    }
                     );
                    
                } else{
                    toast.error('Campaign Monitor lists not found');
                }
                    
                } else {
                    toast.error('Campaign Monitor lists not found');
                }
            }
        }
        );
  };
  

  useEffect(() => {
    setCampaignName(FrontEndcname);
    setSubject(FrontEndSubject);
    if (
      getParam("selectedEspId") !== null &&
      getParam("selectedEspId") !== "" &&
      getParam("cv_name") !== null &&
      getParam("email_subject_value") !== null
    ) {
      if (isIframe === true) {
        CampaignExportEspfunProcess(
          getParam("selectedEspId"),
          getParam("versionId"),
          getParam("cv_name"),
          getParam("email_subject_value")
        );
      }
    }

    // eslint-disable-next-line
  }, [isIframe]);

  return (
    <Box className={classes.componentHeader}>
      <Box
        className={classes.componentInnerHeader}
        style={{ width: "100%", marginBottom: "13px" }}
      >
        <Typography variant="h5" style={{ marginBottom: "8px" }}>
          Export to Campaign Monitor
        </Typography>
        <Typography style={{ marginBottom: "8px" }}>
          Enter the details below to export your email to Campaign Monitor
        </Typography>

        <TabContext value={value}>
          <TabList
            className={classes.Tabcontentarea}
            onChange={handleChange}
            aria-label="simple tabs example"
            TabIndicatorProps={{
           style: { background: "#057CFF" }
         }}
          >
            <Tab label="Create Email" value="1" />
            <Tab label="Create Template" value="2" />
            <Tab label="Update Template" value="3" />
          </TabList>
        </TabContext>
        
        
        {campaignMode === "updateTemplate" && isIframe === true && (
          <Box
            className={classes.InputField}
            style={{
              position: "relative",
            }}
          >
          
           {iframetemplateload === true ? (
            <Autocomplete
                disableClearable
                options={campaignMonitorTemplatesFrontend}
                defaultValue={optionsarr}
                getOptionLabel={(campaignMonitorTemplatesFrontend) => campaignMonitorTemplatesFrontend.label}
                onChange={setTemplatedefautvalues}
                renderInput={(params) => (
                <TextField
                error={template}
                helperText={helperTexttemplate}
                {...params}
                label="Select Template"
                placeholder="Select Template"
                variant="outlined"
                />
                )}
                />
          ) : (
            <TextField
              select
              fullWidth
              defaultValue=""
              variant="outlined"
              label="Select Template"
              InputProps={{
                endAdornment: (
                  <CircularProgress
                    style={{
                      marginTop: 10,
                      width: 18,
                      height: 18,
                      color: "rgba(0, 0, 0, 0.26)",
                      display: isdisplay ? "block" : "block",
                      position: "absolute",
                      top: "10px",
                      right: "40px",
                    }}
                  />
                ),
              }}
            >
             
            </TextField>
          )}
          </Box>
        )}

        {campaignMode === "updateTemplate" && isIframe === false && (
          <Box
            className={classes.InputField}
            style={{
              position: "relative",
            }}
          >
          <Autocomplete
                disableClearable
                options={campaignMonitorTemplates}
                defaultValue={optionsarr}
                getOptionLabel={(campaignMonitorTemplates) => campaignMonitorTemplates.label}
                onChange={setTemplatedefautvalues}
                renderInput={(params) => (
                <TextField
                error={template}
                helperText={helperTexttemplate}
                {...params}
                label="Select Template"
                placeholder="Select Template"
                variant="outlined"
                
                
                />
                )}
                />
          </Box>
        )}
        
        
        {templatepart === true && (
          <Box className={classes.InputField}>
            <TextField
              name="TemplateName"
              autoFocus
              error={TemplateNameError}
              helperText={helperTextTemplateName}
               fullWidth
              defaultValue={TemplateName}
              key={TemplateName}
              onChange={setTemplateNamefun}
              variant="outlined"
              label="Template Name"
              //className={classes.clsNewTemplateName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
          </Box>
        )}

        {createCampaign === true && (
          <Box className={classes.InputField}>
            <TextField
              name="Campaign_title"
              autoFocus
              error={CampaignNameError}
              helperText={helperTextCampaignName}
              fullWidth
              defaultValue={CampaignName}
              key={CampaignName}
              onChange={CampaignNamefun}
              variant="outlined"
              label="Campaign Name"
              //className={classes.clsNewTemplateName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
          </Box>
        )}
        {createCampaign === true && (
          <Box className={classes.InputField}>
            <TextField
              name="Sender_name"
              autoFocus
              error={senderNameError}
              helperText={helperTextsenderName}
              fullWidth
              defaultValue={SenderName}
              key={SenderName}
              onChange={setSenderNamefun}
              variant="outlined"
              label="Sender Name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
          </Box>
        )}
        
        {createCampaign === true && (
          <Box className={classes.InputField}>
            <TextField
              name="Sender_email"
              autoFocus
              error={SenderEmailError}
              helperText={helperTextSenderEmail}
              fullWidth
              defaultValue={SenderEmail}
              key={SenderEmail}
              onChange={setSenderEmailfun}
              variant="outlined"
              label="Sender Email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
          </Box>
        )}
        
        
        {createCampaign === true && (
          <Box className={classes.InputField}>
            <TextField
              name="Subject"
              autoFocus
              error={errorSubject}
              helperText={helperTextSubject}
              fullWidth
              defaultValue={Subject}
              key={Subject}
              onChange={setSubjectfun}
              variant="outlined"
              label="Subject"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
            />
          </Box>
        )}

        {createCampaign === true && isIframe === false && (
          <Box>
            <TextField
              error={errorCampaignList}
              helperText={helperTextCampaignList}
              select
              fullWidth
              defaultValue={CampaignList}
              key={CampaignList}
              onChange={setCampaignListfun}
              variant="outlined"
              label="List Campaign"
            >
              {campaignMonitorlist.map((Item: any, index: any) => {
                return <MenuItem value={Item.ListID}>{Item.Name}</MenuItem>;
              })}
            </TextField>
          </Box>
        )}
        {createCampaign === true && isIframe === true && (
          <Box>
            <TextField
              error={errorCampaignList}
              helperText={helperTextCampaignList}
              select
              fullWidth
              defaultValue={CampaignList}
              key={CampaignList}
              onChange={setCampaignListfun}
              variant="outlined"
              label="List Campaign"
            >
              {campaignMonitorlistFrontend.map((Item: any, index: any) => {
                return <MenuItem value={Item.ListID}>{Item.Name}</MenuItem>;
              })}
            </TextField>
          </Box>
        )}
       {createCampaign === true && (
        <Box>
          <RadioGroup
            style={{ marginTop: "10px" }}
            aria-label="image"
            name="camOptions"
            onChange={handleChangeOption}
          >
            <FormControlLabel
              checked={checkedvalue}
              value="create"
              control={<Radio color="primary" />}
              label="Create draft Campaign"
            />
            <FormControlLabel
              value="send"
              control={<Radio color="primary" />}
              label="Send the Campaign immediately"
            />
            <FormControlLabel
              value="schedule"
              control={<Radio color="primary" />}
              label="Schedule the Campaign for a date to be sent"
            />
          </RadioGroup>
        </Box>
        )}

        {schdule === true && createCampaign === true && (
          <Box style={{ marginBottom: "15px", marginTop: "15px" ,marginRight: "30px" }}>
            <DatePicker
              wrapperClassName={classes.wrapperDatePicker}
              selected={startDate}
              onChange={(date: any) => setcmpdate(date)}
            />
            <span>{helperTextdatepicker}</span>
          </Box>
        )}

        {schdule === true && createCampaign === true && isIframe===false && (
          <Box>
            <TextField
              error={errorTimezone}
              helperText={helperTextTimezone}
              select
              fullWidth
              defaultValue={TimeZone}
              key={TimeZone}
              onChange={setTimeZonefun}
              variant="outlined"
              label="TimeZone"
            >
              {campaignMonitortimezone.map((Item: any, index: any) => {
                return <MenuItem value={Item}>{Item}</MenuItem>;
              })}
            </TextField>
          </Box>
        )}
        
        {schdule === true && createCampaign === true && isIframe===true && (
          <Box>
            <TextField
              error={errorTimezone}
              helperText={helperTextTimezone}
              select
              fullWidth
              defaultValue={TimeZone}
              key={TimeZone}
              onChange={setTimeZonefun}
              variant="outlined"
              label="TimeZone"
            >
              {campaignMonitortimezoneFrontend.map((Item: any, index: any) => {
                return <MenuItem value={Item}>{Item}</MenuItem>;
              })}
            </TextField>
          </Box>
        )}

        <Box className={classes.gptFooter}>
          {isIframe === true ? (
            <Button
              onClick={() => {
                closeContent();
              }}
              size="large"
              style={{ opacity: "0.6", marginRight: 16 }}
            >
              CANCEL
            </Button>
          ) : (
            <Button
              onClick={() => {
                handleClose();
              }}
              size="large"
              style={{ opacity: "0.6", marginRight: 16 }}
            >
              CANCEL
            </Button>
          )}

          {isProcess === true ? (
            <CircularProgress
              style={{
                marginTop: 10,
                width: 18,
                height: 18,
                color: "rgba(0, 0, 0, 0.26)",
                display: "block",
              }}
            />
          ) : (
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.insertbut}
              onClick={() => {
                ExportHtml();
              }}
            >
              EXPORT
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CmExport;
