import React from "react";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "../styles";

interface Props {
	teamData: any;
	defaultTeamslist: any;
	setSelectedvalue: any;
	teamSelectedValue: any;
	setTriggerChange: any;
}

const TeamDropDown:  React.FC<Props> = ({
	teamData,
	defaultTeamslist,
	setSelectedvalue,
	teamSelectedValue,
	setTriggerChange
}) => {
  const classes = useStyles(); 
  // eslint-disable-next-line
  const [defaultStylelist, setdefaultStylelist] = React.useState<any>([]);
  //const [isCheckAll, setIsCheckAll] = React.useState(true);
  //const URL = window.location.pathname.split('/').pop();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "auto",
      },
    },
  };

   const makeJoin = (selected:[]) => {
	  
	  var arrTemp:string[] = []
	   selected.forEach((Item:any, index:any) => {
		  teamData.forEach((InD:any, index1:any) => {
			if(Item ===InD.id){
				arrTemp[index]= InD.team_name;
			}
		  }); 
		 
	  });
	 
	  return arrTemp.join(',');
  };
 
  
  const handleChange = (event:any) => {
	//setIsCheckAll(false);
    setSelectedvalue(typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value);
	setTriggerChange(true);
  };
  
	

  return (
    <Box className={classes.multipleCheckBox} display="flex" alignItems="center" >
      <Typography style={{fontWeight: "600", marginRight: 15 ,letterSpacing: "0.63px",}}>TEAM</Typography>
      <FormControl className={classes.teamDropControl} >
        {/* <InputLabel id="demo-multiple-checkbox-label">TEAM</InputLabel> */}
        <Select
          className={classes.teamDropSelect} 
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={defaultTeamslist}
          onChange={handleChange}
          // label={"TEAM"}
          renderValue={(selected: any) => makeJoin(selected)}
          MenuProps={MenuProps}
        >
			
          {teamData.map((Item : any,index:any) => {
              return (
              <MenuItem key={Item.id} value={Item.id}>
                <Checkbox checked={defaultTeamslist.indexOf(Item.id) > -1} color="primary" />
                <ListItemText primary={Item.team_name} />
              </MenuItem>
              )
          })}
        </Select>
      </FormControl>      
    </Box>
  );
};

export default TeamDropDown;
