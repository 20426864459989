import React from "react";
import {TextField,Box,Button} from "@material-ui/core";
import { useStyles } from "../styles";
interface Props {
  history?:any;
  teamName: any;
  setTeamName: any;
  saveTeam: any;
  clearData: any;
  saving:boolean;
}
const TeamName: React.FC<Props> = ({history,teamName,setTeamName,saveTeam,clearData,saving }) => {
   
// var team_name = useSelector((state: any) => state.styleGuide.team_name);
// eslint-disable-next-line
//const [teamName, setTeamName] = useState<string>("");  

const classes = useStyles();  

const handleInputChange = (Inputvalue : any) => {
    setTeamName(Inputvalue.target.value);
};

const reloadListing = () => {
	clearData();
	localStorage.removeItem('teamID');	
    setTimeout(history.push('/settings/teams'), 1000);
};

  return (
    <Box className="App">
        <Box className={classes.headerInfo}>
          <Box className={classes.headerInfoFirst}>
            <strong>Name:</strong> 
            <TextField style={{marginBottom:"10px"}}
					   onMouseOut={(e) => handleInputChange(e) } 
					   defaultValue = {teamName}
                       key={teamName}
                       className={classes.inputTeamName} />
          </Box> 
          <Box className={classes.headerInfoSecond}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {/* <Typography id="FlsMsgAlert"
              style={{
              fontSize: "14px",
              fontWeight: 600,
              display:"none",
              float:"left",
              }}            
              className={classes.classFlsMsgAlert}
              >
              </Typography> */}
              <Button className={classes.closeButtonStyle} variant="outlined" color="primary" onClick={() => { reloadListing() }}>
                Close
              </Button>
              <Button
                className={classes.buttonStyle}
                variant="contained"
                color="primary"
				disabled={saving}
                onClick={() => {saveTeam()}}
              >
                SAVE & CLOSE
            </Button>
            </Box>
          </Box>
        </Box>
    </Box>
  );
};

export default TeamName;
