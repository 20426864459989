import React, { Dispatch, SetStateAction } from "react";
import {
  Dialog,
  
} from "@material-ui/core";


import CampaignMonitorExport from './CampaignMonitorExport';
 interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  campaignMonitorlist:any;
  campaignMonitortimezone:any;
  campaignMonitorTemplates:any;
  FrontEndEspId:any;
  FrontendversionId:any;
  FrontEndcname:any;
  FrontEndSubject:any;
  
  
}

const CampaignMonitorExportDialog: React.FC<Props> = ({
  open,
  setOpen,
  campaignMonitorlist,
  campaignMonitortimezone,
  campaignMonitorTemplates,
  FrontEndEspId,
  FrontendversionId,
  FrontEndcname,
  FrontEndSubject,
  
}) => {

 
  const handleClose = () => {
    setOpen(false);
  };

   return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ style: {
    width: '600px',
    height:'100%'
  }}}
        aria-labelledby="form-dialog-title"
      >
      <CampaignMonitorExport FrontEndSubject={FrontEndSubject} 
      handleClose={handleClose} FrontEndcname={FrontEndcname} 
      FrontendversionId={FrontendversionId}  
      FrontEndEspId={FrontEndEspId} 
      campaignMonitorlist={campaignMonitorlist} 
      campaignMonitortimezone={campaignMonitortimezone}
      campaignMonitorTemplates={campaignMonitorTemplates}
      />
       
      </Dialog>
    </div>
  );
};

export default CampaignMonitorExportDialog;
