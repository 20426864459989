import React, { Dispatch, SetStateAction } from "react";
import {
  Dialog,
  TextField,
  Button,
  Box,
  MenuItem,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AxiosInstance from "../../../../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
import { toast } from "react-toastify";
interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedFolderName: string;
  history: any;
  getloadCampsInitial: any;
  selectedCampId: number;
  brandID: string;
  triggerCampActions:any;
  styleFetchData:any;
  selectedDesign: string;
  setDesignUpated: any;
}


const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  label: {
    fontWeight: "inherit",
    color: "inherit",
    borderRadius: theme.spacing(0.5),
    marginTop: ".3rem",
  },
  
  header: {
    borderBottom: "1px solid #c4c4c4",
    paddingLeft: "0",
    paddingRight: "0",
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
    paddingBottom: ".5rem",
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: 350,
  },
}));
const ChangeDesign: React.FC<Props> = ({
  open,
  setOpen,
  selectedFolderName,
  history,
  getloadCampsInitial,
  selectedCampId,
  brandID,
  triggerCampActions,
  styleFetchData,
  selectedDesign,
  setDesignUpated,
}) => {
  const classes = useStyles();
  const [styleguideId, setstyleguideId] = React.useState<string>("");
 

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event: any) => {
   setstyleguideId(event.target.value);
  };
 
  const updateDesign = () => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("camp_id", selectedCampId);
    
	var ID=styleguideId;
	if(!ID)
		ID=selectedDesign;

	if(!ID){
		toast.error('Design not selected');
		return;
	}
	data.append("styleguideId", ID);
    AxiosInstance.post('api/v1/Campaign/updateDesign', data)
     .then((response: AxiosResponse) => {
      setOpen(false);
        if (response.data.status === true) {       
          setDesignUpated(ID);
        } else
        {
            toast.error(response.data.response_msg);
        }
      })
  };

  
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.header}>
          Update Design: {selectedFolderName}
        </DialogTitle>
		<DialogContent>
		</DialogContent>
        <DialogContent>
          {styleFetchData && (
            <Box className={classes.root}>
            <TextField
            select
            fullWidth											
            name="userRoles"
            id="userRoles"
            defaultValue={selectedDesign}
            onChange={handleChange}
            variant="outlined"            
            label="Design Systems"
            >
            {styleFetchData.map((Item :any) => {
				if(Item.id>0){
					return (
						<MenuItem value={Item.id}>{Item.styleguide_name}</MenuItem>
					)
				}else{
					return ('');
				}
				
			  
            })}
            </TextField>
            </Box>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={updateDesign}
            color="primary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChangeDesign;
