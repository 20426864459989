import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box, Paper, Typography } from "@material-ui/core";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "95vw",
    height: "95vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paperContainer: {
    width: 200,
    height: 60,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 10,
    paddingLeft: 10,
  },
}));

interface Props {
  history: any;
  match: any;
}

const ActivateAccount: React.FC<Props> = ({ history, match }) => {
  const { userToken } = match.params;
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const classes = useStyles();
  const fetchUser = async () => {
    var axios = require("axios");
    var data = JSON.stringify({ usr_signup_token: userToken });
    var config = {
      method: "POST",
      url: `api/v1/users/activate_account`,
      data,
    };
    try {
      const request = await axios(config);
		localStorage.setItem("X-Token", request.data.access_token);
		localStorage.setItem("trial_mode", request.data.trial_mode);
		localStorage.setItem("team_active", request?.data?.team_active);
		localStorage.setItem("numberDays", request.data.numberDays);
		localStorage.setItem("day_msg", request.data.day_msg);
		localStorage.setItem("end_msg", request.data.end_msg);
		localStorage.setItem("date_ended", request.data.date_ended);
        localStorage.setItem("loggedInUser", JSON.stringify(request.data));
        localStorage.setItem("usr_id", request?.data?.usr_id);
		localStorage.setItem("my_teams", request.data.my_teams);
		localStorage.setItem('campReload',JSON.stringify(false)); 
		localStorage.setItem('ImageReload',JSON.stringify(false));
		localStorage.setItem('TemplateReload',JSON.stringify(false));
		localStorage.setItem('DSReload',JSON.stringify(false));
		localStorage.setItem('LandedReload',JSON.stringify(false));
      if(localStorage.getItem("loggedInUser"))
      history.push({
        pathname: '/campaign',
        search: '?redirect=true',
      })
    } catch (err: any) {
      setShowLoader(false);
      if (err.response) {
        if (err.response.status === 400) {
          toast.warn(err.response.data.message);
        }
        if (err.response.status === 500) {
          alert("500, Server Error");
          toast.error(err.response.data.message);
        }
      }
    }
  };
  useEffect(() => {
    fetchUser();
  });
  return (
    <Box className={classes.container}>
      <Paper>
        <Box className={classes.paperContainer}>
          <Typography>
            {" "}
            {showLoader ? "Verifying User..." : "Invalid Token"}
          </Typography>
          {showLoader && <CircularProgress />}
        </Box>
      </Paper>
    </Box>
  );
};

export default ActivateAccount;
