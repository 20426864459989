import React, { useEffect, useState } from "react";

import {
  Typography,
  Grid,
  Button,
  Link,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Backdrop,
  CircularProgress,
  Box,
} from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Summary from "./Summary";
import BillingInfoModal from "./BillingInfoModal";
import { getSubscriptionDetails } from "./api";
import Payment from "./payment/Payment";
import { toast } from "react-toastify";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import AxiosInstance from "../../../../../utils/AxiosInstance";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  root: {
    paddingTop: "20px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#F8F8F8",
    alignItems: "center",
  },
  containerGrid: {
    borderRadius: "6px",
    color: "#0069DD",
    border: "1px solid #057CFF",
    height: '74px',
    paddingLeft: '5px'
  },
  freeplan: {
    fontWeight: "bold",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#057CFF",
  },
  subHeading: {
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#057CFF",
  },
  upgradeplan: {
    textAlign: "right",
    cursor: "pointer",
  },
  company: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  taxsetting: {
    paddingTop: "15px",
    paddingBottom: "5px",
  },
  plantext: {
    marginTop: "5px",
    marginBottom: "5px",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#585858",
  },
  radioBtnRoot: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#057CFF",
    // backgroundColor: '#137cbd',
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#057CFF",
    },
  },

  Abnnumber: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "30px",
    color: "#585858",
    // paddingTop: "1rem",
  },
  Abnnumbertext: {
    width: "500px",
    // borderRadius: '0.5px solid #C6C6C6',
    "& input": {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      // lineHeight: '30px',
      color: "#808080",
      padding: "0px",
      height: "32px",
      paddingLeft: "12px",
      // border: '0.5px solid #C6C6C6',
      borderColor: "#C6C6C6",
    },
    "& .Mui-focused": {
      "& fieldset": {
        borderColor: "#057CFF",
      },
      "&:hover fieldset": {
        borderColor: "#057CFF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#057CFF",
      },
    },
  },
  billingInfo: {
    paddingLeft: "10px",
    color: "#000000",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "22px",
  },
  container: {
    padding: "8rem",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  Background: {
    background: "#FFFFFF",
    border: "1.02495px solid #E2E5E6",
    borderRadius: "4.0998px",
    padding: "15px",
  },
  containerPadding: {
    padding: "12px",
  },
  editbtn: {
    textTransform: "none",
    border: "1px solid #D1D5DB",
    boxSizing: "border-box",
    borderRadius: "6px",
    boxShadow: "none",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    "&:hover": {
      boxShadow: "none",
    },
  },
  linkedit: {
    color: "#057CFF",
    cursor: "pointer",
  },
  radiobtn: {
    // paddingLeft: "1rem",
  },
  abnnumber: {
    padding: "1rem",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "30px",
    color: "#585858",
  },
  heading: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
  },
  textSetting: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    marginTop: "20px",
    marginBottom: "5px",
  },
  radioLabel: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "30px",
    color: "#585858",
  },

}));
/*interface Props {
  upgradePlan: Dispatch<SetStateAction<any>>;
}*/

const ChoosePlan: React.FC = () => {
  const [isAbnVisible, setIsAbnVisible] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isPaymentProcessing, setIsPaymentProcessing] = React.useState(false);
  const history = useHistory();
  const [state, setState] = useState<any>({
    firstName: "",
    lastName: "",
    businessName: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });

  const getSubscriptionData = async () => {
    const { status, data } = await getSubscriptionDetails();
    if (status) {
      setState(data);
    }
  };
  const abnRef = useRef<any>();
  useEffect(() => {
    getSubscriptionData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles({});
  const handleChange = (e: any) => {
    setIsAbnVisible(e.currentTarget.value === "2" ? true : false);
  };

  const handleSubmit = async (event: any) => {
    setIsPaymentProcessing(true);
    event.preventDefault();
    const form = event?.currentTarget?.elements;
    let paymentCallback = callbackFactory(form.plan.value, form);
    let window2: any = window;
    let Stripe: any = window2.Stripe;
    if (!form?.name?.value) {
      toast.error("Name is required");
      setIsPaymentProcessing(false);
      return;
    }
    if (!Stripe.card.validateCardNumber(form?.cardNumber?.value)) {
      toast.error("Card number Invalid");
      setIsPaymentProcessing(false);
      return;
    }
    if (!Stripe.card.validateExpiry(form.month.value, form?.year?.value)) {
      toast.error("The expiration date is not valid");
      setIsPaymentProcessing(false);
      return;
    }

    if (form?.cvv?.value !== " ") {
      if (!Stripe.card.validateCVC(form?.cvv?.value)) {
        toast.error("The CVV is not valid.");
        setIsPaymentProcessing(false);
        return;
      }
    }
    let stripePayload = {
      name: form?.name?.value,
      number: form?.cardNumber?.value,
      cvc: form?.cvv?.value,
      exp_month: form.month.value,
      exp_year: form.year.value,
    };
    Stripe.card.createToken(stripePayload, paymentCallback);
  };
  const callbackFactory = (selectedPlan: any, form: any) => {
    const paymentCallback = async (status: any, payload: any) => {
      if (payload.error) {
        toast.warn(payload?.error?.message);
        setIsPaymentProcessing(false);
        return;
      }
      const FormData = require("form-data");
      let data = new FormData();
      data.append("stripeToken", payload?.id);
      data.append("subscription_id", selectedPlan || 0);
      data.append("card_num", payload?.card?.last4);
      data.append("exp_year", payload?.card?.exp_year);
      data.append("customer_name", payload?.card?.name);
      data.append("exp_month", payload?.card?.exp_month);

      data.append(
        "abn",
        isAbnVisible ? abnRef?.current?.querySelector("input")?.value : ""
      );
      try {
        const response = await AxiosInstance.post('api/v1/billing/create_subscription', data);
        if (response?.data?.status && !response?.data?.error) {
          toast.success("Subscription added");
          history.push("/settings/billing");
        } else {
          toast.success("failed to add subscription");
        }
        setIsPaymentProcessing(false);
      } catch (e) {
        toast.success("failed to add subscription");

        setIsPaymentProcessing(false);
      }
    };
    return paymentCallback;
  };

  function StyledRadio(props: any) {
    return (
      <Radio
        className={classes.radioBtnRoot}
        disableRipple
        color="default"
        checkedIcon={
          <span className={clsx(classes.icon, classes.checkedIcon)} />
        }
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }

  return (
    <>
      <div className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Backdrop className={classes.backdrop} open={isPaymentProcessing}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid container className={classes.container} spacing={3}>
            <Grid item md={7} className={classes.Background}>
              <Grid container spacing={6} className={classes.containerPadding}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box mb={2}>
                        <Typography className={classes.heading}>
                          Choose Plan
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        className={classes.containerGrid}
                        alignItems='center'
                      >
                        <Grid item xs={12} sm={6}>
                          <Grid container spacing={0}>
                            <Grid item xs={12}>
                              <Typography
                                component="div"
                                className={classes.freeplan}
                              >
                                {state?.subscription?.title ||
                                  " Basic Plan - $12/mo"}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography className={classes.subHeading}>
                                {state?.subscription?.description ||
                                  "This plan gives you 200 credits"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.upgradeplan}
                        >
                          <Button
                            variant="outlined"
                            className={classes.editbtn}
                          >
                            Edit
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box mb={2}>
                        <Typography className={classes.heading}>
                          Credit Card Information
                        </Typography>
                      </Box>
                      <Payment />
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography className={classes.heading}>
                          Billing Information
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={12}>
                      <Box display="flex">
                        <Box>
                          <Typography className={classes.billingInfo}>
                            {`${state?.bills?.usr_name || ""} ${state?.bills?.usr_surname || ""
                              }`}{" "}
                            <br></br>
                            {state?.bills?.business_name || ""}
                            <br></br>
                            {state?.bills?.address || ""}
                            <br></br>
                            {state?.bills?.city || ""} <br></br>
                            {state?.bills?.state || ""} <br></br>
                            {state?.bills?.country || ""}
                            <br></br>
                            {state?.bills?.postal_code || ""}
                          </Typography>
                        </Box>
                        <Box ml={5}>
                          <Typography>
                            <Link
                              className={classes.linkedit}
                              type="button"
                              onClick={handleOpen}
                            >
                              Edit
                            </Link>
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.textSetting}>
                        Tax Settings
                      </Typography>
                      <Box mb={3} mt={2}>
                        <Typography className={classes.plantext}>
                          For tax purposes, we need more information <br></br>
                          Since you’re in Australia, you’ll need to indicate
                          whether your account is an Australian Goods <br></br>
                          and Services Tax GST registered business. Australia
                          requires the collection of GST on <br></br>
                          international sales of digital products and services.
                        </Typography>
                      </Box>
                      <Box my={3}>
                        <Typography className={classes.plantext}>
                          If you have an Australian Business Number (ABN) and
                          your business is GST registered, we <br></br>
                          won't collect GST with your payments.
                        </Typography>
                      </Box>
                      <Box mt={3} mb={1}>
                        <Typography className={classes.plantext}>
                          Pick one of the following options:
                        </Typography>
                      </Box>
                      <RadioGroup
                        onChange={handleChange}
                        className={classes.radiobtn}
                        value={isAbnVisible ? "2" : "1"}
                      >
                        <FormControlLabel
                          value="1"
                          className={classes.radioLabel}
                          control={<StyledRadio color="primary" />}
                          label={
                            <label className={classes.radioLabel}>
                              I don’t have a GST Registration
                            </label>
                          }
                        />
                        <FormControlLabel
                          value="2"
                          control={<StyledRadio color="primary" />}
                          label={
                            <label className={classes.radioLabel}>
                              I have an ABN Number and I’m registered for GST
                            </label>
                          }
                        />
                      </RadioGroup>
                      {isAbnVisible && (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography className={classes.Abnnumber}>
                            ABN Number
                          </Typography>
                          <TextField
                            className={classes.Abnnumbertext}
                            variant="outlined"
                            ref={abnRef}
                            margin="dense"
                            fullWidth
                            placeholder="ABN Number"
                          />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4}>
              <Summary handleSubmit={handleSubmit} plans={state?.plans} />
            </Grid>
          </Grid>
          <BillingInfoModal open={open} handleClose={handleClose} />
        </form>
      </div>
    </>
  );
};
export default ChoosePlan;
