import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";

interface Props {}

const useStyles = makeStyles((theme) => ({
  newCampaignDiv: {
    border: "1px solid #ffffff",
    cursor: "pointer",
    position: "relative",
    borderRadius: "3px",
  },
  newCampaignCardImage: {
    width: "242px",
    height: "172px",
    backgroundSize: "auto !important",
  },
  root: {
    overflow: "auto",
    paddingTop: "4px",
    paddingLeft: "5px",
  },
}));

const FileListSkleton: React.FC<Props> = () => {
  const CardBox = () => (
    <Box p={1}>
      <div className={classes.newCampaignDiv}>
        <Card elevation={2}>
          <Skeleton
            className={classes.newCampaignCardImage}
            animation="wave"
            variant="rect"
          />
        </Card>
      </div>
    </Box>
  );
  const classes = useStyles();
  return (
    <Box display="flex" flexWrap="wrap" width="100%" className={classes.root}>
      <CardBox />
      <CardBox />
      <CardBox />
      <CardBox />
      <CardBox />
      <CardBox />
      <CardBox />
      <CardBox />
    </Box>
  );
};

export default FileListSkleton;
