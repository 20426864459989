import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box, MenuItem, TextField } from "@material-ui/core";
import { useStyles } from "../styles";
import ButtonRaduis from './Typography/ButtonRaduis';
import { AutoSave } from "../StyleGuideAutosave";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../redux/actions/styleGuideActions";
import { DefaultBoldList, DefaultStyleGuide } from "../utilities/DefaultStyleGuide";
import CustomFontSelection from "./Typography/CustomFontSelection";
import TextFiledNumber from "./Typography/TextFiledNumber";
import ButtonPadding from './Typography/ButtonPadding';
import { Autocomplete } from "@material-ui/lab";

interface Props {}

const Buttons: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.styleGuide);
  const classes = useStyles();
  // const radius = state["button_radius"] || "0";
  // const selectNoRadius = () => {
  //   dispatch(addField("button_radius", "0"));
  //   AutoSave();
  // };
  // const selectNormal = () => {
  //   dispatch(addField("button_radius", "4"));
  //   AutoSave();
  // };
  // const selectHalfRadius = () => {
  //   dispatch(addField("button_radius", "20"));
  //   AutoSave();
  // };
  if(!state["button_font"] && state["button_font"] !== undefined){
    dispatch(addField("button_font", DefaultStyleGuide["button_font"]));
    dispatch(addField("styleGuideDefault", true));
  }

  return (
    <Box display="flex" flexDirection="column" className={`${classes.section} ${classes.ctaContainerDS}`}>
      <Typography className={classes.heading} variant="h6">
        Call To Actions
      </Typography>      
      <Typography variant="caption" className={classes.caption}>
         Set the appearance of your default Call To Actions.
      </Typography>  
      <Box className={`${classes.section3} ${classes.ctaFontFamilyWrapper}`}>
        {(state?.custom_font_visible === true) && (<Box className={classes.ctaFontFamilyCustom} >
          <CustomFontSelection label="Custom Font" fieldName="cta_custom_font" />
        </Box>)}
        <Box className={classes.ctaFontFamilyStandard} >
          {/* <Box className={`${classes.flexColumn1} ${classes.TitleDescription3}`}>         
            Button{(state?.custom_font_visible) ? " Fallback" : ""} Font:
          </Box>   */}
          <Box flex="1">
            <Box >    
              <TextField
                select
                label="Standard Font"
                size="small"
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  },
                }}
                placeholder="Select your button font"
                value={state["button_font"] || "none"}
                fullWidth
                onChange={(event) => {
                  dispatch(addField("button_font", event.target.value));
                  state?.fontsList?.forEach((option:any) => {
                        if(option.name === event.target.value){  
                          dispatch(addField("button_font_full", option.family));
                        }
                    });
                  AutoSave();
                }}
              >
                {!state["button_font"] && (
                  <MenuItem value="none" disabled style={{ color: "#545454" }}>
                    Select your button font
                  </MenuItem>
                )}
                {state.fontsList && state.fontsList.map((option:any) => (
                  <MenuItem key={option.name} value={option.name}>
                  <span style={{ fontFamily: option.family }}> {option.name === "Trebuchet MS" ? "Trebuchet" : option.name}</span>
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <FontSizeLineHeight title="Text:" fieldNameFontSize="button_text_size"  fieldNameLineHeight="button_text_lineheight" fieldNameBold="button_text_weight" />    */}
      {/* <TextSizeWeight title="Text:" fieldNameFontSize="button_text_size"  fieldNameLineHeight="button_text_lineheight" fieldNameBold="button_text_weight" /> */}
      <Box className={classes.ctaSizingWrapper}>
          <Box className={classes.ctaFontSize}>
            <TextFiledNumber fieldName="button_text_size" label="Font Size" min={1} max={90} maxFieldName="button_text_lineheight" />
          </Box>
          <Box className={classes.ctaLineHeight}>
            <TextFiledNumber fieldName="button_text_fixed_lineheight" label="Line Height" min={1} max={70} minFieldName="button_text_fixed_lineheight" />
          </Box>
          <Box className={`${classes.TypographyWeight} ${classes.ctaFontWeight}`}>
            <Autocomplete
              id={`combo-box-BoldList-button`}
              options={DefaultBoldList}
              getOptionLabel={(option) => option}
              style={{ flex: "1",maxWidth:60 }}
              value={state["button_text_weight_nb"] || "none"}
              onChange={(event: any, newValue: string | null) => {
                dispatch(addField("button_text_weight_nb", newValue));
                AutoSave();
              }}
              size="small"
              renderInput={(params) => <TextField {...params} label="Weight" variant="outlined" />}
            />
          </Box>
          <Box className={classes.ctaLetterSpace}>
            <TextFiledNumber fieldName="button_text_letterspacing" label="Letter Space" min={0} max={200} />
          </Box>
      </Box>
      
      {/* <TextSizeHeightOne title="Height" fieldNameFontSize="button_text_size"  fieldNameLineHeight="button_text_lineheight" /> */}
      <ButtonPadding />
      <ButtonRaduis title="Corner Radius" fieldName="button_radius" />  
        
      {/* <Typography className={classes.subTitle} variant="subtitle1">
        Choose style:
      </Typography>
      <Box display="flex" justifyContent="space-between" maxWidth="50%" className={classes.buttonsStyle}>
        <Box className={classes.flexColumn}>
          <Button
            variant="contained"
            className={
              radius === "0" ? classes.selectedNoRadius : classes.noRadius
            }
            color="button"
            onClick={selectNoRadius}
          >
            Text
          </Button>
        </Box>
        <Box className={classes.flexColumn}>
          <Button
            className={radius === "4" ? "" : classes.normalRadius}
            onClick={selectNormal}
            variant="contained"
            color="button"
          >
            Text
          </Button>
        </Box>
        <Box className={classes.flexColumn}>
          <Button
            className={
              radius === "20" ? classes.selectedHalfRadius : classes.halfRadius
            }
            variant="contained"
            onClick={selectHalfRadius}
            color="button"
          >
            Text
          </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" maxWidth="28%" >
        <Box className={classes.flexColumn}>
          <ColorPicker fieldName="button_background_color" />
          <Typography variant="caption" className={classes.iconButtonLabel}>
            Color
          </Typography>
        </Box>
        <Box className={classes.flexColumn}>
          <ColorPicker fieldName="button_text_color" />
          <Typography variant="caption" className={classes.iconButtonLabel}>
            Text
          </Typography>
        </Box>
      </Box> */}
    </Box>
  );
};

export default Buttons;
