import React, { useState } from "react";
import { Box, IconButton} from '@material-ui/core';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/core/styles';
import ChangePassword from "./ChangePassword";
import AccountSettings from "../settings/UsersTab/AccountSetting";
import { useEffect } from "react";
import {getUserFromToken, logoutUser, teamEnabled} from "../../../../utils/AuthUtility";
import AxiosInstance from "../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
import TeamDropDown from "../../onboarding/teams/TeamDropDown";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {useDispatch} from "react-redux";
import {updateActiveTabs} from "../../../redux/actions/TabManagerActions";
const useStyles = makeStyles({    
  containerIconHelp:{
    display: "flex",
    marginLeft: 15,
    "& .MuiIconButton-root:hover":{
      backgroundColor:"unset"
    },
    "& .MuiIconButton-root":{
      padding:"unset"
    }
  },
  btnPrimary: {
		background: "#057CFF",
		borderRadius: "3px",
		color: "#ffffff",
		fontSize: 16,
		fontWeight: "normal",
		padding: "5px 30px",
		"&:hover": {
			background: "#0a6adc",
		}
	},
	teamFormSelect:{
        width:150,
		paddingLeft:10
    },

  });
  
const ActionMenu: React.FC = () => {
  const classes = useStyles();
  const state = useSelector((state: any) => state);
  const dispatch = useDispatch();
	const loggedInUser = getUserFromToken();
	let window2: any = window;
	window2?.Stripe?.setPublishableKey(
		loggedInUser?.payment_method?.publishable_key
	);
  const [open, setOpen] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedValue, setSelectedvalue] = useState<any>([]);
  const [triggerChange, setTriggerChange] = useState<boolean>(false);
  var history = useHistory();
   const switchTeam = (row:any,history:any) => {
	  
	const FormData = require("form-data");
	let data = new FormData();
	let URL = window.location.pathname.split('/').pop();
	data.append("selected", row);
	//console.log('selectedValue==',selectedValue);
    AxiosInstance.post('api/v1/Teams/switch_teams', data)
			.then((response: AxiosResponse) => {
				var loggedInUser = JSON.parse(localStorage.getItem("loggedInUser") || "");
				loggedInUser.selected_teams = response.data.selected;
				localStorage.setItem("loggedInUser", JSON.stringify(loggedInUser));
				localStorage.setItem('campReload',JSON.stringify(true)); 
				localStorage.setItem('ImageReload',JSON.stringify(true));
				localStorage.setItem('TemplateReload',JSON.stringify(true));
				localStorage.setItem('DSReload',JSON.stringify(true));
				localStorage.setItem('LandedReload',JSON.stringify(true));
				
				var scontent='';
				
				if(URL === 'campaign'){
					scontent='/scampaign';
				}else if(URL === 'content'){
					scontent='/scontent';
				}else if(URL === 'designSystems'){
					scontent='/sdesignSystems';
				}else if(URL === 'landing'){
					scontent='/slanding';
				}
				else if(URL === 'teams'){
					scontent='steams';
					URL='settings/teams';
				}
				else if(URL === 'users'){
					scontent='susers';
					URL='settings/users';
				}
				else if(URL === 'integrations'){
					scontent='sintegrations';
					URL='settings/integrations';
				}
				if(URL === 'campaign' || URL === 'content' || URL === 'designSystems' || URL === 'landing' || URL === 'landing' || URL === 'settings/teams' || URL === 'settings/users' || URL === 'settings/integrations'){
					history.push({
					  pathname: scontent,
					});
					setTimeout(() => {
						history.push({
						  pathname: "/"+URL,
						});
					}, 1);
				}
				
			}).catch((err) => {
			
			});
  };
  
  const getMyteams = () => {
	  AxiosInstance.post(`api/v1/Teams/my_teams`)
      .then((response: AxiosResponse) => {
      	dispatch(updateActiveTabs({fieldName: "my_teams", fieldValue: response.data.my_teams}));
      });
  };
  
  const getUser = (userId: any) => {
  	AxiosInstance.post(`api/v1/settings/get_user/${userId}`)
      .then((response: AxiosResponse) => {
      	setUser(response.data?.data?.user_info);
		var allow_menu = response.data.data.user_info.allow_menu;
		localStorage.setItem('allow_menu',allow_menu);
      });
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
	if(formState){}
  };
	const [formState, setFormState] = React.useState({
	 userRoles: selectedValue
	});
  // eslint-disable-next-line
	const handleTeamChange = (event: any) => {
		setSelectedvalue(event.target.value);
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value
    }));
	};
  useEffect(() => {
	if(loggedInUser !==null ){
		setSelectedvalue(loggedInUser.selected_teams);
	}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
   useEffect(() => {
	   if(triggerChange === true){
			switchTeam(selectedValue,history);
			setTriggerChange(false);
	   }
  }, [triggerChange,selectedValue,history]);
  
  useEffect(() => {
      if(loggedInUser !==null )
      {
    getUser(loggedInUser.usr_id);
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  
   useEffect(() => {
	  getMyteams();
	  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);
  const handleClose = () => {
    setAnchorEl(null);
  };
  // eslint-disable-next-line
  
  const onClickLogout = () => {
    logoutUser();
  };
  const onClickClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{display: "flex",alignItems:"center",flex: "1",justifyContent: "flex-end" }}>
    
      {(state?.tabManager?.my_teams?.length>1 && teamEnabled()) && (
        <Box display="flex" flex="1" style={{marginLeft:20}}>        
          <TeamDropDown 
            teamData={state?.tabManager?.my_teams}
            defaultTeamslist={selectedValue}
            setSelectedvalue={setSelectedvalue}
            teamSelectedValue={selectedValue}
			setTriggerChange={setTriggerChange}
          />
          {/* <TextField
          select
            className={classes.teamFormSelect}											
          name="userRoles"
          id="userRoles"
          variant="outlined"
          SelectProps={{
          multiple: true,
          value: selectedValue,
          onChange: handleTeamChange
          }}
          >
            {loggedInUser.my_teams.map((Item:any,index:any) => {

              return (
                <MenuItem value={Item.id}>{Item.team_name}</MenuItem>
              )
              })}
          <Button type="submit" className={classes.btnPrimary} onClick={switchTeam} >
            Switch
          </Button>
          </TextField> */}
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      >
        <Typography
          style={{
            marginRight: "12px",
            fontSize: "14px",
            fontWeight: 500
          }}
        >
          {`${user ? user?.usr_name : ""} ${user ? user.usr_surname : ""}`}
        </Typography>
        {!user?.profile_image.length ? (
          <Avatar
            style={{
              width: "30px",
              height: "30px",
              fontSize: "14px",
              fontWeight: 600
            }}
          >
            {loggedInUser?.user_detail?.first_last}
          </Avatar>
        ) : (
          <Avatar
            style={{ width: "30px", height: "30px",border: "1px solid #cdcdcd" }}
            alt={loggedInUser?.user_detail?.first_last}
            src={`${user?.profile_image}`}
          />
        )}
      </Box>

      <Box className={classes.containerIconHelp} >    
        <IconButton href="https://support.mashrmail.io/" target="_blank" rel="noopener noreferrer">
          <HelpIcon htmlColor="#005dcc" style={{width: 35,height: 35}} ></HelpIcon>
        </IconButton> 
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={(e) => {
                               setOpen(true);
							   onClickClose();
                              }} >Account settings</MenuItem>
        <ChangePassword 
		setAnchorEl={setAnchorEl}
		/>
        <MenuItem onClick={onClickLogout}>Log out</MenuItem>
      </Menu>
      {open && (
        <AccountSettings
          open={open}
          isAccountSetting={true}
          userId={user.usr_id}
          setOpen={setOpen}
        />
      )}
    </div>
  );
};

export default ActionMenu;
