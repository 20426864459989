import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {
	Avatar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	TextField,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Field, Form, Formik} from "formik";
import Typography from "@material-ui/core/Typography";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
import {toast} from "react-toastify";
import { useDispatch } from "react-redux";
import { updateActiveTabs } from "../../../../redux/actions/TabManagerActions";
import {teamEnabled,iSuperAdmin,isPowerUser} from "../../../../../utils/AuthUtility";

interface Props {
	open: any;
	isAccountSetting?: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	userId?: any;
	reloadLists?: any;
    StyleData:any;
}

const useStyles = makeStyles((theme) => ({
	boxRow: {
		display: "flex",
		alignItems: "center",
		paddingBottom: 17,
	},
	avatar: {
		width: 32,
		height: 32,
	},
	InputText: {
		marginTop: "0",
		flex: 1,
		"& input ": {
			padding: "7px 7px 7px 12px",
			height: "unset"
		},
		"& .MuiSelect-selectMenu ": {
			padding: 7,
		},
		"& > div ": {
			borderRadius: "2px !important"
		},
		"& input::placeholder": {
			fontSize: "14px",
			color: "#999999"
		}
	},
	InputLabel: {
		fontWeight: 400,
		color: "#585858",
		width: 117
	},
	form: {
		minWidth: 422,
		padding: "15px 15px 5px 15px",
	},
	dialogTitle: {
		padding: "7px 15px 15px 15px",
		"& > h2": {
			fontSize: "24px",
		}
	},
	dialogContent: {
		padding: "15px 15px 7px 15px",
		overflow: "hidden",
		overflowY: "scroll"
	},
	btnCancelNobelO: {
		border: "1px solid #969696",
		color: "#676767",
		borderRadius: 2,
		fontSize: 16,
		fontWeight: "normal",
		padding: "3px 22px",
		"&:hover": {
			background: "#969696",
			color: "#ffffff"
		}
	},
	btnPrimary: {
		background: "#057CFF",
		borderRadius: "3px",
		color: "#ffffff",
		fontSize: 16,
		fontWeight: "normal",
		padding: "5px 30px",
		"&:hover": {
			background: "#0a6adc",
		}
	},
	dialogActions: {
		borderTop: "1px solid #e5e5e5",
		padding: "23px 0 15px 0",
		margin: "0 15px"
	}
}));
const CreateNewUser: React.FC<Props> = ({
                                                open,
                                                isAccountSetting,
                                                setOpen,
                                                reloadLists,
                                                userId,
                                                StyleData
										  }) => {
											
	const dispatch = useDispatch();										
	const classes = useStyles();
    const [selectedImage, setSelectedImage] = useState<any>(null);
    const [selectedValue, setSelectedvalue] = useState<any>([]);
    const [selectedRoleValue, setselectedRoleValue] = useState<any>([]);
	const [initialState, setInitialState] = useState<any>({
		usr_name: "",
		usr_surname: "",
		usr_email: "",
		usr_active: "",
		usr_role: "",
        profile_image:"",
	});
    
   const [formState, setFormState] = React.useState({
         userRoles: selectedValue
		});
        
        var count = Object.keys(StyleData).length;
        if(count>0)
        {
            const Stylelist = JSON.parse(StyleData);
            var StyleItmes = Object.keys(Stylelist).map((k) => Stylelist[k]);
        }
        
    const handleFieldChange = (event: any) => {
    setSelectedvalue(event.target.value);
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value
    }));
  };  
  const handleFieldOnChange = (event: any) => {
     let RoleBox: HTMLDivElement | null = document.querySelector("#RoleBox");
    setselectedRoleValue(event.target.value);
	
    if (RoleBox) {
		if(teamEnabled()){
			RoleBox.setAttribute("style", "display:none");
		}else{
			if(event.target.value === '1')
			{
				RoleBox.setAttribute("style", "display:none");
			} else {
				RoleBox.setAttribute("style", "display:");
			}
		}
    }
    initialState.usr_role=event.target.value;
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value
    }));
  };
	
	const getUser = (userId: any) => {
		AxiosInstance.post(`api/v1/settings/get_user/${userId}`)
			.then((response: AxiosResponse) => {
				if(response.data.data.user_info.usr_level === '0'){
					response.data.data.user_info.usr_role='0';
				}else if(response.data.data.user_info.usr_level === '1'){
					response.data.data.user_info.usr_role='-1';
				}
				  setInitialState(response.data.data.user_info);
				var allow_menu = response.data.data.user_info.allow_menu;
				localStorage.setItem('allow_menu',allow_menu);
                if(response.data.data.user_info.styleguide_ids)
                {
                    setSelectedvalue(response.data.data.user_info.styleguide_ids.split(','));
                }

			});
	};
	useEffect(() => {
		if (userId) getUser(userId);
	}, [userId]);
	const handleClose = () => {
		setOpen(false);
		dispatch(updateActiveTabs({fieldName: "AddNewUser", fieldValue: false}));
	};
    
    useEffect(() => {
		 let RoleBox: HTMLDivElement | null = document.querySelector("#RoleBox");
    if (RoleBox) {
        if(initialState.usr_role === '1')
        {
          RoleBox.setAttribute("style", "display:none");
        } else {
            RoleBox.setAttribute("style", "display:");
        }
		if(!teamEnabled()){
			RoleBox.setAttribute("style", "display:");
		}
		if(teamEnabled()){
			RoleBox.setAttribute("style", "display:none");
		}
    }
	}, [initialState]);
	
    
    
	const handleUploadClick = (event: any) => {
		var file = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = function (e) {
			setSelectedImage({file, src: reader.result});
		};
	};
	const onSubmit = (form: any) => {
		const FormData = require("form-data");
		let data = new FormData();
		data.append("name", form.usr_name);
        if(formState.userRoles.length===0)
        {
                data.append("StyleId", selectedValue);
        } else {
        data.append("StyleId", formState.userRoles);
        }
        
		data.append("surname", form.usr_surname);
        if(selectedRoleValue == null || selectedRoleValue.length === 0)
        {
           data.append("role", initialState.usr_role);
		
        } else {
             data.append("role", selectedRoleValue);
        }
       
		data.append("status", form.usr_active);
		data.append("email", form.usr_email);
		if (selectedImage) {
			data.append("profile_image", selectedImage.file);
		} else if (userId) {
			data.append("old_profile_image", initialState.profile_image);
		}
		if (userId) data.append("update_user_id", initialState.usr_id);

		AxiosInstance.post('api/v1/settings/add_user', data)
			.then((response: AxiosResponse) => {
				reloadLists && reloadLists();
				setOpen(false);
				dispatch(updateActiveTabs({fieldName: "AddNewUser", fieldValue: false}));
			}).catch((err) => {
				if (err.response)
					toast.warning(err.response?.data?.data?.message);
			});
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<Formik
					enableReinitialize={true}
					initialValues={initialState}
					onSubmit={onSubmit}
				>
					{() => (
						<Form className={classes.form}>
							<DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
								{isAccountSetting
									? "Account Settings"
									: userId
										? "Edit User"
										: "Create New User"}
							</DialogTitle>
							<DialogContent className={classes.dialogContent}>
								<Box className={classes.boxRow}>
									<Typography
										variant="subtitle2"
										className={classes.InputLabel}
									>
										Profile Image
									</Typography>
									<div style={{flex: 1}}>
										{initialState.profile_image!=null && (
										<Avatar
											className={classes.avatar}
											src={
												selectedImage
													? selectedImage.src
													: userId
													? `${window.globalConfig.apiOrigin}assets/users/${initialState.profile_image}`
													: ""
											}
									  />)}
									</div>
									<label htmlFor="file-upload">
										<Typography
											style={{
												backgroundColor: "#ffffff",
												color: "#057CFF",
												cursor: "pointer",
												fontWeight: "normal"
											}}
											// variant="contained"
											// component="span"
											variant="subtitle2"
											// startIcon={<CloudUploadIcon />}
										>
											Upload Image
										</Typography>
									</label>
									<input
										accept="image/*"
										onChange={handleUploadClick}
										hidden
										id="file-upload"
										type="file"
									/>
								</Box>
								<Box className={classes.boxRow}
									 style={{}}
								>
									<Typography
										variant="subtitle2"
										className={classes.InputLabel}
									>
										First Name
									</Typography>
									<Field
										autoFocus
										as={TextField}
										name="usr_name"
										margin="dense"
										required
										className={classes.InputText}
										placeholder="First Name"
										fullWidth
									/>
								</Box>
								<Box className={classes.boxRow}>
									<Typography
										variant="subtitle2"
										className={classes.InputLabel}
									>
										Last Name
									</Typography>
									<Field
										margin="dense"
										as={TextField}
										className={classes.InputText}
										placeholder="Last Name"
										name="usr_surname"
										fullWidth
									/>{" "}
								</Box>
								<Box>
									{!isAccountSetting && (

										<>
											<div className={classes.boxRow}>
												<Typography
													variant="subtitle2"
													className={classes.InputLabel}
												>
													Email
												</Typography>
												<Field
													margin="dense"
													as={TextField}
													name="usr_email"
													type="email"
													required
													className={classes.InputText}
													placeholder="Email"
													fullWidth
												/>
											</div>
											<div className={classes.boxRow}>
												<Typography
													variant="subtitle2"
													className={classes.InputLabel}
												>
													Status
												</Typography>
												<Field
													margin="dense"
													as={TextField}
													name="usr_active"
													className={classes.InputText}
													placeholder="Status"
													select
													fullWidth
												>
													<MenuItem value="1">Active</MenuItem>
													<MenuItem value="0">Not Active</MenuItem>
												</Field>
											</div>
											<div className={classes.boxRow}>
												<Typography
													variant="subtitle2"
													className={classes.InputLabel}
												>
													Access
												</Typography>
												<Field
													margin="dense"
													as={TextField}
													name="usRoles"
													required
													className={classes.InputText}
													placeholder="Access"
													select
													fullWidth
                                                    SelectProps={{
											multiple: false,
                                            value: initialState.usr_role,
											onChange: handleFieldOnChange
											}}
												>
													{isPowerUser() && (
													<MenuItem value="-1">PowerUser</MenuItem>
													)}
													{iSuperAdmin() && (
													<MenuItem value="0">SuperAdmin</MenuItem>
													)}
													<MenuItem value="1">Admin</MenuItem>
													<MenuItem value="4">Copywriter</MenuItem>
													<MenuItem value="2">Designer</MenuItem>
                                                    <MenuItem value="5">Marketer</MenuItem>
													
												</Field>
											</div>
                                            
                                            <div className={classes.boxRow}  id="RoleBox" style={{display:'none'}}>
                                            <Typography
													variant="subtitle2"
													className={classes.InputLabel}
												>
													Design Systems
												</Typography>
											{StyleItmes && (
											<TextField
											select
                                           fullWidth											
											name="userRoles"
											id="userRoles"
											variant="outlined"
											className={classes.InputText}
											SelectProps={{
											multiple: true,
                                            value: selectedValue,
											onChange: handleFieldChange
											}}
											>
											     {StyleItmes.map((Item,index) => {

													return (
                                                       <MenuItem value={Item.id}>{Item.styleguide_name}</MenuItem>
													)
													})}
											</TextField>
                                             )}


										</div>
                                            

										</>


									)}
								</Box>
							</DialogContent>
							<DialogActions className={classes.dialogActions}>
								<Button onClick={handleClose} color="primary" className={classes.btnCancelNobelO}>
									Cancel
								</Button>
								<Button type="submit" className={classes.btnPrimary}>
									{userId
										? "Update"
										: "Add User"}

								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default CreateNewUser;
