import React, { Dispatch, SetStateAction,useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	TextField,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Field, Form, Formik} from "formik";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveTabs } from "../../../redux/actions/TabManagerActions";

interface Props {
   open: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handlenewIntsubmit:any
  IntegrationData:any
}
const useStyles = makeStyles((theme) => ({
	boxRow: {
		display: "flex",
		alignItems: "center",
		paddingBottom: 17,
	},
	avatar: {
		width: 32,
		height: 32,
	},
	InputText: {
		marginTop: "0",
		flex: 1,
		"& input ": {
			padding: "7px 7px 7px 12px",
			height: "unset"
		},
		"& .MuiSelect-selectMenu ": {
			padding: 7,
		},
		"& > div ": {
			borderRadius: "2px !important"
		},
		"& input::placeholder": {
			fontSize: "14px",
			color: "#999999"
		}
	},
	InputLabel: {
		fontWeight: 400,
		color: "#585858",
		width: 150
	},
	form: {
		minWidth: 422,
		padding: "15px 15px 5px 15px",
	},
	dialogTitle: {
		padding: "7px 15px 15px 15px",
		"& > h2": {
			fontSize: "18px",
		}
	},
	dialogContent: {
		padding: "15px 15px 7px 15px",
		overflow: "hidden",
		overflowY: "scroll"
	},
	btnCancelNobelO: {
		border: "1px solid #969696",
		color: "#676767",
		borderRadius: 2,
		fontSize: 16,
		fontWeight: "normal",
		padding: "3px 22px",
		"&:hover": {
			background: "#969696",
			color: "#ffffff"
		}
	},
	btnPrimary: {
		background: "#057CFF",
		borderRadius: "3px",
		color: "#ffffff",
		fontSize: 16,
		fontWeight: "normal",
		padding: "5px 30px",
		"&:hover": {
			background: "#0a6adc",
		}
	},
	dialogActions: {
		borderTop: "1px solid #e5e5e5",
		padding: "23px 0 15px 0",
		margin: "0 15px"
	}
}));


  
 const CreateNewIntegration: React.FC<Props> = ({ open, setOpen ,handlenewIntsubmit,IntegrationData}) => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state);
  const classes = useStyles();
  const URL = window.location.pathname.split('/').pop();
  const history = useHistory();
  const [selectedValue, setSelectedvalue] = useState<any>([]);
		
	var count = Object.keys(IntegrationData).length;
	if(count>0)
	{
			const inTegrationlist = JSON.parse(IntegrationData);
			var inTegrationItmes = Object.keys(inTegrationlist).map((k) => inTegrationlist[k]);
	}
	
	 
		
  const handleClose = () => {
    setOpen(false);
	dispatch(updateActiveTabs({fieldName: "AddNewIntegration", fieldValue: false}));
  };
  
   const handleFieldChange = (event: any) => {
    setSelectedvalue(event.target.value);
    event.persist();
  };
  
  
	const onSubmit = (form: any) => {
		if(selectedValue!=='')
		{
			localStorage.removeItem("integration_type");
			localStorage.removeItem("integration_name");
			localStorage.setItem('integration_type', selectedValue);
			localStorage.setItem('integration_name', form.integration_name);
			handleClose();
			history.push('/settings/NewIntegration');
		}
	};
    useEffect(() => {
    if(URL ==='integrations')
     {
         localStorage.removeItem("integration_type");
	     localStorage.removeItem("integration_name");
     }
  }, [URL]);

  return (
    <div>
			<Dialog
				open={state?.tabManager?.AddNewIntegration}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<Formik
				initialValues={{
                    
                }}
					onSubmit={onSubmit}
				>
					{() => (
						<Form className={classes.form}>
							<DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
								{"Create New Integration"}
							</DialogTitle>
							<DialogContent className={classes.dialogContent}>
                            
								<Box className={classes.boxRow}>
                                <Typography
										variant="subtitle2"
										className={classes.InputLabel}
									>
										Name
									</Typography>
                                <Field
										autoFocus
										as={TextField}
										name="integration_name"
										margin="dense"
										required
										className={classes.InputText}
										placeholder="Integration Name"
										fullWidth
									/>
								</Box>
								
								
								<Box className={classes.boxRow}>
                                <Typography
										variant="subtitle2"
										className={classes.InputLabel}
									>
										Integration Type
									</Typography>
										{inTegrationItmes && (
										<TextField
										required
										select
										fullWidth											
										name="userRoles"
										id="userRoles"
										variant="outlined"
										className={classes.InputText}
										SelectProps={{
										multiple: false,
										//value: selectedValue,
										onChange: handleFieldChange
										}}
										>
										{inTegrationItmes.map((Item : any,index:any) => {

										return (
										<MenuItem value={Item.p_name}>{Item.p_name}</MenuItem>
										)
										})}
										</TextField>
										)}
								</Box>
								
							</DialogContent>
							<DialogActions className={classes.dialogActions}>
								<Button onClick={handleClose} color="primary" className={classes.btnCancelNobelO}>
									Cancel
								</Button>
								<Button type="submit" className={classes.btnPrimary}>
									{"Add"}

								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
  );
};

export default CreateNewIntegration;
