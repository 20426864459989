import React from "react";
import { useStyles } from "../../styles/styles";
import TreeItem from "@material-ui/lab/TreeItem";
import FolderIcon from "@material-ui/icons/Folder";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import Box from "@material-ui/core/Box";

interface Props {
	fileTreeData: RenderTree;
	parrentId: any;
	closeDropDownFolder: any;
	treeName: any;
}
interface RenderTree {
  id: string;
  name?: string;
  type?: string;
  folder_name?: string;
  parent_id?: string;
  company_id?: string;
  state?: {
    opened: boolean;
  };
  childes?: RenderTree[];
}


const SimpleTree: React.FC<Props> = ({
	fileTreeData,
	parrentId,
	closeDropDownFolder,
	treeName
}) => {
  const classes = useStyles();

  const renderTree = (nodes: RenderTree, i: number,) => {

    return (
      <>
        {!nodes ? ("") : (
          <TreeItem
            key={nodes.id}
            nodeId={nodes.id}
            classes={{
              root: (parrentId === String(nodes.id)) ? classes.divRoot1 : classes.divRoot2,
              label: classes.label,
              content: classes.content,
              iconContainer: classes.iconContainer
            }}
            onLabelClick={(event) => {
              event.preventDefault();
            }}
            label={nodes?.type === "company" || nodes?.type === "archive" ? (
              <div>
                <Box display="flex" data-comapnyId={nodes.id} id={nodes.id}
                  className={(parrentId === String(nodes.id)) ? classes.treeViewDiv1 : classes.treeViewDiv2}
                >
                  <Box width="100%" onClick={closeDropDownFolder} id={nodes.id} data-foldername={nodes.folder_name} data-id={nodes.company_id}>
                    <Box display="flex" flexDirection="row" style={{alignItems: 'center'}}>
                      <Box display="flex">
                        <FolderIcon className={classes.treeViewFolder} />
                      </Box>
                      <Box>
                        <Typography
                            variant="body1"
                            className={classes.filetreeTypography}
                            title={nodes.folder_name}
                          >
						  {treeName}
                          </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box flexShrink={0}>
                    <Box display="flex" flexDirection="row">
                      <Box>
                        <CheckIcon className={classes.tickIcon} />
                      </Box>                      
                    </Box>
                  </Box>
                </Box>
              </div>
            ) : (
              <Box
                display="flex"
                data-comapnyId={nodes.id}
                id={nodes.id}
				className={(parrentId === String(nodes.id)) ? classes.treeViewDiv1 : classes.treeViewDiv2}
               >
                <Box
                  width="100%"
                  onClick={closeDropDownFolder}
                  id={nodes.id}
				  data-foldername={nodes.folder_name} 
				  data-id={nodes.company_id}
                >
                  <Box display="flex" flexDirection="row" style={{alignItems: 'center'}}>
                    <Box>
                      <FolderIcon
                        className={classes.treeViewFolder}
                      />
                    </Box>
                    <Box>
                      <Typography
                          variant="body1"
                          className={classes.filetreeTypography}                          
                          title={nodes.folder_name}
                        >
                          {nodes.folder_name}
                        </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box flexShrink={0}>
                  <Box display="flex" flexDirection="row">
                    <Box>
                      <CheckIcon className={classes.tickIcon} />
                    </Box>
					
                  </Box>
                </Box>
              </Box>
            )
            }
          >
            {Array.isArray(nodes.childes) ? nodes.childes.map((node, i) => {
              return renderTree(node, i,);
            })
              : ""}
          </TreeItem>
        )}
      </>
    );
  };
  
  return (
		<div>
        {Array.isArray(fileTreeData.childes) ? fileTreeData.childes.map((node, i) => {
			 return renderTree(node, 0);
		})
        : ""}
		</div>
          
  );
};

export default SimpleTree;
