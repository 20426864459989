import React, { Dispatch, SetStateAction } from "react";
import {
  Dialog,
  
} from "@material-ui/core";


import ExportHtml from './ExportHtml';
interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  emailId:any;
  EmailProviders:any;
  controller:any;
  
}

const ExportHtmlDialog: React.FC<Props> = ({
  open,
  setOpen,
  emailId,
  EmailProviders,
  controller,
  
}) => {

 
  const handleClose = () => {
     setOpen(false);
  };

  return (
    <div>
      <Dialog
        PaperProps={{ style: {
        width: '600px'
        }}}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
      <ExportHtml controller={controller} handleClose={handleClose} EmailProviders={EmailProviders} emailId={emailId} />
       
      </Dialog>
    </div>
  );
};

export default ExportHtmlDialog;
