import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "0.5em",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      "-webkit-border-radius": "10px",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#C4C4C4",
      "-webkit-border-radius": "10px",
    },
    "input[type=number]::-webkit-inner-spin-button":{
      "-webkit-appearance": "auto",
      opacity:1
    },
    "input[type=number]::-webkit-outer-spin-button": { 
      "-webkit-appearance": "auto",
      opacity:1
    }
  },
  container: {
    width: "100%",
    height: "100%",
    border: "1px solid #D3D3D3",
    borderRadius: "14px",
    // padding: '0px 0px 0px 30px',
    // borderRadius: "12px",
  },
  dragDropMenuConatiner:{
    "& .MuiOutlinedInput-input":{
      padding:"10.5px 14px"
    }
  },
  dragDropSocialsConatiner:{
    "& .MuiOutlinedInput-input":{
      padding:"18.5px 14px 18.5px 0"
    }
  },
  logoSlider:{
    display: "flex",
    alignItems: "flex-end",
  },
  buttonStyle: {
    textTransform: "none",
    fontWeight: 500,
    boxShadow: "none",
    marginLeft:"10px",
    "&:hover": {
      background: "#0a6adc",
      boxShadow: "none",
    },
  },
  sliderContainer:{
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  },
  sliderWrapper:{
    width: "60%",
    display: "flex",
    justifyContent: "center",
  },
  slider:{
    flex: 1
  },
  sliderValue:{
    display: "flex",
    alignItems: "center",
    paddingBottom: 3,
    marginLeft: 10,
    color: "#646464",
    fontSize: 14,
    fontFamily:"Milush",
    fontWeight: 400
  },
  caption: {
    marginBottom: "25px",
    fontSize: "14px",
    color: "#646464",
    lineHeight: "18px",
  },
  captionDetails:{    
    marginBottom: "25px",
    fontSize: "14px",
    color: "#646464",
    lineHeight: "13px",
  },
  typographyText: {
    marginBottom: "25px",
    fontSize: "14px",
    color: "#646464",
    lineHeight: "18px",
  },
  styleList:{
    paddingTop:15,
    width:"100%",
  },
  styleList1:{
    width:"100%",
  },
  styleList2:{
    width:"100%",
    height:56 
  },
  styleList3:{
    width:"100%",
    paddingTop:15,   
    height:56 
    // marginBottom:15
  },
  fontFamilywithFallback:{
    display:"flex",
    flexDirection: "column",
    paddingLeft: 20,
    paddingRight: 10,
    flex:1
  },
  fontFallbackFamilyDrop:{
    paddingTop:20
  },
  fallbackText:{
    color: "#2b7ff6",
    textDecoration: "underline",
    textAlign: "right",
    fontSize: 12,
    paddingTop:8
  },
  heading: {
    marginBottom: theme.spacing(1),
    color: "#2F2F2F",
    fontSize: "18px",
    lineHeight: "23px",
    fontStyle: "normal",
    fontWeight: 500,
  },
  headingcoding: {
    borderBottom:"1px solid #c4c4c4",paddingBottom:"40px",marginBottom:"10px!important",
  },
  topHeading: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "38px",
    lineHeight: "39px",
    marginBottom: 20,
    color: "#11142D",
  },

  logoCrossButton: {
    position: "absolute",
    border: "1px solid #D3D3D3",
    padding: 1,
    right: -33,
    top: -0,
  },
  InputLabel: {
    marginTop: "-.5rem",
    fontWeight: 500,
    // marginLeft: "5px",
    // marginBottom: ".2rem",
  },
  InputLabel1: {
    fontWeight: 500,
    fontSize: 10
  },
  boldIconButton:{
    outline: "1px solid #c4c4c4",
    borderRadius: 7,
    padding: 0,
    width: 38,
    height: 40,
    "&:hover":{
      backgroundColor: "unset"
    },
    "& svg.MuiSvgIcon-root" :{
      width: 31,
      height: 28
    }
  },
  textfield: {
    width: "15rem",
  },
  fontCombo: {
    width: "40%",
    marginBottom: "1rem",
    // margintTop:'10px'
  },
  footerInfoTextField: {
    width: 216,
    borderRadius: 7,
    marginBottom: "-1px",
  },
  "MuiInputBase-input MuiOutlinedInput-input": {
    borderRadius: 70,
  },
  section: {
    marginBottom: "2rem",
  },
  section1: {
    paddingBottom: "30px",
    borderBottom:"1px solid #c4c4c4"
  },
  section2:{
    marginBottom: "2rem",
    marginTop:34,
    "& > .MuiBox-root":{
      paddingLeft:5
    }
  },
  section3: {
    marginBottom: 10,
    paddingLeft:0
  },
  section4: {
    marginBottom: 20,
    paddingLeft:0
  },
  hyperlinkSection:{
    paddingTop:30
  },
  iconButton: {
    width: "fit-content",
    border: "1px solid #CDCDCD",
    margin: 10,
  },
  iconButtonLabel: {
    color: "#11142D",
    fontWeight: 400,
    fontSize:11,
    // height: 20,
    overflow: "hidden",
    maxWidth:62,
    textAlign: "center",
    lineHeight: "14px"
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  flexColumn1:{    
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    fontFamily: 'Mulish',
    height:40
  },
  TitleDescription1:{
    width:"33%"
  },
  TitleDescription2:{
    width:"45%",
    display: "flex",
    alignItems: "center",    
    paddingTop: 14,
    paddingBottom: 20
  },
  TitleDescription3:{
    width:"55%",
  },
  componentLeftContainer: {
    display: "flex",
    overflow: "auto",
    height: "100%",
    //maxHeight:" calc(100vh - 296px)",
    flexDirection: "column",
    // paddingTop: "30px",
    // marginTop: "10px",
    marginLeft: "40px",
    paddingRight: 48
  },
  componentRight:{
    maxWidth: "unset",
    width: "58%",
    flexBasis: "unset",
    flexGrow: "unset",    
    // paddingLeft: 20,
    // paddingRight: 20
  },
  componentRightContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    overflowY:"scroll",
    //maxHeight: "calc(100vh - 256px)",
    alignItems: "center",
  },
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    "& .MuiPaper-root":{
      width:"100%"
    }
  },
  textFieldBody: {
    // width:'7rem',
    width: "103px",
    // margin: "0.3rem 1rem .3rem .3rem",
    "& .MuiOutlinedInput-input":{
      padding:"10.5px 14px"
    }
  },
  ContainerFontSize:{
    flex:1,
    marginRight:5,
  },
  ContainerLineHeight:{
    flex:1,
    marginRight:5
  },
  ContainerOneLineHeight:{
    flex:1,
    marginRight:0,
    maxWidth:100
  },
   ContainerLineHeightforRadiusbut:{
    flex:1,
    maxWidth:100
  },
  ContainerSpaceAfter:{
    flex:1,
    marginRight:5
  },
  StyleFontSize: {
    width: "100%",
    "& .MuiOutlinedInput-input":{
      padding:"10.5px 14px"
    }
  },
  StyleLineHeight: {
    width: "100%",
    "& .MuiOutlinedInput-input":{
      padding:"10.5px 14px"
    }
  },
  inputNumber:{
    "& .MuiOutlinedInput-input":{
      padding: "11px 12px"
    },
  },
  containerPadding:{
    flex:1
  },
  containerPaddingRow:{
    display: "flex",
  },
  textSizeSelect: {
    width: "8rem",
  },
  footerGrid: {
    width: "100%",
  },
  subTitle: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  noRadius: {
    borderRadius: "0%",
    backgroundColor: "#C4C4C4",
    "&:hover": {
      backgroundColor: "rgba(5, 124, 255, 0.15)",
    },
  },
  normalRadius: {
    backgroundColor: "#C4C4C4",
    "&:hover": {
      backgroundColor: "rgba(5, 124, 255, 0.15)",
    },
  },
  halfRadius: {
    borderRadius: "20px",
    backgroundColor: "#C4C4C4",
    "&:hover": {
      backgroundColor: "rgba(5, 124, 255, 0.15)",
    },
  },
  selectedNoRadius: {
    borderRadius: "0%",
  },
  selectedHalfRadius: {
    borderRadius: "20px",
  },
  popover: {
    position: "absolute",
    zIndex: 2,
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
  iconName: { fontSize: 15, color: "#333333" },
  fieldSideIconDiv: {
    width: 20,
    marginLeft: 5,
    color: "rgba(211, 211, 211, 1)",
  },
  fieldSideIconSocialDiv: {
    width: "1em",
    marginLeft: 5,
    marginTop: "14px",
    color: "rgba(211, 211, 211, 1)",
  },
  fieldSideIcon: {
    cursor: "pointer",
    width:20,
    height:20
  },
  socialIconSelect: {
    paddingBottom: ".8rem",
    paddingTop: ".9rem",
  },
  addNewMenuButton: {
    marginTop: "1rem",
    border: "2px solid #057CFF",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    fontWeight: 500,
    "&:hover": {
      border: "2px solid #057CFF",
    },
  },
  addNewSocialButton: {
    marginTop: "1rem",
    border: "2px solid #057CFF",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    fontWeight: 500,
    "&:hover": {
      border: "2px solid #057CFF",
    },
  },
  leftBorder: {
    borderRight: "1px solid #CDCDCD",
    minWidth: 507,
    flex: "unset",
    flexGrow: "unset",
    width: "42%",
    maxWidth: "unset",
    flexBasis: "unset",
    paddingTop: "40px",
    // marginTop: "10px",
  },
  logoButton: {
    border: "2px solid #057CFF",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    fontWeight: 500,
    "&:hover": {
      border: "2px solid #057CFF",
    },
  },
  selectIcon: {
    width: "155px",
    height: "44px",
    marginTop: "16px",
    "& .MuiOutlinedInput-input": {
      // width: "47px",
      overflow: "hidden",
      textIndent: "15px",
      paddingLeft: "4px",
      textOverflow: "clip",
      paddingRight: "20px",
      // "&::after":{
      //   content:'"ADD SOCIAL"',
      //   color: "#057CFF"
      // }
    },
    "& .MuiOutlinedInput-input span": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline":{
      borderColor: "#057CFF"
    },
    "& .MuiSelect-select:focus" : {
      background:"unset"
    },
    "&:hover .MuiOutlinedInput-notchedOutline" : {
      borderColor: "#057CFF"
    },
    "& legend":{
      position: "absolute",
      top: 15,
      left: 13,
      height: "auto",
      maxWidth: "unset",
      "& span":{      
        visibility: "visible",
        color: "#057CFF",
        fontSize: 16
      }
    },
    
  },
  buttonsStyle:{
    paddingBottom:10
  },
  menuArrowDown: {
    position: "absolute",
    right: "0",
    pointerEvents: "none",
    "& path" : {
      stroke : "#057CFF"
    }
  },
  dividerStyle:{
    marginBottom: 20
  },
  hcPopover: {
    top:35,
    "&:after" : {
      content:'""',
      position: "absolute",
      top: -10,
      left: 68,
      marginLeft: -50,
      width: 0,
      height: 0,
      borderBottom: "solid 10px #ffffff",
      borderLeft: "solid 7px transparent",
      borderRight: "solid 7px transparent",
    }
  },
  hexColorPicker:{
    background: "#fff",
    padding: 17,
    paddingBottom: 24,
    boxShadow: "-1px 4px 13px -3px rgba(0,0,0,0.51)",
    "-webkit-box-shadow": "-1px 4px 13px -3px rgba(0,0,0,0.51)",
    "-moz-box-shadow": "-1px 4px 13px -3px rgba(0,0,0,0.51)",
    "& .react-colorful__last-control":{
      borderRadius: "8px !important",
      marginTop: 10
    },
    "& .react-colorful" : {
      width: 190,
      height: 178
    },
    "& .react-colorful__saturation" : {
      marginBottom: 18,
      borderRadius: 6
    },
    "& .react-colorful__hue.react-colorful__last-control" : {
      height: 10,
      marginTop:0
    },
    "& .react-colorful__pointer.react-colorful__hue-pointer,& .react-colorful__pointer.react-colorful__saturation-pointer" : {
      width: 22,
      height: 22,
      border: "5px solid #fff"
    },
    "& .react-colorful__pointer.react-colorful__saturation-pointer " : {
      width: 22,
      height: 22,
      border: "5px solid #fff"
    },
    "& .MuiOutlinedInput-adornedEnd" : {
      padding: 4,
      borderRadius: "3px !important"
    },
    "& .MuiInputLabel-outlined" :{
      transform: "translate(14px, 13px) scale(1)"
    },
    "& .MuiInputLabel-shrink" :{
      fontSize: 14,
      transform:" translate(14px, -6px) scale(0.75)"
    }
  },
  hcPickerValue: {
    paddingTop:18,
    "& .MuiOutlinedInput-input" : {
      padding: "0 7px",
      color: "#000000",
      textTransform: "uppercase"
    }
  }, 
  hcPickerName: {
    paddingTop:13,
    "& .MuiOutlinedInput-root":{
      borderRadius: "3px !important"
    },
    "& .MuiOutlinedInput-input" : {
      padding: "7px 11px",
      color: "#000000",
    }
  },
  clearColorPallet:{
    background: "#fff",
    outline: "1px solid #000",
    borderRadius: "50%",
    cursor: "pointer",
    color: "#000",
    width: 17,
    height: 17,   
    position: "absolute",
    left: 34
  },
  btnAddColorPallet:{
    outline: "1px solid #c4c4c4",
    "&:hover" : {
      background:"unset"
    }
  },
  mainColorPalletList:{
    display: "flex",
    flexWrap: "wrap",
    "& $flexColumn:nth-child(6n) $hcPopover:after":{  
      left:200,
      ['@media (max-width:1261px)']: { // eslint-disable-line no-useless-computed-key
        left:77
      }
    },    
    "& $flexColumn:nth-child(6n - 1) $hcPopover:after":{  
      left:128,
      ['@media (max-width:1261px)']: { // eslint-disable-line no-useless-computed-key
        left:143
      }
    }
  }, 
  plPopover:{
    top:35,
    "&:after" : {
      content:'""',
      position: "absolute",
      top: -10,
      left: 68,
      marginLeft: -50,
      width: 0,
      height: 0,
      borderBottom: "solid 10px #ffffff",
      borderLeft: "solid 7px transparent",
      borderRight: "solid 7px transparent",
    }
  },
  logoContainer:{
    borderBottom: "1px solid #c4c4c4"
  },
  logoContainerRow:{
    display: "flex",
    alignItems: "center",
    marginBottom: 20
  },
  replaceImageBtn:{
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "flex-end"
  },
  replaceImageInput:{
    "& .MuiInputBase-input" : {
      fontSize:13
    }
  },
  paddingTop15:{
    paddingTop:15
  },
  headerInfo:{
    display: "flex",
    padding: "19px 20px 19px 31px",
    alignItems: "center",
    borderBottom: "1px solid #c4c4c4",
    fontFamily: 'Mulish'
  },
  headerInfoFirst:{
    flex:"1",
    display: "flex",
    alignItems: "center",
  },
  headerInfoSecond:{
  },
  appBarStyleDetails:{
    boxShadow:"unset",
    borderBottom:" 1px solid #e0e0e0",
    borderRadius: 4,
  },
  classFlsMsgAlert:{
    marginTop:0,
    marginRight:17,
  },
  inputStyleGuideName:{
    "& .MuiOutlinedInput-input":{
      paddingLeft:0,
      paddingRight:0,
      borderBottom: "1px solid #c4c4c4",
      width: 300,
    },
    "& .MuiOutlinedInput-notchedOutline":{
      border:"unset"
    }
  },
  tabContainerStyle: {
		minHeight: 52,
		"& .MuiTab-textColorPrimary": {
			color: '#575757'
		},
		"& .MuiTab-textColorPrimary.Mui-selected": {
			color: '#057CFF',
			"& svg": {
				color: "#057CFF",
			},

		},
	},
  tabContentErrorStyle:{
    color: "#dc004e !important",
  },
  ulMainColorPalley:{
    background: "#fff",
    marginBottom:20,
    boxShadow: "-1px 4px 13px -3px rgb(0 0 0 / 51%)",
    "-moz-box-shadow": "-1px 4px 13px -3px rgba(0,0,0,0.51)",
    "-webkit-box-shadow": "-1px 4px 13px -3px rgb(0 0 0 / 51%)",
    minWidth: 115,
    borderRadius: 5,
    maxHeight: 380,
    overflowY: "scroll",
    "& $flexColumn:nth-child(6n) $plPopover:after":{  
      left:78,
      ['@media (max-width:1261px)']: { // eslint-disable-line no-useless-computed-key
        // left:77
      }
    },    
    "&::-webkit-scrollbar" : {
      width: 4
    },    
    "&::-webkit-scrollbar-track-piece:start" : {
      marginTop:5
    },    
    "&::-webkit-scrollbar-track-piece:end" : {
      marginBottom: 4
    },
  },
  fontContainer:{

  } ,
  fontRadioGroup:{
    flexDirection: "row"
  },
  radioControlLabel:{
    marginLeft:-9,
    "& .MuiSvgIcon-root" : {
      width:12,
      height:12,
    }
  },
  googleFontsContainer:{
    paddingLeft:"0 !important",
    paddingTop:23,
    position: "relative",
    display: "flex",
    paddingBottom:20

  },
  radioGoogleFontParent:{
    "& $googleLogo1":{
      fill: "#8f8f8f"
    },
    "& $googleLogo2":{
      fill: "#7f7f7f"
    },
    "& $googleLogo3":{
      fill: "#7f7f7f"      
    },
    "& $googleLogo4":{
      fill: "#383838"
    },
    "& $googleLogo5":{
      fill: "#6e6e6e"      
    },
    "& $googleLogo6":{
      fill: "#5e5e5e"         
    },
    "& $googleLogo7":{
      fill: "#818181"         
    },
  },
  googleLogo1:{

  },
  googleLogo2:{

  },
  googleLogo3:{

  },
  googleLogo4:{

  },
  googleLogo5:{

  },
  googleLogo6:{

  },
  googleLogo7:{

  },
  LayoutStyle:{

  },
  LayoutStyleModernSelected:{
    "& > $LayoutStyleModern" :{
      outline: "2px solid #057CFF"
    }
  },
  LayoutStyleClassicSelected:{
    "& > $LayoutStyleClassic" :{
      outline: "2px solid #057CFF"
    }
  },
  LayoutStyleModern:{
    marginRight: 10,
    boxShadow: "unset",
    outline: "1px solid #e3e3e3",
    marginLeft: 2,
  },
  LayoutStyleClassic:{
    marginLeft: 10,
    boxShadow: "unset",
    outline: "1px solid #e3e3e3",
  },
  StyleCardActionArea:{
    backgroundColor: "#f8f8f8",
    display : "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  StyleCardContent:{
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
  },
  StyleCardContentTitle:{
    color: "#2F2F2F",
    fontWeight: 700,
    fontSize: 14,
  },
  StyleCardContentDescription:{
    color: "#646464",
    fontSize: 10
  },
  switchLayoutGrid:{
    paddingBottom: 10,
    display: "flex",
    alignItems: "center"
  },
  cornerRadiusOutlook:{
    paddingBottom: 10,
    display: "flex",
    alignItems: "center",
    "& $heading":{      
      flex: 1,
      marginBottom: 0,
    }
  },
  alertLayoutGrid:{
    paddingBottom: 14,
    "& .MuiPaper-root":{
      width: "auto"
    }
  },
  layoutGridTitle:{
    flex: 1,
    marginBottom: 0,
  },
  layoutGridInputContainer:{

  },
  layoutGridMargins:{
    marginRight: 10,
    flex:1
  },
  layoutGridcolumns:{
    marginRight: 10,
    flex:1
  },
  layoutGridGutters:{
    flex:1
  },
  layoutGridWidth:{
    flex:1
  },
  layoutGridEqual:{
    flex:1,
    display: "flex",
    alignItems: "center",
    paddingTop: 18,
    justifyContent: "center",
  },
  layoutPaddingTitle:{

  },
  layoutPaddingInputContainer:{
    paddingTop: 40
  },
  layoutPaddingInputWrapper:{
    display: "flex",
    height: 94,
    alignItems: "center",
    border: "2px solid #000",
    borderRadius: 5,
    position: "relative",
    margin: "20px 30px 20px 31px",
    justifyContent: "center",
  },
  layoutPaddingLeft:{
    position: "absolute",
    left: "-38px",
    width: 80,
    background: "#ffffff",
    paddingBottom: 10,
  },
  layoutPaddingTop:{
    position: "absolute",
    top: "-38px",
    width: 80,
    background: "#ffffff",
    paddingLeft: 10,
    paddingRight: 10,

  },
  layoutPaddingBottom:{
    position: "absolute",
    bottom: "-22px",
    width: 80,
    background: "#ffffff",
    paddingLeft: 10,
    paddingRight: 10,
  },
  layoutPaddingRight:{
    position: "absolute",
    right: "-38px",
    width: 80,
    background: "#ffffff",
  },
  radioGoogleFont:{
    padding: "0 9px 0 0"
  },
  radioCustomFonts:{
    padding: "0 9px 0 0"
  },
  fontContainerRadio:{
    paddingTop: 15,
    "& .MuiSvgIcon-root":{
      width:12,
      height:12
    }
  },
  googleFontsInput:{
    width: "100%"
  },
  section1new:{
    paddingBottom:20
  },
  section1Font:{
    paddingBottom:20,
    borderBottom:"unset",
    
  },
  customFontsContainer:{
    paddingBottom:20,
  },
  switchCustomFonts:{
    paddingBottom: 10,
    display: "flex",
    alignItems: "center"
  },
  customFontsLabel:{
    flex: 1,
    marginBottom: 0,
  },
  alertCustomFonts:{
    paddingBottom: 14,
    "& .MuiPaper-root":{
      width: "auto"
    }
  },
  alertCornerRadius:{
    paddingTop: 15,
    paddingBottom: 14,
    "& .MuiPaper-root":{
      width: "auto"
    }
  },
  alertDarkMode:{
    paddingTop: 15,
    paddingBottom: 30,
    "& .MuiPaper-root":{
      width: "auto"
    }
  },
  fontsContainerRow:{
    display: "flex",
    alignItems: "flex-end",
    paddingTop:18
  },
  customFontsInput:{
    width: "100%",
    paddingRight: 22
  },
  btnUpload:{
    fontWeight:"normal",
    minWidth:100,
  },
  googleAutocomplete:{
    width: "100% !important",
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":{
      paddingLeft:40
    },
    "& label.MuiInputLabel-outlined":{
      paddingLeft:30
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink":{
      paddingLeft:0
    }
  },
  boldSelected:{
    "& $boldIconButton" : {
      backgroundColor: "#dddddd"
    }
  },
  normalSelected:{

  },
  EditorStyleContainer:{
    "& .MuiOutlinedInput-notchedOutline":{
      // padding:0
    }
  },
  ctaContainerDS:{
    "& label.MuiInputLabel-outlined.MuiInputLabel-shrink" : {
      color: "rgba(0, 0, 0, 0.87)",
      fontSize:11
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink" : {
      transform: "translate(14px, -4px) scale(0.75)"
    }
  },
  ctaFontFamilyWrapper:{
    display:"flex",
    marginBottom: 15
  },
  ctaFontFamilyCustom:{
    width:"50%",
    marginRight:4
  },
  ctaFontFamilyStandard:{
    width:"50%",
    marginLeft:4
  },
  ctaSizingWrapper:{
    display: "flex",
    justifyContent:"flex-start",    
    paddingBottom:20,
    borderBottom: "1px solid #c4c4c4"
  },
  ctaFontSize:{
    flex: "1",
    maxWidth: 72,
    marginRight: 5
  },
  ctaLineHeight:{
    flex: "1",
    maxWidth: 72,
    marginRight: 5
  },
  ctaFontWeight:{
    flex:"1",
    maxWidth: 72,
    marginRight: 5
  },
  ctaLetterSpace:{
    flex: "1",
    maxWidth: 72,
    marginRight: 5
  },
  ctaAllPadding:{
    paddingBottom:40,
    marginBottom: 20,
    borderBottom: "1px solid #c4c4c4"
  },
  ctaPaddingcontainer:{
    width:"84%"
  },
  ctaPaddingRow:{
    display:"flex",
    justifyContent:"center",
    position:"relative",    
    border: "2px solid #000000",
    borderRadius: 5,
  },
  ctaPaddingTop:{
    maxWidth: 72,
    position:"absolute",
    top:"-20px",    
    background: "#ffffff",
    paddingLeft: 10,
    paddingRight: 10,
  },
  ctaPaddingBottom:{
    maxWidth: 72,
    position:"absolute",
    bottom:"-20px",    
    background: "#ffffff",
    paddingLeft: 10,
    paddingRight: 10,
  },
  ctaPaddingLeft:{
    maxWidth: 72,
    position:"absolute",
    top:"20px",
    left: "-36px",
    background: "#ffffff",
    paddingTop: 10,
    paddingBottom: 10,
  },
  ctaPaddingRight:{
    maxWidth: 72,
    position:"absolute",
    top:"20px",
    right: "-36px",
    background: "#ffffff",
    paddingTop: 10,
    paddingBottom: 10,
  },
  ctaTotalLineHeight:{
    paddingTop: 40,
    paddingBottom: 40,
    "& .MuiTypography-body1":{
      fontSize:13
    }
  },
  rowListDetails1:{
    marginBottom: 18,
    "& label.MuiInputLabel-outlined.MuiInputLabel-shrink" : {
      color: "rgba(0, 0, 0, 0.87)",
      fontSize:11
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink" : {
      // transform: "translate(14px, -6px) scale(1)"
    }
  },
  rowListDetails2:{
    marginBottom: 15,
    "& label.MuiInputLabel-outlined.MuiInputLabel-shrink" : {
      color: "rgba(0, 0, 0, 0.87)",
      fontSize:12
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink" : {
      // transform: "translate(14px, -6px) scale(1)"
    }
  },
  styleColDetails1:{
  },
  styleColDetails2:{
    "& > .MuiBox-root":{
      flex:"1"
    } ,
    "& .MuiOutlinedInput-root .MuiOutlinedInput-input" :{
      padding: "11px 4px 11px 12px"
    }
  },
  TypographyFontSize:{
    "& label.MuiInputLabel-outlined.MuiInputLabel-shrink":{
      marginLeft: "-3px",
    },
  },
  TypographyLineHeight:{
    "& label.MuiInputLabel-outlined.MuiInputLabel-shrink":{
      marginLeft: "-6px",
      width:90
    },
  },
  TypographyWeight:{
    "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
      visibility: "hidden",
      display:"none"
    },
    '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]' :{
      paddingRight:0
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment':{
      right: "-4px"
    }
  },
  TypographyLetterSpacing:{
    "& label.MuiInputLabel-outlined.MuiInputLabel-shrink":{
      marginLeft: "-8px",
      width:90
    },
  },
  TypographyMarginTop:{
    "& label.MuiInputLabel-outlined.MuiInputLabel-shrink":{
      marginLeft: "-6px",
      width:90
    },
  },
  TypographyMarginBottom:{

    "& label.MuiInputLabel-outlined.MuiInputLabel-shrink":{
      marginLeft: "-12px",
      width:90
    },
  },
  TypographyName:{
    "& > .MuiTextField-root":{
      flex:"1",
      marginRight: 8
    },
    "& > .MuiBox-root":{
      flex:"1"
    },
  },
  TypographyCutomFont:{
    marginRight: 8
  },
  TypographyStandardFont:{

  },
  skeletonGrid2:{
    paddingLeft: "unset"
  },
  skeletonGrid3:{
    paddingLeft: "unset"

  },
  skeletonGrid4:{
    paddingLeft: "unset"

  },
  skeletonGrid5:{
    paddingLeft: "unset"

  },
  skeletonGrid6:{
    paddingLeft: "unset"
  },
  groupStyleType:{
    color:"gray",
    "& .MuiButton-label":{
      color:"gray",
      fontWeight:"500"
    }
  },
  selectedStyleType:{
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    "& .MuiButton-label":{      
      color: "#057CFF"
    }
  },
  styleUrlSource:{
    "& .MuiOutlinedInput-input" :{
      // padding: "9px 14px"
    },
    "& .MuiFormHelperText-root.Mui-error":{
      fontSize: "11px"
    }
  },
  styleTypographyWeight:{
    "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
      visibility: "hidden",
      display:"none"
    },
    '& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]' :{
      paddingRight:15
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment':{
      right: "-4px"
    },
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input":{
      minWidth: 32
    }
  },
  styleFormatItalic:{
    minWidth: "unset"
  },
  styleButtonItem:{
    padding: "8px"
  },
  styleFontsInput:{

  },
  StyleItemRow:{

  },
  StyleItemCol:{
    '&:not(:last-child)': {
      marginRight: 8,
    }
  },  
  containerCustomFont:{
    "& $wrapperCustomFont:not(:first-child)":{
      borderBottom: "1px solid #c4c4c4"
    },
    "& $wrapperCustomFont:nth-child(3)":{
      paddingTop: 15
    },
    "& $wrapperCustomFont:nth-child(4)":{
      paddingTop: 15
    },

  },
  wrapperCustomFont:{
    
  },
  styleBtnUpload:{
    fontWeight:"normal",
    minWidth:80,
    marginRight:8
  },  
  boxWrapperAddBtn:{ 
    paddingTop:20
  },
  styleBtnAddStyle:{
    fontWeight:"normal",
    minWidth:80,
    marginRight:8,
  },
  stylefontFormatSelected:{
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
  styleFieldSideIcon: {
    color: "rgba(211, 211, 211, 1)",
  },
  fieldIconDelete:{
    width: 30,
    height:30,
    cursor:"pointer"
  },
  customFontIconDeleteContainer: {
    width: 20,
    height: 20,
    color: "rgba(211, 211, 211, 1)",
  },
  WeightFormControl:{
    width: 70,
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink" :{
      paddingRight: 7,
      background:" #fff"
    },
    "& .MuiSelect-outlined.MuiSelect-outlined" :{
      padding: "10.5px",
      paddingRight: 18
    }
  },
  FontStyleFormControl:{
    width: 100,
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink" :{
      paddingRight: 7,
      background:" #fff"
    },
    "& .MuiSelect-outlined.MuiSelect-outlined" :{
      padding: "10.5px",
      paddingRight: 18
    }
  },
  listColorPallet:{
    paddingRight:4
  },
  colorPalletValue:{
    width:24,
    minWidth:24,
    height:24,
    marginRight: 6,
    borderRadius: "50%",
    cursor: "pointer"
  },
  colorPalettName:{
    cursor: "pointer",
    "& span":{
      fontSize:12,
      height: 20,
      overflow: "hidden",
      width: 120
    }
  },
  colorPalletwihte:{
    outline: "1px solid #CDCDCD"
  },
  colorsList:{
    flexWrap:"wrap",
    "& $flexColumn":{
      marginBottom:10,
      width: 70
    },
    // "& $flexColumn:nth-child(6)":{      
    //   marginRight:0,
    //   ['@media (max-width:1270px)']: { // eslint-disable-line no-useless-computed-key
    //     marginRight: 20,
    //   }
    // },          
    "& $flexColumn:nth-child(6n) $hcPopover":{  
      left:-122,
      ['@media (max-width:1261px)']: { // eslint-disable-line no-useless-computed-key
        left:2
      }
    }, 
    "& $flexColumn:nth-child(6n) $plPopover":{  
      left:-66,
      "&:after ":{
        left:145
      },
      ['@media (max-width:1261px)']: { // eslint-disable-line no-useless-computed-key
        left:2
      }
    },  
    "& $flexColumn:nth-child(6n - 1) $hcPopover":{  
      left:-50,
      ['@media (max-width:1261px)']: { // eslint-disable-line no-useless-computed-key
        left:-65
      }
    },  
    "& $flexColumn:nth-child(6n - 1) $plPopover":{  
      left:10,
      ['@media (max-width:1261px)']: { // eslint-disable-line no-useless-computed-key
        left:10
      }
    },  
    "& $flexColumn:nth-child(5n) $hcPopover":{  
      ['@media (max-width:1261px)']: { // eslint-disable-line no-useless-computed-key
        left:-65,
        "&:after":{
          left:142
        }
      }
    },  
  },
  
}));
