import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Dialog,
  Typography,
  Button,
  Box,
  Radio,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  open: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
  duplicateCampaign: any;
  isVersion:any;
  selectedDuplicateCampaign:any;
}
const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: 350,
  },
  root1: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    justifyContent: "left",
    alignItems: "left",
    flexDirection: "column",
    minWidth: 400,
  },
  text: {
    margin: "0",
    paddingBottom: "1rem",
    borderBottom: "1px solid #c4c4c4",
  },
  textColor: {
    color: "red",
  },
  radioGoogleFont:{
    padding: "0 9px 0 0"
  },
  radioCustomFonts:{
    padding: "0 9px 0 0"
  },
  fontContainerRadio:{
    paddingTop: 15,
    "& .MuiSvgIcon-root":{
      width:12,
      height:12
    }
  },
}));
const DuplicateFile: React.FC<Props> = ({
  open,
  setOpen,
  duplicateCampaign,
  isVersion,
  selectedDuplicateCampaign
}) => {
  const classes = useStyles();
  const [versionSelected, setVersionSelected] = useState('1');
  const handleClose = () => {
    setOpen(false);
  };

  const handleVersion = () => {
    setOpen(false);
	var copy_type= (versionSelected === '1' ? 'duplicate':'duplicateall');
    duplicateCampaign(selectedDuplicateCampaign, copy_type);
  };
   const handleChange = (event:any) => {
    setVersionSelected(event.target.value); 
  };
  useEffect(()=>{
    setVersionSelected('1'); 
    // eslint-disable-next-line
  },[open]);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Diplicate Email</DialogTitle>
        <DialogContent>
          <Box className={classes.root1}>
            <Typography className={classes.text}>
             <Box style={{display:"flex",alignItems:"left"}}>
              <Box style={{display:"flex",alignItems:"left"}}>
                <Radio
                  checked={versionSelected === '1'}
                  onChange={handleChange}
                  value="1"
                  name="radio-button-googlefonts"
                  color="primary"
                  className={classes.radioGoogleFont}
                />
                
                </Box>
                <Typography variant="body1" style={{cursor:"pointer",marginRight: 36}}>
                  Duplicate Master as new Email
                </Typography>
              </Box>
              <Box style={{display:"flex",alignItems:"left"}}>
                <Box>
                  <Radio
                    checked={versionSelected === '0'}
					onChange={handleChange}
                    value="0"
                    name="radio-button-googlefonts"
                    color="primary"
                    className={classes.radioGoogleFont}
                  />
                </Box>
                <Typography variant="body1"  style={{cursor:"pointer"}}>
                  Duplicate Master as new Email, and duplicate all Versions
                </Typography>
              </Box>
            
              
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={handleVersion}
            color="primary"
          >
            Duplicate
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DuplicateFile;
