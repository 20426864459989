export default class CampaignModel {

    static getStatus(camp:any){
            let isSent = CampaignModel.isSent(camp);
            var status = parseInt(camp.cv_status);
            let isExported = CampaignModel.isExported(camp);
            if (isNaN(status)) {
                status = 0;
            }
			if (isExported || isSent) {
				status = 998;
			}
            

            switch (status) {
                case 0: return "Draft";
                case 1: return "Awaiting Approval";
                case 2: return "Approved";
                case 3: return "Not Approved";
                case 4: return "Changes Required";
				case 998: return "Exported";
            }

            return "Unknown";
    }

    static isSent (camp: any) {
        return (!CampaignModel.empty(camp.cv_is_avarto) && parseInt(camp.cv_is_avarto) !== 0)
    }

	static isExported (camp: any) {
		var is_exported=parseInt(camp.cv_is_exported);
		if(is_exported === 1){
			return true;
		}
		return !CampaignModel.empty(camp.cv_camp_monitor_id);
	}
    

    static empty(obj: any) {
        if (obj == null) return true;
        if (Array.isArray(obj) || typeof obj === "string") return obj.length === 0;
        for (var key in obj) if (obj.hasOwnProperty(key)) return false;
        return true;
    }

    static getStatusClass (camp: any) {
        var isSent = CampaignModel.isSent(camp);
        var status = parseInt(camp.cv_status);
        let isExported = CampaignModel.isExported(camp);
        
        if (isNaN(status)) {
        status = 0;
        }
        if (isExported || isSent) {
        status = 998;
        }

        switch (status) {
            case 0: return "draft";
            case 1: return "awaiting-approval";
            case 2: return "approved";
            case 3: return "not-approved";
            case 4: return "changes-required";
            case 998: return "completed";
        }

        return "unknown";
    };
}
