import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box, TextField } from "@material-ui/core";
import { useStyles } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../../redux/actions/styleGuideActions";
import {AutoSave} from "../../StyleGuideAutosave";
//import { DefaultStyleGuide } from '../../utilities/DefaultStyleGuide';
import {useState} from 'react';
import {useEffect} from 'react';

interface Props {
  title?:string;
  fieldName:string;
  min?:number;
  max?:number;
  minFieldName?:string;
  maxFieldName?:string;
  label?:string;
}
const TextFiledNumber: React.FC<Props> = ({title, fieldName, min=1, max=70, minFieldName='',maxFieldName='',label=''}) => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.styleGuide);
  const classes = useStyles();
  if(!state[fieldName] || state[fieldName] === undefined){
   dispatch(addField(fieldName, '0'));
   dispatch(addField("styleGuideDefault", true));    
  }  
  const [value,SetValue] = useState(state[fieldName]);
  useEffect(() => {
    SetValue(state[fieldName]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state[fieldName]]);
  const handleSetValue = (inputValue: any) => {
    if (inputValue === "" || inputValue < min) {
      return;
    }
    const valuenew = +inputValue;
    if (valuenew > max) {
      SetValue(max); 
    } else if (valuenew > state[maxFieldName] ) {      
       dispatch(addField(maxFieldName, valuenew));
       SetValue(valuenew); 
    } else if (valuenew < min ) {
      SetValue(min); 
    } else if(valuenew < state[minFieldName]){
      dispatch(addField(minFieldName, valuenew));
      SetValue(valuenew); 
    } else {
      SetValue(valuenew);
    }
  }
  return (         
          <Box>
            {(title) && <Typography variant="subtitle2" className={classes.InputLabel1}>
              {title}
            </Typography>}
            <TextField
              type="number"
              label={label}
              fullWidth
              value={value}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.inputNumber}
              onKeyUp={(e) => {
                if(value !== state[fieldName]){
                  dispatch(addField(fieldName, value));
                  AutoSave();
                }
              }}
              onClick={(e)=>{
                const target = e.target as HTMLInputElement;
                handleSetValue(target.value);
                if(target.value !== state[fieldName]){
                  dispatch(addField(fieldName, target.value));
                  AutoSave();
                }
              }}
              onChange={(e) => {
                handleSetValue(e.target.value);
              }}
            />
          </Box> 
  );
};
export default TextFiledNumber;