import React, { Dispatch, SetStateAction } from "react";
import {
  Dialog,
  TextField,
  Button,
  Box,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AxiosInstance from "../../../../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";

interface Props {
  open: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
  renameTextID:any;
  history?:any;
  setNewImageName:any;
  newImageName:string;
  getImagesByCompInitial:any
}
const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: 350,
  },
  text: {
    margin: "0",
    paddingBottom: "1rem",
    borderBottom: "1px solid #c4c4c4",
  },
  textColor: {
    color: "red",
  },
}));
const RenameFile: React.FC<Props> = ({
  open,
  setOpen,
  renameTextID,
  newImageName,
  setNewImageName,
  history,
  getImagesByCompInitial
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setOpen(false);
    renameCampFile();
  };
  

  
  const renameCampFile = () => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("image_id", renameTextID);
    data.append("new_name", newImageName);

    AxiosInstance.post('api/v1/Contents/rename_gallery_image', data)
     .then((response: AxiosResponse) => {
        if (response.data.status === true) {
          getImagesByCompInitial();
        }
      })
  };
  const handleInputChange = (event: any) => {
    setNewImageName(event.currentTarget.value);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Rename Image</DialogTitle>
        <DialogContent>
        <Box className={classes.root}>
            <TextField
              name="new_name"
              autoFocus
              margin="dense"
              fullWidth
              value={newImageName}
              onChange={handleInputChange}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={handleDelete}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RenameFile;
