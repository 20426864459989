import React from "react";
import { useStyles } from "../../../../../../../styles/styles";
import Card from "@material-ui/core/Card";
import SearchBar from "../../components/SearchBar";
import Header from "../../components/Header";


interface Props {
  querySearch:any;
  renderStyleList:any;
  setQuerySearch:any;
  setSearchStatus:any;
  setsearchStyleStatus:any;
  setListNextPage:any;
  setLoadCamps:any;
  searchStatus:any;
  searchStyleStatus:any;
  handleClickNewCampaign:any;
}


const Sidebarcomponents: React.FC<Props> = ({
  querySearch,
  renderStyleList,
  setQuerySearch,
  setSearchStatus,
  setsearchStyleStatus,
  setListNextPage,
  setLoadCamps,
  searchStatus,
  searchStyleStatus,
  handleClickNewCampaign,
}) => {
  const classes = useStyles();
  return (
  <div>
    <Card elevation={0} style={{ backgroundColor: 'transparent'}}>
    <Header handleClickNewCampaign={handleClickNewCampaign} />
  </Card>
   <Card elevation={0} className={classes.searchComponent} style={{ backgroundColor: 'transparent'}}>
  <SearchBar
    querySearch={querySearch}
    renderStyleList={renderStyleList}
    setQuerySearch={setQuerySearch}
    setSearchStatus={setSearchStatus}
    setsearchStyleStatus={setsearchStyleStatus}
    setListNextPage={setListNextPage}
    setLoadCamps={setLoadCamps}
    searchStatus={searchStatus}
    searchStyleStatus={searchStyleStatus}
/>
   </Card>
   </div>

  );
};

export default Sidebarcomponents;
