import React from "react";
// import LogoSVG from "../../assets/svgs/Svgs";
import { Box, Slide } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import StepButton from "@material-ui/core/StepButton";
import { ArrowForwardIos } from "@material-ui/icons";
import Intregrate from "./integrate/IntegrateApp";
import Profile from "./profile/Profile";
import StyleGuide from "./styleguide/StyleGuidePage";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { LogoMashrmail } from '../../assets/svgs/Svgs';
interface Props {}

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    height: "100vh",
  },
  formGrid: {
    display: "flex",
    justifyContent: "center",
  },
  profileGrid: {
    minHeight: "100vh",
    background: "#ffffff",
  },
  logo: {
    position: "absolute",
    top: "20px",
    left: "20px",
  },
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  bredCrumb: {
    height: "100vh",
    paddingTop: "2em",
  },
  bredCrumbSpan: {},
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  body: {
    backgroundColor: "white",
    margin: 0,
    padding: 0,
  },
  connecter: {
    color: "#9A9AB0",
  },
  stepperContainer: {
    backgroundColor: "white",
    // paddingTop: "2rem",
  },
}));

const getSteps = () => {
  return ["Profile", "Style Guide", "Integrate"];
};

const OnboardRouting: React.FC<Props> = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>(
    {}
  );
  const steps = getSteps();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <Profile handleNext={handleComplete} />;
      case 1:
        return (
          <StyleGuide isNotSettingsPage={true} handleNext={handleComplete} />
        );
      case 2:
        return (
          <Intregrate isNotSettingsPage={true} handleNext={handleComplete} />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="center"
        className={classes.stepperContainer}
      >
        <Stepper
          activeStep={activeStep}
          connector={
            <ArrowForwardIos fontSize="inherit" className={classes.connecter} />
          }
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton
                onClick={handleStep(index)}
                icon={
                  <span
                    style={{
                      background:
                        activeStep === index
                          ? "#057CFF"
                          : index < activeStep
                          ? "#14B8A6"
                          : "#DBD7F4",
                      padding: "6px 10px",
                      borderRadius: "50%",
                      fontStyle: "normal",
                      fontWeight: "bold",
                      fontSize: "16px",
                      letterSpacing: "0.005em",
                      color:
                        activeStep === index || index < activeStep
                          ? "#FFFFFF"
                          : "#8B879F",
                    }}
                  >
                    {index + 1}
                  </span>
                }
              >
                <span
                  style={{
                    color:
                      activeStep === index
                        ? "#057CFF"
                        : index < activeStep
                        ? "#14B8A6"
                        : "#9A9AB0",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "20px",
                    letterSpacing: "0.005em",
                  }}
                >
                  {label}
                </span>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
      <div className={classes.body}>
        <div>
          <div className={classes.instructions}>
            {getStepContent(activeStep)}
          </div>
        </div>
      </div>
    </div>
  );
};

const Onboarding: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Grid container component="main" className={classes.mainRoot}>
        <CssBaseline />
        <Grid item xs={12} sm={12} md={12} component={Paper} square>
          <Box className={classes.logo}>
            <Link to="/">
              <LogoMashrmail />
            </Link>
          </Box>
          <OnboardRouting />
        </Grid>
      </Grid>
    </Slide>
  );
};

export default Onboarding;
