import React, { Dispatch, SetStateAction } from "react";
import {
  Dialog,
  TextField,
  Button,
  Box,
  DialogContent,
  DialogActions,
  DialogTitle,
  Paper,
  Typography
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from "@material-ui/core/styles";
import AxiosInstance from "../../../../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
import { toast } from "react-toastify";
interface Props {
  open: boolean;
  selectedFolderName: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  history: any;
  getloadCampsInitial: any;
  selectedCampId: number;
  brandID: string;
  triggerCampActions:any;
  masterData:any;
  selectedMaster:number;
  setSelectedMaster:any
}


const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  label: {
    fontWeight: "inherit",
    color: "inherit",
    borderRadius: theme.spacing(0.5),
    marginTop: ".3rem",
  },
  
  header: {
    borderBottom: "1px solid #c4c4c4",
    paddingLeft: "0",
    paddingRight: "0",
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
    paddingBottom: ".5rem",
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: 350,
  },
  googleAutocomplete:{
    width: "100% !important",
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child":{
      paddingLeft:40
    },
    "& label.MuiInputLabel-outlined":{
      paddingLeft:30
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink":{
      paddingLeft:0
    }
  },
  googleFontsContainer:{
    paddingLeft:"0 !important",
    paddingTop:23,
    position: "relative",
    display: "flex",
    paddingBottom:10

  },
  textColor: {
    fontSize: '13px',
	color: "#e74c3c",
	marginLeft: '1.5rem',
    marginRight: '1.5rem',
  },
}));
const ConvertVersion: React.FC<Props> = ({
  open,
  setOpen,
  selectedFolderName,
  history,
  getloadCampsInitial,
  selectedCampId,
  brandID,
  triggerCampActions,
  masterData,
  selectedMaster,
  setSelectedMaster
}) => {
  const classes = useStyles();
  const [selectedCvID, setSelectedCvID] = React.useState<number>(0);

  const handleClose = () => {
    setOpen(false);
  };
  
  const updateChangeMaster = () => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("cv_id", selectedCampId);
	data.append("master_cv_id", selectedCvID);
	data.append("cv_camp_id", selectedMaster);
	    
	if(!selectedMaster){
		toast.error('Please select master email');
		return;
	}
	
    AxiosInstance.post('api/v1/Campaign/updateChangeMaster', data)
     .then((response: AxiosResponse) => {
      setOpen(false);
        if (response.data.status === true) {       
          getloadCampsInitial();
        } else
        {
            toast.error('Something went wrong!!! Please try again');
        }
      })
  };
  
	const handleAutoCompleteChange = (e:any, value:any)=>{
		setSelectedMaster(value.cv_camp_id);
		setSelectedCvID(value.cv_id);
	}
	const handleTextChange = (e:any) => {

	}
	const CustomPaper = (props: any) => {
		return <Paper elevation={8} {...props} />;
	};

  
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.header}>
          Select Email Master
        </DialogTitle>
		<Typography className={classes.textColor}> Design system for selected email will be updated</Typography>
		<DialogContent>
		</DialogContent>
        <DialogContent>
          {masterData && (
            <Box className={classes.googleFontsContainer}>
            
			 <Box><SearchIcon style={{ cursor: "pointer", padding: "17px", position: "absolute" }} /></Box>
			 <Autocomplete
				id="combo-box-demo"   
				freeSolo      
				disableClearable     
				options={masterData}
				value=""
				style={{ width: 400 }}
				getOptionLabel={(master) => master['cv_name' as any] || ""}
				className={classes.googleAutocomplete}
				PaperComponent={CustomPaper}
				onChange={handleAutoCompleteChange}
				renderInput={(params) => (
				  <TextField 
					{...params} 
					label="Master Email" 
					placeholder="Search Master Email"
					variant="outlined"
					onChange={handleTextChange}
					/>
				)}
			  />
            </Box>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={updateChangeMaster}
            color="primary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConvertVersion;
