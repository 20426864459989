import AxiosInstance from "../../../../utils/AxiosInstance";
import { toast } from "react-toastify";
import { store } from '../../../redux/store';
import {setFlashMessage} from "../../../../utils/AuthUtility";

export const SaveCustomFontStyleguide = async (styleguide_id:any) => {
    const stateInitial = store.getState();
    const state = stateInitial.styleGuide;
    //console.log(state.RegularNormal.file);
    if(state.styleguideName === '' || state.styleguideName===undefined)
    {
         toast.error('Data not saved! Design system name can not be empty.');
         return false;
    }
    
    var formdata = new FormData();
    const data = localStorage.getItem("loggedInUser")

    let Email = ""
    if (data) {
      const { user_detail } = JSON.parse(data)
      Email = user_detail.Email
    }

    formdata.append(
      "company_id",
      localStorage.getItem("usr_company_id") ||
      localStorage.getItem("company_id") ||
      ""
    );

    formdata.append("styleguide_name", state.styleguideName);
    formdata.append("styleguideId", styleguide_id);

    
    // formdata.append(
    //   "fontName", state.RegularNormal.customName
    // );
    
  //   let allfiles: any = {};
  //   if(Object.keys(state.RegularNormal).length>0)
  //   {
  //       allfiles['RegularNormal_file'] = state.RegularNormal.file;
  //       allfiles['RegularNormal_fontType'] = state.RegularNormal.fontType;
  //   }
  //   if(state.RegularItalic)
  //   {
  //       if(Object.keys(state.RegularItalic).length>0)
  //       {
  //           allfiles['RegularItalic_file'] = state.RegularItalic.file;
  //           allfiles['RegularItalic_fontType'] = state.RegularItalic.fontType;
  //       }
  //   }
   
  //  formdata.append(
  //     "filearr[]", state.RegularNormal.file
  //   );
  //   formdata.append(
  //     "filearr[]", state.RegularItalic.file
  //   );
    
    
     let custom_font_family = 
    { 
     "headline_custom_font" : state?.headline_custom_font ? state?.headline_custom_font : "none",
     "headline_custom_h1" : state?.headline_custom_h1 ? state?.headline_custom_h1 : "none",
     "headline_custom_h2" : state?.headline_custom_h2 ? state?.headline_custom_h2 : "none",
     "headline_custom_h3" : state?.headline_custom_h3 ? state?.headline_custom_h3 : "none",
     "headline_custom_h4" : state?.headline_custom_h4 ? state?.headline_custom_h4 : "none",
     "body_custom_font" : state?.body_custom_font ? state?.body_custom_font : "none",
     "text_custom_body1" : state?.text_custom_body1 ? state?.text_custom_body1 : "none",
     "text_custom_body2" : state?.text_custom_body2 ? state?.text_custom_body2 : "none",
     "text_custom_legal" : state?.text_custom_legal ? state?.text_custom_legal : "none",
     "cta_custom_font" : state?.cta_custom_font ? state?.cta_custom_font : "none",
    };

    formdata.append(
      "custom_font_family" , JSON.stringify(custom_font_family)
    );

    let font_family = {
      "list_custom_fonts" : state?.list_custom_fonts
    }

    formdata.append(
      "font_family" , JSON.stringify(font_family)
    );
    

    formdata.append("Email", Email || "");
    formdata.append("styleguide_name", state.styleguideName);

    await AxiosInstance.post(`api/styleguide/updateCustomfontDetails`, formdata)
      .then((result) => {
          if(result.data.status_code === '404')
          {
                 toast.error('Sorry You are not authorized to perform this action');
                 return false;
          }
          state.custom_font = result.data.custom_font;
          
            let MsgContainer: HTMLDivElement | null = document.querySelector("#FlsMsgAlert");
            if (MsgContainer) {
                MsgContainer.setAttribute("style", "display:block;");
            var dt = new Date();
            if(setFlashMessage(dt.toUTCString()) === undefined)
            {
                MsgContainer.setAttribute("style", "display:inline-block;float:left;margin-top:3px;margin-right:29px");
                MsgContainer.innerHTML = 'All Saved ✔ a few seconds ago';
            }
            else
            {
               
                MsgContainer.innerHTML = 'Last Saved ✔ '+setFlashMessage(dt.toUTCString());
            }
               localStorage.removeItem("LastSavedTime");
               localStorage.setItem('LastSavedTime', dt.toISOString());
            }
            let styleCloseBox: HTMLDivElement | null = document.querySelector("#styleClosebut");
            if (styleCloseBox) {
                styleCloseBox.setAttribute("style", "background:#0a6adc;");
                
            }
            
            // localStorage.setItem('styleGuideResID', result.data.insert_id);
      })
      .catch(function (error: any) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.warn(error.response.data.message);
          }
          if (error.response.status === 500) {
            alert("500, Server Error");
            toast.error(error.response.data.message);
          }
        }
        
      });
  
  
 //toast("Saved Successfully");
}
