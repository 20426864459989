import { Box, Container } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// import UsersList from "./UsersList";
// import Paper from "@material-ui/core/Paper";
import { useEffect, useState } from "react";
import { useStyles } from "../styles";
import TeamsList from "./TeamsList";

interface Props {
  history?: any;
}
const Teams: React.FC<Props> = ({ history }) => {
  const classes = useStyles(); 
  // eslint-disable-next-line
  const [userData, setUserData] = useState<any>({ usertable: [], pending: [] });
  const loadUserData = () => {
  	// AxiosInstance.get('api/v1/Settings/user')
    //   .then((response: AxiosResponse) => {
		//   setUserData(response.data.data)
    //   });
  };
  useEffect(() => {
    // loadUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <Container className={classes.containerTeamList}>
        <TeamsList reloadLists={loadUserData} data={userData.usertable} history={history} />        
      </Container>
    </Box>
  );
};

export default Teams;
