

import { makeStyles } from "@material-ui/core/styles";

export const useStylesMeter = makeStyles((theme) => ({
listIcon: {
    minWidth: "5px",
  },
listDot: {
    color: "#9A9AB0",
    fontSize: "12px", // 15
  },
  listDotFaded: {
    color: "#159f1e",
    fontSize: "12px", //15
  },
  listItem: {
    paddingLeft: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
	height: "17px"
  },
  listText: {
    paddingLeft: "2px",
    "& .MuiTypography-body2": {
      fontSize: "12px",
    },
  },
  listTextColor: {
    color: "#159f1e",
    fontWeight: 400,
  },
  invalid: {
    borderColor: "#dc3545",
   },
	
  strengthmeter: {
	  position: 'relative',
	  height: '3px',
	  background: '#ddd',
	  margin: '7px 0',
	  borderRadius: '2px',
	  "&:before, &:after": {
			content: '""',
			display: "block",
			background: "transparent",
			width: "calc(18% + 6px)",
			height: "inherit",
			position: "absolute",
			borderColor: "#fff",
			borderStyle: 'solid',
			borderWidth: '0 6px 0',
			zIndex: '10'
		},
		"&:before": {
			left: "calc(18% - -4px)",
		},
		"&:after": {
			right: "calc(18% - -3px)",
		},
		
	},
	strengthmeter1: {
	  position: 'relative',
	  height: '3px',
	  background: '#ddd',
	  margin: '7px 0',
	  borderRadius: '2px',
	  "&:before, &:after": {
			content: '""',
			display: "block",
			background: "transparent",
			width: "calc(17% + 6px)",
			height: "inherit",
			position: "absolute",
			borderColor: "#fff",
			borderStyle: 'solid',
			borderWidth: '0 6px 0',
			zIndex: '10'
		},
		"&:before": {
			left: "calc(18% - -1px)",
		},
		"&:after": {
			right: "calc(18% - 2px)",
		},
		
	},
	strengthmeterfill: {
		background: "transparent",
		height: "inherit",
		position: "absolute",
		width: "0",
		borderRadius: "inherit",
		transition: "width 0.5s ease-in-out, background 0.25s",
		'&[data-strength="0"]':{
		  width: '20%',
		  background: 'darkred'
		},
		'&[data-strength="1"]':{
		  width: '40%',
		  background: 'orangered'
		},
		'&[data-strength="2"]':{
		  width: '60%',
		  background: 'orange'
		},
		'&[data-strength="3"]':{
		  width: '80%',
		  background: 'yellowgreen'
		},
		'&[data-strength="4"]':{
		  width: '100%',
		  background: 'green'
		}
	},
}));

