import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box, IconButton } from '@material-ui/core';
import { useStyles } from "../styles";
import ColorPallet from '../utilities/ColorPallet';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch, useSelector } from "react-redux";
import { addField } from '../../../../redux/actions/styleGuideActions';
import { AutoSave } from '../StyleGuideAutosave';

interface Props {}

const MainColorsPallet: React.FC<Props> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reduxState = useSelector((state: any) => state.styleGuide);
const addColorPallet = () => {
  let length = reduxState?.mainColorPallet?.length;
  let lastItem =  reduxState?.mainColorPallet[length - 1];
  let index = 0;
  if(length > 0) index = lastItem["key"].replace('colour_','');
  index++;
  reduxState.mainColorPallet.push({key:"colour_"+index,name:"Colour "+index, value:"#FFFFFF"});
  dispatch(addField("mainColorPallet", reduxState.mainColorPallet));
  AutoSave();
}
                         
  return (
    <Box display="flex" flexDirection="column" className={classes.section}>
      <Typography className={classes.heading} variant="h6">
        Brand Colours
      </Typography>
      <Typography variant="caption" className={classes.caption}>
        Set your main colour pallet for this design system.
      </Typography>
      <Box display="flex" className={`${classes.colorsList} ${classes.mainColorPalletList}`}>
              { reduxState?.mainColorPallet !== undefined ? (reduxState?.mainColorPallet?.map(function(element:any, index: any) { 
                  return(<Box className={classes.flexColumn} key={index} >
                    <ColorPallet index={ "index"+reduxState.styleguideId+"-"+(Math.random() * 1000) } fieldName={element.key} fieldColorName={element.name} fieldColorValue={element.value} />
                    <Typography variant="caption" title={element.name} className={classes.iconButtonLabel}>
                      {element.name}
                    </Typography>
                  </Box>)
                  })) : ""
                }        
        <Box className={classes.flexColumn}>
          <IconButton className={classes.btnAddColorPallet} onClick={addColorPallet} >
            <AddIcon style={{ color: "#C4C4C4" }} />
          </IconButton>
          <Typography variant="caption" className={classes.iconButtonLabel}>            
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MainColorsPallet;
