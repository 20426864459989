
import { toast } from "react-toastify";
import { store } from "../../../../../redux/store";
import AxiosInstance from "../../../../../../utils/AxiosInstance";
// import { setFlashMessage } from "../../../../../../utils/AuthUtility";

export const CustomFontUploadAutosave = async (file:any , fontstyle:string, fontName?:any) => {
    const stateInitial = store.getState();
    const state = stateInitial.styleGuide;
    try {
            const styleId = store.getState().styleGuide.styleguideId;
            const formdata = new FormData();
            formdata.append(
                "company_id",
                localStorage.getItem("usr_company_id") ||
                localStorage.getItem("company_id") ||
                ""
              );
            formdata.append("styleguide_name", state.styleguideName);
            formdata.append("file", file);

            if (styleId) {
                formdata.append("autosave_action", 'save');
                formdata.append("styleguideId", styleId);
            }

            const result = await AxiosInstance.post(`api/styleguide/addNewCustomfont`, formdata);

            if (result.data.status_code === '404') {
                toast.error('Sorry, you are not authorized to perform this action');
                return false;
            }

            return result.data.customFontLink;
        } catch (error:any) {
            if (error.response) {
                if (error.response.status === 400) {
                    toast.warn(error.response.data.message);
                } else if (error.response.status === 500) {
                    toast.error(error.response.data.message);
                }
            } else {
                console.error(error); // Handle other types of errors if needed
            }
            return null; // Return a suitable value to indicate an error
        }
}