import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";
import {
  Typography,
  Button,
  Radio,
  FormControlLabel,
  RadioGroup,
  Box,
} from "@material-ui/core";

interface Props {
  plans: any;
  handleSubmit: any;
}
const useStyles = makeStyles(() => ({
  overrides: {
    MuiTypography: {
      body1: {
        fontWeight: "bold",
      },
    },
  },
  root: {
    flexGrow: 1,
    width: "260px",
    height: "453px",
    marginTop: -11,
    marginLeft: 10,
    fontSize: "14px",
    lineHeight: "20px",
    background: "#FFFFFF",
    border: "1.02495px solid #E2E5E6",
    borderRadius: "4.0998px",
  },
  summary: {
    background: "#EEF1F2",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "12px",
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "20px",
  },
  boldtext: {
    fontWeight: "bold",
    fontStyle: "normal",
    padding: "5px",
    fontSize: "14px",
    lineHeight: "20px",
  },
  priceText: {
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#000000",
    // padding: "5px",
  },
  placeButton: {
    backgroundColor: "#057CFF",
    color: "#FFFFFF",
    width: "100%",
    textTransform: "none",
    boxShadow: '0px 1px 2px rgba(0 0 0 0.05)',
    borderRadius: '2px',
    marginTop: '22px',
    "&:hover": {
      backgroundColor: "#0a6adc"
    }
  },
  mplan: {
    display: "flex",
    flexDirection: "row",
  },
  radiobtn: {
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "10px",
  },
  text1: {
    paddingBottom: "1.5rem",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#5A5A5A",
  },
  text2: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "0.5px solid #A7A7A7",
  },
  cardContent: {
    padding: "15px 20px"
  },
  btnLabel: {
    color: "#111827",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
  },
  terms: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "13px",
    marginTop: "10px",
    color: '#5A5A5A'
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#057CFF",
    // backgroundColor: '#137cbd',
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#057CFF",
    },
  },
  radioBtnRoot: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));
const Summary: React.FC<Props> = ({ plans, handleSubmit }) => {
  const classes = useStyles({});
  const [currentlySelectedPlan, setCurrentlySelectedPlan] = React.useState<
    number | string
  >(1);

  function StyledRadio(props: any) {
    return (
      <Radio
        className={classes.radioBtnRoot}
        disableRipple
        color="default"
        checkedIcon={
          <span className={clsx(classes.icon, classes.checkedIcon)} />
        }
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }
  const loadPlanes = () => {
    if (!plans) return;
    const plansList = [];
    let currentPlan;
    let count = 1;
    while (plans[count]) {
      currentPlan = plans[count];
      plansList.push(
        <FormControlLabel
          value={count}
          control={<StyledRadio />}
          label={
            <label
              className={classes.btnLabel}
              style={{
                color: currentlySelectedPlan === count ? "#057CFF" : "black",
                fontWeight: currentlySelectedPlan === count ? "bold" : "normal"
              }}
            >
              {currentPlan?.sub_name || ""}
              <label style={{ color: "red", fontWeight: 'normal' }}>
                &nbsp;{currentPlan?.save || ""}
              </label>
            </label>
          }
        />
      );
      count++;
    }
    return plansList;
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.summary}>Summary</Typography>
      <div className={classes.cardContent}>
        <RadioGroup
          name="plan"
          value={currentlySelectedPlan}
          onChange={(e) => {
            setCurrentlySelectedPlan(Number(e.target.value));
          }}
          className={classes.radiobtn}
        >
          {loadPlanes()}
        </RadioGroup>
        <Typography className={classes.text1}>
          You will be charged on the 1st of every month from the payment option
          you selected.
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.boldtext}>Monthly Plan</Typography>
          <Box>
            <Typography className={classes.priceText}>
              ${(plans && plans[currentlySelectedPlan]?.price) || ""}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.text2}
        >
          <Box>
            <Typography className={classes.boldtext}>Tax</Typography>
          </Box>
          <Box>
            <Typography className={classes.priceText}>
              ${(plans && plans[currentlySelectedPlan]?.tax) || ""}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.mplan}
        >
          <Box>
            <Typography className={classes.boldtext}>Total</Typography>
          </Box>
          <Box>
            <Typography className={classes.priceText}>
              $
              {plans &&
                plans[currentlySelectedPlan]?.tax &&
                plans[currentlySelectedPlan]?.price
                ? Number(plans[currentlySelectedPlan]?.price) +
                Number(plans[currentlySelectedPlan]?.tax)
                : ""}
            </Typography>
          </Box>
        </Box>
        <Button
          variant="contained"
          type="submit"
          className={classes.placeButton}
        >
          Place Order
        </Button>
        <Typography className={classes.terms}>
          * By placing this order, you agree to the Terms & Conditions set forth
          by MASHR Labs.
        </Typography>
      </div>
    </div>
  );
};
export default Summary;
