import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "95vw",
    height: "95vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paperContainer: {
    width: 200,
    height: 60,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 10,
    paddingLeft: 10,
  },
}));

interface Props {
  history: any;
  match: any;
}

const RedirectSetNewPassword: React.FC<Props> = ({ history, match }) => {
  const { userToken } = match.params;
  const [showLoader] = useState<boolean>(true);
  const classes = useStyles();
  const fetchUser = async () => {
    history.push({
      pathname: "/set-new-password",
      userToken: userToken,
    });
  };
  useEffect(() => {
    fetchUser();
  });
  return (
    <Box className={classes.container}>
      <Paper>
        <Box className={classes.paperContainer}>
          <Typography>
            {" "}
            {showLoader ? "Varifying User..." : "Invalid Token"}
          </Typography>
          {showLoader && <CircularProgress />}
        </Box>
      </Paper>
    </Box>
  );
};

export default RedirectSetNewPassword;
