import React, { useState, useCallback, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "../../styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import InputAdornment from "@material-ui/core/InputAdornment";
import CreateIcon from "@material-ui/icons/Create";
import MoveFile from "./utilities/MoveFile";
import ChangeDesign from "./utilities/ChangeDesign";
import ExportHtmlBox from "../../../../ExportHtml/ExportHtmlBox";
import ConvertVersion from "./utilities/ConvertVersion";
import DeleteFile from "./utilities/DeleteFile";
import DuplicateFile from "./utilities/DuplicateFile";
import InfiniteScroll from "react-infinite-scroll-component";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Iframe from "react-iframe";
import Paper from "@material-ui/core/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { useTheme } from "@material-ui/core/styles";
// import NavigateNext from '@material-ui/icons/NavigateNext';
import AxiosInstance from "../../../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
import moment from "moment";
import CampaignImage from './CampaignImage';
import CampaignModel from '../../../../../../../utils/CampaignModel';
import {isCopywriter, isDesigner, isMarketer, getUserFromToken, redirectTo, isTrial,checkmarketostatus,checkmarketostatusafterload} from "../../../../../../../utils/AuthUtility";
import { toast } from "react-toastify";
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
// import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
// import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton'; 
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import MailjetExportDialog from "../../../../../onboarding/espIntegrations/components/MailjetExportDialog";
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import {Standard,Interactive } from '../../../../../../assets/svgs/SvgDynamic';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import BrazeExportDialog from "../../../../../onboarding/espIntegrations/components/BrazeExportDialog";
import CmExportDialog from "../../../../../onboarding/espIntegrations/components/CampaignMonitorExportDialog";


interface Props {
  fileMapData: {
    cv_id: string;
    cv_camp_id: string;
    cv_name: string;
    cv_date_creation: string;
    cv_updated_date: string;
    cv_status: string;
    cv_camp_monitor_id: string;
    cv_is_avarto: string;
    camp_folders_id: string;
    usr_id: string;
    usr_name: string;
    usr_surname: string;
    c_id: string;
    c_name: string;
    folder_name: string;
    cv_image?: string;
    is_version: any;
    is_duplicated: any;
    cv_is_exported: any;
  }[];
  renderFileList: {
    cv_id: string;
    cv_camp_id: string;
    cv_name: string;
    cv_date_creation: string;
    cv_updated_date: string;
    cv_status: string;
    cv_camp_monitor_id: string;
    cv_is_avarto: string;
    camp_folders_id: string;
    usr_id: string;
    usr_name: string;
    usr_surname: string;
    c_id: string;
    c_name: string;
    folder_name: string;
    cv_image?: string;
    is_version: any;
    is_duplicated: any;
    cv_is_exported: any;
    
  }[];
  getloadCampsLoad: any;
  triggerCampActions: any;
  listCompleteStatus: boolean;
  history: any;
  getloadCampsInitial: any;
  setLoadCamps: any;
  brandID: string;
  setRenderFileList: any;
  setDragOverFolder: any,
  dragOverFolder: any,
  styleFetchData: any,
  setDesignUpated: any,
  connectedesp:any;
}

const FileList: React.FC<Props> = ({
  fileMapData,
  getloadCampsLoad,
  listCompleteStatus,
  history,
  getloadCampsInitial,
  triggerCampActions,
  setLoadCamps,
  brandID,
  setRenderFileList,
  renderFileList,
  setDragOverFolder,
  styleFetchData,
  setDesignUpated,
  connectedesp,
}) => {
  const classes = useStyles();
  // rename section
  const [showRename, setShowRename] = useState(false);
  const [renameText, setRenameText] = useState("");
  const [renamedata, setRenameData] = useState<any>(null);
  const [renameTextID, setRenameTextID] = useState<any>(0);
  const [widthrenameText, setWidthrenameText] = useState<any>(120);
  const [existRenameText, setExistRenameText] = useState("");
  const [dragevent, setdragevent] = useState<any>(1);
  const [emailId, setemailId] = useState("");
  const [EmailProviders, setEmailProviders] = React.useState<any>([]);
  const [mailjetsenderlist, setmailjetsenderlist] = React.useState<any>([]);
  const [mailjetcontactlist, setmailjetcontactlist] = React.useState<any>([]);
 const [FrontEndEspId, setFrontEndEspId] = React.useState<string>("");
  const [FrontendversionId, setFrontendversionId] = React.useState<string>("");
 const [FrontEndcname, setFrontEndcname] = React.useState<string>("");
 const [FrontEndSubject, setFrontEndSubject] = React.useState<string>("");
 const [FrontEndProfile, setFrontEndProfile] = React.useState<string>("");
 const [campaignMonitorlist, setcampaignMonitorlist] = React.useState<any>([]);
 const [campaignMonitortimezone, setcampaignMonitortimezone] = React.useState<any>([]);
 const [campaignMonitorTemplates, setcampaignMonitorTemplates] = React.useState<any>([]);
 const [controller, setcontroller] = React.useState("");
  
  const loggedInUser = getUserFromToken();
  const openRename = (event: any) => {
      setdragevent(0);
    const selectedFolderName = event.currentTarget.id;
    const selectedFolderID = event.currentTarget.value;
    setRenameText(selectedFolderName);
    setExistRenameText(selectedFolderName);
    setRenameTextID(selectedFolderID);
    setWidthrenameText(getTextWidth(selectedFolderName));
     setShowRename(true);
  };
  
    const openExportHtmlfun = (event: any) => {
        setemailId(event.currentTarget.value);
        var FormData = require("form-data");
        setemailId(event.currentTarget.value);
      var data = new FormData();
      data.append("espId",'');
       data.append("controller",'email');
       setcontroller('email');
      AxiosInstance.post("api/v1/Integration/all_email_providers", data).then(
        (response: AxiosResponse) => {
          if (response) {
            if (response.data.data) {
                setEmailProviders(response.data.data.email_providers);
                setOpenExportHtmlDialog(true);
            } else {
               
            }
          } 
        }
      );
      
      handleCloseExport();
  };
  const handleViewChange = (event: any) => {
	setViewFormats(event.target.value);
	var url = new URL(previewURL);
	if(!url.searchParams.has("modeView")){
		url.searchParams.append('modeView', event.target.value);
	}else{
		url.searchParams.set('modeView', event.target.value);
	}
    setPreviewURL(url.toString());
    event.persist();
  };
  const openExportEspfun = (event: any) => {
        setFrontEndcname(event.currentTarget.dataset.cname);
        setFrontEndSubject(event.currentTarget.dataset.csubject);
        setFrontEndProfile(event.currentTarget.dataset.profile);
        const Idvalue = event.currentTarget.value;
        setFrontEndEspId(Idvalue);
        setFrontendversionId(event.currentTarget.id);
            if( event.currentTarget.dataset.pname==='Braze')
            {
                ExportEspfunProcessForBraze(Idvalue);
            } else if(event.currentTarget.dataset.pname==='Campaign Monitor')
            {
                CampaignExportEspfunProcess(Idvalue);
            }
            else {
                ExportEspfunProcess(Idvalue);
            }
        handleCloseExport();

  };
  const ExportEspfunProcessForBraze = (Idvalue:any) => {
       setOpenBrazeDialog(true);
  };
  
  const CampaignExportEspfunProcess = (Idvalue:any) => {
        var FormData = require("form-data");
        var data = new FormData();
        data.append("espId",Idvalue);
        AxiosInstance.post("campaigns/load_camp_monitor_lists", data).then(
        (response: AxiosResponse) => {
            if(response.data)
            {
                if(response.data.status === true)
                {
                    if(response.data.listCM.length>0)
                    {
                        setcampaignMonitorlist(response.data.listCM);
                        setcampaignMonitortimezone(response.data.timezonesCM);
                    var sFormData = require("form-data");
                    var sdata = new sFormData();
                    sdata.append("espId",Idvalue);
                       AxiosInstance.post("api/v1/Integration/load_camp_monitor_templates", sdata).then(
                    (response: AxiosResponse) => {
                        if(response.data.status === true)
                        {
                            if(response.data.data.CmTemplates.length>0)
                            {
                                setcampaignMonitorTemplates(response.data.data.CmTemplates);
                                setOpenCmDialog(true);
                                  
                            } 
                        } else {
                            toast.error('Campaign Monitor Template list not found');
                        }
                    }
                     );
                    
                } else{
                    toast.error('Campaign Monitor list not found');
                }
                    
                } else {
                    toast.error('Mailjet Sender list not found');
                }
            }
        }
        );
  };
  
  const ExportEspfunProcess = (Idvalue:any) => {
        var FormData = require("form-data");
        var data = new FormData();
        data.append("espId",Idvalue);
        AxiosInstance.post("api/v1/Integration/getMailjetSenderlist", data).then(
        (response: AxiosResponse) => {
            if(response.data.data)
            {
                if(response.data.status === true)
                {
                    setmailjetsenderlist(response.data.data.mailjetsenderlist);
                    var sFormData = require("form-data");
                    var sdata = new sFormData();
                    sdata.append("espId",Idvalue);
                    AxiosInstance.post("api/v1/Integration/getMailjetContatlist", sdata).then(
                    (response: AxiosResponse) => {
                        if(response.data.status === true)
                        {
                            if(response.data.data.mailjetcontatlist.length>0)
                            {
                                  setmailjetcontactlist(response.data.data.mailjetcontatlist);
                                  setOpenMailjetDialog(true);
                            } 
                        } else {
                            toast.error('Mailjet Contatlist list not found');
                        }
                    }
                    );
                } else {
                    toast.error('Mailjet Sender list not found');
                }
            }
        }
        );
  };
  
  
  const getTextWidth = (text:string): any => {
        const div = document.createElement('div');
        div.style.cssText = "display: inline-block; visibility: hidden;font-size:1.12rem";
        div.innerText = text;
        const element = window.document.getElementById("rename-campaign-text");
        element?.appendChild(div);
        var width = div.getBoundingClientRect().width;
        element?.removeChild(div);
      return width + 30;
  }
  
  const closeRename = () => {
    setShowRename(false);
    if (existRenameText !== renameText) {
      renameCampFolder();
    }
  };

  const onKeyDownHandler = (e:any) => {
    if (e.keyCode === 13) {
      setShowRename(false);
    if (existRenameText !== renameText) {
      renameCampFolder();
    }
    }
  };
  
   const onBlurDownHandler = (e:any) => {
      setShowRename(false);
    if (existRenameText !== renameText) {
      renameCampFolder();
    }
    setdragevent(1);
  };
  
  
 

  const renameCampFolder = () => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("camp_id", renameTextID);
    data.append("camp_name", renameText);
    data.append("isCopywriter",isCopywriter());
     setdragevent(1);

    AxiosInstance.post('api/v1/campaigns_ajax/update_camp_name', data)
    	.then((response: AxiosResponse) => {
			if (response.data.status === true) {
			  renderFileList[renamedata.rowIndex].cv_name =
				response.data.data.camp_name;
			  setRenderFileList([...renderFileList]);
			} else
            {
                toast.error(response.data.response_msg);
            }
    	})
       
  };

  // move to folder section
  const [openMoveDialog, setOpenMoveDialog] = useState<boolean>(false);
  const [openChangeDesignDialog, setOpenChangeDesignDialog] = useState<boolean>(false);
  const [OpenExportHtmlDialog, setOpenExportHtmlDialog] = useState<boolean>(false);
  const [openConvertVersionDialog, setOpenConvertVersionDialog] = useState<boolean>(false);
  const [selectedFolderName, setSelectedFolderName] = useState("");
  const [selectedCampId, setSelectedCampId] = useState<number>(0);
  const [selectedDesign, setSelectedDesign] = useState("");
  const [masterData, setMasterData] = React.useState<any>([]);
  const [OpenMailjetDialog, setOpenMailjetDialog] = useState<boolean>(false);
  const [OpenBrazeDialog, setOpenBrazeDialog] = useState<boolean>(false);
  const [OpenCmDialog, setOpenCmDialog] = useState<boolean>(false);

  
  const openMoveFunc = (event: any) => {
    setSelectedFolderName(event.currentTarget.id);
    setSelectedCampId(event.currentTarget.dataset.id);
    setOpenMoveDialog(true);
  };
  const openChangeDesignFunc = (event: any) => {
	setSelectedFolderName(event.currentTarget.id);
	//if(styleFetchData)
	
	var desgin='';
	if(!event.currentTarget.dataset.design){
		if(styleFetchData.length >0){
			desgin = styleFetchData[1].id;
		}
	}else{
		desgin = event.currentTarget.dataset.design;
	}
	if(desgin === '0'){
		desgin ='';
	}
    setSelectedCampId(event.currentTarget.dataset.id);

	setSelectedDesign(desgin);
    setOpenChangeDesignDialog(true);
  };
  const openConvertVersionFunc = (event: any) => {
	setSelectedFolderName(event.currentTarget.id);
    setSelectedCampId(event.currentTarget.dataset.id);
	setSelectedMaster(0);
	AxiosInstance.get('api/v1/Campaign/get_master', {
		params: {
			cv_id: event.currentTarget.dataset.id,
		},
	}).then((response: AxiosResponse) => {
			setMasterData(response.data.data)
			setOpenConvertVersionDialog(true);
    	});
    
  };
  

  // delete releted functions
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [selectedDuplicateCampaign, setSelectedDuplicateCampaign] = useState<number>(0);
  const [openDuplicateDialog, setOpenDuplicateDialog] = useState<boolean>(false);
  const [deleteCampaignID, setDeleteCampaignID] = useState<number>(0);
  const [isVersion, setIsVersion] = useState<number>(0);
  const [campRelatedID, setCampRelatedID] = useState<number>(0);
  const [selectedMaster, setSelectedMaster] = useState<number>(0);

  const openDelete = (event: any) => {
	const selectedCampaignID = event.currentTarget.id;
    setIsVersion(getIsVersionbyCampId(selectedCampaignID));
    setCampRelatedID(getCampRelatedIDbyCampId(selectedCampaignID));
    setDeleteCampaignID(selectedCampaignID);
    setOpenDeleteDialog(true);
  };

  const getIsVersionbyCampId = (camp_id:any) => {
    var is_version = 0;
    renderFileList?.forEach((camp: any, index: any) => {       
      if(camp.cv_id === camp_id){
        is_version = camp.is_version;
      }
    });
    return is_version;
  }

  const getCampRelatedIDbyCampId = (camp_id:any) => {
    var camp_Related_ID = 0;
    renderFileList?.forEach((camp: any, index: any) => {       
      if(camp.cv_id === camp_id){
        camp_Related_ID = camp.cv_camp_id;
      }
    });
    return camp_Related_ID;
  }


  const handleCampaignDelete = () => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("camp_id", deleteCampaignID);
    data.append("is_version", isVersion);
    data.append("camp_related_id", campRelatedID);
    data.append("isCopywriter",isCopywriter());

    AxiosInstance.post('api/v1/campaigns_ajax/delete_campaign', data)
     .then((response: AxiosResponse) => {
        if (response.data.status === true) {
          triggerCampActions('delete', {
          	campToDelete: deleteCampaignID
		  });
		  
        } else
        {
            toast.error(response.data.response_msg);
        }
      })
  };

  // till date time calculation for the list
  const calculateNotificationArrivalTime = (createdDateString: string) => {
	  return moment(createdDateString).add(-(new Date().getTimezoneOffset()), 'minutes').fromNow()
  };
  const padTo2Digits = (num:number) => {
    return num.toString().padStart(2, '0');
  }
  const formatDateWithSlach = (dateString:string) => {
    const date = new Date(dateString);
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }
  // duplicate releted functions
  const handleDuplicate = (event: any) => {
    const cvId = event.currentTarget.id;
	const campID = event.currentTarget.dataset.campid;
	setSelectedDuplicateCampaign(cvId);
	const copy_type = event.currentTarget.dataset.copytype;
	let MsgContainer: NodeListOf<HTMLElement> | null = document.querySelectorAll('.cardchildclass_'+campID);
	if(MsgContainer.length>0){
		setOpenDuplicateDialog(true);
	}else{
		duplicateCampaign(cvId,copy_type);
	}

  };
  const handleVersion = (event: any) => {
    const campID = event.currentTarget.id;
	setSelectedDuplicateCampaign(campID);
	const copy_type = event.currentTarget.dataset.copytype;
	duplicateCampaign(campID,copy_type);

  };
  
  const saveMashrmailTemplate = (event: any) => {
    const selectedCampaign = event.currentTarget.id;
    var FormData = require("form-data");
    var data = new FormData();
    data.append("version_id", selectedCampaign);
    data.append("isCopywriter",isCopywriter());
    data.append("templatefor", 'mashrmail');
    data.append("newDate", new Date());

    AxiosInstance.post('api/v1/Campaign/create_template_from_campaign', data)
     .then((response: AxiosResponse) => {
        if (response.data.status === true) {
          toast.success('Template successfully created')
        } else
        {
            toast.error(response.data.response_msg);
        }
     })
  };
  
    const saveMarketoTemplate = (event: any) => {
    const selectedCampaign = event.currentTarget.id;
    var FormData = require("form-data");
    var data = new FormData();
    data.append("version_id", selectedCampaign);
    data.append("isCopywriter",isCopywriter());
    data.append("templatefor", 'marketo');
    data.append("newDate", new Date());

    AxiosInstance.post('api/v1/Campaign/create_template_from_campaign', data)
     .then((response: AxiosResponse) => {
        if (response.data.status === true) {
          toast.success('Template successfully created')
        } else
        {
            toast.error(response.data.response_msg);
        }
     })
  };
  
  

  const duplicateCampaign = (selectedCampaign: number,copy_type: string ) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("version_id", selectedCampaign);
    data.append("isCopywriter",isCopywriter());
	data.append("copy_type",copy_type);

    AxiosInstance.post('api/v1/Campaign/duplicateCamp', data)
     .then((response: AxiosResponse) => {
        if (response.data.status === true) {
			var data1 = new FormData();
			data1.append("dir", response.data.dir);
			AxiosInstance.post('api/v1/campaign/delete_init', data1).then((resp: AxiosResponse) => {
				triggerCampActions('duplicate', {
					camp: response.data.camp,
					beforeIndex: selectedCampaign
				});
				if(response.data.copy_type === 'duplicateall'){
					setLoadCamps([]);
					getloadCampsInitial();
				}
			});
        } else
        {
            toast.error(response.data.response_msg);
        }
     })
  };

  // move to archive functions
  const openMoveArchive = (event: any) => {
      if(!isCopywriter())
      {
        const selectedCampaign = event.currentTarget.id;
        setLoadCamps([]);
        setRenderFileList([]);
        moveToArchiveCampaign(selectedCampaign);
      } else { 
      toast.error('You are not authorized to perform this action');
      }
  };



  const moveToArchiveCampaign = (selectedCampaign: number) => {
      
    var FormData = require("form-data");
    var data = new FormData();
    data.append("camp_id", selectedCampaign);
    data.append("folder_id", "-1");
    data.append("isCopywriter",isCopywriter());

    AxiosInstance.post('api/v1/Campaign/moveCampToFolder', data)
     .then((response: AxiosResponse) => {
        if (response.data.status === true) {
          getloadCampsInitial();
        } else
        {
            toast.error(response.data.response_msg);
        }
      })
  };

  // preview section
  const [openPreview, setOpenPreview] = React.useState(false);
  const [viewMenu, setViewMenu] = React.useState(false);
  const [viewMenuList, setViewMenuList] = React.useState("");
  const [previewURL, setPreviewURL] = React.useState("");
  const [previewName, setPreviewName] = React.useState("");
  // eslint-disable-next-line
  const [darkURL, setDarkURL] = React.useState("");
  // eslint-disable-next-line
  const [lightURL, setLightURL] = React.useState("");
  const [isDark, setIsDark] = React.useState(false);

  const handleDragStart = useCallback((e: any, option: any,dragevent:any) => {
    e.dataTransfer.setData("cv_camp_id", option.cv_id);
    e.dataTransfer.setData("dragable_event", dragevent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleDragEnd = useCallback((e: any) => {
    setDragOverFolder(null)
  }, [setDragOverFolder])

  const handleClickOpenPreview = (event: any) => {
    const selectedCampaignID = event.currentTarget.id;
    setPreviewName(event.currentTarget.dataset.id);
    var FormData = require("form-data");
    var data = new FormData();
    data.append("campaign_code", 30);
    data.append("version_id", selectedCampaignID);
    data.append("export_esp", false);
    document.body.setAttribute("oncontextmenu","return false;");

    AxiosInstance.post('api/v1/Campaign/campaign_screenshot', data)
     .then((response: AxiosResponse) => {
        if (response.data.status === true) {
          setPreviewURL(response.data.temp_url);
		  if(response.data.viewenabled === true){
			setViewMenu(true);
			setViewMenuList(response.data.viewmode);
			setViewFormats(response.data.viewmode);
			var url = new URL(response.data.temp_url);
			if(!url.searchParams.has("modeView")){
				url.searchParams.append('modeView', response.data.viewmode);
			}else{
				url.searchParams.set('modeView', response.data.viewmode);
			}
			setPreviewURL(url.toString());
		  }
          setOpenPreview(true);
          setDarkURL(response.data.data+"?modePreview=dark");
          setLightURL(response.data.data);
          if(response?.data?.is_dark) setIsDark(response.data?.is_dark);
        }
      })
  };


  const [mode, setMode] = React.useState("light");
  const handleMode = (event:any, newFormats:any) => {
    var url = new URL(previewURL);
    if (newFormats !== null) {
      setMode(newFormats);
      if(newFormats === "dark"){ 
        if(!url.searchParams.has("modePreview")){
          url.searchParams.append('modePreview', 'dark');
        } else{
          url.searchParams.set('modePreview', 'dark');
        }
          setPreviewURL(url.toString());

        // setPreviewURL(darkURL);
      } else  {
          if(url.searchParams.has("modePreview")){
            url.searchParams.delete("modePreview");
          }
        setPreviewURL(url.toString());
          // setPreviewURL(lightURL);
        }
    }
  };




  const handleClosePreview = () => {
    setOpenPreview(false);
	setViewMenu(false);
	setViewMenuList("");
    document.body.removeAttribute("oncontextmenu");
    setFormats("desktop");

    setMode("light");
	setViewFormats('standard');

  };

  const [formats, setFormats] = React.useState("desktop");
  const [viewFormats, setViewFormats] = React.useState("standard");
  const handleFormat = (event:any, newFormats:any) => {
    if (newFormats !== null) {
      setFormats(newFormats);
    }
  };

  // const theme = useTheme();
  const isScreenLg = useMediaQuery('(min-width: 1800px)');

  const [SubMenuExportAnchorEl, setSubMenuExportAnchorEl] = useState<null | HTMLElement>(null);
  const [SubMenuExportAnchorE2, setSubMenuExportAnchorE2] = useState<null | HTMLElement>(null);

  const handleSubMenuExportClick = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation(); // Prevent click event from propagating to parent
    setSubMenuExportAnchorEl(SubMenuExportAnchorEl ? null : event.currentTarget);
  };

  const handleCloseExport = () => {
    setSubMenuExportAnchorEl(null);
  };

  const handleMenuMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation(); // Prevent onMouseEnter event from propagating to MenuItem
    setSubMenuExportAnchorEl(event.currentTarget);
  };

  const handleMenuMouseLeave = () => {
    setSubMenuExportAnchorEl(null);
  };
  
  
  
  
  const actionSubMenuExportClick = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation(); // Prevent click event from propagating to parent
    setSubMenuExportAnchorE2(SubMenuExportAnchorE2 ? null : event.currentTarget);
  };

  const actionCloseExport = () => {
    setSubMenuExportAnchorE2(null);
  };

  const actionMenuMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation(); // Prevent onMouseEnter event from propagating to MenuItem
    setSubMenuExportAnchorE2(event.currentTarget);
  };

  const actionMenuMouseLeave = () => {
    setSubMenuExportAnchorE2(null);
  };
  
  
  
useEffect(()=>{
   
	  setcontroller('email');
  // eslint-disable-next-line
  },[viewFormats]);
  return (
    <>
      <div id="rename-campaign-text" className={classes.renameCampaign} ></div>
      <ExportHtmlBox
        open={OpenExportHtmlDialog}
        setOpen={setOpenExportHtmlDialog}
        EmailProviders={EmailProviders}
        emailId={emailId}
         controller={controller}
        
      />
      <MailjetExportDialog
        open={OpenMailjetDialog}
        setOpen={setOpenMailjetDialog}
        mailjetsenderlist={mailjetsenderlist}
        mailjetcontactlist={mailjetcontactlist}
        FrontEndEspId={FrontEndEspId}
        FrontendversionId={FrontendversionId}
        FrontEndcname={FrontEndcname}
        FrontEndSubject={FrontEndSubject}
        
      />

      <BrazeExportDialog
        open={OpenBrazeDialog}
        setOpen={setOpenBrazeDialog}
        FrontEndEspId={FrontEndEspId}
        FrontendversionId={FrontendversionId}
        FrontEndcname={FrontEndcname}
        FrontEndSubject={FrontEndSubject}
        FrontEndProfile={FrontEndProfile}
      />
      
      <CmExportDialog
        open={OpenCmDialog}
        setOpen={setOpenCmDialog}
        campaignMonitorlist={campaignMonitorlist}
        campaignMonitortimezone={campaignMonitortimezone}
        campaignMonitorTemplates={campaignMonitorTemplates}
        FrontEndEspId={FrontEndEspId}
        FrontendversionId={FrontendversionId}
        FrontEndcname={FrontEndcname}
        FrontEndSubject={FrontEndSubject}
        
      />
	  <ChangeDesign
        open={openChangeDesignDialog}
        selectedFolderName={selectedFolderName}
        setOpen={setOpenChangeDesignDialog}
        history={history}
        getloadCampsInitial={getloadCampsInitial}
        selectedCampId={selectedCampId}
        brandID={brandID}
        triggerCampActions={triggerCampActions}
		styleFetchData={styleFetchData}
		selectedDesign={selectedDesign}
		setDesignUpated={setDesignUpated}
      />
	  <ConvertVersion
        open={openConvertVersionDialog}
		selectedFolderName={selectedFolderName}
		setOpen={setOpenConvertVersionDialog}
        history={history}
        getloadCampsInitial={getloadCampsInitial}
        selectedCampId={selectedCampId}
        brandID={brandID}
        triggerCampActions={triggerCampActions}
		masterData={masterData}
		selectedMaster={selectedMaster}
		setSelectedMaster={setSelectedMaster}

      />
      <MoveFile
        open={openMoveDialog}
        selectedFolderName={selectedFolderName}
        setOpen={setOpenMoveDialog}
        history={history}
        getloadCampsInitial={getloadCampsInitial}
        selectedCampId={selectedCampId}
        brandID={brandID}
        triggerCampActions={triggerCampActions}
      />
      <DeleteFile
        handleCampaignDelete={handleCampaignDelete}
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        isVersion={isVersion}
      />
	  <DuplicateFile
        duplicateCampaign={duplicateCampaign}
        open={openDuplicateDialog}
        setOpen={setOpenDuplicateDialog}
        isVersion={isVersion}
		selectedDuplicateCampaign={selectedDuplicateCampaign}
      />
      <InfiniteScroll
        dataLength={renderFileList.length}
        next={getloadCampsLoad}
        hasMore={listCompleteStatus}
        loader={<LinearProgress />}
        height={`${localStorage.getItem("trial_mode") === 'true' ? "calc(100vh - 341px)": "calc(100vh - 285px)"}`}
      >
        <div>
          {renderFileList.map((option: any, index: any) => {
            return (
              (option.is_version === "0") ? (
              <div key={option.id} className ={classes.campGroupContainer} draggable={((isDesigner() || isMarketer()) && dragevent===1 && isTrial() === false) ? true : false}
                        onDragStart={(e) => handleDragStart(e, option,dragevent)}
                        onDragEnd={(e) => handleDragEnd(e)}>
                <Card elevation={0} className ={classes.campMaster}  >
                  <Grid container alignItems="center">
                    <Grid item className={classes.GridcardImage} >
                      <CampaignImage
                        thumb={option.cv_image}
                        classes={classes.cardImage}
                        campId={option.cv_id}
                      />
                    </Grid>
                    <Grid
                      item
                      className={classes.boxBody}
                    >
                      <div className={classes.boxItemTitle}
                      >
                        {showRename && String(option.cv_id) === String(renameTextID) ? (
                         <ClickAwayListener onClickAway={onBlurDownHandler}>
                            <Input
                              className={classes.fileEdit}
                              style={{width:widthrenameText}}
                              id={index}
                              onKeyDown={onKeyDownHandler}
                              onBlur={onBlurDownHandler}
                              value={renameText}
                              onChange={(e) => {
                                setRenameData({
                                  text: e.currentTarget.value,
                                  rowIndex: e.currentTarget.id,
                                });
                                setRenameText(e.currentTarget.value);
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <CreateIcon
                                    className={classes.fileEditIcon}
                                    onClick={closeRename}
                                  />
                                </InputAdornment>
                              }
                            />
                            </ClickAwayListener>
                        
                        ) : (
                          <Typography
                            variant="subtitle2"
                          >
                            <a href={`${window.globalConfig.apiOrigin}${window.globalConfig.subDir && window.globalConfig.subDir.length ? ('/' + window.globalConfig.subDir) : ''}campaigns/build/${option.cv_id}`} className={classes.boxItemA}>
                                 {option.cv_name}
                            </a>
                          </Typography>
                        )}
                        <Typography
                            component="p"
                            className={`${classes.boxDescription} ${classes.statusTypography}`}
                          >
                          <Typography component="p" className={`${classes.statusSpan} ${CampaignModel.getStatusClass(option)}`}  >
                                {CampaignModel.getStatus(option)}
                            </Typography>
                        </Typography>
                      </div>

                      <div>
                        <Box
                          display="flex"
                          alignItems="center"
                          flex={1}
                        >
                          <Box className={classes.campListInfo}>
                            <Typography
                              component="p"
                              className={classes.boxHeader}
                            >
                              Created By
                            </Typography>
                            {option.usr_name ? (
                              <Typography
                                component="p"
                                className={`${classes.boxDescription} ${classes.createdBY}`}
                                title={`${option.usr_name} ${option.usr_surname}`}
                              >
                                {option.usr_name} {option.usr_surname}
                              </Typography>
                            ) : (
                              <Typography
                                component="p"
                                className={`${classes.boxDescription} ${classes.createdBY}`}
                              >
                                Unknown
                              </Typography>
                            )}
                          </Box>
                          <Box className={classes.campListInfo}>
                            <Typography
                              component="p"
                              className={classes.boxHeader}
                            >
                              CREATED DATE
                            </Typography>
                            <Typography component="p" className={`${classes.boxDescription} ${classes.createDateCamp}`} >                              
                                {formatDateWithSlach(option.cv_date_creation)}
                            </Typography>
                          </Box>
                          <Box className={classes.campListInfoLast}>
                            <Typography
                              component="p"
                              className={classes.boxHeader}
                            >
                              LAST UPDATE
                            </Typography>
                            <Typography
                              component="p"
                              className={`${classes.boxDescription} ${classes.lastUpdate}`}
                            >
                              {calculateNotificationArrivalTime(
                                option.cv_updated_date
                              )}
                            </Typography>
                          </Box>
                          <Box className={classes.campListInfo}>
                            <Typography
                              component="p"
                              className={classes.boxHeader}
                            >
                              FOLDER
                            </Typography>
                            {option.folder_name ? (
                              <Typography
                                component="p"
                                className={classes.boxDescription}
                                title={option.folder_name}
                              >
                                {option.folder_name}
                              </Typography>
                            ) : ((option.camp_folders_id === "-1"? (
                                <Typography
                                  component="p"
                                  className={classes.boxDescription}
                                >
                                  Archive
                                </Typography>
                            ) : (
                                <Typography
                                  component="p"
                                  className={classes.boxDescription}
                                >
                                  -
                                </Typography>
                            ))
                             
                            )}
                          </Box>
                          
                           <Box className={classes.campListInfo}>
                            <Typography
                              component="p"
                              className={classes.boxHeader}
                            >
                              DESIGN SYSTEM   
                            </Typography>
                            {option.styleguide_name ? (
                              <Typography
                                component="p"
                                className={`${classes.boxDescription} ${classes.styleguideName}`}
                                title={option.styleguide_name}
                              >
                                {option.styleguide_name}
                              </Typography>
                            ) : (
                              <Typography
                                component="p"
                                className={`${classes.boxDescription} ${classes.styleguideName}`}
                              >
                                -
                              </Typography>
                            )}
                          </Box>
                          
                          
                        </Box>
                      </div>
                    </Grid>
                    <Grid item className={classes.threedotcamp}>
                      <PopupState
                        variant="popover"
                        popupId="demo-popup-menu"
                      >
                        {(popupState: any) => (
                          <React.Fragment>
                            <MoreVertIcon
                              className={classes.dotIcon}
                              {...bindTrigger(popupState)}
                            />
                            <Menu {...bindMenu(popupState)}
                              getContentAnchorEl={null}
                              anchorOrigin={{
                                vertical: 30,
                                horizontal: -60
                              }}
                            >
                              <div>
                                <MenuItem
                                  onClick={() => {
                                    const data = option;
                                    const editLink = `${window.globalConfig.apiOrigin}${window.globalConfig.subDir && window.globalConfig.subDir.length ? ('/' + window.globalConfig.subDir) : ''}/campaigns/build/${data.cv_id}`;

                                    window.location.href = editLink;
                                  }}
                                >
                                  <Typography>Edit</Typography>
                                </MenuItem>
                              </div>
                              <div onClick={popupState.close}>
                                <MenuItem
                                  id={option.cv_id}
                                  data-id={option.cv_name}
                                  onClick={handleClickOpenPreview}
                                >
                                  <Typography>Preview</Typography>
                                </MenuItem>
                              </div>
                              {(!isCopywriter()) && (
                              <div onClick={popupState.close}>
                                <MenuItem
                                  id={option.cv_id}
								  data-campid={option.cv_camp_id}
								  data-copytype="duplicate"
								  onClick={handleDuplicate}
								  disabled={isTrial()}
                                >
                                  <Typography>Duplicate</Typography>
                                </MenuItem>
                              </div>
                              )}
							  {(!isCopywriter()) && (option.is_version ==='0') && (
                              <div onClick={popupState.close}>
                                <MenuItem
                                  id={option.cv_id}
								  data-copytype="version"
                                  onClick={handleVersion}
								  disabled={isTrial()}
                                >
                                  <Typography>Create Version</Typography>
                                </MenuItem>
                              </div>
                              )}
                               
                                {(checkmarketostatus() === true || checkmarketostatusafterload()===true)? (
                                <div
                               style={{display:!isCopywriter()? 'block': 'none'}}
                                onMouseEnter={actionMenuMouseEnter}
                                onMouseLeave={actionMenuMouseLeave}
                                >
                                <MenuItem onClick={actionSubMenuExportClick}>
                                 <Box flex="1">Save as Template</Box> {/*<NavigateNext fontSize="small" /> */}
                                </MenuItem>
                                <Menu
                                id="sub-menu-export1"
                                anchorEl={SubMenuExportAnchorE2}
                                className={classes.subMenuExport}
                                keepMounted
                                open={Boolean(SubMenuExportAnchorE2)}
                                onClose={actionCloseExport}
                                style={{ position: "unset" }}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: isScreenLg ? "right" : "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: isScreenLg ? "left" : "right",
                                }}
                                >
                                <div onClick={popupState.close}>
                                <MenuItem
                                    id={option.cv_id}
                                    value={option.cv_id}
                                    onClick={saveMashrmailTemplate}
                                    disabled={isTrial()}
                                  >
                                    <Typography>MashrMail Template</Typography>
                                  </MenuItem>
                                    <MenuItem
                                    id={option.cv_id}
                                    value={option.cv_id}
                                    onClick={saveMarketoTemplate}
                                    disabled={isTrial()}
                                    >
                                    <Typography>Marketo Template</Typography>
                                    </MenuItem>
                                   
                                  
                                </div>
                                </Menu>
                                </div>
                                ) : (
                                <div style={{display:!isCopywriter()? 'block': 'none'}} onClick={popupState.close}>
                                <MenuItem
                                id={option.cv_id}
                                    value={option.cv_id}
                                    onClick={saveMashrmailTemplate}
                                    disabled={isTrial()}
                                >
                                <Typography>Save as Template</Typography>
                                </MenuItem>
                                </div>
                                )}
                              
                              {/* <div onClick={popupState.close}>
                                <MenuItem
								  id={option.cv_name}
                                  value={option.cv_id}
                                  onClick={openExportHtmlfun}
								  disabled={isTrial()}
                                >
                                  <Typography>Export</Typography>
                                </MenuItem>
                                </div> */}
                                <div
                                  onMouseEnter={handleMenuMouseEnter}
                                  onMouseLeave={handleMenuMouseLeave}
                                >
                                  <MenuItem onClick={handleSubMenuExportClick}>
                                     <Box flex="1">Export</Box> {/*<NavigateNext fontSize="small" /> */}
                                  </MenuItem>
                                  <Menu
                                    id="sub-menu-export"
                                    anchorEl={SubMenuExportAnchorEl}
                                    className={classes.subMenuExport}
                                    keepMounted
                                    open={Boolean(SubMenuExportAnchorEl)}
                                    onClose={handleCloseExport}
                                    style={{ position: "unset" }}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: isScreenLg ? "right" : "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: isScreenLg ? "left" : "right",
                                    }}
                                  >
                                    <div onClick={popupState.close}>
                                    <MenuItem
                                        id={option.cv_name}
                                        value={option.cv_id}
                                        onClick={openExportHtmlfun}
                                        disabled={isTrial()}
                                      >
                                        <Typography>Export As Html</Typography>
                                      </MenuItem>
                                        {connectedesp.map((Item: any, index: any) => {
                                        return <MenuItem
                                        id={option.cv_id}
                                        value={Item.pc_id}
                                        data-cname={option.cv_name}
                                        data-csubject={option.cv_email_subject}
                                        data-profile={option.cv_email_preview}
                                        data-pname={Item.p_name}
                                        onClick={openExportEspfun}
                                        disabled={isTrial()}
                                        >
                                        <Typography>{Item.pc_assign_name}</Typography>
                                        </MenuItem>
                                        })}
                                    </div>
                                  </Menu>
                                </div>
                              
                              {/* <div onClick={popupState.close}>
                               
                              </div> */}
                              
							  {(!isCopywriter()) && (
                              <div onClick={popupState.close}>
                                <MenuItem
                                  id={option.cv_name}
                                  value={option.cv_id}
                                  onClick={openRename}
								  disabled={isTrial()}
                                >
                                  <Typography>Rename</Typography>
                                </MenuItem>
                              </div>
							  )}

							  {(!isCopywriter()) && (
                              <div onClick={popupState.close}>
                                <MenuItem
								  id={option.cv_name}
                                  data-id={option.cv_id}
								  data-design={option.styleguide_id}
                                  onClick={openChangeDesignFunc}
								  disabled={isTrial()}
                                >
                                  <Typography>Update Design System</Typography>
                                </MenuItem>
                              </div>
                              )}
							  {(!isCopywriter() && option.totalchilds <=1) && (
                              <div onClick={popupState.close}>
                                <MenuItem
								  id={option.cv_name}
                                  data-id={option.cv_id}
								  disabled={isTrial()}
								  data-design={option.styleguide_id}
                                  onClick={openConvertVersionFunc}
                                >
                                  <Typography>Convert to Version</Typography>
                                </MenuItem>
                              </div>
                              )}

                              {(!isCopywriter()) && (
                              <div onClick={popupState.close}>
                                <MenuItem
                                  disabled={isTrial()}
                                  id={option.cv_name}
                                  data-id={option.cv_id}
                                  onClick={openMoveFunc}
                                >
                                  <Typography>Move to Folder</Typography>
                                </MenuItem>
                              </div>
                              )}


                                {(!isCopywriter()) && (
                              <div onClick={popupState.close}>
                                <MenuItem
                                  id={option.cv_id}
                                  onClick={openDelete}
                                  className={classes.deleteItem}
								  disabled={isTrial()}
                                >
                                  <Typography>Delete</Typography>
                                </MenuItem>
                              </div>
                                )}
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </Grid>
                  </Grid>
                </Card>
                {renderFileList.map((optionnew: any, indexnew: any) => {
                return (option.cv_camp_id === optionnew.cv_camp_id && optionnew.is_version === "1") ? (
                  <Card key={optionnew.id} elevation={0} className={classes.campChild + " cardchildclass_"+option.cv_camp_id } draggable={((isDesigner() || isMarketer()) && dragevent===1 && isTrial() === false) ? true : false} 
                  onDragStart={(e) => handleDragStart(e, option,dragevent)}
                        onDragEnd={(e) => handleDragEnd(e)}
                  >
                    <Grid container alignItems="center">
                      <Grid item className={classes.GridcardImage}>
                        <CampaignImage
                          thumb={optionnew.cv_image}
                          classes={classes.cardImage}
                          campId={optionnew.cv_id}
                        />
                      </Grid>
                      <Grid
                        item
                        className={classes.boxBody}
                      >
						<div className={classes.boxItemTitle}
                          onDragStart={(e) => handleDragStart(e, optionnew,dragevent)}
                          onDragEnd={(e) => handleDragEnd(e)}
                        >
                          {showRename && String(optionnew.cv_id) === String(renameTextID) ? (
                           <ClickAwayListener onClickAway={onBlurDownHandler}>
                              <Input
                                className={classes.fileEdit}
                                style={{width:widthrenameText}}
                                id={indexnew}
                                onKeyDown={onKeyDownHandler}
                                onBlur={onBlurDownHandler}
                                value={renameText}
                                onChange={(e) => {
                                  setRenameData({
                                    text: e.currentTarget.value,
                                    rowIndex: e.currentTarget.id,
                                  });
                                  setRenameText(e.currentTarget.value);
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <CreateIcon
                                      className={classes.fileEditIcon}
                                      onClick={closeRename}
                                    />
                                  </InputAdornment>
                                }
                              />
                              </ClickAwayListener>
                          
                          ) : (
                            <Typography
                              variant="subtitle2"
                            >
                              <a href={`${window.globalConfig.apiOrigin}${window.globalConfig.subDir && window.globalConfig.subDir.length ? ('/' + window.globalConfig.subDir) : ''}campaigns/build/${optionnew.cv_id}`} className={classes.boxItemA}>
                                  {optionnew.cv_name}
                              </a>
                            </Typography>
                          )}
                          <Typography
                              component="p"
                              className={`${classes.boxDescription} ${classes.statusTypography}`}
                            >
                            <Typography component="p" className={`${classes.statusSpan} ${CampaignModel.getStatusClass(optionnew)}`}  >
                                  {CampaignModel.getStatus(optionnew)}
                              </Typography>
                          </Typography>
                        </div>

                        <div>
                          <Box
                            display="flex"
                            alignItems="center"
                            flex={1}
                          >
                            <Box className={classes.campListInfoChild}>
                              <Typography
                                component="p"
                                className={classes.boxHeader}
                              >
                                Created By
                              </Typography>
                              {optionnew.usr_name ? (
                                <Typography
                                  component="p"
                                  className={`${classes.boxDescription} ${classes.createdBY}`}
                                  title={`${optionnew.usr_name} ${optionnew.usr_surname}`}
                                >
                                  {optionnew.usr_name} {optionnew.usr_surname}
                                </Typography>
                              ) : (
                                <Typography
                                  component="p"
                                  className={`${classes.boxDescription} ${classes.createdBY}`}
                                >
                                  Unknown
                                </Typography>
                              )}
                            </Box>
                            <Box className={classes.campListInfoChild}>
                              <Typography
                                component="p"
                                className={classes.boxHeader}
                              >
                                CREATED DATE
                              </Typography>
                              <Typography component="p" className={`${classes.boxDescription} ${classes.createDateCamp}`} >                              
                                  {formatDateWithSlach(optionnew.cv_date_creation)}
                              </Typography>
                            </Box>
                            <Box className={classes.campListInfoChildLast}>
                              <Typography
                                component="p"
                                className={classes.boxHeader}
                              >
                                LAST UPDATE
                              </Typography>
                              <Typography
                                component="p"
                                className={`${classes.boxDescription} ${classes.lastUpdate}`}
                              >
                                {calculateNotificationArrivalTime(
                                  optionnew.cv_updated_date
                                )}
                              </Typography>
                            </Box>
                            <Box className={classes.campListInfoChild}>
                              <Typography
                                component="p"
                                className={classes.boxHeader}
                              >
                                DESIGN SYSTEM   
                              </Typography>
                              {optionnew.styleguide_name ? (
                                <Typography
                                  component="p"
                                  className={`${classes.boxDescription} ${classes.styleguideName}`}
                                  title={optionnew.styleguide_name}
                                >
                                  {optionnew.styleguide_name}
                                </Typography>
                              ) : (
                                <Typography
                                  component="p"
                                  className={`${classes.boxDescription} ${classes.styleguideName}`}
                                >
                                  -
                                </Typography>
                              )}
                            </Box>
                            
                            
                          </Box>
                        </div>
                      </Grid>
                      <Grid item className={classes.threedotcamp}>
                        <PopupState
                          variant="popover"
                          popupId="demo-popup-menu"
                        >
                          {(popupState: any) => (
                            <React.Fragment>
                              <MoreVertIcon
                                className={classes.dotIcon}
                                {...bindTrigger(popupState)}
                              />
                              <Menu {...bindMenu(popupState)}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                  vertical: 30,
                                  horizontal: -60
                                }}
                              >
                                <div>
                                  <MenuItem
                                    onClick={() => {
                                      const data = optionnew;
                                      const editLink = `${window.globalConfig.apiOrigin}${window.globalConfig.subDir && window.globalConfig.subDir.length ? ('/' + window.globalConfig.subDir) : ''}/campaigns/build/${data.cv_id}`;

                                      window.location.href = editLink;
                                    }}
                                  >
                                    <Typography>Edit</Typography>
                                  </MenuItem>
                                </div>
                                <div onClick={popupState.close}>
                                  <MenuItem
                                    id={optionnew.cv_id}
                                    data-id={optionnew.cv_name}
                                    onClick={handleClickOpenPreview}
                                  >
                                    <Typography>Preview</Typography>
                                  </MenuItem>
                                </div>
                                {(!isCopywriter()) && (
                                <div onClick={popupState.close}>
                                  <MenuItem
                                    id={optionnew.cv_id}
									data-copytype="duplicate"
									onClick={handleDuplicate}
									disabled={isTrial()}
                                  >
                                    <Typography>Duplicate</Typography>
                                  </MenuItem>
                                </div>
                                )}
                               {(optionnew.is_version ==='0') && (
                                <div onClick={popupState.close}>
                                  <MenuItem
                                    id={optionnew.cv_id}
                                    data-copytype="version"
                                    onClick={handleVersion}
									disabled={isTrial()}
                                  >
                                    <Typography>Create Version</Typography>
                                  </MenuItem>
                                </div>
                                )}
                                {(checkmarketostatus() === true || checkmarketostatusafterload()===true)? (
                                <div
                               style={{display:!isCopywriter()? 'block': 'none'}}
                                onMouseEnter={actionMenuMouseEnter}
                                onMouseLeave={actionMenuMouseLeave}
                                >
                                <MenuItem onClick={actionSubMenuExportClick}>
                                 <Box flex="1">Save as Template</Box> {/*<NavigateNext fontSize="small" /> */}
                                </MenuItem>
                                <Menu
                                id="sub-menu-export1"
                                anchorEl={SubMenuExportAnchorE2}
                                className={classes.subMenuExport}
                                keepMounted
                                open={Boolean(SubMenuExportAnchorE2)}
                                onClose={actionCloseExport}
                                style={{ position: "unset" }}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: isScreenLg ? "right" : "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: isScreenLg ? "left" : "right",
                                }}
                                >
                                <div onClick={popupState.close}>
                                <MenuItem
                                    id={optionnew.cv_id}
                                    value={optionnew.cv_id}
                                    onClick={saveMashrmailTemplate}
                                    disabled={isTrial()}
                                  >
                                    <Typography>MashrMail Template</Typography>
                                  </MenuItem>
                                    <MenuItem
                                    id={optionnew.cv_id}
                                    value={optionnew.cv_id}
                                    onClick={saveMarketoTemplate}
                                    disabled={isTrial()}
                                    >
                                    <Typography>Marketo Template</Typography>
                                    </MenuItem>
                                   
                                  
                                </div>
                                </Menu>
                                </div>
                                ) : (
                                <div style={{display:!isCopywriter()? 'block': 'none'}} onClick={popupState.close}>
                                <MenuItem
                                id={optionnew.cv_id}
                                    value={optionnew.cv_id}
                                    onClick={saveMashrmailTemplate}
                                    disabled={isTrial()}
                                >
                                <Typography>Save as Template</Typography>
                                </MenuItem>
                                </div>
                                )}
                                <div
                                  onMouseEnter={handleMenuMouseEnter}
                                  onMouseLeave={handleMenuMouseLeave}
                                >
                                  <MenuItem onClick={handleSubMenuExportClick}>
                                     <Box flex="1">Export</Box> {/*<NavigateNext fontSize="small" /> */}
                                  </MenuItem>
                                  <Menu
                                    id="sub-menu-export"
                                    anchorEl={SubMenuExportAnchorEl}
                                    className={classes.subMenuExport}
                                    keepMounted
                                    open={Boolean(SubMenuExportAnchorEl)}
                                    onClose={handleCloseExport}
                                    style={{ position: "unset" }}
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: isScreenLg ? "right" : "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: isScreenLg ? "left" : "right",
                                    }}
                                  >
                                    <div onClick={popupState.close}>
                                    <MenuItem
                                        id={option.cv_name}
                                        value={option.cv_id}
                                        onClick={openExportHtmlfun}
                                        disabled={isTrial()}
                                      >
                                        <Typography>Export As Html</Typography>
                                      </MenuItem>
                                      {connectedesp.map((Item: any, index: any) => {
                                        return <MenuItem
                                        id={option.cv_id}
                                        value={Item.pc_id}
                                        data-cname={option.cv_name}
                                        data-csubject={option.cv_email_subject}
                                        onClick={openExportEspfun}
                                        disabled={isTrial()}
                                        >
                                        <Typography>{Item.pc_assign_name}</Typography>
                                        </MenuItem>
                                    })}
                                    </div>
                                  </Menu>
                                </div>
                                
                                <div onClick={popupState.close}>
                                  <MenuItem
                                    id={optionnew.cv_name}
                                    value={optionnew.cv_id}
                                    onClick={openRename}
									disabled={isTrial()}
                                  >
                                    <Typography>Rename</Typography>
                                  </MenuItem>
                                </div>
								{(!isCopywriter()) && (
									<div onClick={popupState.close}>
									<MenuItem
									  id={optionnew.cv_name}
									  data-id={optionnew.cv_id}
									  data-design={optionnew.styleguide_id}
									  onClick={openConvertVersionFunc}
									>
									  <Typography>Update Master</Typography>
									</MenuItem>
									</div>
								)}
                                {(!isCopywriter()) && (
                                <div onClick={popupState.close}>
                                  <MenuItem
                                    id={optionnew.cv_id}
                                    onClick={openMoveArchive}
									disabled={isTrial()}
                                  >
                                    <Typography>Archive</Typography>
                                  </MenuItem>
                                </div>
                                )}

                                  {(!isCopywriter()) && (
                                <div onClick={popupState.close}>
                                  <MenuItem
                                    id={optionnew.cv_id}
                                    onClick={openDelete}
                                    className={classes.deleteItem}
									disabled={isTrial()}
                                  >
                                    <Typography>Delete</Typography>
                                  </MenuItem>
                                </div>
                                  )}
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </Grid>
                    </Grid>
                  </Card>
                  ): ""
                })}
              </div>) : ""
            );
          })}
        </div>
      </InfiniteScroll>
      <Dialog fullScreen open={openPreview} onClose={handleClosePreview}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          component={Paper}
          className={classes.formGrid}
          elevation={4}
          square
        >
          <div className={`${classes.paper} ${classes.PreviewContainer}`}>
            <div className={classes.previewHeader}>
              <Box display="flex">
                <Box p={1} flexGrow={1} display="flex" alignItems="center">
                  <Box className={classes.previewImgLogo} style={{ cursor: "pointer" }} onClick={() => redirectTo('/')} >
                    <img
                      alt="logo"
                      style={{ maxHeight:34,maxWidth: 66 }}                  
                      src={loggedInUser?.user_detail?.CompanyImage+"?time=" + new Date().getTime()}
                    />  
                  </Box>
                  <Typography variant="subtitle1" style={{color: "#5D7186"}}>
                    {previewName}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                <Grid container>

                    
                  <Grid style={{display:"flex"}}>
                    {isDark && <Box style={{marginRight:20}}>                      
                      <ToggleButtonGroup
                        size="small"
                        value={mode}
                        exclusive
                        onChange={handleMode}
                        aria-label="light"
                      >
                        <ToggleButton value="light" aria-label="light" classes={{ selected: classes.ToggleSelected}}  >
                          <WbSunnyIcon color={mode === "light" ? "primary" : undefined} />
                        </ToggleButton>
                        <ToggleButton value="dark" aria-label="dark" classes={{ selected: classes.ToggleSelected}}>
                          <Brightness3Icon color={mode === "dark" ? "primary" : undefined} />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>}
					
					  <Box style={{marginRight:20}}> 
						{viewMenu === true && (
						 <ToggleButtonGroup
							size="small"
							value={formats}
							exclusive
							aria-label="desktop"
						  >
							<TextField
							select
							fullWidth
							style={{width:165}}
							InputProps={{ style: { height: 40 } }}
							name="userRoles"
							id="userRoles"
							variant="outlined"
							label="View"
							defaultValue={viewFormats}
							SelectProps={{
							multiple: false,
							//value: selectedValue,
							onChange: handleViewChange
							}}
							>
							<MenuItem value="fallback">
							<ListItemIcon style={{minWidth:35 ,float:'left'}}>
							<RadioButtonUnchecked color={viewFormats === "fallback" ? "primary" : undefined}/>
						  </ListItemIcon>
						  <ListItemText primary="Fallback" style={{color: (viewFormats === "fallback" ? "#057CFF" : '')}}/>
						</MenuItem>
						<MenuItem value="standard" selected={true}>
						  <ListItemIcon style={{minWidth:35 ,float:'left'}}>
							<Standard color={viewFormats === "standard" ? "#057CFF" : ''}/> 
						  </ListItemIcon>
						  <ListItemText primary="Standard" style={{color: (viewFormats === "standard" ? "#057CFF" : '')}}/>
						  </MenuItem>
						{viewMenuList ==='interactive' && (
						<MenuItem value="interactive">
						  <ListItemIcon style={{minWidth:35 ,float:'left'}}>
							<Interactive color={viewFormats === "interactive" ? "#057CFF" : ''}/> 
						  </ListItemIcon>
						  <ListItemText primary="Interactive" style={{color: (viewFormats === "interactive" ? "#057CFF" : '')}}/>
						</MenuItem>
						)}

							
							</TextField>
						  </ToggleButtonGroup>
						)}
						</Box>
                    <div>

                      <ToggleButtonGroup
                        size="small"
                        value={formats}
                        exclusive
                        onChange={handleFormat}
                        aria-label="desktop"
                      >
                        <ToggleButton value="desktop" aria-label="desktop" classes={{ selected: classes.ToggleSelected}}  >
                          <DesktopMacIcon color={formats === "desktop" ? "primary" : undefined} />
                        </ToggleButton>
                        <ToggleButton value="table" aria-label="table" classes={{ selected: classes.ToggleSelected}}>
                          <TabletMacIcon color={formats === "table" ? "primary" : undefined} />
                        </ToggleButton>
                        <ToggleButton value="phone" aria-label="phone" classes={{ selected: classes.ToggleSelected}}>
                          <PhoneIphoneIcon color={formats === "phone" ? "primary" : undefined} />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </Grid>
                </Grid>
                </Box>
                <Box p={1}>
                  <CloseIcon
                    className={classes.previewPointer}
                    onClick={handleClosePreview}
                  />
                </Box>
              </Box>
            </div>
            <Box display="flex" justifyContent="center" className={classes.previewContentBody}>
              <Box width="100%" className={classes.campaign_preview_body}>
                {/* <div className={classes.campaign_preview_window_header_dsktop}>
                  <FiberManualRecordIcon className={classes.previewDOT} />
                  <FiberManualRecordIcon className={classes.previewDOT} />
                  <FiberManualRecordIcon className={classes.previewDOT} />
                </div> */}
                <div className={classes.campaign_preview_window_content_body}>
                  <Iframe
                    className={`${classes.iFrame}
                    ${formats === "desktop" ? classes.previewDesktopSelected : ""}
                    ${formats === "table" ? classes.previewTableSelected : ""}
                    ${formats === "phone" ? classes.previewPhoneSelected : ""}
                     `}
                    url={previewURL}
                    height="100%"
                    width="100%"
                  ></Iframe>
                </div>
              </Box>
            </Box>
          </div>
        </Grid>
      </Dialog>
    </>
  );
};

export default FileList;
