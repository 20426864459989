import React ,{ useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Box, Paper, Checkbox} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import {setFlashMessage} from "../../../../utils/AuthUtility";
import TeamName from "./TeamName";
import { useStyles } from "../styles";
import TeamStyleGuides from "./TeamStyleGuides";
import TeamUsers from "./TeamUsers";
import TeamIntegrations from "./TeamIntegrations";
import EmailFolderTree from "./FolderTree/EmailFolderTree";
import { getUserFromToken } from "../../../../utils/AuthUtility";
import ImageFolderTree from "./FolderTree/ImageFolderTree";
import TemplateFolderTree from "./FolderTree/TemplateFolderTree";
import { toast } from "react-toastify";
import AxiosInstance from "../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
import { useSelector } from "react-redux";
import {useDispatch} from "react-redux";
import {updateActiveTabs} from "../../../redux/actions/TabManagerActions";

interface Props {
  history?: any;
}

const TeamPage: React.FC<Props> = ({
  history
}) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state);
  const loggedInUser = getUserFromToken();
  const URL = window.location.pathname.split('/').pop();
  //const brandID = loggedInUser.user_detail.Company;
  const [teamMembersData, setTeamMembersData] = useState<any>([]);
  const [teamDesignSystemData, setTeamDesignSystemData] = useState<any>([]);
  const [teamIntegrationsData, setTeamIntegrationsData] = useState<any>([]);
  const [emailFolders, setEmailFolders] = useState<any>([]);
  const [imageFolders, setImageFolders] = useState<any>([]);
  const [templateFolders, setTemplateFolders] = useState<any>([]);
  const [defaultUserslist, setDefaultUserslist] = useState<any>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [defaultDesignslist, setDefaultDesignslist] = useState<any>([]);
  const [selectedDesigns, setSelectedDesigns] = useState<any>([]);
  const [defaultIntegrationslist, setDefaultIntegrationslist] = useState<any>([]);
  const [selectedIntegrations, setSelectedIntegrations] = useState<any>([]);
  const [teamID, setTeamID] = useState<any>("");
  const [teamName, setTeamName] = useState<any>("");
  const [saving, setSaving] = useState<any>(false);
  const [allUsers, setAllUsers] = React.useState<any>(true);
  const [allDs, setAllDs] = React.useState<any>(true);
  const [allIntegrations, setAllIntegrations] = React.useState<any>(true);
  const [allEmailFolders, setAllEmailFolders] = React.useState<any>(true);
  const [allImageFolders, setAllImageFolders] = React.useState<any>(true);
  const [allTemplateFolders, setAllTemplateFolders] = React.useState<any>(true);
  var brandID : any = '';
  if(loggedInUser !==null )
  {
       brandID = loggedInUser.user_detail.Company;
  }
  const loaTeamAttributeData = () => {
		AxiosInstance.get('api/v1/Teams/team_attribute')
		  .then((response: AxiosResponse) => {
			  setTeamMembersData(response.data.data.team_members);
			  setTeamDesignSystemData(response.data.data.design_systems);
			  setTeamIntegrationsData(response.data.data.integrations);
		  });
	};
	const getTeam = (teamID : any) => {
		setAllUsers(false);
		setAllDs(false);
		setAllIntegrations(false);
		setAllEmailFolders(false);
		setAllImageFolders(false);
		setAllTemplateFolders(false);
		AxiosInstance.get('api/v1/Teams/team',{
		params: {
			id: teamID,
		},
	}).then((response: AxiosResponse) => {
			  var resp=response.data.data;
			  var emailArr=[];
			  var imageArr=[];
			  var templateArr=[];
			  var integrationsArr=[];
			  var usersArr=[];
			  var desinsArr=[];
			  var selUsersArr=[];
			  var selDesignsArr=[];
			  var selIntegrationsArr=[];
			  emailArr=resp.email_folders.split(',');
			  imageArr=resp.image_folders.split(',');
			  templateArr=resp.template_folders.split(',');
			  if(resp.users === '-1'){
				  usersArr=[];
			  }else{
				  usersArr=resp.users.split(',');
			  }
			  if(resp.design_system === '-1'){
				  desinsArr=[];
			  }else{
				  desinsArr=resp.design_system.split(',');
			  }
			  if(resp.integrations === '-1'){
				  integrationsArr=[];
			  }else{
				  integrationsArr=resp.integrations.split(',');
			  }
			  selUsersArr=resp.user_selected.split(',');
			  selDesignsArr=resp.design_selected.split(',');
			  selIntegrationsArr=resp.integration_selected.split(',');
			  setEmailFolders(emailArr);
			  setImageFolders(imageArr);
			  setTemplateFolders(templateArr);
			  setDefaultUserslist(usersArr);
			  setSelectedUsers(selUsersArr);
			  setDefaultDesignslist(desinsArr);
			  setSelectedDesigns(selDesignsArr);
			  setDefaultIntegrationslist(integrationsArr);
			  setSelectedIntegrations(selIntegrationsArr);
			  setTeamName(resp.team_name);
			  setTeamID(resp.id);
			  if(resp.all_users ==='0'){
				 setAllUsers(true);  
			   }else{
				 setAllUsers(false);    
			   }
			   if(resp.all_design_system ==='0'){
				 setAllDs(true);  
			   }else{
				 setAllDs(false);    
			   }
			   if(resp.all_integrations ==='0'){
				 setAllIntegrations(true);  
			   }else{
				 setAllIntegrations(false);    
			   }
			   if(resp.email_folders === '0'){
				   setAllEmailFolders(true);
			   }else{
				  setAllEmailFolders(false); 
			   }
			   if(resp.image_folders === '0'){
				   setAllImageFolders(true);
			   }else{
				  setAllImageFolders(false); 
			   }
			   if(resp.template_folders === '0'){
				   setAllTemplateFolders(true);
			   }else{
				  setAllTemplateFolders(false); 
			   }
			   
		  });
	};
	const clearData = () => {
		setEmailFolders([]);
		setImageFolders([]);
		setTemplateFolders([]);
		setDefaultUserslist([]);
		setSelectedUsers([]);
		setDefaultDesignslist([]);
		setSelectedDesigns([]);
		setDefaultIntegrationslist([]);
		setSelectedIntegrations([]);
		setTeamName("");
		setTeamID("");
		setAllUsers(true);
		setAllDs(true);
		setAllIntegrations(true);
		setAllEmailFolders(true);
		setAllImageFolders(true);
		setAllTemplateFolders(true);
	};
	const saveTeam = () => {
		var isError=false;
		if(!teamName){
			toast.error("Enter Team Name");
			isError=true;
		}
		
		if(isError === false){
			setSaving(true);
			var FormData = require("form-data");
			var data = new FormData();
			data.append("id", teamID);
			data.append("team_name", teamName);
			data.append("users", defaultUserslist);
			data.append("design_system", defaultDesignslist);
			data.append("integrations", defaultIntegrationslist);
			data.append("email_folders", emailFolders);
			data.append("template_folders", templateFolders);
			data.append("image_folders", imageFolders);
			data.append("allUsers", allUsers);
			data.append("allDs", allDs);
			data.append("allIntegrations", allIntegrations);
			data.append("allEmailFolders", allEmailFolders);
			data.append("allImageFolders", allImageFolders);
			data.append("allTemplateFolders", allTemplateFolders);
			AxiosInstance.post('api/v1/Teams/save', data)
			.then((response: AxiosResponse) => {
				var loggedInUser = JSON.parse(localStorage.getItem("loggedInUser") || "");
				loggedInUser.teams_ids = response.data.teams_ids;
				loggedInUser.my_teams = response.data.my_teams;
				dispatch(updateActiveTabs({fieldName: "my_teams", fieldValue: response.data.my_teams}));
				//console.log(response.data.teams_ids);
				localStorage.setItem("loggedInUser", JSON.stringify(loggedInUser));
				history.push('/settings/teams');
				localStorage.removeItem('teamID');	
				setSaving(false);
				clearData();
					
			});
		}

	};
	const handleEmailFolderChange = (event:any) => {
		setAllEmailFolders(event.target.checked);
	};
	const handleImageFolderChange = (event:any) => {
		setAllImageFolders(event.target.checked);
	};
	const handleTemplateFolderChange = (event:any) => {
		setAllTemplateFolders(event.target.checked);
	};
  useEffect(() => {
    loaTeamAttributeData();
	
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
   useEffect(() => {
	if(URL ==='TeamDetails'){
	 loaTeamAttributeData();
	var teamID=localStorage.getItem("teamID");
	if(teamID){
		getTeam(teamID);
	}
	}
   }, [URL]);
   
   const checkboxCheck = (arg: any, type:string) => {
	   if(type === 'template' && !state?.tabManager?.temp_folder_tree?.childes?.length){
		   return false;
	   }
	   else if(type === 'email' && !state?.tabManager?.email_folder_tree?.childes?.length){
		   return false;
	   }else if(type === 'image' && !state?.tabManager?.image_folder_tree?.childes?.length){
		   return false;
	   }
	  
	  if(arg){
		  return true;
	  }
	  return false;
  };
  const selectCheck = (arg: any, type:string) => {
	   if(type === 'template' && !state?.tabManager?.temp_folder_tree?.childes?.length){
		   return true;
	   }
	   else if(type === 'email' && !state?.tabManager?.email_folder_tree?.childes?.length){
		   return true;
	   }else if(type === 'image' && !state?.tabManager?.image_folder_tree?.childes?.length){
		   return true;
	   }
	  
	  if(arg){
		  return true;
	  }
	  return false;
  };
  
  return (
    <Box className={classes.root}>
   
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        flexDirection="column"
      >
        <Paper
          className={classes.container}
          elevation={0}
          // square={!isNotSettingsPage}
          style={{borderRadius:4}}
        >
          <Grid container style={{flexWrap: "unset"}}>
            <Grid item md={12} sm={12} className={classes.leftBorder}>
                <TeamName  history={history} teamName={teamName} setTeamName={setTeamName} saveTeam={saveTeam} clearData={clearData} saving={saving}/>
                <Box className={classes.componentLeftContainer}>               
                  <Box display="flex" flexDirection="column" className={classes.section}>
                    <Typography className={classes.heading} variant="h6">
                      Teams Access
                    </Typography>
                    <Typography variant="caption" className={classes.caption}>     
                      Control your teams access by assigning Users, Design Systems, Integrations and Folders to your Team below.
                    </Typography>
                    <Box className={classes.multipleCheckBoxWrapper}>
                      <TeamUsers 
						usersData={teamMembersData}
						defaultUserslist={defaultUserslist}
						setDefaultUserslist={setDefaultUserslist}
						selectedUsers={selectedUsers}
						allUsers={allUsers}
						setAllUsers={setAllUsers}
						
					  />
                      <TeamStyleGuides
						styleGuidesData={teamDesignSystemData}
						defaultDesignslist={defaultDesignslist}
						setDefaultDesignslist={setDefaultDesignslist}
						selectedDesigns={selectedDesigns}
						allDs={allDs}
						setAllDs={setAllDs}
					  />   
                      <TeamIntegrations 
						integrationsData={teamIntegrationsData}
						defaultIntegrationslist={defaultIntegrationslist}
						setDefaultIntegrationslist={setDefaultIntegrationslist}
						selectedIntegrations={selectedIntegrations}
						allIntegrations={allIntegrations}
						setAllIntegrations={setAllIntegrations}
					  />  
                    </Box>
                    <Box className={classes.multipleFoldersWrapper}>
					<Box>
						<Box className={classes.boxFolderTitleName}>     
						<Typography variant="caption" className={classes.folderTitleName} style={{fontWeight:500}}>     
						  Email Folders
						</Typography>
					  </Box>   
						<FormControlLabel classes={{label:classes.checkboxLabel}} control={<Checkbox checked={checkboxCheck(allEmailFolders,'email')} color="primary" onChange={handleEmailFolderChange}/>} label="Automatically add all Email Folders"  className={classes.captioncheckbox}/>
						<div className={selectCheck(allEmailFolders,'email')?classes.disableClass:''}>
						<EmailFolderTree history={history} brandID={brandID} emailFolders={emailFolders} setEmailFolders={setEmailFolders}/>
						</div>
					</Box>
					<Box>
						<Box className={classes.boxFolderTitleName}>     
						<Typography variant="caption" className={classes.folderTitleName} style={{fontWeight:500}}>     
						  Image Folders
						</Typography>
					  </Box>  
						<FormControlLabel classes={{label:classes.checkboxLabel}} control={<Checkbox checked={checkboxCheck(allImageFolders,'image')} color="primary" onChange={handleImageFolderChange}/>} label="Automatically add all Image Folders"  className={classes.captioncheckbox}/>
						<div className={selectCheck(allImageFolders,'image')?classes.disableClass:''}>
						<ImageFolderTree history={history} brandID={brandID} imageFolders={imageFolders} setImageFolders={setImageFolders}/>
						</div>
					</Box>
					<Box>
						<Box className={classes.boxFolderTitleName}>     
						<Typography variant="caption" className={classes.folderTitleName} style={{fontWeight:500}}>     
						  Template Folders
						</Typography>
					  </Box>  
						<FormControlLabel classes={{label:classes.checkboxLabel}} control={<Checkbox checked={checkboxCheck(allTemplateFolders,'template')} color="primary" onChange={handleTemplateFolderChange}/>} label="Automatically add all Template Folders"  className={classes.captioncheckbox}/>
						<div className={selectCheck(allTemplateFolders,'template')?classes.disableClass:''}>
						<TemplateFolderTree history={history} brandID={brandID} templateFolders={templateFolders} setTemplateFolders={setTemplateFolders}/>
						</div>
					</Box>                                             
                    </Box>
                  </Box>
                </Box>
            </Grid>            
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

export default TeamPage;
