import React from "react";
import { Box, Button } from "@material-ui/core";
import { useStyles } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../../redux/actions/styleGuideActions";
import { getNewCustomFont } from "../../../../../../utils/Functions";

interface Props {
  data?:any
}

const AddCustomFonts: React.FC<Props> = ({data}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const state = useSelector((state: any) => state.styleGuide);
  
  const handleAddFontClick = () => {
    let arr = state?.list_custom_fonts;
    const newCustomFont = getNewCustomFont(arr);
    arr.push(newCustomFont);
   
    dispatch(addField("list_custom_fonts", arr));
  }

  return(
          <>{state?.list_custom_fonts?.length < 3 && 
            <Box className={`${classes.boxWrapperAddBtn}`}> <Button                    
              component="label"                    
              variant="contained"
              color="primary"
              className={`${classes.styleBtnUpload}`}
              onClick={handleAddFontClick}
            >
              Add Font
            </Button></Box>}
          </>
    )
};

export default AddCustomFonts;
