import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box, Switch } from "@material-ui/core";
import { useStyles } from "../styles";
import DarkColorPicker from "../utilities/DarkColorPicker";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";
import { addField } from "../../../../redux/actions/styleGuideActions";
import { AutoSave } from "../StyleGuideAutosave";
import DarkMainColors from "./DarkMainColors";

interface Props {}

const DarkBrandColors: React.FC<Props> = () => {
  const classes = useStyles();
  const reduxState = useSelector((state: any) => state.styleGuide);
  const dispatch = useDispatch();

  const handleChangeDarkMode = (event:any) => {
    if(event.target.checked){
      dispatch(addField("is_dark", "1"));
    } else {      
      dispatch(addField("is_dark", "0"));
    }
    restDarkModeColor();
    AutoSave();
  };

  const restDarkModeColor = () => {
    reduxState?.mainColorPallet?.forEach((item:any)=>{
      if(item && item?.value){
        item["dark"] = item.value;
      }    
    })
  }

  return (
    <Box display="flex" flexDirection="column" className={classes.section}>
          <Box className={classes.cornerRadiusOutlook}>
            <Box flex="1">
              <Box className={classes.flexColumn1}> 
                <Typography className={classes.heading} variant="h6">
                  Dark Mode
                </Typography>
              </Box>
            </Box>
            <Switch
              checked={reduxState?.is_dark === "1"}
              onChange={handleChangeDarkMode}
              color="primary"
              name="checkedDark"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Box>

          {reduxState?.is_dark === "1" && (<><Box className={classes.alertDarkMode}>
            <Alert severity="info">
              Dark mode control is supported on roughly 42% of email clients, mainly Apple&nbsp;devices. 
              All other email clients will automatically convert your email to its own Dark&nbsp;Mode&nbsp;style.
            </Alert>
          </Box>
          <Typography className={classes.heading} variant="h6">
            Brand Colours
          </Typography>
          <Typography variant="caption" className={classes.caption}>
            Control how each of your brand colours appears in dark mode.
          </Typography>
          <Box display="flex" className={classes.section}>          
            <Box display="flex" className={`${classes.colorsList} ${classes.mainColorPalletList}`}>
                    { reduxState?.mainColorPallet !== undefined ? (reduxState?.mainColorPallet?.map(function(element:any, index: any) { 
                        return(<Box className={classes.flexColumn} key={index} >
                          <DarkColorPicker fieldColorKey={element.key} fieldColorDark={element.dark} fieldColorValue={element.value} />
                          <Typography variant="caption" title={element.name} className={classes.iconButtonLabel}>
                            {element.name}
                          </Typography>
                        </Box>)
                        })) : ""
                      }
            </Box>
          </Box>
          <DarkMainColors />
          </>)}
    </Box>
  );
};

export default DarkBrandColors;
