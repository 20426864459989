import React, { memo}  from "react";
import Typography from "@material-ui/core/Typography";
import { Box} from "@material-ui/core";
import { useStyles } from "../../styles";
import TextFiledNumber from "../Typography/TextFiledNumber";
interface Props {}

const LayoutPadding: React.FC<Props> = () => {
  const classes = useStyles();

  return (
        <Box display="flex" flexDirection="column" className={`${classes.section1new}`}>
          <Box >
           <Box flex="1">
              <Typography className={`${classes.heading} ${classes.layoutPaddingTitle}`} variant="h6"> 
                Padding 
              </Typography>            
              <Typography variant="caption" className={classes.typographyText}>
                Adjust the outer most padding of email.
              </Typography>
            </Box>
          </Box>
          <Box className={classes.layoutPaddingInputContainer}>
            <Box className={classes.layoutPaddingInputWrapper} style={{display:"flex"}}>   
              <Box className={classes.layoutPaddingLeft}>
                <TextFiledNumber fieldName="layout_padding_left" title="Left" min={0} max={300} />
              </Box>
              <Box className={classes.layoutPaddingTop}>
                <TextFiledNumber fieldName="layout_padding_top" title="Top" min={0} max={300} />
              </Box>  
              <Box className={classes.layoutPaddingBottom}>
                <TextFiledNumber fieldName="layout_padding_bottom" title="Bottom" min={0} max={300} />
              </Box>   
              <Box className={classes.layoutPaddingRight}>
                <TextFiledNumber fieldName="layout_padding_right" title="Right" min={0} max={300} />
              </Box>     
            </Box>
          </Box>
        </Box>
  );
};

export default memo(LayoutPadding);