import AxiosInstance from "../../../../utils/AxiosInstance";
import { toast } from "react-toastify";
import { store } from '../../../redux/store';
import {setFlashMessage} from "../../../../utils/AuthUtility";
import { DefaultStyleGuide } from './utilities/DefaultStyleGuide';
import { getTotalLineHeightForButton } from "../../../../utils/Functions";
import moment from "moment";
import { SaveCustomFontStyleguide } from "./SaveCustomFontStyleguide";
//import {  useSelector } from "react-redux";
//import { addField } from "../../../redux/actions/styleGuideActions";

export async function SaveStyleguide() {
    var styleId: any = null;
    
    
     const stateInitial = store.getState();
    const state = stateInitial.styleGuide;
    const button_radius = (state.button_radius == null || state.button_radius.length === 0 ? "0" : state.button_radius);
    if(state.styleguideName === '' || state.styleguideName===undefined)
    {
         toast.error('Data not saved! Design system name can not be empty.');
         return false;
    }
   
    var formdata = new FormData();
    const data = localStorage.getItem("loggedInUser")
    if(localStorage.getItem("styleGuideResID")===
    '' || localStorage.getItem("styleGuideResID")===null)
    {
       styleId=localStorage.getItem("rowId")
    }
    else
    {
       styleId = localStorage.getItem("styleGuideResID");
    }
    let Email = ""
    if (data) {
      const { user_detail } = JSON.parse(data)
      Email = user_detail.Email
    }
    formdata.append(
      "company_id",
      localStorage.getItem("usr_company_id") ||
      localStorage.getItem("company_id") ||
      ""
    );
    formdata.append("Email", Email || "");
    formdata.append("styleguide_name", state.styleguideName);
    
    formdata.append("head_email_code", state.head_email_code);
    formdata.append("body_top_email_code", state.body_top_email_code);
    formdata.append("body_bottom_email_code", state.body_bottom_email_code);
    
	formdata.append(
	  "email_color_new",
	  state.email_color ? state.email_color : DefaultStyleGuide.email_color
	);
    formdata.append(
      "primary_color_new",
      state.primary_color ? state.primary_color : DefaultStyleGuide.primary_color
    );
    formdata.append(
      "secondary_color_new",
      state.secondary_color ? state.secondary_color : DefaultStyleGuide.secondary_color
    );
    formdata.append(
      "banner_text_color",
      state.header_text_color ? state.header_text_color : ""
    );
    formdata.append(
      "headline_font",
      state.primary_font ? state.primary_font : DefaultStyleGuide.primary_font
    );
    formdata.append(
      "custom_font_visible",
      state.custom_font_visible ? state.custom_font_visible : false
    );

    // let custom_font_family = 
    // { 
    //  "headline_custom_font" : state.headline_custom_font ? state.headline_custom_font : "none",
    //  "headline_custom_h1" : state.headline_custom_h1 ? state.headline_custom_h1 : "none",
    //  "headline_custom_h2" : state.headline_custom_h2 ? state.headline_custom_h2 : "none",
    //  "headline_custom_h3" : state.headline_custom_h3 ? state.headline_custom_h3 : "none",
    //  "headline_custom_h4" : state.headline_custom_h4 ? state.headline_custom_h4 : "none",
    //  "body_custom_font" : state.body_custom_font ? state.body_custom_font : "none",
    //  "text_custom_body1" : state.text_custom_body1 ? state.text_custom_body1 : "none",
    //  "text_custom_body2" : state.text_custom_body2 ? state.text_custom_body2 : "none",
    //  "text_custom_legal" : state.text_custom_legal ? state.text_custom_legal : "none",
    //  "cta_custom_font" : state.cta_custom_font ? state.cta_custom_font : "none",
    // };
    // formdata.append(
    //   "custom_font_family" , JSON.stringify(custom_font_family)
    // );
    // if(state.font_type === 'googlefonts' || state.google_font.family!=='')
    // {

    //   console.log("state",state)
    // if(state.font_type === 'googlefonts' || state.google_font.family!=='')
    // {
    //     var google_font = ''
    //    if(state.google_font.family === undefined)
    //    {
    //       google_font = 'none'; 
    //    } else {
    //        google_font = state.google_font.family;
    //    }
    //     let custom_font_family = 
    // { 
    //  "headline_custom_font" : state.google_font_value ? state.google_font_value : google_font,
    //  "headline_custom_h1" : state.google_font_value ? state.google_font_value : google_font,
    //  "headline_custom_h2" : state.google_font_value ? state.google_font_value : google_font,
    //  "headline_custom_h3" : state.google_font_value ? state.google_font_value : google_font,
    //  "headline_custom_h4" : state.google_font_value ? state.google_font_value : google_font,
    //  "body_custom_font" : state.google_font_value ? state.google_font_value :google_font,
    //  "text_custom_body1" : state.google_font_value ? state.google_font_value : google_font,
    //  "text_custom_body2" : state.google_font_value ? state.google_font_value : google_font,
    //  "text_custom_legal" : state.google_font_value ? state.google_font_value : google_font,
    //  "cta_custom_font" : state.google_font_value ? state.google_font_value :google_font,
    // };
    //  formdata.append(
    //   "custom_font_family" , JSON.stringify(custom_font_family)
    // );
        
    // } else {
    //     var custom_font = ''
    //    if(state["custom_font"].family === undefined)
    //    {
    //       custom_font = 'none'; 
    //    } else {
    //        custom_font = state["custom_font"].family;
    //    }
    // let custom_font_family = 
    // { 
    
    //  "headline_custom_font" : state.customFontname ? state.customFontname : custom_font,
    //  "headline_custom_h1" : state.customFontname ? state.customFontname : custom_font,
    //  "headline_custom_h2" : state.customFontname ? state.customFontname :custom_font,
    //  "headline_custom_h3" : state.customFontname ? state.customFontname :custom_font,
    //  "headline_custom_h4" : state.customFontname ? state.customFontname : custom_font,
    //  "body_custom_font" : state.customFontname ? state.customFontname : custom_font,
    //  "text_custom_body1" : state.customFontname ? state.customFontname : custom_font,
    //  "text_custom_body2" : state.customFontname ? state.customFontname : custom_font,
    //  "text_custom_legal" : state.customFontname ? state.customFontname : custom_font,
    //  "cta_custom_font" : state.customFontname ? state.customFontname : custom_font,
    // };

    //  formdata.append(
    //   "custom_font_family" , JSON.stringify(custom_font_family)
    // );
    // }
    
   
    // formdata.append(
    //   "custom_font_name" , state.customFontname
    // );
    formdata.append(
      "button_font",
      state.button_font ? state.button_font : DefaultStyleGuide.button_font
    );
    formdata.append(
      "fallback_font",
      state.fallback_font ? JSON.stringify(state.fallback_font) : ""
    );
    formdata.append("website_url", state.website_url ? state.website_url : "");
    formdata.append(
      "headline_color",
      state.hedline_color ? state.hedline_color : DefaultStyleGuide.hedline_color
    );
    formdata.append("text_color", state.text_color ? state.text_color :  DefaultStyleGuide.text_color);
    formdata.append("text_font", state.text_font ? state.text_font : DefaultStyleGuide.text_font);
    formdata.append("link_color", state.link_color ? state.link_color :  DefaultStyleGuide.link_color);
    let contact_details = 
    { 
     "company_website" : state.company_website ? state.company_website : "",
     "company_tagline" : state.company_tagline ? state.company_tagline : "",
     "company_address" : state.company_address ? state.company_address : "",
     "company_name" : state.company_name ? state.company_name : "",
     "contact_phone" : state.contact_phone ? state.contact_phone : "",
     "contact_email" : state.contact_email ? state.contact_email : "",
    };
    formdata.append(
      "contact_details", JSON.stringify(contact_details)
    );
    let headline_font_size = 
    { 
     "headline_size_h1" : state.headline_size_h1 ? state.headline_size_h1 : DefaultStyleGuide.headline_size_h1,
     "headline_size_h2" : state.headline_size_h2 ? state.headline_size_h2 : DefaultStyleGuide.headline_size_h2,
     "headline_size_h3" : state.headline_size_h3 ? state.headline_size_h3 : DefaultStyleGuide.headline_size_h3,
     "headline_size_h4" : state.headline_size_h4 ? state.headline_size_h4 : DefaultStyleGuide.headline_size_h4,
     "headline_lineheight_h1" : state.headline_lineheight_h1 ? state.headline_lineheight_h1 : DefaultStyleGuide.headline_lineheight_h1,
     "headline_lineheight_h2" : state.headline_lineheight_h2 ? state.headline_lineheight_h2 : DefaultStyleGuide.headline_lineheight_h2,
     "headline_lineheight_h3" : state.headline_lineheight_h3 ? state.headline_lineheight_h3 : DefaultStyleGuide.headline_lineheight_h3,
     "headline_lineheight_h4" : state.headline_lineheight_h4 ? state.headline_lineheight_h4 : DefaultStyleGuide.headline_lineheight_h4,
     "headline_spaceafter_h1" : state.headline_spaceafter_h1 ? state.headline_spaceafter_h1 : DefaultStyleGuide.headline_spaceafter_h1,
     "headline_spaceafter_h2" : state.headline_spaceafter_h2 ? state.headline_spaceafter_h2 : DefaultStyleGuide.headline_spaceafter_h2,
     "headline_spaceafter_h3" : state.headline_spaceafter_h3 ? state.headline_spaceafter_h3 : DefaultStyleGuide.headline_spaceafter_h3,
     "headline_spaceafter_h4" : state.headline_spaceafter_h4 ? state.headline_spaceafter_h4 : DefaultStyleGuide.headline_spaceafter_h4,
     "headline_name_h1" : state.headline_name_h1 ? state.headline_name_h1 : DefaultStyleGuide.headline_name_h1,
     "headline_name_h2" : state.headline_name_h2 ? state.headline_name_h2 : DefaultStyleGuide.headline_name_h2,
     "headline_name_h3" : state.headline_name_h3 ? state.headline_name_h3 : DefaultStyleGuide.headline_name_h3,
     "headline_name_h4" : state.headline_name_h4 ? state.headline_name_h4 : DefaultStyleGuide.headline_name_h4,
     "headline_spacebefore_h1" : state.headline_spacebefore_h1 ? state.headline_spacebefore_h1 : DefaultStyleGuide.headline_spacebefore_h1,
     "headline_spacebefore_h2" : state.headline_spacebefore_h2 ? state.headline_spacebefore_h2 : DefaultStyleGuide.headline_spacebefore_h2,
     "headline_spacebefore_h3" : state.headline_spacebefore_h3 ? state.headline_spacebefore_h3 : DefaultStyleGuide.headline_spacebefore_h3,
     "headline_spacebefore_h4" : state.headline_spacebefore_h4 ? state.headline_spacebefore_h4 : DefaultStyleGuide.headline_spacebefore_h4,
     "headline_letterspacing_h1" : state.headline_letterspacing_h1 ? state.headline_letterspacing_h1 : DefaultStyleGuide.headline_letterspacing_h1,
     "headline_letterspacing_h2" : state.headline_letterspacing_h2 ? state.headline_letterspacing_h2 : DefaultStyleGuide.headline_letterspacing_h2,
     "headline_letterspacing_h3" : state.headline_letterspacing_h3 ? state.headline_letterspacing_h3 : DefaultStyleGuide.headline_letterspacing_h3,
     "headline_letterspacing_h4" : state.headline_letterspacing_h4 ? state.headline_letterspacing_h4 : DefaultStyleGuide.headline_letterspacing_h4,
     "headline_font_h1" : state.headline_font_h1 ? state.headline_font_h1 : DefaultStyleGuide.headline_font_h1,
     "headline_font_h2" : state.headline_font_h2 ? state.headline_font_h2 : DefaultStyleGuide.headline_font_h2,
     "headline_font_h3" : state.headline_font_h3 ? state.headline_font_h3 : DefaultStyleGuide.headline_font_h3,
     "headline_font_h4" : state.headline_font_h4 ? state.headline_font_h4 : DefaultStyleGuide.headline_font_h4,
     "headline_weight_nb_h1" : state.headline_weight_nb_h1 ? state.headline_weight_nb_h1 : DefaultStyleGuide.headline_weight_nb_h1,
     "headline_weight_nb_h2" : state.headline_weight_nb_h2 ? state.headline_weight_nb_h2 : DefaultStyleGuide.headline_weight_nb_h2,
     "headline_weight_nb_h3" : state.headline_weight_nb_h3 ? state.headline_weight_nb_h3 : DefaultStyleGuide.headline_weight_nb_h3,
     "headline_weight_nb_h4" : state.headline_weight_nb_h4 ? state.headline_weight_nb_h4 : DefaultStyleGuide.headline_weight_nb_h4,
     "headline_weight_h1" : state.headline_weight_h1 ? state.headline_weight_h1 : DefaultStyleGuide.headline_weight_h1,
     "headline_weight_h2" : state.headline_weight_h2 ? state.headline_weight_h2 : DefaultStyleGuide.headline_weight_h2,
     "headline_weight_h3" : state.headline_weight_h3 ? state.headline_weight_h3 : DefaultStyleGuide.headline_weight_h3,
     "headline_weight_h4" : state.headline_weight_h4 ? state.headline_weight_h4 : DefaultStyleGuide.headline_weight_h4,
    };

    formdata.append(
      "headline_font_size", JSON.stringify(headline_font_size)
    );
    let text_size = 
      { 
       "text_size_body1" : state.text_size_body1 ? state.text_size_body1 : DefaultStyleGuide.text_size_body1 ,
       "text_size_body2" : state.text_size_body2 ? state.text_size_body2 : DefaultStyleGuide.text_size_body2,
       "text_size_legal" : state.text_size_legal ? state.text_size_legal : DefaultStyleGuide.text_size_legal,
       "text_lineheight_body1" : state.text_lineheight_body1 ? state.text_lineheight_body1 : DefaultStyleGuide.text_lineheight_body1,
       "text_lineheight_body2" : state.text_lineheight_body2 ? state.text_lineheight_body2 : DefaultStyleGuide.text_lineheight_body2,
       "text_lineheight_legal" : state.text_lineheight_legal ? state.text_lineheight_legal : DefaultStyleGuide.text_lineheight_legal,
       "text_name_body1" : state.text_name_body1 ? state.text_name_body1 : DefaultStyleGuide.text_name_body1,
       "text_name_body2" : state.text_name_body2 ? state.text_name_body2 : DefaultStyleGuide.text_name_body2,
       "text_name_legal" : state.text_name_legal ? state.text_name_legal : DefaultStyleGuide.text_name_legal,
       "text_spacebefore_body1" : state.text_spacebefore_body1 ? state.text_spacebefore_body1 : DefaultStyleGuide.text_spacebefore_body1,
       "text_spacebefore_body2" : state.text_spacebefore_body2 ? state.text_spacebefore_body2 : DefaultStyleGuide.text_spacebefore_body2,
       "text_spacebefore_legal" : state.text_spacebefore_legal ? state.text_spacebefore_legal : DefaultStyleGuide.text_spacebefore_legal,
       "text_spaceafter_body1" : state.text_spaceafter_body1 ? state.text_spaceafter_body1 : DefaultStyleGuide.text_spaceafter_body1,
       "text_spaceafter_body2" : state.text_spaceafter_body2 ? state.text_spaceafter_body2 : DefaultStyleGuide.text_spaceafter_body2,
       "text_spaceafter_legal" : state.text_spaceafter_legal ? state.text_spaceafter_legal : DefaultStyleGuide.text_spaceafter_legal,
       "text_letterspacing_body1" : state.text_letterspacing_body1 ? state.text_letterspacing_body1 : DefaultStyleGuide.text_letterspacing_body1,
       "text_letterspacing_body2" : state.text_letterspacing_body2 ? state.text_letterspacing_body2 : DefaultStyleGuide.text_letterspacing_body2,
       "text_letterspacing_legal" : state.text_letterspacing_legal ? state.text_letterspacing_legal : DefaultStyleGuide.text_letterspacing_legal,
       "text_font_body1" : state.text_font_body1 ? state.text_font_body1 : DefaultStyleGuide.text_font_body1,
       "text_font_body2" : state.text_font_body2 ? state.text_font_body2 : DefaultStyleGuide.text_font_body2,
       "text_font_legal" : state.text_font_legal ? state.text_font_legal : DefaultStyleGuide.text_font_legal,
       "text_weight_nb_body1" : state.text_weight_nb_body1 ? state.text_weight_nb_body1 : DefaultStyleGuide.text_weight_nb_body1,
       "text_weight_nb_body2" : state.text_weight_nb_body2 ? state.text_weight_nb_body2 : DefaultStyleGuide.text_weight_nb_body2,
       "text_weight_nb_legal" : state.text_weight_nb_legal ? state.text_weight_nb_legal : DefaultStyleGuide.text_weight_nb_legal,
       "text_weight_body1" : state.text_weight_body1 ? state.text_weight_body1 : DefaultStyleGuide.text_weight_body1,
       "text_weight_body2" : state.text_weight_body2 ? state.text_weight_body2 : DefaultStyleGuide.text_weight_body2,
       "text_weight_legal" : state.text_weight_legal ? state.text_weight_legal : DefaultStyleGuide.text_weight_legal,
      };
    formdata.append(
      "text_size" , JSON.stringify(text_size)
    ); 
    let button_font_size = 
    { 
     "button_text_size" : state.button_text_size ? state.button_text_size : DefaultStyleGuide.button_text_size,
     "button_text_lineheight" : getTotalLineHeightForButton(state),
     "button_text_weight" : state.button_text_weight ? state.button_text_weight : DefaultStyleGuide.button_text_weight,
     "button_text_weight_nb" : state.button_text_weight_nb ? state.button_text_weight_nb : DefaultStyleGuide.button_text_weight_nb,
     "button_text_letterspacing" : state.button_text_letterspacing ? state.button_text_letterspacing : DefaultStyleGuide.button_text_letterspacing,
     "button_text_fixed_lineheight" : state.button_text_fixed_lineheight ? state.button_text_fixed_lineheight : DefaultStyleGuide.button_text_fixed_lineheight,
     "button_padding_top" : state.button_padding_top ? state.button_padding_top : DefaultStyleGuide.button_padding_top,
     "button_padding_bottom" : state.button_padding_bottom ? state.button_padding_bottom : DefaultStyleGuide.button_padding_bottom,
     "button_padding_left" : state.button_padding_left ? state.button_padding_left : DefaultStyleGuide.button_padding_left,
     "button_padding_right" : state.button_padding_right ? state.button_padding_right : DefaultStyleGuide.button_padding_right
    };
  formdata.append(
    "button_font_size" , JSON.stringify(button_font_size)
  );

  let layout_details = 
  { 
   "layout_style" : state.layout_style ? state.layout_style : DefaultStyleGuide.layout_style,
   "layout_grid_swicth" : state.layout_grid_swicth ? state.layout_grid_swicth : DefaultStyleGuide.layout_grid_swicth,
   "layout_grid_margins" : state.layout_grid_margins ? state.layout_grid_margins : DefaultStyleGuide.layout_grid_margins,
   "layout_grid_columns" : state.layout_grid_columns ? state.layout_grid_columns : DefaultStyleGuide.layout_grid_columns,
   "layout_grid_gutters" : state.layout_grid_gutters ? state.layout_grid_gutters : DefaultStyleGuide.layout_grid_gutters,
   "layout_grid_width" : state.layout_grid_width ? state?.layout_grid_margins*2 + state?.layout_grid_gutters*13 + state?.layout_grid_columns*12 : DefaultStyleGuide.layout_grid_width,
   "layout_padding_left" : state.layout_padding_left ? state.layout_padding_left : DefaultStyleGuide.layout_padding_left,
   "layout_padding_top" : state.layout_padding_top ? state.layout_padding_top : DefaultStyleGuide.layout_padding_top,
   "layout_padding_bottom" : state.layout_padding_bottom ? state.layout_padding_bottom : DefaultStyleGuide.layout_padding_bottom,
   "layout_padding_right" : state.layout_padding_right ? state.layout_padding_right : DefaultStyleGuide.layout_padding_right,
  };
  formdata.append(
    "layout_details" , JSON.stringify(layout_details)
  );

    formdata.append(
      "button_padding_top",
      state.button_padding_top ? state.button_padding_top : DefaultStyleGuide.button_padding_top
    );
    formdata.append(
      "button_padding_bottom",
      state.button_padding_bottom ? state.button_padding_bottom : DefaultStyleGuide.button_padding_bottom
    );
    formdata.append(
      "button_padding_left",
      state.button_padding_left ? state.button_padding_left : DefaultStyleGuide.button_padding_left
    );
    formdata.append(
      "button_padding_right",
      state.button_padding_right ? state.button_padding_right : DefaultStyleGuide.button_padding_right
    );
    formdata.append(
      "default_button_color",
      state.button_background_color ? state.button_background_color : DefaultStyleGuide.button_background_color
    );
    formdata.append(
      "default_button_text_color",
      state.button_text_color ? state.button_text_color : DefaultStyleGuide.button_text_color
    );
    formdata.append(
      "social_accent_color",
      state.social_accent_color ? state.social_accent_color : DefaultStyleGuide.social_accent_color
    );
    formdata.append(
      "button_shape",
       button_radius
    );
    formdata.append(
      "button_radius_switch",
      state.button_radius_switch ? state.button_radius_switch : DefaultStyleGuide.button_radius_switch 
    );
    // state.socialLinkList.forEach((item: any) => {
    //   formdata.append(item.title, item.link ? item.link : "");
    //   formdata.append(item.title + "_src",  item.src ?  item.src : "");
    // });
    formdata.append("address", state.address ? state.address : "");
    formdata.append("state", state.store ? state.store : "");
    formdata.append("country", state.country ? state.country : "");
    formdata.append("postal_code", state.zip ? state.zip : "");
    formdata.append("c_logo_width", state.c_logo_width ? state.c_logo_width : 96);
    // if (state.logo && state.logo.file)
    //   formdata.append("c_logo", state.logo.file);
    // else 
    
    if (state.logo && state.logo.src)
      formdata.append("old_c_logo", state.logo.src);
  
    if(state.styleguideId){
		styleId = state.styleguideId;
	}
    if(styleId)
    {
        formdata.append("autosave_action", 'save'); 
        formdata.append("styleguideId", styleId);
    }
    
    else
        
    {
        formdata.append("autosave_action", 'Insert'); 
    }
  
    const titleArray: any = [];
    const linkArray: any = [];

    state.menuList.forEach((item: any) => {
      titleArray.push(item.title);
      linkArray.push(item.link);
    });
    formdata.append("m_title", titleArray);
    formdata.append("m_link", linkArray);
    formdata.append(
      "main_color_pallet", state.mainColorPallet ? JSON.stringify(state.mainColorPallet) : JSON.stringify(DefaultStyleGuide.main_color_pallet)
    );
    // formdata.append(
    //   "google_font", state.google_font ? JSON.stringify(state.google_font) : JSON.stringify(DefaultStyleGuide.google_font)
    // );
    // formdata.append(
    //   "UploadFontRegularNormal", state.UploadFontRegularNormal ? state.UploadFontRegularNormal : ""
    // );
    
    /*var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };*/
    
    
   
    // if(state.RegularNormal)
    // {
    //      formdata.append(
    //     "filearr[]", state.RegularNormal.file
    // );
    // }
    // if(state.RegularItalic)
    // {
    //     formdata.append(
    //     "filearr[]", state.RegularItalic.file
    // );
    // }
    //  if(state.BoldNormal)
    // {
    //     formdata.append(
    //     "filearr[]", state.BoldNormal.file
    // );
    // }
    // if(state.BoldItalic)
    // {
    //     formdata.append(
    //     "filearr[]", state.BoldItalic.file
    // );
    // }
    
    
    
    state?.socialLinkList?.forEach((item: any) => {
    formdata.append(item.title, item.link ? item.link : "");
  });
  
  let socialIconSrc:any = {};
  state?.socialLinkList?.forEach((item: any) => {
    if(item.src !=='' && item.title!=='') {
      socialIconSrc[item.title] = item.src;   
    }     
  });
 
  formdata.append("social_icon_url", JSON.stringify(socialIconSrc));
   if(moment(new Date())!==null && moment(new Date())!==undefined )
   {
    formdata.append("updated_time", moment(new Date()).toString() );
   }

  formdata.append("is_dark", state?.is_dark ? state?.is_dark : DefaultStyleGuide.is_dark );

    let styleCloseBox: HTMLDivElement | null = document.querySelector("#styleClosebut");
            if (styleCloseBox) {
                styleCloseBox.setAttribute("style", "background:#808080;");
                
            }
            if( state.logoUrl!== undefined && state.logoUrl!=='' )
            {
                formdata.append("logoUrl", state.logoUrl );
                
            }
            if( state.logoUrlInput!== undefined && state.logoUrlInput!=='' )
            {
                formdata.append("logoUrlInput", state.logoUrlInput );
                
            }

    AxiosInstance.post(`api/template`, formdata)
      .then(async (result) => {
          if(result.data.status_code==='404')
          {
                 toast.error('Sorry You are not authorized to perform this action');
                 return false;
          }
          if(result.data.status_code==='406')
          {
                 toast.error('Duplicate style name please choose a diffrent one');
                 return false;
          }
            let MsgContainer: HTMLDivElement | null = document.querySelector("#FlsMsgAlert");
            let element: HTMLElement = document.getElementsByClassName('Confirmcancelbtn')[0] as HTMLElement;
            let elementnew: HTMLElement = document.getElementsByClassName('close_button_for_popup')[0] as HTMLElement;
                if (element instanceof HTMLElement) {
                     setTimeout(() => {
                        element.click();
                        }, 600);
                    
                }
                if (elementnew instanceof HTMLElement) {

                        setTimeout(() => {
                        elementnew.click();
                        }, 400);
                    
                }
             if (MsgContainer) {
                MsgContainer.setAttribute("style", "display:none;");
            var dt = new Date();
            if(setFlashMessage(dt.toUTCString()) === undefined)
            {
                MsgContainer.setAttribute("style", "display:none;float:left;margin-top:3px;margin-right:29px");
                //MsgContainer.innerHTML = 'All Saved ✔ a few seconds ago';
            }
            else
            {
               
                //MsgContainer.innerHTML = 'Last Saved ✔ '+setFlashMessage(dt.toUTCString());
            }
               localStorage.removeItem("LastSavedTime");
               localStorage.setItem('LastSavedTime', dt.toISOString());
            }
            let styleCloseBox: HTMLDivElement | null = document.querySelector("#styleClosebut");
            if (styleCloseBox) {
                styleCloseBox.setAttribute("style", "background:#0a6adc;");
                
            }
            localStorage.setItem('styleGuideResID', result.data.insert_id);           
            await SaveCustomFontStyleguide(result.data.insert_id);
      })
      .catch(function (error: any) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.warn(error.response.data.message);
          }
          if (error.response.status === 500) {
            alert("500, Server Error");
            toast.error(error.response.data.message);
          }
        }
        
      });
  
  
 //toast("Saved Successfully");
}
