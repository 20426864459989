import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TrackingList from "./TrackingList";
import Paper from "@material-ui/core/Paper";
import { useEffect, useState } from "react";
import AxiosInstance from "../../../../utils/AxiosInstance";
import {isPowerAdmin} from "../../../../utils/AuthUtility";
import {AxiosResponse} from "axios";

const useStyles = makeStyles({
  container: { 
      // backgroundColor: "#f8f8f8",
      //height: 'calc(100vh - 250px)',
      //overflow:'hidden',
      //overflowY:'scroll',
      boxShadow:"unset",
      padding:12
  },
   disabledList: { 
    pointerEvents:"none",
    opacity:0.4
  },
});
interface Props {
  history?: any;
}
const TrackingTab: React.FC<Props> = ({ history }) => {
      const URL = window.location.pathname.split('/').pop();
  const classes = useStyles(); 
  const [userData, setUserData] = useState<any>({ usertable: [], pending: [] });
  const loadTrackingData = () => {
  	AxiosInstance.get(`api/getTrackingData/${localStorage.getItem("company_id")}`)
      .then((response: AxiosResponse) => {
		  setUserData(response.data)
      });
  };
  const getDisabled = () => {
	  if(!isPowerAdmin()){
		  return classes.disabledList;
	  }
	  return '``';
  };
  // useEffect(() => {
  //   loadTrackingData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
   useEffect(() => {
    if(URL ==='tracking')
    {
      localStorage.removeItem("TrackingResID");
      localStorage.removeItem("TrackingrowId");
      loadTrackingData();
      let SettingContainer: HTMLDivElement | null = document.querySelector("#SettingBox");
      if (SettingContainer) {
      SettingContainer.setAttribute("style", "display:block");
      }
      let ButContainer: HTMLDivElement | null = document.querySelector("#closeBut");
      if (ButContainer) {
      ButContainer.setAttribute("style", "display:none;");
      }


    }
    else if(URL ==='Newtracking')
    {
      let ButContainer: HTMLDivElement | null = document.querySelector("#closeBut");
      if (ButContainer) {
      ButContainer.setAttribute("style", "display:block;paddingLeft:10px");
      }

      let SettingContainer: HTMLDivElement | null = document.querySelector("#SettingBox");
      if (SettingContainer) {
      SettingContainer.setAttribute("style", "display:none");
      }

      let msgContainer: HTMLDivElement | null = document.querySelector("#FlsMsgAlert");
      if (msgContainer) {
      msgContainer.setAttribute("style", "display:none");
      }


    }
    else {

      let SettingContainer: HTMLDivElement | null = document.querySelector("#SettingBox");
      if (SettingContainer) {
      SettingContainer.setAttribute("style", "display:block");
      }
      let ButContainer: HTMLDivElement | null = document.querySelector("#closeBut");
      if (ButContainer) {
      ButContainer.setAttribute("style", "display:none;");
      }



    }
	 
  }, [URL]);
  return (
    <Box className={getDisabled()}>
      <Paper className={classes.container}>
        <TrackingList history={history} reloadLists={loadTrackingData} data={userData.usertable} />
      </Paper>
    </Box>
  );
};

export default TrackingTab;
