import React from "react";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { Box } from "@material-ui/core";
import { useStyles } from "../styles";

interface Props {
	usersData: any;
	defaultUserslist: any;
	setDefaultUserslist: any;
	selectedUsers: any;
	allUsers: any;
	setAllUsers: any;
}

const TeamUsers: React.FC<Props> = ({
	usersData,
	defaultUserslist,
	setDefaultUserslist,
	selectedUsers,
	allUsers,
	setAllUsers
}) => {
  const classes = useStyles(); 
  // eslint-disable-next-line
  const [defaultStylelist, setdefaultStylelist] = React.useState<any>([]);
  //const [isCheckAll, setIsCheckAll] = React.useState(false);
  //const URL = window.location.pathname.split('/').pop();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "auto",
      },
    },
  };
  
  const makeJoin = (selected:[]) => {
	  
	  var arrTemp:string[] = []
	  var i=0;
	  
	  selected.forEach((Item:any, index:any) => {
		  usersData.forEach((InD:any, index1:any) => {
			if(Item ===InD.usr_id){
				arrTemp[i]= InD.usr_name+' '+InD.usr_surname;
				i++;
			}
		  }); 
		 
	  });
	  return arrTemp.join(',');
  };
  
  
 
  const handleChange = (event:any) => {
	//setIsCheckAll(false);
    setDefaultUserslist(typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value);
  };
   const handleUserChange = (event:any) => {
	setAllUsers(event.target.checked);
  };
 
 const checkboxCheck = () => {
	  if(!usersData.length)
		  return false;
	  return allUsers;
  };
  const selectCheck = () => {
	  if(!usersData.length)
		  return true;
	  return allUsers;
  };
    
  return (
  <Box>
  <FormControlLabel classes={{label:classes.checkboxLabel}} control={<Checkbox checked={checkboxCheck()} color="primary" onChange={handleUserChange}/>} label="Automatically add all users"  className={classes.captioncheckbox}/>
    <Box className={classes.multipleCheckBox}>
      <FormControl className={classes.teamFormControl} variant="outlined" size="medium">
        <InputLabel id="demo-multiple-checkbox-label">Users</InputLabel>
        <Select
          className={classes.teamFormSelect} 
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={defaultUserslist}
          onChange={handleChange}
          label={"Users"}
          renderValue={(selected: any) => makeJoin(selected)}
          MenuProps={MenuProps}
		  disabled={selectCheck()}
        >
			
			
          {usersData.map((Item : any,index:any) => {
              return (
              <MenuItem key={Item.usr_id} value={`${Item.usr_id}`}>
                <Checkbox checked={defaultUserslist.indexOf(`${Item.usr_id}`) > -1}  color="primary" />
                <ListItemText primary={`${Item.usr_name} ${Item.usr_surname}`} />
              </MenuItem>
              )
          })}
        </Select>
      </FormControl>      
    </Box>
	</Box>
  );
};

export default TeamUsers;
