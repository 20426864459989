import React from "react";
import { useStyles } from "../../styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

interface Props { }

const SingleFile = () => {
  const classes = useStyles();

  return (
    <Grid item xs={6} sm={4} md>
      <div className={classes.newCampaignDivSkelton}>
        <Card elevation={1}>
          <Skeleton className={classes.newCampaignCardImage} />
        </Card>
        <Skeleton style={{ width: "100%" }} >
          <Box display="flex" style={{ height: "3rem" }}></Box>
        </Skeleton>
      </div>
    </Grid>
  );
};
const FileListSkleton: React.FC<Props> = () => {
  return (
    <>
      <SingleFile />
      <SingleFile />
      <SingleFile />
      <SingleFile />
      <SingleFile />
      <SingleFile />
      <SingleFile />
      <SingleFile />
      <SingleFile />
      <SingleFile />
    </>
  );
};

export default FileListSkleton;
