import { Avatar, Box, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
// import UsersList from "./UsersList";
import Paper from "@material-ui/core/Paper";
import React ,{ useState, useEffect } from "react";
import TeamListSkeleton from "./TeamListSkeleton";
import { getInitialsFromName, isTrial, iSuperAdmin } from "../../../../utils/AuthUtility";
import { useStyles } from '../styles';
import DeleteTeam from "./DeleteTeam";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import TrialPopup from "../../../../utils/TrialPopup";
import AxiosInstance from "../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
import {useDispatch} from "react-redux";
import {updateActiveTabs} from "../../../redux/actions/TabManagerActions";

interface Props {
  data: any;
  reloadLists: any;
  history: any;
}
const TeamsList: React.FC<Props> = ({ data, reloadLists , history}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  //const loggedInUser = getUserFromToken();  
  const URL = window.location.pathname.split('/').pop();
  const [openTeamDeleteDialog, setopenTeamDeleteDialog] = useState<boolean>(false);  
  const [trialPopupStatus, setTrialPopupStatus] = useState<boolean>(false);
  const [rowIndex, setRowIndex] = useState<any>(0);
  const [listData, setTeamData] = useState<any>([]);
   
  const handleTeamDelete = () => {
    setopenTeamDeleteDialog(false);
	deleteTeam();
  };
  
  const removeTeam = (event: any) => {
	setRowIndex(event.currentTarget.value);
    setopenTeamDeleteDialog(true);
  };
  // eslint-disable-next-line
  const TeamDetails = () => {
	localStorage.removeItem('teamID');
    if(!isTrial()){
       setTimeout(history.push('/settings/TeamDetails'), 1000);
    }else{
      setTrialPopupStatus(true);
    }
 };
 const editTeam = (event: any) => {
	 localStorage.removeItem('teamID');
	 localStorage.setItem("teamID",event.currentTarget.value);
	 setTimeout(history.push('/settings/TeamDetails'), 1000);
    
 };
 
	const loaTeamData = () => {
		AxiosInstance.get('api/v1/Teams/list')
		  .then((response: AxiosResponse) => {
			  setTeamData(response.data.data)
		  });
	};
	const deleteTeam = () => {
		const selectedRecord = listData[rowIndex];
		const id = selectedRecord.id;
		AxiosInstance.delete(`api/v1/Teams/delete_team?id=${id}`)
		 .then((response: AxiosResponse) => {
			 dispatch(updateActiveTabs({fieldName: "my_teams", fieldValue: response.data.my_teams}));
			var loggedInUser = JSON.parse(localStorage.getItem("loggedInUser") || "");
			loggedInUser.my_teams = response.data.my_teams;
			localStorage.setItem("loggedInUser", JSON.stringify(loggedInUser));
			 listData.splice(rowIndex, 1);
			 setTeamData([...listData]);
		  }).catch((err) => {
			
		});
	};
	const validateAccess = (id:any) => {
		if(isTrial() === true){
			return true;
		}
		if(iSuperAdmin() === true){
			return false;
		}
		
		return true;
	}
	
  useEffect(() => {
	if(URL ==='teams'){
		loaTeamData();
		//console.log(loggedInUser.teams_ids);
	}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [URL]);
  return (
    <Box>
        <TrialPopup
          trialPopupStatus={trialPopupStatus}		
          setTrialPopupStatus={setTrialPopupStatus}
        />
        <DeleteTeam
          handleTeamDelete={handleTeamDelete}
          open={openTeamDeleteDialog}
          setOpen={setopenTeamDeleteDialog}
        />
      
        {/* <Toolbar className={classes.toolbar}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography
              variant="h6"
              id="tableTitle"
              component="div"
              className={classes.titleh6}
            ></Typography>
			
            <Button
              className={classes.buttonStyle}
              variant="contained"
              color="primary"
              onClick={() => {TeamDetails();}}
			  disabled={isPowerAdmin() ? false:true}
            >
              Add Team
            </Button>
			
          </Box>
        </Toolbar> */}
        <TableContainer component={Paper} square className={`${classes.tableContainer} ${localStorage.getItem("trial_mode") === 'true' ? classes.hasTrialInfo: ""}`}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className={classes.tableThead}>
              <TableRow>
                <TableCell align="left">TEAM NAME</TableCell>
                <TableCell align="left">TEAM MEMBERS</TableCell>
                <TableCell align="left">DESIGN SYSTEMS</TableCell>
                <TableCell align="center">ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            
            <TableBody>
              {listData.length === 0 && (
                <>
                  <TeamListSkeleton />
                  <TeamListSkeleton />
                  <TeamListSkeleton />
                  <TeamListSkeleton />
                  <TeamListSkeleton />
                  <TeamListSkeleton />
                </>
              )}
              {listData &&
                listData.map((row: any, index: any) => (
                  <TableRow key={"team_" + row.id} className={classes.tableTbodyRow}>
                    <TableCell align="left" width="26%" >                      
                      <Box>
                        {row.team_name}
                      </Box>                     
                    </TableCell>
                    <TableCell align="left" width="34%">
                      <Box className={classes.membersList}>
						  {row?.team_members?.map((team_member: any, index: any) => (
						  index < 10 &&(
                          <Avatar className={classes.avatorIconName} style={{backgroundColor: `${team_member.hex}` }}
                          >
                            {getInitialsFromName(team_member.usr_name, team_member.usr_surname)}
                          </Avatar>
                        )))} 
						{!row.team_members.length && (
							<div>None</div>
						)}
                      </Box>
                    </TableCell>                    
                    <TableCell align="left" width="34%">
                      {row?.design_systems?.map((team_design_system: any, index: any) => (
						 index < 10 &&(
                          team_design_system.c_logo !== "" && team_design_system.c_logo !==null ? (
                            <Box className={classes.styleLogo} style={{background:team_design_system.primary_color_new || "#C4C4C4"}}><img alt="" src={team_design_system.c_logo} /></Box>
                          ) : (
                            <Box className={classes.styleLogo} style={{background:team_design_system.primary_color_new || "#C4C4C4"}}><img alt="" src={`${window.globalConfig.apiOrigin}images/gallery/common_images/style-guide/logo.png`} /></Box>
                          )
                      )))}
					  {!row.design_systems.length && (
						<div>None</div>
					)}
                    </TableCell>                    
                    
                    <TableCell align="center" width="6%">
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState: any) => (
                            <React.Fragment>
                              <MoreVertIcon
                                className={classes.menuButton}
                                {...bindTrigger(popupState)}
                              />
                              <Menu {...bindMenu(popupState)}>
                                <Box onClick={popupState.close}>
                                  <MenuItem value={row.id} disabled={validateAccess(row.id)} onClick={editTeam} data-id={row.id}>
                                    <Typography>Edit</Typography>
                                  </MenuItem>
                                </Box>
								{listData.length >1 && (
                                <Box
                                  onClick={popupState.close}
                                  className={classes.deleteOption}
                                > 
                                <MenuItem value={index} onClick={removeTeam} disabled={validateAccess(row.id)}>
                                    <Typography>Delete</Typography>
                                </MenuItem>
                                </Box>
								)}
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </TableCell>
                    
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>


    </Box>
  );
};

export default TeamsList;
