import { Box, Container } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./style";
interface Props {
  history: any;
}
const Details: React.FC<Props> = ({ history }) => {

  const classes = useStyles();
  return (
    <>
      <Box className={classes.root}>
        <Container className={classes.container}>
          <Typography
            component="h1"
            align="left"
            className={classes.heading}
            variant="h5"
          >
            Details
          </Typography>
        </Container>
      </Box>
    </>
  );
};
export default Details;
