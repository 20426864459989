import AxiosInstance from "./AxiosInstance";
import {AxiosResponse} from "axios";

export enum LEVEL {
	SUPERADMIN = "0",
	POWERUSER = "1",
}

export enum ROLE {
	ADMIN = "1",
 	DESIGNER = "2",
	APPROVER = "3",
	COPYWRITER = "4",
    Marketer = "5"
}

const notAuthRoutes = [
	'/login',
	'/sendmail-forget',
	'/checkmail-forget',
	'/set-new-password',
	'/redirect-reset-password',
	'/activate-account',
	'/preview'
];

export const redirectTo = (endpoint: string, force: boolean = false) => {
	if (!endpoint.startsWith('/')) endpoint = `/${endpoint}`;
	const toURL = `${window.location.protocol}//${window.location.host}${window.globalConfig.subDir && window.globalConfig.subDir.length ? ('/' + window.globalConfig.subDir) : ''}${endpoint}`;
	if (!force && window.location.href === toURL) return;
	window.location.href = toURL;
}

export const redirectToLogin = () => {
	clearLocalStorage();
	redirectTo('/login');
}

export const clearLocalStorage = () => {
    let getUsers: any = {};
    getUsers  =  JSON.parse(localStorage.getItem('registerusersondevice') || '{}');
    localStorage.clear();
    localStorage.setItem('registerusersondevice', JSON.stringify(getUsers));
    redirectTo('/login');
}
export const checkmarketostatus = () => {
	const loggedInUser = getLoggedInUserData();
    if (!loggedInUser) return false;
	if(localStorage.getItem('mktstatus')!==null){
        if(localStorage.getItem('mktstatus') === '1')
            return true; 
         else return false;
      } else {
           return false;
      }
}
export const checkmarketostatusafterload = () => {
	const loggedInUser = getLoggedInUserData();
    if (!loggedInUser) return false;
	if(localStorage.getItem('mktstatusafterload')!==null){
        if(localStorage.getItem('mktstatusafterload') === '1')
            return true; 
         else return false;
      } else {
           return loggedInUser.user_detail.marketoflag;
      }
}


export const logoutUser = () => {
	AxiosInstance.post('api/v1/users/logout')
	    .then((response: AxiosResponse) => {
			redirectToLogin();
		})
		.catch((error: any) => {
			redirectToLogin();
		});
}

export const getUserFromToken = () => {
	try {
		return getLoggedInUserData();
	} catch (error) {
		logoutUser();
	}
}

const getLoggedInUserData = () => {
	try {
		return JSON.parse(localStorage.getItem("loggedInUser") || "");
	} catch (e) {
		return null;
	}
}
export const isLoginRequired = () => {
	const currentUrl = window.location.href;
	var loginRequired=false;
	var currentSegment=currentUrl.replace(`${window.location.protocol}//${window.location.host}${window.globalConfig.subDir}`, '');
	for (var i = 0; i < notAuthRoutes.length; i++) {
		if(currentSegment.startsWith(notAuthRoutes[i])===true){
			loginRequired=false;
			break;
		}else{
			loginRequired=true;
		}
	}
	
	return loginRequired;
};
export const logoutIfUnauthorized = () => {
	const currentUrl = window.location.href;
	let currentEndpoint = currentUrl.replace(`${window.location.protocol}//${window.location.host}${window.globalConfig.subDir}`, '');

	const matchedRoute = notAuthRoutes.find(route => {
		return currentEndpoint.startsWith(route);
	});

	if (!matchedRoute && !localStorage.getItem("X-Token")) {
		redirectToLogin();
	}
}

export const isPowerUser = () => {
	const loggedInUser = getLoggedInUserData();
	if (!loggedInUser) return false;
	const level = loggedInUser?.user_detail?.level;
	return level && level === LEVEL.POWERUSER;
}
export const iSuperAdmin = () => {
	const loggedInUser = getLoggedInUserData();
	if (!loggedInUser) return false;
	const level = loggedInUser?.user_detail?.level;
	if(level === '1')
		return true;
	if(level === '0')
		return true;
	return false;
}
export const getUserId = () => {
	const loggedInUser = getLoggedInUserData();
    if (!loggedInUser) return false;
	return loggedInUser.user_detail.Userid;
}


export const getUserEmail = () => {
	const loggedInUser = getLoggedInUserData();
    if (!loggedInUser) return false;
	return loggedInUser.user_detail.Email;
}

export const isCopywriter = () => {
	const loggedInUser = getLoggedInUserData();
	if (!loggedInUser) return false;
	const level = loggedInUser?.user_detail?.level;
    const role = loggedInUser?.user_detail?.role;
    if(level === '-1' && role === '4')
        return true;
    else 
        return false;
}
export const isAdmin = () => {
	const loggedInUser = getLoggedInUserData();
	if (!loggedInUser) return false;
	const level = loggedInUser?.user_detail?.level;
    const role = loggedInUser?.user_detail?.role;
    if(level === '-1' && role === '1')
        return true;
    else 
        return false;
}
export const isPowerAdmin = () => {
	const loggedInUser = getLoggedInUserData();
	if (!loggedInUser) return false;
    const role = loggedInUser?.user_detail?.role;
    if(role === '1')
        return true;
    else 
        return false;
}

export const isMarketer = () => {
	const loggedInUser = getLoggedInUserData();
	if (!loggedInUser) return false;
	const level = loggedInUser?.user_detail?.level;
    const role = loggedInUser?.user_detail?.role;
    if(level === '-1' && role === '5')
        return true;
    else 
        return false;
}
export const isDesignerCopywriter = () => {
	const loggedInUser = getLoggedInUserData();
	if (!loggedInUser) return false;
    const role = loggedInUser?.user_detail?.role;
    if(role === '2' || role === '4')
        return true;
    else 
        return false;
}
export const isDesigner = () => {
	const loggedInUser = getLoggedInUserData();
	if (!loggedInUser) return false;
    const role = loggedInUser?.user_detail?.role;
    if(role === '1' || role === '2')
        return true;
    else 
        return false;
}
export const isAllowMenu = () => {
	const allow_menu = localStorage.getItem('allow_menu');
    if(allow_menu === '1' && allow_menu!==null)
        return true;
    else 
        return false;
}

export const isNewStyle = () => {
	const URL = window.location.pathname.split('/').pop();
    if(URL === 'NewStyleGuide')
    {
        return true;
    } else {
        return false;
    }
}
export const isNewtracking = () => {
	const URL = window.location.pathname.split('/').pop();
    if(URL === 'Newtracking')
    {
        return true;
    } else {
        return false;
    }
}

export const isTeamDetails = () => {
	const URL = window.location.pathname.split('/').pop();
    if(URL === 'TeamDetails')
    {
        return true;
    } else {
        return false;
    }
}

export const isEnterprise = () => {
	const loggedInUser = getLoggedInUserData();
	if (!loggedInUser) return false;
	const isEnterprise = parseInt(loggedInUser?.user_detail?.settings?.is_enterprise);
	return isEnterprise && isEnterprise === 1;
}

export const isArvatoEnabled = (): boolean => {
	return !(!window.globalConfig.arvEnabled || window.globalConfig.arvEnabled === "false");
}

export const isSAAS = () => !isEnterprise();

export const getUserLevel = (level: string, role: string) => {
	if (level === LEVEL.SUPERADMIN) return "SUPERADMIN";
	if (level === LEVEL.POWERUSER) return "POWERUSER";

	switch (role) {
		case ROLE.ADMIN: return "ADMIN";
		case ROLE.DESIGNER: return "DESIGNER";
		case ROLE.APPROVER: return "APPROVER";
		case ROLE.COPYWRITER: return "COPYWRITER";
        case ROLE.Marketer: return "MARKETER";
	}
}

export const getInitialsFromName = (firstName: string, lastName: string): string => {
	const namesArray = `${firstName} ${lastName}`.trim().split(' ');
	if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
	else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`;
}

export const isLocalHost = () => {
	const LOCAL_DOMAINS = ["localhost", "127.0.0.1", "localhost:3000"];

	if (LOCAL_DOMAINS.includes(window.location.hostname))
		return true;
	else
		return false;
}

export const setFlashMessage = (datevalue: any) => {
    
    var savetimeValue = localStorage.getItem('LastSavedTime');
    if(savetimeValue)
    {
       
    let datevalueNew = new Date(savetimeValue);
      let d = new Date();
      let d1 = d.toISOString();
      let currentDate = new Date(d1);
     
  let UTCsecondsNow = (currentDate.getTime() + currentDate.getTimezoneOffset() * 60 * 1000);
  let UTCseconds = (datevalueNew.getTime() + datevalueNew.getTimezoneOffset() * 60 * 1000);
  let diff = UTCsecondsNow - UTCseconds;
  //alert(diff);
  let tense = 'ago';
  if (diff < 0) {
    tense = 'later';
    diff = Math.abs(diff);
  }
  if (diff === 0) return 0;
  // 365.25 * 24 * 60 * 60 * 1000
  let years = singular(Math.round(diff / 31557600000), 'Year');
  if (years)
    return years + tense;
  let months = singular(Math.round(diff / 2592000000), 'Month');
  if (months)
    return months + tense;
  let days = singular(Math.round(diff / 86400000), 'Day');
  if (days)
    return days + tense;
  let hours = singular(Math.round(diff / 3600000), 'Hour');
  if (hours)
    return hours + tense;
  let mins = singular(Math.round(diff / 60000), 'Minute');
  if (mins)
    return mins + tense;
   var secs = singular(Math.round(diff / 1000), 'Second');
  if (parseInt(secs)<60)
    return 'a few seconds ago';
if (parseInt(secs)>60)
    return '1 Minute ago';
}
}
let singular = function(num:any, str:any) {
  if (num > 1) {
    if (num === 1)
      return '1 ' + str + ' ';
    else
      return num + ' ' + str + 's ';
  }
  return '';
}
export const isTrial = () => {
	var numberDays=localStorage.getItem('numberDays');
	if(localStorage.getItem("trial_mode")!=='true'){
		
		return false;
	}
	if(numberDays){
		let numberDays1=parseInt(numberDays);
		if(numberDays1<=0)
			return true;
	}
	
	return false;
};
export const teamEnabled = () => {
	if(localStorage.getItem("team_active")!=='true'){
		return false;
	}
	return true;
};

logoutIfUnauthorized();
