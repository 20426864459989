import React, { useRef, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Box,IconButton} from "@material-ui/core";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import AxiosInstance from "../../../../../utils/AxiosInstance";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import {
  Button,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";

interface Props {
  isNotSettingsPage: any;
}

const Mailjet: React.FC<Props> = ({ isNotSettingsPage }) => {
    const inputElement = useRef<HTMLInputElement>(null);
    const history = useHistory();
    const [integrationName, setintegrationName] = useState("");
    const [integrationNamevalue, seintegrationNamevalue] = useState("");
    const [dbintType, setdbintType] = useState("");
    const [espaction, setespaction] = useState("");
    const [clientIdmaskvalue, setclientIdmaskvalue] = useState("");
    const [clientApikeymaskvalue, setclientApikeymaskvalue] = useState("");
    const [clientId, setclientId] = useState("");
    const [clientApikey, setclientApikey] = useState("");
    const [clientIdoff, setclientIdoff] = useState<boolean>(true);
    const [clientApikeyoff, setclientApikeyoff] = useState<boolean>(true);
  const URL = window.location.pathname.split('/').pop();
   const handleclientIdoff = () => {
   setclientIdoff(false);
   setclientIdmaskvalue('');
  };
  
  const handleclientApikeyoff = () => {
   setclientApikeyoff(false);
   setclientApikeymaskvalue('');
  };
  
  
  const handleclientIdchange = (event: any) => {
   setclientId(event.target.value)
  };
  
  const handleclientApikeychange = (event: any) => {
   setclientApikey(event.target.value)
  };

  const getIntegrationData = (rowId: any) => {
    AxiosInstance.get(`api/getEspDataById/${rowId}`).then(
      (response: AxiosResponse) => {
        if (response.data) {
          seintegrationNamevalue(response.data.usertable.pc_assign_name || '');
          setintegrationName(response.data.usertable.pc_assign_name || '');
          setclientIdmaskvalue('XX:XX:XX:XX:XX:XX:XX:XX:XX:XX:XX');
          setclientApikeymaskvalue('XX:XX:XX:XX:XX:XX:XX:XX:XX:XX:XX');
          setdbintType(response.data.usertable.pc_provider_id || '');
        }
      }
    );
  };

  const initialValues = {};
  useEffect(() => {
      const intName = localStorage.getItem("integration_name") || "";
      if(localStorage.getItem("editRId"))
      {
        getIntegrationData(localStorage.getItem("editRId"));
        setespaction('UPDATE');
      } else {
          seintegrationNamevalue(intName);
      }
      setclientIdmaskvalue('XX:XX:XX:XX:XX:XX:XX:XX:XX:XX:XX');
      setclientApikeymaskvalue('XX:XX:XX:XX:XX:XX:XX:XX:XX:XX:XX');
      setclientIdoff(true);
      setclientApikeyoff(true)
    inputElement.current?.focus();
  }, [URL]);

  const onSubmit = (form: any) => {
    const FormData = require("form-data");
    var integration_type = '';
    if(dbintType!=='' && localStorage.getItem("editRId")!=='')
    {
        integration_type = dbintType || "";
    } else {
        integration_type = localStorage.getItem("integration_type") || "";
    }
    if ( integration_type!== "") {
      let data = new FormData();
      var pc_assign_name = "";
      var editRowId = localStorage.getItem("editRId") || "";
      if (integrationName !== "") {
        pc_assign_name = integrationName || "";
      } else {
        pc_assign_name = localStorage.getItem("integration_name") || "";
      }
      data.append("pc_provider_id", localStorage.getItem("integration_type"));
      if (clientId) {
        data.append("client_api",clientId);
      }
      if (clientApikey) {
        data.append("pc_api_id", clientApikey);
      }
      if (form.confirmation_email) {
        data.append("confirmation_email", form.confirmation_email);
      }
      if (pc_assign_name) {
        data.append("pc_assign_name", pc_assign_name);
      }
      if (editRowId) {
        data.append("editRowId", editRowId);
      } else {
          data.append("editRowId", '');
      }

      AxiosInstance.post("api/v1/Integration/save_configuration_ajax", data)
        .then((response: AxiosResponse) => {
          if (response?.data?.status) {
            toast.success(response?.data?.data?.message);
            setTimeout(() => {
              history.push("/settings/integrations");
            }, 150);
          }
        })
        .catch((error: any) => {
          if (error.response) {
            if (error.response.status === 400) {
              toast.error(error.response.data.message);
            }
          }
        });
    }
  };
  const useStyles = makeStyles((theme) => ({
    boxRow: {
      alignItems: "center",
      paddingBottom: 17,
    },
    avatar: {
      width: 32,
      height: 32,
    },
    InputText: {
      marginTop: "0",
      flex: 1,
      "& input ": {
        height: "unset",
        borderRadius: "15px!important",
      },
      "& .MuiSelect-selectMenu ": {
        padding: 7,
      },
      "& > div ": {
        borderRadius: "2px !important",
      },
      "& input::placeholder": {
        fontSize: "14px",
        color: "#999999",
      },
    },
    InputTextOutlined:{
    marginTop: "0",
    marginBottom: 24,
    "&:last-child":{      
      marginBottom: 8,
    },
    "& .MuiOutlinedInput-root": {
      "& input": {
        paddingTop: 18,
        paddingBottom: 18,
      },
    },
  },
    InputText1: {
      borderRadius: "12px !important",
    },
    InputLabel: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: "bold",
      color: "#11142D",
      width: 200,
    },
    InputLabelBut: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: "bold",
      color: "#11142D",
      width: 130,
    },
    form: {
      width: 800,
      padding: "15px 15px 100px 15px",
    },
    dialogTitle: {
        paddingLeft:"23px",
      padding: "7px 15px 15px 15px",
      "& > h2": {
        fontSize: "18px",
      },
    },
    dialogContent: {
      padding: "15px 15px 7px 15px",
      overflow: "hidden",
      overflowY: "scroll",
    },
    btnCancelNobelO: {
      border: "1px solid #969696",
      color: "#676767",
      borderRadius: 2,
      fontSize: 16,
      fontWeight: "normal",
      padding: "3px 22px",
      "&:hover": {
        background: "#969696",
        color: "#ffffff",
      },
    },
    btnPrimary: {
      background: "#057CFF",
      borderRadius: "3px",
      color: "#ffffff",
      fontSize: 16,
      fontWeight: "normal",
      padding: "5px 30px",
      "&:hover": {
        background: "#0a6adc",
      },
    },
    dialogActions: {
      borderTop: "1px solid #e5e5e5",
      padding: "23px 0 15px 0",
      margin: "0 15px",
    },
  }));

  const classes = useStyles();
  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {() => (
          <Form className={classes.form}>
            <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
              {"Mailjet Integration"}
            </DialogTitle>
            <DialogContent>
              <Box className={classes.boxRow}>
                <Field
                label="Integration Name"
                onChange={(e: any) => setintegrationName(e.target.value)}
                defaultValue={integrationNamevalue}
                key={integrationNamevalue}
                autofocus="true"
                ref={inputElement}
                as={TextField}
                name="pc_assign_name"
                required
                className={classes.InputTextOutlined}
                placeholder="Integration Name"
                fullWidth
                type="text"
                variant="outlined"
                margin="normal"
                />
              </Box>

             <Box className={classes.boxRow}>
                
                   {espaction === 'UPDATE' ? (
                <TextField
                label="API Key"
                defaultValue={clientApikeymaskvalue}
                name="pc_api_key"
                required
                onChange={handleclientApikeychange}
                    key={clientApikeymaskvalue}
                    disabled={clientApikeyoff}
                    variant="outlined"
                    autoComplete="off"
                    margin="dense"
                    InputProps={{
                      endAdornment: (
                        <IconButton  onClick={handleclientApikeyoff} edge="end" >
                          <VisibilityOffOutlinedIcon style={{ color: "#DADADA" }} />
                        </IconButton>
                      ),
                    }}
                    fullWidth
                  />
        ) : (
                <Field
                label="API Key"
                autofocus="true"
                ref={inputElement}
                as={TextField}
                onChange={handleclientApikeychange}
                name="pc_api_key"
                required
                className={classes.InputTextOutlined}
                placeholder="API Key"
                fullWidth
                type="text"
                variant="outlined"
                margin="normal"
                />
        )}
              </Box>

              <Box className={classes.boxRow}>
                
                   {espaction === 'UPDATE' ? (
                <TextField
                label="SECRET KEY"
                defaultValue={clientIdmaskvalue}
                name="pc_api_id"
                required
                onChange={handleclientIdchange}
                    key={clientIdmaskvalue}
                    disabled={clientIdoff}
                    variant="outlined"
                    autoComplete="off"
                    margin="dense"
                    InputProps={{
                      endAdornment: (
                        <IconButton  onClick={handleclientIdoff} edge="end" >
                          <VisibilityOffOutlinedIcon style={{ color: "#DADADA" }} />
                        </IconButton>
                      ),
                    }}
                    fullWidth
                  />
        ) : (
                <Field
                label="SECRET KEY"
                autofocus="true"
                ref={inputElement}
                as={TextField}
                onChange={handleclientIdchange}
                name="pc_api_id"
                required
                className={classes.InputTextOutlined}
                placeholder="Client Id"
                fullWidth
                type="text"
                variant="outlined"
                margin="normal"
                />
        )}
              </Box>

              <Box className={classes.boxRow}>
                <Typography className={classes.InputLabelBut}></Typography>
                {localStorage.getItem("editRId") ? (
              <Button type="submit" className={classes.btnPrimary}>
                  {"UPDATE INTEGRATION"}
                </Button>
            ): (
              <Button type="submit" className={classes.btnPrimary}>
                  {"ADD INTEGRATION"}
                </Button>
            )}
              </Box>
            </DialogContent>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Mailjet;
