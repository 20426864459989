import React, { memo, useEffect, useState }  from "react";
import Typography from "@material-ui/core/Typography";
import { Box, Card, CardActionArea, CardContent, CardMedia } from "@material-ui/core";
import { useStyles } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../../redux/actions/styleGuideActions";
import { AutoSave } from "../../StyleGuideAutosave";
interface Props {}

const LayoutStyle: React.FC<Props> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.styleGuide);
  const [layoutStyle, setLayoutStyle] = useState("classic");

  const handleClickModern = (event:any) => {
    setLayoutStyle("modern");
    dispatch(addField("layout_style", "modern"));
    dispatch(addField("layout_padding_left", "0"));
    dispatch(addField("layout_padding_top", "0"));
    dispatch(addField("layout_padding_bottom", "0"));
    dispatch(addField("layout_padding_right", "0"));
    AutoSave();
  };
  const handleClickClassic = (event:any) => {
    setLayoutStyle("classic");
    dispatch(addField("layout_style", "classic"));
    AutoSave();
  };

  useEffect(()=>{
    if(state?.layout_style){
      setLayoutStyle(state?.layout_style);
    }
    // eslint-disable-next-line
  },[state?.layout_style]);

  return (
      <Box style={{display:"flex"}} className={`${classes.LayoutStyle} ${layoutStyle === "modern" ? classes.LayoutStyleModernSelected : classes.LayoutStyleClassicSelected}`}>
        <Card className={classes.LayoutStyleModern}>
          <CardActionArea onClick={handleClickModern} className={classes.StyleCardActionArea} >
            <CardMedia
              component="img"
              alt="Modern"
              height="107"
              style={{width:"auto",paddingTop:10}}
              image={`${window.globalConfig.apiOrigin}images/ds/layout-modern.jpg`}
              title="Modern"
            />
            <CardContent className={classes.StyleCardContent}>
              <Typography gutterBottom variant="h5" component="h2" className={classes.StyleCardContentTitle}>
                Modern
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p" className={classes.StyleCardContentDescription}>
                Module backgrounds will continue full-width, giving a modern, website style design.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card className={classes.LayoutStyleClassic}>
          <CardActionArea onClick={handleClickClassic} className={classes.StyleCardActionArea}>
            <CardMedia
              component="img"
              alt="Classic"
              height="107"
              style={{width:"auto",paddingTop:10,margin:"0 auto"}}
              image={`${window.globalConfig.apiOrigin}images/ds/layout-classic.jpg`}
              title="Classic"
            />
            <CardContent className={classes.StyleCardContent}>
              <Typography gutterBottom variant="h5" component="h2" className={classes.StyleCardContentTitle}>
                Classic
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p" className={classes.StyleCardContentDescription}>
                Module backgrounds are contained within the grid creating a 'floating page' style design.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
  );
};

export default memo(LayoutStyle);