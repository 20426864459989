import axios from 'axios';
import {redirectToLogin} from "./AuthUtility";

const AxiosInstance = axios.create({
	headers: {
		'X-Token': localStorage.getItem('X-Token')
	},
	
	


});

AxiosInstance.interceptors.response.use(response => response,
	error => {
		if (error?.response?.status === 401) {
			redirectToLogin();
		} else {
			console.log(error);
		}
		return Promise.reject(error);
	}
);

AxiosInstance.interceptors.request.use(config => {
	var csrfName=localStorage.getItem("csrfName");
	var csrfHash=localStorage.getItem("csrfHash");
	if(config.method === "post" || config.method === "put" || config.method === "delete" || config.method === "patch"){
		if(typeof(config.data) !== "undefined"){
			config.data.append(csrfName,csrfHash);
		}else{
			var FormData = require("form-data");
			config.data = new FormData();
			config.data.append(csrfName,csrfHash);
		}
	}
		if (localStorage.getItem("X-Token"))
			config.headers['X-Token'] = localStorage.getItem("X-Token");
		if (window.globalConfig.subDir.length && !config.url?.includes(window.globalConfig.subDir)) {
			if (config.baseURL !== window.globalConfig.subDir)
				config.baseURL = window.location.origin + "/" + window.globalConfig.subDir;
		} else {
			config.baseURL = window.location.origin + "/";
		}
		return config
	}
);

export const setAuthToken = (token: string) => {
	if (token) {
		AxiosInstance.defaults.headers.common['X-Token'] = token;
	} else {
		delete AxiosInstance.defaults.headers.common['X-Token'];
	}
}

export default AxiosInstance;
