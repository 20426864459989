import React, { useEffect, useState } from "react";
import { makeStyles }  from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper"; 
import { MenuItem, Menu } from "@material-ui/core";
import PopupState, {  bindTrigger, bindMenu } from "material-ui-popup-state";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from  "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Button } from "@material-ui/core";
import {isDesigner, getUserFromToken,isMarketer, isTrial}  from "../../../../utils/AuthUtility";
import TrialPopup from "../../../../utils/TrialPopup";
import AccessPopup from "../../../../utils/AccessPopup";
import AxiosInstance from "../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
import CreateNewStyle from "./CreateNewStyle";
import { useDispatch, useSelector } from 'react-redux';
import { addPrefilData, addField, removedata } from '../../../redux/actions/styleGuideActions';
import { convertToReduxState } from "./utilities/convertApiDataToReduxState";
import { IconButton } from "@material-ui/core";
import DeleteStyleGuide from "./DeleteStyleGuide";
///import moment from "moment";
import { toast } from "react-toastify";
// import { DefaultStyleGuide } from './utilities/DefaultStyleGuide';
// import Grid from '@material-ui/core/Grid';
import StyleGuideListSkeleton from './skeleton/StyleGuideListSkeleton';

interface Props {
  data: any;
  reloadLists: any;
  history: any;
  listLoaded: any;
  setListLoaded: any;
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
   buttonStyle: {
    textTransform: "none",
    fontWeight: 500,
    boxShadow: "none",
    "&:hover": {
      background: "#0a6adc",
      boxShadow: "none",
    },
  },
  menuButton: { cursor: "pointer" },
  deleteOption: {
    color: "red",
  },
  toolbarCreateNewStyle: { 
    backgroundColor: "white",
    // paddingLeft:5,
    minHeight: "unset",
    // paddingBottom:17,
    // paddingRight:0,
    borderBottom: "1px solid #e0e0e0",
    padding: "14px 12px 14px 12px",
		borderTopRightRadius: "4px",
		borderTopLeftRadius: "4px",
  },
  avatar: { marginRight: 10 ,width:32 , height:32},
  tableThead:{
    backgroundColor:"#F3F3F3",
    borderRadius:3,
    "& th":{
      fontSize:10,
      fontWeight:"600",
      lineHeight:"unset",
      padding: "12px 0 10px 0"
    },
    "& th:first-child":{
      paddingLeft:20
    }
  },
  outLineButtonContent: {
    border: "1px solid #057cff",
    borderRadius: "4px",
    color: "#367BF5",
    height: "unset",
    lineHeight: "normal",
    fontWeight:"normal",
    "&:hover": {
      color: "#ffffff",
      background: "#367BF5",
    },
  },
  titleh6:{
    fontSize:18
  },
  tableTbodyRow:{
    borderBottom: "1px solid #E1E1E1",
    "& > td" : {
      padding:"10px 0 !important",
    },
    "& > td$fontFamilyName" :{
      paddingLeft: "10px !important",
    },
    "& > td:first-child" : {
      paddingLeft: "20px !important"
    }
  },
  fontFamilyName:{
    overflow: "hidden",
    maxWidth: 150,
    verticalAlign:"top",
  },
  headlineFontFamilyName:{
    overflow: "hidden",
    maxWidth: 150,
    verticalAlign:"top",
  },
  styleLogo:{
    width: 32, 
    height: "32px", 
    margin:"5px 10px 5px 5px",
    float:"left",
    display:"flex",
    alignItems:"center",
    overflow: "hidden",
    borderRadius: "50%",
    border: "1px solid #cdcdcd",
    padding: 2,
    boxSizing: "border-box",
    "& img":{
      width: "100%",
      height: "100%",
      objectFit: "contain"
    }
  },
  hasTrialInfo:{

  },
  tableContainerStyleGuide:{
    margin: 12,
    marginBottom: 20,
    width: "auto",    
    maxHeight: "calc(100vh - 195px)",
    overflow: "hidden",
    overflowY: "scroll",
    "&$hasTrialInfo":{
      maxHeight: "calc(100vh - 251px)",
    }
  },
  disabledList: { 
    pointerEvents:"none",
    opacity:0.4
  },
});
  

const StyleGideList: React.FC<Props> = ({ data, reloadLists ,history, listLoaded, setListLoaded}) => {
    const dispatch = useDispatch();
    const styleGuide = useSelector((state: any) => state.styleGuide);
  const classes = useStyles();
  const [listData, setListData] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [openStyleDeleteDialog, setopenStyleDeleteDialog] = useState<boolean>(false);
  const [StyleGuideIdDel, setStyleGuideIdDel] = useState<boolean>(false);
  const [assignCampfound, setassignCampfound] = useState<any>(null);
  const [defaultStyle, setdefaultStyle] = useState<any>(null);
  const [defaultStyleId, setdefaultStyleId] = useState<any>(null);
  const styles = makeStyles((theme) => ({
    color: {
      width: "fit-content",
      border: "1px solid #CDCDCD",
      // margin: 10,
      background:"#697689",
      "&:hover": {
        background:"#F8F8F8",
      },
    },

    colorBase: {
      position: "relative",
      "& > .MuiIconButton-root" : {
        border: "1px solid #cdcdcd !important"
      }
    },
  }));
  
const loggedInUser = getUserFromToken();
const [trialPopupStatus, setTrialPopupStatus] = useState<boolean>(false);
const [accessPopupStatus, setAccessPopupStatus] = useState<boolean>(false);

const role = loggedInUser?.user_detail?.role;
const classCss = styles();
const refreshStyleGuideData = () => {
	setListLoaded(false);
  	AxiosInstance.get(`api/getStyleguide/${localStorage.getItem("company_id")}`)
      .then((response: AxiosResponse) => {
        var data = response.data;
        data?.usertable?.forEach((item: any)=>{
          if(isJsonString(item.main_color_pallet)){
            item.main_color_pallet =  JSON.parse(item.main_color_pallet);  
          }
        })
		  setListLoaded(true);	
		  setListData(data.usertable);
      });
  };
  const isJsonString = (str: any) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
     return true; 
    }
  /*const level = loggedInUser?.user_detail?.level;*/
  // const getSavedData = async (rowId : any) => {
  //   //const axios = require("axios");
  //   try { 
  //     const request = await AxiosInstance.get(
  //       `api/company-styleGuide/${localStorage.getItem("company_id")}/${rowId}`
  //     );
  //     dispatch(
  //       await addPrefilData(
  //         await convertToReduxState(request.data.styGuide_Detail)
  //       )
  //     );
  //   } catch (err) {}
  // };
  
  /*const calculateNotificationArrivalTime = (createdDateString: string) => {
	  return moment(createdDateString).add(-(new Date().getTimezoneOffset()), 'minutes').fromNow()
  };
  
   const padTo2Digits = (num:number) => {
    return num.toString().padStart(2, '0');
  }
  const formatDateWithSlach = (dateString:string) => {
    const date = new Date(dateString);
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }*/
  
   const getcompanyFonts = async () => {
    try { 
      const request = await AxiosInstance.get(
        `api/company-fonts/${localStorage.getItem("company_id")}`
      );
      dispatch(
        await addPrefilData(
          await convertToReduxState(request.data.styGuide_Detail)
        )
      );
    } catch (err) { }
  };

  const hasListDefaultMainColor = () => {
    var bool = false;
    styleGuide?.mainColorPallet?.forEach((item: any,index: any) => {
      if(item.list_default !== undefined){
        bool = true;
      }
    });
  
    return bool;
  }
  const addListColorToDefaultMain = () => {
    let listDefaultColor = ["email_color","primary_color","secondary_color","hedline_color","text_color","link_color","button_background_color","button_text_color","social_accent_color"];
    let listRelated:any[] = [];

    listDefaultColor.forEach((item: any,index: any) => {
      var detectFirst = false;
      styleGuide?.mainColorPallet?.forEach((itemMain: any,indexMain: any) => {       
        if(itemMain.value.toLowerCase() === styleGuide[item].toLowerCase()){
          if(!detectFirst){
            listRelated.push({key: itemMain.key , value: item})
          }          
          detectFirst = true;         
        }
      });
    });

    listRelated.forEach((item: any, index: any) =>{
      styleGuide?.mainColorPallet?.forEach((itemMain: any,indexMain: any)=>{
        let list:string[] = [];
          if(item.key === itemMain.key ){
              if(itemMain.list_default !== undefined){
                itemMain.list_default.push(item.value);
              } else {
                list.push(item.value);
                itemMain.list_default = list;
              }
          }
      });  
    });
  }
  
  const getDisabled = () => {
	  if(!isDesigner()){
		  return classes.disabledList;
	  }
	  return '``';
  };   
  useEffect(()=>{
     if(styleGuide?.mainColorPallet !== undefined){
      if(!hasListDefaultMainColor()){
        addListColorToDefaultMain();        
        dispatch(addField("mainColorPallet", styleGuide?.mainColorPallet));
       } 
     } 
    // eslint-disable-next-line
  },[styleGuide?.mainColorPallet]);
 
  const editStyle = (event: any) => {    
    dispatch(addField("editclicked", "true"))
    dispatch(removedata());
    const rowId= event.currentTarget.value;
    dispatch(addField("styleguideId", rowId));
    localStorage.removeItem('rowId');
    localStorage.setItem('rowId', rowId);
    // getSavedData(rowId);
    localStorage.removeItem("styleGuideResID")
    dispatch(addField("designSystem", "showDetails"))    
    // setTimeout(function(){
    //   dispatch(addField("editclicked", "false"))
    // }, 1500);
    // setTimeout(history.push('/designSystems/NewStyleGuide'), 1500);
  };
   const newstyle = async () => {
		if(!isTrial()){
			let ds = parseInt(listData.length);
			let max_ds1=localStorage.getItem('max_ds') as string;
			let max_ds = parseInt(max_ds1);
			if(ds >=max_ds && max_ds!==0){
				setAccessPopupStatus(true);
			}else{
			   dispatch(removedata());
			   dispatch(addField("editclicked", "true"))  

			   getcompanyFonts();
			   localStorage.removeItem('rowId');
			   localStorage.removeItem("styleGuideResID");
			  //  setTimeout(function() {
				 dispatch(addField("designSystem", "showDetails"))
         if(styleGuide?.googleFontList === undefined || styleGuide?.googleFontList.length === 0) {
          const getGoogleFontListFromAPI = await AxiosInstance.get('api/getGoogleFontsList');
          dispatch( await addField("googleFontList", JSON.parse(getGoogleFontListFromAPI.data.googleFontList)));    
        }
				// }, 1500);
				// setTimeout(function(){
				//   dispatch(addField("editclicked", "false"))
				// }, 1500);
			}
			
		}else{
			setTrialPopupStatus(true);
		}
      //  setTimeout(history.push('/designSystems/NewStyleGuide'), 1000);
  };
  
  
  const setDesigbeforedelete = (newstyleguideId:any) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("prev_style_id", StyleGuideIdDel);
    data.append("new_style_id", newstyleguideId);
    data.append("company_id",localStorage.getItem("company_id"))

    AxiosInstance.post('api/reassignStyle', data)
     .then((response: AxiosResponse) => {
        if (response.data.success === 1) {
            handleStyleDelete();
        } else
        {
            toast.error(response.data.response_msg);
        }
      })
  };
  
  
  
  
  
   const handleStyleDelete = () => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("style_id", StyleGuideIdDel);
    data.append("company_id",localStorage.getItem("company_id"))

    AxiosInstance.post('api/delete-styleGuide', data)
     .then((response: AxiosResponse) => {
        if (response.data.success === 1) {
            refreshStyleGuideData();
            setopenStyleDeleteDialog(false);
        } else
        {
            toast.error(response.data.response_msg);
        }
      })
  };
  
  const duplicate_styleguide = (event: any) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("style_id", event.currentTarget.value);
    data.append("company_id",localStorage.getItem("company_id"))

    AxiosInstance.post('api/duplicate_style', data)
     .then((response: AxiosResponse) => {
        if (response.data.success === 1) {
            refreshStyleGuideData();
        } else
        {
            toast.error(response.data.response_msg);
        }
      })
  };
  

   const checkCampExists = (styleId : any) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("style_id", styleId);
    data.append("company_id",localStorage.getItem("company_id"))

    AxiosInstance.post('api/checkAassignCamp', data)
     .then((response: AxiosResponse) => {
        if (response.data.count > 0) {
            setassignCampfound(1);
            setopenStyleDeleteDialog(true);
        } else {
            setassignCampfound(0);
            setopenStyleDeleteDialog(true);
        }
      })
      
      
  };
  
  
  const isWhiteColor = (color:any) => {
    if (color.toLowerCase() === '#ffffff'  || color.toLowerCase() === '#fff') return true;
    else return false;
  }
  
  const deleteStyleguide = (event: any) => {
    const rowIndex = event.currentTarget.value;
    if(listData.length>0)
     {
         // eslint-disable-next-line
         {listData.forEach((Item :any) => {
             if(Item.defaultstyle===1)
             {
                 setdefaultStyleId(Item.id);
             }
             })
             }
        var rowId = listData[rowIndex].id;
        var styleguidename = listData[rowIndex].styleguide_name;
        //setdefaultStyle(rowId);
        setStyleGuideIdDel(rowId);
        setdefaultStyle(styleguidename);
        checkCampExists(rowId);
     }
    
    /*AxiosInstance.delete(`api/v1/settings/delete_user_ajax?id=${userId}`)
     .then((response: AxiosResponse) => {
		 listData.splice(rowIndex, 1);
		 setListData([...listData]);
      });*/
      
  };

  const getFontFamilyByStylguide = (styleGuide:any, fontTag?:any) => {
    if(styleGuide && styleGuide.custom_font_family && isJsonString(styleGuide.custom_font_family)){
     var customFontFamily = JSON.parse(styleGuide.custom_font_family);
      if(fontTag === "headline" && (customFontFamily.headline_custom_font !== "none" || customFontFamily.headline_custom_h1 !== "none")){
          return customFontFamily.headline_custom_h1 ? customFontFamily.headline_custom_h1 : customFontFamily.headline_custom_font;
      }
      if(fontTag === "body" && (customFontFamily.body_custom_font !== "none" || customFontFamily.text_custom_body1 !== "none")){
        return customFontFamily.text_custom_body1 ? customFontFamily.text_custom_body1 : customFontFamily.body_custom_font;
      }
      if(fontTag === "cta" && customFontFamily.cta_custom_font !== "none"){
        return customFontFamily.cta_custom_font;
      }
    } 

    if(fontTag === "headline" && styleGuide.headline_font_size && isJsonString(styleGuide.headline_font_size)){
      var headlineFontSize = JSON.parse(styleGuide.headline_font_size);
      if(headlineFontSize.headline_font_h1){
        return headlineFontSize.headline_font_h1;
      }      
    }

    if(fontTag === "headline" && styleGuide.headline_font){
      return styleGuide.headline_font;
    }
    if(fontTag === "body" && styleGuide.text_size && isJsonString(styleGuide.text_size)){
      var textSize = JSON.parse(styleGuide.text_size);
      if(textSize.text_font_body1){
        return textSize.text_font_body1;
      }      
    }

    if(fontTag === "body" && styleGuide.text_font){
      return styleGuide.text_font;
    }
    

    if(fontTag === "cta"){
      if(styleGuide.button_font) {
        return styleGuide.button_font;
      } else {
        if(styleGuide.text_font) return styleGuide.text_font;
      }
      
    }    
    
    return "";
  }

  useEffect(() => {
      setListData(data);
  }, [data]);
  
  return (
    <>
	<TrialPopup
	    trialPopupStatus={trialPopupStatus}		
		setTrialPopupStatus={setTrialPopupStatus}
      />
	  <AccessPopup
	    accessPopupStatus={accessPopupStatus}
		setAccessPopupStatus={setAccessPopupStatus}
		popupTitle="Design System"
      />
     <DeleteStyleGuide
        handleStyleDelete={handleStyleDelete}
        setDesigbeforedelete={setDesigbeforedelete}
        assignCampfound={assignCampfound}
        defaultStyle={defaultStyle}
        listData={listData}
        defaultStyleId={defaultStyleId}
        open={openStyleDeleteDialog}
        setOpen={setopenStyleDeleteDialog}
      />
     
      <Toolbar className={classes.toolbarCreateNewStyle}>
        {open && (
          <CreateNewStyle
            open={open}
            setOpen={setOpen}
          />
        )}
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="h6" id="tableTitle" component="div" className={classes.titleh6}>
          </Typography>
          <Button
           className={classes.buttonStyle}
		   disabled={!isDesigner() ? true:false}
            variant="contained"
            color="primary"
            onClick={() => {
              newstyle();
            }}
          >
            Add Design System
          </Button>
          
        </Box>
        
        
        
      </Toolbar>
      
      <TableContainer component={Paper} square className={`${classes.tableContainerStyleGuide} ${localStorage.getItem("trial_mode") === 'true' ? classes.hasTrialInfo: ""} `} >
        <Table className={classes.table +` ` + getDisabled()} aria-label="simple table">
          <TableHead className={classes.tableThead}>
            <TableRow >
              
              <TableCell valign="top" align="left">DESIGN SYSTEMS</TableCell>
              <TableCell valign="top" align="left">COLOUR PALETTE</TableCell>
              <TableCell align="left">HEADLINE FONT</TableCell>
              <TableCell valign="top" align="left" style={{paddingLeft:10}}>BODY FONT</TableCell>
              <TableCell valign="top" align="left" style={{paddingLeft:10}}>CTA FONT</TableCell>
              <TableCell valign="top" align="left" style={{paddingLeft:10}}>CREATED BY</TableCell>
              <TableCell valign="top" align="left" style={{paddingLeft:10}}>UPDATED BY</TableCell>
               {(!isMarketer()) && ( <TableCell align="center" style={{minWidth:60}}>ACTIONS</TableCell> )}
            </TableRow>
          </TableHead>
          <TableBody>
            {listLoaded === false && (
              < >
              
              <StyleGuideListSkeleton />
              <StyleGuideListSkeleton />
              <StyleGuideListSkeleton />
              <StyleGuideListSkeleton />
              <StyleGuideListSkeleton />
              <StyleGuideListSkeleton />
              <StyleGuideListSkeleton />
              
            </>)}
            {listData && listLoaded === true &&
              listData.map((row: any, index: any) => (
              
                <TableRow key={row.name} className={classes.tableTbodyRow}>
                  
                  <TableCell style={{verticalAlign:"top"}} valign="top" align="left" width="24%">
                   {row.c_logo !== "" && row.c_logo !==null ? (
                          <Box className={classes.styleLogo} style={{background:row.primary_color_new || "#C4C4C4"}}><img alt="" src={row.c_logo} /></Box>
                        ) : (
                          <Box className={classes.styleLogo} style={{background:row.primary_color_new || "#C4C4C4"}}><img alt="" src={`${window.globalConfig.apiOrigin}images/gallery/common_images/style-guide/logo.png`} /></Box>
                      )}
                      <Box><span  style={{marginRight:"5px",marginTop:"10px",display:"inline-block"}}>{row.styleguide_name}</span>
                      {row.defaultstyle===1 && (
                      <code style={{ backgroundColor:'#e5e5e5',paddingTop:'2px',paddingRight:'8px',paddingBottom:'2px',paddingLeft:'8px',marginLeft:'5px',borderRadius:'10px'}}>Default</code> )}</Box>
                  </TableCell>
                  <TableCell style= {{maxWidth:180,verticalAlign:"top"}} valign="top" align="left"> 

                  <div className={classCss.colorBase }>
                        
                        {row?.main_color_pallet?.map((colorPalletItem: any, index: any) => (
                          index < 500 &&(<IconButton key={colorPalletItem.key + "-" + (Math.random() * 100)} style={ {
                            border: isWhiteColor(colorPalletItem.value) ? '1px solid black':'1px solid '+colorPalletItem.value,
                            width: "fit-content", 
                            fontSize: "5px",
                            marginRight:'4px',
                            marginBottom:"4px",
                            background:colorPalletItem.value || "#C4C4C4",
                            }}></IconButton>)
                        ))}
                       
                       {/* <IconButton style={ {
                        border: isWhiteColor(row.background_color) ? '1px solid black':'1px solid '+row.background_color,
                        width: "fit-content", 
                        fontSize: "5px",
                        marginRight:'4px',
                        background:row.background_color || "#C4C4C4",
                        }}>
                        
                    </IconButton>
                     <IconButton style={ {
                       border: isWhiteColor(row.primary_color_new) ? '1px solid black':'1px solid '+row.primary_color_new,
                        width: "fit-content",
                        fontSize: "5px",
                         marginRight:'4px',
                        background:row.primary_color_new || "#C4C4C4",

                        }}>
                       
                    </IconButton>
                     <IconButton style={ {
                        border: isWhiteColor(row.secondary_color_new) ? '1px solid black':'1px solid '+row.secondary_color_new,
                        width: "fit-content",
                        fontSize: "5px",
                        marginRight:'3px',
                        background:row.secondary_color_new || "#C4C4C4",

                        }}>
                       
                    </IconButton>
                     <IconButton style={ {
                         border: isWhiteColor(row.headline_color) ? '1px solid black':'1px solid '+row.headline_color,
                        width: "fit-content",
                        fontSize: "5px",
                        marginRight:'3px',
                        background:row.headline_color || "#C4C4C4",

                        }}>
                        
                    </IconButton>
                     <IconButton style={ {
                         border: isWhiteColor(row.text_color) ? '1px solid black':'1px solid '+row.text_color,
                        width: "fit-content",
                        fontSize: "5px",
                        marginRight:'3px',
                        background:row.text_color || "#C4C4C4",

                        }}>
                       
                    </IconButton>
                    <IconButton style={ {
                        border: isWhiteColor(row.link_color) ? '1px solid black':'1px solid '+row.link_color,
                        width: "fit-content",
                        fontSize: "5px",
                        marginRight:'3px',
                        background:row.link_color || "#C4C4C4",

                        }}>
                        
                    </IconButton>
                    
                    <IconButton style={ {
                        border: isWhiteColor(row.default_button_color) ? '1px solid black':'1px solid '+row.default_button_color,
                        width: "fit-content",
                        fontSize: "5px",
                        marginRight:'3px',
                        background:row.default_button_color || "#C4C4C4",

                        }}>
                       
                    </IconButton>
                    
                     <IconButton style={ {
                         border: isWhiteColor(row.default_button_text_color) ? '1px solid black':'1px solid '+row.default_button_text_color,
                        width: "fit-content",
                        fontSize: "5px",
                        marginRight:'3px',
                         background:row.default_button_text_color || "#C4C4C4",

                        }}>
                       
                    </IconButton> */}
                     </div></TableCell>
                 
                  <TableCell valign="top" align="left" className={classes.headlineFontFamilyName} title={getFontFamilyByStylguide(row, "headline")}>
                    {getFontFamilyByStylguide(row, "headline") === "Trebuchet MS" ?  "Trebuchet" : getFontFamilyByStylguide(row, "headline")}
                  </TableCell>
                  <TableCell valign="top" align="left" className={classes.fontFamilyName} title={getFontFamilyByStylguide(row, "body")}>
                    {getFontFamilyByStylguide(row, "body") === "Trebuchet MS" ?  "Trebuchet" : getFontFamilyByStylguide(row, "body")}
                  </TableCell>                  
                  <TableCell valign="top" align="left" className={classes.fontFamilyName} title={getFontFamilyByStylguide(row, "cta")}>
                    {getFontFamilyByStylguide(row, "cta") === "Trebuchet MS" ?  "Trebuchet" : getFontFamilyByStylguide(row, "cta")}
                  </TableCell>
                  
                  <TableCell valign="top" className={classes.fontFamilyName} align="left">
                    {row.created_by ? (
                    <Typography
                    style={{fontSize:'0.875rem',paddingRight:"15px"}}
                    className={classes.fontFamilyName}
                    >
                    {row.created_by}
                    </Typography>
                    ) : (
                    <Typography
                    >
                    -
                    </Typography>
                    )}
                  </TableCell>
                  
                   <TableCell style={{verticalAlign:"top"}} valign="top" align="left">
                   
                   {row.last_updated_by ? (
                    <Typography
                    style={{fontSize:'0.875rem',paddingRight:"15px"}}
                    >
                    {row.last_updated_by}
                    </Typography>
                    ) : (
                    <Typography
                    >
                    -
                    </Typography>
                    )}
                  </TableCell>
                  {(!isMarketer()) && (
                  <TableCell style={{verticalAlign:"top"}}  valign="top" align="center">
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState: any) => (
                        <React.Fragment>
                          <MoreVertIcon
                            className={classes.menuButton}
                            {...bindTrigger(popupState)}
                          />
                          <Menu {...bindMenu(popupState)}>
                            <div onClick={popupState.close}>
                              <MenuItem value={row.id} onClick={editStyle} disabled={isTrial()}>
                                <Typography>Edit</Typography>
                              </MenuItem>
                               <MenuItem value={row.id} onClick={duplicate_styleguide} disabled={isTrial()}>
                                <Typography>Duplicate</Typography>
                              </MenuItem>
                              
                            </div>
                            {row.defaultstyle!==1 && Number(role) === 1 && (
                            <div
                              onClick={popupState.close}
                              className={classes.deleteOption}
                            >
                               <MenuItem value={index} onClick={deleteStyleguide} disabled={isTrial()}>
                                <Typography>Delete</Typography>
                              </MenuItem>
                            </div>
                            )}
                          </Menu>
                        </React.Fragment>
                      )}
                    </PopupState>
                  </TableCell>
                  )}
                 </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default StyleGideList;
