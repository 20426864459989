import React, {useState,useEffect} from "react";
import CampaignList from "./campaignList/CampaignList";
import NewCampaign from "./newCampaign/NewCampaign";
import {isCopywriter, isTrial} from "../../../../utils/AuthUtility";
import Tabs from "@material-ui/core/Tabs";
import { useStyles } from "./style";
import Tab from "@material-ui/core/Tab";
import TrialPopup from "../../../../utils/TrialPopup";
import Box from "@material-ui/core/Box";
import Templates from "../content/templates/Templates";
import CreateNewTemplate from "../content/utilities/CreateNewTemplate";
import MarketoCreateTemplate from "../../onboarding/espIntegrations/components/espmodals/MarketoCreateTemplate";

import AxiosInstance from "../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";

interface Props {
  setCampaignTabValue: any;
  campaignTabValue: number;
  history: any;
}

const CampaignIndex: React.FC<Props> = ({
  setCampaignTabValue,
  campaignTabValue,
  history
}) => {

    const [EmailcontentTab, setEmailcontentTab] = React.useState(0);
     const [openTemplate, setOpenTemplate] = React.useState<boolean>(false);
    const [openFlag, setopenFlag] = React.useState<number>(0);
	const URL = window.location.pathname.split('/').pop();
	const [trialPopupStatus, setTrialPopupStatus] = useState<boolean>(false);
    const [templatetab, settemplatetab] = React.useState<boolean>(false);
    const [mtemplateStatus, setmtemplateStatus] = React.useState<boolean>(false);
    const [styleData, setstyleData] = React.useState<boolean>(false);
    const [marketoFetchData, setmarketoFetchData] = React.useState<any>([]);
    const [addTemplatefor, setaddTemplatefor] = React.useState<any>('');
    
    const onBackButtonEvent = (e:any) => {
    e.preventDefault();
    var active_url = window.location.pathname.split('/').pop();
   
    if(active_url === 'campaign')
    {
        setCampaignTabValue(0);
        settemplatetab(false);
    }
    if(active_url === 'landing')
    {
        setCampaignTabValue(1);
        settemplatetab(false);
    }
    
    
  
}

    const classes = useStyles();
  const handleClickNewCampaign = () => {
	  if(isTrial()){
		  setTrialPopupStatus(true);
	  }else{
		  if(!isCopywriter())
		  {
			setCampaignTabValue(1);
			history.push('/landing');
		  }
	  }
  };
  const SetOpenModal = (esp:any) => {
      setaddTemplatefor(esp);
	if(!isTrial()){
		setOpenTemplate(true);
		if(openFlag)
		{
		setopenFlag(0);
		} else { 
		setopenFlag(1);
		}
	}else{
		setTrialPopupStatus(true);
	}
  };
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    //setnewcampaign(false);
    setEmailcontentTab(newValue);
    
    if(newValue === 0)
    {
        settemplatetab(false);
    } else {
        settemplatetab(true);
    }
  };
  function a112yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const getStyleGuideData = () => {
       //var totalLength = 0;
  	AxiosInstance.get(`api/getStyleguide/${localStorage.getItem("company_id")}`)
      .then((response: AxiosResponse) => {
          if(Object.keys(response.data.usertable).length>0)
          {
              //totalLength = Object.keys(response.data.usertable).length;
              setstyleData(response.data.usertable);
              //setdefaultStyle(response.data.usertable[0]['id']);
              //localStorage.setItem('defaultStyleId', response.data.usertable[0]['id']);
              //setstylecount(totalLength);
          }
      });
  };
  const fetchMarketoaccountData = () => {
        var FormData = require("form-data");
        var data = new FormData();
        data.append("p_name", 'marketo');
        AxiosInstance.post("api/v1/Integration/all_accounts", data).then(
          (response: AxiosResponse) => {
            if (response && response.data.data.email_providers.length > 0) {
               setmarketoFetchData(response.data.data.email_providers)
               //console.log(response.data.data.email_providers)
            } 
          }
        );
   }

 const SetMarketoTemplateModal = () => {
	setmtemplateStatus(true);
  };
  const CloseMarketoTemplateModal = () => {
	setmtemplateStatus(false);
  };

  useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);  
    };
    // eslint-disable-next-line 
  }, []);

  return (
    
    <Box className={`${classes.componentRoot} `} style={ (campaignTabValue === 1 && templatetab === false) ? {width:"100%"} : {height: 'calc(100vh - 30px)',paddingLeft: "0px"}}>
     <MarketoCreateTemplate
              mtemplateStatus={mtemplateStatus}
              CloseMarketoTemplateModal={CloseMarketoTemplateModal}
              styleData={styleData}
              marketoFetchData={marketoFetchData}
            />
            
   <CreateNewTemplate
            open={openTemplate}
            setOpen={setOpenTemplate}
            openFlag={openFlag}
            history={history}
            addTemplatefor={addTemplatefor}
            
        />
 
   <Box className={ (campaignTabValue === 1 && templatetab === false) ? '' : classes.boxWidth } style={ (campaignTabValue === 1 && templatetab === false) ? {width:"100%"} : {maxWidth: "1236px",paddingLeft: "0px"}}>
    <div  className={ (campaignTabValue === 1 && templatetab === false) ? '' : classes.componentHeader }>
           {URL !== 'landing' && (
          <div>
         
            <Tabs
              style={{ height: "100%" }}
              value={EmailcontentTab}
              indicatorColor="primary"
              onChange={handleChange}
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
				<Tab
					className={classes.TabName}
					label="Emails"
					{...a112yProps(0)}
				/>          
                 <Tab
                className={classes.TabName}
                label="Templates"
                {...a112yProps(1)}
                 />
            </Tabs>
            
          </div>
           )}
        </div>
        
      
       {campaignTabValue === 0 && templatetab === false && (
        <CampaignList handleClickNewCampaign={handleClickNewCampaign} URL={URL} />
        )}
        {templatetab === true && (
        <Templates fetchMarketoaccountData={fetchMarketoaccountData} getStyleGuideData={getStyleGuideData}  SetMarketoTemplateModal={SetMarketoTemplateModal} SetOpenModal={SetOpenModal} />
        )}
        {campaignTabValue === 1 && templatetab === false && (
        <NewCampaign history={history} />
        )}
      
	  <TrialPopup
	    trialPopupStatus={trialPopupStatus}		
		setTrialPopupStatus={setTrialPopupStatus}
      />
   
    </Box>
    </Box>
  );
};

export default CampaignIndex;