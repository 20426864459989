import React from "react";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import { useStyles } from "../styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
//import {isArvatoEnabled} from "../../../../../utils/AuthUtility";
interface Props {
  appData: any;
  handleClickOpen: any;
}

const App: React.FC<Props> = ({ appData, handleClickOpen }) => {
  const classes = useStyles();

  return (
    <>
      {appData.selected ? (
        <CheckCircleIcon className={classes.selectedIcon} />
      ) : (
        ""
      )}
     
        <Card
          className={appData.p_active === "0" ? classes.itemBoxDisabled : classes.itemBox}
          id={appData.p_name}
          onClick={handleClickOpen}
        >
          <CardMedia
            className={classes.media}
            image={appData.logo}
            title={appData.p_name}
          />
          <Typography className={classes.text} variant="body2">
            {appData.p_name}
          </Typography>
        </Card>
      
    </>
  );
};

export default App;
