import React from "react";
import { Box} from "@material-ui/core";
// import { useStyles } from "../styles";
// import { useDispatch, useSelector } from "react-redux";
// import { addField } from "../../../../redux/actions/styleGuideActions";
// import { AutoSave } from "../StyleGuideAutosave";
import ContactDetailsTextFiled from "./ContactDetailsTextFiled";


const ContactDetails: React.FC = () => {  
  // const classes = useStyles();
  // const dispatch = useDispatch();
  // const state = useSelector((state: any) => state.styleGuide);

  return (
    <Box display="flex" flexDirection="column">
      <ContactDetailsTextFiled label="Company Name*" fieldName="company_name" />
      <ContactDetailsTextFiled label="Website*" fieldName="company_website" />
      <ContactDetailsTextFiled label="Tagline" fieldName="company_tagline" />
      <ContactDetailsTextFiled label="Address*" fieldName="company_address" />
      <ContactDetailsTextFiled label="Phone" fieldName="contact_phone" />
      <ContactDetailsTextFiled label="Email" fieldName="contact_email" />
    </Box>
  );
};

export default ContactDetails;
