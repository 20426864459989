import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  hasIntegrate:{
    "& $itemBox":{
       border: "3px solid #14B8A6",
    background: "rgba(20, 184, 166, 0.11)",
    boxSizing: "border-box",
    
    borderRadius: "10px",
      
    }
  },
  treeViewDiv: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(0.5),
    "&:hover": {
      "& $moreVertIcon": {
        display: "block",
      },
    },
  },
  treeViewFolder: {
    color: "#e0e0e0",
  },
  itemBox: {
    border: "1px solid #C6C6C6",
    cursor: "pointer",
    background: "#FFFFFF",
    boxSizing: "border-box",
    textAlign: "center",
    borderRadius: "10px",
    verticalAlign: "middle",
    boxShadow: "none",
    margin: "1px",
    width:170,
    height:126,
    marginRight: 52,
    marginBottom: 38, 
    position:"relative",
    "&:hover": {
      background: "rgba(5, 124, 255, 0.04)",
      border: "2px solid #057CFF",
      boxSizing: "border-box",
      borderRadius: "10px",
      // margin: "0px",
    },
  },
  itemBoxDisabled: {
    border: "1px solid #C6C6C6",
    background: "#FFFFFF",
    boxSizing: "border-box",
    textAlign: "center",
    borderRadius: "10px",
    verticalAlign: "middle",
    cursor: "pointer",
    width:170,
    height:126,
    marginRight: 52,
    marginBottom: 38,
    position:"relative"
  },
  media: {
    height: 74,
    marginTop: "1rem",
    marginLeft: "1rem",
    backgroundSize: "auto",
    marginRight: "1rem",
  },
  text: {
    paddingTop: ".5rem",
    paddingBottom: ".5rem",
    fontWeight: 500,
  },
  selectedBox: {
    border: "3px solid #14B8A6",
    background: "rgba(20, 184, 166, 0.11)",
    boxSizing: "border-box",
    borderRadius: "10px",
    verticalAlign: "middle",
    cursor: "pointer",
    textAlign: "center",
    width:170,
    height:126,
    marginRight: 52,
    marginBottom: 38,
  },
  selectedIcon: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      // marginTop: "5px",
      // marginLeft: "127px",
    },
    color: "#14B8A6",
    position: "absolute",
    right: 57,
    
    top: 5,
    zIndex:1
    
  },
  modelFooter: {
    paddingRight: "0px",
    paddingLeft: "0px",
    paddingTop: 24,
    paddingBottom: 16,
    borderTop: "1px solid #ccc",
    marginTop: 16
  },
  modalTitle: {
    paddingBottom: "0px",
    "& .MuiTypography-h6":{
      fontSize: 24,
      color: "rgba(0, 0, 0, 0.87)",
      // fontFamily: 'Roboto',
      fontWeight: "normal"
    }
  },
  stepperContainer: {
      paddingLeft:0,
      paddingRight:0,    
      marginLeft: "-10px"
  },
  integrateSkeleton:{
    display: "flex",
    flexWrap: "wrap",
    "& .MuiSkeleton-root":{
      width:170,
      height:126,
      marginRight: 52,
      borderRadius: 10,
      marginBottom: 38,
    }
  },
  nextBtn:{
    letterSpacing:"0.46px",
    fontSize: 15,
    padding: "8px 22px"
  },
  backBtn:{
    marginRight: 16,
    padding: "6px 20px",
    fontSize: 15,
    letterSpacing:" 0.46px",
    textTransform: "uppercase",
  },
  completeBtn:{
    fontSize: 15,
    letterSpacing: "0.46px",
    padding: "8px 21px"
  },
  salesforceTree:{
    background: "rgba(0, 0, 0, 0.04)",
    border: "1px solid rgba(0, 0, 0, 0.38)",
    borderRadius: 4,
    ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label":{
      background: "rgba(25, 118, 210, 0.04)"
    },
    "&.MuiTreeView-root":{
      padding:10,
      marginTop: 16,
      marginBottom: 8,
    },
    "& $treeViewFolder":{
      display: "none"
    },
    "& $treeViewDiv":{
      paddingTop: 0,
      "& .MuiTypography-body1":{
        paddingLeft: "0 !important",
        fontSize: 16,
        letterSpacing:" 0.15px",
        
      }
    }
  },
  errorButton: {
    color: "#fff",
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: "#9a0036",
    },
  },
  header: {
    marginBottom: "2rem",
  },
  mainDiv: {
    maxWidth: "100%",
    width:"100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    //height: "calc(100vh - 250px)",
    //overflow: "hidden",
    //overflowY: "scroll", 
    padding:"20px 12px 0 12px",
  },
  InputLabel: {
    color: "#11142D",
    fontSize: "14px",
    fontStyle: "normal",
    marginTop: "20px",
    fontFamily: 'Poppins, sans-serif',
    fontWeight: "bold",
    lineHeight: "120%",
    marginBottom: "8px",
  },
  InputText: {
    marginTop: "0",
    "& .MuiOutlinedInput-root": {
      "& input": {
        paddingTop: "12.5px",
        paddingBottom: "12.5px",
      },
    },
  },
  InputTextOutlined:{
    marginTop: "0",
    marginBottom: 24,
    "&:last-child":{      
      marginBottom: 8,
    },
    "& .MuiOutlinedInput-root": {
      "& input": {
        paddingTop: 18,
        paddingBottom: 18,
      },
    },
  },
  disabledList: { 
    pointerEvents:"none",
    opacity:0.4
  },
}));
