import React, { useRef, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Box} from "@material-ui/core";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";

import {
  Button,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";

interface Props {
  isNotSettingsPage: any;
}

const Braze: React.FC<Props> = ({ isNotSettingsPage }) => {
  const inputElement = useRef<HTMLInputElement>(null);
  const history = useHistory();

  const [integrationName, setintegrationName] = useState("");
  const [integrationNamevalue, seintegrationNamevalue] = useState("");
  const [dbvalue, setdbvalue] = useState("");
  const [dbintType, setdbintType] = useState("");
  const [RestEndpoint, setRestEndpoint] = useState("");
  const [errorFirst, seterrorFirst] = React.useState(false);
  const [helperTextFirst, sethelperTextFirst] = React.useState<string>("");
    const resendpointoptions = [
    { value: "https://rest.iad-01.braze.com", label: "https://dashboard-01.braze.com" },
    { value: "https://rest.iad-02.braze.com", label: "https://dashboard-02.braze.com" },
    { value: "https://rest.iad-03.braze.com", label: "https://dashboard-03.braze.com" },
    { value: "https://rest.iad-04.braze.com", label: "https://dashboard-04.braze.com" },
    { value: "https://rest.iad-05.braze.com", label: "https://dashboard-05.braze.com" },
    {value: "https://rest.iad-06.braze.com",label: "https://dashboard-06.braze.com"},
    { value: "https://rest.iad-08.braze.com", label: "https://dashboard-08.braze.com" },
    { value: "https://rest.fra-01.braze.eu", label: "https://dashboard-01.braze.eu" },
    { value: "https://rest.fra-02.braze.eu", label: "https://dashboard-02.braze.eu" },
    ];
  const getIntegrationData = (rowId: any) => {
    AxiosInstance.get(`api/getEspDataById/${rowId}`).then(
      (response: AxiosResponse) => {
        if (response.data) {
          seintegrationNamevalue(response.data.usertable.pc_assign_name || '');
          setdbvalue('* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *');
          setdbintType(response.data.usertable.pc_provider_id || '');
        }
      }
    );
  };
const handleAutoCompleteChange = (e: any, Dropdownvalue: any) => {
        setRestEndpoint(Dropdownvalue.value);
  };
  const initialValues = {};
  useEffect(() => {
      const intName = localStorage.getItem("integration_name") || "";
      if(localStorage.getItem("editRId"))
      {
        getIntegrationData(localStorage.getItem("editRId"));
      } else {
          seintegrationNamevalue(intName);
      }
    inputElement.current?.focus();
  }, []);

  const onSubmit = (form: any) => {
       var integration_type = '';
    const FormData = require("form-data");
    if(RestEndpoint === '')
    {
        seterrorFirst(true);
        sethelperTextFirst('Required Field');
    } else {
   
    if(dbintType!=='' && localStorage.getItem("editRId")!=='')
    {
        integration_type = dbintType || "";
    } else {
        integration_type = localStorage.getItem("integration_type") || "";
    }
    if ( integration_type!== "") {
      let data = new FormData();
      var pc_assign_name = "";
      var editRowId = localStorage.getItem("editRId") || "";
      if (integrationName !== "") {
        pc_assign_name = integrationName || "";
      } else {
        pc_assign_name = localStorage.getItem("integration_name") || "";
      }
      data.append("pc_provider_id", localStorage.getItem("integration_type"));
      if (form.pc_api_id) {
        data.append("pc_api_id", form.pc_api_id);
      }
      if (form.client_api) {
        data.append("client_api", form.client_api);
      }
      if (form.confirmation_email) {
        data.append("confirmation_email", form.confirmation_email);
      }
      if (pc_assign_name) {
        data.append("pc_assign_name", pc_assign_name);
      }
      data.append("account_domain", RestEndpoint);
      if (editRowId) {
        data.append("editRowId", editRowId);
      } else {
          data.append("editRowId", '');
      }

      AxiosInstance.post("api/v1/Integration/save_configuration_ajax", data)
        .then((response: AxiosResponse) => {
          if (response?.data?.status) {
            toast.success(response?.data?.data?.message);
            setTimeout(() => {
              history.push("/settings/integrations");
            }, 150);
          }
        })
        .catch((error: any) => {
          if (error.response) {
            if (error.response.status === 400) {
              toast.error(error.response.data.message);
            }
          }
        });
    }
  }
  };
  const useStyles = makeStyles((theme) => ({
    boxRow: {
      alignItems: "center",
      paddingBottom: 17,
    },
    avatar: {
      width: 32,
      height: 32,
    },
    InputText: {
      marginTop: "0",
      flex: 1,
      "& input ": {
        height: "unset",
        borderRadius: "15px!important",
      },
      "& .MuiSelect-selectMenu ": {
        padding: 7,
      },
      "& > div ": {
        borderRadius: "2px !important",
      },
      "& input::placeholder": {
        fontSize: "14px",
        color: "#999999",
      },
    },
    InputTextOutlined:{
    marginTop: "0",
    marginBottom: 24,
    "&:last-child":{      
      marginBottom: 8,
    },
    "& .MuiOutlinedInput-root": {
      "& input": {
        paddingTop: 18,
        paddingBottom: 18,
      },
    },
  },
    InputText1: {
      borderRadius: "12px !important",
    },
    InputLabel: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: "bold",
      color: "#11142D",
      width: 200,
    },
    InputLabelBut: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: "bold",
      color: "#11142D",
      width: 130,
    },
    form: {
      width: 800,
      padding: "15px 15px 100px 15px",
    },
    dialogTitle: {
      padding: "7px 15px 15px 15px",
      "& > h2": {
        fontSize: "18px",
      },
    },
    dialogContent: {
      padding: "15px 15px 7px 15px",
      overflow: "hidden",
      overflowY: "scroll",
    },
    btnCancelNobelO: {
      border: "1px solid #969696",
      color: "#676767",
      borderRadius: 2,
      fontSize: 16,
      fontWeight: "normal",
      padding: "3px 22px",
      "&:hover": {
        background: "#969696",
        color: "#ffffff",
      },
    },
    btnPrimary: {
      background: "#057CFF",
      borderRadius: "3px",
      color: "#ffffff",
      fontSize: 16,
      fontWeight: "normal",
      padding: "5px 30px",
      "&:hover": {
        background: "#0a6adc",
      },
    },
    dialogActions: {
      borderTop: "1px solid #e5e5e5",
      padding: "23px 0 15px 0",
      margin: "0 15px",
    },
  }));

  const classes = useStyles();
  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {() => (
          <Form className={classes.form}>
            <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
              {"Campaign Monitor Integration"}
            </DialogTitle>
            <DialogContent>
              <Box className={classes.boxRow}>
                <Field
                label="Integration Name"
                onChange={(e: any) => setintegrationName(e.target.value)}
                defaultValue={integrationNamevalue}
                key={integrationNamevalue}
                autofocus="true"
                ref={inputElement}
                as={TextField}
                name="pc_assign_name"
                required
                className={classes.InputTextOutlined}
                placeholder="Integration Name"
                fullWidth
                type="text"
                variant="outlined"
                margin="normal"
                />
              </Box>

              <Box className={classes.boxRow}>
              
                    <Field
                    label="API KEY"
                    autofocus="true"
                    ref={inputElement}
                    as={TextField}
                    name="pc_api_id"
                    required
                    className={classes.InputTextOutlined}
                    placeholder="Client Id"
                    fullWidth
                    defaultValue={dbvalue}
                    key={dbvalue}
                    type="text"
                    variant="outlined"
                    margin="normal"
                    />
              </Box>
              
              <Box className={classes.boxRow}>
              <Autocomplete
          id="combo-box-demo"
          options={resendpointoptions}
          onClose={() => {
            console.log("Close");
          }}
          onChange={handleAutoCompleteChange}
          getOptionLabel={(resendpointoptions) => resendpointoptions.label}
          renderInput={(params) => (
            <TextField
            required={true}
              error={errorFirst}
              helperText={helperTextFirst}
              {...params}
              label="API Endpoint"
              placeholder="API Endpoint"
              variant="outlined"
            />
          )}
        />
</Box>
              <Box className={classes.boxRow}>
                <Typography className={classes.InputLabelBut}></Typography>
                {localStorage.getItem("editRId") ? (
              <Button type="submit" className={classes.btnPrimary}>
                  {"UPDATE INTEGRATION"}
                </Button>
            ): (
              <Button type="submit" className={classes.btnPrimary}>
                  {"ADD INTEGRATION"}
                </Button>
            )}
              </Box>
            </DialogContent>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Braze;
