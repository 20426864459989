import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { useStyles } from "../styles";
import DynamicSocialLinks from "../utilities/DynamicSocialLinks"

interface Props {}

const SocialLinks: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" className={classes.section}>
      <Typography className={classes.heading} variant="h6">
        Social links
      </Typography>
      <Typography variant="caption" className={classes.caption}>
         Set your social networks and related URL’s
      </Typography>
      <DynamicSocialLinks/>
    </Box>
  );
};

export default SocialLinks;
