import React, { useState,useEffect } from "react";
import { useStyles } from "../styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
// import List from "@material-ui/core/List";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import ExpandLessIcon from "@material-ui/icons/ExpandLess";
// import Typography from "@material-ui/core/Typography";
// import { Collapse, Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
interface Props {
  setSearchStatus: any;
  setsearchStyleStatus:any;
  querySearch?: string;
  renderStyleList:any;
  setQuerySearch: any;
  setLoadCamps: any;
  setListNextPage: any;
  searchStatus: string;
  searchStyleStatus: string;
}

const statusList = [
  { label: "All", value: "" },
  { label: "Draft", value: "0" },
  { label: "Exported", value: "SENT" },
];

const SearchBar: React.FC<Props> = ({
  setSearchStatus,
  setsearchStyleStatus,
  querySearch,
  renderStyleList,
  setQuerySearch,
  setLoadCamps,
  setListNextPage,
  searchStatus,
  searchStyleStatus,
}) => {
    
  useEffect(() => {
    setQuerySearch("");
    // eslint-disable-next-line 
  }, []);
  const classes = useStyles();
  // const [showDropdown, setShowDropdown] = useState(false);
  const [statusName, setStatusName] = useState("All");
  const [StylestatusName, setStylestatusName] = useState("All");

  // const handleStatus = () => {
  //   setShowDropdown(!showDropdown);
  // };

  
  // const ShowStyleDropdown = () => {
  //   setShowDropdown(!showDropdown);
  // };
  
  
  // const selectStatus = (event: any) => {
  //   setShowDropdown(!showDropdown);
  //   setStatusName(event.currentTarget.id);
  // };
  
  //  const selectStyleStatus = (event: any) => {
  //   setShowDropdown(!showDropdown);
  //   setStylestatusName(event.currentTarget.id);
  // };
  

  const querryFunc = (event: any) => {
    
    if(event.currentTarget.value === ''){
        setQuerySearch('empty');
    } else {
        setQuerySearch(event.currentTarget.value);
    }
    setLoadCamps([]);
    setListNextPage(1);
  };
  const selectStatusValue = (event: any) => {
    if (searchStatus !== event.currentTarget.id) {
      setSearchStatus(event.currentTarget.id);
      setLoadCamps([]);
      setListNextPage(1);      
      setStatusName(event.target.value);
    }
  };
   const selectStyleStatusValue = (event: any) => {
    if (searchStyleStatus !== event.currentTarget.id) {
        setLoadCamps([]);
         setsearchStyleStatus(event.currentTarget.id);
         setListNextPage(1);
         setStylestatusName(event.target.value);
    }
  };
  

  return (
  <div>
    <AppBar
      elevation={0}
      position="static"
      color="transparent"
      className={classes.searchHeader}
    >
      <Toolbar className={classes.toolbarSearch}>
        <div className={classes.search} style={{ width: renderStyleList.length>0? '100%': '65%'}}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search"
            classes={{
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            defaultValue={querySearch}
            onChange={querryFunc}
          />
        </div>
        
      </Toolbar>
    </AppBar>
    <AppBar
      elevation={0}
      position="static"
      color="transparent"
      className={classes.searchHeaderdropdown}
    >
     <TextField
            id="outlined-select-status"
            select
            label="Status"
            value={statusName}
            onChange={selectStatusValue}
            variant="outlined"
            className={classes.selectStatus}
          >
              {statusList.map((option : any) => (             
                  <MenuItem key={option.value} value={option.label} id={option.value}  >
                    {option.label}
                  </MenuItem>                
              ))}
          </TextField>
          
          <TextField
              id="outlined-select-styleguide"
              select
              label="Design Systems"
              value={StylestatusName}
              onChange={selectStyleStatusValue}
              variant="outlined"
              className={classes.selectStyleGuide}
            >
                {renderStyleList.length>0 && renderStyleList.map((option : any) => (             
                    <MenuItem key={option.id} value={option.styleguide_name} id={option.id}  >
                      {option.styleguide_name}
                    </MenuItem>                
                ))}

            </TextField>
            
            
    </AppBar>
    </div>
    
  );
};

export default SearchBar;
