import React, { useState, useEffect, useCallback } from "react";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useStyles } from "../../../../../../../styles/styles";
import Tree from "../../../../../../../common/components/Tree";
import TreeView from "@material-ui/lab/TreeView";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CreateNewFolderOutlinedIcon from "@material-ui/icons/CreateNewFolderOutlined";
import CreateNewFolder from "./utilities/CreateNewFolder";
import MoveFolder from "./utilities/MoveFolder";
import DeleteFolder from "./utilities/DeleteFolder";
import AxiosInstance from "../../../../../../../utils/AxiosInstance";
import {isDesigner, isMarketer, isTrial} from "../../../../../../../utils/AuthUtility";
import TrialPopup from "../../../../../../../utils/TrialPopup";
import {AxiosResponse} from "axios";

interface Props {
  fileTreeData: RenderTree;
  setFolderID: any;
  history: any;
  brandID: string;
  refreshCampFolders: any;
  moveCampToFolderFunction: any;
  moveFolderDragandDrop: any;
  setDragOverFolder: any;
  dragOverFolder: any;
  ParamFolderId:any;
  
}
interface RenderTree {
  id: string;
  name?: string;
  type?: string;
  folder_name?: string;
  parent_id?: string;
  company_id?: string;
  state?: {
    opened: boolean;
  };
  childes?: RenderTree[];
  allparents : any;
  allchildes: any;
}

const FolderTree: React.FC<Props> = ({
  fileTreeData,
  setFolderID,
  history,
  brandID,
  refreshCampFolders,
  moveCampToFolderFunction,
  moveFolderDragandDrop,
  setDragOverFolder,
  dragOverFolder,
  ParamFolderId,
}) => {
  const classes = useStyles();


  // edit releted functions
  /*const grabedFolder = useRef(undefined);*/
  const URL = window.location.pathname.split('/').pop();
  const [showEdit, setShowEdit] = useState(false);
  const [renameFolder, setRenameFolder] = useState("");
  const [existRenameFolder, setExistRenameFolder] = useState("");
  const [renameFolderID, setRenameFolderID] = useState<string>("");
  const [renameparrentID, setRenameParrentID] = useState("");
  const [renameCompanyID, setRenameCompanyID] = useState("");
  const [expanded, setExpanded] = useState<any>([]);
  const [selected, setSelected] = React.useState([]);
  const [selectedFoldr, setSelectedFolder] = useState<any>([]);
  const [draggingChildes, setDraggingChildes] = useState<any>([])
  const [trialPopupStatus, setTrialPopupStatus] = useState<boolean>(false);

  const selectFolderFunction = (event: any) => {
    const selectedID = String(event.currentTarget.id);
    if (showEdit === false) {
	//
	var imp =event.currentTarget.dataset.imp;
	//var allparents =event.currentTarget.dataset.allparents;
	//var allchildes =event.currentTarget.dataset.allchildes;
	var pushed=true;
	//var allparentsArr=[];
	//var allparentsArr:string[] = [];
	//var allchildesArr:string[] = [];

	var exp:string[] = [];
	var index;
	if(imp){
		exp=imp.split(',');
	}
	if(selectedID === '0'){
		if(exp.includes(selectedID)){
			pushed=false;
		}
		exp=[];
	}else{
		index = exp.indexOf('0');
		if (index >= 0) {
			exp.splice( index, 1 );
		}
	}
	if(!exp.includes(selectedID)){
		if(pushed === true){
			/*if(allparents){
				 allparentsArr =allparents.split(','); 
				 allparentsArr.forEach((Item:any, index:any) => {
					index = exp.indexOf(Item);
					if (index >= 0) {
						exp.splice( index, 1 ); // disabled for allow all
					}
					//exp.push(Item); // added for allow all
				 });
			}if(allchildes){
				 allchildesArr =allchildes.split(','); 
				 allchildesArr.forEach((Item:any, index:any) => {
					index = exp.indexOf(Item);
					if (index >= 0) {
						//exp.splice( index, 1 ); // disabled for allow all
					}
					if(!exp.includes(String(Item))){
						exp.push(Item); // added for allow all
					}
				 });
			}*/
			if(!exp.includes(selectedID)){
				exp.push(selectedID);
			}
			
		}
	}else{
			
			/*if(allparents){ // added for allow all
				 allparentsArr =allparents.split(','); 
				 allparentsArr.forEach((Item:any, index:any) => {
					index = exp.indexOf(Item);
					if (index >= 0) {
						exp.splice( index, 1 );
					}
					
				 });
			}
			if(allchildes){ // added for allow all
				 allchildesArr =allchildes.split(','); 
				 allchildesArr.forEach((Item:any, index:any) => {
					index = exp.indexOf(Item);
					if (index >= 0) {
						exp.splice( index, 1 );
					}
					
				 });
			}*/
			
			index = exp.indexOf(selectedID);
			if (index >= 0) {
				exp.splice( index, 1 );
			}
	}
	//
    
    if(exp.length<=0)
    {
       setFolderID(0);
    } else {
      setFolderID(exp);
    }
      setMoveList([]);
      setSelectedFolder(exp);
    }
  };
  
  useEffect(() => {
    let data: any[] = [0];
    let parentId:any = ParamFolderId ? ParamFolderId : '' ;
    const expdParent = (child: any , folderId: any) => {  
      const expd = (child: any) => {
        child.forEach((item: any) => {
          if(item.id === folderId){
            data.push(String(item.parent_id));
            parentId = item.parent_id;
            expdParent(fileTreeData.childes,parentId)
            return;
          }
        if (item.childes) {
           expd(item.childes);
         }
        });
       };
       expd(child);    
    }
    expdParent(fileTreeData.childes,parentId);
    setFolderID([]);
    setMoveList([]);
    setSelectedFolder([]);
    setExpanded(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
	useEffect(() => {
    if(URL==='campaign')
	{ 
		var campReload = localStorage.getItem('campReload');
		if(campReload === 'true'){
			setSelectedFolder([]);
		}
	}
    // eslint-disable-next-line 
  }, [URL]);
  
  // useEffect(() => {
    // let data: any[] = expanded;    
    // const expd = (child: any) => {
    //  child.forEach((item: any) => {
    //   dataChild.push(String(item.id));
    //  if (item.childes) {
    //     expd(item.childes);
    //   }
    //  });
    // };
    // expd(fileTreeData.childes);
    // setExpanded(data);

  // }, [fileTreeData]);


  const handleToggle = (event: any, nodeIds: any) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: any, nodeIds: any) => {
    setSelected(nodeIds);
  };

  const openEdit = (event: any) => {
    const selectedFolderName = event.currentTarget.id;
    const selectedFolderID = event.currentTarget.value;
    const selectdParrentID = event.currentTarget.dataset.id;
    const selectdCompanyID = event.currentTarget.dataset.value;
    setRenameFolder(selectedFolderName);
    setExistRenameFolder(selectedFolderName);
    setRenameParrentID(selectdParrentID);
    setRenameFolderID(selectedFolderID);
    setRenameCompanyID(selectdCompanyID);
    setShowEdit(true);
  };

  const closeEdit = () => {
    setShowEdit(false);
    if (existRenameFolder !== renameFolder) {
      renameCampFolder();
    }
  };

  const handleClickAwayEdit = () => {
    setShowEdit(false);
    if (existRenameFolder !== renameFolder) {
      renameCampFolder();
    }
  };
  const getAllChildesId = (folder: any) => {
    let data: any[] = [];
    const expd = (child: any) => {
      child.forEach((item: any) => {
        data.push(String(item.id));
        if (item.childes) {
          expd(item.childes);
        }
      });
    };
    if (folder.childes.length > 0) {
      expd(folder.childes);
    }
    return data
  }


  const handleDragOver = useCallback((e: any) => {
    e.preventDefault();
  }, [])

  const handleDragStart = useCallback((e: any, folderId: string) => {
    const findFolder = (folder: any, id: string): any => {
      if (folder.id === id) {
        return folder
      } else if (folder.childes !== null) {
        let reqFolder = null
        for (let i = 0; reqFolder == null && i < folder.childes.length; i++) {
          reqFolder = findFolder(folder.childes[i], id)
        }
        return reqFolder
      }
      return null
    }
    const requiredFolder = findFolder(fileTreeData, folderId)
    const ids = getAllChildesId(requiredFolder)

    setDraggingChildes(ids)
    e.dataTransfer.setData("dragingFolderId", folderId)
  }, [fileTreeData])

  const handleDragEnter = useCallback((e: any, folderId: any) => {
    if (draggingChildes.includes(folderId)) {
      setDragOverFolder(null)
    } else {
      setDragOverFolder(folderId)
    }
  }, [draggingChildes, setDragOverFolder])

  const handleDragEnd = useCallback((e: any) => {
    setDragOverFolder(null)
    setDraggingChildes([])
  }, [setDragOverFolder])

  const handleDrop = useCallback((e: any, id: string) => {
    e.preventDefault();
    if (e.dataTransfer.getData("cv_camp_id") && e.dataTransfer.getData("dragable_event")==='1') {
      const cv_camp_id = e.dataTransfer.getData("cv_camp_id");
      moveCampToFolderFunction(id, cv_camp_id)

    } else if (e.dataTransfer.getData("dragingFolderId")) {
      const folderId = e.dataTransfer.getData("dragingFolderId");

      if (folderId !== id.toString() && !draggingChildes.includes(id)) {
        moveFolderDragandDrop(folderId, id)
      }
    }
  }, [draggingChildes, moveCampToFolderFunction, moveFolderDragandDrop])

  const renameCampFolder = () => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("folder_id", renameFolderID);
    data.append("parent_id", renameparrentID);
    data.append("companyId", renameCompanyID);
    data.append("folder_name", renameFolder);

    AxiosInstance.post('api/v1/campaigns_ajax/update_camp_folder', data)
     .then((response: AxiosResponse) => {
        if (response.data.status === true) {
          refreshCampFolders();
        }
      })
  };

  // move releted functions
  const [openMoveDialog, setOpenMoveDialog] = useState<boolean>(false);
  const [selectedFolderName, setSelectedFolderName] = useState("");
  const [moveList, setMoveList] = useState<any>([]);
  const [moveFolderID, setMoveFolderId] = useState<string>();

  const getCampFolders = () => {
  	AxiosInstance.get('api/v1/Campaign/getCampFolders', {
		params: {
			brandID: brandID,
			excluded: moveFolderID,
		},
	}).then((response: AxiosResponse) => {
        if (response.data.status === true) {
          setMoveList(response.data.folders[0]);
        }
      })
  };
  const openMoveFunc = (event: any) => {
    const selectedName = event.currentTarget.id;
    setMoveFolderId(event.currentTarget.dataset.id);
    setSelectedFolderName(selectedName);
    setOpenMoveDialog(true);
  };

  // delete releted functions
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deleteFolderID, setDeleteFolderID] = useState<boolean>(false);

  const openDelete = (event: any) => {
    const selectedFolder = event.currentTarget.id;
    setDeleteFolderID(selectedFolder);
    setOpenDeleteDialog(true);
  };

  const handleCampaignDelete = () => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("folder_id", deleteFolderID);

    AxiosInstance.post('api/v1/campaigns_ajax/delete_camp_folder', data)
     .then((response: AxiosResponse) => {
        if (response.data.status === true) {
          refreshCampFolders();
          setFolderID([]);
          setOpenDeleteDialog(false);
        }
      })
  };
  const handleClickNewFolder = () => {
	  if(isTrial()){
		  setTrialPopupStatus(true);
	  }else{
		 setOpenNew(true);
	  }
  };
  useEffect(() => {
    if(moveFolderID !== undefined) getCampFolders();
    setSelectedFolder(ParamFolderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveFolderID]);

  
  const [openNew, setOpenNew] = useState<boolean>(false);

  return (
  <div>
    <Card elevation={0} className={classes.fileTree} style={{ marginRight: '.5rem'}}>
	<TrialPopup
	    trialPopupStatus={trialPopupStatus}		
		setTrialPopupStatus={setTrialPopupStatus}
      />
      <CreateNewFolder
        open={openNew}
        setOpen={setOpenNew}
        history={history}
        brandID={brandID}
        refreshCampFolders={refreshCampFolders}
      />
      <MoveFolder
        open={openMoveDialog}
        selectedFolderName={selectedFolderName}
        setOpen={setOpenMoveDialog}
        moveList={moveList}
        moveFolderID={moveFolderID}
        brandID={brandID}
        history={history}
        refreshCampFolders={refreshCampFolders}
      />
      <DeleteFolder
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        handleCampaignDelete={handleCampaignDelete}
      />
	  { (isDesigner() || isMarketer()) &&
      <div className={classes.filetreeHeader} onClick={() => handleClickNewFolder()}>
        <div className={classes.filetreeInnerHeader}>
          <CreateNewFolderOutlinedIcon />
          <Typography className={classes.filetreeName}>New Folder</Typography>
        </div>
      </div>
	  }
      <CardContent className={`${classes.treeHeight} ${localStorage.getItem("trial_mode") === 'true' ? classes.hasTrialInfo: ""}`}>
        <div className={classes.treeContainer}>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
          >
            
			<Tree
				fileTreeData={fileTreeData}
				dragOverFolder={dragOverFolder}
				selectedFoldr={selectedFoldr}
				selectFolderFunction={selectFolderFunction}
				handleDragStart={handleDragStart}
				handleDragOver={handleDragOver}
				handleDrop={handleDrop}
				handleDragEnter={handleDragEnter}
				handleDragEnd={handleDragEnd}
				showEdit={showEdit}
				renameFolderID={renameFolderID}
				handleClickAwayEdit={handleClickAwayEdit}
				renameFolder={renameFolder}
				setRenameFolder={setRenameFolder}
				closeEdit={closeEdit}
				openEdit={openEdit}
				openMoveFunc={openMoveFunc}
				openDelete={openDelete}
				draggingChildes={draggingChildes}
				treeName="Emails"
			>
			</Tree>
          </TreeView>
        </div>
      </CardContent>
    </Card>
    </div>
  );
};

export default FolderTree;
