import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useStyles } from "../../../../../styles/styles";
import TreeView from "@material-ui/lab/TreeView";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TrialPopup from "../../../../../utils/TrialPopup";
import TreeList from "../../../../../common/components/TreeList";
import { Box } from "@material-ui/core";

interface Props {
  name:string;
  history: any;
  listFolders: any;
  selectedFolders: any;
  setFolders:any;
}

const FolderTreeList: React.FC<Props> = ({
  name,
  history,
  listFolders,
  selectedFolders,
  setFolders
}) => {
  const classes = useStyles();
  const [trialPopupStatus, setTrialPopupStatus] = useState<boolean>(false); 
  
  // start needed   
  const [expanded, setExpanded] = useState<any>([]);
  //const [selected, setSelected] = useState<any>([]);

  useEffect(() => {
    let data: any[] = [0];
    let parentId:any =  '' ;
    const expdParent = (child: any , folderId: any) => {  
      const expd = (child: any) => {
        child?.forEach((item: any) => {
          if(item.id === folderId){
            data.push(String(item.parent_id));
            parentId = item.parent_id;
            expdParent(listFolders.childes,parentId)
            return;
          }
        if (item.childes) {
           expd(item.childes);
         }
        });
       };
       expd(child);    
    }
    expdParent(listFolders.childes,parentId);
    setExpanded(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[listFolders]);
  
  const handleToggle = (event: any, nodeIds: any) => {
    setExpanded(nodeIds);
  };
  const handleSelect = (event: any, nodeIds: any) => {
    //setSelected(nodeIds);
	//console.log(event.currentTarget);
  };

  const dropDownFolder = (event: any) => {
    const selectedID = String(event.currentTarget.id); 
	var imp =event.currentTarget.dataset.imp;
	var allparents =event.currentTarget.dataset.allparents;
	var allchildes =event.currentTarget.dataset.allchildes;
	var pushed=true;
	//var allparentsArr=[];
	var allparentsArr:string[] = [];
	var allchildesArr:string[] = [];

	var exp:string[] = [];
	var index;
	if(imp){
		exp=imp.split(',');
	}
	if(selectedID === '0'){
		if(exp.includes(selectedID)){
			pushed=false;
		}
		exp=[];
	}else{
		index = exp.indexOf('0');
		if (index >= 0) {
			exp.splice( index, 1 );
		}
	}
	if(!exp.includes(selectedID)){
		if(pushed === true){
			if(allparents){
				 allparentsArr =allparents.split(','); 
				 allparentsArr.forEach((Item:any, index:any) => {
					index = exp.indexOf(Item);
					if (index >= 0) {
						//exp.splice( index, 1 ); // disabled for allow all
					}
					exp.push(Item); // added for allow all
				 });
			}if(allchildes){
				 allchildesArr =allchildes.split(','); 
				 allchildesArr.forEach((Item:any, index:any) => {
					index = exp.indexOf(Item);
					if (index >= 0) {
						//exp.splice( index, 1 ); // disabled for allow all
					}
					exp.push(Item); // added for allow all
				 });
			}
			exp.push(selectedID);
		}
	}else{
			
			/*if(allparents){ // added for allow all
				 allparentsArr =allparents.split(','); 
				 allparentsArr.forEach((Item:any, index:any) => {
					index = exp.indexOf(Item);
					if (index >= 0) {
						exp.splice( index, 1 );
					}
					
				 });
			}*/
			if(allchildes){ // added for allow all
				 allchildesArr =allchildes.split(','); 
				 allchildesArr.forEach((Item:any, index:any) => {
					index = exp.indexOf(Item);
					if (index >= 0) {
						exp.splice( index, 1 );
					}
					
				 });
			}
			
			index = exp.indexOf(selectedID);
			if (index >= 0) {
				exp.splice( index, 1 );
			}
	}
	//setSelected(exp);
	setFolders(exp);
  };

  return (
    <Box className={classes.folderListItemCol}>       
          
      <Card elevation={0} className={classes.fileTree}>
        <TrialPopup
          trialPopupStatus={trialPopupStatus}		
          setTrialPopupStatus={setTrialPopupStatus}
        />
        <CardContent className={`${classes.treeHeight} ${localStorage.getItem("trial_mode") === 'true' ? classes.hasTrialInfo: ""}`}>
          <Box className={classes.treeContainer}>
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                multiSelect={true}
                selected={selectedFolders}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
              >
                <TreeList
                  fileTreeData={listFolders}                  
                  listSelected={selectedFolders}
                  dropDownFolder={dropDownFolder}
                  treeName="All" />
              </TreeView>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default FolderTreeList;
