import React, { useEffect } from "react";
import AxiosInstance from "../../../../../../utils/AxiosInstance";
import { CircularProgress } from "@material-ui/core";
import { AxiosResponse } from "axios";
import TreeView from "@material-ui/lab/TreeView";
import Tree from "../../../../../../common/components/MarketoTree";
import MarketoImageFolderTree from "../../../../../../common/components/MarketoImageFolderTree";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { toast } from "react-toastify";
import moment from "moment";

import {
  Button,
  TextField,
  Typography,
  Dialog,
  Box,
  DialogContent,
  DialogActions,
  DialogTitle,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";

interface Props {
  history?: any;
  mtemplateStatus: any;
  CloseMarketoTemplateModal: any;
  styleData:any;
  marketoFetchData:any;
}
const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  treeContainerError: {
    background: "rgba(0, 0, 0, 0.04)",
    border: "1px solid #FF0000",
    borderRadius: "4px",
    marginTop: "20px",
    padding: "10px",
  },
  treeContainer: {
    background: "rgba(0, 0, 0, 0.04)",
    border: "1px solid rgba(0, 0, 0, 0.38)",
    borderRadius: "4px",
    marginTop: "20px",
    padding: "10px",
    "& .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: "transparent",
    },
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: 350,
  },
  imgError: {
    color:"#dc004e",
    padding:"10px",
    fontSize: "12px",
   
  },
  avatar: {
    width: 80,
    height: 80,
  },
  treeViewDiv: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  treeViewFolder: {
    color: "#e0e0e0",
  },
  treeViewItem: {
    borderRadius: "5px",
  },
  divRoot: {
    "&.Mui-selected  > .MuiTreeItem-content": {
      "& $tickIcon": {
        color: "#73a8e2",
        paddingLeft: "5rem",
        display: "block",
      },
      "& $treeViewFolder": {
        color: "#73a8e2",
      },
    },
  },
  label: {
    fontWeight: "inherit",
    color: "inherit",
    borderRadius: theme.spacing(0.5),
    marginTop: ".3rem",
  },
  tickIcon: {
    display: "none",
  },
  folderHeight: {
    height: "15rem",
    overflow: "scroll",
    borderBottom: "1px solid #c4c4c4",
    paddingRight: "1rem",
  },
  clsNewTemplateName: {
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: 18,
      paddingBottom: 18,
    },
  },
}));

const MarketoCreateTemplate: React.FC<Props> = ({
  history,
  mtemplateStatus,
   CloseMarketoTemplateModal,
   styleData,
   marketoFetchData

}) => {
  const classes = useStyles();
  const [loadTemplatefolder, setloadTemplatefolder] = React.useState<any>([]);
  const [errorFirst, seterrorFirst] = React.useState(false);
  const [helperTextFirst, sethelperTextFirst] = React.useState<string>("");
  const [errorSecond, seterrorSecond] = React.useState(false);
  const [helperTextSecond, sethelperTextSecond] = React.useState<string>("");
  const [errorThird, seterrorThird] = React.useState(false);
  const [errorFourth, seterrorFourth] = React.useState(false);
  const [errorFifth, seterrorFifth] = React.useState(false);
  const [helperTextFifth, sethelperTextFifth] = React.useState<string>("");
  const [expanded, setExpanded] = React.useState<any>([]);
  const [selected, setSelected] = React.useState([]);
  const [parrentId, setParrentId] = React.useState<string>("");
  const [ImgFolderparentId, setImgFolderparentId] = React.useState<string>("");
  const [ImgisdisplayForFolders, setImgisdisplayForFolders] =React.useState<any>(false);
  const [loadImagesfolder, setloadImagesfolder] = React.useState<any>([]);
  const [enableDropdown, setenableDropdown] = React.useState<any>(false);
  const [marketoespId, setmarketoespId] = React.useState<any>("");
  const [styleguideId, setstyleguideId] = React.useState<any>(localStorage.getItem("defaultStyleId"));
  const [mtemplateFolderId, setmtemplateFolderId] = React.useState<any>("");
  const [mImageFolderId, setmImageFolderId] = React.useState<any>("");
  const [newTemplateName, setnewTemplateName] = React.useState<string>("");
  const [buttondisable, setbuttondisable] = React.useState(false);
  const [loader, setloader] = React.useState(false);
  const [espdefaultvalue, setespdefaultvalue] = React.useState<string>("");
  //const [styleFetchData, setstyleFetchData] = React.useState<any>([]);
   ///const [marketoFetchData, setmarketoFetchData] = React.useState<any>([]);

  
 /*const loadStyleGuideData = () => {
      // var totalLength = 0;
  	AxiosInstance.get(`api/getStyleguide/${localStorage.getItem("company_id")}`)
      .then((response: AxiosResponse) => {
          if(Object.keys(response.data.usertable).length>0)
          {
              //totalLength = Object.keys(response.data.usertable).length;
              setstyleFetchData(response.data.usertable);
              //setdefaultStyle(response.data.usertable[0]['id']);
              //localStorage.setItem('defaultStyleId', response.data.usertable[0]['id']);
              //setstylecount(totalLength);
          }
      });
  };
  
   const loadMarketoaccountData = () => {
        var FormData = require("form-data");
        var data = new FormData();
        data.append("p_name", 'marketo');
        AxiosInstance.post("api/v1/Integration/all_accounts", data).then(
          (response: AxiosResponse) => {
            if (response && response.data.data.email_providers.length > 0) {
               //setmarketoFetchData(response.data.data.email_providers)
               //console.log(response.data.data.email_providers)
            } 
          }
        );
   }
  */
   useEffect(() => {

      //loadStyleGuideData();
      //loadMarketoaccountData();
    var FormData = require("form-data");
    var data = new FormData();
    data.append("espId", "");
    AxiosInstance.post("api/v1/Integration/all_email_providers", data).then(
      (response: AxiosResponse) => {
        if (response) {
          if (response.data.data) {
            //setallEmailProviders(response.data.data.email_providers);
          } else {
          }
        }
      }
    );
    // eslint-disable-next-line
  }, []);
  const handleInputChange = (event: any) => {
    setnewTemplateName(event.currentTarget.value);
    seterrorFifth(false);
   
  };

  const loadMarketoTemplatefolders = (event: any) => {
      setImgisdisplayForFolders(true);
      seterrorFirst(false);
      sethelperTextFirst('');
      setbuttondisable(true);
      
    var FormData = require("form-data");
    var data = new FormData();
    data.append("espId", event.target.value);
    setespdefaultvalue(event.target.value);

    AxiosInstance.post("api/v1/Integration/marketo_templatfolders", data).then(
      (response: AxiosResponse) => {
        if (response.data.success === true) {
          loadMarketoImagesfolders(event.target.value);
          setmarketoespId(event.target.value)
          setloadTemplatefolder(response.data.folders[0]);
          let data: any[] = [0];
          let parentId: any = "";
          const expdParent = (child: any, folderId: any) => {
            const expd = (child: any) => {
              child.forEach((item: any) => {
                if (item.id === folderId) {
                  data.push(String(item.parent_id));
                  var parentId = item.parent_id;
                  expdParent(loadTemplatefolder.childes, parentId);
                  return;
                }
                if (item.childes) {
                  // eslint-disable-next-line
                  expd(item.childes);
                }
              });
            };
            //expd(child);
          };
          expdParent(loadTemplatefolder.childes, parentId);
          setExpanded(data);
        } else {
             toast.error('Marketo system did not returns the Template folder hierarchy please try with diffrent account.');
             setImgisdisplayForFolders(false);
        }
      }
    );
  };

  const loadMarketoImagesfolders = (MarketoaccId: any) => {
    var FormData = require("form-data");
    //alert(MarketoaccId);
    var data = new FormData();
    data.append("espId", MarketoaccId);
    AxiosInstance.post("api/v1/Integration/marketo_imagesfolders", data).then(
      (response: AxiosResponse) => {
        if (response.data.success === true) {
          setloadImagesfolder(response.data.folders[0]);
          let data: any[] = [0];
          let parentId: any = "";
          const expdParent = (child: any, folderId: any) => {
            const expd = (child: any) => {
              child.forEach((item: any) => {
                if (item.id === folderId) {
                  data.push(String(item.parent_id));
                  var parentId = item.parent_id;
                  expdParent(loadImagesfolder.childes, parentId);
                  return;
                }
                if (item.childes) {
                  // eslint-disable-next-line
                  expd(item.childes);
                }
              });
            };
            //expd(child);
          };
          expdParent(loadImagesfolder.childes, parentId);
          setExpanded(data);
          setImgisdisplayForFolders(false);
          setenableDropdown(true);
          setbuttondisable(false);
          
        } else {
            toast.error('Marketo system did not returns the Images folder hierarchy please try with diffrent account.');
             setImgisdisplayForFolders(false);
        }
      }
    );
  };

  const closeDropDownFolder = (event: any) => {
    const selectedFolder = event.currentTarget.id;
  
    setParrentId(selectedFolder);
  };
  const ImgcloseDropDownFolder = (event: any) => {
    const selectedFolder = event.currentTarget.id;
  
    setImgFolderparentId(selectedFolder);
  };

  const handleToggle = (event: any, nodeIds: any) => {
    setExpanded(nodeIds);
  };
  const handleImgToggle = (event: any, nodeIds: any) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: any, nodeIds: any) => {
    setSelected(nodeIds);
    setmtemplateFolderId(nodeIds);
    seterrorThird(false);
    
  };
  const handleImgSelect = (event: any, nodeIds: any) => {
    setSelected(nodeIds);
    setmImageFolderId(nodeIds);
    seterrorFourth(false)
   
  };
  const setStyleguide = (event: any) => {
   setstyleguideId(event.target.value);
   seterrorSecond(false);
   sethelperTextSecond('');
  };
  const handleClose = () => {
    CloseMarketoTemplateModal(false);
  };
  
  const handleaddTemplate = () => {
      //alert(mtemplateFolderId);
    if(marketoespId === '' || marketoespId === undefined)
    {
        seterrorFirst(true);
        sethelperTextFirst('Please select the Export Account');
    } 
    else if(styleguideId ==='' || styleguideId===undefined)
    {
        seterrorSecond(true);
        sethelperTextSecond('Please select the design system');
    }
    else if(newTemplateName ==='')
    {
        seterrorFifth(true);
        sethelperTextFifth('Please enter the template name');
    }
    else if(mtemplateFolderId ==='')
    {
        seterrorThird(true);
        
    }
    else if(mImageFolderId ==='')
    {
        seterrorFourth(true);
    }
    else {
        
    var FormData = require("form-data");
    var data = new FormData();
    
    data.append("esp_id", marketoespId);
    data.append("templatename", newTemplateName);
    data.append("templatefolderId", mtemplateFolderId);
    data.append("imagesfolderId", mImageFolderId);
    
    
    if(styleguideId === '')
    {
        data.append("assign_styleguide_id", localStorage.getItem("defaultStyleId"));
    } else {
        data.append("assign_styleguide_id", styleguideId);
    }
    data.append("last_updated_time", moment(new Date()));
    setloader(true);

    AxiosInstance.post('api/v1/Contents/save_marketo_template_ajax', data)
      .then((response: AxiosResponse) => {
          //console.log(response);
          
        if (response.data.status === true) {
          handleClose();
          const data = response.data.data.id;
          const reDirectLink = `${window.globalConfig.apiOrigin}${window.globalConfig.subDir && window.globalConfig.subDir.length ? ('/' + window.globalConfig.subDir) : ''}template/build/${data}`;
            window.location.href = reDirectLink;
        } else {
            setloader(false);
            toast.error(response.data.data.message);
        }
      })
      
    }
    
    
  };


  return (
    <div>
      <Dialog
    PaperProps={{ style: {
    width: '600px'
  }}}
        open={mtemplateStatus}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        
      >
        <DialogTitle id="form-dialog-title">
          Create Marketo Template
        </DialogTitle>
        <DialogContent >
          {marketoFetchData && (
            <Box className={classes.root}>
              <TextField
                error={errorFirst}
                 helperText={helperTextFirst}
                select
                fullWidth
                name="marketoaccounts"
                id="marketoaccounts"
                onChange={loadMarketoTemplatefolders}
                variant="outlined"
                defaultValue={espdefaultvalue}
                label="Export Accounts"
              >
                {marketoFetchData.map((Item: any, index: any) => {
                  return <MenuItem value={Item.id}>{Item.label}</MenuItem>;
                })}
              </TextField>
            </Box>
          )}

          {styleData && (
            <Box className={classes.root}>
              <TextField
                 error={errorSecond}
                 helperText={helperTextSecond}
                select
                fullWidth
                name="userRoles"
                id="userRoles"
                defaultValue={localStorage.getItem("defaultStyleId")}
                onChange={setStyleguide}
                variant="outlined"
                label="Design Systems"
              >
                {styleData.map((Item: any, index: any) => {
                  return (
                    <MenuItem value={Item.id}>{Item.styleguide_name}</MenuItem>
                  );
                })}
              </TextField>
            </Box>
          )}
          
           <Box className={classes.root}>
            <TextField
              name="new_name"
              autoFocus
              error={errorFifth}
                 helperText={helperTextFifth}
              fullWidth
              value={newTemplateName}
              onChange={handleInputChange}
              variant="outlined"
              label="Template Name"
              className={classes.clsNewTemplateName}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
            />
          
          </Box>

          <CircularProgress
            style={{
              marginTop: 20,
              width: 18,
              height: 18,
              color: "rgba(0, 0, 0, 0.26)",
              display: ImgisdisplayForFolders ? "block" : "none",
            }}
          />
          
          {errorThird === true && (
        <Typography className={classes.imgError}>
          Please select the Template folder
        </Typography>
        )}

          {enableDropdown === true && (
            <div
              className={`${
                errorThird ? classes.treeContainerError : classes.treeContainer
              }`}
            >
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
              >
                <Tree
                  fileTreeData={loadTemplatefolder}
                  treeName="Template folders"
                  parrentId={parrentId}
                  closeDropDownFolder={closeDropDownFolder}
                ></Tree>
              </TreeView>
            </div>
          )}
          
          {errorFourth === true && (
        <Typography className={classes.imgError}>
          Please select the Images folder
        </Typography>
        )}

          {enableDropdown === true && (
            <div
              className={`${
                errorFourth ? classes.treeContainerError : classes.treeContainer
              }`}
            >
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleImgToggle}
                onNodeSelect={handleImgSelect}
              >
                <MarketoImageFolderTree
                  fileTreeData={loadImagesfolder}
                  treeName="Images folders"
                  ImgFolderparentId={ImgFolderparentId}
                  ImgcloseDropDownFolder={ImgcloseDropDownFolder}
                ></MarketoImageFolderTree>
              </TreeView>
            </div>
          )}
          <DialogActions style={{borderTop: "1px solid #c4c4c4",marginTop:"50px"}}>
          <Button  style={{marginTop:"10px"}} color="primary"  onClick={handleClose}>Cancel</Button>
          {loader === true ? (
          <CircularProgress
            style={{
              width: 18,
              height: 18,
              marginRight:18,
              color: "rgba(0, 0, 0, 0.26)",
              display: ImgisdisplayForFolders ? "block" : "block",
            }}
          />
        ) : (
        <Button style={{marginTop:"10px"}} disabled={buttondisable} variant="contained" color="primary" onClick={handleaddTemplate}>
            Add
          </Button>
        )}
        </DialogActions>
        </DialogContent>
        
      </Dialog>
    </div>
  );
};

export default MarketoCreateTemplate;
