import {logoutUser} from "../../../../utils/AuthUtility";

interface Props {
  history: any;
}
const Logout: React.FC<Props> = ({ history }) => {
  logoutUser();
  return <div></div>;
};

export default Logout;
