import React, { useRef } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "../styles";
import Button from "@material-ui/core/Button";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../redux/actions/styleGuideActions";
import Typography from "@material-ui/core/Typography";
import {AutoSave} from "../StyleGuideAutosave";
interface Props {}

const DynamicFields: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.styleGuide);
  const inputList = state.menuList;
  const classes = useStyles();
  const showFields = inputList.length > 0 ? true : false;
  const droppableRef = useRef<any>();
  // const [showFields, setShowFields] = React.useState<any>({
  //   displayField: inputList.length > 0 ? true : false,
  // });

  const showListFields = () => {
    // setShowFields({ displayField: true });
    if (inputList.length < 1) setInputList([{ title: "", link: "" }]);
  };
  const setInputList = (list: any) => {
    dispatch(addField("menuList", list));
    //AutoSave();
  };
  const handleHideClick = () => {
    // setShowFields({ displayField: false });
    dispatch(addField("menuList", []));
    AutoSave();
  };
  
  const handleBlur = () => {
     AutoSave();
  };
  
  
  const getStyle = (snapshot: any, provided: any, draggableId: any) => {
    if (draggableId === snapshot.draggingFromThisWith) {
      let transform = provided?.draggableProps?.style?.transform;
      let droppableReff = droppableRef.current;

      if (transform) {
        transform = transform?.substring(10)?.split(",");
        let xTranslate = Number(transform[0].replace("px", ""));
        let yTranslate = Number(transform[1].replace("px)", ""));
        let y = yTranslate + provided?.draggableProps?.style?.top;
        let minY = droppableReff.getBoundingClientRect().y;
        let maxY =
          droppableReff.getBoundingClientRect().y +
          droppableReff.getBoundingClientRect().height;

        if (xTranslate > 70 || xTranslate < -70 || y < minY || y > maxY) {
          return {
            ...provided?.draggableProps?.style,
            opacity: 0,
            cusrsor: "pointer",
          };
        } else {
          return {
            ...provided?.draggableProps?.style,
            opacity: 1,
          };
        }
      }
    }
    return provided.draggableProps.style;
  };
  const handleInputChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveClick = (index: any) => {
    const list = [...inputList];
    setInputList(list);
    list.splice(index, 1);
    AutoSave();
  };

  const handleAddClick = () => {
    setInputList([...inputList, {title: "", link: "" }]);
  };

  return (
    <div>
      {showFields ? (
        <>
          <Grid container spacing={1} className={classes.footerGrid}>
            <Grid item xs={12} sm={5} md={4}>
              <Typography variant="subtitle2" className={classes.InputLabel}>
                Title
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              <Typography style={{marginLeft:"75px"}} variant="subtitle2" className={classes.InputLabel}>
                Link
              </Typography>
            </Grid>
          </Grid>
          <div ref={droppableRef} className={classes.dragDropMenuConatiner}>
            <DragDropContext
              onDragEnd={(params: any) => {
                if (!(params.source && params.destination)) return;
                const sourceIndex: number = params.source.index;
                const destinationIndex: number = params.destination.index;

                inputList.splice(
                  destinationIndex,
                  0,
                  inputList.splice(sourceIndex, 1)[0]
                );
                setInputList(inputList);
              }}
            >
              <Droppable droppableId="droppable-1">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {inputList.map((x: any, i: any) => {
                      return (
                        <Draggable
                          key={i}
                          draggableId={"draggable-" + i}
                          index={i}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={getStyle(
                                snapshot,
                                provided,
                                "draggable-" + i
                              )}
                            >
                              <div className="box" style={{position:"relative"}}>
                                <>
                                  <Grid
                                    container
                                    spacing={1}
                                    className={classes.footerGrid}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sm={5}
                                      md={6}
                                      style={{
                                        height: "70px",
                                      }}
                                    >
                                      <TextField
                                        name="title"
                                        autoComplete="title"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        value={x.title}
                                        onBlur={handleBlur}
                                        error={(state.menuItemsError === true && state.menuItems.includes(i) ? true : false)}
                                         helperText={(state.menuItemsError === true && state.menuItems.includes(i) ? 'This field cannot be left blank' : '')}
                                        onChange={(e) =>
                                          handleInputChange(e, i)
                                        }
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={5}
                                      md={6}
                                      style={{
                                        height: "70px",
                                      }}
                                    >
                                      <TextField
                                        name="link"
                                        autoComplete="link"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        onBlur={handleBlur}
                                        error={(state.menuItemsError === true && state.menuItemsLink.includes(i) ? true : false)}
                                         helperText={(state.menuItemsError === true && state.menuItemsLink.includes(i) ? 'This field cannot be left blank' : '')}
                                        value={x.link}
                                        onChange={(e) =>
                                          handleInputChange(e, i)
                                        }
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      sm={1}
                                      md={1}
                                      style={{
                                        height: "70px",
                                        position:"absolute",
                                        right:"-23px"
                                      }}
                                    >
                                      {inputList.length !== 1 ? (
                                        <div
                                          className={classes.fieldSideIconDiv}
                                        >
                                          <HighlightOffRoundedIcon
                                            fontSize="default"
                                            className={classes.fieldSideIcon}
                                            onClick={() => handleRemoveClick(i)
                                            
                                            }
                                          ></HighlightOffRoundedIcon>
                                          <div {...provided.dragHandleProps}>
                                            <MenuRoundedIcon
                                              className={classes.fieldSideIcon}
                                              fontSize="default"
                                            ></MenuRoundedIcon>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className={classes.fieldSideIconDiv}
                                        >
                                          <HighlightOffRoundedIcon
                                            fontSize="default"
                                            className={classes.fieldSideIcon}
                                            onClick={() => handleHideClick()}
                                          ></HighlightOffRoundedIcon>
                                          <div {...provided.dragHandleProps}>
                                            <MenuRoundedIcon
                                              className={classes.fieldSideIcon}
                                              fontSize="default"
                                            ></MenuRoundedIcon>
                                          </div>
                                        </div>
                                      )}
                                    </Grid>
                                  </Grid>
                                  {inputList.length - 1 === i &&
                                    !snapshot.isDraggingOver && (
                                      <Button
                                        className={classes.addNewMenuButton}
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        onClick={handleAddClick}
                                      >
                                        Add
                                      </Button>
                                    )}
                                </>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={showListFields}
        >
          Add
        </Button>
      )}
      {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
    </div>
  );
};

export default DynamicFields;
