import React,{useEffect} from "react";
import { HexColorPicker } from "react-colorful";
import { makeStyles } from "@material-ui/core/styles";
import { useStyles } from "../styles";
import { IconButton, Box } from '@material-ui/core';
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../redux/actions/styleGuideActions";
import {AutoSave} from "../StyleGuideAutosave";
// import { DefaultStyleGuide } from './DefaultStyleGuide'; 
import TextField from '@material-ui/core/TextField';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Clear } from '@material-ui/icons';
import { toast } from 'react-toastify';
interface Props {
  index: any;
  fieldName: string;
  fieldColorName: string;
  fieldColorValue: string;
}

const ColorPallet: React.FC<Props> = ({ index, fieldName, fieldColorName, fieldColorValue }) => {

  const dispatch = useDispatch();
  const mainColorPallet = useSelector((state: any) => state.styleGuide.mainColorPallet);
  const styleGuide = useSelector((state: any) => state.styleGuide);

  const [state, setState] = React.useState<any>({
    displayColorPicker: false,
    firstLoadRedux:true,
  });

  const [color, setColor] = React.useState<any>(
    fieldColorValue || "#fff",
  );
  const [inputColor, setInputColor] = React.useState<any>(
    fieldColorValue || "#fff",
  );
  // const [isRemoved, setIsRemoved] = React.useState<any>(0);
  
  useEffect(() => {
    if(fieldColorValue !== undefined && state.firstLoadRedux){
      setColor(fieldColorValue);    
      setInputColor(fieldColorValue);       
      setState({ firstLoadRedux: false });
    }
    
    // eslint-disable-next-line
  },[fieldColorValue]);
  
  const [inputName, setInputName] = React.useState<string>(fieldColorName || "");
  // if(!fieldColorValue && fieldColorValue !== undefined){
  //   dispatch(addField(fieldName, DefaultStyleGuide[fieldName]));
  //   setColor( DefaultStyleGuide[fieldName] );
  //   dispatch(addField("styleGuideDefault", true));
  // }
  const handleClick = () => {
    setState({ displayColorPicker: true });
    dispatch(addField("editclicked", "false"));
  };

  const handleClose = (color: any) => {
    // AutoSave();
    setState({ displayColorPicker: false });
  };

  const handleChange = (colorhex: any) => {    
    setColor(colorhex);
    setInputColor(colorhex);
  };

  const handleChangeInputValue = (e: any) =>{ 
    let regex = /^[#A-Fa-f0-9]+$/;
    let ifGood = regex.test(e.target.value);
    if(e.target.value.length > 7 || !ifGood) return;
       setInputColor( e.target.value );     
    if(!e.target.value.includes("#") ){
      setInputColor( "#" + e.target.value);
      setColor(  "#" + e.target.value );
    }
    if(e.target.value.length === 7 || e.target.value.length === 4){
      setColor( e.target.value );
    }    
  }

 
  const handleChangeInputName = (name: any) =>{   
    /*let regex = /^[a-zA-Z0-9\s]+$/;
    let ifGood = regex.test(name);
    if(ifGood){
      setInputName(name);    
    } else toast.error("Can't add special characters only spaces, numbers and letters are permissible.")*/
    setInputName(name);
  }
  const changeColorPalletValue = (color:any) => {
    mainColorPallet.forEach((item: any)=>{
      if(item.key === fieldName){
        item.value = color;
        item.dark = color;
      }
    });    
    dispatch(addField("mainColorPallet", mainColorPallet));
  }

  const changeDefaultColorValueRelated = (color:any) => {
   var list:any[] = [];

    mainColorPallet?.forEach((item: any)=>{
      if(item.key === fieldName && item.list_default !== undefined){
        list = item.list_default;
      }
    });
    list?.forEach((fieldName: any) =>{
      dispatch(addField(fieldName, color));
    });
  }

  const changeColorPalletName = (name:any) => {
    mainColorPallet.forEach((item: any)=>{
      if(item.key === fieldName){
        item.name = name;
      }
    });
    dispatch(addField("mainColorPallet", mainColorPallet));
  }
  const handleRemove = () => {

    if(!styleGuide?.styleguideName){
      setTimeout(() => {}, 1200);      
    }
        
    if(mainColorPallet.length === 1) {
      toast.error("Can't remove the last color palette");
      return;
    }
    mainColorPallet.forEach((item: any,index: any)=>{
      if(item.key === fieldName){
          removeMainColorPallete(index);          
          mainColorPallet.splice(index,1);
      }
    });

    dispatch(addField("mainColorPallet", mainColorPallet));   
    // setIsRemoved(Math.random() * 100);
    AutoSave();
  }

  const removeMainColorPallete = (index:number) => {
    var listRemoved:any[] = [];
    var listToAdd:any[] = [];

    if(mainColorPallet.length === index + 1) {
      listRemoved =  mainColorPallet[index].list_default;
      listToAdd = mainColorPallet[index - 1].list_default;
      
      if(listRemoved !== undefined ) {
          if(listToAdd !== undefined ) {
            listToAdd = listToAdd.concat(listRemoved.filter((item) => listToAdd.indexOf(item) < 0));
          } else {
            listToAdd = listRemoved;
          }
          mainColorPallet[index - 1].list_default = listToAdd;                 
      } 
      changeDefaultColorValueRelated(mainColorPallet[index - 1].value);
      
    } else {
      listRemoved =  mainColorPallet[index].list_default;
      listToAdd = mainColorPallet[index + 1].list_default;
      if(listRemoved !== undefined ) {
          if(listToAdd !== undefined ) {
            listToAdd = listToAdd.concat(listRemoved.filter((item) => listToAdd.indexOf(item) < 0));
          } else {
            listToAdd = listRemoved;
          }
          mainColorPallet[index + 1].list_default = listToAdd;                 
      } 
      changeDefaultColorValueRelated(mainColorPallet[index + 1].value);
    }
  }

  useEffect(() => {    
    setInputName(fieldColorName);    
    setInputColor(fieldColorValue);   
    setColor(fieldColorValue);

    // eslint-disable-next-line
  },[mainColorPallet?.length]);

  useEffect(()=>{ 
    if(styleGuide?.editclicked === "false"){
      const delayColor = setTimeout(() => { 
        changeColorPalletValue(color);
        changeDefaultColorValueRelated(color);
        AutoSave();
      }, 750);  
      return () => clearTimeout(delayColor);
    }
    // eslint-disable-next-line
  },[color]);

  useEffect(()=>{ 
    if(styleGuide?.editclicked  === "false"){
      const delayTypingName = setTimeout(() => {        
        changeColorPalletName(inputName);  
        AutoSave();
      }, 1200);  
      return () => clearTimeout(delayTypingName);
    }   

    // eslint-disable-next-line
  },[inputName]);

  const styles = makeStyles((theme) => ({
    color: {
      width: "fit-content",
      border: "1px solid #CDCDCD",
      // margin: 10,
      background: color || "#F8F8F8",
      "&:hover": {
        background: color || "#F8F8F8",
      },
    },

    colorBase: {
      position: "relative",
    },
  }));

  const classes = useStyles();
  const classCss = styles();

  return (
    <div className={fieldName}>
      <div className={classCss.colorBase}>
        <IconButton className={classCss.color} onClick={handleClick}>
          <AddOutlinedIcon
            fontSize="inherit"
            style={{ color: "transparent" }}
          />
        </IconButton>
         { mainColorPallet?.length > 1 ? (<Clear className={classes.clearColorPallet} onClick={handleRemove} />) : ""  }
      </div>
      {state.displayColorPicker ? (
        <div className={`${classes.popover} ${classes.hcPopover}`}> 
            <div className={classes.cover} onClick={handleClose} />
            <div className={classes.hexColorPicker}>
              <HexColorPicker color={inputColor} onChange={(e) => handleChange(e)} />
              <Box className={classes.hcPickerValue}>
                <TextField
                  id="outlined-start-adornment"
                  label="Value"
                  variant="outlined"
                  value={inputColor}
                  onChange={handleChangeInputValue}
                  InputProps={{
                    endAdornment: <Box style={{background:color,padding:12,borderRadius:4}}></Box>,
                  }}
                />
              </Box>
              <Box className={classes.hcPickerName}>
                <TextField
                    id="outlined-start-adornment"
                    label="Name"
                    variant="outlined"
                    value={inputName}
                    onChange={(e)=>handleChangeInputName(e.target.value)}
                  />
              </Box>
            </div>          
        </div>
      ) : null}
    </div>
  );
};

export default ColorPallet;
