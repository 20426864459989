import React from "react";
import { useStyles } from "./styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Header from "./components/Header";
import FileList from "./components/FileList";
import FileListSkleton from "./components/skleton/fileList";
import { Container } from "@material-ui/core";
import {getUserFromToken, isTrial, redirectTo} from "../../../../../utils/AuthUtility";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
interface Props {
  history: any;
}

const NewCampaign: React.FC<Props> = ({ history }) => {
  const classes = useStyles();

	const loggedInUser = getUserFromToken();

  const brandID = loggedInUser.user_detail.Company;
  const [campFolders, setCampFolders] = React.useState<any>([]);
  const [Styleguidedropdown, setStyleguidedropdown] = React.useState<any>([]);
  const [defaultStyle, setdefaultStyle] = React.useState<any>([]);
  const [totalLength, settotalLength] = React.useState<any>(0);
  const URL = window.location.pathname.split('/').pop();
  const getCampFolders = () => {
    AxiosInstance.get('api/v1/Campaign/getCampFolders', {
		params: {
			brandID: brandID,
		}
	}).then((response: AxiosResponse) => {
        if (response.data.status === true) {
          setCampFolders(response.data.folders[0]);
        }
      })
  };
  
  const loadStyleGuideData = () => {
       var totalLength = 0;
  	AxiosInstance.get(`api/getStyleguide/${localStorage.getItem("company_id")}`)
      .then((response: AxiosResponse) => {
          if(Object.keys(response.data.usertable).length>0)
          {
              totalLength = Object.keys(response.data.usertable).length;
			  setStyleguidedropdown(response.data.usertable);
              setdefaultStyle(response.data.usertable[0]['id']);
              //localStorage.setItem('defaultStyleId', response.data.usertable[0]['id']);
              settotalLength(totalLength);
          }
      });
  };
  
  

  const [templates, setTemplates] = React.useState<any>([]);
  const [listOverlay, setListOverlay] = React.useState<any>(false);
  const [selectedTemplateID, setSelectedTemplateID] = React.useState<any>('');
  const [screenShotItem, setScreenShotItem] = React.useState<any>([]);
  const [renderList, setRenderedLsit] = React.useState([]);
  //const [categoryOverlay, setCategoryOverlay] = React.useState<any>(false);


  const getTemplates = () => {
    AxiosInstance.get('api/v1/Campaign/loadPublishedTemplates', {
		params: {
			brandID: brandID,
			temp_cat_id: selectedList
		}
	}).then((response: AxiosResponse) => {
        if (response.data.status === true) {
          setTemplates(response.data.templates);
          setListOverlay(true);
        }
      })
  };

  //const [categoryList, setCategoryList] = React.useState<any>([]);

 /* const getCategories = () => {
    AxiosInstance
		.get('api/v1/Contents/get_templates_categories')
    	.then((response: any) => {
			if (response.data.status === true) {
			  setCategoryList(response.data.data.template_categories);
			  setCategoryOverlay(true)
			}
    	})
  };*/

  const [selectedList, setSelectedList] = React.useState("");
  const [folderDropDown, closeFolderDropDown] = React.useState(false);

  React.useEffect(() => {
	 
    getCampFolders();
    loadStyleGuideData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandID,folderDropDown]);
   React.useEffect(() => {
    if(URL==='landing')
	{ 
		var LandedReload = localStorage.getItem('LandedReload');
		if(LandedReload === 'true'){
			getCampFolders();
			setStyleguidedropdown([]);
			loadStyleGuideData();
		}
		
	  localStorage.setItem('LandedReload',JSON.stringify(false));
	}
    // eslint-disable-next-line 
  }, [URL]);
  
  React.useEffect(() => {
    getTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandID, selectedList]);
  React.useEffect(() => {
    //getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
	if(isTrial()){
	 redirectTo('/campaign');
	}
  }, []);
  return (
    <Box className={classes.newCampaignRoot}>
      <Header
        history={history}
        campFolders={campFolders}
        Styleguidedropdown={Styleguidedropdown}
        defaultStyle={defaultStyle}
        totalLength={totalLength}
        brandID={brandID}
        selectedTemplateID={selectedTemplateID}
        categoryList={[]}
        categoryOverlay={false}
        setCampFolders={setCampFolders}
        getCampFolders={getCampFolders}
        selectedList={selectedList}
        setSelectedList={setSelectedList}
        folderDropDown={folderDropDown}
        closeFolderDropDown={closeFolderDropDown}
      />
      <Box className={classes.newCampaignBody}>
        <Container>
          <Grid container spacing={2} alignItems="center" justify="center">
            {listOverlay === false ? (
              <FileListSkleton />
            ) : (
              <FileList
                templates={templates}
                setSelectedTemplateID={setSelectedTemplateID}
                setScreenShotItem={setScreenShotItem}
                screenShotItem={screenShotItem}
                history={history}
				renderList={renderList}
                setRenderedLsit={setRenderedLsit}
              />
            )}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default NewCampaign;
