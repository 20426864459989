import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {
	Avatar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	TextField,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Field, Form, Formik} from "formik";
import {toast} from "react-toastify";
import Typography from "@material-ui/core/Typography";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";

interface Props {
	open: any;
	isAccountSetting?: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	userId?: any;
	reloadLists?: any;
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		"& > *": {
			margin: theme.spacing(1.2),
		},
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		width: 350,
	},
	boxRow: {
		display: "flex",
		alignItems: "center",
		paddingBottom: 17,
	},
	avatar: {
		marginTop: 0,
		width: 70,
		height: 70,
	},
	InputText: {
		marginTop: "0",
		flex: 1,
		"& input ": {
			padding: "7px 7px 7px 12px",
			height: "unset"
		},
		"& .MuiSelect-selectMenu ": {
			padding: 7,
		},
		"& > div ": {
			borderRadius: "2px !important"
		},
		"& input::placeholder": {
			fontSize: "14px",
			color: "#999999"
		}
	},
	InputLabel: {
		fontWeight: 500,
		color: "#000000",
		fontSize:16,
		width: 110
	},
		form: {
		minWidth: 422,
		padding: "15px 15px 5px 15px",
	},
	dialogTitle: {
		padding: "7px 15px 15px 15px",
		"& > h2": {
			fontSize: "24px",
		}
	},
	dialogContent: {
		padding: "15px 15px 7px 15px",
		overflow: "hidden",
		overflowY: "scroll"
	},
	btnCancelNobelO: {
		border: "1px solid #969696",
		color: "#676767",
		borderRadius: 2,
		fontSize: 16,
		fontWeight: "normal",
		padding: "3px 22px",
		"&:hover": {
			background: "#969696",
			color: "#ffffff"
		}
	},
	btnPrimary: {
		background: "#057CFF",
		borderRadius: "3px",
		color: "#ffffff",
		fontSize: 16,
		fontWeight: "normal",
		padding: "5px 30px",
		"&:hover": {
			background: "#0a6adc",
		}
	},
	dialogActions: {
		borderTop: "1px solid #e5e5e5",
		padding: "23px 0 15px 0",
		margin: "0 15px"
	}
}));
const AccountSettings: React.FC<Props> = ({
											  open,
											  isAccountSetting,
											  setOpen,
											  reloadLists,
											  userId,
										  }) => {
	const classes = useStyles();
	const [selectedImage, setSelectedImage] = useState<any>(null);
	const [initialState, setInitialState] = useState<any>({
		usr_name: "",
		usr_surname: "",
		usr_email: "",
		usr_active: "",
		usr_role: "",
	});
	const getUser = (userId: any) => {
		AxiosInstance.post(`api/v1/settings/get_user/${userId}`)
			.then((response: AxiosResponse) => {
				setInitialState(response.data.data.user_info);
				var allow_menu = response.data.data.user_info.allow_menu;
				localStorage.setItem('allow_menu',allow_menu);
			})
	};
	useEffect(() => {
		if (userId) getUser(userId);
	}, [userId]);
	const handleClose = () => {
		setOpen(false);
	};
	const handleUploadClick = (event: any) => {
		var file = event.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onloadend = function (e) {
			setSelectedImage({file, src: reader.result});
		};
	};
	const onSubmit = (form: any) => {

		const FormData = require("form-data");
		let data = new FormData();
		data.append("Userid", initialState.usr_id);
		if (selectedImage) {
			data.append("profile_image", selectedImage.file);
			AxiosInstance.post('api/v1/settings/update_profile_image', data)
				.then((response: AxiosResponse) => {
					reloadLists && reloadLists();
					setOpen(false);
				}).catch(err => {
					if (err.response)
						toast.warning(err.response?.data?.data?.message);
				});
		} else {
			data.append("fname", form.usr_name);
			data.append("lname", form.usr_surname);
			AxiosInstance.post('api/v1/settings/update_fname_lname', data)
				.then((response: AxiosResponse) => {
					reloadLists && reloadLists();
					setOpen(false);
				});
		}
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<Formik
					enableReinitialize={true}
					initialValues={initialState}
					onSubmit={onSubmit}
				>
					{() => (
						<Form className={classes.form}>
							<DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
								{isAccountSetting
									? "Account Settings"
									: userId
										? "Edit User"
										: "Create New User"}
							</DialogTitle>
							<DialogContent className={classes.dialogContent}>
								<Box className={classes.boxRow}>
									<Typography
										variant="subtitle2"
										className={classes.InputLabel}
									>
										Profile Image
									</Typography>
									<div style={{flex: 1}}>
										<Avatar
											className={classes.avatar}
											src={
												selectedImage
													? selectedImage.src
													: userId
													? `assets/users/${initialState.profile_image}`
													: ""
											}
										/>
									</div>
									
									<label htmlFor="file-upload">
										<Typography
											style={{
												backgroundColor: "#ffffff",
												color: "#057CFF",
												cursor: "pointer",
												fontWeight: "normal"
											}}
											// variant="contained"
											// component="span"
											variant="subtitle2"
											// startIcon={<CloudUploadIcon />}
										>
											Upload Image
										</Typography>
									</label>
									<input
										accept="image/*"
										onChange={handleUploadClick}
										hidden
										id="file-upload"
										type="file"
									/>
								</Box>
								{/* <Box className={classes.root}>
									<Avatar
										className={classes.avatar}
										src={
											selectedImage
												? selectedImage.src
												: userId
												? `${process.env.REACT_APP_API_ORIGIN}/assets/users/${initialState.profile_image}`
												: ""
										}
									/>
									<label htmlFor="file-upload">
										<Button
											variant="contained"
											component="span"
											color="primary"
											size="small"
											startIcon={<CloudUploadIcon/>}
										>
											Upload Image
										</Button>
									</label>
									<input
										accept="image/*"
										onChange={handleUploadClick}
										hidden
										id="file-upload"
										type="file"
									/>
								</Box> */}
								<Box className={classes.boxRow}>
									<Typography
										variant="subtitle2"
										className={classes.InputLabel}
									>
										First Name
									</Typography>
									<Field
										autoFocus
										as={TextField}
										name="usr_name"
										margin="dense"
										required
										className={classes.InputText}
										placeholder="First Name"
										fullWidth
									/>
								</Box>
								<Box className={classes.boxRow}>
									<Typography
										variant="subtitle2"
										className={classes.InputLabel}
									>
										Last Name
									</Typography>
									<Field
										margin="dense"
										as={TextField}
										className={classes.InputText}
										placeholder="Last Name"
										name="usr_surname"
										fullWidth
									/>{" "}
									{!isAccountSetting && (
										<>
											<div className={classes.boxRow}>
												<Typography
													variant="subtitle2"
													className={classes.InputLabel}
												>
													Email
												</Typography>
												<Field
													margin="dense"
													as={TextField}
													name="usr_email"
													type="email"
													required
													className={classes.InputText}
													placeholder="Email"
													fullWidth
												/>
											</div>
											<div className={classes.boxRow}>
												<Typography
													variant="subtitle2"
													className={classes.InputLabel}
												>
													Status
												</Typography>
												<Field
													margin="dense"
													as={TextField}
													name="usr_active"
													className={classes.InputText}
													placeholder="Status"
													select
													fullWidth
												>
													<MenuItem value="1">Active</MenuItem>
													<MenuItem value="0">Not Active</MenuItem>
												</Field>
											</div>
											<div className={classes.boxRow}>
												<Typography
													variant="subtitle2"
													className={classes.InputLabel}
												>
													Access
												</Typography>
												<Field
													margin="dense"
													as={TextField}
													name="usr_role"
													required
													className={classes.InputText}
													placeholder="Access"
													select
													fullWidth
												>
													<MenuItem value="1">Admin</MenuItem>
													<MenuItem value="4">Copywriter</MenuItem>
													<MenuItem value="2">Designer</MenuItem>
													<MenuItem value="5">Marketer</MenuItem>
												</Field>
											</div>
										</>
									)}
								</Box>
							</DialogContent>
							<DialogActions className={classes.dialogActions}>
								<Button onClick={handleClose} color="primary" className={classes.btnCancelNobelO}>
									Cancel
								</Button>
								<Button type="submit" color="primary" className={classes.btnPrimary}>
									Save
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default AccountSettings;
