import React, { useState, useEffect } from "react";
import CardMedia from "@material-ui/core/CardMedia";
import Skeleton from "@material-ui/lab/Skeleton";
import Screenshot from '../../../../../../../utils/Screenshot';
import Card from '@material-ui/core/Card';

interface Props {
  thumb: string;
  classes: string;
  tempId: string;
}

const TemplateImage: React.FC<Props> = ({ thumb, classes, tempId }) => {

  const [screenshot, setScreenshot] = useState(thumb);
  
  const loadScreenShot = async () => {
    const screenshot = await new Screenshot(
      tempId, 
      "template", 
      600, 
      `template/template_screenshot/${tempId}?mute=true`
    ).load();
    setScreenshot(screenshot);   
  };

  useEffect(() => {
    loadScreenShot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempId]);

   
  return (
          <>
            <Card elevation={0} key={tempId}>
              {screenshot != null ? (
                <CardMedia
                  image={screenshot}
                  className={classes}
                />
              ) : (
                <Skeleton
                  animation="wave"
                  variant="rect"
                  className={classes}
                />
              )}
              </Card>
          </>
  );
};

export default TemplateImage;
