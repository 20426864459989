import React, { useEffect } from "react";
import { useStyles } from "./style";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FolderTree from "./components/FolderTree/FolderTree";
import Sidebarcomponents from "./components/FolderTree/Sidebarcomponents";
import FileList from "./components/FileList/FileList";
import FileListSkleton from "./components/skleton/fileList";
import FileTreeSkleton from "./components/skleton/fileTree";
import Typography from "@material-ui/core/Typography";
import {isDesigner, isMarketer,  getUserFromToken} from "../../../../../utils/AuthUtility";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
interface Props {
  history?: any;
  imageFolderID: string;
  setImageFolderID: any;
  createImage: any;
  onSelect?: (path: string, originId?: number) => void;
  isHeadless: boolean;
  funcsetOpenImage:any;
  selectedImages: any;
}

const Gallery: React.FC<Props> = ({
  history,
  imageFolderID,
  setImageFolderID,
  createImage,
  onSelect,
  isHeadless,
  funcsetOpenImage,
  selectedImages
}) => {
  const classes = useStyles();

	const loggedInUser = getUserFromToken();
  const [campFolders, setCampFolders] = React.useState<any>([]);
  const [folderOverlay, setFolderOverlay] = React.useState<any>(false);
  const URL = window.location.pathname.split('/').pop();
  const getCampFolders = () => {
  	AxiosInstance.get('api/v1/Contents/getGalleryFolders')
    	.then((response: AxiosResponse) => {
			if (response.data.data.success === true) {
			  setCampFolders(response.data.data.folders[0]);
			  setFolderOverlay(true);
			}
    	});
  };

  const [loadImages, setImagesByComp] = React.useState<any>([]);

  const [listnumRows, setListnumRows] = React.useState<any>(0);
  const [listshowing, setListshowing] = React.useState<any>(0);
  const [listNextPage, setListNextPage] = React.useState<any>(1);
  const [listOverlay, setListOverlay] = React.useState<any>(false);
  const [listCompleteStatus, setListCompleteStatus] =
    React.useState<boolean>(true);
  const [querySearch, setQuerySearch] = React.useState<string>("");
  const [screenShotItem, setScreenShotItem] = React.useState<any>([]);
  const [dragOverFolder, setDragOverFolder] = React.useState("");
  var brandID : any = '';
  if(loggedInUser !==null )
  {
       brandID = loggedInUser.user_detail.Company;
  }

  const getImagesByComp = () => {
  	AxiosInstance.get('api/v1/Contents/company_images', {
		params: {
			folderID: imageFolderID,
			query: querySearch,
			page: listNextPage,
		},
	}).then((response: AxiosResponse) => {
        if (response.data.status === true) {
          setImagesByComp(loadImages.concat(response.data.data.images));

          setListnumRows(response.data.data.numRows);
          setListshowing(response.data.data.showing);
          setListNextPage(response.data.data.nextPage);
          if (response.data.data.numRows === response.data.data.showing) {
            setListCompleteStatus(false);
          } else {
            setListCompleteStatus(true);
          }
          setListOverlay(true);
        }
      });
  };
 const getImagesByCompInitial1 = () => {
	const newList = [...loadImages];
	newList.splice(0, 0, createImage)
	setImagesByComp(newList);
	setListshowing(listshowing+1);
	setListnumRows(listnumRows+1);
 };
  const getImagesByCompInitial = () => {
  	AxiosInstance.get('api/v1/Contents/company_images', {
		params: {
			folderID: imageFolderID,
			query: querySearch,
			page: 1,
		},
	})
		.then((response: AxiosResponse) => {
        if (response.data.status === true) {
          setImagesByComp(response.data.data.images);
          setListnumRows(response.data.data.numRows);
          setListshowing(response.data.data.showing);
          setListNextPage(response.data.data.nextPage);
          if (response.data.data.numRows === response.data.data.showing) {
            setListCompleteStatus(false);
          } else {
            setListCompleteStatus(true);
          }
          setListOverlay(true);
        }
      })
  };

  // move folder drag and drop
  const moveFolderDragandDrop = (folderId: number, parrent_id: number) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("folder_id", folderId);
    data.append("parent_id", parrent_id);
    data.append("newBrandID", brandID);

    AxiosInstance.post('api/v1/Contents/move_folder', data)
     .then((response: AxiosResponse) => {
        if (response.data.status === true) {
          getCampFolders();
        }
      })
  };

  const moveImageToFolderFunction = (folderId: number, origin_id: number) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("tag_id", folderId);
    data.append("company_id", brandID);
    data.append("origin_id", origin_id);

    AxiosInstance.post('api/v1/Contents/move_tags', data)
     .then((response: AxiosResponse) => {
        if (response.data.status === true) {
          getImagesByCompInitial();
        }
      })
  };
  
  const getDisabled = () => {
	  if(!isDesigner() && !isMarketer() && isHeadless === false){
		  return classes.disabledList;
	  }
	  return '``';
  };
  
  useEffect(() => {
    if(URL === "content" || URL === "gallery") getCampFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandID, URL]);
  useEffect(() => {
  	getImagesByCompInitial1();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createImage]);

  useEffect(() => {
    setImagesByComp([]);
	getImagesByCompInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySearch, imageFolderID]);
  useEffect(() => {
	  var ImageReload = localStorage.getItem('ImageReload');
    if(URL === "content" || URL === "gallery") {
		
		if(ImageReload === 'true'){
			setImagesByComp([]);
			setImageFolderID([]);
			getImagesByCompInitial();
			setListnumRows(0);
			setListshowing(0);
			setListNextPage(1);
			localStorage.setItem('ImageReload',JSON.stringify(false));
		}
		
	}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [URL]);

  return (
    <Box className={classes.templateHeader}>
      <Grid container style={{marginTop: '1rem'}}>
         <Grid item xs={12} sm={12} md={4} style={{paddingRight:10,maxWidth:412}}>
         <div className={getDisabled()}>
         <Sidebarcomponents
                querySearch={querySearch}
                setQuerySearch={setQuerySearch}
                setListNextPage={setListNextPage}
                setImagesByComp={setImagesByComp}
                funcsetOpenImage={funcsetOpenImage}
                />
	     </div>
                
            {folderOverlay === false ? (
            
             <FileTreeSkleton />
            
            ) : (
            <FolderTree
            fileTreeData={campFolders}
            setFolderID={setImageFolderID}
            history={history}
            brandID={brandID}
            refreshCampFolders={getCampFolders}
            moveFolderDragandDrop={moveFolderDragandDrop}
            moveImageToFolderFunction={moveImageToFolderFunction}
            setDragOverFolder={setDragOverFolder}
            dragOverFolder={dragOverFolder}
            
            />
              )}
            </Grid>
        <Grid item className={classes.mainBody}>
          <Grid container className={classes.fileListGrid}>
            {listOverlay === false ? (
              <>
                <FileListSkleton />
              </>
            ) : (
              <FileList
                getImagesByComp={getImagesByComp}
                listCompleteStatus={listCompleteStatus}
                fileMapData={loadImages}
                screenShotItem={screenShotItem}
                setScreenShotItem={setScreenShotItem}
                history={history}
                getImagesByCompInitial={getImagesByCompInitial}
				getImagesByCompInitial1={getImagesByCompInitial1}
                setImagesByComp={setImagesByComp}
                brandID={brandID}
                setDragOverFolder={setDragOverFolder}
                dragOverFolder={dragOverFolder}
				onSelect={onSelect}
				isHeadless={isHeadless}
				selectedImages={selectedImages}
              />
            )}
          </Grid>
           <Grid item xs={12} sm={12} md={4}></Grid>
        <Grid item xs={12} sm={12} md={12} className={classes.listCount}>
          <Typography>
            showing {listshowing} out of {listnumRows} results
          </Typography>
        </Grid>
        </Grid>
       
      </Grid>
    </Box>
  );
};

export default Gallery;
