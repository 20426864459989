import React, { Dispatch, SetStateAction } from "react";
import {
  Dialog,
  
} from "@material-ui/core";


import BrazeExport from './BrazeExport';
 interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  FrontEndEspId:any;
  FrontendversionId:any;
  FrontEndcname:any;
  FrontEndSubject:any;
  FrontEndProfile:any;
  
  
}

const BrazeExportDialog: React.FC<Props> = ({
  open,
  setOpen,
  FrontEndEspId,
  FrontendversionId,
  FrontEndcname,
  FrontEndSubject,
  FrontEndProfile,
  
}) => {

 
  const handleClose = () => {
    setOpen(false);
  };

   return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
      <BrazeExport FrontEndSubject={FrontEndSubject} FrontEndProfile={FrontEndProfile} handleClose={handleClose} FrontEndcname={FrontEndcname} FrontendversionId={FrontendversionId}  FrontEndEspId={FrontEndEspId}  />
       
      </Dialog>
    </div>
  );
};

export default BrazeExportDialog;
