import React, { Dispatch, SetStateAction,useEffect,useState } from "react";
//import { addField } from "../../../redux/actions/styleGuideActions";
import {
  Dialog,
  Typography,
  Button,
  Box,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
   open: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
  closestyle:any;
  styleIdFromres:any;
}
const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: 350,
  },
  text: {
    margin: "0",
    paddingBottom: "1rem",
    borderBottom: "1px solid #c4c4c4",
  },
  textColor:{
      color:"red"
  },
  loader:{
      width:"28px",
      height:"28px"
  },
  topdiv:{
      paddingBottom:"0px",
      paddingLeft:"24px"
  },
  mgtop:{
      marginTop:"25px"
  },
  disabledclass:{
      pointerEvents:"none",
      backgroundColor:"#D0D0D0"
      
  }
}));
const ErrorConfirm: React.FC<Props> = ({ open, setOpen,closestyle,styleIdFromres}) => {
  const classes = useStyles();
  //const [newstyleguideId, setnewstyleguideId] = useState<any>(null);
 const [updateflag, setupdateflag] = useState<any>(null);
 const [dialogTitle, setdialogTitle] = useState<any>('');
 //const [styleguidestatus, setstyleguidestatus] = useState<any>(null);

  const handleClose = () => {
    setOpen(false);
  };
  
 
    useEffect(() => {
        
    /*var styleId: any = null;
    if(localStorage.getItem("styleGuideResID")===
    '' || localStorage.getItem("styleGuideResID")===null)
    {
        styleId=localStorage.getItem("rowId")
    }
    else
    {
        styleId = localStorage.getItem("styleGuideResID");
    }*/
    //setstyleguidestatus(styleId);
    if(styleIdFromres === undefined || styleIdFromres==='')
    {
        setdialogTitle('Add');
    } else {
        setdialogTitle('Update');
    }
    setupdateflag(1); 
  }, [open,styleIdFromres]);
   /*const handleChange = (event: any) => {
  // setnewstyleguideId(event.target.value);
  };*/

  return (
    <div>
      <Dialog
      PaperProps={{ style: {
    width: '600px'
  }}}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.topdiv} id="form-dialog-title">{dialogTitle} Design System</DialogTitle>
        <DialogContent>
          <Box className={classes.root}>
           <Typography className={classes.text}>
                Your Design System currently has configuration errors. Please update all required fields before continuing.
                </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          
        {updateflag===1 ? (
        <Button  variant="contained" disableElevation onClick={handleClose} color="primary">
        Continue 
        </Button>

        ): (
        <Button  variant="contained"  onClick={handleClose} color="primary">
        Continue 
        </Button>
        )}
         
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ErrorConfirm;
