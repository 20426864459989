import React from "react";
import Typography from "@material-ui/core/Typography";
//import { UnControlled as CodeMirror } from "react-codemirror2";
//import "codemirror/lib/codemirror.css";
//import "codemirror/theme/material.css";
//import "codemirror/addon/hint/show-hint.css";
import { Box,TextareaAutosize } from "@material-ui/core";
import { useStyles } from "../styles";
import { AutoSave } from '../StyleGuideAutosave';
import { useDispatch} from "react-redux";
import { addField } from '../../../../redux/actions/styleGuideActions';
import { store } from '../../../../redux/store';

//require("codemirror/mode/css/css");
//require("codemirror/addon/hint/css-hint");
//require("codemirror/addon/hint/show-hint");
//require("codemirror/addon/edit/closebrackets");
//require("codemirror/addon/lint/lint");
//require("codemirror/addon/display/autorefresh");


function CodemirrorExample() {
    
    const divStyle = {
      marginTop:30,
      
 }
 const divStyle3 = {
      marginTop:30,
	  marginBottom:20,

 }
  const divStyle1 = {
      marginBottom:10,
 }
 
  const txtareaSec = {
      minWidth:421,
	  maxWidth:421,
	  minHeight:147,
	  background:"#243343",
	  borderRadius:3,
	  color:"#fff",
	  paddingLeft:10,
	  paddingBottom:10,
	  paddingRight:10,
	  paddingTop:10,
 }
 
 
 const mainheading = {
     borderBottom:"1px solid #c4c4c4",paddingBottom:"20px",marginBottom:"10px!important",
 }
  const top_heading = {
     marginBottom:"20px",display:"block",lineHeight:"21px"
 }
 const sub_heading = {
     fontSize:"14px"
 }

 const classes = useStyles();
const stateInitial = store.getState();
 var head_email_code_value = stateInitial.styleGuide.head_email_code_value;
 var body_top_email_code_value = stateInitial.styleGuide.body_top_email_code_value;
 var body_bottom_email_code_value = stateInitial.styleGuide.body_bottom_email_code_value;
 
 //const [head_email_code, setHeadEmailcode] = useState('')
 //const [body_top_email_code, setTopEmailcode] = useState('')
 //const [body_bottom_email_code, setBottomEmailcode] = useState('')
 
  const dispatch = useDispatch();

  const save_head_email_code = event => {
	dispatch(addField("head_email_code", event.target.value));
      AutoSave();
  };
  
  const save_body_top_email_code = event => {
	dispatch(addField("body_top_email_code", event.target.value));
      AutoSave();
  };
  
   const save_body_bottom_email_code = event => {
	dispatch(addField("body_bottom_email_code", event.target.value));
      AutoSave();
  };
  
 
  
  return (
   <Box display="flex" flexDirection="column">
      <Typography className={classes.heading} variant="h6">
        Coding
      </Typography>
      <Typography variant="caption" style={mainheading} className={classes.captionDetails}>
         Add code to each email built using the design system
      </Typography>
      
      <Box style={divStyle1}>
      <Typography style={sub_heading} className={classes.heading} variant="h6">
        Head
      </Typography>
      <Typography variant="caption" style={top_heading} className={classes.captionDetails}>
         The code below will be added to the Head of your emails If you are adding CSS 
         please include the relevent style tags
      </Typography>
      
		<TextareaAutosize
		style={txtareaSec}
		   multiline
		   defaultValue={head_email_code_value}
		   key={head_email_code_value}
            onKeyUp={save_head_email_code}
		/>
       </Box>
       
       
        <Box style={divStyle}>
      <Typography style={sub_heading} className={classes.heading} variant="h6">
        Body(Top)
      </Typography>
      <Typography variant="caption" style={top_heading} className={classes.captionDetails}>
         The code below will be added directly after the opening body tag of your email.
      </Typography>
      
        <TextareaAutosize
		style={txtareaSec}
		   multiline
		   defaultValue={body_top_email_code_value}
		   key={body_top_email_code_value}
            onKeyUp={save_body_top_email_code}
		/>
       </Box>
       
       
       
        <Box style={divStyle3}>
      <Typography style={sub_heading} className={classes.heading} variant="h6">
        Body (Bottom)
      </Typography>
      <Typography variant="caption" style={top_heading} className={classes.captionDetails}>
        The code below will be added directly before the closing body tag of your email.
      </Typography>
      
         <TextareaAutosize
		style={txtareaSec}
		   multiline
		   defaultValue={body_bottom_email_code_value}
		   key={body_bottom_email_code_value}
            onKeyUp={save_body_bottom_email_code}
		/>
		
       </Box>
       
       
    </Box>
    
  
  
  );
}

export { CodemirrorExample };

