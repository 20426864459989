import React, { Dispatch, SetStateAction } from "react";
import {
  Dialog,
  Typography,
  Button,
  Box,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  open: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleCampaignDelete:any
}
const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: 350,
  },
  text: {
    margin: "0",
    paddingBottom: "1rem",
    borderBottom: "1px solid #c4c4c4",
  },
  textColor:{
      color:"red"
  }
}));
const CreateNewFolder: React.FC<Props> = ({ open, setOpen,handleCampaignDelete }) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <Box className={classes.root}>
            <Typography className={classes.text}>
              This folder will be <b className={classes.textColor}>deleted</b> and will no longer be
              accessible.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" disableElevation onClick={handleCampaignDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateNewFolder;
