import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IntegrationList from "./IntegrationList";
import Paper from "@material-ui/core/Paper";
import { useEffect, useState } from "react";
import AxiosInstance from "../../../../utils/AxiosInstance";
import { AxiosResponse } from "axios";

const useStyles = makeStyles({
  container: {
    // backgroundColor: "#f8f8f8",
    //height: "calc(100vh - 250px)",
    //overflow: "hidden",
    //overflowY: "scroll",
    boxShadow: "unset",
    padding: 12,
  },
});
interface Props {
  history?: any;
}
const EspTab: React.FC<Props> = ({ history }) => {
  const URL = window.location.pathname.split("/").pop();
  const classes = useStyles();
  const [userData, setUserData] = useState<any>({ usertable: [], pending: [] });
  const loadTrackingData = () => {
    AxiosInstance.get(
      `api/getEspData/${localStorage.getItem("company_id")}`
    ).then((response: AxiosResponse) => {
      setUserData(response.data);
    });
  };

  useEffect(() => {
    if (URL === "integrations") {
      loadTrackingData();
      let ButContainer1: HTMLDivElement | null =
        document.querySelector("#EspcloseBut");
      if (ButContainer1) {
        ButContainer1.setAttribute("style", "display:none;");
      }
      
    } else if (URL === "NewIntegration") {
      //localStorage.removeItem("integration_type");
      //localStorage.removeItem("editRId");
      let SettingContainer: HTMLDivElement | null =
        document.querySelector("#SettingBox");
      if (SettingContainer) {
        SettingContainer.setAttribute("style", "display:block");
      }
      let ButContainer: HTMLDivElement | null =
        document.querySelector("#EspcloseBut");
      if (ButContainer) {
        ButContainer.setAttribute("style", "display:block;");
      }
      let ButContainer1: HTMLDivElement | null =
        document.querySelector("#closeBut");
      if (ButContainer1) {
        ButContainer1.setAttribute("style", "display:none;");
      }
    } else if (URL === "Newtracking") {
      let ButContainer: HTMLDivElement | null =
        document.querySelector("#closeBut");
      if (ButContainer) {
        ButContainer.setAttribute("style", "display:block;paddingLeft:10px");
      }

      let SettingContainer: HTMLDivElement | null =
        document.querySelector("#SettingBox");
      if (SettingContainer) {
        SettingContainer.setAttribute("style", "display:none");
      }

      let msgContainer: HTMLDivElement | null =
        document.querySelector("#FlsMsgAlert");
      if (msgContainer) {
        msgContainer.setAttribute("style", "display:none");
      }
    } else {
      let SettingContainer: HTMLDivElement | null =
        document.querySelector("#SettingBox");
      if (SettingContainer) {
        SettingContainer.setAttribute("style", "display:block");
      }
      let ButContainer: HTMLDivElement | null =
        document.querySelector("#closeBut");
      if (ButContainer) {
        ButContainer.setAttribute("style", "display:none;");
      }
    }
  }, [URL]);
  return (
    <Box>
      <Paper className={classes.container}>
        <IntegrationList
          history={history}
          reloadLists={loadTrackingData}
          data={userData.usertable}
        />
      </Paper>
    </Box>
  );
};

export default EspTab;
