//import AxiosInstance from "../../../../utils/AxiosInstance";
//import { toast } from "react-toastify";
//import { store } from '../../../redux/store';
//import {setFlashMessage} from "../../../../utils/AuthUtility";

export const DynamicSocialLinksAutosave = async () => {
    return true;
  /*var styleId: any = null;
  const stateInitial = store.getState();
  const state = stateInitial.styleGuide;
  if(state.styleguideName === '' || state.styleguideName===undefined)
  {
       toast.error('Data not saved! Design system name can not be empty.');
       return false;
  }

  var formdata = new FormData();
  const data = localStorage.getItem("loggedInUser")
  if(localStorage.getItem("styleGuideResID")==='' || localStorage.getItem("styleGuideResID")===null)
  {
     styleId=localStorage.getItem("rowId")
  }
  else
  {
     styleId = localStorage.getItem("styleGuideResID");
  }
  let Email = ""
  if (data) {
    const { user_detail } = JSON.parse(data)
    Email = user_detail.Email
  }

  formdata.append(
    "company_id",
    localStorage.getItem("usr_company_id") ||
    localStorage.getItem("company_id") ||
    ""
  );

  formdata.append("Email", Email || "");
  formdata.append("styleguide_name", state.styleguideName);
	if(state.styleguideId){
		styleId = state.styleguideId;
	}
  if(styleId)
  {
    formdata.append("autosave_action", 'save'); 
    formdata.append("styleguideId", styleId);
  }

  state?.socialLinkList?.forEach((item: any) => {
    formdata.append(item.title, item.link ? item.link : "");
  });
  
  let socialIconSrc:any = {};
  state?.socialLinkList?.forEach((item: any) => {
    if(item.src && item?.isvalid !== false) {
      socialIconSrc[item.title] = item.src;   
    }    
  });

  formdata.append("social_icon_url", JSON.stringify(socialIconSrc));   

  AxiosInstance.post(`api/styleguide/setDynamicSocialLinks`, formdata)
    .then((result) => {
        if(result.data.status_code === '404')
        {
               toast.error('Sorry You are not authorized to perform this action');
               return false;
        }
        // state.logo.src_url = result.data.src_url;

          let MsgContainer: HTMLDivElement | null = document.querySelector("#FlsMsgAlert");
          if (MsgContainer) {
              MsgContainer.setAttribute("style", "display:block;");
          var dt = new Date();
          if(setFlashMessage(dt.toUTCString()) === undefined)
          {
              MsgContainer.setAttribute("style", "display:inline-block;float:left;margin-top:3px;margin-right:29px");
              MsgContainer.innerHTML = 'All Saved ✔ a few seconds ago';
          }
          else
          {

              MsgContainer.innerHTML = 'Last Saved ✔ '+setFlashMessage(dt.toUTCString());
          }
             localStorage.removeItem("LastSavedTime");
             localStorage.setItem('LastSavedTime', dt.toISOString());
          }
          let styleCloseBox: HTMLDivElement | null = document.querySelector("#styleClosebut");
          if (styleCloseBox) {
              styleCloseBox.setAttribute("style", "background:#0a6adc;");

          }

          // localStorage.setItem('styleGuideResID', result.data.insert_id);
    })
    .catch(function (error: any) {
      if (error.response) {
        if (error.response.status === 400) {
          toast.warn(error.response.data.message);
        }
        if (error.response.status === 500) {
          alert("500, Server Error");
          toast.error(error.response.data.message);
        }
      }

    });


//toast("Saved Successfully");
}
// eslint-disable-next-line
const getSocialSrcFromState = async (socialLinkList:any) => {
  let data: any[] = [];
  socialLinkList?.forEach((item: any) => {
    let title:any = item.title + "_src";
    if(item.src && item?.isvalid !== false) data[title] = item.src;
  });
  return data;
  */
  
}

export default DynamicSocialLinksAutosave;