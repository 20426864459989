import React from "react";
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@material-ui/core";
import { useStyles } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../../redux/actions/styleGuideActions";
import { DefaultBoldList } from "../../utilities/DefaultStyleGuide";
import { updateCustomCssStyleSheet } from "../../../../../../utils/Functions";

interface Props {
  data:any;
}

const StyleItemWeight: React.FC<Props> = ({data}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.styleGuide);  

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
        width: 70,
      },
    },
  };

  const handleWeightClick = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string[];
    if(value.length === 0) return;
    value.sort((a, b) => {
      // Convert strings to numbers for comparison
      const numA = parseInt(a, 10);
      const numB = parseInt(b, 10);
    
      // Compare the numbers
      return numA - numB;
    });
    let arr = state?.list_custom_fonts;
    arr?.forEach((item:any) => {      
      // Loop through the styles array within each item
      item?.styles?.forEach((style:any) => {
        if(style?.key === data?.key ){
          style["fontWeight"] = value;    
        }
      });
    });
    dispatch(addField("list_custom_fonts", arr));    
    updateCustomCssStyleSheet(arr);
  }

  // const handleWeightClick = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   const value = event.target.value as string[];
  //   if (value.length === 0) return;
  
  //   // Create a new copy of the list_custom_fonts array
  //   const updatedListCustomFonts = state?.list_custom_fonts.map((item: any) => {
  //     const updatedItem = { ...item };
  //     updatedItem.styles = item.styles.map((style: any) => {
  //       if (style?.key === data?.key) {
  //         // Create a new copy of the style object with updated fontWeight
  //         return { ...style, fontWeight: event.target.value as string[] };
  //       }
  //       return style;
  //     });
  //     return updatedItem;
  //   });
  
  //   dispatch(addField("list_custom_fonts", updatedListCustomFonts));
  // };


  return (    
    <Box className={`${classes.styleTypographyWeight}`}>
      <FormControl variant="outlined" className={classes.WeightFormControl}>
        <InputLabel id="demo-mutiple-weight-label">Weight</InputLabel>
        <Select
          // variant="outlined"
          labelId="demo-mutiple-weight-label"
          id="demo-mutiple-weight"
          multiple
          value={data?.fontWeight}
          onChange={handleWeightClick}
          input={<OutlinedInput label="Weight" />}
          MenuProps={MenuProps}
        >
          {DefaultBoldList?.map((fontWeight:any) => (
            <MenuItem key={fontWeight} value={fontWeight} >
              {fontWeight}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default StyleItemWeight;
