import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { useStyles } from "../styles";
import DynamicFields from "../utilities/DynamicFields";
interface Props {}
const Menu: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" className={classes.section}>
      <Typography className={classes.heading} variant="h6">
      Important links
      </Typography>
      <Typography variant="caption" className={classes.caption}>     
        Set your important company links. This is used to populate menu which&nbsp;can be added to your emails.
      </Typography>
      <DynamicFields />
    </Box>
  );
};

export default Menu;
