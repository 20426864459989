import React, { Dispatch, SetStateAction } from "react";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DropzoneArea } from "material-ui-dropzone";
import { toast } from "react-toastify";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
interface Props {
  open: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
  imageFolderID: string;
  history: any;
  brandID: string;
  refreshCampImages:any;
  refreshCampImages1: any;
  buttonDisabledAdd: any;
  setButtonDisabledAdd: Dispatch<SetStateAction<boolean>>;
  setSelectedImages: any;
  selectedImages: any;
  setProgress: any;
  setProgressBar: any;
}
type FileDetail = {
  file: File,
  src: string | ArrayBuffer
}
/*interface RenderTree {
  id: string;
  name?: string;
  type?: string;
  folder_name?: string;
  parent_id?: string;
  company_id?: string;
  state: {
    opened: boolean;
  };
  childes?: RenderTree[];
}*/

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: 350,
  },
  avatar: {
    width: 80,
    height: 80,
  },
  treeViewDiv: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  treeViewFolder: {
    color: "#e0e0e0",
  },
  treeViewItem: {
    borderRadius: "5px",
  },
  divRoot: {
    "&.Mui-selected  > .MuiTreeItem-content": {
      "& $tickIcon": {
        color: "#73a8e2",
        paddingLeft: "5rem",
        display: "block",
      },
      "& $treeViewFolder": {
        color: "#73a8e2",
      },
    },
  },
  label: {
    fontWeight: "inherit",
    color: "inherit",
    borderRadius: theme.spacing(0.5),
    marginTop: ".3rem",
  },
  tickIcon: {
    display: "none",
  },
  folderHeight: {
    height: "15rem",
    overflow: "scroll",
    borderBottom: "1px solid #c4c4c4",
    paddingRight: "1rem",
  },
  dropDown: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    "& .MuiGrid-spacing-xs-8":{
      width: "calc(100% + 35px)"
    },
    "& .MuiDropzonePreviewList-image":{
      objectFit: "cover"
    },
  },
  DialogContentDrop:{
    "& .MuiSnackbarContent-message":{
      maxWidth:500
    }
  },
  actionButton: {
    paddingRight: "1.5rem",
  },
}));

export const MAX_FILE_SIZE_IN_BYTES = 2 * 1048576;

const CreateNewImage: React.FC<Props> = ({
  open,
  setOpen,
  imageFolderID,
  brandID,
  history,
  refreshCampImages,
  refreshCampImages1,
  buttonDisabledAdd,
  setButtonDisabledAdd,
  setSelectedImages,
  selectedImages,
  setProgress,
  setProgressBar
  
}) => {
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };

  const setFolderToNewFolder = async (event: any) => {    
    if (!selectedImage) {
      toast.error("Please Select Image");
      return;
    }
    uploadImages(selectedImage);
   
  };

  const uploadImage = (file:FileDetail, index:any) => {
    var FormData = require("form-data");
    var data = new FormData();
    let tags: string[] = [imageFolderID]; 
    data.append("qqfile", file.file);
    data.append("tags",JSON.stringify(tags));
	setButtonDisabledAdd(true);
    AxiosInstance.post('api/v1/Contents/add_gallery_new_image', data).then((response: AxiosResponse) => {
		var data1 = new FormData();
			data1.append("dir", response.data.data.dir);
			let temp: any[] = [];
			
			var uploadLength = localStorage.getItem('uploadLength')?localStorage.getItem('uploadLength'):0;
			uploadLength = Number(uploadLength);
			for(var i=0;i<uploadLength-1;i++){
				temp.push(i);
			}
			localStorage.setItem('uploadLength',JSON.stringify(uploadLength-1));
			setSelectedImages(temp);
			
			AxiosInstance.post('api/v1/campaign/delete_init', data1);
			refreshCampImages1(response.data.data.images);
      });
  }

  const uploadImages = (selected: any) => {
	 setProgress(10);
	 setProgressBar();
	 let tempArr: any[] = [];
	selected.forEach((Item:any, index:any) => {
		tempArr.push(index);
	});
	setSelectedImages(tempArr);
	localStorage.setItem('uploadLength',JSON.stringify(tempArr.length));
	selected.map((image: any, index:any) => uploadImage(image,index+1));
	setOpen(false);
  }
  
  const [selectedImage, setSelectedImage] = React.useState<FileDetail[]>();
  const readFile = (file: any): Promise<FileDetail> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        resolve({ file, src: reader.result || ''});
      };
    })
  }
  const readFiles = async (files: any): Promise<FileDetail[]> => {
    const promises: Promise<FileDetail>[] = [];
    files.forEach((file: any) => {
      promises.push(readFile(file));
    });
    const results = await Promise.allSettled(promises);
    const filesResponse: FileDetail[] = [];
    results.forEach(result => {
      if (result.status === "fulfilled") {
        filesResponse.push(result.value);
      }
    });
    return filesResponse;
  }
  const handleUploadClick = async (files:any) => {
    let fileArray = await readFiles(files);
    setSelectedImage(fileArray);
  };
  const checkFileType = (type: string, acceptedFiles: string[]) => {
	  const match = acceptedFiles.find(acceptedFile => type.match(acceptedFile));
	  if (match) return true;
	  return false;
  }

  const bytesToHumanReadableFileSize = (size: number): string => {
	  return size>=1048576?size/1048576+" megabytes":size>=1024?size/1024+" kilobytes":size+" bytes";
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Image</DialogTitle>
        <DialogContent classes={{
            root: classes.DialogContentDrop, 
          }}>
          <DropzoneArea
            filesLimit ={100}
            dropzoneClass={classes.dropDown}
            acceptedFiles={["image/*"]}
            dropzoneText={"Drag media or Click here to Upload"}
			maxFileSize={MAX_FILE_SIZE_IN_BYTES}
			getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
				if (!checkFileType(rejectedFile.type, acceptedFiles)) return `File ${rejectedFile.name} is not supported`;
				if (rejectedFile.size > maxFileSize) return `File ${rejectedFile.name} is too big, Size limit is ${bytesToHumanReadableFileSize(MAX_FILE_SIZE_IN_BYTES)}`;
				return `File ${rejectedFile.name} is Rejected`;
			}}
            onChange={(files) => handleUploadClick(files)}
          />
        </DialogContent>
        <DialogActions className={classes.actionButton}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={setFolderToNewFolder}
            color="primary"
			disabled={buttonDisabledAdd}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateNewImage;
