import React, { useRef, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Box,IconButton} from "@material-ui/core";
//import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

import {
  Button,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";

interface Props {
  isNotSettingsPage: any;
}

const Klaviyo: React.FC<Props> = ({ isNotSettingsPage }) => {
  const inputElement = useRef<HTMLInputElement>(null);
  const history = useHistory();

  const [integrationName, setintegrationName] = useState("");
  const [integrationNamevalue, seintegrationNamevalue] = useState("");
  const [InputmaskvalueEndPoint, setInputmaskvalueEndPoint] = useState("");
  const [espaction, setespaction] = useState("");
  const [endPointdisable, setendPointdisable] = useState<boolean>(true);
  const [apikey, setapikey] = useState("");
  
   const URL = window.location.pathname.split('/').pop();
  const getIntegrationData = (rowId: any) => {
    AxiosInstance.get(`api/getEspDataById/${rowId}`).then(
      (response: AxiosResponse) => {
        if (response.data) {
          seintegrationNamevalue(response.data.usertable.pc_assign_name || '');
          setintegrationName(response.data.usertable.pc_assign_name || '');
          setInputmaskvalueEndPoint('XX:XX:XX:XX:XX:XX:XX:XX:XX:XX:XX');
        }
      }
    );
  };

  const handleapikeychange = (event: any) => {
   setapikey(event.target.value)
  };
  
   const handleEndPointClick = () => {
   setendPointdisable(false);
   setInputmaskvalueEndPoint('');
  };
  
  const initialValues = {};
  useEffect(() => {
      const intName = localStorage.getItem("integration_name") || "";
      if(localStorage.getItem("editRId"))
      {
        getIntegrationData(localStorage.getItem("editRId"));
      } else {
          seintegrationNamevalue(intName);
      }
    inputElement.current?.focus();
  }, []);

  const onSubmit = (form: any) => {
    const FormData = require("form-data");
    if (localStorage.getItem("integration_type") !== "") {
      let data = new FormData();
      var pc_assign_name = "";
      if (integrationName !== "") {
        pc_assign_name = integrationName || "";
      } else {
        pc_assign_name = localStorage.getItem("integration_name") || "";
      }
      data.append("pc_provider_id", localStorage.getItem("integration_type"));
      data.append("editRowId", localStorage.getItem("editRId"));
      if (apikey!=='') {
        data.append("client_api", apikey);
      }
     
      if (pc_assign_name) {
         data.append("pc_assign_name", pc_assign_name);
      }

      AxiosInstance.post("api/v1/Integration/save_configuration_ajax", data)
        .then((response: AxiosResponse) => {
          if (response?.data?.status) {
            toast.success(response?.data?.data?.message);
            setTimeout(() => {
              history.push("/settings/integrations");
            }, 150);
          }
        })
        .catch((error: any) => {
          if (error.response) {
            if (error.response.status === 400) {
              toast.error(error.response.data.message);
            }
          }
        });
    }
  };
  const useStyles = makeStyles((theme) => ({
    boxRow: {
      alignItems: "center",
      paddingBottom: 17,
    },
    avatar: {
      width: 32,
      height: 32,
    },
    InputText: {
      marginTop: "0",
      flex: 1,
      "& input ": {
        height: "unset",
        borderRadius: "15px!important",
      },
      "& .MuiSelect-selectMenu ": {
        padding: 7,
      },
      "& > div ": {
        borderRadius: "2px !important",
      },
      "& input::placeholder": {
        fontSize: "14px",
        color: "#999999",
      },
    },
    InputText1: {
      borderRadius: "12px !important",
    },
    InputLabel: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: "bold",
      color: "#11142D",
      width: 200,
    },
    InputTextOutlined:{
    marginTop: "0",
    marginBottom: 24,
    "&:last-child":{      
      marginBottom: 8,
    },
    "& .MuiOutlinedInput-root": {
      "& input": {
        paddingTop: 18,
        paddingBottom: 18,
      },
    },
  },
    InputLabelBut: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: "bold",
      color: "#11142D",
      width: 130,
    },
    form: {
      width: 800,
      padding: "15px 15px 100px 15px",
    },
    dialogTitle: {
      padding: "7px 15px 15px 15px",
      "& > h2": {
        fontSize: "18px",
      },
    },
    dialogContent: {
      padding: "15px 15px 7px 15px",
      overflow: "hidden",
      overflowY: "scroll",
    },
    btnCancelNobelO: {
      border: "1px solid #969696",
      color: "#676767",
      borderRadius: 2,
      fontSize: 16,
      fontWeight: "normal",
      padding: "3px 22px",
      "&:hover": {
        background: "#969696",
        color: "#ffffff",
      },
    },
    btnPrimary: {
      background: "#057CFF",
      borderRadius: "3px",
      color: "#ffffff",
      fontSize: 16,
      fontWeight: "normal",
      padding: "5px 30px",
      "&:hover": {
        background: "#0a6adc",
      },
    },
    dialogActions: {
      borderTop: "1px solid #e5e5e5",
      padding: "23px 0 15px 0",
      margin: "0 15px",
    },
  }));
  
   useEffect(() => {
      const intName = localStorage.getItem("integration_name") || "";
      if(localStorage.getItem("editRId"))
      {
        getIntegrationData(localStorage.getItem("editRId"));
        setespaction('UPDATE');
      } else {
          seintegrationNamevalue(intName);
      }
    inputElement.current?.focus();
    //setInputmaskvalueEndPoint('XX:XX:XX:XX:XX:XX:XX:XX:XX:XX:XX');
    //setInputmaskvalueClientId('XX:XX:XX:XX:XX:XX:XX:XX:XX:XX:XX');
    ///setInputmaskvalueClientSecret('XX:XX:XX:XX:XX:XX:XX:XX:XX:XX:XX');
    //setendPointdisable(true);
    //setclientIddisable(true);
    //setclientSecretdisable(true);
  }, [URL]);

  const classes = useStyles();
  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {() => (
          <Form className={classes.form}>
            <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
              {"Klaviyo Integration"}
            </DialogTitle>
            <DialogContent>
              <Box className={classes.boxRow}>
               
                    <Field
                    label="Integration Name"
                    onChange={(e: any) => setintegrationName(e.target.value)}
                    defaultValue={integrationNamevalue}
                    key={integrationNamevalue}
                    autofocus="true"
                    ref={inputElement}
                    as={TextField}
                    name="pc_assign_name"
                    required
                    className={classes.InputTextOutlined}
                    placeholder="Integration Name"
                    fullWidth
                    type="text"
                    variant="outlined"
                    margin="normal"
                    />
              </Box>

              <Box className={classes.boxRow}>
                
                   {espaction === 'UPDATE' ? (
                <TextField
                label="Client API Key"
                defaultValue={InputmaskvalueEndPoint}
                name="pc_api_key"
                required
                onChange={handleapikeychange}
                    key={InputmaskvalueEndPoint}
                    disabled={endPointdisable}
                    variant="outlined"
                    autoComplete="off"
                    margin="dense"
                    InputProps={{
                      endAdornment: (
                        <IconButton  onClick={handleEndPointClick} edge="end" >
                          <VisibilityOffOutlinedIcon style={{ color: "#DADADA" }} />
                        </IconButton>
                      ),
                    }}
                    fullWidth
                  />
        ) : (
                <Field
                label="Client API Key"
                autofocus="true"
                ref={inputElement}
                as={TextField}
                onChange={handleapikeychange}
                name="pc_api_key"
                required
                className={classes.InputTextOutlined}
                placeholder="Client API Key"
                fullWidth
                type="text"
                variant="outlined"
                margin="normal"
                />
        )}
              </Box>

             

              <Box className={classes.boxRow}>
                <Typography className={classes.InputLabelBut}></Typography>
               {espaction === 'UPDATE' ? (
           <Button type="submit" className={classes.btnPrimary}>
                  {"UPDATE INTEGRATION"}
                </Button>
        ) : (
           <Button type="submit" className={classes.btnPrimary}>
                  {"ADD INTEGRATION"}
                </Button>
        )}
              </Box>
            </DialogContent>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Klaviyo;
