import {ADD_FIELD,REMOVE_DATA, ADD_LOGO, ADD_PREFIL_DATA, REMOVE_LOGO} from '../enums/Enums'
const initialState ={
    button_radius:'24',
    menuList:[],
    ParameterList:[],
    AttributesList:[],
    socialLinkList:[],
    StyleFromDbList:[],
    RegularNormal:[]
}


 const StylesGuideReducer = (state=initialState,action:any)=>{
     const fieldValue = action.payload?.value?action.payload.value:'';
    switch(action.type){
        case ADD_LOGO: return {...state,logo:action.payload} 
        case REMOVE_LOGO: return {...state,logo:action.payload} 
        case ADD_FIELD: return {...state,[action.payload.fieldName]:fieldValue}
        case ADD_PREFIL_DATA: return {...state,...action.payload}
        case REMOVE_DATA: return {...initialState}
        default: return state
    }
}
export default StylesGuideReducer