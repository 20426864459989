import {UPDATE_ACTIVE_TAB} from '../enums/Enums'
const initialState ={
    activeParent:'campaign',
}


 const TabManagerReducer = (state=initialState,action:any)=>{
    switch(action.type){
        case UPDATE_ACTIVE_TAB: return {...state,[action.payload.fieldName]:action.payload.fieldValue} 
        default: return state
    }
}
export default TabManagerReducer