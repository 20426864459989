import React, { useState, Dispatch, SetStateAction } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useStylesMeter } from "../../../../styles/password";
import zxcvbn from 'zxcvbn-typescript';
import MenuItem from "@material-ui/core/MenuItem";
import {
  Box,
  Button,
  DialogActions,
  Divider,
  TextField,
} from "@material-ui/core";
import { toast } from "react-toastify";
import AxiosInstance from "../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";

const emails = ["username@gmail.com", "user02@gmail.com"];
const useStyles = makeStyles({
  container: {
    display: "flex",
    width: 300,
    padding: 10,
    "&>*": {
      margin: 10,
      width: "100%",
    },
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  button: {
    textTransform: "none",
  },
  InputLabel: {
    marginTop: ".7rem",
    fontWeight: 500,
    marginBottom: ".2rem",
  },
  ErrorMsg: {
    textAlign: "center",
    color:"red"
  },
  InputText: {
    marginTop: "0",
  },
  label: {
    paddingTop: "10px",
    paddingRight: "20px",
    paddingBottom: "0px",
    paddingLeft: "20px",
  },
});

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

function SimpleDialog(props: SimpleDialogProps) {
  const classes = useStyles();
  const classes1 = useStylesMeter();
  const { onClose, selectedValue, open } = props;
  //const [error, setError] = useState<boolean>(false);
  var [errorMsg, seterrorMsg] = useState<string>("");
  const lowercaseRegex = /(?=.*[a-z])/;
	const uppercaseRegex = /(?=.*[A-Z])/;
	const numericRegex = /(?=.*[0-9])/;
	const specialRegex = /(?=.*[@:*?$%^&!+=])/;
	const [showLowercase, setShowLowercase] = useState(true);
	const [showUppercase, setShowUppercase] = useState(true);
	const [showNumeric, setShowNumeric] = useState(true);
	const [showSpecial, setShowSpecial] = useState(true);
	const [showMin12, setShowMin12] = useState(true);
	const [strength, setStrength] = useState(-1);
	//const [passwordLength, setPasswordLength] = useState(0);
	const [passwordError, setPasswordError] = useState(false);
	const [confirmPasswordError, setConfirmPasswordError] = useState(false);
	const [passVal, setPassVal] = useState("");
	const [confirmpassVal, setConfirmpassVal] = useState("");
	
	//const strengthClass = passwordLength > 0 ? classes.visible : classes.visible;
	
	
  const validatePassword = (value: any, score:any ) => {
	//setPasswordLength(value.length);
	setPassVal(value);
	if(confirmpassVal.length){
	 if(value !==confirmpassVal){
		  setConfirmPasswordError(true);
	  }else{
		  setConfirmPasswordError(false);
	  }
	}
	var showLowercaseT =true;
	var showUppercaseT =true;
	var showNumericT =true;
	var showSpecialT =true;
	var showMin12T =true;
	
    if (lowercaseRegex.test(value)) {
      setShowLowercase(false);
	  showLowercaseT=false;
    }
    else if(!lowercaseRegex.test(value)) {
      setShowLowercase(true);
	  showLowercaseT=true;
    }
    if (uppercaseRegex.test(value)) {
		setShowUppercase(false);
		showUppercaseT=false;
    }
    else if(!uppercaseRegex.test(value)) {
      setShowUppercase(true);
	  showUppercaseT=true;
    }
    if (numericRegex.test(value)) {
      setShowNumeric(false);
	  showNumericT=false;
    }
    else if (!numericRegex.test(value)) {
      setShowNumeric(true);
	  showNumericT=true;
    }
    if (specialRegex.test(value)) {
		setShowSpecial(false);
		showSpecialT=false;
    }
    else if (!specialRegex.test(value)) {
		setShowSpecial(true);
		showSpecialT=true;
    }
    if (value.length >= 12) {
		setShowMin12(false);
		showMin12T=false;
    }
    else if (value.length < 12) {
      setShowMin12(true);
	  showMin12T=true;
    }
	
	if(score>=2){
		
		if(showLowercaseT === false && showUppercaseT === false && showNumericT === false && showSpecialT === false && showMin12T === false){
			setStrength(score);
		}else{
			setStrength(2);
		}
	}else{
		if(!value.length){
			setStrength(-1);
		}else{
			setStrength(score);
		}
	}
	if(showLowercaseT === true || showUppercaseT === true || showNumericT === true || showSpecialT === true || showMin12T === true){
		setPasswordError(true);
		
	}else{
		setPasswordError(false);
	}
	if(!value.length){
		setPasswordError(false);
	}
  }
  function validateZXCVBN(value: any) {
	var score =zxcvbn(value).score;
	validatePassword(value,score);
	//setStrength(score);
  }
  function handleChangeConfirm(value: any) {
	  setConfirmpassVal(value);
	  if(value !==passVal){
		  setConfirmPasswordError(true);
	  }else{
		  setConfirmPasswordError(false);
	  }
  }

  const handleClose = () => {
    onClose(selectedValue);
  };

  const changePassword = (event: any) => {
    event.preventDefault();
    const { password, confirmPassword } = event.currentTarget.elements;
	if(strength <=2 || passwordError){
		 //setError(true);
		 return;
	}
    if (password.value !== confirmPassword.value) {
       seterrorMsg('Passwords do not match.');
       //setError(true);
      return;
    } else if (!password.value && !confirmPassword.value) {
      //setError(true);
      return;
    } else {
      //setError(false);
    }
    const FormData = require("form-data");
    let data = new FormData();
    data.append("new_password", password.value);
    data.append("confirm_password", confirmPassword.value);

    AxiosInstance.post('api/v1/settings/submit_user_password_ajax', data)
     .then((response: AxiosResponse) => {
		 toast("Password Changed");
		 handleClose();
      });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title" className={classes.label}>
        Change Password
      </DialogTitle>
      <form onSubmit={changePassword}>
      <p className={classes.ErrorMsg}>{errorMsg}</p>
        <Box className={classes.container}>
          <Typography variant="subtitle2" className={classes.InputLabel}>
          New Password
          </Typography>
			<List dense>
			<Grid container>
			<Grid item xs={12} sm={12}>
				<ListItem className={classes1.listItem}>
				  <ListItemIcon className={classes1.listIcon}>
					{showMin12 === false ? (
					  <FiberManualRecordIcon
						className={classes1.listDotFaded}
					  />
					) : (
					  <FiberManualRecordIcon
						className={classes1.listDot}
					  />
					)}
				  </ListItemIcon>
				  <ListItemText className={classes1.listText}>
					{showMin12 === false ? (
					  <span className={classes1.listTextColor}>
					   Minimum of 12 characters long
					  </span>
					) : (
					  <span>Minimum of 12 characters long</span>
					)}
				  </ListItemText>
				</ListItem>
			  </Grid>
			  <Grid item xs={12} sm={12}>
				<ListItem className={classes1.listItem}>
				  <ListItemIcon className={classes1.listIcon}>
					{showUppercase === false ? (
					  <FiberManualRecordIcon
						className={classes1.listDotFaded}
					  />
					) : (
					  <FiberManualRecordIcon
						className={classes1.listDot}
					  />
					)}
				  </ListItemIcon>
				  <ListItemText className={classes1.listText}>
					{showUppercase === false ? (
					  <span className={classes1.listTextColor}>
						Include one uppercase letter (ABC)
					  </span>
					) : (
					  <span>Include one uppercase letter (ABC)</span>
					)}
				  </ListItemText>
				</ListItem>
			  </Grid>
			  <Grid item xs={12} sm={12}>
				<ListItem className={classes1.listItem}>
				  <ListItemIcon className={classes1.listIcon}>
					{showLowercase === false ? (
					  <FiberManualRecordIcon
						className={classes1.listDotFaded}
					  />
					) : (
					  <FiberManualRecordIcon
						className={classes1.listDot}
					  />
					)}
				  </ListItemIcon>
				  <ListItemText className={classes1.listText}>
					{showLowercase === false ? (
					  <span className={classes1.listTextColor}>
						Include one lowercase letter (xyz)
					  </span>
					) : (
					  <span>Include one lowercase letter (xyz)</span>
					)}
				  </ListItemText>
				</ListItem>
			  </Grid>
			  <Grid item xs={12} sm={12}>
				<ListItem className={classes1.listItem}>
				  <ListItemIcon className={classes1.listIcon}>
					{showNumeric === false ? (
					  <FiberManualRecordIcon
						className={classes1.listDotFaded}
					  />
					) : (
					  <FiberManualRecordIcon
						className={classes1.listDot}
					  />
					)}
				  </ListItemIcon>
				  <ListItemText className={classes1.listText}>
					{showNumeric === false ? (
					  <span className={classes1.listTextColor}>
						Include one number (123)
					  </span>
					) : (
					  <span>Include one number (123)</span>
					)}
				  </ListItemText>
				</ListItem>
			  </Grid>
			  <Grid item xs={12} sm={12}>
				<ListItem className={classes1.listItem}>
				  <ListItemIcon className={classes1.listIcon}>
					{showSpecial === false ? (
					  <FiberManualRecordIcon
						className={classes1.listDotFaded}
					  />
					) : (
					  <FiberManualRecordIcon
						className={classes1.listDot}
					  />
					)}
				  </ListItemIcon>
				  <ListItemText className={classes1.listText}>
					{showSpecial === false ? (
					  <span className={classes1.listTextColor}>
						Include one special symbol (:@$!%*?&)
					  </span>
					) : (
					  <span>Include one special symbol (:@$!%*?&)</span>
					)}
				  </ListItemText>
				</ListItem>
			  </Grid>
			  
			</Grid>
			</List>
			
          <TextField
            className={classes.InputText}
            type="password"
			autoComplete="off"
            error={passwordError}
            name="password"
            placeholder="New Password"
			style={{
					  marginTop:10,
					   marginBottom:0,
					}}
			inputProps={{
				maxLength: 100,
			}}
			onKeyDown = {(e: any) => {
				e.stopPropagation();
			 }}
			onChange={(e: any) => {
  				validateZXCVBN(e.currentTarget.value)
            }}
          />
		  <div className={classes1.strengthmeter1+' mt-2'}>
				<div className={classes1.strengthmeterfill} data-strength={strength}></div>
			</div>
          
          <TextField
            type="password"
			autoComplete="off"
            className={classes.InputText}
            error={confirmPasswordError}
            name="confirmPassword"
            placeholder="Confirm Password"
			style={{
					  marginTop:20,
					}}
			inputProps={{
				maxLength: 100,
			}}
			onKeyDown = {(e: any) => {
				e.stopPropagation();
			 }}
			onChange={(e: any) => {
                    handleChangeConfirm(e.currentTarget.value);
            }}
          />{" "}
        </Box>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            className={classes.button}
            size="small"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            size="small"
            className={classes.button}
            color="primary"
          >
            Change
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
interface Props {
	setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
}
const ChangePassword: React.FC<Props> = ({
											 setAnchorEl
										  }) => {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
	setAnchorEl(null);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <>
      <MenuItem onClick={handleClickOpen}>Change Password</MenuItem>{" "}
      {open && (
        <SimpleDialog
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
        />
      )}
    </>
  );
};
export default ChangePassword;
