import React  from "react";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { useStyles } from "../styles";
import LayoutStyle from "./Layout/LayoutStyle";
import LayoutGrid from "./Layout/LayoutGrid";
import LayoutPadding from "./Layout/LayoutPadding";
import { useSelector } from "react-redux";
interface Props {}

const Layout: React.FC<Props> = () => {
  const classes = useStyles();

  const state = useSelector((state: any) => state.styleGuide);

  return (
      <Box>
        <Box display="flex" flexDirection="column" className={classes.section1}>
          <Typography className={classes.heading} variant="h6"> 
              Style 
          </Typography>
          <Typography variant="caption" className={classes.typographyText}>
             Select a email design style below.
          </Typography>
          <Box className={classes.fontContainerRadio} style={{display:"flex"}}>
            <LayoutStyle />
          </Box>

        </Box>
        <Box display="flex" flexDirection="column" className={classes.section2}>
          <LayoutGrid />
          {(state.layout_style === "classic")? <LayoutPadding /> : ""}
        </Box>
      </Box>
  );
};

export default Layout;