import React from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";

interface Props {
  handleNext: any;
  handleSkip: () => {};
}
const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: ".5rem",
    width: "20rem",
  },
  skipButton: {
    marginTop: "1rem",
    marginBottom: ".5rem",
    color: "#646464",
    width: "20rem",
  },
}));
const Footer: React.FC<Props> = ({ handleNext, handleSkip }) => {
  const classes = useStyles();

  return (
    <Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        container
        spacing={0}
        alignItems="center"
        justify="center"
      >
        <Button
          onClick={handleNext}
          color="primary"
          variant="contained"
          className={classes.button}
        >
          Next
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        container
        spacing={0}
        alignItems="center"
        justify="center"
      >
        <Button
          onClick={handleSkip}
          color="primary"
          className={classes.skipButton}
        >
          Skip
        </Button>
      </Grid>
    </Grid>
  );
};

export default Footer;
