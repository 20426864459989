import React, { useState } from "react";
import "./styles.css";
import {
  AmexFadedSvg,
  MasterCardFaded,
  CreditCardSvg,
  DownArrow,
} from "../../../../../assets/svgs/Svgs";
import { cardImageProvider } from "./cardImageProvider";

// import useResponsiveFontSize from "../../useResponsiveFontSize";

interface Props {}
const Payment: React.FC<Props> = () => {
  const [currentProvider, setCurrentProvider] = useState<any>("visa02");
  return (
    <div>
      <div className="fieldContainer">
        <label className="label">
          CARD OWNER
          <input className="input" name="name" placeholder="Name" />
        </label>
        <label className="label" style={{ position: "relative" }}>
          CARD NUMBER
          <input
            className="input cardnumber"
            pattern="[0-9 ]+"
            maxLength={19}
            onChange={(e) => {
              e.target.value = e.target.value
                .replace(/[^\dA-Z]/g, "")
                .replace(/(.{4})/g, "$1 ")
                .trim();

              if (cardImageProvider(e.currentTarget.value))
                setCurrentProvider(cardImageProvider(e.currentTarget.value));
              else setCurrentProvider("visa02");
            }}
            name="cardNumber"
          />
          <div
            style={{ position: "absolute", right: 6, top: 36 }}
            className="iconContainer"
          >
            <AmexFadedSvg />

            <MasterCardFaded />
          </div>
          <img
          	alt=""
            style={{ position: "absolute", top: 35, right: 39 }}
            height={28}
            width={29}
            src={
              require(`../../../../../assets/cardProvider/${currentProvider}.png`)
                ?.default
            }
          />
        </label>
      </div>
      <div className="fieldContainer2">
        <label className="label">
          EXPIRATION
          <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", right: 8 }}>
              <DownArrow />
            </div>
            {/* <div>
              <DownArrow />
            </div>{" "} */}
            <div style={{ position: "relative", display: "inline" }}>
              <select
                className="input expiryCombo select_box"
                name="month"
                placeholder="Month"
				defaultValue={""}
              >
                <option
                  className="selectPlaceholder"
                  value=""
                  disabled
                  hidden
                >
                  Month
                </option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
              <div
                style={{ position: "absolute", display: "inline", right: 8 }}
              >
                <DownArrow />
              </div>
            </div>

            <select
              className="input expiryCombo select_box"
              name="year"
              placeholder="Year"
            >
              <option
                className="selectPlaceholder"
                value=""
                disabled
                selected
                hidden
              >
                Year
              </option>

              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
              <option value="2028">2028</option>
              <option value="2029">2029</option>
              <option value="2030">2030</option>
              <option value="2031">2031</option>
              <option value="2032">2032</option>
              <option value="2033">2033</option>
              <option value="2033">2033</option>
              <option value="2034">2034</option>
              <option value="2035">2035</option>
              <option value="2036">2036</option>
              <option value="2037">2037</option>
              <option value="2038">2038</option>
            </select>
          </div>
        </label>
        <label className="label">
          CVV
          <div style={{ position: "relative" }}>
            <input className="input cvv" name="cvv" />
            <span style={{ position: "absolute", right: 4, top: 6 }}>
              <CreditCardSvg />
            </span>
          </div>
        </label>
      </div>
      <div>
        {/* <input />
        <input />
        <input /> */}
      </div>
    </div>
  );
};

export default Payment;
