import React, { useEffect } from "react";
import { useState, Dispatch, SetStateAction } from "react";
import {
  Typography,
  Grid,
  Button,
  TextField,
  Theme,
  Box,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { getSubscriptionDetails } from "./api";
import { toast } from "react-toastify";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";
const FormData = require("form-data");

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  updatetext: {
    paddingTop: "1rem",
  },
  billininfofname: {
    display: "flex",
    flexDirection: "row",
  },
  billnginfocountry: {
    borderBottom: "3px solid #F8F8F8",
    paddingBottom: "1rem",
  },
  billinginfocancel1: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    "&>*": {
      marginRight: "1rem",
    },
  },
  billinginfocancel2: {
    // paddingLeft: "6rem",
  },
  billingfields: {
    display: "flex",
    flexDirection: "row",
  },
  billingformname: {
    // paddingTop: "10px",
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '30px',
    color: '#585858',

  },
  billingtextfield: {
    // width: "70%",
    // width: '460px',
    // border: '0.5px solid #C6C6C6',

    boxSizing: 'border-box',
    "& input": {
      fontFamily: 'Poppins, sans-serif',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '30px',
      color: "#000000",
      // borderRadius: '2px',
      padding: '0px',
      height: '32px',
      paddingLeft: '12px',
      // border: '0.5px solid #C6C6C6',
      borderColor: '#C6C6C6',
    },
    "& .Mui-focused": {
      "& fieldset": {
        borderColor: '#057CFF',
      },
      "&:hover fieldset": {
        borderColor: '#057CFF',
      },
      "&.Mui-focused fieldset": {
        borderColor: '#057CFF',
      },
    },
  },
  heading: {
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '12px'
  }
}));
interface Props {
  open: any;
  handleClose: Dispatch<SetStateAction<any>>;
}
const BillingInfoModal: React.FC<Props> = ({ open, handleClose }) => {
  const [initialState, setInitialState] = useState<any>({
    firstName: "",
    lastName: "",
    businessName: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });
  const classes = useStyles({});
  const onSubmit = (data: any, { setSubmitting }: any) => {
    const formData = new FormData();
    formData.append("name", data.firstName);
    formData.append("surname", data.lastName);
    formData.append("business_name", data.businessName);
    formData.append("address", data.streetAddress);
    formData.append("city", data.city);
    formData.append("state", data.state);
    formData.append("postal_code", data.zipCode);
    formData.append("country", data.country);
    formData.append("no_notify", data.true);

    AxiosInstance.post('api/v1/billing/save_subscription_details', formData)
     .then(function (response: AxiosResponse) {
        setSubmitting(false);
        if (response.status === 200) {
          toast.success("Changes Saved", { autoClose: 2000 });
          handleClose("");
          // handleSave();
        }
      })
      .catch(function (error: any) {
        setSubmitting(false);
        if (error.response) {
          if (error.response.status === 400) {
            toast.warn(error.response.data.message);
          }
          if (error.response.status === 500) {
            alert("500, Server Error");
            toast.error(error.response.data.message);
          }
        }
      });
  };
  const getSubscriptionData = async () => {
    const { status, data } = await getSubscriptionDetails();
    if (status) {
      let { bills } = data;
      setInitialState({
        firstName: bills?.usr_name || "",
        lastName: bills?.usr_surname || "",
        businessName: bills?.business_name || "",
        streetAddress: bills?.address || "",
        city: bills?.city || "",
        state: bills?.state || "",
        zipCode: bills?.postal_code || "",
        country: bills?.country || "",
      });
    }
  };
  useEffect(() => {
    getSubscriptionData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required("First Name is a required")
      .max(160, "First Name Can not be more than 160 Character"),
    businessName: yup.string().required("Business Name is a required"),
    streetAddress: yup.string().required("Street Address is a required"),
    city: yup.string().required("City is a required"),
    state: yup.string().required("State is a required"),
    zipCode: yup.number().required("Zip Code is a required"),
    country: yup.string().required("Country is a required"),
  });

  return (
    <Dialog
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogContent>
        {/* <Card className={classes.cardRoot}> */}
        <Formik
          initialValues={initialState}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {({ isSubmitting, errors }) => {
            return (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <Typography className={classes.heading}>
                          Update Billing
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.billingfields}>
                    <Grid container spacing={0}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        className={classes.billininfofname}
                      >
                        <Grid container spacing={0} alignItems='center'>
                          <Grid item xs={12} sm={3}>
                            <Typography className={classes.billingformname}>
                              First Name
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <Field
                              name="firstName"
                              fullWidth
                              size="small"
                              as={TextField}
                              error={errors.firstName}
                              // helperText={errors.firstName}
                              margin="dense"
                              variant="outlined"
                              // label="First Name"
                              placeholder="First Name"
                              className={classes.billingtextfield}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} >
                        <Grid container spacing={0} alignItems='center'>
                          <Grid item xs={12} sm={3}>
                            <Typography className={classes.billingformname}>
                              Last Name
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <Field
                              name="lastName"
                              fullWidth
                              size="small"
                              as={TextField}
                              margin="dense"
                              variant="outlined"
                              // label="Last Name"
                              placeholder="Last Name"
                              className={classes.billingtextfield}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} >
                        <Grid container spacing={0} alignItems='center'>
                          <Grid item xs={12} sm={3}>
                            <Typography className={classes.billingformname}>
                              Business Name
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <Field
                              name="businessName"
                              fullWidth
                              size="small"
                              as={TextField}
                              error={errors.businessName}
                              // helperText={errors.businessName}
                              margin="dense"
                              variant="outlined"
                              // label="Business Name"
                              placeholder="Business Name"
                              className={classes.billingtextfield}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={0} alignItems='center'>
                          <Grid item xs={12} sm={3}>
                            <Typography className={classes.billingformname}>
                              Street Address
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <Field
                              name="streetAddress"
                              fullWidth
                              size="small"
                              as={TextField}
                              error={errors.streetAddress}
                              // helperText={errors.streetAddress}
                              margin="dense"
                              variant="outlined"
                              // label="Street Address"
                              placeholder="Street Address"
                              className={classes.billingtextfield}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={0} alignItems='center'>
                          <Grid item xs={12} sm={3}>
                            <Typography className={classes.billingformname}>
                              City
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <Field
                              name="city"
                              fullWidth
                              size="small"
                              as={TextField}
                              error={errors.city}
                              // helperText={errors.city}
                              margin="dense"
                              variant="outlined"
                              // label="City"
                              placeholder="City"
                              className={classes.billingtextfield}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={0} alignItems='center'>
                          <Grid item xs={12} sm={3}>
                            <Typography className={classes.billingformname}>
                              State
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <Field
                              name="state"
                              fullWidth
                              size="small"
                              as={TextField}
                              error={errors.state}
                              // helperText={errors.state}
                              margin="dense"
                              variant="outlined"
                              // label="State"
                              placeholder="State"
                              className={classes.billingtextfield}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={0} alignItems='center'>
                          <Grid item xs={12} sm={3}>
                            <Typography className={classes.billingformname}>
                              Zip Code
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <Field
                              name="zipCode"
                              fullWidth
                              size="small"
                              as={TextField}
                              error={errors.zipCode}
                              // helperText={errors.zipCode}
                              margin="dense"
                              variant="outlined"
                              // label="Zip Code"
                              placeholder="Zip Code"
                              className={classes.billingtextfield}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className={classes.billnginfocountry}>
                        <Grid container spacing={0} alignItems='center'>
                          <Grid item xs={12} sm={3}>
                            <Typography className={classes.billingformname}>
                              Country
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <Field
                              name="country"
                              fullWidth
                              size="small"
                              as={TextField}
                              error={errors.country}
                              // helperText={errors.country}
                              margin="dense"
                              variant="outlined"
                              // label="Country"
                              placeholder="Country"
                              className={classes.billingtextfield}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box className={classes.billinginfocancel1} mb={3}>
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={handleClose}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      variant="contained"
                      size="large"
                      color="primary"
                      type="submit"
                    >
                      Save
                    </Button>
                  </Box>
                </Grid>
              </Form>
            );
          }}
        </Formik>
        {/* </Card> */}
      </DialogContent>
    </Dialog>
  );
};
export default BillingInfoModal;
