import React, { useEffect, useState } from "react";
// import { SketchPicker } from "react-color";
import { makeStyles } from "@material-ui/core/styles";
import { useStyles } from "../styles";
import { IconButton, Box } from '@material-ui/core';
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../redux/actions/styleGuideActions";
import {AutoSave} from "../StyleGuideAutosave";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

interface Props {
  fieldColorKey: string;
  fieldColorDark: string;
  fieldColorValue: string;
}

const DarkColorPicker: React.FC<Props> = ({ fieldColorDark, fieldColorKey, fieldColorValue }) => {
  const dispatch = useDispatch();
  const reduxState = useSelector((state: any) => state.styleGuide);
  const [state, setState] = React.useState<any>({
    displayDarkColorPicker: false,
  });
  const [colorDark, SetColorDark] = useState(fieldColorDark);
  

  const handleClick = () => {
    setState({ displayDarkColorPicker: true });
  };

  const handleClose = (color: any) => {
    setState({ displayDarkColorPicker: false });
  };

  const styles = makeStyles((theme) => ({
    color: {
      width: "fit-content",
      border: "1px solid #CDCDCD",
      // margin: 10,
      background: colorDark || "#F8F8F8",
      "&:hover": {
        background: colorDark || "#F8F8F8",
      },
      zIndex:1
    },
    colorPallet: {
      padding: 8,
      position:"absolute",
      left: "-5px",
      top: "-2px",
      border: "1px solid #CDCDCD",
      background: fieldColorValue || "#F8F8F8",
      "&:hover": {
        background: fieldColorValue || "#F8F8F8",
      },
      zIndex:0
    },

    colorBase: {
      position: "relative",
    },
  }));

  const classes = useStyles();
  const classCss = styles();

  const changeColorPalletValue = (color:any) => {
    reduxState?.mainColorPallet.forEach((item: any)=>{
      if(item.key === fieldColorKey){
        item["dark"] = color;
        SetColorDark(color);
        dispatch(addField(item.key+"_dark", color));
      }
    });    
    dispatch(addField("mainColorPallet", reduxState?.mainColorPallet));
    AutoSave();
  }

  useEffect(()=>{
    if(fieldColorDark !== colorDark) {
      SetColorDark(fieldColorDark);
    }
    // eslint-disable-next-line
  },[fieldColorDark]);

  return (
    <div className={fieldColorKey}>
      <div className={classCss.colorBase}>
        <IconButton className={classCss.color} onClick={handleClick}>
          <AddOutlinedIcon
            fontSize="inherit"
            style={{ color: "transparent" }}
          />
        </IconButton>
        <IconButton className={classCss.colorPallet}>
        </IconButton>

      </div>
      {state.displayDarkColorPicker ? (
        <div className={`${classes.popover} ${classes.plPopover}`}>
          <div className={classes.cover} onClick={handleClose} />
           <List className={classes.ulMainColorPalley}>
                { reduxState?.mainColorPallet?.map(function(element:any, index: any) { 
                  return(                                
                    <ListItem key={index} className={classes.listColorPallet} onClick={(e) => {
                      changeColorPalletValue(element.value);
                          }}>

                      <Box 
                        className={`${classes.colorPalletValue} ${classes.colorPalletwihte} ` } 
                        style={{ background:element?.value }}
                      >
                      </Box>
                      <ListItemText className={classes.colorPalettName} primary={element.name} title={element.name} />
                    </ListItem>    
                  )
                  })                  
                }   
            </List>
        </div>
      ) : null}
    </div>
  );
};

export default DarkColorPicker;
