import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { useStyles } from "../styles";
import TrackingParametersFields from "../utilities/TrackingParametersFields";
interface Props {}
const TrackingParameters: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" className={classes.section}>
      <Typography className={classes.heading} variant="h6">
      Parameters
      </Typography>
      <Typography variant="caption" className={classes.caption}>     
         These are added to the end of your link URL automaticaly.These are commonly used for apending Google Analytics tracking.

      </Typography>
      <TrackingParametersFields />
    </Box>
  );
};

export default TrackingParameters;
