export const convertReduxState=(data:any)=>{
    const payload:any = {}; 
   payload['ParameterList'] = data.ParametersData.map((item:any)=>({Parametertype:item.parameter_type,ParameterFieldLabel:item.field_label,ParameterName:item.parameter_name,ParameterValue:item.parameter_value}));
   payload['AttributesList'] = data.AttributeData.map((item:any)=>({Attributetype:item.attribute_type,AttributeFieldLabel:item.field_label,AttributeName:item.attribute_name,AttributeValue:item.attribute_value}));
   payload['StyleFromDbList'] = data.StyleData.map((item:any)=>({StyleDataId:item.styleguide_id}));
   payload['tracking_name'] = data.tracking_name;

   return payload
}


