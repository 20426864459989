import React, {useEffect} from "react";
import { Box, Switch } from "@material-ui/core";
import { useStyles } from "../../styles";
import TextFiledNumber from './TextFiledNumber';
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../../redux/actions/styleGuideActions";
import { AutoSave } from "../../StyleGuideAutosave";

interface Props {
  title:string;
  fieldName:string;
}

const ButtonRaduis: React.FC<Props> = ({title, fieldName}) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  // const [addFallBack, SetAddFallBack] = useState(false);
  const state = useSelector((state: any) => state.styleGuide);

  const handleChangeCornerRaduisOutlook = (event:any) => {
    // setSwitchCornerRaduisOutlook(event.target.checked);    
    if(event.target.checked){
      dispatch(addField("button_radius_switch", event.target.checked));
    } else {      
      dispatch(addField("button_radius_switch", "false"));
    }
    AutoSave();
  };
useEffect(()=>{
  if(state?.button_radius === "0" && state?.button_radius_switch === true){
    dispatch(addField("button_radius_switch", "false"));
    AutoSave();
  }
  // eslint-disable-next-line
},[state?.button_radius]);

  return (
        <Box className={classes.section4}>
          <Box display="flex" className={classes.styleList1} >
            <Box className={classes.TitleDescription1}>        
              <Box className={classes.flexColumn1}>         
                {title}
              </Box>  
            </Box>      
            <Box className={classes.ContainerFontSize}>
              &nbsp;
            </Box>        
            <Box className={classes.ContainerLineHeightforRadiusbut}>
              <TextFiledNumber fieldName={fieldName} title="" min={0} max={50}  />
            </Box>
          </Box>
          {state?.button_radius !== "0" && (<><Box className={classes.alertCornerRadius}>
            <Alert severity="info">
              Rounded corners are supported on most modern email clients however not on some versions of Outlook, 
              less than 4% of the market. We recommend leaving Outlook support turned off, but if necessary you can turn this&nbsp;on&nbsp;below.
            </Alert>
          </Box>
          <Box className={classes.cornerRadiusOutlook}>
           <Box flex="1">
              <Box className={classes.flexColumn1}> 
                Microsoft Outlook Support
              </Box>
            </Box>
            <Switch
              checked={state?.button_radius_switch === true}
              onChange={handleChangeCornerRaduisOutlook}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Box></>)}
        </Box>
  );
};

export default ButtonRaduis;