import React, { useState, useEffect } from "react";
import CardMedia from "@material-ui/core/CardMedia";
import Skeleton from "@material-ui/lab/Skeleton";
import Screenshot from '../../../../../../../utils/Screenshot';

interface Props {
  thumb: string;
  classes: string;
  campId: string;
}

const CampaignImage: React.FC<Props> = ({ thumb, classes, campId }) => {
  const [screenshot, setScreenshot] = useState(thumb);

  const loadScreenShot = async () => {
    const screenshot = await new Screenshot(
      campId, 
      "campaign", 
      600, 
      `campaigns/previewCamp/${campId}?mute=true`
    ).load();
    setScreenshot(screenshot);
  };

  useEffect(() => {
    loadScreenShot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campId]);

  return (
    <>
      {screenshot != null ? (
        <CardMedia
          image={screenshot}
          className={classes}
          title=""
        />
      ) : (
        <Skeleton animation="wave" variant="rect" className={classes} />
      )}
    </>
  );
};

export default CampaignImage;
