import React, { useEffect, useRef, useState }  from "react";
import { Box, TextField } from "@material-ui/core";
import { useStyles } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../../redux/actions/styleGuideActions";
import { DefaultCustomNameList } from "../../utilities/DefaultStyleGuide";
// import { AutoSave } from "../../StyleGuideAutosave";
import StyleItem from "./StyleItem";
import AddStyleButton from "./AddStyleButton";
import { getStyleDraggable, updateCustomCssStyleSheet } from "../../../../../../utils/Functions";
// import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
// import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import CloseIcon from '@material-ui/icons/Close';
import ReorderIcon from '@material-ui/icons/Reorder';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

interface Props {
  data:any
}

const CustomItem: React.FC<Props> = ({data}) => {
    const dispatch = useDispatch();
  const classes = useStyles();
  const state = useSelector((state: any) => state.styleGuide);
  const [customName, setCustomName] = useState(data?.type === "googlefonts" ? "Custom New" : data.family);
  const [nameChanged, setNameChanged] = useState(false);

  const handleRemoveClick = (styleItem:any) => {
    const arr = [...state?.list_custom_fonts];
    const order = styleItem?.order;
    let filteredList = arr?.map((item: any) => ({
      ...item,
      styles: item.styles.filter((style: any) => style.key !== styleItem?.key),
    }));    
    filteredList = fixOrderStyle(filteredList, order);
    dispatch(addField("list_custom_fonts", filteredList));    
    updateCustomCssStyleSheet(filteredList);
  }

  const fixOrderStyle = (filteredList:any ,order:any) => {
    // let intOrder = parseInt(order);
    filteredList?.forEach((item:any) => {
      item?.styles?.forEach((style:any) => {
        if(parseInt(style.order) > parseInt(order)){          
          style.order = (parseInt(style.order) - 1).toString();
        }
      });
    });
    return filteredList;
  }



const handleNameChange = (name:any) => {
  let arr = state?.list_custom_fonts;
  arr?.forEach((option:any) => {
    if(option.key === data.key){
      if(option.type === "googlefonts"){
        option["type"] = "customfonts";
        option["css_link"] = "";
      }
      option["family"] = name;   
      dispatch(addField("list_custom_fonts", arr));
      updateCustomCssStyleSheet(arr);
    }
  });

  DefaultCustomNameList?.forEach((option:any) => {
    if(data.key === state["key_" + option]) {
      dispatch(addField(option, name));
    }
  });

}


useEffect(() => {
  // Create a setTimeout function to check if the user has finished typing
  const delayTypingName = setTimeout(() => {
    // Add your logic here after the user finishes typing
    if(nameChanged) {
      handleNameChange(customName);
      setNameChanged(false);
    }
  }, 1200);

  // Clear the timeout if customName changes before 1200ms
  return () => clearTimeout(delayTypingName);
  // eslint-disable-next-line
}, [customName]);
  


const droppableRef = useRef<any>();
const styleList = data?.styles;

const setStyleList = (list: any) => {
  let arr = state?.list_custom_fonts;
  list?.forEach((item:any, index:any) => {
    item.order = (index + 1).toString();
  });
  arr?.forEach((option:any) => {
    if(option.key === data.key){
      option["styles"] = list;
      dispatch(addField("list_custom_fonts", arr));
      updateCustomCssStyleSheet(arr);
    }
  });
};


  return (
    <Box className={classes.customFontsContainer}>
      <Box className={classes.fontsContainerRow}>
        <TextField
          value={customName}
          label="Name"
          style={{ marginTop: 8,marginBottom: 16 }}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) =>{
            setNameChanged(true);
            setCustomName(e.target.value);
          }}
          variant="outlined"
        />
      </Box>
      <Box>
        <div ref={droppableRef} className={classes.dragDropMenuConatiner}>
          <DragDropContext
            onDragEnd={(params: any) => {
              if (!(params.source && params.destination)) return;
              const sourceIndex: number = params.source.index;
              const destinationIndex: number = params.destination.index;
              styleList.splice(
                destinationIndex,
                0,
                styleList.splice(sourceIndex, 1)[0]
              );
              setStyleList(styleList);
            }}
          >
            <Droppable droppableId="droppable-1">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {styleList.map((styleItem: any, i: any) => {
                    return (
                      <Draggable
                        key={i}
                        draggableId={"draggable-" + i}
                        index={i}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getStyleDraggable(
                              snapshot,
                              provided,
                              "draggable-" + i,
                              droppableRef
                            )}
                          >
                            <Box key={styleItem?.key ? styleItem?.key : i} style={{paddingBottom:15}}>  
                              <Box display="flex" className={`${classes.StyleItemRow}`}> 
                                <StyleItem data={styleItem} />
                                {styleList.length > 0 && (
                                  <div
                                    className={classes.fieldSideIconDiv}
                                  >
                                    <CloseIcon
                                      fontSize="default"
                                      className={classes.fieldSideIcon}
                                      onClick={() => handleRemoveClick(styleItem)
                                      
                                      }
                                    ></CloseIcon>
                                    <div {...provided.dragHandleProps}>
                                      <ReorderIcon
                                        className={classes.fieldSideIcon}
                                        fontSize="default"
                                      ></ReorderIcon>
                                    </div>
                                  </div>
                                )}
                              </Box>
                            </Box>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Box>
      <Box>
        {/* {
          data?.styles?.map((Item : any, index:any) => {
            return (      
              <Box key={Item?.key ? Item?.key : index} style={{paddingBottom:15}}>  
                <Box display="flex" className={`${classes.StyleItemRow}`}>          
                  <StyleItem data={Item} />

                  <Box className={`${classes.StyleItemCol}`}>
                    
                    <Box className={classes.styleFieldSideIcon} >
                      <HighlightOffRoundedIcon
                        fontSize="default"
                        className={classes.fieldSideIcon}
                        onClick={() => handleRemoveClick(Item)}
                      ></HighlightOffRoundedIcon>
                      <Box>
                        <MenuRoundedIcon
                          className={classes.fieldSideIcon}
                          fontSize="default"
                        ></MenuRoundedIcon>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )
            })
        } */}
             
      <Box>
        <AddStyleButton data={data} /> 
      </Box>
      </Box> 
    </Box>
  );
};

export default CustomItem;
