import React, { useEffect, useState } from "react";
import { TextField} from "@material-ui/core";
// import { useStyles } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../redux/actions/styleGuideActions";
// import { AutoSave } from "../StyleGuideAutosave";

interface Props {
  fieldName: any;
  label: any;
  error?:any;
}

const ContactDetailsTextFiled: React.FC<Props> = ({fieldName,label,error}) => {  
  // const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.styleGuide);
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(state[fieldName]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state[fieldName]]);

  useEffect(() => {
    // const delayTypingName = setTimeout(() => {
      handleInputChange(value,fieldName);
    // }, 1200);

    // return () => clearTimeout(delayTypingName);

    // eslint-disable-next-line
  }, [value])

const handleInputChange = (value : string,fieldName: string) => {
      if(value !== state[fieldName])
      {
        dispatch(addField(fieldName, value));
        // AutoSave();
      }
  };
  return (
    <>
      <TextField
          value={value}
          label={label}
          error={state[fieldName+"_error"]}
          helperText={state[fieldName+"_error"]}
          style={{ marginTop: 8,marginBottom: 16 }}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setValue(e.target.value)}
          variant="outlined"
        />
    </>
  );
};

export default ContactDetailsTextFiled;
