import React, { useState }  from "react";
import Typography from "@material-ui/core/Typography";
import { Box, Radio } from "@material-ui/core";
import { useStyles } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import GoogleItem from "./GoogleItem";
import CustomItem from "./CustomItem";
// import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import CloseIcon from '@material-ui/icons/Close';
import { addField } from "../../../../../redux/actions/styleGuideActions";
import { getNewStyleFont, updateCustomCssStyleSheet } from "../../../../../../utils/Functions";
import { DefaultCustomNameList } from "../../utilities/DefaultStyleGuide";

interface Props {
  data:any,
} 

const CustomFontContainer: React.FC<Props> = ({data}) => {
  const classes = useStyles();
  const state = useSelector((state: any) => state.styleGuide);
  const dispatch = useDispatch();
  const [customFontsSelected, setCustomFontsSelected] = useState(data && data.type === "customfonts" ? 'customfonts' : 'googlefonts');

  const handleChangeFontStyle = (event:any) => {
    setCustomFontsSelected(event.target.value); 
  };
  const handleChangeGoogleFonts = () => {
    setCustomFontsSelected("googlefonts");
  };
  const handleChangeCustomFonts = () => {
    setCustomFontsSelected("customfonts"); 
    if(data?.styles?.length === 0){
      let style = getNewStyleFont(data);
      if(style) data?.styles?.push(getNewStyleFont(data));
    }
  };

  const handleRemoveCustomClick = () => {
    let arr = state?.list_custom_fonts;
    const updatedArray = arr?.filter((item:any) => item.key !== data.key);
    dispatch(addField("list_custom_fonts", updatedArray));
    updateCustomCssStyleSheet(updatedArray);
    DefaultCustomNameList?.forEach((option:any) => {
      if(data.key === state["key_" + option]) {
        dispatch(addField(option, "none")); 
      }
    });

  }

  return(
          <Box>
            <Box display="flex">
              <Box flex={1} >
                <Typography variant="caption" className={classes.typographyText}>
                  Select a Google Font or upload a Custom Font.
                </Typography>
              </Box>
              <Box className={classes.customFontIconDeleteContainer}>
              {state?.list_custom_fonts?.length > 1 && <CloseIcon
                  fontSize="large"
                  className={classes.fieldIconDelete}
                  onClick={() => handleRemoveCustomClick()}
                ></CloseIcon>}
              </Box>
            </Box>
            <Box className={classes.fontContainerRadio} style={{display:"flex"}}>
              <Box className={customFontsSelected !== 'googlefonts' ? classes.radioGoogleFontParent : ""} style={{display:"flex",alignItems:"center"}}>
                <Radio
                  checked={customFontsSelected === 'googlefonts'}
                  onChange={handleChangeFontStyle}
                  value="googlefonts"
                  name="radio-button-googlefonts"
                  color="primary"
                  className={classes.radioGoogleFont}
                />
                <Box style={{ paddingRight: 10, display: "flex"}}> 
                  <svg height="17" viewBox="0 0 509.58086729 397.8808683" xmlns="http://www.w3.org/2000/svg">
                    <path className={classes.googleLogo2} d="m0 387.4 245.52-385.39 131.6 83.84-192.11 301.55z" fill="#fbbc04"/>
                    <path className={classes.googleLogo3} d="m240.33 0h151.13v387.4h-151.13z" fill="#1a73e8"/>
                    <circle className={classes.googleLogo1} cx="83.72" cy="81.35" fill="#ea4335" r="74.91"/>
                    <path className={classes.googleLogo5} d="m499.1 279.76a107.64 107.64 0 0 1 -107.64 107.64v-215.27a107.64 107.64 0 0 1 107.64 107.63z" fill="#34a853"/>
                    <path className={classes.googleLogo4} d="m391.46 172.13v215.27a107.64 107.64 0 0 1 0-215.27z" fill="#0d652d"/>
                    <path className={classes.googleLogo7} d="m474.3 89.29a82.85 82.85 0 0 1 -82.84 82.84v-165.69a82.85 82.85 0 0 1 82.84 82.85z" fill="#1a73e8"/>
                    <path className={classes.googleLogo6} d="m391.46 6.44v165.69a82.85 82.85 0 0 1 0-165.69z" fill="#174ea6"/>
                  </svg>
                </Box>
                <Typography variant="body1" onClick={handleChangeGoogleFonts} style={{cursor:"pointer",marginRight: 36}}>
                  Google Fonts
                </Typography>
              </Box>
              <Box style={{display:"flex",alignItems:"center"}}>
                <Box>
                  <Radio
                    checked={customFontsSelected === 'customfonts'}
                    onChange={handleChangeFontStyle}
                    value="customfonts"
                    name="radio-button-customfonts"
                    color="primary"
                    className={classes.radioCustomFonts}
                  />
                </Box>
                <Typography variant="body1"  onClick={handleChangeCustomFonts} style={{cursor:"pointer"}}>
                  Custom Fonts
                </Typography>
              </Box>
            </Box>

            {(customFontsSelected === "googlefonts") && (
              <GoogleItem data={data} />
            )}
            {(customFontsSelected === "customfonts" ) && (
              <CustomItem data={data} />
            )}  

          </Box>
        )

}

export default CustomFontContainer;