import React from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import {redirectTo} from "../../../../../utils/AuthUtility";

interface Props {
  handleNext: any;
}
const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: "2rem",
    width: "20rem",
  },
  skipButton: {
    marginTop: "2rem",
    color: "black",
    width: "20rem",
  },
}));
const Footer: React.FC<Props> = ({ handleNext }) => {
  const classes = useStyles();

  return (
    <Grid>
      <Grid>
        <Button
          onClick={() => {
            redirectTo('/');
          }}
          color="primary"
          variant="contained"
          className={classes.button}
        >
          Next
        </Button>
      </Grid>
      <Grid>
        <Button
          onClick={handleNext}
          color="primary"
          className={classes.skipButton}
        >
          Skip
        </Button>
      </Grid>
    </Grid>
  );
};

export default Footer;
