import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useStyles } from "./styles";
import FolderTree from "./components/FolderTree/FolderTree";
import Sidebarcomponents from "./components/FolderTree/Sidebarcomponents";
import FileList from "./components/FileList/FileList";
import Typography from "@material-ui/core/Typography";
import FileListSkleton from "./components/skleton/fileList";
import FileTreeSkleton from "./components/skleton/fileTree";
import { Container } from "@material-ui/core";
import {getUserFromToken,getUserId} from "../../../../../utils/AuthUtility";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";

// import { DragDropContext } from "react-beautiful-dnd";
interface Props {
  handleClickNewCampaign: any;
  history?: any;
  URL:any;
}

const CampaignList: React.FC<Props> = ({ handleClickNewCampaign, history,URL }) => {
  const classes = useStyles();

  const loggedInUser = getUserFromToken();
  const loggedInUserId = getUserId();
  const [campFolders, setCampFolders] = React.useState<any>([]);
  const [folderOverlay, setFolderOverlay] = React.useState<any>(false);

  const [loadCamps, setLoadCamps] = React.useState<any>([]);

  const [listnumRows, setListnumRows] = React.useState<any>(0);
  const [listshowing, setListshowing] = React.useState<any>(0);
  const [listNextPage, setListNextPage] = React.useState<any>(1);
  const [listOverlay, setListOverlay] = React.useState<any>(false);
  const [listCompleteStatus, setListCompleteStatus] =React.useState<boolean>(true);
  const [searchStatus, setSearchStatus] = React.useState<string>("");
  const [searchStyleStatus, setsearchStyleStatus] = React.useState<string>("");
  const [querySearch, setQuerySearch] = React.useState<string>("");
  const [folderID, setFolderID] = React.useState<any>([]);
  const [designUpated, setDesignUpated] = React.useState<any>("");
   const [renderFileList, setRenderFileList] = React.useState<any>([]);
  const [renderStyleList, setrenderStyleList] = React.useState<any>([]);
  const [styleFetchData, setstyleFetchData] = React.useState<any>([]);
  
  const [dragOverFolder, setDragOverFolder] = React.useState(null);
  const [UrlRender, setUrlRender] = React.useState<any>(false);
  const [ParamFolderId, setParamFolderId] = React.useState<any>(0);
  const [connectedesp, setconnectedesp] = React.useState<any>([]);
  
 
  
  var brandID : any = '';
  if(loggedInUser !==null )
  {
       brandID = loggedInUser.user_detail.Company;
  }
 

function getParam(param : any){
return new URLSearchParams(window.location.search).get(param);
}
 const getconnectedEsp = () => {
    AxiosInstance
		.get('api/v1/Integration/getconnectedEsp', {
			params: {
				brandID: brandID,
			},
		}).then((response: AxiosResponse) => {
                if(response.data.data)
                {
                    if(response.data.data.connectedesp.length>0)
                    {
                        setconnectedesp(response.data.data.connectedesp);

                    }
                }
		})
  };
  
  const getCampFolders = () => {
    AxiosInstance
		.get('api/v1/Campaign/getCampFolders', {
			params: {
				brandID: brandID,
			},
		}).then((response: AxiosResponse) => {
			if (response.data.status === true) {
			  setCampFolders(response.data.folders[0]);
			  setFolderOverlay(true);
			}
		})
  };
  const getloadCamps = () => {
      var SelectedFolder;
    if(UrlRender===false && getParam('folderID')!=null){
        SelectedFolder = getParam('folderID')
      } else{
          SelectedFolder = folderID;
      }
         setParamFolderId(SelectedFolder);
    AxiosInstance
		.get('api/v1/Campaign/loadCamps', {
			params: {
				folderID: SelectedFolder,
				status: searchStatus,
				query: querySearch,
				page: listNextPage,
                loggedInUserId:loggedInUserId,
				StyleId:searchStyleStatus,
			}
		})
		.then((response: any) => {
			if (response.data.status === true) {
            const allCamps = loadCamps.concat(response.data.camps);
            setLoadCamps(allCamps);
            setUrlRender(true);
            setRenderFileList(allCamps);
            setListnumRows(response.data.numRows);
            setListshowing(response.data.showing);
            setListNextPage(response.data.nextPage);
			  if (response.data.numRows === response.data.showing) {
				setListCompleteStatus(false);
			  } else {
				setListCompleteStatus(true);
			  }
			  setListOverlay(true);
			}
		})
  };

  const triggerCampActions = (action: string, data: any) => {
		switch (action) {
			case 'delete': removeCampFromList(data.campToDelete); break;
			case 'duplicate': addCampToList(data.camp, data.beforeIndex); break;
			case 'moveToFolder': moveCampToFolder(data.selectedCampId, data.selectedFolderID,data.selectedFolderName); break;
		}
  };

  const removeCampFromList = (campId: string) => {
		const indexToDelete = loadCamps.findIndex((camp: any) => camp.cv_id === campId);
		if (indexToDelete || indexToDelete === 0) {
			const listWithoutDeletedCamp = [
				...loadCamps.slice(0, indexToDelete),
				...loadCamps.slice(indexToDelete + 1)
			]
			setLoadCamps(listWithoutDeletedCamp);
			setRenderFileList(listWithoutDeletedCamp);
		}
  }

  const addCampToList = (camp: any, beforeCampId: string) => {
		const campIndexBefore = loadCamps.findIndex((camp: any) => camp.cv_id === beforeCampId);
		const newList = [...loadCamps];
		newList.splice(campIndexBefore, 0, camp)
	  	setLoadCamps(newList);
	  	setRenderFileList(newList);
  }

  const moveCampToFolder = (selectedCampId: string, selectedFolderID: string,selectedFolderName:string) => {
    let folderChild = '';
    if(folderID){
      const folderElement = searchNode(campFolders, folderID);   
      folderChild = searchId(folderElement, selectedFolderID);    
    }   

    if(folderChild == null) {
      removeCampFromList(selectedCampId);
      setListnumRows(listnumRows - 1);
      setListshowing(listshowing - 1);
    } else {
      const newList = loadCamps.map((camp: any) => {
        return camp.cv_id === selectedCampId ? {
          ...camp,
          camp_folders_id: selectedFolderID,
          folder_name: selectedFolderID === '0' ? null : selectedFolderName
        } : camp;
      });
      setLoadCamps(newList);
      setRenderFileList(newList);
    }    
  }

  const searchNode = (tree:any, id:any):any => {
    if (String(tree.id) === String(id)) {
      return tree;
    }
    
    for (const child of tree.childes) {
      const res = searchNode(child, id);
      if (res) {
        return res;
      }
    }
    return null;
  };

  const searchId = (tree:any, id:string):any => {
    if (String(tree.id) === String(id)) {
      return tree;
    }
    
    for (const child of tree.childes) {
      const res = searchId(child, id);
      
      if (res) {
        return res;
      }
    }
    return null;
  };

  const moveCampToFolderFunction = (folderId: number, cv_camp_id: number) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("camp_id", cv_camp_id);
    data.append("newBrandID", brandID);
    data.append("folder_id", folderId);

    AxiosInstance
		.post('api/v1/Campaign/moveCampToFolder', data)
		.then((response: any) => {
			if (response.data.status === true) {
			  getloadCampsInitial();
			}
	    })
  };
  const getloadCampsInitial = () => {
    
    setLoadCamps([]);
    setRenderFileList([]);
    var SelectedFolder;
    if(UrlRender===false && getParam('folderID')!=null){
        SelectedFolder = getParam('folderID')
      } else{
          SelectedFolder = folderID;
      }
          setParamFolderId(SelectedFolder);
         
    AxiosInstance.get('api/v1/Campaign/loadCamps', {
		params: {
			folderID: SelectedFolder === 0 ? "" : SelectedFolder,
			status: searchStatus,
			query: querySearch,
            StyleId:searchStyleStatus,
            loggedInUserId:loggedInUserId,
			page: 1,
		}
	}).then((response: AxiosResponse) => {
       
        if (response.data.status === true) {
            setUrlRender(true);
            //setLoadCamps([]);
          setLoadCamps(response.data.camps);          
	      setRenderFileList(response.data.camps);
          setListnumRows(response.data.numRows);
          setListshowing(response.data.showing);
          setListNextPage(response.data.nextPage);
          if (response.data.numRows === response.data.showing) {
            setListCompleteStatus(false);
          } else {
            setListCompleteStatus(true);
          }
          setListOverlay(true);
        }
      })
  };
   const loadStyles = () => {
  	AxiosInstance.get(`api/getStyleguide/${localStorage.getItem("company_id")}`)
      .then((response: AxiosResponse) => {
          if(response.data.usertable)
          {
              if(Object.keys(response.data.usertable).length>0)
              {
				var temp = response.data.usertable;
				setstyleFetchData(temp);
				var feed = {id: "", styleguide_name:"All"};
				response.data.usertable.unshift(feed);
				setrenderStyleList(response.data.usertable);
              }
          }
      });
  };
  

  // move folder drag and drop
  const moveFolderDragandDrop = (folderId: number, parrent_id: number) => {
     var FormData = require("form-data");
      var data = new FormData();
    data.append("folder_id", folderId);
    data.append("parent_id", parrent_id);
    data.append("newBrandID", brandID);

    AxiosInstance.post('api/v1/Campaign/moveCampFolderToFolder', data)
    	.then((response: AxiosResponse) => {
			if (response.data.status === true) {
			  getCampFolders();
			}
    	})
  };
const checkmktaccstatus = () => {
  	 AxiosInstance.get('api/v1/Integration/checkmktaccountstatus', {
		params: {},
	}).then((response: AxiosResponse) => {
        if (response.data.data.status === '1') {
         localStorage.removeItem("mktstatusafterload");
         localStorage.removeItem("mktstatus");
         localStorage.setItem("mktstatusafterload",'1');
         localStorage.setItem("mktstatus",'1');
        } else {
            localStorage.removeItem("mktstatusafterload");
            localStorage.removeItem("mktstatus");
            localStorage.setItem("mktstatusafterload",'0');
            localStorage.setItem("mktstatus",'0');
        }
      })
  };
  

 useEffect(() => {
    checkmktaccstatus(); 
    getCampFolders();
    getconnectedEsp();
    if(URL!=='campaign')
    { 
      loadStyles();
    }
    // eslint-disable-next-line 
  }, [brandID]);
  
  useEffect(() => {
    if(URL==='campaign')
	{ 
		var campReload = localStorage.getItem('campReload');
		
		if(campReload === 'true'){
			setFolderID([]);
			getCampFolders();
			getloadCampsInitial();
			localStorage.setItem('campReload',JSON.stringify(false));
			
		}
		loadStyles();
        getconnectedEsp();
        getloadCampsInitial();
        setLoadCamps([]);
     
	 
	}
    // eslint-disable-next-line 
  }, [URL]);
  
  useEffect(() => {
      setLoadCamps([]);
      getloadCampsInitial();
      // eslint-disable-next-line 
  }, [searchStatus,searchStyleStatus,querySearch,folderID,brandID,designUpated]);
  

  

  return (
  
    <Container style={{ paddingLeft: "0px",paddingRight:"0px" }} className={`${classes.root} ${localStorage.getItem("trial_mode") === 'true' ? classes.hasTrialInfo: ""}`}>
      
      <div className={classes.capmaignEntryDiv} style={{ paddingLeft: "0px" }}>
        <Grid container style={{ paddingLeft: "0px" }}>
        
            <Grid item xs={12} sm={12} md={4} >
                <Sidebarcomponents
                querySearch={querySearch}
                renderStyleList={renderStyleList}
                setQuerySearch={setQuerySearch}
                setSearchStatus={setSearchStatus}
                setsearchStyleStatus={setsearchStyleStatus}
                setListNextPage={setListNextPage}
                setLoadCamps={setLoadCamps}
                searchStatus={searchStatus}
                searchStyleStatus={searchStyleStatus}
                handleClickNewCampaign={handleClickNewCampaign}
                />
            {folderOverlay === false ? (
            
             <FileTreeSkleton />
            
            ) : (
              <FolderTree
                fileTreeData={campFolders}
                setFolderID={setFolderID}
                history={history}
                brandID={brandID}
                refreshCampFolders={getCampFolders}
                moveCampToFolderFunction={moveCampToFolderFunction}
                moveFolderDragandDrop={moveFolderDragandDrop}
                setDragOverFolder={setDragOverFolder}
                dragOverFolder={dragOverFolder}
                ParamFolderId={ParamFolderId}
              />
              )}
            </Grid>
          
          {listOverlay === false ? (
            <Grid item xs={12} sm={12} md={8} className={`${classes.mainBody} ${localStorage.getItem("trial_mode") === 'true' ? classes.hasTrialInfo: ""}`}>
              <FileListSkleton />
              <FileListSkleton />
              <FileListSkleton />
              <FileListSkleton />
              <FileListSkleton />
            </Grid>
          ) : (
            <>
              <Grid  item xs={12} sm={12} md={8} className={`${classes.mainBody} ${localStorage.getItem("trial_mode") === 'true' ? classes.hasTrialInfo: ""}`}>
                <FileList

                  fileMapData={loadCamps}
                  getloadCampsLoad={getloadCamps}
                  listCompleteStatus={listCompleteStatus}
                  history={history}
                  getloadCampsInitial={getloadCampsInitial}
                  triggerCampActions={triggerCampActions}
                  setLoadCamps={setLoadCamps}
                  brandID={brandID}
                  renderFileList={renderFileList}
                  setRenderFileList={setRenderFileList}
                  setDragOverFolder={setDragOverFolder}
                  dragOverFolder={dragOverFolder}
				  styleFetchData={styleFetchData}
				  setDesignUpated={setDesignUpated}
                  connectedesp={connectedesp}

                />
                 <Grid item xs={12} sm={12} md={4}></Grid>
              <Grid item xs={12} sm={12} md={12} className={classes.listCount}>
                {listshowing !== 0 ? (
                  <Typography>
                    showing {listshowing} out of {listnumRows} results
                  </Typography>
                ) : (
                  <Typography>No results found</Typography>
                )}
              </Grid>

              </Grid>
             
            </>
          )}
        </Grid>
      </div>
    </Container>
  );
};

export default CampaignList;
