// import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
// import LogoSVG, { SignInSVG } from "../../../../assets/svgs/Svgs";
import { Box } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { ShapesHerolgt, LogoMashrmail } from '../../../../assets/svgs/Svgs';
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  svg: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f7f7fc",
    justifyContent: "center",
  },
  formGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  paper: {
    margin: "85px 32px",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    minWidth:350,
    maxWidth: 635,
    width: "100%",
    position: "relative",
    height: "100%"
    // alignItems: "center",
  },
  logo: {
    position: "absolute",
    top: 25,
    left: 30,
  },
  listItem: {
    paddingLeft: "0px",
    paddingTop: '2px',
    paddingBottom: '2px',    
    display: "flex",
    alignItems: "flex-start",
    "& > .MuiListItemText-dense" :{
      paddingLeft: 10
    }
  },
  listText: {
    color: "#646464",
    fontSize: "16px",
  },
  listIcon: {
    minWidth: "5px",
    color: "#11142D",
    paddingTop: 10
  },
  listDot: {
    fontSize: "8px",
  },
  resendLink: {
    color: "#057CFF",
    textDecoration: "auto",
    fontWeight: 500,
  },
  subheader: {
    fontSize: "16px",
    lineHeight: '26px',
    fontWeight: 600,
    paddingTop: "1rem",
    marginBottom: '10px'
  },
  messageColor: {
    color: "#646464",
    fontSize: '16px',
    lineHeight: '26px',
    marginBottom: '0px'
  },
  mailId: {
    paddingLeft: "5px",
    paddingRight: "5px",
    color: "#11142D",
    fontWeight: 500
  },
  listTextDiv: {
    paddingLeft: "20px",
    color: "#646464",
    fontSize: "16px",
  },
  heading: {
    marginBottom: 13,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "31px",
    lineHeight: "120%",
    letterSpacing: "0.005em",
    color: "#11142D",
  },
  logoImage:{
    position: "absolute",
    top: 40,
    left: 0
  },
  boxImgVisual:{
    background: "#234FBD",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonOption:{
    display: "flex",
    alignItems: "center",
    paddingTop: 24,
    marginTop: 14,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)"
  }
}));

interface Props { }
const CheckYourMail: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <Grid container component="main" className={classes.root}>
      {/* <CssBaseline />
      <Hidden smDown>
        <Grid item xs={false} sm={5} md={4} className={classes.svg}>
          <SignInSVG />
        </Grid>
      </Hidden>
      <Box className={classes.logo}>
        <LogoSVG />
      </Box> */}
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        component={Paper}
        className={classes.formGrid}
        elevation={1}
        square
      >
        <div className={classes.paper}>
          <Box className={classes.logoImage}>
              <Link to="/login" >
                <LogoMashrmail />                 
            </Link>
          </Box>
          <Typography className={classes.heading}>
            Check your email
          </Typography>
          <Typography
            variant="body1"
            className={classes.messageColor}
          >
            We’ve sent a message to
            <span className={classes.mailId}>
              {localStorage.getItem("usr_check_email_id")}
            </span>
            with a link to activate your account
          </Typography>
          <Typography
            component="h1"
            variant="h5"
            gutterBottom
            className={classes.subheader}
          >
            Didn’t get an email?
          </Typography>
          <Typography
            variant="body1"
            className={classes.messageColor}
          >
            If you don’t see an email from us within a few minutes, a few things
            could have happened:
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <List dense>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listIcon}>
                    <FiberManualRecordIcon className={classes.listDot} />
                  </ListItemIcon>
                  <ListItemText>
                    <span className={classes.listText}>
                      The email is in your spam folder. (Sometimes things get
                      lost in there.)
                    </span>
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listIcon}>
                    <FiberManualRecordIcon className={classes.listDot} />
                  </ListItemIcon>
                  <ListItemText>
                    <span className={classes.listText}>
                      The email address you entered had a mistake or typo.
                      (Happens to the best of us)
                    </span>
                  </ListItemText>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listIcon}>
                    <FiberManualRecordIcon className={classes.listDot} />
                  </ListItemIcon>
                  <ListItemText>
                    <span className={classes.listText}>
                      You accidentally gave us another email address. (Usually a
                      work or personal one
                    </span>
                  </ListItemText>
                </ListItem>
                <div className={classes.listTextDiv}>
                  istead of the one you meant)
                </div>

                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listIcon}>
                    <FiberManualRecordIcon className={classes.listDot} />
                  </ListItemIcon>
                  <ListItemText>
                    <span className={classes.listText}>
                      We can’t deliver the email to this address (Usually
                      because of corporate firewalls
                    </span>
                  </ListItemText>
                </ListItem>
                <div className={classes.listTextDiv}>or filtering)</div>
              </List>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Typography variant="body1">
              <Link to="/resend-activation" className={classes.resendLink}>
                {"Re-enter your email and try again"}
              </Link>
            </Typography>
          </Box>
        </div>
      </Grid>
      <Grid item xs={false} sm={12} md={6} className={classes.boxImgVisual}>
          <ShapesHerolgt />
      </Grid>
    </Grid>
  );
};

export default CheckYourMail;
