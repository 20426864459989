import React  from "react";
import { Button} from "@material-ui/core";
import { useStyles } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../../redux/actions/styleGuideActions";
// import { AutoSave } from "../../StyleGuideAutosave";
import { getNewStyleFont } from "../../../../../../utils/Functions";

interface Props {
  data?:any
}

const AddStyleButton: React.FC<Props> = ({data}) => {
    const dispatch = useDispatch();
  const classes = useStyles();
  const state = useSelector((state: any) => state.styleGuide);
 
  const handleAddStyleClick = () => {
    let arr = state?.list_custom_fonts;
    arr?.forEach((option:any) => {
      if(option.key === data.key){        
        const newStyle = getNewStyleFont(option);    
        option.styles.push(newStyle);        
        dispatch(addField("list_custom_fonts", arr));
      }
    });
  }

  return(
    <Button                    
      component="label"                    
      variant="outlined"
      color="primary"
      className={`${classes.styleBtnUpload}`}
      onClick={handleAddStyleClick}
    >
      Add Style
    </Button>
  )
};

export default AddStyleButton;
