import Button from "@material-ui/core/Button";
// import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useStylesMeter } from "../../../../../styles/password";
// import LogoSVG, { SignUpSVG } from "../../../../assets/svgs/Svgs";
import { Box, IconButton, Slide } from "@material-ui/core";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import { LogoMashrmail, ShapesHerolgt } from '../../../../assets/svgs/Svgs';
import AxiosInstance from "../../../../../utils/AxiosInstance";
import {redirectTo} from "../../../../../utils/AuthUtility";
import {AxiosResponse} from "axios";
import zxcvbn from 'zxcvbn-typescript';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';
//import "./styles.css"; 

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  svg: {
    display: "flex",
    alignItems: "center",
    background: "#f7f7fc",
  },
  formGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    margin: "85px 32px",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth:350,
    maxWidth: 450,
    width: "100%",
    position: "relative",
    height: "100%"
  },
  logo: {
    position: "absolute",
    top: 25,
    left: 30,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  caption: {
    marginBottom: theme.spacing(1),
    fontSize: '16px',
    lineHeight: '26px',
    letterSpacing: '0.005em'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  heading: {
    marginBottom: theme.spacing(1),
    fontWeight: 600,
    fontSize: '31px',
    lineHeight: '120%',
    letterSpacing: '0.005em'
  },
  InputLabel: {
    marginTop: ".7rem",
    marginBottom: ".2rem",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "120%",
  },
  InputText: {
    marginTop: "0",
    "& .MuiOutlinedInput-root": {
      "& input:-webkit-autofill": {
        transition: 'background-color 5000s ease-in-out 0s',
        WebkitBoxShadow: '0 0 0px 1000px white inset'
      },
    },
  },
  loginTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "left",
    width: "100%",
  },
  errorPaper: {
    // marginTop: "-5px",
    marginBottom: 24,
    width: "100%",
    background: "#EB5757",
    padding: "2px 0 2px 2px",
  },
  errorContainer: {
    margin: 10,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  errorIcon: {
    color: "#fff",
    width: "26.67px",
    height: "26.67px",
  },
  errorText: {
    color: "#fff",
  },
  errorText1: {
    color: "#fff",
    fontSize: "16px",
    lineHeight: "20px",
    fontStyle: "normal",
  },
  errortextBorder: {
    marginTop: "0",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
      "& input:-webkit-autofill": {
        transition: 'background-color 5000s ease-in-out 0s',
        WebkitBoxShadow: '0 0 0px 1000px white inset'
      },
    },
  },
  eyeBtn: {
    padding: '8px'
  },
  logoImage:{
    position: "absolute",
    top: 40,
    left: 0
  },
  boxImgVisual:{
    background: "#234FBD",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonOption:{
    display: "flex",
    alignItems: "center",
    paddingTop: 24,
    marginTop: 14,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)"
  },
  securitymessage:{
	  fontSize:'13px',
	  fontWeight: 600
  },
   errorPaperDeactivated: {
    // marginTop: "-5px",
    marginBottom: 24,
    width: "100%",
    background: "#4da6ff",
    padding: "3px",
  },
  
}));

interface Props {
  history: any;
}
const validationSchema = yup.object({
  usr_password: yup.string().required("Passworrd is missing"),
  confirm_password: yup
    .string()
    .oneOf(
      [yup.ref("usr_password")],
      "confirm password must match the password"
    )
    .required("Confirm Password is missing"),
});
const SetNewPassword: React.FC<Props> = ({ history }) => {
  if (localStorage.getItem("X-Token")) history.push("/campaign");
  const classes = useStyles();
  const classes1 = useStylesMeter();
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };

  const [usrToken, setUsrToken] = useState("");
  useEffect(() => { 
	  let userToken = localStorage.getItem('userToken');
	  let token = history.location.userToken;
    if (history.location.userToken) {
      
	  setUsrToken(token);
    }else if(userToken){
		setUsrToken(userToken);
		setTokenStatus(true);
	}
	if(!token && !userToken){
		redirectTo("/login");
	}
	
	localStorage.removeItem('userToken');
  }, [history.location.userToken]);
  const onSubmit = async (data: any, { setSubmitting }: any) => {
    var FormData = require("form-data");
    var dataP = new FormData();
	var token=JSON.stringify(usrToken);
	var userTokenT = localStorage.getItem('userToken');
	if(!token){
		if(userTokenT){
			token=userTokenT;
		}
	}
    dataP.append("usr_signup_token", token);
    dataP.append("usr_password",JSON.stringify(data.usr_password));
    dataP.append("usr_cpassword",JSON.stringify(data.confirm_password));
		
	if(strength <=2 || passwordError){
		setSubmitting(false);
	}else{
		localStorage.removeItem("userToken");
		AxiosInstance.post('api/v1/users/setpassword', dataP)
		 .then((response: AxiosResponse) => {
			 //alert(response.data.code);
			if (response.data.code === '200') {
			   
				history.push("/login");
				setSubmitting(true);
			} else
			{
				setSubmitting(false);
				setError(data.message);
			}
		  }).catch(function (error: any) {
			  setError(error.message);
		  });
	}
  };
  
	const lowercaseRegex = /(?=.*[a-z])/;
	const uppercaseRegex = /(?=.*[A-Z])/;
	const numericRegex = /(?=.*[0-9])/;
	const specialRegex = /(?=.*[@:*?$%^&!+=])/;
	const [showLowercase, setShowLowercase] = useState(true);
	const [showUppercase, setShowUppercase] = useState(true);
	const [showNumeric, setShowNumeric] = useState(true);
	const [showSpecial, setShowSpecial] = useState(true);
	const [showMin12, setShowMin12] = useState(true);
	const [strength, setStrength] = useState(-1);
	//const [passwordLength, setPasswordLength] = useState(0);
	const [passwordError, setPasswordError] = useState(false);
	const [tokenStatus, setTokenStatus] = useState(false);
	const [confirmPasswordError, setConfirmPasswordError] = useState(false);
	const [passVal, setPassVal] = useState("");
	const [confirmpassVal, setConfirmpassVal] = useState("");
	
	//const strengthClass = passwordLength > 0 ? classes.visible : classes.visible;
	
	const checkForm = (e:any) => {
		if(!passVal){
			setPasswordError(true);
		}
		if(!confirmpassVal){
			setConfirmPasswordError(true);
		}
	};
  const validatePassword = (value: any, score:any ) => {
	//setPasswordLength(value.length);
	setPassVal(value);
	if(confirmpassVal.length){
	 if(value !==confirmpassVal){
		  setConfirmPasswordError(true);
	  }else{
		  setConfirmPasswordError(false);
	  }
	}
	var showLowercaseT =true;
	var showUppercaseT =true;
	var showNumericT =true;
	var showSpecialT =true;
	var showMin12T =true;
	
    if (lowercaseRegex.test(value)) {
      setShowLowercase(false);
	  showLowercaseT=false;
    }
    else if(!lowercaseRegex.test(value)) {
      setShowLowercase(true);
	  showLowercaseT=true;
    }
    if (uppercaseRegex.test(value)) {
		setShowUppercase(false);
		showUppercaseT=false;
    }
    else if(!uppercaseRegex.test(value)) {
      setShowUppercase(true);
	  showUppercaseT=true;
    }
    if (numericRegex.test(value)) {
      setShowNumeric(false);
	  showNumericT=false;
    }
    else if (!numericRegex.test(value)) {
      setShowNumeric(true);
	  showNumericT=true;
    }
    if (specialRegex.test(value)) {
		setShowSpecial(false);
		showSpecialT=false;
    }
    else if (!specialRegex.test(value)) {
		setShowSpecial(true);
		showSpecialT=true;
    }
    if (value.length >= 12) {
		setShowMin12(false);
		showMin12T=false;
    }
    else if (value.length < 12) {
      setShowMin12(true);
	  showMin12T=true;
    }
	
	if(score>=2){
		
		if(showLowercaseT === false && showUppercaseT === false && showNumericT === false && showSpecialT === false && showMin12T === false){
			setStrength(score);
		}else{
			setStrength(2);
		}
	}else{
		if(!value.length){
			setStrength(-1);
		}else{
			setStrength(score);
		}
	}
	if(showLowercaseT === true || showUppercaseT === true || showNumericT === true || showSpecialT === true || showMin12T === true){
		setPasswordError(true);
		
	}else{
		setPasswordError(false);
	}
	if(!value.length){
		setPasswordError(false);
	}
  }
  
  const showError = (errors: any) => {
    if (
      Object.keys(errors).includes("usr_password") &&
      Object.keys(errors).includes("confirm_password") &&
      errors['usr_password'].includes("is missing") &&
      errors['confirm_password'].includes("is missing")
    ) {
      return (
        <Typography
          component="h1"
          variant="caption"
          className={classes.errorText}
        >
          Password and Confirmed password is missing
        </Typography>
      );
    }

    return (Object.keys(errors) as Array<keyof typeof errors>).map((item) => {
      return (
        <Typography
          component="h1"
          variant="caption"
          className={classes.errorText}
        >
          {errors[item]}
        </Typography>
      );
    });
  };
  function validateZXCVBN(value: any) {
	var score =zxcvbn(value).score;
	validatePassword(value,score);
	//setStrength(score);
  }
  function handleChangeConfirm(value: any) {
	  setConfirmpassVal(value);
	  if(value !==passVal){
		  setConfirmPasswordError(true);
	  }else{
		  setConfirmPasswordError(false);
	  }
  }

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Grid container component="main" className={classes.root}>
        {/* <CssBaseline />
        <Hidden smDown>
          <Grid item xs={false} sm={5} md={4} className={classes.svg} style={{
              paddingLeft: "30px",
              paddingRight: "50px",
            }}>
            <SignUpSVG />
          </Grid>
        </Hidden>
        <Box className={classes.logo}>
          <Link to="/">
            <LogoSVG />
          </Link>
        </Box> */}
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          component={Paper}
          className={classes.formGrid}
          elevation={4}
          square
        >
          <div className={classes.paper}>
           <Box className={classes.logoImage}>
               <Link to="/login" >
                  <LogoMashrmail />                 
              </Link>
            </Box>
            <Box>
              <Typography
                component="h1"
                align="left"
                className={classes.heading}
                variant="h5"
              ></Typography>
            </Box>
            <Box className={classes.loginTextContainer}>
              <Typography
                component="h1"
                variant="h5"
                className={classes.heading}
              >
                Create a New Password
              </Typography>
              
            </Box>
            <Formik
              initialValues={{
                usr_password: "",
                confirm_password: "",
              }}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={onSubmit}
            >
              {({
                values,
                isSubmitting,
                errors,
                handleChange,
                handleBlur,
                touched,
              }) => (
                <Form className={classes.form}>
                  {(Object.keys(errors).length > 0 || error) && (
                    <Paper className={classes.errorPaper}>
                      <Box className={classes.errorContainer}>
                        <NotInterestedIcon className={classes.errorIcon} />
                        <Box mx={1.5}>
                          {Object.keys(errors).length > 0 ? (
                            showError(errors)
                          ) : (
                            <Box mx={1.5}>
                              <Typography
                                component="h1"
                                variant="caption"
                                className={classes.errorText}
                              >
                                {error}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Paper>
                  )}

                  {/* <Typography
                    variant="subtitle2"
                    className={classes.InputLabel}
                  >
                    Password
                  </Typography> */}
				  {(tokenStatus === true) && (
				    <Paper className={classes.errorPaperDeactivated}>
                      <Box className={classes.errorContainer}>
                        <ErrorTwoToneIcon className={classes.errorIcon} />
                        <Box display="flex" flexDirection="column" mx={2}>
                        <Typography
                        component="h1"
                        variant="caption"
                        className={classes.errorText}
                          >
                         Due to our recently increased security measures, we require that you set a new password which meets our minimum password requirements
                          </Typography>
                        </Box>
                      </Box>
                    </Paper>
				  )}
				  <List dense>
                    <Grid container>
					<Grid item xs={12} sm={12}>
                        <ListItem className={classes1.listItem}>
                          <ListItemIcon className={classes1.listIcon}>
                            {showMin12 === false ? (
                              <FiberManualRecordIcon
                                className={classes1.listDotFaded}
                              />
                            ) : (
                              <FiberManualRecordIcon
                                className={classes1.listDot}
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText className={classes1.listText}>
                            {showMin12 === false ? (
                              <span className={classes1.listTextColor}>
                               Minimum of 12 characters long
                              </span>
                            ) : (
                              <span>Minimum of 12 characters long</span>
                            )}
                          </ListItemText>
                        </ListItem>
                      </Grid>
					  <Grid item xs={12} sm={12}>
                        <ListItem className={classes1.listItem}>
                          <ListItemIcon className={classes1.listIcon}>
                            {showUppercase === false ? (
                              <FiberManualRecordIcon
                                className={classes1.listDotFaded}
                              />
                            ) : (
                              <FiberManualRecordIcon
                                className={classes1.listDot}
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText className={classes1.listText}>
                            {showUppercase === false ? (
                              <span className={classes1.listTextColor}>
                                Include one uppercase letter (ABC)
                              </span>
                            ) : (
                              <span>Include one uppercase letter (ABC)</span>
                            )}
                          </ListItemText>
                        </ListItem>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <ListItem className={classes1.listItem}>
                          <ListItemIcon className={classes1.listIcon}>
                            {showLowercase === false ? (
                              <FiberManualRecordIcon
                                className={classes1.listDotFaded}
                              />
                            ) : (
                              <FiberManualRecordIcon
                                className={classes1.listDot}
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText className={classes1.listText}>
                            {showLowercase === false ? (
                              <span className={classes1.listTextColor}>
                                Include one lowercase letter (xyz)
                              </span>
                            ) : (
                              <span>Include one lowercase letter (xyz)</span>
                            )}
                          </ListItemText>
                        </ListItem>
                      </Grid>
					  <Grid item xs={12} sm={12}>
                        <ListItem className={classes1.listItem}>
                          <ListItemIcon className={classes1.listIcon}>
                            {showNumeric === false ? (
                              <FiberManualRecordIcon
                                className={classes1.listDotFaded}
                              />
                            ) : (
                              <FiberManualRecordIcon
                                className={classes1.listDot}
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText className={classes1.listText}>
                            {showNumeric === false ? (
                              <span className={classes1.listTextColor}>
                                Include one number (123)
                              </span>
                            ) : (
                              <span>Include one number (123)</span>
                            )}
                          </ListItemText>
                        </ListItem>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <ListItem className={classes1.listItem}>
                          <ListItemIcon className={classes1.listIcon}>
                            {showSpecial === false ? (
                              <FiberManualRecordIcon
                                className={classes1.listDotFaded}
                              />
                            ) : (
                              <FiberManualRecordIcon
                                className={classes1.listDot}
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText className={classes1.listText}>
                            {showSpecial === false ? (
                              <span className={classes1.listTextColor}>
                                Include one special symbol (:@$!%*?&)
                              </span>
                            ) : (
                              <span>Include one special symbol (:@$!%*?&)</span>
                            )}
                          </ListItemText>
                        </ListItem>
                      </Grid>
                      
                    </Grid>
                  </List>
				 
                  <TextField
                    label="Password"
                    variant="outlined"
                    autoComplete="off"
					error={passwordError}
                    // margin="dense"
                     onChange={(e: any) => {
                      handleChange(e);
					  validateZXCVBN(e.currentTarget.value)
                    }}
                    onBlur={handleBlur}
                   style={{
					  marginTop:10,
					}}
                    className={
                      Boolean(
                        (errors.usr_password && touched.usr_password) || error
                      )
                        ? classes.InputText
                        : classes.InputText
                    }
					inputProps={{
						maxLength: 100,
					}}
                    InputProps={{
					  endAdornment: (
                        <IconButton onClick={handleClick} edge="end" className={classes.eyeBtn}>
                          {showPassword ? (
                            <VisibilityOutlinedIcon
                              style={{ color: "#DADADA" }}
                            />
                          ) : (
                            <VisibilityOffOutlinedIcon
                              style={{ color: "#DADADA" }}
                            />
                          )}
                        </IconButton>
                      ),
                    }}
                    fullWidth
                    name="usr_password"
                    type={showPassword ? "text" : "password"}
                  />
                  {/* <Typography
                    variant="subtitle2"
                    className={classes.InputLabel}
                  >
                    Confirm Password
                  </Typography> */}
				   <div className={classes1.strengthmeter+' mt-2'}>
                        <div className={classes1.strengthmeterfill} data-strength={strength}></div>
                  </div>
                  <Field
                    label="Confirm Password"
                    name="confirm_password"
					autoComplete="off"
					inputProps={{ maxLength: 100 }}
                    as={TextField}
                    variant="outlined"
					style={{
					  marginTop:20,
					}}
					error={confirmPasswordError}
					onChange={(e: any) => {
					  handleChange(e);
                      handleChangeConfirm(e.currentTarget.value);
                    }}
					onBlur={handleBlur}
                    // margin="dense"
                    fullWidth
                    className={
                      Boolean(
                        (errors.confirm_password && touched.confirm_password) ||
                        error
                      )
                        ? classes.InputText
                        : classes.InputText
                    }
					type={"password"}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isSubmitting}
                    color="primary"
                    className={classes.submit}
					onClick={checkForm}
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
        <Grid item xs={false} sm={12} md={6} className={classes.boxImgVisual}>
          <ShapesHerolgt />
        </Grid>
      </Grid>
    </Slide>
  );
};

export default SetNewPassword;
