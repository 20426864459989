import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { useStyles } from "../styles";
import TrackingAttributesFields from "../utilities/TrackingAttributesFields";
interface Props {}
const TrackingAttributes: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" className={classes.section}>
      <Typography className={classes.heading} variant="h6">
      Attributes
      </Typography>
      <Typography variant="caption" className={classes.caption}> 
        These are added to the code of your link. These are commonly used for adding specific platform tracking      
      </Typography>
      <TrackingAttributesFields />
    </Box>
  );
};

export default TrackingAttributes;
