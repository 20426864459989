import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import UsersList from "./UsersList";
import Paper from "@material-ui/core/Paper";
import { useEffect, useState } from "react";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";

const useStyles = makeStyles({
  container: { 
      // backgroundColor: "#f8f8f8",
      //height: 'calc(100vh - 250px)',
      //overflow:'hidden',
      //overflowY:'scroll',
      boxShadow:"unset",
      padding:12
  },
});
interface Props {
  history?: any;
}
const UsersTab: React.FC<Props> = ({ history }) => {
  const classes = useStyles(); 
  const [userData, setUserData] = useState<any>({ usertable: [], pending: [] });
  const URL = window.location.pathname.split('/').pop();
  const loadUserData = () => {
  	AxiosInstance.get('api/v1/Settings/user')
      .then((response: AxiosResponse) => {
		  setUserData(response.data.data)
      });
  };
  useEffect(() => {
    loadUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
	if(URL ==='users'){
		loadUserData();
		//console.log(loggedInUser.teams_ids);
	}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [URL]);
  return (
    <Box>
      <Paper className={classes.container}>
        <UsersList reloadLists={loadUserData} data={userData.usertable} />
        
      </Paper>
    </Box>
  );
};

export default UsersTab;
