import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        "& .MuiPaper-root":{
          width:"100%"
        }
    },  
    topHeading: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "38px",
        lineHeight: "39px",
        marginBottom: 20,
        color: "#11142D",
    },
    caption: {
        marginBottom: "25px",
        fontSize: "14px",
        color: "#646464",
        lineHeight: "18px",
    },
	captioncheckbox: {
		marginBottom: "10px",
        color: "#646464",
        lineHeight: "18px",
    },
	checkboxLabel: {
		fontSize: "14px",
    },
    container: {
        width: "100%",
        height: "100%",
        border: "1px solid #D3D3D3",
        borderRadius: "14px",
    },
    containerTeamList: { 
        padding:"unset"
    },
    leftBorder: {
        minWidth: 507,
        flex: "unset",
        flexGrow: "unset",
        width: "100%",
        maxWidth: "unset",
        flexBasis: "unset",
    },
    componentLeftContainer: {
        display: "flex",
        overflow: "auto",
        maxHeight: "70vh",
        flexDirection: "column",
        paddingTop: "30px",
        marginTop: "10px",
        marginLeft: "40px",
        paddingRight: 48
    },
    section: {
        marginBottom: "2rem",
    },
    heading: {
        marginBottom: theme.spacing(1),
        color: "#2F2F2F",
        fontSize: "18px",
        lineHeight: "23px",
        fontStyle: "normal",
        // fontWeight: "bold",
    },
    skeletonGrid2:{
        paddingLeft: "unset"
    },
    skeletonGrid3:{
        paddingLeft: "unset"
    
    },
    skeletonGrid4:{
        paddingLeft: "unset"
    
    },
    skeletonGrid5:{
        paddingLeft: "unset"
    
    },
    skeletonGrid6:{
        paddingLeft: "unset"
    },
    listColorPallet:{
        paddingRight:4
    },
    toolbar: {
        backgroundColor: "white",
        paddingLeft: 5,
        minHeight: "unset",
        paddingBottom: 17,
        paddingRight: 0,
    },
    titleh6: {
        fontSize: 18,
    },
    buttonStyle: {
        textTransform: "none",
        fontWeight: 600,
        boxShadow: "none",
        "&:hover": {
        background: "#0a6adc",
        boxShadow: "none",
        },
    },  
    hasTrialInfo:{

    },
    table: {
        minWidth: 650,
    },
    tableContainer:{
        margin: 12,
        marginBottom: 20,
        width: "auto",    
        maxHeight: "calc(100vh - 195px)",
        overflow: "hidden",
        overflowY: "scroll",
        "&$hasTrialInfo":{
        maxHeight: "calc(100vh - 251px)",
        }
    },
    tableTbodyRow:{
        borderBottom: "1px solid #E1E1E1",
        "& > td" : {
        padding:"10px 0 !important"
        },
        "& > td:first-child" : {
        paddingLeft: "20px !important"
        }
    },
    styleLogo:{
        width: 32, 
        height: "32px", 
        margin:"5px 3px 5px 3px",
        float:"left",
        display:"flex",
        alignItems:"center",
        overflow: "hidden",
        borderRadius: "50%",
        border: "1px solid #cdcdcd",
        padding: 2,
        boxSizing: "border-box",
        "& img":{
          width: "100%",
          height: "100%",
          objectFit: "contain"
        }
      },
    tableThead:{
        backgroundColor:"#F3F3F3",
        borderRadius:3,
        "& th":{
        fontSize:10,
        fontWeight:"600",
        lineHeight:"unset",
        padding: "12px 0 10px 0"
        },
        "& th:first-child":{
        paddingLeft:20
        }
    },
    defaultSelectText:{
        backgroundColor: "#e5e5e5",
        paddingTop: 2,
        paddingRight: 8,
        paddingBottom: 2,
        paddingLeft: 8,
        marginLeft: 5,
        borderRadius: 10
    },
    membersList:{
        display: "flex",
        justifyContent: "flex-start",
        flexFlow: "wrap"
    },
    avatorIconName:{        
        width: "32px",
        height: "32px",
        fontSize: "14px",
        marginRight: 3,
        marginTop: 1,
        marginBottom: 1,
        fontWeight: 600
    },
    menuButton: { 
        cursor: "pointer" 
    },
    deleteOption: {
        color: "red",
    },  
    headerInfo:{
        display: "flex",
        padding: "19px 20px 19px 31px",
        alignItems: "center",
        borderBottom: "1px solid #c4c4c4",
        fontFamily: 'Mulish'
    },
    headerInfoFirst:{
    flex:"1",
    display: "flex",
    alignItems: "center",
    },
    headerInfoSecond:{
    },
    classFlsMsgAlert:{
    marginTop:0,
    marginRight:17,
    },
    inputTeamName:{
    marginLeft: 16,
    "& .MuiOutlinedInput-input":{
        paddingLeft:0,
        paddingRight:0,
        borderBottom: "1px solid #c4c4c4",
        width: 300,
    },
    "& .MuiOutlinedInput-notchedOutline":{
        border:"unset"
    }
    },
    multipleCheckBoxWrapper:{
        display:"flex",
    },
    multipleFoldersWrapper:{
        display:"flex",
        marginTop: 15,
    },
    multipleCheckBox:{
        marginRight: 35
    },
    teamFormControl:{
        width:260
    },
    teamFormSelect:{
        width:260
    },
    teamDropControl:{
        width:160
    },
    teamDropSelect:{
        width:160
    },
    closeButtonStyle:{
        marginRight:15
    },
	folderTitleName:{      
    color: "#2F2F2F",
    fontSize: "16px",
    lineHeight: "23px",
  },
	boxFolderTitleName:{
    marginBottom:3
  },
  disableClass:{
    opacity:0.6,
	pointerEvents: 'none'
  },
      
}));
