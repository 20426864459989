import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { useEffect, useState } from "react";
import {AxiosResponse} from "axios";
import StyleGideList from '../../onboarding/styleguide/StyleGideList';
import AxiosInstance from '../../../../utils/AxiosInstance'; 
import { useDispatch, useSelector } from 'react-redux';
import { addField } from '../../../redux/actions/styleGuideActions';
import StyleGuide from '../../onboarding/styleguide/StyleGuidePage';

const useStyles = makeStyles({
  containerStyleGideList: { 
      // backgroundColor: "#f8f8f8",
      // height: 'calc(100vh - 95px)',
      // overflow:'hidden',
      // overflowY:'scroll',
      // padding:12,
      maxWidth: 1236,
      flex: "1"
  },
  hasTrialInfo:{

  },
  rootStyleGideList:{
    paddingTop:86,
    justifyContent: "center",
    display: "flex",
    paddingBottom: 30,
    backgroundColor:"#f8f8f8",
    "&$hasTrialInfo":{
      paddingTop: 143,
    }
  },
  containerStyleGuideDetails:{
    maxWidth: 1236,
    flex: "1",
    borderRadius:4
  }
});
interface Props {
  history?: any;
}
const DesignSystems: React.FC<Props> = ({ history }) => {
      const URL = window.location.pathname.split('/').pop();
  const classes = useStyles(); 
  const [userData, setUserData] = useState<any>({ usertable: [], pending: [] });

  const dispatch = useDispatch(); 
  const reduxState = useSelector((state: any) => state?.styleGuide);
  const [listLoaded, setListLoaded] = useState<boolean>(false);
  
  // console.log("reduxState",reduxState)

  // const [showList, setShowList] = useState<any>(false);
  // const [showDetails, setShowDetails] = useState<any>(false);

  const googleFontListFun = () => {
       var FormData = require("form-data");
    var frmdata = new FormData();
    AxiosInstance.post('api/getGoogleFontsList', frmdata)
     .then((response: AxiosResponse) => {
         if(response.data.success)
         {
          if(reduxState?.googleFontList === undefined || reduxState?.googleFontList.length === 0) {
           dispatch(addField("googleFontList", JSON.parse(response.data.googleFontList)));
         }
                
     }
        
      })
  };
  
  const loadStyleGuideData = () => {
	setListLoaded(false);
  	AxiosInstance.get(`api/getStyleguide/${localStorage.getItem("company_id")}`)
      .then((response: AxiosResponse) => {
        var data = response.data;
        data?.usertable?.forEach((item: any)=>{
          if(isJsonString(item.main_color_pallet)){
            item.main_color_pallet =  JSON.parse(item.main_color_pallet);  
          }
        })
		    setListLoaded(true);
			setUserData(data);
      });
  };
  const isJsonString = (str: any) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
     return true; 
    }

  useEffect(() => {
    if(reduxState?.designSystem === "showList" ) loadStyleGuideData(); googleFontListFun();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxState?.designSystem]);
   useEffect(() => {
    if(URL ==='designSystems')
     {       
         loadStyleGuideData();
         googleFontListFun();
         reduxState["designSystem"] = "showList";
         dispatch(addField("designSystem", reduxState["designSystem"]));
     }
     // eslint-disable-next-line
  }, [URL]);
  return (
    <Box className={`${classes.rootStyleGideList} ${localStorage.getItem("trial_mode") === 'true' ? classes.hasTrialInfo: ""}`}>               
        { reduxState?.designSystem !== undefined &&  reduxState?.designSystem === "showList" ? (
          <Paper className={classes.containerStyleGideList}>
          <StyleGideList history={history} reloadLists={loadStyleGuideData} data={userData.usertable} listLoaded={listLoaded} setListLoaded={setListLoaded}/>
        </Paper>
        ) : ""}

        { reduxState?.designSystem !== undefined &&  reduxState?.designSystem === "showDetails" ? (
          <Paper square className={classes.containerStyleGuideDetails}>            
            <StyleGuide isNotSettingsPage={false}/>
          </Paper>
        ):""
          
        }
      
    </Box>
  );
};

export default DesignSystems;
