

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  treeViewDiv1: {
    display: "flex",
    alignItems: "center",
    paddingTop: 6,
    paddingBottom: 8,
    paddingLeft: theme.spacing(0.5),
    borderRadius: '5px',
    "& $tickIcon": {
      color: "#73a8e2",
      display: "block",
      marginRight: theme.spacing(2),
    },
    "& $treeViewFolder": {
      color: "#057cff",
    },
    "&:hover": {
      "& $moreVertIcon": {
        display: "block",
      },
    },
  },
  treeViewDiv2: {
    display: "flex",
    alignItems: "center",
    paddingTop:6,
    paddingBottom:8,
    paddingLeft: theme.spacing(0.5),
    "&:hover": {
      "& $moreVertIcon": {
        display: "block",
      },
    },
  },

  treeViewFolder: {
    color: "#e0e0e0",
    display:"flex"
  },
  treeViewFolder2: {
    color: "#e0e0e0",
    opacity: '.5',
    display:"flex"
  },
  treeViewItem: {
    borderRadius: "5px",
  },
  divRoot1: {
    "& > .MuiTreeItem-content": {
      backgroundColor: '#DBECFF',
      borderRadius: "5px",
    },

    "&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: 'transparent',
      "&:hover": {
        backgroundColor: 'transparent',
      }
    },
    "&:focus > .MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: 'transparent',
    }
  },
  divRoot2: {
    "&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: 'transparent',
      "&:hover": {
        backgroundColor: 'transparent',
      }
    },
    "&:focus > .MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: 'transparent',
    }
  },
  treeContainer: {
    "& .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label": {
      backgroundColor: 'transparent',
    }
  },
  moreVertIcon: {
    display: "none",
    "&:active": {
      outline: "0",
      boxShadow: "inset 0 3px 5px rgb(0 0 0 / 13%)",
    },
    marginTop: 6
  },
  label: {
    fontWeight: "inherit",
    color: "inherit",
    borderRadius: theme.spacing(0.5),
    // marginTop: ".9rem",
    "&:hover": {
      backgroundColor: 'transparent'
    }
  },
  tickIcon: {
    display: "none",
  },
  fileTree: {
    border: "1px solid #E1E1E1",
  },
  searchComponent: {
    marginRight: ".5rem",
  },
  
  filetreeHeader: {
    padding: ".5rem",
    borderBottom: "1px solid #E1E1E1",
  },
  filetreeInnerHeader: {
    display: "flex",
    verticalAlign: "middle",
    cursor: "pointer",
    padding: ".25rem",
    color: "#238cff",
    "&:hover": {
      background: "#DBECFF",
      borderRadius: "4px",
    },
  },
  filetreeName: {
    paddingLeft: ".5rem",
  },
  filetreeTypography: {
    paddingLeft: "1rem",
    overflow: "hidden",
    fontSize: '14px',
    lineHeight: '22px',
    wordBreak: "break-all",
    height: 22,
  },
  filetreeTypography2: {
    paddingLeft: "1rem",
    overflow: "hidden",
    opacity: '.5',
    fontSize: '14px',
    lineHeight: '22px',
    wordBreak: "break-all",
    height: 22,
  },
  deleteItem: {
    color: "red",
  },
  fileEdit: {
    width: "70%",
  },
  hasTrialInfo:{
  },
   PrimarybuttonStyle: {
    textTransform: "none",
    width:"98%",
    marginBottom:"12px",
    fontWeight: 500,
    boxShadow: "none",
    "&:hover": {
      background: "#0a6adc",
      boxShadow: "none",
    },
  },
  treeHeight: {
    maxHeight: "calc(100vh - 370px)",
    overflowY: "scroll",
    marginBottom: "1rem",
    "&$hasTrialInfo":{
      maxHeight: "calc(100vh - 426px)",
    }
  },
  folderListItemCol:{
    marginRight: 35,
    width:260,
    "& $treeHeight":{
      padding:"unset",
      minHeight: 200
    }
  },
  folderTitleName:{      
    color: "#2F2F2F",
    fontSize: "16px",
    lineHeight: "23px",
  },
  boxFolderTitleName:{
    marginBottom:3
  },
  content: {
    flexDirection: 'row-reverse',
    justifyContent: "flex-end",
    "&:hover": {
      backgroundColor: 'rgba(196, 196, 196, 0.3)',
      borderRadius: '5px',
      "& $moreVertIcon": {
        display: "block",
        position: 'absolute',
        right: "-2.5rem",
        top: 0,
        cursor: 'pointer',
      },
    },
  },
  content2: {
    flexDirection: 'row-reverse',
    justifyContent: "flex-end",
    backgroundColor: 'rgba(196, 196, 196, 0.3);',
    borderRadius: '5px',
    animation: `$pulse-animation 1s linear infinite`,
    "&:hover": {
      borderRadius: '5px',
      "& $moreVertIcon": {
        display: "block",
        position: 'absolute',
        right: "-2.5rem",
        top: 0,
        cursor: 'pointer',
      },
    },
  },
  "@keyframes pulse-animation": {
    "0%": { boxShadow: '0 0 0 0 rgb(196, 196, 196, .4) , 0 0 0 0 rgba(196, 196, 196, .4)' },
    "40%": { boxShadow: '0 0 0 10px rgba(196, 196, 196, 0), 0 0 0 0 rgba(196, 196, 196, 0.4)' },
    "80%": { boxShadow: '0 0 0 10px rgba(196, 196, 196, 0), 0 0 0 0 rgba(196, 196, 196, 0)' },
    "100%": { boxShadow: '0 0 0 0 rgba(196, 196, 196, 0), 0 0 0 5px rgba(196, 196, 196, 0)' },
  },
  iconContainer: {
    // "& .MuiSvgIcon-root": {
    marginRight: '1.5rem',
    // },
  },
  paper: {
    width: '120px'
  },
  disabledList: { 
    pointerEvents:"none",
    opacity:0.4
	},
}));

