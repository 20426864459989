import React from "react";
// import { Box, MenuItem, TextField } from "@material-ui/core";
// import { useStyles } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../redux/actions/styleGuideActions";
// import {AutoSave} from "../StyleGuideAutosave";
import { DefaultStyleGuide } from '../utilities/DefaultStyleGuide';
//import { toast } from "react-toastify";
// import FontSizeLineHeight from './Typography/FontSizeLineHeight';
//import {useState} from 'react';
import { useEffect } from "react";
// import CustomFontSelection from "./Typography/CustomFontSelection";
import EditorStyle from "./Typography/EditorStyle";

interface Props {}
const TypographyHeadline: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.styleGuide);
  // const classes = useStyles();
  //const [addFallBack, SetAddFallBack] = useState(false);
   //const styleguideName = useSelector((state: any) => state.styleGuide.styleguideName);
  if(!state["headline_size"] && state["headline_size"] !== undefined){
    dispatch(addField("headline_size", DefaultStyleGuide["headline_size"]));
    dispatch(addField("styleGuideDefault", true));
  }

  if(!state["primary_font"] && state["primary_font"] !== undefined){
    dispatch(addField("primary_font", DefaultStyleGuide["primary_font"]));
    dispatch(addField("styleGuideDefault", true));
  }
  
useEffect(() => {  
  if (state.fallback_font?.headline) { 
      //SetAddFallBack(true);
  }
  
}, [state.fallback_font?.headline,state.fontsList]);



  return (
    <>
    {/* <Box className={classes.section3}>
      {(state?.custom_font_visible === true) && (<Box display="flex" className={classes.styleList2} >
        <CustomFontSelection title="Headline" fieldName="headline_custom_font" />
      </Box>)}
      <Box display="flex" className={classes.styleList1} >
        <Box className={`${classes.flexColumn1} ${classes.TitleDescription3}`}>         
          Headline{(state?.custom_font_visible) ? " Fallback" : ""} Font:
        </Box>  
        <Box flex="1">
          <Box >    
            <TextField
              select
              size="small"
              // className={classes.fontFallbackFamilyDrop}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              }}
              placeholder="Select your primary font"
              value={state["primary_font"] || "none"}
              fullWidth
              onChange={(event) => {
                dispatch(addField("primary_font", event.target.value));
                state?.fontsList?.forEach((option:any) => {
                      if(option.name === event.target.value){  
                        dispatch(addField("primary_font_full", option.family));
                      }
                  });
                // dispatch(addField("primary_font_full",  event.target.value));
                 AutoSave();
              }}
            >
              {!state["primary_font"] && (
                <MenuItem value="none" disabled style={{ color: "#545454" }}>
                  Select your primary font
                </MenuItem>
              )}
              {state.fontsList && state.fontsList.map((option:any) => (
                <MenuItem key={option.name} value={option.name}>
                <span style={{ fontFamily: option.family }}> {option.name === "Trebuchet MS" ? "Trebuchet" : option.name}</span>
                </MenuItem>
              ))}
            </TextField>
            {/* {!addFallBack ? (
                <Box onClick={()=>SetAddFallBack(true)} className={classes.fallbackText}>
                  Add fallback
                </Box>
            ) : (  
              <TextField
                select
                className={classes.fontFallbackFamilyDrop}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  },
                }}
                placeholder="Select your fallback font"
                size="small"
                value={state.fallback_font?.headline || "none"}
                fullWidth
                onChange={(e)=>{               
                  setFallBack({...state.fallback_font, headline: e.target.value}); 
                }}
              >
                {!state.fallback_font?.headline && (
                  <MenuItem value="none" disabled style={{ color: "#545454" }}>
                    Select your fallback font
                  </MenuItem>
                )}
                {state.fontsList && state.fontsList.map((option:any) => (
                  <MenuItem key={option.name} value={option.name}>
                  <span style={{ fontFamily: option.name }}> {option.name}</span>
                  </MenuItem>
                ))}
              </TextField> 
            )}
          </Box>
        </Box>
      </Box>
    </Box> */}
    <EditorStyle name="headline" code="h1" />
    <EditorStyle name="headline" code="h2" />
    <EditorStyle name="headline" code="h3" />
    <EditorStyle name="headline" code="h4" />
    {/* <FontSizeLineHeight title="Headline 1(H1):" fieldNameFontSize="headline_size_h1"  fieldNameLineHeight="headline_lineheight_h1" fieldNameSpaceAfter="headline_spaceafter_h1" fieldNameBold="headline_weight_h1" />
    <FontSizeLineHeight title="Headline 2(H2):" fieldNameFontSize="headline_size_h2"  fieldNameLineHeight="headline_lineheight_h2" fieldNameSpaceAfter="headline_spaceafter_h2" fieldNameBold="headline_weight_h2" />
    <FontSizeLineHeight title="Headline 3(H3):" fieldNameFontSize="headline_size_h3"  fieldNameLineHeight="headline_lineheight_h3" fieldNameSpaceAfter="headline_spaceafter_h3" fieldNameBold="headline_weight_h3" />
    <FontSizeLineHeight title="Headline 4(H4):" fieldNameFontSize="headline_size_h4"  fieldNameLineHeight="headline_lineheight_h4" fieldNameSpaceAfter="headline_spaceafter_h4" fieldNameBold="headline_weight_h4" /> */}
    </>
  );
};

export default TypographyHeadline;

/*const fonts = [
  { title: "Arial" },
  { title: "Verdana" },
  { title: "Helvetica" },
  { title: "Trebuchet MS" },
  { title: "Times New Roman" },
  { title: "Georgia" },
  { title: "Garamond" },
  { title: "Courier New" },
  { title: "Brush Script MT" },
];*/