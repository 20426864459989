import React, { useEffect } from "react";
import { useStyles } from "./style";
import Box from "@material-ui/core/Box";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import { CircularProgress } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';

import {
  Button,
  TextField,
  Typography,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";

interface Props {
  history?: any;
  onTextBoxSelect?: any;
  isIframe?: boolean;
  handleClose: any;
  mailjetsenderlist: any;
  mailjetcontactlist: any;
  FrontEndEspId:any;
  FrontEndcname:any;
  FrontendversionId:any;
  FrontEndSubject:any;
}

const MailjetExport: React.FC<Props> = ({
  history,
  onTextBoxSelect,
  isIframe,
  handleClose,
  mailjetsenderlist,
  mailjetcontactlist,
  FrontEndEspId,
  FrontEndcname,
  FrontEndSubject,
  FrontendversionId,
}) => {
  const classes = useStyles();
  const [Campaigntitle, setCampaigntitle] = React.useState<string>("");
  const [Sendername, setSendername] = React.useState<string>("");
  const [Senderaddress, setSenderaddress] = React.useState<string>("");
  const [Subjectline, setSubjectline] = React.useState<string>("");
  const [Contactlist, setContactlist] = React.useState<string>("");
  const [errorFirst, seterrorFirst] = React.useState(false);
  const [errorSecond, seterrorSecond] = React.useState(false);
  const [errorThird, seterrorThird] = React.useState(false);
  const [errorFourth, seterrorFourth] = React.useState(false);
  const [errorFifth, seterrorFifth] = React.useState(false);
  const [helperTextFirst, sethelperTextFirst] = React.useState<string>("");
  const [helperTextSecond, sethelperTextSecond] = React.useState<string>("");
  const [helperTextThird, sethelperTextThird] = React.useState<string>("");
  const [helperTextFourth, sethelperTextFourth] = React.useState<string>("");
  const [helperTextFifth, sethelperTextFifth] = React.useState<string>("");
  const [mailjetsenderlistFrame, setmailjetsenderlistFrame] = React.useState<any>([]);
  const [mailjetcontactlistFrame, setmailjetcontactlistFrame] = React.useState<any>([]);
  const [isProcess, setisProcess] = React.useState(false);
  const [IframeEspId, setIframeEspId] = React.useState<string>("");
  const [versionId, setversionId] = React.useState<string>("");
  const [mailjetDraftCampaignlistFrame, setmailjetDraftCampaignlistFrame] = React.useState<any>([]);
  const [campaignMode, setcampaignMode] = React.useState<string>("create");
  const [Campaigntitlevalue, setCampaigntitlevalue] = React.useState<string>(FrontEndcname);
  const [Sendernamevalue, setSendernamevalue] = React.useState<string>("");
  const [Subjectvalue, setSubjectvalue] = React.useState<string>(FrontEndSubject);
  const [SenderAddressvalue, setSenderAddressvalue] = React.useState<string>("");
  const [ContactListvalue, setContactListvalue] = React.useState<string>("");
  const [DraftCampaignIdvalue, setDraftCampaignIdvalue] = React.useState<string>("");
  const [updateDropdowndisable, setupdateDropdowndisable] = React.useState(true);
  const [isdisplay, setisdisplay] = React.useState(true);

    const [value, setValue] = React.useState('1');

  const handleChange = (event:any, newValue:any) => {
      if(newValue === '1')
      {
          CreateDraftCampaign();
      } else {
          UpdateDraftcampaign();
      }
    setValue(newValue);
  };
   function getParam(param : any){
        return new URLSearchParams(window.location.search).get(param);
    }
   

  isIframe = isIframe ? isIframe : false;
  
   const CreateDraftCampaign = () => {
    setcampaignMode('create');
    //event.preventDefault();
   
  };
  const UpdateDraftcampaign = () => {
    setcampaignMode('update');
    
    var sDraftFormData = require("form-data");
            var sDraftdata = new sDraftFormData();
            if(isIframe === true)
                    {
                      sDraftdata.append("espId",IframeEspId);
                    } else {
                        sDraftdata.append("espId",FrontEndEspId);
                    }
            
            AxiosInstance.post("api/v1/Integration/getMailjetDraftcampaignList", sDraftdata).then(
            (response: AxiosResponse) => {
                if(response.data.data)
                {
                    if(response.data.data.draftcampaignlist.length>0)
                    {
                        setmailjetDraftCampaignlistFrame(response.data.data.draftcampaignlist);
                        setupdateDropdowndisable(false);
                        setisdisplay(false);

                    }
                }
            }
            );
      
            
   // event.preventDefault();
    
  };
  
   const closeContent = () => {
    onTextBoxSelect("close");
  };
  
const setCampaigntitlefun = (event: any) => {
    setCampaigntitle(event.currentTarget.value);
    setCampaigntitlevalue(event.currentTarget.value);
    seterrorFirst(false);
    sethelperTextFirst("");
  };
  const setSendernamefun = (event: any) => {
    setSendername(event.currentTarget.value);
    setSendernamevalue(event.currentTarget.value);
    seterrorSecond(false);
    sethelperTextSecond("");
   
  };
   const setSenderaddressfun = (event: any) => {
    setSenderaddress(event.target.value);
    //SenderAddressvalue(event.target.value);
    seterrorThird(false);
    sethelperTextThird("");
   
  };
   const setSubjectlinefun = (event: any) => {
    setSubjectline(event.currentTarget.value);
    setSubjectvalue(event.currentTarget.value);
    seterrorFourth(false);
    sethelperTextFourth("");
   
  };
   const setContactlistfun = (event: any) => {
    setContactlist(event.target.value);
    seterrorFifth(false);
    sethelperTextFifth("");
   
  };
  const ExportHtml = () => {
      
    if (Campaigntitle === "") {
      seterrorFirst(true);
      sethelperTextFirst("Required field");
    } else if (Sendername === "") {
      seterrorSecond(true);
      sethelperTextSecond("Required field");
    } else if (Senderaddress === "") {
      seterrorThird(true);
      sethelperTextThird("Required field");
    } else if (Subjectline === "") {
      seterrorFourth(true);
      sethelperTextFourth("Required field");
    } else if (Contactlist === "") {
      seterrorFifth(true);
      sethelperTextFifth("Required field");
    } else {
        setisProcess(true);
                 var sFormData = require("form-data");
                    var sdata = new sFormData();
                    if(isIframe === true)
                    {
                         sdata.append("espId",IframeEspId);
                    } else {
                         sdata.append("espId",FrontEndEspId);
                    }
                   if(isIframe === true)
                    {
                         sdata.append("versionId",versionId);
                    } else {
                         sdata.append("versionId",FrontendversionId);
                    }
                    
                    sdata.append("Campaigntitle",Campaigntitle);
                    sdata.append("Sendername",Sendername);
                    sdata.append("Senderaddress",Senderaddress);
                    sdata.append("Subjectline",Subjectline);
                    sdata.append("Contactlist",Contactlist);
                    sdata.append("CampaignMode",campaignMode);
                    sdata.append("DraftCampaignId",DraftCampaignIdvalue);
                    AxiosInstance.post("api/v1/Integration/createMailjetdraftCampaign", sdata).then(
                    (response: AxiosResponse) => {
                        if(response.data.data)
                        {
                            if(response.data.data.draftresponse)
                            {
                                if(isIframe === true)
                                {
                                    setisProcess(false);
                                    onTextBoxSelect("success");

                                } else {
                                    handleClose();
                                    toast.success('Campaign Successfully exported to mailjet');
                                    setisProcess(false);
                                }
                               
                            } else {
                                if(isIframe === true)
                                {
                                    setisProcess(false);
                                    onTextBoxSelect("Failed");

                                } else {
                                    toast.error('Something wrong please try again');
                                    handleClose();
                                    setisProcess(false);
                                }
                               
                            }
                        }
                    }
                    );
                    
    }
  };
  
  const setCampigndefautvalues = (event: any) => {
       setisProcess(true);
       setisdisplay(true);
        var sDraftFormData = require("form-data");
            var sDraftdata = new sDraftFormData();
            sDraftdata.append("DraftCampaignId",event.target.value);
            if(isIframe === true)
                    {
                         sDraftdata.append("espId",IframeEspId);
                    } else {
                         sDraftdata.append("espId",FrontEndEspId);
                    }
            setDraftCampaignIdvalue(event.target.value);
            AxiosInstance.post("api/v1/Integration/getMailjetDraftcampaignDetail", sDraftdata).then(
            (response: AxiosResponse) => {
                if(response.data.status === true)
                {
                    if(response.data.data.draftcampaigndetail.length>0)
                    {
                        setCampaigntitlevalue(response.data.data.draftcampaigndetail[0].Title);
                        setCampaigntitle(response.data.data.draftcampaigndetail[0].Title);
                        setSendernamevalue(response.data.data.draftcampaigndetail[0].SenderName);
                        setSendername(response.data.data.draftcampaigndetail[0].SenderName);
                        setSubjectvalue(response.data.data.draftcampaigndetail[0].Subject);
                        setSubjectline(response.data.data.draftcampaigndetail[0].Subject);
                        setSenderAddressvalue(response.data.data.draftcampaigndetail[0].SenderEmail);
                        setSenderaddress(response.data.data.draftcampaigndetail[0].SenderEmail);
                        setContactListvalue(response.data.data.draftcampaigndetail[0].ContactsListID);
                        setContactlist(response.data.data.draftcampaigndetail[0].ContactsListID);
                        setisProcess(false);
                        setisdisplay(false);
                    } else {
                        setisProcess(false);
                    }
                } else {
                    toast.error('Campaign details not found');
                    setisProcess(false);
                }
            }
            );
            //setisdisplay(false);
   
  };
  
  
  
   const openExportEspfun = (espIdvalue:any,versionId:any,cv_name:any,email_subject_value:any) => {
        //setemailId(event.currentTarget.value);
        setCampaigntitlevalue(cv_name);
        setCampaigntitle(cv_name);
        setSubjectvalue(email_subject_value);
        setSubjectline(email_subject_value);
        var FormData = require("form-data");
        var data = new FormData();
        data.append("espId",espIdvalue);
        setIframeEspId(espIdvalue);
        setversionId(versionId);
        AxiosInstance.post("api/v1/Integration/getMailjetSenderlist", data).then(
        (response: AxiosResponse) => {
            if(response.data.data)
            {
                if(response.data.status === true)
                {
                    setmailjetsenderlistFrame(response.data.data.mailjetsenderlist);
                    var sFormData = require("form-data");
                    var sdata = new sFormData();
                    sdata.append("espId",espIdvalue);
                    AxiosInstance.post("api/v1/Integration/getMailjetContatlist", sdata).then(
                    (response: AxiosResponse) => {
                        if(response.data.data)
                        {
                            if(response.data.data.mailjetcontatlist.length>0)
                            {
                                setmailjetcontactlistFrame(response.data.data.mailjetcontatlist);
                            }
                        }
                    }
                    );
                } else {
                    
                    onTextBoxSelect("Error");
                }
            }
        }
        );
      
        
  };

  useEffect(() => {
      setCampaigntitle(FrontEndcname);
      setSubjectline(FrontEndSubject);
        if(getParam('selectedEspId')!==null && getParam('selectedEspId')!==''
        && getParam('cv_name')!==null && getParam('email_subject_value')!==null)
        {
            if(isIframe === true)
            {
               openExportEspfun(getParam('selectedEspId'),getParam('versionId'),
               getParam('cv_name'),getParam('email_subject_value'));
            }
        }
     
       // eslint-disable-next-line 
  }, [isIframe]);


  return (
    <Box className={classes.componentHeader}>
      <Box className={classes.componentInnerHeader} style={{ width:"100%",marginBottom:"13px" }}>
        <Typography variant="h5" style={{ marginBottom:"8px" }}>Export to Mailjet</Typography>
        <Typography style={{ marginBottom:"8px" }}>
          Enter the details below to export your email to mailjet
        </Typography>
       
                <TabContext value={value} >
            <TabList className={classes.Tabcontentarea} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Create campaign" value="1" />
              <Tab label="Update campaign" value="2" />
            </TabList>
        </TabContext>
        
        
        {campaignMode === 'update' && (
          <Box className={classes.InputField} style={{
              position:"relative"
              
            }}>
            <TextField
              error={errorThird}
              helperText={helperTextThird}
              select
              fullWidth
              onChange={setCampigndefautvalues}
              disabled={updateDropdowndisable}
              defaultValue=""
              variant="outlined"
              label="Select Campaign"
               InputProps={{
					  endAdornment: (
                       <CircularProgress
             style={{
              marginTop: 10,
              width: 18,
              height: 18,
              color: "rgba(0, 0, 0, 0.26)",
              display: isdisplay ? "block" : "none",position:"absolute",top:"10px",right:"40px"
            }}
          />
                      ),
                    }}
            >
              {mailjetDraftCampaignlistFrame.map((Item: any, index: any) => {
                return <MenuItem value={Item.ID}>{Item.Title}</MenuItem>;
              })}
            </TextField>
            
          </Box>
        )}
       

        <Box className={classes.InputField}>
          <TextField
            name="Campaign_title"
            autoFocus
            error={errorFirst}
            helperText={helperTextFirst}
            fullWidth
            defaultValue={Campaigntitlevalue}
            key={Campaigntitlevalue}
            onChange={setCampaigntitlefun}
            variant="outlined"
            label="Campaign Name"
            //className={classes.clsNewTemplateName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
        </Box>

        <Box className={classes.InputField}>
          <TextField
            name="Sender_name"
            autoFocus
            error={errorSecond}
            helperText={helperTextSecond}
            fullWidth
            defaultValue={Sendernamevalue}
            key={Sendernamevalue}
            onChange={setSendernamefun}
            variant="outlined"
            label="Sender Name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
        </Box>

        {isIframe === true ? (
        <Box className={classes.InputField}>
          <TextField
            error={errorThird}
            helperText={helperTextThird}
            select
            fullWidth
            onChange={setSenderaddressfun}
            defaultValue={SenderAddressvalue}
            key={SenderAddressvalue}
            variant="outlined"
            label="Sender Address"
          >
            {mailjetsenderlistFrame.map((Item: any, index: any) => {
              return <MenuItem value={Item.Email}>{Item.Name}</MenuItem>;
            })}
          </TextField>
        </Box>
        ) : (
          <Box className={classes.InputField}>
          <TextField
            error={errorThird}
            helperText={helperTextThird}
            select
            fullWidth
            onChange={setSenderaddressfun}
            defaultValue={SenderAddressvalue}
            key={SenderAddressvalue}
            variant="outlined"
            label="Sender Address"
          >
            {mailjetsenderlist.map((Item: any, index: any) => {
              return <MenuItem value={Item.Email}>{Item.Name}</MenuItem>;
            })}
          </TextField>
        </Box>
        )}

        <Box className={classes.InputField}>
          <TextField
            name="Subject"
            autoFocus
            error={errorFourth}
            helperText={helperTextFourth}
            fullWidth
            defaultValue={Subjectvalue}
            key={Subjectvalue}
            onChange={setSubjectlinefun}
            variant="outlined"
            label="Subject"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
        </Box>

        {isIframe === true ? (
        <Box>
          <TextField
            error={errorFifth}
            helperText={helperTextFifth}
            select
            fullWidth
            defaultValue={ContactListvalue}
            key={ContactListvalue}
            onChange={setContactlistfun}
            variant="outlined"
            label="Contact List"
          >
            {mailjetcontactlistFrame.map((Item: any, index: any) => {
              return <MenuItem value={Item.ID}>{Item.Name}</MenuItem>;
            })}
          </TextField>
        </Box>
        ) : (
          <Box>
          <TextField
            error={errorFifth}
            helperText={helperTextFifth}
            select
            fullWidth
            defaultValue={ContactListvalue}
            key={ContactListvalue}
            onChange={setContactlistfun}
            variant="outlined"
            label="Contact List"
          >
            {mailjetcontactlist.map((Item: any, index: any) => {
              return <MenuItem value={Item.ID}>{Item.Name}</MenuItem>;
            })}
          </TextField>
        </Box>
        )}

        <Box className={classes.gptFooter}>
          {isIframe === true ? (
            <Button
              onClick={() => {
                closeContent();
              }}
              size="large"
              style={{ opacity: "0.6", marginRight: 16 }}
            >
              CANCEL
            </Button>
          ) : (
            <Button
              onClick={() => {
                handleClose();
              }}
              size="large"
              style={{ opacity: "0.6", marginRight: 16 }}
            >
              CANCEL
            </Button>
          )}

          {isProcess === true ? (
          <CircularProgress
            style={{
              marginTop: 10,
              width: 18,
              height: 18,
              color: "rgba(0, 0, 0, 0.26)",
              display:"block"
            }}
          />
        ) : (
           <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.insertbut}
              onClick={() => {
                ExportHtml();
              }}
            >
              EXPORT
            </Button>
         
        )}
        </Box>
      </Box>
    </Box>
  );
};

export default MailjetExport;
