import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        fontWeight: "bold",
      },
      outlined: {
        width: "fit-content",
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "rgba(5, 124, 255, 0.15)",
        },
      },
    },
    MuiMenuItem: {
      root: {
        "&:hover": {
          backgroundColor: "rgba(5, 124, 255, 0.15)",
        },
      },
    },
    MuiListItem: {
      root: {
        "&.Mui-selected": {
          backgroundColor: "rgba(5, 124, 255, 0.15)",
        },
        "&.Mui-selected:hover": {
          backgroundColor: "rgba(5, 124, 255, 0.15)",
        },
      },
    },
    MuiButtonBase: {},
    MuiTab: {
      wrapper: {
        flexDirection: "row-reverse",
        justifyContent: "space-evenly",
      },
    },
    // .MuiTypography-body1-503
    MuiTypography: {
      body1: {
        503: {
          fontWeight: "bold",
        },
      },
    },
    MuiTextField: {
      root: {
        "Mui-error": {
          border: "2px solid #FF0000",
        },
      },
    },
  },

  props: {
    MuiTextField: {
      variant: "outlined",
      SelectProps: {
        MenuProps: {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#057CFF",
    },
    secondary: {
      main: "#14B8A6",
      light: "black",
    },
    error: {
      main: "#dc004e",
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    caption: {
      color: "#646464",
    },
    h5: {
      color: "#11142D",
    },
    h6: {
      fontWeight: 500,
      color: "#2F2F2F",
    },
  },
});
