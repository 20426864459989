import React from "react";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { Box } from "@material-ui/core";
import { useStyles } from "../styles";

interface Props {
	integrationsData: any;
	defaultIntegrationslist: any;
	setDefaultIntegrationslist: any;
	selectedIntegrations: any;
	allIntegrations: any;
	setAllIntegrations: any;
}

const TeamIntegrations:  React.FC<Props> = ({
	integrationsData,
	defaultIntegrationslist,
	setDefaultIntegrationslist,
	selectedIntegrations,
	allIntegrations,
	setAllIntegrations
}) => {
  const classes = useStyles(); 
  // eslint-disable-next-line
  const [defaultStylelist, setdefaultStylelist] = React.useState<any>([]);
  //const [isCheckAll, setIsCheckAll] = React.useState(false);
  //const URL = window.location.pathname.split('/').pop();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "auto",
      },
    },
  };

   const makeJoin = (selected:[]) => {
	  
	  var arrTemp:string[] = []
	  var i=0;
	   selected.forEach((Item:any, index:any) => {
		  integrationsData.forEach((InD:any, index1:any) => {
			if(Item ===InD.pc_id){
				if(InD.pc_assign_name!==''){
					arrTemp[i]= InD.pc_assign_name;
					i++;
				}
			}
		  }); 
		 
	  });
	 
	  return arrTemp.join(',');
  };
  
  const handleChange = (event:any) => {
	//setIsCheckAll(false);
    setDefaultIntegrationslist(typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value);
  };
  
  const handleIntChange = (event:any) => {
	setAllIntegrations(event.target.checked);
  };
  
  const checkboxCheck = () => {
	  if(!integrationsData.length)
		  return false;
	  return allIntegrations;
  };
  const selectCheck = () => {
	  if(!integrationsData.length)
		  return true;
	  return allIntegrations;
  };
	 

  return (
   <Box>
  <FormControlLabel disabled={(integrationsData.length) ? false:true} classes={{label:classes.checkboxLabel}} control={<Checkbox checked={checkboxCheck()}  color="primary"  onChange={handleIntChange}/>} label="Automatically add Integrations" className={classes.captioncheckbox}/>
    <Box className={classes.multipleCheckBox}>
      <FormControl className={classes.teamFormControl} variant="outlined" size="medium">
        <InputLabel id="demo-multiple-checkbox-label">Integrations</InputLabel>
        <Select
          className={classes.teamFormSelect} 
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={defaultIntegrationslist}
          onChange={handleChange}
          label={"Integrations"}
          renderValue={(selected: any) => makeJoin(selected)}
          MenuProps={MenuProps}
		  disabled={selectCheck()}
        >
			
          {integrationsData.map((Item : any,index:any) => {
              return (
              <MenuItem key={Item.pc_id} value={Item.pc_id}>
                <Checkbox checked={defaultIntegrationslist.indexOf(Item.pc_id) > -1} color="primary"/>
                <ListItemText primary={Item.pc_assign_name} />
              </MenuItem>
              )
          })}
        </Select>
      </FormControl>      
    </Box>
	</Box>
  );
};

export default TeamIntegrations;
