import { makeStyles, Paper } from "@material-ui/core";
import { Box, Button, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    height: 250,
  },
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "5rem",
    backgroundColor: "#f8f8f8",
  },
  caption: {
    marginBottom: theme.spacing(2),
  },
  image: {
    backgroundColor: "#C4C4C4",
    width: 350,
    height: 250,
  },
  heading: {
    marginBottom: theme.spacing(1),
    fontWeight: 500,
  },
}));

const GettingStarted: React.FC<any> = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box width="65%">
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Box>
              <Typography
                component="h1"
                align="left"
                className={classes.heading}
                variant="h5"
              >
                Getting Started
              </Typography>
              <Typography
                component="p"
                className={classes.caption}
                align="left"
                variant="caption"
              >
                Here's what we recommend you do next
              </Typography>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Paper className={classes.mainPaper}>
              <Grid container>
                <Grid
                  item
                  md={6}
                  style={{ backgroundColor: "#c4c4c4", height: 250 }}
                ></Grid>
                <Grid item md={6}>
                  <Box
                    display="flex"
                    style={{ margin: 20 }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <Typography
                        component="h1"
                        align="left"
                        className={classes.heading}
                        variant="h5"
                      >
                        Getting Started
                      </Typography>
                      <Typography
                        component="p"
                        className={classes.caption}
                        align="left"
                        variant="caption"
                      >
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit. Aenean commodo ligula eget dolor. Aenean massa.
                      </Typography>
                      <Button variant="contained" color="primary">
                        Get Started
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={6}>
            <Paper className={classes.mainPaper}>
              <Grid container>
                <Grid
                  item
                  md={5}
                  style={{ backgroundColor: "#c4c4c4", height: 250 }}
                ></Grid>
                <Grid item md={7}>
                  <Box
                    display="flex"
                    style={{ margin: 20 }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <Typography
                        component="h1"
                        align="left"
                        className={classes.heading}
                        variant="h5"
                      >
                        Getting Started
                      </Typography>
                      <Typography
                        component="p"
                        className={classes.caption}
                        align="left"
                        variant="caption"
                      >
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit. Aenean commodo ligula eget dolor. Aenean massa.
                      </Typography>
                      <Button color="primary">Create Email</Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={6}>
            <Paper className={classes.mainPaper}>
              <Grid container>
                <Grid
                  item
                  md={5}
                  style={{ backgroundColor: "#c4c4c4", height: 250 }}
                ></Grid>
                <Grid item md={7}>
                  <Box
                    display="flex"
                    style={{ margin: 20 }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <Typography
                        component="h1"
                        align="left"
                        className={classes.heading}
                        variant="h5"
                      >
                        Getting Started
                      </Typography>
                      <Typography
                        component="p"
                        className={classes.caption}
                        align="left"
                        variant="caption"
                      >
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit. Aenean commodo ligula eget dolor. Aenean massa.
                      </Typography>
                      <Button color="primary">Create Email</Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={6}>
            <Paper className={classes.mainPaper}>
              <Grid container>
                <Grid
                  item
                  md={5}
                  style={{ backgroundColor: "#c4c4c4", height: 250 }}
                ></Grid>
                <Grid item md={7}>
                  <Box
                    display="flex"
                    style={{ margin: 20 }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <Typography
                        component="h1"
                        align="left"
                        className={classes.heading}
                        variant="h5"
                      >
                        Getting Started
                      </Typography>
                      <Typography
                        component="p"
                        className={classes.caption}
                        align="left"
                        variant="caption"
                      >
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit. Aenean commodo ligula eget dolor. Aenean massa.
                      </Typography>
                      <Button color="primary">Create Email</Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item md={6}>
            <Paper className={classes.mainPaper}>
              <Grid container>
                <Grid
                  item
                  md={5}
                  style={{ backgroundColor: "#c4c4c4", height: 250 }}
                ></Grid>
                <Grid item md={7}>
                  <Box
                    display="flex"
                    style={{ margin: 20 }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box>
                      <Typography
                        component="h1"
                        align="left"
                        className={classes.heading}
                        variant="h5"
                      >
                        Getting Started
                      </Typography>
                      <Typography
                        component="p"
                        className={classes.caption}
                        align="left"
                        variant="caption"
                      >
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit. Aenean commodo ligula eget dolor. Aenean massa.
                      </Typography>
                      <Button color="primary">Create Email</Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default GettingStarted;
