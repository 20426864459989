import React from "react";
import { useStyles } from "../../styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

interface Props {}
const FileListSkleton: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <>
      <Card elevation={0} className={classes.boxBorder}>
        <Grid container alignItems="center">
          <Grid item xs={2} sm={2} md={2}>
            <Skeleton
              animation="wave"
              variant="rect"
              className={classes.cardImage}
            />
          </Grid>
          <Grid item xs={9} sm={9} md={9} className={classes.boxBody}>
            <Skeleton
              animation="wave"
              height={30}
              style={{ marginBottom: 6 }}
            />
            <Skeleton animation="wave" height={20} width="80%" />
          </Grid>
          <Grid item xs={1} sm={1} md={1}></Grid>
        </Grid>
      </Card>
    </>
  );
};

export default FileListSkleton;
