import React, { useRef, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Box} from "@material-ui/core";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Stepper from '@material-ui/core/Stepper';
import { StepLabel } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import FolderIcon from "@material-ui/icons/Folder";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Button,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";

interface Props {
  isNotSettingsPage: any;
}
interface RenderTree {
  id: string;
  name?: string;
  type?: string;
  folder_name?: string;
  parent_id?: string;
  company_id?: string;
  state?: {
    opened: boolean;
  };
  childes?: RenderTree[];
}

    const Saleforce: React.FC<Props> = ({ isNotSettingsPage }) => {
    const inputElement = useRef<HTMLInputElement>(null);
    const history = useHistory();

    const [integrationName, setintegrationName] = useState("");
    const [integrationNamevalue, seintegrationNamevalue] = useState("");

    const [activeStepSalesForceCloud, setActiveStepSalesForceCloud] = React.useState(0);
    const stepSalesForceCloud = ['API Connection Settings', 'Default Export Folder'];

    const [clientid, setclientid] = useState('');
    const [clientSecret, setclientSecret] = useState('');
    const [authBaseUrl, setauthBaseUrl] = useState('');
    const [BaseUrl, setBaseUrl] = useState('');
    const [cloudexport, setcloudexport] = useState<boolean>(false);
    const [cloudFoldersnew, setcloudFoldersnew] = React.useState<any>([]);
    const [expanded, setExpanded] = useState<any>([]);
    const [sfmcdefaultFolder, setsfmcdefaultFolder] = useState('');
    const [showLoader, setShowLoader] = useState<boolean>(false);
  
  
    const handleChange = (event: any) => {
        if(event.target.name ==='client_id')
        {
          setclientid(event.target.value); 
            
        } else if(event.target.name ==='client_secret')
        {
            setclientSecret(event.target.value);
        } else if(event.target.name ==='auth_base_url')
        {
            setauthBaseUrl(event.target.value);
        } else if(event.target.name ==='rest_base_url')
        {
            setBaseUrl(event.target.value);
        }
  };

  const getIntegrationData = (rowId: any) => {
    AxiosInstance.get(`api/getEspDataById/${rowId}`).then(
      (response: AxiosResponse) => {
        if (response.data) {
          seintegrationNamevalue(response.data.usertable.pc_assign_name || '');
        }
      }
    );
  };

  const initialValues = {};
  useEffect(() => {
      const intName = localStorage.getItem("integration_name") || "";
      if(localStorage.getItem("editRId"))
      {
        getIntegrationData(localStorage.getItem("editRId"));
      } else {
          seintegrationNamevalue(intName);
      }
    inputElement.current?.focus();
  }, []);

  const submitFrm = () => {
      var integration_type =  localStorage.getItem("integration_type") || "";
    if (localStorage.getItem("integration_type") !== "") {
      let data = new FormData();
      var pc_assign_name = "";
      if (integrationName !== "") {
        pc_assign_name = integrationName || "";
      } else {
        pc_assign_name = localStorage.getItem("integration_name") || "";
      }
      data.append("pc_provider_id", integration_type);
      
      
      if (clientid) {
        data.append("saleforce_cloud_clientId", clientid);
      }
      if (clientSecret) {
        data.append("saleforce_cloud_clientSecret", clientSecret);
      }
      if (authBaseUrl) {
        data.append("saleforce_cloud_authURL", authBaseUrl);
      }
      if (BaseUrl) {
        data.append("saleforce_cloud_baseURL", BaseUrl);
      }
      if (sfmcdefaultFolder) {
        data.append("saleforce_default_folder",sfmcdefaultFolder);
      }
     
      if (pc_assign_name) {
         data.append("pc_assign_name", pc_assign_name);
      }

      AxiosInstance.post("api/v1/Integration/save_configuration_ajax", data)
        .then((response: AxiosResponse) => {
          if (response?.data?.status) {
            toast.success(response?.data?.data?.message);
            setTimeout(() => {
              history.push("/settings/integrations");
            }, 150);
          }
        })
        .catch((error: any) => {
          if (error.response) {
            if (error.response.status === 400) {
              toast.error(error.response.data.message);
            }
          }
        });
    }
  };
  
  const OpenExportview = (event:any) => {
      event.preventDefault();
      
        if(clientid ==='')
        {
            toast.error('All fields are required');
            setcloudexport(false);       
        }
        else if(clientSecret ==='')
        {
            toast.error('All fields are required');
            setcloudexport(false);       
        }
        else if(authBaseUrl ==='')
        {
            toast.error('All fields are required');
            setcloudexport(false);       
        }
        else if(BaseUrl ==='')
        {
            toast.error('All fields are required');
            setcloudexport(false);       
        }     

        else {
            getCloudFolders();
            
        }
  }
  
   const setFolderId = (event: any) => {
       setsfmcdefaultFolder(event.target.id);
  };
  
  const handleToggle = (event: any, nodeIds: any) => {
    setExpanded(nodeIds);
  };
 
    React.useEffect(() => {
    let data: any[] = [0];
       setExpanded(data);
       // eslint-disable-next-line 
  }, [cloudFoldersnew]);
  
  const getCloudFolders = () => {
      setShowLoader(true)
    AxiosInstance.get('api/v1/Campaign/getexportFolders', {
		params: {
            clientid:clientid,
            clientSecret:clientSecret,
            authBaseUrl:authBaseUrl,
            BaseUrl:BaseUrl,
		}
	}).then((response: AxiosResponse) => {
        if (response.data.status === true) {
            setcloudFoldersnew(response.data.folders[0]);
             setShowLoader(false);
            setcloudexport(true);
            setActiveStepSalesForceCloud((prevActiveStep) => prevActiveStep + 1);
        } else {
            setShowLoader(false)
            setcloudexport(false);
            toast.error("Invalid credentials supplied for Salesforce Cloud Settings");
        }
      }).catch(function(error) {
});
  };
  
  const CloseExportview = (event:any) => {
      event.preventDefault();
      setShowLoader(false);
      setcloudexport(false);
	  setActiveStepSalesForceCloud((prevActiveStep) => prevActiveStep - 1);
  }
  
  
  
  
  const useStyles = makeStyles((theme) => ({
    boxRow: {
      alignItems: "center",
      paddingBottom: 17,
    },
    avatar: {
      width: 32,
      height: 32,
    },
    InputText: {
      marginTop: "0",
      flex: 1,
      "& input ": {
        height: "unset",
        borderRadius: "15px!important",
      },
      "& .MuiSelect-selectMenu ": {
        padding: 7,
      },
      "& > div ": {
        borderRadius: "2px !important",
      },
      "& input::placeholder": {
        fontSize: "14px",
        color: "#999999",
      },
    },
      backBtn:{
    marginRight: 16,
    padding: "6px 20px",
    fontSize: 15,
    letterSpacing:" 0.46px",
    textTransform: "uppercase",
  },
  completeBtn:{
    fontSize: 15,
    letterSpacing: "0.46px",
    padding: "8px 21px"
  },
    InputText1: {
      borderRadius: "12px !important",
    },
     nextBtn:{
    letterSpacing:"0.46px",
    fontSize: 15,
    padding: "8px 22px"
  },
  InputTextOutlined:{
    marginTop: "0",
    marginBottom: 24,
    "&:last-child":{      
      marginBottom: 8,
    },
    "& .MuiOutlinedInput-root": {
      "& input": {
        paddingTop: 18,
        paddingBottom: 18,
      },
    },
  },
    InputLabel: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: "bold",
      color: "#11142D",
      width: 200,
    },
    InputLabelBut: {
      fontFamily: "Poppins, sans-serif",
      fontWeight: "bold",
      color: "#11142D",
      width: 130,
    },
    form: {
      width: 800,
     paddingBottom:50,
    },
    dialogTitle: {
      padding: "7px 15px 15px 15px",
      "& > h2": {
        fontSize: "18px",
      },
    },
    dialogContent: {
      padding: "15px 15px 7px 15px",
      overflow: "hidden",
      overflowY: "scroll",
    },
    btnCancelNobelO: {
      border: "1px solid #969696",
      color: "#676767",
      borderRadius: 2,
      fontSize: 16,
      fontWeight: "normal",
      padding: "3px 22px",
      "&:hover": {
        background: "#969696",
        color: "#ffffff",
      },
    },
      treeViewDiv: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(0.5),
    "&:hover": {
      "& $moreVertIcon": {
        display: "block",
      },
    },
  },
  treeViewFolder: {
    color: "#e0e0e0",
  },
    stepperContainer: {
      paddingLeft:0,
      paddingRight:0,    
      marginLeft: "-10px"
  },
    btnPrimary: {
      background: "#057CFF",
      borderRadius: "3px",
      color: "#ffffff",
      fontSize: 16,
      fontWeight: "normal",
      padding: "5px 30px",
      "&:hover": {
        background: "#0a6adc",
      },
    },
    salesforceTree:{
    background: "rgba(0, 0, 0, 0.04)",
    border: "1px solid rgba(0, 0, 0, 0.38)",
    borderRadius: 4,
    ".MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label":{
      background: "rgba(25, 118, 210, 0.04)"
    },
    "&.MuiTreeView-root":{
      padding:10,
      marginTop: 16,
      marginBottom: 8,
    },
    "& $treeViewFolder":{
      display: "none"
    },
    "& $treeViewDiv":{
      paddingTop: 0,
      "& .MuiTypography-body1":{
        paddingLeft: "0 !important",
        fontSize: 16,
        letterSpacing:" 0.15px",
        
      }
    }
  },
   outerdiv: {
     width:"70%",
    },
    dialogActions: {
      borderTop: "1px solid #e5e5e5",
      padding: "23px 0 15px 0",
      margin: "0 15px",
    },
  }));
  const renderTree = (nodes: RenderTree) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={
        <div
          id={nodes.folder_name}
          data-id={nodes.id}
           className={classes.treeViewDiv}
        >
          <FolderIcon className={classes.treeViewFolder} />
          <Typography variant="body1" onClick={setFolderId} id={nodes.id} style={{ paddingLeft: "1rem" }}>
            {nodes.folder_name}
          </Typography>
        </div>
      }
    >
      {Array.isArray(nodes.childes)
        ? nodes.childes.map((node) => renderTree(node))
        : ""}
    </TreeItem>
  );

  const classes = useStyles();
  return (
    <div className={classes.outerdiv}>
     <DialogContent>
       <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
              {"Salesforce Marketing Cloud Settings"}
            </DialogTitle>
                
           
              <Stepper activeStep={activeStepSalesForceCloud} classes={{ root: classes.stepperContainer }}>
								{stepSalesForceCloud.map((label, index) => {
								const stepProps = {};
								const labelProps = {};
								return (
									<Step key={label} {...stepProps}>
										<StepLabel {...labelProps}>{label}</StepLabel>
									</Step>
								);
								})}
                                
					</Stepper>
                     
    {cloudexport === true ? (
                        <div>
							<Typography
								variant="subtitle2"
							>
								Select the default export folder for all email exports.
							</Typography>
							<TreeView
							defaultCollapseIcon={<ExpandMoreIcon />}
							defaultExpandIcon={<ChevronRightIcon />}
							expanded={expanded}
							onNodeToggle={handleToggle}
							className={classes.salesforceTree}
							>
							{renderTree(cloudFoldersnew)}
							</TreeView>
                             <div><Button  
								startIcon={<ArrowBackIcon />}
								className={classes.backBtn}
								type="button" 
								onClick={CloseExportview} 
								variant="outlined" 
								color="primary"
							 >
                          BACK
                      </Button>
                       <Button className={classes.completeBtn} onClick={submitFrm}  type="submit" variant="contained" color="primary">
					   		COMPLETE
                       </Button>
                       
                      </div>
                      
                        </div>
					) : (
						 <Formik initialValues={initialValues} onSubmit={() => { console.log("submit!"); }}>
        {() => (
          <Form className={classes.form}>
              <Box className={classes.boxRow}>
                
                <Field
                    onChange={(e: any) => setintegrationName(e.target.value)}
                    defaultValue={integrationNamevalue}
                    key={integrationNamevalue}
                    autofocus="true"
                    ref={inputElement}
                    as={TextField}
                    label="Integration Name"
                    type="text"
                    variant="outlined"
                    margin="normal"
                    name="pc_assign_name"
                    required
                    className={classes.InputTextOutlined}
                    placeholder="Integration Name"
                    fullWidth
                />
              </Box>
              
              <Box className={classes.boxRow}>
                
                <Field
                autofocus="true"
                ref={inputElement}
                label="Client ID"
                as={TextField}
                name="client_id"
                required
                onChange={handleChange}
                className={classes.InputText1}
                placeholder="Client ID"
                fullWidth
                type="text"
                variant="outlined"
                margin="normal"
                />
              </Box>
              
               <Box className={classes.boxRow}>
               
                <Field
                label="Client Secret"
                autofocus="true"
                ref={inputElement}
                as={TextField}
                name="client_secret"
                onChange={handleChange}
                required
                className={classes.InputText1}
                placeholder="Client Secret"
                fullWidth
                type="text"
                variant="outlined"
                margin="normal"
                />
              </Box>
              
              
              <Box className={classes.boxRow}>
               
                    <Field
                    label="Authentication Base URI"
                    autofocus="true"
                    ref={inputElement}
                    as={TextField}
                    name="auth_base_url"
                    onChange={handleChange}
                    required
                    className={classes.InputText1}
                    placeholder="Authentication Base URI"
                    fullWidth
                    type="text"
                    variant="outlined"
                    margin="normal"
                    />
              </Box>
              
              
               <Box className={classes.boxRow}>
                
                <Field
                label="Rest Base URI"
                autofocus="true"
                ref={inputElement}
                as={TextField}
                name="rest_base_url"
                onChange={handleChange}
                required
                className={classes.InputText1}
                placeholder="Rest Base URI"
                fullWidth
                type="text"
                variant="outlined"
                margin="normal"
                />
              </Box>
              
              
              
              <Box className={classes.boxRow}>
                <Typography className={classes.InputLabelBut}></Typography>
                <Button 
                    endIcon={<ArrowForwardIcon />} 
                    type="submit" 
                    onClick={OpenExportview} 
                    variant="contained" 
                    color="primary"
                    className={classes.nextBtn}
                >
                NEXT
                </Button>
                <Box
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
                >{showLoader && <CircularProgress />}
                </Box>
              </Box>
          
          </Form>
        )}

      </Formik>
      
					)}
                    
      </DialogContent>
                    
                    
    </div>
    
  );
};

export default Saleforce;
