import React from "react";
import Mailchimp from "./components/Mailchimp";
import CampaignMonitor from "./components/CampaignMonitor";
import Pardot from "./components/Pardot";
import Saleforce from "./components/Saleforce";
import Klaviyo from "./components/Klaviyo";
import Marketo from "./components/Marketo";
import Sendgrid from "./components/Sendgrid";
import Mailjet from "./components/Mailjet";
import Braze from "./components/Braze";


interface Props {
  handleNext?: any;
  save?: any;
  isNotSettingsPage?: boolean;
}

const NewespIntegration: React.FC<Props> = () => {
     
  return (
    <>
      {(localStorage.getItem("integration_type") === 'Mailchimp') && (
        <Mailchimp isNotSettingsPage={false} />
      )}
	  {(localStorage.getItem("integration_type") === 'Campaign Monitor') && (
        <CampaignMonitor isNotSettingsPage={false} />
      )}
      {(localStorage.getItem("integration_type") === 'Pardot') && (
        <Pardot isNotSettingsPage={false} />
      )}
      
      {(localStorage.getItem("integration_type") === 'Salesforce Cloud') && (
        <Saleforce isNotSettingsPage={false} />
      )}
      {(localStorage.getItem("integration_type") === 'Klaviyo') && (
        <Klaviyo isNotSettingsPage={false} />
      )}
       {(localStorage.getItem("integration_type") === 'Marketo') && (
        <Marketo isNotSettingsPage={false} />
      )}
      {(localStorage.getItem("integration_type") === 'Send Grid') && (
        <Sendgrid isNotSettingsPage={false} />
      )}
      {(localStorage.getItem("integration_type") === 'Mailjet') && (
        <Mailjet isNotSettingsPage={false} />
      )}
       {(localStorage.getItem("integration_type") === 'Braze') && (
        <Braze isNotSettingsPage={false} />
      )}
      
    </>
  );
};

export default NewespIntegration;
