import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { useStyles } from "../../styles";
import TextFiledNumber from './TextFiledNumber';
import { useSelector } from "react-redux";
import { getTotalLineHeightForButton } from "../../../../../../utils/Functions";

interface Props {

}

const ButtonPadding: React.FC<Props> = () => {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const state = useSelector((state: any) => state.styleGuide);
  return (
    <Box className={classes.ctaAllPadding}>
      <Box className={classes.TitleDescription2}>       
        <Box className={classes.flexColumn1} >         
          Button Padding:
        </Box>  
      </Box>  
      <Box display="flex" justifyContent="center" className={classes.styleList1} >    
        <Box className={classes.ctaPaddingcontainer}>
          <Box className={classes.ctaPaddingRow}>
            <Box className={classes.ctaPaddingTop}>
              <TextFiledNumber fieldName="button_padding_top" label="Top" min={0} max={150} />
            </Box>        
            <Box className={classes.ctaPaddingBottom}>
              <TextFiledNumber fieldName="button_padding_bottom" label="Bottom" min={0} max={150} />
            </Box>      
            <Box className={classes.ctaPaddingLeft}>
              <TextFiledNumber fieldName="button_padding_left" label="Left" min={0} max={150} />
            </Box>        
            <Box className={classes.ctaPaddingRight}>
              <TextFiledNumber fieldName="button_padding_right" label="Right"  min={0} max={150} />
            </Box>
            <Box className={classes.ctaTotalLineHeight}><Typography>Total Height: {getTotalLineHeightForButton(state)}px</Typography></Box>
          </Box>
          
        </Box>     
          
        </Box>
    </Box>
);
  // return (
  //       <Box className={classes.section3}>
  //         <Box display="flex" className={classes.styleList1} >
  //           <Box className={classes.TitleDescription1}>
  //             <Typography variant="subtitle2" className={classes.InputLabel1}>
  //               &nbsp;
  //             </Typography>          
  //             <Box className={classes.flexColumn1}>         
  //               {title}
  //             </Box>  
  //           </Box>      
  //           <Box className={classes.ContainerFontSize}>
  //             &nbsp;
  //           </Box>        
  //           <Box className={classes.ContainerLineHeight}>
  //             <TextFiledNumber fieldName={fieldName} title="" min={0} max={50}  />
  //           </Box>
  //         </Box>
  //       </Box>
  // );
};

export default ButtonPadding;