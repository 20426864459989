import React, { useEffect, useState } from "react";
import { Box, MenuItem, TextField } from "@material-ui/core";
import { useStyles } from "../../styles";
import TextFiledNumber from './TextFiledNumber';
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../../redux/actions/styleGuideActions";
import { DefaultStyleGuide } from "../../utilities/DefaultStyleGuide";
import CustomFontSelection from "./CustomFontSelection";
import { AutoSave } from "../../StyleGuideAutosave";
import { Autocomplete } from "@material-ui/lab";

interface Props {
  name:string;
  code:string;
  min?:number;
  max?:number;
  // fieldNameSpaceAfter?:string;
  // fieldNameBold:string;
}

const EditorStyle: React.FC<Props> = ({name, code, min=1, max=70}) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.styleGuide);
  // if(!state["headline_size"] && state["headline_size"] !== undefined){
  //   dispatch(addField("headline_size", DefaultStyleGuide["headline_size"]));
  //   dispatch(addField("styleGuideDefault", true));
  // }

  if(!state[`${name}_font_${code}`] && state[`${name}_font_${code}`] !== undefined){
    dispatch(addField(`${name}_font_${code}`, DefaultStyleGuide[`${name}_font_${code}`]));
    dispatch(addField("styleGuideDefault", true));
  }
  
useEffect(() => {  
  if (state.fallback_font?.headline) { 
      //SetAddFallBack(true);
  }
  
}, [state.fallback_font?.headline,state.fontsList]);

const [typographyName, SetTypographyName] = useState('')

useEffect(() => {
  const delayTypingName = setTimeout(() => {
    handleTypographyName(typographyName);
  }, 1200)
  return () => clearTimeout(delayTypingName);

  // eslint-disable-next-line
}, [typographyName])

const handleTypographyName = (typographyName : string) => {
    if(typographyName)
    {
      dispatch(addField(`${name}_name_${code}`, typographyName));        
      AutoSave();
    }
};

useEffect(() => {
  

  // eslint-disable-next-line
}, [state[`${name}_weight_nb_${code}`]])

  // {textSizes.map((option) => (
  //   <MenuItem key={option.value} value={option.value}>
  //     {option.label}
  //   </MenuItem>
  // ))}
  

  return (
      <Box className={classes.EditorStyleContainer} style={{paddingBottom:20}}>
        <Box className={classes.rowListDetails1}>
          <Box display="flex" className={classes.TypographyName} >
            <TextField 
              id={`outlined-basic-name-${name}-${code}`}
              label="Name" 
              variant="outlined" 
              size="small"
              // value={state[`${name}_name_${code}`]}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => SetTypographyName(e.target.value)}
              defaultValue = {state[`${name}_name_${code}`]} 
              key={state[`${name}_name_${code}`]}
              name={typographyName}


              // onChange={(e) => {
              //   changeTypographyName(e.target.value);
              // }}
            />
          {(state?.custom_font_visible === true) && (<Box className={classes.TypographyCutomFont} >
            <CustomFontSelection label="Custom Font" fieldName={`${name}_custom_${code}`} />
          </Box>)}
            <Box className={classes.TypographyStandardFont} >    
              <TextField
                select
                label="Standard Font"
                size="small"
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  },
                }}
                placeholder="Select your primary font"
                value={state[`${name}_font_${code}`] || "none"}
                fullWidth
                onChange={(event) => {
                  dispatch(addField(`${name}_font_${code}`, event.target.value));
                  state?.fontsList?.forEach((option:any) => {
                        if(option.name === event.target.value){  
                          dispatch(addField(`${name}_font_full_${code}`, option.family));
                        }
                    });
                  AutoSave();
                }}
              >
                {!state[`${name}_font_${code}`] && (
                  <MenuItem value="none" disabled style={{ color: "#545454" }}>
                    Select your primary font
                  </MenuItem>
                )}
                {state.fontsList && state.fontsList.map((option:any) => (
                  <MenuItem key={option.name} value={option.name}>
                  <span style={{ fontFamily: option.family }}> {option.name === "Trebuchet MS" ? "Trebuchet" : option.name}</span>
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
        </Box>
        <Box className={classes.rowListDetails2}>
          <Box display="flex" className={classes.styleColDetails2} >
            <Box className={classes.TypographyFontSize}>
              <TextFiledNumber fieldName={`${name}_size_${code}`} label="Font Size" min={min} max={max} maxFieldName={`${name}_lineheight_${code}`} />
            </Box>      
            <Box style={{width:5,flex:"unset"}}>&nbsp;</Box>  
            <Box className={classes.TypographyLineHeight}>
              <TextFiledNumber fieldName={`${name}_lineheight_${code}`} label="Line Height" min={min} max={max} minFieldName={`${name}_size_${code}`} />
            </Box>  
            <Box style={{width:10,flex:"unset"}}>&nbsp;</Box> 
            <Box className={classes.TypographyWeight}>
              <Autocomplete
                id={`combo-box-BoldList-${code}`}
                options={BoldList}
                getOptionLabel={(option) => option}
                style={{ flex: "1",maxWidth:60 }}
                value={state[`${name}_weight_nb_${code}`] || "none"}
                onChange={(event: any, newValue: string | null) => {
                  dispatch(addField(`${name}_weight_nb_${code}`, newValue));
                  AutoSave();
                }}
                size="small"
                renderInput={(params) => <TextField {...params} label="Weight" variant="outlined" />}
              />
              {/* <TextFiledNumber fieldName={`${name}_spaceafter_${code}`} label="Margin Bottom" min={0} max={200} /> */}
            </Box>  
            <Box style={{width:13,flex:"unset"}}>&nbsp;</Box>
            <Box className={classes.TypographyMarginTop}>
              <TextFiledNumber fieldName={`${name}_spacebefore_${code}`} label="Margin Top" min={-5} max={200} />
            </Box>
            <Box style={{width:13,flex:"unset"}}>&nbsp;</Box>
            <Box className={classes.TypographyMarginBottom}>
              <TextFiledNumber fieldName={`${name}_spaceafter_${code}`} label="Margin Bottom" min={0} max={200} />
            </Box>  
            <Box style={{width:15,flex:"unset"}}>&nbsp;</Box>
            <Box className={classes.TypographyLetterSpacing}>
              <TextFiledNumber fieldName={`${name}_letterspacing_${code}`} label="Letter Space" min={0} max={200} />
            </Box>
            {/* {fieldNameSpaceAfter ? (<Box className={classes.ContainerSpaceAfter}>
              <TextFiledNumber fieldName={fieldNameSpaceAfter} title="Space After:" min={0} max={200} />
            </Box>) : "" }   */}
            {/* <Box >
              <BoldFiled fieldName={fieldNameBold} title="Weight:" />
            </Box> */}
          </Box>
        </Box>
      </Box>
  );
};

// const BoldList1 = [
//   { key: "100", name: "100", value:"Thin"},
//   { key: "200", name: "200", value:"Extra Light (Ultra Light)"},
//   { key: "300", name: "300", value:"Light"},
//   { key: "400", name: "400", value:"Normal"},
//   { key: "500", name: "500", value:"Medium"},
//   { key: "600", name: "600", value:"Semi Bold (Demi Bold)"},
//   { key: "700", name: "700", value:"Bold"},
//   { key: "800", name: "800", value:"Extra Bold (Ultra Bold)"},
//   { key: "900", name: "900", value:"Black (Heavy)"},
// ];
const BoldList = [
   "100",
   "200",
   "300",
   "400",
   "500",
   "600",
   "700",
   "800",
   "900",
];

export default EditorStyle;