import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  newCampaignRoot: {
    background: "white",
  },
  headerRoot: {
    maxWidth: '1250px'
  },
  newCampaignBody: {
    display: "flex",
    borderTop: "2px solid #c3c3c3",
    paddingTop: "3rem",
    justifyContent: "center",
  },
  treeViewDiv: {
    padding: ".5rem",
    display: "flex",
  },
  treeViewFolder: {
    color: "#e0e0e0",
    // "&:hover": {
    //   color: "#057cff",
    // },
  },
  boxPadding: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
    [theme.breakpoints.between("sm", "md")]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    },
  },
  hasTrialInfo:{
  },
  newCampaignHeader: {
    display: "flex",
    background: "#f8f8f8",
    alignItems: "center",
    paddingBottom: "1rem",
    justifyContent: "center",
    paddingTop: "10px",
    "&$hasTrialInfo":{
      paddingTop: "152px",
    }
  },
  boxWidth: {
    [theme.breakpoints.between("xs", "md")]: {
      width: "95% !important",
    },
    [theme.breakpoints.up("md")]: {
      width: "65%",
    },
  },
  boxWidthList: {
    [theme.breakpoints.between("xs", "md")]: {
      width: "95% !important",
    },
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
  },
  newCampaignDiv: {
    border: "1px solid #ffffff",
    cursor: "pointer",
    borderRadius: "5px",
    marginBottom: "2rem",
    boxShadow: ' 0px 1px 2px rgba(0, 0, 0, 0.32)',
    // height: '407px',
    maxWidth: "248px",
    "&:hover": {
      border: "1px solid #057CFF",
      "& $hoverBox": {
        display: "block",
      },
    },
  },
  selectedNewCampaignDiv: {
    border: "3px solid #057CFF",
    cursor: "pointer",
    borderRadius: "5px",
    marginBottom: "2rem",
    boxShadow: ' 0px 1px 2px rgba(0, 0, 0, 0.32)',
    // height: '407px',
    maxWidth: "248px",
    "& $hoverBox": {
      display: "block",
    },
  },
  newCampaignName: {
    marginRight: "1rem",
    background: "white",
  },
  newCampaignTextField:{
    "&:hover fieldset": {
      border: "1px solid #057cff  !important",
    }
  },
  newCampaignBrandDiv: {
    position: "relative",
    borderRadius: "7px",
    border: "1px solid #c4c4c4",
    display: "flex",
    letterSpacing: "inherit",
    padding: ".9rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    cursor: "pointer",
    background: "white",
    "&:hover": {
      border: "1px solid #057cff",
    }
  },
  newCampaignHearderOverflow: {
    overflow: "hidden",
    height: "20px",
  },
  newCampaignText: {
    borderRadius: "4px !important",
    background: "white",
  },
  skletonInputDiv: {
    border: "1px solid #c4c4c4",
    display: "flex",
    padding: ".9rem",
    position: "relative",
    background: "white",
    marginLeft: "1rem",
    marginRight: "1rem",
    borderRadius: "7px",
    letterSpacing: "inherit",
    height: "25px",
  },
  folderDropdownListTreeview: {
    maxHeight: "274px",
    overflowY: "scroll",
    marginTop: ".5rem",
  },
  folderDropdownList: {
    position: "absolute",
    top: "60px",
    left: "17px",
    width: "400px",
    display: "block",
    background: "#fff",
    boxShadow: "0px 0px 6px rgb(18 19 19 / 22%)",
    borderRadius: "8px",
    overflowY: "scroll",
    zIndex: 1
  },
  newCampaignButtonDiv: {
    marginTop: ".5rem",
    marginLeft: "1rem",
    textTransform: "none",
    fontWeight: 500,
    boxShadow: "none",
    "&:hover": {
      background: "#0a6adc",
      boxShadow: "none",
    },
  },
  brandDropdownList: {
    position: "absolute",
    top: "60px",
    left: "17px",
    width: "240px",
    display: "block",
    background: "#fff",
    boxShadow: "0px 0px 6px rgb(18 19 19 / 22%)",
    borderRadius: "8px",
    maxHeight: "274px",
    overflowY: "scroll",
  },
  newCampaignCard: {
    overflow: "hidden",
  },
  scrollEffectImage: {
    background: "#f5f5f5",
    height: "100%",
    minWidth: '230px',
    maxWidth: '230px'
  },
  scrollImageContainer: {
    maxHeight: "355px",
    minHeight: "355px",
    overflowY: "scroll",
    display: "flex",
    justifyContent: "center"
  },
  newCampaignCardImage: {
    background: "#f5f5f5",
    width: '230px',
    height: "360px",
    // height: "13rem",
    // width: "13rem",
  },
  hoverBox: {
    display: "none",
  },
  newCampaignEye: {
    // paddingTop: ".5rem",
    // paddingLeft: "1rem",
    paddingRight: '1rem',
    color: "#057cff",
  },
  checkBoxRoot: {
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    paddingTop: "0px",
    paddingRight: "0px",
    paddingBottom: "1rem",
  },
  heading: {
    marginBottom: "16px",
    fontStyle: 'normal',
    fontFamily: 'Poppins, sans-serif',
    lineHeight: '120%',
    fontWeight: 500,
    letterSpacing: "1.2px",
    fontSize: '26px',
  },

  boxText: {
    overflow: "hidden",
    height: "1.5rem",
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normalize',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHight: '15px'
  },

  // skleton section
  boxBorder: {
    border: "1px solid #E1E1E1",
    marginBottom: "1rem",
    paddingBottom: ".5rem",
    paddingTop: ".5rem",
  },
  cardImage: {
    height: "4rem !important",
    marginLeft: "1rem",
    width: "4rem",
  },
  boxBody: {
    paddingBottom: ".5rem",
    overflow: "hidden",
  },
  newCampaignDivSkelton: {
    border: "1px solid #ffffff",
    cursor: "pointer",
    borderRadius: "5px",
    background: "#f5f5f5",
    width: "230px",
    height: '407px',
    boxShadow: ' 0px 1px 2px rgba(0, 0, 0, 0.32)',

  },
  previewRoot: {
    margin: 0,
    padding: theme.spacing(2),
  },
  previewCloseButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  campaign_preview_desktop: {
    border: "1px solid #CBD6E2",
    margin: "0 74px 0 0",
    display: "flex",
    overflow: "hidden",
    background: "#f6f8fa",
    alignItems: "center",
    borderRadius: "7px 7px 6px 6px",
    flexDirection: "column",
    width: "50rem",
    ['@media (max-width:1400px)']: { // eslint-disable-line no-useless-computed-key
      width: "42rem",
    }
  },
  campaign_preview_window_header_dsktop: {
    display: "flex",
    height: "32px",
    paddingLeft: "15px",
    alignItems: "center",
    background: "#F6F8FA",
    width: "100%",
  },
  campaign_preview_window_header_mobile: {
    height: "60px",
    display: "flex",
    background: "#F6F8FA",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  campaign_preview_mobile: {
    margin: "0 0 0px 13px",
    border: "1px solid #CBD6E2",
    boxSizing: "border-box",
    borderRadius: "36px",
    width: "25rem",
    background: "#f6f8fa", 
    ['@media (max-width:1300px)']: { // eslint-disable-line no-useless-computed-key
      marginRight: 30,
    }
  },
  campaign_preview_window_content: {
    height: "calc(100vh - 300px)",
    background: "#fff",
    width: "100%",
  },
  campaign_preview_window_content_mobile: {
    margin: "0 13px 13px 13px",
    border: "1px solid #CBD6E2",
    boxSizing: "border-box",
    borderRadius: "0 0 28px 28px",
    background: "#fff",
    height: "calc(100vh - 300px)",
    overflow: "hidden",
  },

  previewDOT: {
    height: "11px",
    color: "#cbd6e2",
  },
  paper: {
    margin: theme.spacing(8, 4),
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    width: "100%" 
  },
  PreviewContainer:{
    margin:0,
    alignItems: "unset",
    flexDirection: "column",
    justifyContent: "flex-start",
    "& iframe" : {
      borderRadius: 7,
      border: "1px solid #e0e0e0"
    }
  },
  previewImgLogo:{
    display: "flex",
    marginLeft: 12,
    marginRight: 20
  },
  previewIcon:{
    display: "flex",
    alignItems: "center"
  },
  previewContentBody:{

  },
  campaign_preview_body:{

  },
  campaign_preview_window_content_body:{
    margin: 20,
    height: "calc(100vh - 114px)",
    display: "flex",
    justifyContent: "center",
  },
  previewDesktopSelected:{

  },
  previewTableSelected:{
    width:768
  },
  previewPhoneSelected:{
    width:330
  },
  ToggleSelected:{
    "&.MuiToggleButton-root.Mui-selected":{
      backgroundColor: "rgba(25, 118, 210, 0.08)"
    }
  },
  formGrid: {
    display: "flex",
    justifyContent: "center",
    boxShadow: "unset"
  },
  previewH6: {
    fontSize: "28px",
    color: "#5D7186",
  },
  previewHeader:{
    width: "100%",
    // position: "absolute",
    // top: 0,
    // left: 0,
    boxShadow: "1px 3px 10px -5px #b2b2b2"
  },
  previewContent:{
    position: "absolute",
    top: 66,    
    ['@media (max-width:1300px)']: { // eslint-disable-line no-useless-computed-key
      left: 0,
    }
  },
  previewPointer: {
    color: "#5d7186",
    fontSize: "3rem",
    paddingRight: "1rem",
    cursor: "pointer",
    width: 30,
    display: "flex"
  },
  iFrame: {
    border: "0",
  },
  filetreeHeader: {
    marginLeft: "1rem",
    marginRight: "1rem",
    paddingTop: ".5rem",
    paddingBottom: ".5rem",
    borderTop: "1px solid #E1E1E1",
  },
  filetreeInnerHeader: {
    display: "flex",
    verticalAlign: "middle",
    cursor: "pointer",
    padding: ".25rem",
    color: "#238cff",
    "&:hover": {
      background: "#DBECFF",
      borderRadius: "4px",
    },
  },
  filetreeName: {
    paddingLeft: ".5rem",
  },
  styleGuideNewCamp:{
    "& .MuiSelect-selectMenu" :{
      background: "#ffffff"
    }
  }
}));
