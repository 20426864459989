import React from "react";
import Grid from "@material-ui/core/Grid";
import App from "./App";
import Alert from "@material-ui/lab/Alert";
import { useStyles } from '../styles';
interface Props {
  appDatas: any;
  handleClickOpen: any;
  selectedData:any;
  integrateSelected:boolean;
}
const AppLists: React.FC<Props> = ({ appDatas, handleClickOpen , selectedData, integrateSelected }) => {
  const classes = useStyles();
  return (
    <Grid container className={(selectedData.selected === true || integrateSelected === true) ? classes.hasIntegrate : "not-integrated"}>
      {appDatas === null || appDatas === undefined ? (
          <Alert severity="error">There is Something Wrong</Alert>
      ) : (
        appDatas.map((appData: any) => (
          <Grid item key={appData.p_name} style={{position:"relative"}}>
            <App appData={appData} handleClickOpen={handleClickOpen} />
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default AppLists;