import React from "react";
import { Box,Typography} from "@material-ui/core";
import { useStyles } from "../styles";
//import { useDispatch, useSelector } from "react-redux";
//import { addField } from "../../../../redux/actions/styleGuideActions";
//import * as yup from "yup";
//import { AutoSave } from "../StyleGuideAutosave";

const Details: React.FC = () => {  
  const classes = useStyles();
  //const dispatch = useDispatch();
  //const state = useSelector((state: any) => state.styleGuide);
  //const [saveClicked, SetSaveClicked] = useState(0);

  /*const handleSaveDetails = () => {
    checkValidationCompanyName();
    checkValidationCompanyAddress();
    checkValidationCompanyWebsite();
    checkValidationCompanyEmail();
    SetSaveClicked(saveClicked+1);    
  }*/
  /*useEffect(()=>{
    if(saveClicked > 0 && checkStateValidationAdded(state?.company_name_error) && 
     checkStateValidationAdded(state?.company_address_error) &&
     checkStateValidationAdded(state?.company_website_error)){      
      AutoSave();      
    }
    // eslint-disable-next-line
  },[saveClicked])*/

  /*const checkStateValidationAdded = (string:any) => {
    if(string === ""){
      return true;
    }
    return false;
  }

  const checkValidationCompanyName = () => {
    if(!state?.company_name){
      dispatch(addField("company_name_error", "Required"));
    } else dispatch(addField("company_name_error", ""));
  }

  const checkValidationCompanyAddress = () => {
    if(!state?.company_address){
      dispatch(addField("company_address_error", "Required"));
    } else dispatch(addField("company_address_error", ""));
  }
  const checkValidationCompanyWebsite = () => {
    if(!state?.company_website){
      dispatch(addField("company_website_error", "Required"));
      return;
    }

    if(!isValidUrl(state?.company_website)){
      dispatch(addField("company_website_error", "Please enter a valid website"));
    } else dispatch(addField("company_website_error", ""));
    
  }*/
  /*const isValidUrl = (str:string)=> {
      var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
      '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
    return !!urlPattern.test(str);
  }
  const checkValidationCompanyEmail = () => {
    if(!state?.contact_email){
      return;
    }
    let schema = yup.object().shape({
      email: yup.string().email(),
    });
    schema
    .isValid({
      email: state?.contact_email,
    })
    .then((valid) => {
      if(!valid){
        dispatch(addField("contact_email_error", "Please enter a valid email"));
      } else dispatch(addField("contact_email_error", ""));
    });
  }*/


  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box display="flex">
        <Typography className={classes.heading} style={{flex:1}} variant="h6">
          Details
        </Typography>
        
        </Box>
        <Typography variant="caption" className={classes.captionDetails}>
          Set your important company information below 
        </Typography>
      </Box>
    </>
  );
};

export default Details;
