import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  hasTrialInfo:{
  },
  componentRoot: {
    width: "100vw",
    display: "flex",
    paddingTop: 86,
    justifyContent: "center",
    background: " #f8f8f8",
    "&$hasTrialInfo":{
      paddingTop: 143,
    },
    "&$componentOpenAI":{
      background: " #ffffff",
      padding:24,
      height: "calc(100vh - 47px) !important",
      width: "unset"
    }
  },
  componentOpenAI:{
    "& $boxWidth":{
      maxWidth: "unset !important",
    }
  },
  boxWidth: {
    maxWidth: "1200px",
    width: "100%",
  },
  heading: {
    marginBottom: "16px",
    fontStyle: 'normal',
    fontFamily: 'Poppins, sans-serif',
    lineHeight: '120%',
    fontWeight: 500,
    letterSpacing: "1.2px",
    fontSize: '26px',
  },
  componentHeader: {
    display: "flex",
    //paddingLeft: "8px",
    border: "1px solid #E1E1E1",
    background: "white",
    // marginTop: "4px",
    borderRadius: "4px",
    paddingLeft:"0px",
  },
  TabName: {
    textTransform: "none",
    // color: "#057CFF",
    // borderBottom: "1px solid #057CFF",
    padding: "20px 0px",
    cursor: "pointer",
    minWidth: "123px",
  },
  IndexButton: {
    display: "flex",
    paddingRight: "25px",
    justifyContent: "flex-end",
    alignItems: "center",
    flex: "1",
    height: "65px",
  },
  outLineButtonContent: {
    border: "1px solid #057cff",
    borderRadius: "4px",
    color: "#367BF5",
    height: "unset",
    lineHeight: "normal",
    "&:hover": {
      color: "#ffffff",
      background: "#367BF5",
    },
  },
  disabledList: { 
    pointerEvents:"none",
    opacity:0.4
	},
}));
