import React, { useEffect } from "react";
import { useStyles } from "./style";
import Box from "@material-ui/core/Box";
import { Autocomplete } from "@material-ui/lab";
import AxiosInstance from "../../../../utils/AxiosInstance";
import { CircularProgress } from "@material-ui/core";
import { AxiosResponse } from "axios";
import TreeView from "@material-ui/lab/TreeView";
import Tree from "../../../../common/components/MarketoTree";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";


import {
  Button,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

interface Props {
  history?: any;
  onTextBoxSelect?: any;
  isIframe?: boolean;
  emailId:any;
  handleClose:any;
  EmailProviders:any;
  controller:any;

}

const ExportHtml: React.FC<Props> = ({ history, onTextBoxSelect,isIframe,emailId,handleClose,EmailProviders,controller }) => {
  const classes = useStyles();
  const [ExportOption, setExportOption] = React.useState<string>("1");
  const [EspOption, setEspOption] = React.useState<string>("None_None");
  const [localhostingOption, setlocalhostingOption] = React.useState<any>(true);
  const [hostingOption, sethostingOption] = React.useState<any>(false);
  const [errorFirst, seterrorFirst] = React.useState(false);
  const [errorSecond, seterrorSecond] = React.useState(false);
  const [errorThird, seterrorThird] = React.useState(false);
  const [helperTextFirst, sethelperTextFirst] = React.useState<string>("");
  const [helperTextSecond, sethelperTextSecond] = React.useState<string>("");
  //const [helperTextThird, sethelperTextThird] = React.useState<string>("");
  const [radiostatus, setradiostatus] = React.useState(false);
  const [loadespaccounts, setloadespaccounts] = React.useState<any>([]);
  const [loadImagesfolder, setloadImagesfolder] = React.useState<any>([]);
  const [enableaccDropdown, setenableaccDropdown] = React.useState<any>(false);
  const [mailchimpenableaccDropdown, setmailchimpenableaccDropdown] =React.useState<any>(false);
  const [enableImagesDropdown, setenableImagesDropdown] =React.useState<any>(false);
  const [isdisplay, setisdisplay] = React.useState(false);
  const [ismultipleacc, setismultipleacc] = React.useState<any>(false);
  const [ismktImagesfolder, setismktImagesfolder] = React.useState<any>(false);
  const [espId, setespId] = React.useState<string>("");
  const [mktImagesFolderid, setmktImagesFolderid] = React.useState<string>("");
  const [expanded, setExpanded] = React.useState<any>([]);
  const [selected, setSelected] = React.useState([]);
  const [Imgisdisplay, setImgisdisplay] = React.useState(false);
  // eslint-disable-next-line 
  const [mailchimpisdisplay, setmailchimpisdisplay] = React.useState(false);
  const [mediafound, setmediafound] = React.useState(true);
  const [buttondisable, setbuttondisable] = React.useState(false);
  const [allEmailProviders, setallEmailProviders] = React.useState<any>([]);
  const [ProviderId, setProviderId] = React.useState<string>("");
  const [pardotacc, setpardotacc] = React.useState(false);
  const [PardotaccDropdown, setPardotaccDropdown] = React.useState(false);
  const [PardotFormatting, setPardotFormatting] = React.useState<string>("PML");
  const [pmlstatus, setpmlstatus] = React.useState(true);
  const [hmlstatus, sethmlstatus] = React.useState(false);
  const [iframecontroller, setiframecontroller] = React.useState<string>("");
  const [inputelementstatus, setinputelementstatus] =  React.useState(true);
  //const [hostingmsg, sethostingmsg] = React.useState<string>("Host all uploaded images Option is disabled as there is not any active marketo account found  please check the setting integration tab for the connected accounts.you can continue with the selected option.");
  //const [defaultmsg, setdefaultmsg] = React.useState<string>("Configure your export package below by selecting your export platform and how you would like images hosted.");
  function getParam(param : any){
        return new URLSearchParams(window.location.search).get(param);
    }
  isIframe = isIframe ? isIframe : false;

  useEffect(() => {
     var FormData = require("form-data");
      var data = new FormData();
      data.append("espId",'');
      if(getParam('controller') === 'template')
      {
        data.append("controller",'template');
      }
      AxiosInstance.post("api/v1/Integration/all_email_providers", data).then(
        (response: AxiosResponse) => {
          if (response) {
            setTimeout(async () => {
      if (response.data.data) {
               
                if(response.data.data.email_providers.length>0)
                {
                setallEmailProviders(response.data.data.email_providers);
                } else {
                   
                    if(getParam('controller') === 'template')
                        {
                        setinputelementstatus(false)
                        //setradiostatus(true)
                        //setlocalhostingOption(true);
                        setEspOption('marketo');
                        setExportOption('3');
                        //sethostingmsg('Host all uploaded images Option is disabled as there is not any active marketo account found  please check the setting integration tab for the connected accounts.you can continue with the selected option.');

                        }
                }
            } else {
               
            }
  }, 500);
          } 
        }
      );
    // eslint-disable-next-line 
  }, [controller]);
  
  const [parrentId, setParrentId] = React.useState<string>("");
  const closeDropDownFolder = (event: any) => {
    const selectedFolder = event.currentTarget.id;
    setmktImagesFolderid(selectedFolder);
    setParrentId(selectedFolder);
  };
  
  const Closeaction = () => {
    handleClose();
  };

  const handleToggle = (event: any, nodeIds: any) => {
    setExpanded(nodeIds);
  };
  const handleRadioToggle = (event: any) => {
    setPardotFormatting(event.target.value);
    if(event.target.value === 'PML')
    {
        setpmlstatus(true);
        sethmlstatus(false);
    } else {
        sethmlstatus(true);
        setpmlstatus(false);
        
    }
    
  };

  const handleSelect = (event: any, nodeIds: any) => {
    setSelected(nodeIds);
    seterrorThird(false);
  };

  if(inputelementstatus === true)
 {
    
  setTimeout(async () => {
    const close = await document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if(close !== undefined)
    {
    close.addEventListener("click", () => {
        setEspOption("");
        seterrorThird(false);
        setradiostatus(true);
        setmailchimpenableaccDropdown(false);
        setenableaccDropdown(false);
        setenableImagesDropdown(false); 
        setExportOption('1');
        setPardotaccDropdown(false);
        setpardotacc(false);
    });
    }
  }, 200);
 }
   const optionsarr = 
    {label: "", value: "" }
    const optionsarrBlank = 
    {label: "None", value: "None_None" }
  
  /*const options = [
    { value: "marketo", label: "Adobe Marketo Engage" },
    { value: "CampaignMonitor", label: "Campaign Monitor" },
    { value: "Klaviyo", label: "Klaviyo" },
    { value: "Mailchimp", label: "Mailchimp" },
    { value: "sfmc", label: "Saleforce Marketing Cloud" },
    {value: "Pardot",label: "Saleforce Marketing Cloud Account Engagement (Pardot)"},
    { value: "exportHtml", label: "None" }
  ];*/
  

  
  const ExportHtml = () => {
    if (EspOption === "none" || EspOption === "" || EspOption === "None_None") {
      seterrorFirst(true);
      sethelperTextFirst("Please select the Export Platform");
    } else if (
      EspOption.toLowerCase() === "marketo" &&
      ismultipleacc === true &&
      (espId === null || espId === "")
    ) {
      seterrorSecond(true);
      seterrorFirst(false);
      sethelperTextFirst("");
      sethelperTextSecond("Please select the Account");
    } else if (
      EspOption.toLowerCase() === "marketo" &&
      ismktImagesfolder === true &&
      (mktImagesFolderid === null || mktImagesFolderid === "")
    ) {
        seterrorThird(true);
      //sethelperTextThird("Please select the Images folder");
    } else {
        seterrorFirst(false);
        seterrorSecond(false);
        //seterrorThird(false);
        sethelperTextFirst("");
        sethelperTextSecond("");
        //sethelperTextThird("");
        var today = new Date()
        var cr_time = today.getHours() + '_' + today.getMinutes() + '_' + today.getSeconds();
        var version_id = emailId;
        var exportLink = `${window.globalConfig.apiOrigin}${window.globalConfig.subDir && window.globalConfig.subDir.length ? ('/' + window.globalConfig.subDir) : ''}`;
        if(controller === 'template')
        {
            if(mediafound === false){
           exportLink = exportLink+'/template/export_template/' + version_id+'?cr_time='+cr_time+'&exportType='+ExportOption+'&espPlatform='+EspOption+'&imgFolder='+mktImagesFolderid+'&PardotFormatting='+PardotFormatting+'&espId=';
        } else {
            exportLink = exportLink+'/template/export_template/' + version_id+'?cr_time='+cr_time+'&exportType='+ExportOption+'&espPlatform='+EspOption+'&imgFolder='+mktImagesFolderid+'&espId='+espId+'&PardotFormatting='+PardotFormatting;
        }
        } else {
        if(mediafound === false){
           exportLink = exportLink+'/campaigns/export_template/' + version_id+'?cr_time='+cr_time+'&exportType='+ExportOption+'&espPlatform='+EspOption+'&imgFolder='+mktImagesFolderid+'&PardotFormatting='+PardotFormatting+'&espId=';
        } else {
            exportLink = exportLink+'/campaigns/export_template/' + version_id+'?cr_time='+cr_time+'&exportType='+ExportOption+'&espPlatform='+EspOption+'&imgFolder='+mktImagesFolderid+'&espId='+espId+'&PardotFormatting='+PardotFormatting;
        }
        }
        Closeaction();
        window.location.href = exportLink;
        //setEspOption('None_None');
        //onTextBoxSelect(exPortvalues);
    }
  };
  
  const ExportContent = (iframecontroller:any) => {
    if (EspOption === "none" || EspOption === "" || EspOption === "None_None") {
      seterrorFirst(true);
      sethelperTextFirst("Please select the Export Platform");
    } else if (
      EspOption.toLowerCase() === "marketo" &&
      ismultipleacc === true &&
      (espId === null || espId === "")
    ) {
      seterrorSecond(true);
      seterrorFirst(false);
      sethelperTextFirst("");
      sethelperTextSecond("Please select the Account");
    } else if (
      EspOption.toLowerCase() === "marketo" &&
      ismktImagesfolder === true &&
      (mktImagesFolderid === null || mktImagesFolderid === "")
    ) {
      seterrorThird(true);
      //seterrorFirst(false);
      //sethelperTextFirst('');
      //sethelperTextThird("Please select the Images folder");
    } else {
      //seterrorFirst(false);
      //sethelperText("");
      //setEspOption(EspOption);
      seterrorFirst(false);
      seterrorSecond(false);
      seterrorThird(false);
      sethelperTextFirst("");
      sethelperTextSecond("");
      //sethelperTextThird("");
      //alert(espId);
      var exPortvalues =
        ExportOption + "_" + EspOption + "_" + mktImagesFolderid + "_" + espId+"_"+PardotFormatting+'_'+iframecontroller;
      onTextBoxSelect(exPortvalues);
      
    }
    //alert();
    //setEspOption('None_None');
  };

  const closeContent = () => {
    onTextBoxSelect("close");
  };

  const handleAutoCompleteChange = (e: any, Dropdownvalue: any) => {
      
      //sethostingmsg('Configure your export package below by selecting your export platform and how you would like images hosted.');
        seterrorThird(false);
    if (Dropdownvalue !== null) {
        var baseValue = Dropdownvalue.value.split('_');
        setEspOption(baseValue[1]);
        setProviderId(baseValue[0]);
        setradiostatus(false);
        seterrorFirst(false);
        sethelperTextFirst("");
        setmailchimpenableaccDropdown(false);
        setenableaccDropdown(false);
        setenableImagesDropdown(false);
        if(baseValue[1].toLowerCase() === "pardot")
        {
            setpardotacc(true);
            setenableImagesDropdown(false);
            setenableaccDropdown(false);
            setmailchimpenableaccDropdown(false);

        } else {
            setpardotacc(false);
            setPardotaccDropdown(false);
        }
        
      
    }
      setlocalhostingOption(true);
      sethostingOption(false);
  };

  const handleloadImages = (e: any, Dropdownvalue: any) => {
       setbuttondisable(true);
    if (Dropdownvalue !== null) {
      setImgisdisplay(true);
      var FormData = require("form-data");
      var data = new FormData();
      data.append("espId", Dropdownvalue.id);
      setespId(Dropdownvalue.id);
      AxiosInstance.post("api/v1/Integration/marketo_imagesfolders", data).then(
        (response: AxiosResponse) => {
          if (response) {
            if (response.data.success===true ) {
                setbuttondisable(false);
                setmediafound(true);
              setloadImagesfolder(response.data.folders[0]);
              setenableImagesDropdown(true);
              setImgisdisplay(false);
              setismktImagesfolder(true);

              let data: any[] = [0];
              let parentId: any = "";
              const expdParent = (child: any, folderId: any) => {
                const expd = (child: any) => {
                  child.forEach((item: any) => {
                    if (item.id === folderId) {
                      data.push(String(item.parent_id));
                      var parentId = item.parent_id;
                      expdParent(loadImagesfolder.childes, parentId);
                      return;
                    }
                    if (item.childes) {
                        // eslint-disable-next-line 
                      expd(item.childes);
                    }
                  });
                };
                //expd(child);
              };
              expdParent(loadImagesfolder.childes, parentId);
              setExpanded(data);
            } else {
                //setEspOption("");
                setbuttondisable(false);
                setmediafound(false);
                setImgisdisplay(false);
                setradiostatus(true);
                setmailchimpenableaccDropdown(false);
                setenableaccDropdown(false);
                setenableImagesDropdown(false); 
              
            }
          } else {
              alert('herer');
          }
        }
      );
      seterrorSecond(false);
      sethelperTextSecond("");
    }
   
  };



  const handlemailchimp = (e: any, Dropdownvalue: any) => {
    if (Dropdownvalue !== null) {
      setespId(Dropdownvalue.id);
    }
  };
  
  const handlePardot = (e: any, Dropdownvalue: any) => {
    if (Dropdownvalue !== null) {
        setespId(Dropdownvalue.id);
      var FormData = require("form-data");
        var data = new FormData();
        data.append("pc_id", Dropdownvalue.id);
        AxiosInstance.post("api/v1/Integration/getpardotData", data).then(
          (response: AxiosResponse) => {
              setPardotFormatting(response.data.data.pardot_formatting);
              if(response.data.data.pardot_formatting === 'PML')
              {
                  setpmlstatus(true);
                  sethmlstatus(false);
                  setpardotacc(false);
              } else if(response.data.data.pardot_formatting === 'HML') {
                  sethmlstatus(true);
                  setpmlstatus(false);
                  setpardotacc(false);
              } else {
                  setpardotacc(false);
              }
          }
        );
    }
  };
  

  const handleChangeExportOption = (event: any) => {
    setExportOption(event.target.value);
    if (event.target.value === "1") {
        setismktImagesfolder(false);
        setenableImagesDropdown(false);
        setImgisdisplay(false);
        setlocalhostingOption(true);
        seterrorThird(false);
        setismultipleacc(false);
        setespId('');
        //seterrorSecond(false);
      sethostingOption(false);
      setmailchimpenableaccDropdown(false);
      setPardotaccDropdown(false);
      setenableaccDropdown(false);
      if(EspOption.toLowerCase() === "pardot")
      {
          setpardotacc(true);
      }
      
      
    } else {
      if (EspOption.toLowerCase() === "marketo") {
           setpardotacc(false);
        var FormData = require("form-data");
        var data = new FormData();
        data.append("p_name", EspOption);
        data.append("ProviderId", ProviderId);
        AxiosInstance.post("api/v1/Integration/all_accounts", data).then(
          (response: AxiosResponse) => {
            if (response && response.data.data.email_providers.length > 0) {
              setisdisplay(true);
              setloadespaccounts(response.data.data.email_providers);
              setismultipleacc(true);
              setenableaccDropdown(true);
              setisdisplay(false);
            } else {
              setismultipleacc(false);
              setismktImagesfolder(false);
              setisdisplay(false);
              //sethostingOption(false);
              setinputelementstatus(false);
              //sethostingmsg('Host all uploaded images Option is disabled as there is not any active marketo account found  please check the setting integration tab for the connected accounts.you can continue with the selected option.');
              //setlocalhostingOption(true);
              //setradiostatus(true);
              setExportOption('3');
            }
          }
        );
      }
      else if (EspOption.toLowerCase() === "pardot") {
          // eslint-disable-next-line 
        var FormData = require("form-data");
        // eslint-disable-next-line 
        var data = new FormData();
        data.append("p_name", 'pardot');
        data.append("ProviderId", ProviderId);
        AxiosInstance.post("api/v1/Integration/all_accounts", data).then(
          (response: AxiosResponse) => {
            if (response && response.data.data.email_providers.length > 0) {
              setloadespaccounts(response.data.data.email_providers);
              setPardotaccDropdown(true);
            } else {
                
                setpmlstatus(true);
            } 
          }
        );
      }
      
      else if (EspOption.toLowerCase() === "mailchimp") {
           setpardotacc(false);
          setenableaccDropdown(false);
          setenableImagesDropdown(false);
          // eslint-disable-next-line 
        var FormData = require("form-data");
        // eslint-disable-next-line 
        var data = new FormData();
        data.append("p_name", EspOption);
        AxiosInstance.post("api/v1/Integration/all_accounts", data).then(
          (response: AxiosResponse) => {
            if (response && response.data.data.email_providers.length > 0) {
              setisdisplay(true);
              //setloadespaccounts([]);
              setloadespaccounts(response.data.data.email_providers);
              setmailchimpenableaccDropdown(true);
              setisdisplay(false);
            } else {
              setisdisplay(false);
            }
          }
        );
      } else {
          setenableaccDropdown(false);
          setenableImagesDropdown(false);
      }

      sethostingOption(true);
      setlocalhostingOption(false);
      setpardotacc(false);
      
    }
  };
     useEffect(() => {
        if(getParam('controller')!==null)
        {
        var controllervalue: any = null;
        if(isIframe === true && getParam('controller')!==null)
        {
            controllervalue = getParam('controller')
            setiframecontroller(controllervalue);
        }
        
        }
        
            setTimeout(async () => {
            if(EmailProviders.length<=0 && controller==='template')
            {
                    setinputelementstatus(false);
                    //setradiostatus(true)
                    //setlocalhostingOption(true);
                    setEspOption('marketo');
            }
            }, 300);
            if(controller ==='template' || getParam('controller') ==='template')
            {
                setEspOption('marketo');
                //setdefaultmsg('Configure your export package by selecting below options.');
            }
            
        
       // eslint-disable-next-line 
  }, [isIframe]);
  return (
    <Box className={classes.componentHeader}>
      <Box>
        <Typography variant="h5">Export HTML</Typography>
         {(inputelementstatus === false) ? (
           <Typography style={{ marginTop: 30, marginBottom: 30 }}>
               Configure your export package below by selecting your export platform and how you would like images hosted.
        </Typography>
        ) : (
          <Typography style={{ marginTop: 30, marginBottom: 30 }}>
             Configure your export package below by selecting your export platform and how you would like images hosted.
        </Typography>
        )}
        {mediafound === false && (
        <Typography style={{ marginTop: 10, marginBottom: 20 }}>
          There is not any media folder found for the selected account please Export with the first option
        </Typography>
        )}
        
        {isIframe === true && controller!=='template' && iframecontroller!=='template' && (
       <Autocomplete
          defaultValue={controller==='template' ? optionsarrBlank:optionsarr}
          id="combo-box-demo"
          options={allEmailProviders}
          onClose={() => {
            console.log("Close");
          }}
          onChange={handleAutoCompleteChange}
          getOptionLabel={(allEmailProviders) => allEmailProviders.label}
          renderInput={(params) => (
            <TextField
              error={errorFirst}
              helperText={helperTextFirst}
              {...params}
              label="Export Platform"
              placeholder="Export Platform"
              variant="outlined"
            />
          )}
        />
        )}
        
        
        {isIframe === false && controller!=='template' && iframecontroller!=='template' && (
       <Autocomplete
          id="combo-box-demo"
          defaultValue={controller==='template' ? { label: "", value: '' }:{ label: "None", value: 'None_None' }}
          options={EmailProviders}
          onClose={() => {
            console.log("Close");
          }}
          onChange={handleAutoCompleteChange}
          getOptionLabel={(EmailProviders) => EmailProviders.label}
          renderInput={(params) => (
            <TextField
              error={errorFirst}
              helperText={helperTextFirst}
              {...params}
              label="Export Platform"
              placeholder="Export Platform"
              variant="outlined"
            />
          )}
        />
        )}
        
        
        
        <Box>
          <RadioGroup
            onChange={handleChangeExportOption}
            style={{ marginTop: 30 }}
            aria-label="image"
            name="image"
          >
            <FormControlLabel
              value="1"
              checked={localhostingOption}
              control={<Radio color="primary" />}
              label="Export all images as part of the export package"
            />
            <FormControlLabel
              value="0"
              checked={hostingOption}
              disabled={radiostatus}
              control={<Radio color="primary" />}
              label="Host all uploaded images and preserve all linked images"
            />
          </RadioGroup>
        </Box>

        {mailchimpenableaccDropdown === true ? (
          <Autocomplete
            id="combo-box-demo"
            style={{ marginTop: "20px" }}
            options={loadespaccounts}
            onChange={handlemailchimp}
            getOptionLabel={(loadespaccounts) => loadespaccounts.label}
            renderInput={(params) => (
              <TextField
                error={errorSecond}
                helperText={helperTextSecond}
                {...params}
                label="Export Account"
                placeholder="Export Account"
                variant="outlined"
              />
            )}
          />
        ) : (
          <CircularProgress
            style={{
              marginTop: 20,
              width: 18,
              height: 18,
              color: "rgba(0, 0, 0, 0.26)",
              display: mailchimpisdisplay ? "block" : "none",
            }}
          />
        )}
        
        {PardotaccDropdown === true ? (
          <Autocomplete
           disableClearable
            id="combo-box-demo"
            style={{ marginTop: "20px" }}
            options={loadespaccounts}
            onChange={handlePardot}
            getOptionLabel={(loadespaccounts) => loadespaccounts.label}
            renderInput={(params) => (
              <TextField
                error={errorSecond}
                helperText={helperTextSecond}
                {...params}
                label="Export Account"
                placeholder="Export Account"
                variant="outlined"
              />
            )}
          />
        ) : (
          <CircularProgress
            style={{
              marginTop: 20,
              width: 18,
              height: 18,
              color: "rgba(0, 0, 0, 0.26)",
              display: mailchimpisdisplay ? "block" : "none",
            }}
          />
        )}

        {enableaccDropdown === true ? (
          <Autocomplete
           disableClearable 
            id="combo-box-demo"
            style={{ marginTop: "20px" }}
            options={loadespaccounts}
            onChange={handleloadImages}
            getOptionLabel={(loadespaccounts) => loadespaccounts.label}
            renderInput={(params) => (
              <TextField
                error={errorSecond}
                helperText={helperTextSecond}
                {...params}
                label="Export Account"
                placeholder="Export Account"
                variant="outlined"
              />
            )}
          />
        ) : (
          <CircularProgress
            style={{
              marginTop: 20,
              width: 18,
              height: 18,
              color: "rgba(0, 0, 0, 0.26)",
              display: isdisplay ? "block" : "none",
            }}
          />
        )}

        
        {enableImagesDropdown === true ? (
        <div className={`${errorThird ? classes.treeContainerError : classes.treeContainer}`}>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
          >
            <Tree
              fileTreeData={loadImagesfolder}
              treeName="Media folders"
              parrentId={parrentId}
              closeDropDownFolder={closeDropDownFolder}
            ></Tree>
          </TreeView>
        </div>
        ) : (
          <CircularProgress
            style={{
              marginTop: 20,
              width: 18,
              height: 18,
              color: "rgba(0, 0, 0, 0.26)",
              display: Imgisdisplay ? "block" : "none",
            }}
          />
        )}
        
        {errorThird === true && (
        <Typography className={classes.imgError}>
          Please select the Media folder
        </Typography>
        )}
        
        {pardotacc === true && (
            <Box className={classes.boxRow}>
            <Typography variant="subtitle2" style={{
            float:"left",
            width:"140px",
            marginTop: "10px",
            }} className={classes.InputLabel}>
            Formatting
            </Typography>
            <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="pardot_formatting"
            style={{
            marginTop: "9px",
            }}
            onChange={handleRadioToggle}
            >
            <FormControlLabel  checked = {pmlstatus} value="PML" control={<Radio />} label="PML" />
            <FormControlLabel  checked = {hmlstatus} value="HML" control={<Radio />} label="HML" />

            </RadioGroup>
            </Box>
             )}

        
        <Box className={classes.gptFooter}>
          
          {isIframe === true ? (
          <Button
            onClick={() => {
              closeContent();
            }}
            size="large"
            style={{ opacity: "0.6", marginRight: 16 }}
          >
            CANCEL
          </Button>
        ) : (
        <Button
            onClick={() => {
              handleClose();
            }}
            size="large"
            style={{ opacity: "0.6", marginRight: 16 }}
          >
            CANCEL
          </Button>
        )}
          
          
          {isIframe === true ? (
          <Button
          disabled={buttondisable}
            variant="contained"
            size="large"
            color="primary"
            className={classes.insertbut}
            onClick={() => {
              ExportContent(iframecontroller);
            }}
          >
            EXPORT
          </Button>
        ) : (
         <Button
         disabled={buttondisable}
            variant="contained"
            size="large"
            color="primary"
            className={classes.insertbut}
            onClick={() => {
              ExportHtml();
            }}
          >
            EXPORT
          </Button>
        )}
        </Box>
      </Box>
    </Box>
  );
};

export default ExportHtml;
