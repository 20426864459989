let colorsList: {key:string,name:string,value:string,list_default:string[]}[] = [
  {key:"colour_1" , name:"Colour 1" , value:"#DFDFDF", list_default:["email_color"]},
  {key:"colour_2" , name:"Colour 2" , value:"#FFFFFF", list_default:["primary_color","button_text_color"]},
  {key:"colour_3" , name:"Colour 3" , value:"#EBF2FF", list_default:["secondary_color"]},
  {key:"colour_4" , name:"Colour 4" , value:"#172B4E", list_default:["hedline_color", "text_color"]},
  {key:"colour_5" , name:"Colour 5" , value:"#0B6ADC", list_default:["link_color", "button_background_color","social_accent_color"]},
  // {key:"colour_5" , name:"Colour 5" , value:"#172B4E"},
  // {key:"colour_7" , name:"Colour 7" , value:"#0B6ADC"},
  // {key:"colour_8" , name:"Colour 8" , value:"#FFFFFF"},
];

export let DefaultStyleGuide: {[key: string]: any} = {
  is_dark: 0,
  email_color:"#dfdfdf",
  primary_color:"#ffffff",
  secondary_color:"#EBF2FF",   
  hedline_color:"#172B4E",
  text_color:"#172B4E", 
  link_color:"#0B6ADC",  
  button_background_color:"#0B6ADC",
  button_text_color:"#ffffff",
  social_accent_color:"#0B6ADC",
  primary_font:"Helvetica",
  headline_font_h1:"Helvetica",
  headline_font_h2:"Helvetica",
  headline_font_h3:"Helvetica",
  headline_font_h4:"Helvetica",
  headline_name_h1:"Heading 1",
  headline_name_h2:"Heading 2",
  headline_name_h3:"Heading 3",
  headline_name_h4:"Heading 4",
  button_font:"Helvetica",
  headline_size_h1: "48",
  headline_size_h2: "34",
  headline_size_h3: "24",
  headline_size_h4: "20",
  headline_lineheight_h1:"58",
  headline_lineheight_h2:"40",
  headline_lineheight_h3:"28",
  headline_lineheight_h4:"24",
  headline_spaceafter_h1:"20",
  headline_spaceafter_h2:"20",
  headline_spaceafter_h3:"20",
  headline_spaceafter_h4:"20",
  headline_spacebefore_h1:"0",
  headline_spacebefore_h2:"0",
  headline_spacebefore_h3:"0",
  headline_spacebefore_h4:"0",
  headline_letterspacing_h1:"0",
  headline_letterspacing_h2:"0",
  headline_letterspacing_h3:"0",
  headline_letterspacing_h4:"0",
  headline_weight_nb_h1:"700",
  headline_weight_nb_h2:"700",
  headline_weight_nb_h3:"700",
  headline_weight_nb_h4:"700",
  headline_weight_h1:"bold",
  headline_weight_h2:"bold",
  headline_weight_h3:"bold",
  headline_weight_h4:"bold",
  text_font:"Helvetica",
  text_font_body1:"Helvetica",
  text_font_body2:"Helvetica",
  text_font_legal:"Helvetica",
  text_size_body1:"16",
  text_size_body2:"14",
  text_size_legal:"12",
  text_lineheight_body1:"26",
  text_lineheight_body2:"22",
  text_lineheight_legal:"18",
  text_spaceafter_body1:"20",
  text_spaceafter_body2:"20",
  text_spaceafter_legal:"10",
  text_name_body1:"Body 1",
  text_name_body2:"Body 2",
  text_name_legal:"Legal",
  text_spacebefore_body1:"0",
  text_spacebefore_body2:"0",
  text_spacebefore_legal:"0",
  text_letterspacing_body1:"0",
  text_letterspacing_body2:"0",
  text_letterspacing_legal:"0",
  text_weight_nb_body1:"400",
  text_weight_nb_body2:"400",
  text_weight_nb_legal:"400",
  text_weight_body1:"normal",
  text_weight_body2:"normal",
  text_weight_legal:"normal",
  button_radius:"24",
  button_radius_switch:false,
  button_text_size:"16",
  button_text_lineheight:"48",
  button_text_weight:"bold",
  button_text_weight_nb:"700",
  button_text_letterspacing:"0",
  button_text_fixed_lineheight:"24",
  button_padding_left:"20",
  button_padding_right:"20",
  button_padding_top:"12",
  button_padding_bottom:"12",
  main_color_pallet: colorsList,
  google_font: {family:"", css_link:""},
  custom_font: { family:"" , RegularItalic : "", BoldNormal : "", BoldItalic : ""},
  layout_style : "classic",
  layout_grid_swicth : false,
  layout_grid_margins:"10",
  layout_grid_columns:"30",
  layout_grid_gutters:"20",
  layout_grid_width:"640",
  layout_padding_left:"0",
  layout_padding_top:"0",
  layout_padding_bottom:"0",
  layout_padding_right:"0",
  };


  export const DefaultBoldList = [
    "100",
    "200",
    "300",
    "400",
    "500",
    "600",
    "700",
    "800",
    "900",
 ];

 export const DefaultFontStyleList = [
  "normal",
  "italic",
];

 export const DefaultCustomNameList = [
  "headline_custom_font",
  "headline_custom_h1",
  "headline_custom_h2",
  "headline_custom_h3",
  "headline_custom_h4",
  "body_custom_font",
  "text_custom_body1",
  "text_custom_body2",
  "text_custom_legal",
  "cta_custom_font",
];