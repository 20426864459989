import React, { memo, useEffect, useState }  from "react";
import Typography from "@material-ui/core/Typography";
import { Box, Switch, TextField } from "@material-ui/core";
import { useStyles } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";
import { addField } from "../../../../../redux/actions/styleGuideActions";
import { AutoSave } from "../../StyleGuideAutosave";
import { DefaultStyleGuide } from "../../utilities/DefaultStyleGuide";
import TextFiledNumber from "../Typography/TextFiledNumber";
interface Props {}

const LayoutGrid: React.FC<Props> = () => {
  const classes = useStyles();
  const state = useSelector((state: any) => state.styleGuide);
  const dispatch = useDispatch();
  const [switchLayoutGrid, setSwitchLayoutGrid] = useState(false);
  const [layoutGridWidth, setLayoutGridWidth] = useState(state?.layout_grid_width | DefaultStyleGuide.layout_grid_width);

  const handleChangeLayoutGrid = (event:any) => {
    setSwitchLayoutGrid(event.target.checked);    
    dispatch(addField("layout_grid_swicth", event.target.checked));
    dispatch(addField("layout_grid_margins", DefaultStyleGuide.layout_grid_margins));
    dispatch(addField("layout_grid_columns", DefaultStyleGuide.layout_grid_columns));
    dispatch(addField("layout_grid_gutters", DefaultStyleGuide.layout_grid_gutters));
    AutoSave();
  };


  useEffect(()=>{
    if(state?.layout_grid_swicth){
      setSwitchLayoutGrid(true);
    }
    // eslint-disable-next-line
  },[state?.layout_grid_swicth]);

  useEffect(()=>{
    if(state?.layout_grid_margins && state?.layout_grid_columns && state?.layout_grid_gutters){
      setLayoutGridWidth( state?.layout_grid_margins*2 + state?.layout_grid_gutters*13 + state?.layout_grid_columns*12);
      dispatch(addField("layout_grid_width", state?.layout_grid_margins*2 + state?.layout_grid_gutters*13 + state?.layout_grid_columns*12 ));
      // AutoSave();
    }
    // eslint-disable-next-line
  },[state?.layout_grid_margins, state?.layout_grid_columns , state?.layout_grid_gutters]);


  return (
        <Box display="flex" flexDirection="column" className={`${classes.section1new}`}>
          <Box className={classes.switchLayoutGrid}>
           <Box flex="1">
              <Typography className={`${classes.heading} ${classes.layoutGridTitle}`} variant="h6"> 
                Grid 
              </Typography>

              <Typography variant="caption" className={classes.typographyText}>
                Enable custom grid controls
              </Typography>
            </Box>
            <Switch
              checked={switchLayoutGrid}
              onChange={handleChangeLayoutGrid}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Box>
          {(switchLayoutGrid === true) && (<Box>
            <Box className={classes.alertLayoutGrid}>
              <Alert severity="info">
              We recommend using our default 640px grid structure to ensure maximum accessibility across different email client.
              </Alert>
            </Box>
            <Box className={classes.layoutGridInputContainer} style={{display:"flex"}}>   
              <Box className={classes.layoutGridMargins}>
                <TextFiledNumber fieldName="layout_grid_margins" title="Margins" min={0} max={40} />
              </Box>
              <Box className={classes.layoutGridcolumns}>
                <TextFiledNumber fieldName="layout_grid_columns" title="columns" min={0} max={50} />
              </Box>  
              <Box className={classes.layoutGridGutters}>
                <TextFiledNumber fieldName="layout_grid_gutters" title="Gutters" min={0} max={40} />
              </Box>
              <Box className={classes.layoutGridEqual}>
                <Typography variant="subtitle2" className={classes.InputLabel1}>
                  &nbsp;
                </Typography>
                <svg version="1.1" width="24" height="24" viewBox="0 0 24 24">
                 <path d="M19,10H5V8H19V10M19,16H5V14H19V16Z" />
                </svg>
              </Box> 
              <Box className={classes.layoutGridWidth}>
                <Typography variant="subtitle2" className={classes.InputLabel1}>
                  Width
                </Typography>
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  defaultValue=""
                  fullWidth
                  value={layoutGridWidth}
                  className={classes.inputNumber}
                />
              </Box>     
            </Box>
          </Box>)}
        </Box>
  );
};

export default memo(LayoutGrid);