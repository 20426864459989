import React, { useState, useEffect } from "react";
import Screenshot from '../../../../../../utils/Screenshot';
import Skeleton from "@material-ui/lab/Skeleton";

interface Props {
  thumb: string;
  classes: string;
  classeswave: string;
  tempId: string;
}

const TemplateImage: React.FC<Props> = ({ thumb, classes, classeswave, tempId }) => {
	console.log('thumb==',thumb);
  const [screenshot, setScreenshot] = useState(thumb);
  
  const loadScreenShot = async () => {
    const screenshot = await new Screenshot(
      tempId, 
      "template", 
      600, 
      `template/template_screenshot/${tempId}?mute=true`
    ).load();
    setScreenshot(screenshot); 
	console.log('screenshot==',screenshot);
  };

  useEffect(() => {
    loadScreenShot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempId]);

   
  return (
          <>
             {screenshot != null ? (
               <img
				id={tempId}
				src={screenshot}
				className={classes}
				title=""
				alt=""
			  />
              ) : (
               <Skeleton
                  animation="wave"
                  variant="rect"
                  className={classeswave}
                />
              )}
              
          </>
  );
};

export default TemplateImage;
