import {Box, Container, createMuiTheme, Paper, Toolbar} from "@material-ui/core";
import React, {useState, useEffect} from "react";
// import StyleGuide from "../../onboarding/styleguide/StyleGuidePage";
import TrackingPage from "../../onboarding/tracking/TrackingPage";
import NewespIntegration from "../../onboarding/espIntegrations/NewespIntegration";
import {makeStyles, Theme} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// import {useState} from "react";
import Typography from "@material-ui/core/Typography";
import UsersTab from "./UsersTab/Index";
// import StyleGuideTab from "../../onboarding/styleguide/Index";
import TrackingTab from "../../onboarding/tracking/Index";
import EspTab from "../../onboarding/espIntegrations/Index";
// import Billing from "./billing/Billing";
// import Subscription from "./billing/Subscription";
// import Invoice from "./billing/Invoice";
import {useDispatch, useSelector} from "react-redux";
import {updateActiveTabs} from "../../../redux/actions/TabManagerActions";
import {isMarketer, isNewStyle, isNewtracking,iSuperAdmin,isTeamDetails, isTrial, teamEnabled} from "../../../../utils/AuthUtility";
import { Button } from "@material-ui/core";
import Teams from "../../onboarding/teams/Index";
import TeamPage from "../../onboarding/teams/TeamPage";
import AxiosInstance from "../../../../utils/AxiosInstance";
import { AxiosResponse } from "axios";
import TrialPopup from "../../../../utils/TrialPopup";
import { removedata } from "../../../redux/actions/styleGuideActions";
import { updateFieldData } from "../../../redux/actions/EspManagerActions";
import AccessPopup from "../../../../utils/AccessPopup";
import EditespIntegration from "../../onboarding/espIntegrations/EditespIntegration";

/*import AxiosInstance from "../../../../utils/AxiosInstance";*/
// eslint-disable-next-line
const outerTheme = createMuiTheme({
	overrides: {
		MuiOutlinedInput: {
			input: {
				padding: "12px 14px",
			},
		},
		MuiSelect: {
			selectMenu: {
				"&:focus": {
					backgroundColor: "white",
				},
			},
		},
		MuiInput: {
			underline: {
				370: {
					"&:before": {
						borderBottom: "white",
					},
					"&:after": {
						borderBottom: "white",
					},
					borderBottom: "white",
				},
			},
		},
	},
});

interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const {children, value, index, ...other} = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{/* {value === index && ( */}
			<Box width="100%">
				<div>{children}</div>
			</Box>
			{/* )} */}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme: Theme) => ({
	heading: {
		marginBottom: "20px",
		marginTop: "1rem",
		fontStyle: "normal",
		fontFamily: 'Poppins, sans-serif',
		lineHeight: "120%",
		fontWeight: 500,
		fontSize: "26px",
	},
    
	"@global": {
		"*::-webkit-scrollbar": {
			width: "0.2em",
		},
		"*::-webkit-scrollbar-track": {
			"-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
			"-webkit-border-radius": "10px",
		},
		"*::-webkit-scrollbar-thumb": {
			backgroundColor: "#C4C4C4",
			"-webkit-border-radius": "10px",
		},
	},
	hasTrialInfo:{

	},
	root: {
		width: "100%",
		display: "flex",
		paddingTop: 86,
        // paddingBottom:30,
		justifyContent: "center",
		background: " #f8f8f8", 
		"&$hasTrialInfo":{
			paddingTop: 143,
		  }
	},
	selectStyle: {
		width: "20px",
		color: "white",
		background: "white",
		"&:focus": {
			backgroundColor: "white",
		},
	},
	backgroundupgrade: {
		marginTop: "3.5rem",
		display: "flex",
		justifyContent: "center",
		padding: "0.3rem",
		background: "#444444",
	},
	freeTrail: {
		color: "#FFFFFF",
		padding: "5px",
		paddingRight: "20px",
	},
	menu: {
		paddingLeft: "31px",
		paddingRight: "31px",
		paddingTop: "8px",
		paddingBottom: "8px",
		fontSize: "14px",
		fontFamily: 'Poppins, sans-serif',
		backgroundColor: 'transparent',
		"&:hover": {
			backgroundColor: "transparent",
		},
		"&:focus": {
			backgroundColor: 'transparent',
		},
		"&.Mui-selected": {
			backgroundColor: 'transparent',
			"&:hover": {
				backgroundColor: 'transparent',
			}
		},
	},
	paper: {
		width: "152px",
		boxShadow: "0px 0px 6px rgba(18 19 19 0.22)",
		borderRadius: "8px",
	},
	container: {
		maxWidth: "1236px",
		backgroundColor: "#ffffff",
		padding:"unset",
		flex: "1"
	},
	appBarShadow: {
		// boxShadow:
		// 	"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
		// borderBottom: "1.02495px solid #E2E5E6",
		// borderRadius: "4.0998px",
		borderBottom: "1px solid #e0e0e0",
		backgroundColor: "white",
		boxShadow: "unset",
		borderRadius: "unset",
		borderTopRightRadius: "4px",
		borderTopLeftRadius: "4px",
	},
	tabContainer: {
		minHeight: 52,
		"& .MuiTab-textColorPrimary": {
			color: '#575757'
		},
		"& .MuiTab-textColorPrimary.Mui-selected": {
			color: '#057CFF',
			"& svg": {
				color: "#057CFF",
			},

		},
	},
	tabContent: {
		"& .MuiTab-wrapper": {
			justifyContent: 'center'
		},
		"& .MuiTab-wrapper > *:first-child": {
			marginBottom: '0px',
			marginLeft: '6px'
		},
		"& svg": {
			fontSize: '22px'
		}

	},
	buttonStyle: {
		textTransform: "none",
		fontWeight: 500,
		boxShadow: "none",
		"&:hover": {
		  background: "#0a6adc",
		  boxShadow: "none",
		},
	  },
	btnCancelNobelO: {
		border: "1px solid #969696",
		color: "#676767",
		borderRadius: 4,
		fontSize: 14,
		fontWeight: "normal",
		padding: "6px 22px",
		lineHeight: "unset",
		"&:hover": {
			background: "#969696",
			color: "#ffffff"
		}
	},
	btnCancelUnderline:{
		color: "#676767",
		textDecoration:"underline",
		background: "unset",
		border:"unset",
		fontSize: 14,
		fontWeight: "normal",
		lineHeight: "unset",
		"&:hover": {
			background: "unset",
			textDecoration:"underline",
		}
	},
	outLineButtonContent: {
		border: "1px solid #057cff",
		background: "#ffffff",
		borderRadius: "4px",
		color: "#367BF5",
		height: "unset",
		lineHeight: "normal",
		padding: "6px 22px",
		boxShadow: "unset",
		"&:hover": {
			color: "#ffffff",
			background: "#367BF5",
		},
	},
	toolbar: {
        backgroundColor: "white",
        paddingLeft: 5,
        minHeight: "unset",
        paddingRight: 12,
    },
	titleh6: {
        fontSize: 18,
    },
    // buttonStyle: {
    //     textTransform: "none",
    //     fontWeight: 600,
    //     boxShadow: "none",
    //     "&:hover": {
    //     background: "#0a6adc",
    //     boxShadow: "none",
    //     },
    // },  

}));

interface Props {
	history: any;
}


  

const Settings: React.FC<Props> = ({history}) => {
	const dispatch = useDispatch();
	const [trialPopupStatus, setTrialPopupStatus] = useState<boolean>(false);
	const [accessPopupStatus, setAccessPopupStatus] = useState<boolean>(false);
	const [popupTitle, setPopupTitle] = useState("");
	const [integrationsEnabled, setIntegrationsEnabled] = useState<boolean>(false);

    // dispatch(addField("mainColorPallet", styleGuide?.mainColorPallet));

	/*const [value, setValue] = useState<undefined | number>(0);*/
	// const [subScription, setSubscription] = useState(false);
	// const [invoice, setInvoice] = useState(false);
	// const [billing, setBilling] = useState(true);
	/*const [choosePlan, setChoosePlan] = useState(false);*/
	/*const [activeSubscription, setActiveSubscription] = useState<any>({});*/
	/*let flag = false;*/
	/*const loadActiveSubscription = async () => {
		const response = await AxiosInstance.get('api/v1/billing/get_active_subscription');
		if (response?.data?.status) {
			setActiveSubscription(response?.data?.data);
		}
	};*/
	/*const level = loggedInUser?.user_detail?.level;*/
	// const role = loggedInUser?.user_detail?.role;
	
	const teamEnabledCheck = () => {
		if(teamEnabled())
			return '';
		else 
			return 'none';
	};
	
  	const reloadListing = () => {
       
        localStorage.removeItem('TrackingrowId');
        localStorage.removeItem("TrackingResID");
	    setTimeout(history.push('/settings/tracking'), 1000);
  };
  
  const reloadIntegration = () => {
       
        localStorage.removeItem('integration_type');
        localStorage.removeItem("editRId");
	    setTimeout(history.push('/settings/integrations'), 1000);
  };
  
	const tabManager = useSelector((state: any) => state.tabManager);
      let MsgContainer: HTMLDivElement | null = document.querySelector("#FlsMsgAlert");
	   const updateURL = (event: any) => {
           localStorage.removeItem('editRId');
		const tab = event?.currentTarget?.innerText
			?.toLowerCase()
			 .replaceAll(" ", "");
            
            if(MsgContainer )
            {
                 MsgContainer.setAttribute("style", "display:none;");
                 
            }
		dispatch(updateActiveTabs({fieldName: "activeChild", fieldValue: tab}));
		history.push(`/settings/${tab || ""}`);
	};
	/*useEffect(() => {
		loadActiveSubscription();
	}, []);*/
	const classes = useStyles();
	// eslint-disable-next-line
	const handleChoosePlan = () => {
		/*setChoosePlan(true);*/
		// setSubscription(false);
		// setInvoice(false);
		// setBilling(true);
		dispatch(
			updateActiveTabs({fieldName: "activeParent", fieldValue: "choosePlan"})
		);
	};

    const allStyleguide = () => {
		localStorage.removeItem('TrackingrowId');
        localStorage.removeItem("TrackingResID");
		history.push(`/settings/styleguide`);
	};

	const TeamDetails = () => {
		localStorage.removeItem('teamID');
		if(!isTrial()){
		   setTimeout(history.push('/settings/TeamDetails'), 1000);
		} else{
			setTrialPopupStatus(true);
		}
	};
	const getlistStylesCreateUser = () => {

		if(!isTrial()){
			AxiosInstance.get('api/v1/Settings/user').then((response: AxiosResponse) => {
				let data = response.data.data;
				let listData = data.usertable;
				let users = parseInt(listData.length);
				let max_users1=localStorage.getItem('max_users') as string;
				let max_users = parseInt(max_users1);
				if(users >=max_users && max_users!==0){
					setPopupTitle("User");
					setAccessPopupStatus(true);
				}else{
					AxiosInstance.get(`api/getStyleguidelisting/${localStorage.getItem("company_id")}`)
						.then((response: AxiosResponse) => {
						dispatch(updateActiveTabs({fieldName: "setUserStyleData", fieldValue: JSON.stringify(response.data.usertable)}));
						dispatch(updateActiveTabs({fieldName: "setUserEditRecord", fieldValue: ""}));
						dispatch(updateActiveTabs({fieldName: "AddNewUser", fieldValue: true}));
					});         
				}
				
			});
		           

		} else{
				setTrialPopupStatus(true);
			}
	};
	const newstracking = () => {
		if(!isTrial()){
		dispatch(removedata());
		
		 localStorage.removeItem('TrackingrowId');
		 localStorage.removeItem("TrackingResID");
		 setTimeout(history.push('/settings/Newtracking'), 5000);
		}else{
			setTrialPopupStatus(true);
		}
  	};

	const getEnabledIntegrations = () => {
		AxiosInstance.get(
							`api/getIntegrations/${localStorage.getItem("company_id")}`
						).then((response: AxiosResponse) => {
							if(response.data.usertable.length>0){
								setIntegrationsEnabled(false);
							}else{
								setIntegrationsEnabled(true);
							}
						});
	};

	const getIntegrations = () => {
        localStorage.removeItem('editRId');
		if(!isTrial()){
			AxiosInstance.get(
				`api/getEspData/${localStorage.getItem("company_id")}`
			  ).then((response: AxiosResponse) => {
					let data = response.data;
					let listData = data.usertable;
					let integrations = parseInt(listData.length);
					let max_integrations1=localStorage.getItem('max_integrations') as string;
					let max_integrations = parseInt(max_integrations1);
					if(integrations >=max_integrations && max_integrations!==0){
						setPopupTitle("Integration");
						setAccessPopupStatus(true);
					} else{
						AxiosInstance.get(
							`api/getIntegrations/${localStorage.getItem("company_id")}`
						).then((response: AxiosResponse) => {			
							dispatch(updateFieldData({fieldName: "esp_name", fieldValue: ''}));
							localStorage.removeItem("editRId");
							dispatch(updateActiveTabs({fieldName: "setIntegrationData", fieldValue: JSON.stringify(response.data.usertable)}));
							dispatch(updateActiveTabs({fieldName: "AddNewIntegration", fieldValue: true}));
							dispatch(updateActiveTabs({fieldName: "openIntegrationModule", fieldValue: true}));						
						});
					}
			  });
			
		} else{
			setTrialPopupStatus(true);
		}
	};

    useEffect(() => {
		getEnabledIntegrations();
	}, []);
    
	return (
		<>
			{/*{!subScription && (billing || invoice) && activeSubscription.trail === 1 && (*/}
			{/*  <div>*/}
			{/*    <Grid className={classes.backgroundupgrade}>*/}
			{/*      <Typography className={classes.freeTrail}>*/}
			{/*        Free Trial: {activeSubscription?.total_left || 0} exports left*/}
			{/*      </Typography>*/}
			{/*      <Box style={{ padding: "5px" }}>*/}
			{/*        <Button*/}
			{/*          variant="contained"*/}
			{/*          type="submit"*/}
			{/*          color="primary"*/}
			{/*          size="small"*/}
			{/*          onClick={handleChoosePlan}*/}
			{/*        >*/}
			{/*          Upgrade Now*/}
			{/*        </Button>*/}
			{/*      </Box>*/}
			{/*    </Grid>*/}
			{/*  </div>*/}
			{/*)}*/}
			<AccessPopup
				accessPopupStatus={accessPopupStatus}
				setAccessPopupStatus={setAccessPopupStatus}
				popupTitle={popupTitle}
			/>
			<TrialPopup
				trialPopupStatus={trialPopupStatus}		
				setTrialPopupStatus={setTrialPopupStatus}
			/>
			<Box className={`${classes.root} ${localStorage.getItem("trial_mode") === 'true' ? classes.hasTrialInfo: ""}`}>
				<Container className={classes.container}>
					{/* <Typography
						component="h1"
						align="left"
						className={classes.heading}
						variant="h5"
					>
						 Settings 
					</Typography> */}

					<Box border="1.02495px solid #E2E5E6" borderRadius="4.0998px">
					{(!isNewtracking() && !isTeamDetails()) &&(<AppBar
							color="inherit"
							position="static"
							className={classes.appBarShadow}
						>

							<Box display="flex" justifyContent="space-between">
								<Box display="flex">
									<Box
										display="flex"
										justifyContent="center"
										alignItems="center"
									>
										<Tabs
											value={getActiveTab(tabManager.activeChild)}
											indicatorColor="primary"
											textColor="primary"
											variant="fullWidth"
											aria-label="full width tabs example"
											className={classes.tabContainer}
											TabIndicatorProps={{
												style: {
													marginBottom: "-1px",
												},
											}}
										>
                                        {(!isNewStyle()) && (
											<Tab
												label="Users"
												style={{
													textTransform: "none",
													minWidth: "120px",
													height:66,
													fontWeight:"normal"
												}}
												{...a11yProps(0)}
												onClick={(e) => {
													// setBilling(false);
													// setSubscription(false);

													// setInvoice(false);
													updateURL(e);
												}}
                                        
                                        
											/>
										)}
											{/* {(!isNewStyle()) && (
											<Tab

												label="Style Guide"
												{...a11yProps(1)}
												style={{
													textTransform: "none", 
													minWidth: "132px",
													fontWeight:"normal"
											}}
												onClick={(e) => {
													setBilling(false);
													setSubscription(false);

													setInvoice(false);
													updateURL(e);
												}}

											/> */}
										{(!isNewStyle()) && (
											<Tab
												label="Teams"
												style={{
													textTransform: "none",
													minWidth: "120px",
													height:66,
													fontWeight:"normal",
													display:teamEnabledCheck()
													
												}}
												{...a11yProps(1)}
												onClick={(e) => {
													updateURL(e);
												}}
                                        
                                        
											/>
										)}
											{(!isNewStyle()) && (
								<Tab
									label="Integrations"
									{...a11yProps(2)}
									style={{
										textTransform: "none", 
										minWidth: "132px",
										fontWeight:"normal" 
								}}
									onClick={(e) => {
										// setBilling(false);
										// setInvoice(false);
										// setSubscription(false);



										updateURL(e);

										}}
									/>
						)}
						{(!isNewStyle()) && (<Tab
						label="Tracking"
						{...a11yProps(3)}
						style={{
						textTransform: "none", 
						minWidth: "132px",
						fontWeight:"normal"
						}}
						onClick={(e) => {
						// setBilling(false);
						// setSubscription(false);

						// setInvoice(false);
						updateURL(e);
						}}
						/>
						)}
						


						{/*<Tab*/}
						{/*  label="Marketplace"*/}
						{/*  {...a11yProps(3)}*/}
						{/*  style={{ textTransform: "none", minWidth: "132px" }}*/}
						{/*  onClick={(e) => {*/}
						{/*    setBilling(false);*/}
						{/*    setInvoice(false);*/}
						{/*    setSubscription(false);*/}
						{/*    updateURL(e);*/}
						{/*  }}*/}
						{/*/>*/}
						{/*{level === "0" && (*/}
						{/*  <Tab*/}
						{/*    label="Billing"*/}
						{/*    // classname={classes.tabContent}*/}
						{/*    className={classes.tabContent}*/}
						{/*    icon={*/}
						{/*      <ThemeProvider theme={outerTheme}>*/}
						{/*        <Select*/}
						{/*          labelId="demo-simple-select-label"*/}
						{/*          id="demo-simple-select"*/}
						{/*          className={classes.selectStyle}*/}
						{/*          disableUnderline={true}*/}
						{/*          IconComponent={ExpandMoreIcon}*/}
						{/*          MenuProps={{*/}
						{/*            anchorOrigin: {*/}
						{/*              vertical: 47,*/}
						{/*              horizontal: -98,*/}
						{/*            },*/}
						{/*            getContentAnchorEl: null,*/}
						{/*            classes: {*/}
						{/*              paper: classes.paper,*/}
						{/*            },*/}
						{/*          }}*/}
						{/*        >*/}
						{/*          <MenuItem*/}
						{/*            value={20}*/}
						{/*            className={classes.menu}*/}
						{/*            style={{*/}
						{/*              color: subScription ? "#057CFF" : "black",*/}
						{/*            }}*/}
						{/*            onClick={() => {*/}
						{/*              setSubscription(true);*/}
						{/*              setInvoice(false);*/}
						{/*              setBilling(false);*/}
						{/*              flag = true;*/}
						{/*              setTimeout(() => {*/}
						{/*                flag = false;*/}
						{/*              });*/}
						{/*            }}*/}
						{/*          >*/}
						{/*            Subscriptions*/}
						{/*          </MenuItem>*/}
						{/*          <MenuItem*/}
						{/*            className={classes.menu}*/}
						{/*            value={30}*/}
						{/*            style={{*/}
						{/*              color: invoice ? "#057CFF" : "black",*/}
						{/*            }}*/}
						{/*            onClick={() => {*/}
						{/*              setBilling(false);*/}
						{/*              setSubscription(false);*/}
						{/*              setInvoice(true);*/}
						{/*              flag = true;*/}
						{/*              setTimeout(() => {*/}
						{/*                flag = false;*/}
						{/*              });*/}
						{/*            }}*/}
						{/*          >*/}
						{/*            Invoices*/}
						{/*          </MenuItem>*/}
						{/*        </Select>*/}
						{/*      </ThemeProvider>*/}
						{/*    }*/}
						{/*    {...a11yProps(4)}*/}
						{/*    style={{ textTransform: "none", minWidth: "132px" }}*/}
						{/*    onClick={() => {*/}
						{/*      const a: any = {};*/}
						{/*      a.currentTarget = { innerText: "billing" };*/}
						{/*      updateURL(a);*/}

						{/*      if (!flag) {*/}
						{/*        setBilling(true);*/}
						{/*        setSubscription(false);*/}
						{/*        setInvoice(false);*/}
						{/*      }*/}
						{/*    }}*/}
						{/*  />*/}
						{/*)}*/}
						</Tabs>
						</Box>
						</Box>

						<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						>

							{(tabManager.activeChild === "users") && <Box display="flex" justifyContent="space-between" width="100%" style={{marginRight:12}}>								
								{!isMarketer() &&
								<Button
									className={classes.buttonStyle}
									variant="contained"
									color="primary"
									onClick={() => {
									getlistStylesCreateUser();
									
									}}
								>
									Add New User
								</Button>
								}
							</Box>}
							{(tabManager.activeChild === "teams") && <Toolbar className={classes.toolbar}>
							<Box display="flex" justifyContent="space-between" width="100%">
								<Typography
								variant="h6"
								id="tableTitle"
								component="div"
								className={classes.titleh6}
								></Typography>
								
								<Button
								className={classes.buttonStyle}
								variant="contained"
								color="primary"
								onClick={() => {TeamDetails();}}
								disabled={iSuperAdmin() ? false:true}
								>
								Add Team
								</Button>
								
							</Box>
							</Toolbar>}

							{(!isMarketer() && tabManager.activeChild === "integrations") && <Toolbar className={classes.toolbar}>
								<Box display="flex" justifyContent="space-between" width="100%">
									<Typography
									variant="h6"
									id="tableTitle"
									component="div"
									className={classes.titleh6}
									></Typography>
									<Button
									className={classes.buttonStyle}
									variant="contained"
									color="primary"
									onClick={() => {
										setTimeout(() => {
										getIntegrations();
										}, 50);
									}}
									disabled={integrationsEnabled}
									>
									Add Integration
									</Button>
								</Box>
								</Toolbar>
							}

							{(!isMarketer() && tabManager.activeChild === "tracking") && <Button
									style={{marginRight:12}}
								    className={classes.buttonStyle}
									variant="contained"
									color="primary"
									onClick={() => {
									newstracking();
									}}
								>
								Add Tracking
							</Button>}
                        
						<Typography id="FlsMsgAlert"
						style={{
						marginRight: "12px",
						fontSize: "14px",
						fontWeight: 600,
						display:"none",
						float:"left",
						marginTop:"5px",
						}}
						>
						</Typography> 

						<Typography style={{
						display:"none",paddingLeft:"10px"
						}} id="closeBut"><Button
						className={classes.outLineButtonContent}
						variant="contained"
						style={{
						marginLeft:10,
						marginRight: "12px",
						}}
						color="primary"
						onClick={() => {
							reloadListing();
						}}
						>
						Close
						</Button></Typography>
                        
                        
                        <Typography style={{
						display:"none",paddingLeft:"10px"
						}} id="EspcloseBut"><Button
						className={classes.outLineButtonContent}
						variant="contained"
						style={{
						marginLeft:10,
						marginRight: "12px",
						}}
						color="primary"
						onClick={() => {
							reloadIntegration();
						}}
						>
						Close
						</Button></Typography>
                        
                        

						{(isNewStyle()) && (
						<Button
						className={classes.outLineButtonContent}
						variant="contained"
						style={{
						marginLeft:10,
						marginRight: "12px",
						}}
						color="primary"
						onClick={() => {
							allStyleguide();
						}}
						>
						Close
						</Button>
						)}

						</Box>

						</Box>




						</AppBar>)}
						<TabPanel value={getActiveTab(tabManager.activeChild)} index={0}>
							<UsersTab history={history}/>
						</TabPanel>
						<TabPanel value={getActiveTab(tabManager.activeChild)} index={1}>
							<Teams history={history}/>
							
						</TabPanel>
						{/* <TabPanel
							value={getActiveTab(tabManager.activeChild)}
							index={1}
						>
							<Paper square>
								<StyleGuideTab history={history}/>
							</Paper>
						</TabPanel> */}
						<TabPanel
							value={getActiveTab(tabManager.activeChild)}
							index={2}
						>
							<Paper square style={{boxShadow:"unset"}}>
								<EspTab history={history}/>
							</Paper>
						</TabPanel>
                        
                        {/* <TabPanel
							value={getActiveTab(tabManager.activeChild)}
							index={5}
						>
							<Paper square>
								<StyleGuide isNotSettingsPage={false}/>
							</Paper>
						</TabPanel> */}
                        
                        <TabPanel
							value={getActiveTab(tabManager.activeChild)}
							index={7}
						>
							<Paper square style={{borderRadius:4}}>
								<TrackingPage isNotSettingsPage={false} history={history}/>
							</Paper>
						</TabPanel>
						
						<TabPanel
							value={getActiveTab(tabManager.activeChild)}
							index={10}
						>
							<Paper square style={{borderRadius:4,boxShadow:"unset"}}>
								<TeamPage history={history} />
							</Paper>
						</TabPanel>
						
						 <TabPanel
							value={getActiveTab(tabManager.activeChild)}
							index={9}
						>
							<Paper square style={{boxShadow:"unset"}}>
								<NewespIntegration isNotSettingsPage={false}/>
							</Paper>
						</TabPanel>
                        
                        
                         <TabPanel
							value={getActiveTab(tabManager.activeChild)}
							index={3}
						>
							<Paper square style={{boxShadow:"unset",borderRadius: 4}}>
								<TrackingTab history={history}/>
							</Paper>
						</TabPanel>
						
						
						<TabPanel
							value={getActiveTab(tabManager.activeChild)}
							index={8}
						>
							<Paper square style={{boxShadow:"unset"}}>
								<EspTab history={history}/>
							</Paper>
						</TabPanel>
                        <TabPanel
							value={getActiveTab(tabManager.activeChild)}
							index={11}
						>
							<Paper square style={{boxShadow:"unset"}}>
								<EditespIntegration isNotSettingsPage={false}/>
							</Paper>
						</TabPanel>
                        
                        
                        
                        
						{/* {(!isPowerUser() || !isMarketer()) && (
							<>
								<TabPanel
									value={getActiveTab(tabManager.activeChild)}
									index={4}
								>
									<ThemeProvider theme={outerTheme}>
										<Paper square>
											{billing && <Billing upgradePlan={handleChoosePlan}/>}
											{subScription && (
												<Subscription upgradePlan={handleChoosePlan}/>
											)}
											{invoice && <Invoice/>}
										</Paper>
									</ThemeProvider>
								</TabPanel>
								<TabPanel
									value={getActiveTab(tabManager.activeChild)}
									index={6}
								>
									<Paper square>
									<MarketPlace />
									</Paper>
								</TabPanel>
							</>
						)} */}
					</Box>
				</Container>
			</Box>
		</>
	);
};

const getActiveTab = (activeParent: any) => {
   
	return activeParent === "users"
		? 0
		: activeParent === "susers"
			? 0
		: activeParent === "sintegrations"
			? 2
		: activeParent === "teams"
			? 1
			: activeParent === "steams"
				? 1
				: activeParent === "integrations"
					? 2
					: activeParent === "marketplace"
						? 6
						: activeParent === "NewStyleGuide"
						? 5
						: activeParent === "tracking"
						? 3
						
						: activeParent === "Newtracking"
						? 7
						: activeParent === "NewIntegration"
						? 9
                        : activeParent === "editIntegration"
						? 11
						: activeParent === "billing"
							? 4
							: activeParent === "TeamDetails"
								? 10
							: 0;
	};
export default Settings;
