import AxiosInstance from './AxiosInstance';
var FormData = require("form-data");

interface ScreenshotProp {
  id: string, 
  type: string, 
  vw: number, 
  url: string
};

export default class Screenshot {
  private item: ScreenshotProp;

  constructor(
    id: string, 
    type: string, 
    vw: number, 
    url: string
  ) {
    this.item = {
      id: id,
      type: type,
      vw: vw,
      url: url,
    };
  }

  async load(): Promise<string> {
    const response = await this.getCachedScreenShot();
    if (response.data.cached.length) {
      return response.data.cached[0].path;
    }
    return await this.generateScreenshot();
  }

  private async getCachedScreenShot() {
    var data = new FormData();
    data.append("items", JSON.stringify([this.item]) );
    return await AxiosInstance.post("ScreenshotAPI/getCashedScreenshot", data);
  }

  private async generateScreenshot(): Promise<string> {
    var data = new FormData();
    data.append("email_id",this.item.id);
    data.append("email_type",this.item.type);
    data.append("vw",this.item.vw);
    data.append("url",this.item.url);

    const newScreenshot = await AxiosInstance.post("ScreenshotAPI/getScreenshot", data);
    return newScreenshot.data.data;
  }
}