import React, { Dispatch, SetStateAction } from "react";
import {
  Dialog,
  TextField,
  Button,
  Box,
  MenuItem,
  DialogContent,
  DialogActions,
  DialogTitle,
  InputAdornment,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import FolderIcon from "@material-ui/icons/Folder";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import { toast } from "react-toastify";
import {AxiosResponse} from "axios";
import {getUserFromToken} from "../../../../../utils/AuthUtility";
import moment from "moment";

    interface Props {
      open: any;
      setOpen: Dispatch<SetStateAction<boolean>>;
      history: any;
      openFlag:any;
      addTemplatefor:any;
    }

interface RenderTree {
  id: string;
  name?: string;
  type?: string;
  folder_name?: string;
  parent_id?: string;
  company_id?: string;
  state: {
    opened: boolean;
  };
  childes?: RenderTree[];
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: 350,
  },
  avatar: {
    width: 80,
    height: 80,
  },
  treeViewDiv: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  treeViewFolder: {
    color: "#e0e0e0",
  },
  treeViewItem: {
    borderRadius: "5px",
  },
  divRoot: {
    "&.Mui-selected  > .MuiTreeItem-content": {
      "& $tickIcon": {
        color: "#73a8e2",
        paddingLeft: "5rem",
        display: "block",
      },
      "& $treeViewFolder": {
        color: "#73a8e2",
      },
    },
  },
  label: {
    fontWeight: "inherit",
    color: "inherit",
    borderRadius: theme.spacing(0.5),
    marginTop: ".3rem",
  },
  tickIcon: {
    display: "none",
  },
  folderHeight: {
    height: "15rem",
    overflow: "scroll",
    borderBottom: "1px solid #c4c4c4",
    paddingRight: "1rem",
  },
  clsNewTemplateName:{
    "& .MuiOutlinedInput-inputMarginDense" :{
      paddingTop: 18,
      paddingBottom: 18
    }
  }
}));

const CreateNewTemplate: React.FC<Props> = ({
  open,
  setOpen,
  history,
  openFlag,
  addTemplatefor
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };
  const [parrentId, setParrentId] = React.useState<string>("");
  const [newTemplateName, setnewTemplateName] = React.useState<string>("");
  const [styleguideId, setstyleguideId] = React.useState<string>("");
  const [expanded, setExpanded] = React.useState<any>([]);
  const [folderList, setFolderList] = React.useState<any>([]);
  
    const loggedInUser = getUserFromToken();
    const brandID = loggedInUser?.user_detail?.Company;
    //const [stylecount, setstylecount] = React.useState<any>(0);
    const [styleFetchData, setstyleFetchData] = React.useState<any>([]);

  const closeDropDownFolder = (event: any) => {
    const selectedFolder = event.currentTarget.id;
    setParrentId(selectedFolder);
  };

  //const [folderList, setFolderList] = React.useState<any>([]);
/*React.useEffect(() => {
   let data: any[] = [null];
    setExpanded(data);
    }, []);*/
   const handleToggle = (event: any, nodeIds: any) => {
    setExpanded(nodeIds);
  };
  React.useEffect(() => {
      getTemplateFolders();
      loadStyleGuideData();
   if(window.location.pathname.split('/').pop() === "content") getCampFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openFlag]);
  
  
  const getCampFolders = () => {
  	setnewTemplateName("");
  };
  const getTemplateFolders = () => {
  	AxiosInstance.get('api/v1/Contents/get_templates_all_folders', {
		params: {
			brandID: brandID,
		},
	}).then((response: AxiosResponse) => {
        if (response.data.status === true) {
          setFolderList(response.data.data.folders[0]);
        }
      })
  };
   const loadStyleGuideData = () => {
       //var totalLength = 0;
  	AxiosInstance.get(`api/getStyleguide/${localStorage.getItem("company_id")}`)
      .then((response: AxiosResponse) => {
          if(Object.keys(response.data.usertable).length>0)
          {
              //totalLength = Object.keys(response.data.usertable).length;
               setstyleFetchData(response.data.usertable);
              //setstylecount(totalLength);
          }
      });
  };

  const setFolderToNewFolder = (event: any) => {
    var FormData = require("form-data");
    var data = new FormData();
    if(!newTemplateName)
    {
          toast.error("Template Name can not be empty");
         return false;
    }
    data.append("template_name", newTemplateName);
    data.append("template_folder_id", parrentId);
    data.append("company_template", brandID);
    data.append("addTemplatefor", addTemplatefor);
    if(styleguideId === '')
    {
        data.append("assign_styleguide_id", localStorage.getItem("defaultStyleId"));
    } else {
        data.append("assign_styleguide_id", styleguideId);
    }
    data.append("last_updated_time", moment(new Date()));

    AxiosInstance.post('api/v1/Contents/save_template_ajax', data)
      .then((response: AxiosResponse) => {
        if (response.data.status === true) {
          setOpen(false);
          const data = response.data.data.id;
          const reDirectLink = `${window.globalConfig.apiOrigin}${window.globalConfig.subDir && window.globalConfig.subDir.length ? ('/' + window.globalConfig.subDir) : ''}template/build/${data}`;
            window.location.href = reDirectLink;
        }
      })
  };

  const renderTree = (nodes: RenderTree) => (
    <>
      {!nodes ? (
        ""
      ) : (
        <TreeItem
          key={nodes.id}
          nodeId={nodes.id}
          classes={{
            root: classes.divRoot,
            label: classes.label,
          }}
          onLabelClick={(event) => {
            event.preventDefault();
          }}
          label={
            <div
              onClick={closeDropDownFolder}
              id={nodes.id}
              className={classes.treeViewDiv}
            >
              {nodes.id ? (<FolderIcon className={classes.treeViewFolder} /> ) : "" }
              <Typography variant="body1" style={{ paddingLeft: "1rem" }}>
                {nodes.id ? (nodes.folder_name) :"Templates"}
              </Typography>
              <CheckIcon className={classes.tickIcon} />
            </div>
          }
        >
          {Array.isArray(nodes.childes)
            ? nodes.childes.map((node) => renderTree(node))
            : ""}
        </TreeItem>
      )}
    </>
  );

  // React.useEffect(() => {
  //   getCampFolders();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);


  const handleInputChange = (event: any) => {
    setnewTemplateName(event.currentTarget.value);
   
  };
  const handleChange = (event: any) => {
   setstyleguideId(event.target.value);
  };
  

  return (
    <div>
      <Dialog
      PaperProps={{ style: {
    width: '600px'
  }}}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {addTemplatefor === 'mashrmailTemplate' ? (
         <DialogTitle id="form-dialog-title">New MashrMail Template</DialogTitle>
        ) : (
         <DialogTitle id="form-dialog-title">New Marketo Template</DialogTitle>
        )}
        <DialogContent>
          <Box className={classes.root}>
            <TextField
              name="new_name"
              autoFocus
              fullWidth
              value={newTemplateName}
              onChange={handleInputChange}
              variant="outlined"
              label="Template Name"
              className={classes.clsNewTemplateName}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
            />
          
          </Box>
          
          {styleFetchData && (
            <Box className={classes.root}>
            <TextField
            select
            fullWidth											
            name="userRoles"
            id="userRoles"
            defaultValue={localStorage.getItem("defaultStyleId")}
            onChange={handleChange}
            variant="outlined"            
            label="Design Systems"
            >
            {styleFetchData.map((Item :any ,index : any) => {

            return (
            <MenuItem value={Item.id}>{Item.styleguide_name}</MenuItem>
            )
            })}
            </TextField>
            </Box>
            )}
          
          <Box>
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultSelected={["root"]}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              onNodeToggle={handleToggle}
            >
              {renderTree(folderList)}
            </TreeView>
          </Box>
           <DialogActions  style={{borderTop: "1px solid #c4c4c4",marginTop:"50px"}}>
          <Button style={{marginTop:"10px"}} onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
          style={{marginTop:"10px"}}
            variant="contained"
            disableElevation
            onClick={setFolderToNewFolder}
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
        </DialogContent>
       
      </Dialog>
    </div>
  );
};

export default CreateNewTemplate;
