import React from "react";
import { useSelector } from "react-redux";
import AxiosInstance from "../../../../../utils/AxiosInstance";
import { useEffect } from "react";
import {useState} from 'react';
import {AxiosResponse} from "axios";
import { store } from '../../../../redux/store';
import { toast } from "react-toastify";
import {setFlashMessage} from "../../../../../utils/AuthUtility";
// import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

interface Props {}

const TrackingStyleLists: React.FC<Props> = () => {
    const URL = window.location.pathname.split('/').pop();
  const [StyleDataListvalues, setStyleDataListvalues] = useState<any>([]);
  // eslint-disable-next-line
  const [StyleDataListvaluesById, setStyleDataListvaluesById] = useState<any>([]);
  const [defaultStylelist, setdefaultStylelist] = React.useState<any>([]);
   // eslint-disable-next-line
  const [ehandleChange, setehandleChange] = React.useState<any>(false);
  
  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

  var styleString: any = '';
  const state = useSelector((state: any) => state.styleGuide);
  var styleObj: any = null;
  const inputList = state.StyleFromDbList;
  inputList.forEach((x: any, i: any) => {
      if(x.StyleDataId)
      {
       styleString +=x.StyleDataId+'&';
      }
  })
  
  styleString = styleString.slice(0, -1);
  localStorage.removeItem('styleState');
  localStorage.setItem('styleState', styleString);
  // eslint-disable-next-line
   const handleInputChange = (e: any) => {
		 
		const stateInitial = store.getState();
		const state = stateInitial.styleGuide;
        
        
		if(state.tracking_name === '' || state.tracking_name===undefined)
		{
			toast.error('Data not saved! Tracking name can not be empty.');
			return false;
		}
       var formdata = new FormData();
       var flag: any = 0;
       var trackingId: any = null;
       const targetinput = e.target as HTMLInputElement;
       if(e.target.checked)
      {
          targetinput.checked=true;
          flag = 1;
      } 
      if(localStorage.getItem("TrackingResID")==='' || localStorage.getItem("TrackingResID")===null)
        {
        trackingId = localStorage.getItem("TrackingrowId")
        }
        else
        {
        trackingId = localStorage.getItem("TrackingResID");
        }
        
      var inputValue = document.getElementById('reset_'+e.target.value) as HTMLInputElement | null;
      var targetInputsec = document.getElementById('CInput_'+e.target.value) as HTMLInputElement | null;
      formdata.append("styleguide_id", e.target.value);
      formdata.append("checkboxstatus", flag);
      formdata.append("tracking_id", trackingId);
      if(inputValue)
      {
        inputValue.setAttribute("style", "display:inline-block");
        
      }
      if(targetInputsec)
      {
          targetInputsec.setAttribute("style", "display:none");
      }
      
      if(trackingId!=null)
      {
      AxiosInstance.post(`api/setTrackingStyleId`, formdata)
      .then((result) => {
          if(result.data.success===0)
          {
                 toast.error(result.data.message);
                 return false;
          }
          
          let MsgContainer: HTMLDivElement | null = document.querySelector("#FlsMsgAlert");
            if (MsgContainer) {
                MsgContainer.setAttribute("style", "display:block;");
            var dt = new Date();
            if(setFlashMessage(dt.toUTCString()) === undefined)
            {
                MsgContainer.setAttribute("style", "padding-left:10px;display:inline-block;float:left;margin-top:3px;margin-right:29px");
                MsgContainer.innerHTML = 'All Saved ✔ a few seconds ago';
            }
            else
            {
               
                MsgContainer.innerHTML = 'Last Saved ✔ '+setFlashMessage(dt.toUTCString());
            }
               localStorage.removeItem("LastSavedTime");
               localStorage.setItem('LastSavedTime', dt.toISOString());
            }
            
      })
      .catch(function (error: any) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.warn(error.response.data.message);
          }
          if (error.response.status === 500) {
            toast.error(error.response.data.message);
          }
        }
        
      });
   } else {
       toast.warning('Please try again tracking save process is in progress!');
   }
  };
   // eslint-disable-next-line    
  const checkExists = (styleId:any) => {
    var flag =  false;  
     styleObj = localStorage.getItem('styleState');
      var response_parameter = styleObj.split('&');
        if(response_parameter.length>0)
        {
            response_parameter.forEach(function (value:any) {
            if(value ===styleId )
            {
               flag =  true;

            }          
            });
        }
  
  return flag;
       
  };
  
  // eslint-disable-next-line
  const disableDefault = (isdefault:any,rowId:any) => {
      
    var flag =  false;
    var targetInputsec = document.getElementById('CInput_'+rowId) as HTMLInputElement | null;
    if(isdefault!==undefined)
    {
    var defaultTracking: any = null;
    defaultTracking = localStorage.getItem('default_tracking');
        if(defaultTracking==='yes' && isdefault===1)
        { 
             // eslint-disable-next-line react-hooks/exhaustive-deps
            flag = true;
        } else {
            
            flag =  false;
            if(targetInputsec)
            { 
                targetInputsec.disabled =  false
            }
        }
        
  }
  return flag;
       
  };
  
  
 const listStyles = () => {
  	AxiosInstance.get(`api/getStyleguide/${localStorage.getItem("company_id")}`)
      .then((response: AxiosResponse) => {
          const Stylelist = response.data.usertable;
          var stylename = '';
          var StyleItmesList = Object.keys(Stylelist).map((k) => Stylelist[k]);
             setStyleDataListvalues(StyleItmesList);
             // eslint-disable-next-line
             {StyleItmesList.map((Item : any,index:any) => {
			     stylename +=Item.styleguide_name+',';
			})}
            stylename = stylename.slice(0, -1);
            setdefaultStylelist(stylename.split(','));
            
          
      });
      //fillStylelist();
  };
  
 
   const listStylesById = (id:any) => {
  	AxiosInstance.get(`api/getStyleguideById/${id}`)
      .then((response: AxiosResponse) => {
          const Stylelist = response.data.usertable;
          var stylename = '';
           var StyleItmesList = Object.keys(Stylelist).map((k) => Stylelist[k]);
             setStyleDataListvaluesById(StyleItmesList);
             // eslint-disable-next-line
             {StyleItmesList.map((Item : any,index:any) => {
                 
			     stylename +=Item.styleguide_name+',';
			})}
            stylename = stylename.slice(0, -1);
            setdefaultStylelist(stylename.split(','));
            
          
      });
      //fillStylelist();
  };
  
  
  const handleChange = (event:any) => {
      setehandleChange(true);
      const stateInitial = store.getState();
		const state = stateInitial.styleGuide;
        
        
     var trackingId: any = null;
      var formdata = new FormData();
    const {
      target: { value },
    } = event;
    setdefaultStylelist(
      typeof value === 'string' ? value.split(',') : value,
    );
    
    if(state.tracking_name === '' || state.tracking_name===undefined)
		{
			toast.error('Data not saved! Tracking name can not be empty.');
			return false;
		}
        
    
    if(localStorage.getItem("TrackingResID")==='' || localStorage.getItem("TrackingResID")===null)
    {
        trackingId = localStorage.getItem("TrackingrowId")
    }
    else
    {
        trackingId = localStorage.getItem("TrackingResID");
    }
     formdata.append("tracking_id", trackingId);
     formdata.append("tracking_id_styleguide", event.target.value);
     
     if(trackingId!=null)
      {
       AxiosInstance.post(`api/updateTrackingStyleId`, formdata)
      .then((result) => {
          if(result.data.success===0)
          {
                 toast.error(result.data.message);
                 return false;
          }
          
          let MsgContainer: HTMLDivElement | null = document.querySelector("#FlsMsgAlert");
            if (MsgContainer) {
                MsgContainer.setAttribute("style", "display:block;");
            var dt = new Date();
            if(setFlashMessage(dt.toUTCString()) === undefined)
            {
                MsgContainer.setAttribute("style", "padding-left:10px;display:inline-block;float:left;margin-top:3px;margin-right:29px");
                MsgContainer.innerHTML = 'All Saved ✔ a few seconds ago';
            }
            else
            {
               
                MsgContainer.innerHTML = 'Last Saved ✔ '+setFlashMessage(dt.toUTCString());
            }
               localStorage.removeItem("LastSavedTime");
               localStorage.setItem('LastSavedTime', dt.toISOString());
            }
            
      })
      .catch(function (error: any) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.warn(error.response.data.message);
          }
          if (error.response.status === 500) {
            toast.error(error.response.data.message);
          }
        }
        
      });
   } else {
       toast.warning('Please try again tracking save process is in progress!');
   }
     
        
    
  };
  
  
 useEffect(() => {
    if(URL === "tracking" || URL === "Newtracking"){
      setTimeout(function(){ 
       if(localStorage.getItem("TrackingrowId")==='' || localStorage.getItem("TrackingrowId")===null)
       {
           listStyles();
       } else {
           listStyles();
        listStylesById(localStorage.getItem("TrackingrowId"));
       }
       
       }, 
       50);
    
      var checkboxes = document.querySelectorAll('.customDefault_checkbox');
        for (var i = 0, len = checkboxes.length; i < len; i++) {
        const input = checkboxes[i] as HTMLInputElement;
        if(input!= null)
        {
           input.checked = false;
        }
        //work with checkboxes[i]
        }

    } 
 
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[URL]);

  return (
    <div>
     <FormControl variant="outlined" style={{ maxWidth: "235px" }} size="small">
        <InputLabel id="demo-multiple-checkbox-label">Design Systems</InputLabel>
        <Select
          style={{width: "235px"}}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={defaultStylelist}
          onChange={handleChange}
          label={"Design Systems"}
          // input={<OutlinedInput label="Style Guides" />}
          renderValue={(selected: any) => selected.join(',')}
          MenuProps={MenuProps}
        >
          {StyleDataListvalues.map((Item : any,index:any) => {
			return (
			 <MenuItem key={Item.id} value={Item.styleguide_name}>
              <Checkbox checked={defaultStylelist.indexOf(Item.styleguide_name) > -1} />
              <ListItemText primary={Item.styleguide_name} />
            </MenuItem>
			)
			})}
        </Select>
      </FormControl>
      
    </div>
  );
};

export default TrackingStyleLists;
