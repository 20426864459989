import React, { useEffect } from "react";
import { Box, Paper, TextField } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import { useStyles } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../../redux/actions/styleGuideActions";
import { Autocomplete } from "@material-ui/lab";
import { DefaultCustomNameList } from "../../utilities/DefaultStyleGuide";
import { updateCustomCssStyleSheet } from "../../../../../../utils/Functions";
interface Props {
  data:any;
}

const GoogleItem: React.FC<Props> = ({data}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.styleGuide);

  const handleAutoCompleteChange = (e:any, value:any)=>{

    let css_link = value.family.replace(/ /g, '+');

    css_link = "https://fonts.googleapis.com/css2?family="+css_link;
    data.family = value.family;
    data.css_link = css_link;

    let arr = state?.list_custom_fonts;    
    arr?.forEach((option:any) => {
      if(option.key === data.key){
        option["family"] = value.family;
        option["css_link"] = css_link;
        option["type"] = "googlefonts";
        option["styles"] = [];
        dispatch(addField("list_custom_fonts", arr));        
        updateCustomCssStyleSheet(state?.list_custom_fonts);
      }
    });

    DefaultCustomNameList?.forEach((option:any) => {
      if(data.key === state["key_" + option]) {
        dispatch(addField(option, value.family)); 
      }
    });


    // dispatch(addField("google_font", {family:value.family, css_link:css_link}));
    // dispatch(addField("custom_font", DefaultStyleGuide.custom_font));
    // dispatch(addField("custom_font_name", ""));
    // if(state?.headline_custom_font && state?.headline_custom_font !== 'none'){  
    //   dispatch(addField("headline_custom_font", value.family));
    // }  // TO delete Headline custom_font  
    // if(state?.headline_custom_h1 && state?.headline_custom_h1 !== 'none'){  
    //   dispatch(addField("headline_custom_h1", value.family));
    // }
    // if(state?.headline_custom_h2 && state?.headline_custom_h2 !== 'none'){  
    //   dispatch(addField("headline_custom_h2", value.family));
    // }
    // if(state?.headline_custom_h3 && state?.headline_custom_h3 !== 'none'){  
    //   dispatch(addField("headline_custom_h3", value.family));
    // }
    // if(state?.headline_custom_h4 && state?.headline_custom_h4 !== 'none'){  
    //   dispatch(addField("headline_custom_h4", value.family));
    // }
    // if(state?.body_custom_font && state?.body_custom_font !== 'none'){      
    //   dispatch(addField("body_custom_font", value.family));
    // }
    // if(state?.text_custom_body1 && state?.text_custom_body1 !== 'none'){  
    //   dispatch(addField("text_custom_body1", value.family));
    // }
    // if(state?.text_custom_body2 && state?.text_custom_body2 !== 'none'){  
    //   dispatch(addField("text_custom_body2", value.family));
    // }
    // if(state?.text_custom_legal && state?.text_custom_legal !== 'none'){  
    //   dispatch(addField("text_custom_legal", value.family));
    // }
    // if(state?.cta_custom_font && state?.cta_custom_font !== 'none'){      
    //   dispatch(addField("cta_custom_font", value.family));
    // }
    // dispatch(addField("google_font_name", value.family));
    // AutoSave();
  }
  const handleTextChange = (e:any) => {

    if(e.target.value.length === 0) { 
      let arr = state?.list_custom_fonts;
      arr?.forEach((option:any) => {
        if(option.key === data.key){  
          option["family"] = "";
          option["css_link"] = "";        
          dispatch(addField("list_custom_fonts", arr));            
          updateCustomCssStyleSheet(arr);
        }
      });
      DefaultCustomNameList?.forEach((option:any) => {
        if(data.key === state["key_" + option]) {
          dispatch(addField(option, "none")); 
        }
      });

    //   dispatch(addField("headline_custom_font", "none"));  // TO delete Headline custom_font  
    //   dispatch(addField("headline_custom_h1", "none"));
    //   dispatch(addField("headline_custom_h2", "none"));
    //   dispatch(addField("headline_custom_h3", "none"));
    //   dispatch(addField("headline_custom_h4", "none"));
    //   dispatch(addField("body_custom_font", "none"));   
    //   dispatch(addField("text_custom_body1", "none"));
    //   dispatch(addField("text_custom_body2", "none"));
    //   dispatch(addField("text_custom_legal", "none"));
    //   dispatch(addField("cta_custom_font", "none"));
    //   dispatch(addField("google_font_name", ""));
    //   dispatch(addField("google_font", {family:"", css_link:""}));
    //   AutoSave();
    }
  }
  
  const CustomPaper = (props: any) => {
    return <Paper elevation={8} {...props} />;
  };

  useEffect(()=>{
    if(state?.google_font?.css_link && state?.google_font?.css_link !== undefined){
      let css_google_link: HTMLElement = document.getElementById("css_google_link") as HTMLElement;
      css_google_link.setAttribute("href",state?.google_font?.css_link + ":wght@100;200;300;400;500;600;700;800;900");
    }
  },[state?.google_font?.css_link]);
  return (          
    <Box className={classes.googleFontsContainer}>  
     {(state?.googleFontList !== undefined && state.google_font  !== undefined ) &&( <> <Box><SearchIcon style={{ cursor: "pointer", padding: "17px", position: "absolute" }} /></Box>
      <Autocomplete
        id="combo-box-demo"   
        freeSolo      
        disableClearable     
        options={state?.googleFontList}
        getOptionLabel={(font) => font.family || ""}
        value={{family:data?.type === "customfonts" ? "" : data?.family}}
        style={{ width: 300 }}
        PaperComponent={CustomPaper}
        className={classes.googleAutocomplete}
        onChange={handleAutoCompleteChange}
        renderInput={(params) => (
          <TextField 
            {...params} 
            label="Font" 
            placeholder="Search Fonts"
            variant="outlined"
            onChange={handleTextChange}
            />
        )}
      />         
      </>)}
    </Box>
       
  );
};

export default GoogleItem;
