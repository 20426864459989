import React, { useState,useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { Box, Button, FormControlLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import { useStyles } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import Slider from '@material-ui/core/Slider';
import {AutoSave} from "../StyleGuideAutosave";
import { toast } from "react-toastify";
import {
  addLogo,
} from "../../../../redux/actions/styleGuideActions";
import { addField } from '../../../../redux/actions/styleGuideActions';
import LogoImageAutosave from "../LogoAutosave";
interface Props {
  isNotSettingsPage: any;
}
const Logo: React.FC<Props> = ({ isNotSettingsPage }) => {
  const dispatch = useDispatch();
  const logo = useSelector((state: any) => state.styleGuide.logo);
  const state = useSelector((state: any) => state.styleGuide);
   //const styleguideName = useSelector((state: any) => state.styleGuide.styleguideName);
  const logoWidth = useSelector((state: any) => state.styleGuide.c_logo_width);
  var logoSize: number;
  if(logoWidth){
    logoSize = Math.round((logoWidth / 6.4));
  } else logoSize = 96;

  const handleUploadClick = (event: any) => {
    var file = event.target.files[0];
    if(!isFileImage(file)) {
         dispatch(addField("logo_error_status", true));            
        dispatch(addField("logo_error", file.name + ' is not supported'));        
      //toast.error(file.name + ' is not supported');
      return false;
    }

    const fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize > 2) {
         toast.error(file.name + ' is too big, Size limit is 2 megabytes');
         return false;
    }
    
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async function (e) {
     dispatch(addLogo({ file,src_url:reader.result , src: reader.result }));
      let logoUrlResponse = await LogoImageAutosave(file);
      if(logoUrlResponse !== "" && logoUrlResponse !== undefined){
          dispatch(addField("logoUrl", logoUrlResponse));
        dispatch(addLogo({ file,src_url:logoUrlResponse , src: logoUrlResponse }));
        } else dispatch(addLogo({ src_url:stateURL , src: stateURL }));
     
    };
  };
  const handleSliderChange = (event: any, newValue: number | number[]) => {
     if (!Array.isArray(newValue)) {
      dispatch(addField("c_logo_width", newValue * 6.4));
    }
  };
 
  const handleSliderStop = (event: any, newValue: number | number[]) => {
         AutoSave();
  };

  const isFileImage = (file:any) => {
     return file && (file['type'] === 'image/jpeg' || file['type'] === 'image/jpg' || file['type'] === 'image/png' || file['type'] === 'image/gif');
    }
  
  const [imageStyle, setImageStyle] = React.useState('file');
  

  const handleChangeImageStyle = (event:any) => {
    if(logo?.src_url.includes("data:image")){
      return;
    }
    setImageStyle(event.target.value);
    dispatch(addField("errorInputTextURl", false));
  };
  const [stateURL, setStateURL] = useState("");
  const handleChangeInputUrl = (event:any)=> {
        if(event.target.value === ''){
        dispatch(addField("defaultUrl", state.logo.src));
        } else {
            dispatch(addField("defaultUrl", ''));
        }
    dispatch(addLogo({src_url: event.target.value, src: event.target.value }));
    setStateURL(event.target.value);
    dispatch(addField("logoUrlInput", event.target.value));
  }
useEffect(()=>{
                    
    if(state.errorInputTextURl)
    {
        setImageStyle('url');
    }
    // eslint-disable-next-line
  },[state.errorInputTextURl]);
  

  
/*
  useEffect(()=>{
    setStateURL(logo?.src_url);
    if(logo?.src_url && imageStyle === 'file' && !checkUrlValidation(logo?.src_url)){
      setImageStyle('url');
      dispatch(addLogo({src_url: stateURL, src: stateURL }));
    }
    // eslint-disable-next-line
  },[logo?.src_url]);

  /*useEffect(()=>{
    if(stateURL === logo?.src_url) return;
    const timeOutId = setTimeout(async () => {
      if(stateURL !== ""){
        let ifImage = await checkImage(stateURL);
        if(!ifImage) {        
          toast.error(stateURL + ' is not good URL');
          return false;
        }
      }
      //await LogoURLAutosave(stateURL);
      dispatch(addLogo({src_url: stateURL, src: stateURL }));     
    }, 500);
    return () => clearTimeout(timeOutId);   
    
    
    // eslint-disable-next-line
  },[stateURL]);*/

  /*const checkUrlValidation = (url:any) => {  
    var host = window.location.host;
    host = host.replace("localhost:3000", "localhost");
    var subdomain = host.split('.')[0];
    subdomain = '/' + subdomain + '/';
    if(url && (url.includes(host) || url.includes("data:image") || (url.includes("objectstorage") && url.includes(subdomain))) ){
      return true;
    }
    return false;
  }*/
  /*const checkImage = async (url:any) => {  
    try{
      const img = new Image();
      img.src = url;
      await img.decode();
      return true;
    } catch (ex){
        return false;
    }
}*/

  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" className={classes.section}>
      <Typography className={classes.heading} variant="h6" style={{marginTop:20}}>
        Logo
      </Typography>
      <Typography variant="caption" className={classes.caption}>
        Image:
      </Typography>
      {logo && (
        <Box className={classes.logoContainer}>
          <Box className={classes.logoContainerRow}>
            <Box>
              <RadioGroup row aria-label="image" name="image" value={imageStyle} onChange={handleChangeImageStyle}>
                <FormControlLabel value="file" control={<Radio color="primary" />} label="File" />
                <FormControlLabel  value="url" control={<Radio color="primary" />} label="URL" />
              </RadioGroup>
            </Box>
            { (imageStyle === 'file') && (<Box className={classes.replaceImageBtn}>
              <Box
                width={isNotSettingsPage ? 120 : 90}
                height="auto"
                style={{
                  position: "relative",
                  border: "1px solid #D3D3D3",
                  padding: "11px 15px 11px 15px",
                  borderRadius: 10,
                  marginRight: 15
                }}
              >
                <img
                  alt="not available"
                  width={90}
                  height="auto"
                  src={state.defaultUrl ? state.defaultUrl : logo?.src}
                />
              </Box>
              <Button
                variant="outlined"
                color="primary"
                component="label"
                size="small"
                className={classes.logoButton}
              >
                REPLACE
                <input
                  type="file"
                  accept=".gif, .jpeg, .jpg, .png"
                  onChange={handleUploadClick}
                  hidden
                />
              </Button>
            </Box>)}
            { (imageStyle === 'url') && (<Box className={classes.replaceImageInput} flex={1}>
              <TextField
                id="outlined-full-width"
                label="Source:"
                placeholder="https://"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"                
                value={stateURL ? stateURL : logo?.src}
                error={state.imageinput}
                helperText={state.imageinputText}
                onChange={handleChangeInputUrl}
              />
            </Box>)}

          </Box>
          <Box className={classes.logoContainerRow} >
            <Box >
              <Typography variant="caption" className={classes.caption}>
                Size:
              </Typography>
            </Box>
            <Box className={classes.sliderContainer}>      
              <Box className={classes.sliderWrapper}>
                <Box className={classes.slider}>      
                  <Slider
                    value={logoSize}
                    onChange={handleSliderChange}
                    onChangeCommitted={handleSliderStop}
                    aria-labelledby="input-slider"
                    min={2}
                  />
                </Box>     
                <Box className={classes.sliderValue}>  
                  {logoSize}%
                </Box>
              </Box>
            </Box>
          </Box>
           <span style={{color:"#dc004e",paddingBottom:"20px",display:"block"}}>{state.logo_error}</span>
        </Box>)} 
    </Box>
  );
};

export default Logo;