import React, { useRef } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "../styles";
import Button from "@material-ui/core/Button";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../../../redux/actions/styleGuideActions";
import {AutoSaveTracking} from "../../tracking/TrackingAutosave";
import {MenuItem } from "@material-ui/core";
import {isAdmin,isPowerUser} from "../../../../../utils/AuthUtility";
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

interface Props {}

const TrackingAttributesFields: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.styleGuide);
   //console.log(state);
  const inputList = state.AttributesList;
  //console.log(inputList);
  const classes = useStyles();
  const showFields = inputList.length > 0 ? true : false;
  const droppableRef = useRef<any>();
  // const [showFields, setShowFields] = React.useState<any>({
  //   displayField: inputList.length > 0 ? true : false,
  // });

  const showListFields = () => {
    // setShowFields({ displayField: true });
    if (inputList.length < 1) setInputList([{  Attributetype: "",AttributeFieldLabel:"", AttributeName: "", AttributeValue: "" }]);
  };
  const setInputList = (list: any) => {
    dispatch(addField("AttributesList", list));
    //AutoSave();
  };
  const handleHideClick = () => {
    // setShowFields({ displayField: false });
    dispatch(addField("AttributesList", []));
    AutoSaveTracking();
  };
  
  const handleBlur = () => {
     AutoSaveTracking();
  };
  
  
  const getStyle = (snapshot: any, provided: any, draggableId: any) => {
    if (draggableId === snapshot.draggingFromThisWith) {
      let transform = provided?.draggableProps?.style?.transform;
      let droppableReff = droppableRef.current;

      if (transform) {
        transform = transform?.substring(10)?.split(",");
        let xTranslate = Number(transform[0].replace("px", ""));
        let yTranslate = Number(transform[1].replace("px)", ""));
        let y = yTranslate + provided?.draggableProps?.style?.top;
        let minY = droppableReff.getBoundingClientRect().y;
        let maxY =
          droppableReff.getBoundingClientRect().y +
          droppableReff.getBoundingClientRect().height;

        if (xTranslate > 70 || xTranslate < -70 || y < minY || y > maxY) {
          return {
            ...provided?.draggableProps?.style,
            opacity: 0,
            cusrsor: "pointer",
          };
        } else {
          return {
            ...provided?.draggableProps?.style,
            opacity: 1,
          };
        }
      }
    }
    return provided.draggableProps.style;
  };
  const handleInputChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveClick = (index: any) => {
    const list = [...inputList];
    setInputList(list);
    list.splice(index, 1);
    AutoSaveTracking();
  };

  const handleAddClick = () => {
    
    setInputList([...inputList, { Attributetype: "",AttributeFieldLabel:"", AttributeName: "", AttributeValue: "" }]);
  };

  return (
    <div>
      {showFields ? (
        <>
          
          <div ref={droppableRef} className={classes.dragDropMenuConatiner}>
            <DragDropContext
              onDragEnd={(params: any) => {
                if (!(params.source && params.destination)) return;
                const sourceIndex: number = params.source.index;
                const destinationIndex: number = params.destination.index;

                inputList.splice(
                  destinationIndex,
                  0,
                  inputList.splice(sourceIndex, 1)[0]
                );
                setInputList(inputList);
              }}
            >
              <Droppable droppableId="droppable-1">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {inputList.map((x: any, i: any) => {
                      return (
                        <Draggable
                          key={i}
                          draggableId={"draggable-" + i}
                          index={i}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={getStyle(
                                snapshot,
                                provided,
                                "draggable-" + i
                              )}
                            >
                              <div className="box">
                                <>
                                  <Grid
                                    container
                                    spacing={1}
                                  >
                                  
                                   <Grid
                                      item
                                      xs={4}
                                      sm={2}
                                      md={2}
                                      style={{
                                        height: "70px",
                                      }}
                                    >
                                <FormControl
                                variant="outlined"
                                style={{ width: "100%", marginBottom: 32 }}
                                size="small"
                                >
                                <InputLabel id="test-select-label">Attribute Type</InputLabel>
                                  <Select
                                  style={{ width: "100%" }}
                                  labelId="test-select-label"
                                  label={"Attribute Type"}
                                  fullWidth
                                  value={x.Attributetype}
                                  name="Attributetype"
                                  id="Attributetype"
                                  variant="outlined"
                                  onChange={(e) =>
                                  handleInputChange(e, i)
                                  }

                                  >
                                  <MenuItem value='Fixed'>Fixed Value</MenuItem>
                                  <MenuItem value='Linked'>Link Field</MenuItem>
                                  <MenuItem value='Email'>Email Field</MenuItem>
                                  </Select>
                                </FormControl>
									
									
                                    </Grid>
                                  
                                  {(x.Attributetype==='Linked' || x.Attributetype==='Email') && (
                                    <Grid
                                      item
                                      xs={4}
                                      sm={2}
                                      md={2}
                                      style={{height:"70px"}}
                                    >
                                      <TextField
                                      
                                        name="AttributeFieldLabel"
                                        autoComplete="AttributeFieldLabel"
                                        type="text"
                                        label="Field Label"
                                        variant="outlined"
                                        fullWidth
                                        value={x.AttributeFieldLabel}
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          handleInputChange(e, i)
                                        }
                                      />
                                    </Grid>
                                    
                                     )}
                                    
                                    <Grid
                                      item
                                      xs={4}
                                      sm={2}
                                      md={2}
                                      style={{
                                        height: "70px",
                                      }}
                                    >
                                      <TextField
                                        name="AttributeName"
                                        autoComplete="Attribute Name"
                                        type="text"
                                        size="small"
                                        label="Attribute Name"
                                        variant="outlined"
                                        fullWidth
                                        value={x.AttributeName}
                                        onBlur={handleBlur}
                                        onChange={(e) =>
                                          handleInputChange(e, i)
                                        }
                                      />
                                    </Grid> 
                                    <Grid
                                      item
                                      xs={4}
                                      sm={2}
                                      md={2}
                                      style={{
                                        height: "70px",
                                      }}
                                    >
                                      <TextField
                                        name="AttributeValue"
                                        autoComplete="Attribute Value"
                                        label="Attribute Value"
                                        size="small"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        onBlur={handleBlur}
                                        value={x.AttributeValue}
                                        onChange={(e) =>
                                          handleInputChange(e, i)
                                        }
                                      />
                                    </Grid>
									
									{(isAdmin() || isPowerUser()) && (
                                    <Grid
                                      item
                                      xs={6}
                                      sm={1}
                                      md={1}
                                      style={{
                                        height: "70px",
                                      }}
                                    >
                                      {inputList.length !== 1 ? (
                                        <div
                                          className={classes.fieldSideIconDiv}
                                        >
                                          <HighlightOffRoundedIcon
                                            fontSize="default"
                                            className={classes.fieldSideIcon}
                                            onClick={() => handleRemoveClick(i)
                                            
                                            }
                                          ></HighlightOffRoundedIcon>
                                          
                                        </div>
                                      ) : (
                                        <div
                                          className={classes.fieldSideIconDiv}
                                        >
                                          <HighlightOffRoundedIcon
                                            fontSize="default"
                                            className={classes.fieldSideIcon}
                                            onClick={() => handleHideClick()}
                                          ></HighlightOffRoundedIcon>
                                         
                                        </div>
                                      )}
                                    </Grid>
									)}
									
									
                                  </Grid>
                                  {inputList.length - 1 === i &&
                                    !snapshot.isDraggingOver && (isAdmin() || isPowerUser() ) && (
                                      <Button
                                        className={classes.addNewMenuButton}
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        onClick={handleAddClick}
                                      >
                                        Add
                                      </Button>
                                    )}
                                </>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={showListFields}
        >
          Add
        </Button>
      )}
      {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
    </div>
  );
};

export default TrackingAttributesFields;
