import React, { Dispatch, SetStateAction } from "react";
import {
  Dialog,
  Button,
  Box,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SimpleTree from "../../../../../../../../common/components/SimpleTree";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LinearProgress from "@material-ui/core/LinearProgress";
import AxiosInstance from "../../../../../../../../utils/AxiosInstance";
import {AxiosResponse} from "axios";

interface Props {
  open: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedFolderName: string;
  moveList: RenderTree;
  moveFolderID?: string;
  brandID?: string;
  history: any;
  refreshCampFolders: any;
}
interface RenderTree {
  id: string;
  name?: string;
  type?: string;
  folder_name?: string;
  parent_id?: string;
  company_id?: string;
  state?: {
    opened: boolean;
  };
  childes?: RenderTree[];
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  treeViewDiv: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  treeViewFolder: {
    color: "#e0e0e0",
  },
  treeViewItem: {
    borderRadius: "5px",
  },
  divRoot: {
    "&.Mui-selected  > .MuiTreeItem-content": {
      "& $tickIcon": {
        color: "#73a8e2",
        paddingLeft: "5rem",
        display: "block",
      },
      "& $treeViewFolder": {
        color: "#73a8e2",
      },
    },
  },
  label: {
    fontWeight: "inherit",
    color: "inherit",
    borderRadius: theme.spacing(0.5),
    marginTop: ".3rem",
  },
  tickIcon: {
    display: "none",
  },
  folderHeight: {
    maxHeight: "15rem",
    overflow: "scroll",
    borderBottom: "1px solid #c4c4c4",
    display: "flex",
    flexDirection: "column",
    minWidth: 350,
  },
  header: {
    borderBottom: "1px solid #c4c4c4",
    paddingLeft: "0",
    paddingRight: "0",
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
    paddingBottom: ".5rem",
  },
}));
const MoveFolder: React.FC<Props> = ({
  open,
  setOpen,
  selectedFolderName,
  moveList,
  moveFolderID,
  brandID,
  history,
  refreshCampFolders,
}) => {
  const classes = useStyles();

  const [moveFolderToID, setMoveFolderToId] = React.useState<string>("");
  const [expanded, setExpanded] = React.useState<any>([]);

  const handleClose = () => {
    setOpen(false);
  };

  const selectMovetoFunction = (event: any) => {
    setMoveFolderToId(event.currentTarget.id);
  };

  const setFolderToNewFolder = (event: any) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("folder_id", moveFolderID);
    data.append("parent_id", moveFolderToID);
    data.append("newBrandID", brandID);

    AxiosInstance.post('api/v1/Campaign/moveCampFolderToFolder', data)
	  .then((response: AxiosResponse) => {
        if (response.data.status === true) {
		  setMoveFolderToId('');
          refreshCampFolders();
          setOpen(false);
        }
      })
  };
	React.useEffect(() => {
	let data: any[] = [0];
	const expd = (child: any) => {
	  child.forEach((item: any) => {
		data.push(String(item.id));
		if (item.childes) {
		  expd(item.childes);
		}
	  });
	};

	if (moveList.childes) {
	  expd(moveList.childes);
	}
	setExpanded(data);
	}, [moveList]);
	
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" className={classes.header}>
          Move {selectedFolderName} to
        </DialogTitle>
        <DialogContent>
          <Box className={classes.folderHeight}>
            {!moveList ? (
              <LinearProgress />
            ) : (
              <TreeView
				defaultCollapseIcon={<ExpandMoreIcon />}
				defaultExpandIcon={<ChevronRightIcon />}
				expanded={expanded}
				selected=""
              >
             
			<SimpleTree
				fileTreeData={moveList}
				parrentId={moveFolderToID}
				closeDropDownFolder={selectMovetoFunction}
				treeName="Emails"
			>
			</SimpleTree>
              </TreeView>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={setFolderToNewFolder}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MoveFolder;
