import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: ".25rem",
    },
  },
  componentRoot: {
    width: "100%",
    display: "flex",
    paddingTop: "6rem",
    justifyContent: "center",
    background: " #f8f8f8",
  },
  insertbut: {
   //width: "100%",
    
  },
  sectionbox:{
      width: "100%",
      padding:"20px",
    border: "1px solid #E1E1E1",
    marginBottom: "1rem",
    background:" #fff",
    borderRadius: 4,
    '& $campChild:nth-child(2)': {
      marginTop: 8,
      "& > .MuiGrid-root" :{
        marginTop: "-6px"
      }
    },
    '& $campChild:last-child': {
      paddingBottom: "unset",
      "& > .MuiGrid-root":{
        marginBottom: "-4px"
      }
    }
  },
  header: {
    width: "100%",
    marginTop: "1rem",
    marginBottom: ".5rem",
  },
  componentHeader: {
    display: "flex",
    paddingLeft: "8px",
    border: "1px solid #E1E1E1",
    background: "white",
  },
  searchBar: {
    background: "#ffffff",
    borderRadius: "4px",
    height: "62px",
    justifyContent: "center",
    border: "1px solid #D4D4D4",
  },
  search: {
    position: "relative",
    marginRight: theme.spacing(2),
    width: "100%",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
  },
  templateHeader: {
    "& .MuiButton-root":{
      fontWeight:"500"
    }
  },
  gptHeaderText:{
  },
  gptBodyText:{
    "& .MuiTypography-root":{
      opacity:"0.6",
    }
  },
  gridTextAreaContainer:{
    paddingTop:24,
    paddingBottom:24,
  },
  gridContentArea:{
    display: "flex",
    alignItems:"center"
  },
  textAreaFirstContainer:{
    flex:"1",
    display: "flex",
    flexDirection: "column",
  },
  gridTextAreaWrapper:{
    flex:"1"
  },
  textAreaSecondContainer:{
    flex:"1",
    display: "flex",
    flexDirection: "column",

  },
  textAreaButtonBetween:{
    marginLeft: 20,
    marginRight: 20
  },
  textAreaCount:{
    paddingTop:10,
    opacity:"0.6"
  },
  textareaAutosize:{
    resize: "none",
    height: "152px !important",
    maxHeight: "152px !important",
    padding: "16px 12px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
  },
  textFieldMultiLine:{
    resize: "none",
    height: "152px !important",
    maxHeight: "152px !important",
    "& .MuiOutlinedInput-multiline":{
      height: "100%"
    },
    "& .MuiOutlinedInput-inputMultiline":{
      height: "100%",      
      opacity:"0.87",
    },
  },
  textFieldMultiLineOutput:{
    background: "#F3F4F6"
  },
  gptFooter:{
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    paddingTop: 24,
    display: "flex",
    justifyContent: "flex-end",
  },
  filetreeHeader: {
    padding: ".5rem",
    borderBottom: "1px solid #E1E1E1",
  },
  filetreeInnerHeader: {
    display: "flex",
    verticalAlign: "middle",
    cursor: "pointer",
    padding: ".25rem",
    color: "#238cff",
    "&:hover": {
      background: "#DBECFF",
      borderRadius: "4px",
    },
  },
  mainBody: {
    overflowX: "hidden",
    overflowY: "scroll",
	width: "calc(100% - 400px)",
	padding: "0",
	margin: "0",
  },
  hasTrialInfo:{

  },
  InfiniteScrollGallery:{
    height:"calc(100vh - 322px) !important",
    "&$hasTrialInfo":{
      height: "calc(100vh - 330px) !important",
    }
  },
  galleryGridLayout: {
	width: "100%",
	display: "grid",
	gridTemplateColumns: "repeat(auto-fill, 260px)",
	gridGap: "1rem rem",
	justifyContent: "space-between",
  },
  newCampaignDiv: {
    border: "1px solid #ffffff",
    cursor: "pointer",
    position: "relative",
    borderRadius: "2px",
    "&:hover": {
		"&:before": {
			content: '""',
			display: "block",
			background: "#00000047",
			width: "242px",
			height: "47px",
			position: "absolute",
			top: "0",
			left: "0",
			filter: "blur(2px)",
		},
      border: "1px solid #057CFF",
      boxShadow:
        "0 0 0 5px #ebf5ff, 0 0 0 6px #057cff, 5px 6px rgb(200 238 249)",
      overflow: "hidden",
      "& $fileHeader": {
        display: "block",
      },
		"& $selectButton": {
			display: "block",
		},
    },
  },
	selectButton: {
	  	display: 'none',
	  	position: 'absolute',
		borderRadius: "2px",
		top: 'calc(50% - 18px)',
		left: 'calc(50% - 36px)'
	},
  newCampaignCardImage: {
    width: "242px",
    height: "172px",
    backgroundSize: "auto !important",
    backgroundColor:"#e5e5e5"
  }, 
  newCampaignCardImageContain:{
    backgroundSize: "contain !important",
  },
  newCampaignCardImageCover:{
    backgroundSize: "cover !important",
  },
  fileHeader: {
    position: "absolute",
    color: "#ffffff",
    width: "100%",
    display: "none",
  },
  fileListGrid: {
    paddingTop: "0rem",
  },
  fileTypo: {
    paddingTop: ".5rem",
    paddingLeft: ".5rem",
    width: "10rem",
    overflow: "hidden",
    fontSize: "12px",
    height: "1rem",
  },
  fileTypoText: {
    width: "10rem",
    fontSize: "10px",
    overflow: "hidden",
    paddingBottom: ".5rem",
    paddingLeft: ".5rem",
  },
  galeryCardImage: {
    height: "10rem",
    width: "100%",
    background: "round",
  },
  listCount: {
    display: "flex",
    justifyContent: "center",
  },
  boxItemA: {
    color: "#000000",
    fontSize: "17px",
    lineHeight: "24px",
    letterSpacing: "0.13px",
    cursor: "pointer",
  },
  dotIcon: {
    cursor: "pointer",
    "&:active": {
      outline: "0",
      boxShadow: "inset 0 3px 5px rgb(0 0 0 / 13%)",
    },
  },
  deleteItem: {
    color: "red",
  },
  previewRoot: {
    margin: 0,
    padding: theme.spacing(2),
  },
  previewCloseButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  campaign_preview_desktop: {
    border: "1px solid #CBD6E2",
    margin: "0 74px 0 0",
    display: "flex",
    overflow: "hidden",
    background: "#f6f8fa",
    alignItems: "center",
    borderRadius: "7px 7px 6px 6px",
    flexDirection: "column",
    width: "50rem",
  },
  campaign_preview_window_header_dsktop: {
    display: "flex",
    height: "32px",
    paddingLeft: "15px",
    alignItems: "center",
    background: "#F6F8FA",
    width: "100%",
  },
  campaign_preview_window_header_mobile: {
    height: "60px",
    display: "flex",
    background: "#F6F8FA",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  campaign_preview_mobile: {
    margin: "0 13px 0px 13px",
    border: "1px solid #CBD6E2",
    boxSizing: "border-box",
    borderRadius: "36px",
    width: "25rem",
    background: "#f6f8fa",
  },
  campaign_preview_window_content: {
    height: "calc(100vh - 300px)",
    background: "#fff",
    width: "100%",
  },
  campaign_preview_window_content_mobile: {
    margin: "0 13px 13px 13px",
    border: "1px solid #CBD6E2",
    boxSizing: "border-box",
    borderRadius: "0 0 28px 28px",
    background: "#fff",
    height: "calc(100vh - 300px)",
    overflow: "hidden",
  },

  previewDOT: {
    height: "11px",
    color: "#cbd6e2",
  },
  paper: {
    margin: theme.spacing(8, 4),
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formGrid: {
    display: "flex",
    justifyContent: "center",
  },
  previewH6: {
    fontSize: "28px",
    color: "#5D7186",
  },
  previewPointer: {
    color: "#5d7186",
    fontSize: "3rem",
    paddingRight: "1rem",
    cursor: "pointer",
  },
  iFrame: {
    border: "0",
  },
  searchBarToolBox: {
    paddingLeft: "11px",
  },
  disabledList: { 
    pointerEvents:"none",
    opacity:0.4
	},
}));
