import { changeWeightToNumber, getDefaultCustomFontItem, getDefaultGoogleFontItem, getPaddingForButton } from "../../../../../utils/Functions";
import { DefaultCustomNameList, DefaultStyleGuide } from "./DefaultStyleGuide";

export const convertToReduxState=(data:any)=>{
    const payload:any = {}; 
    if(data.template['headline_font'] === "Trebuchet") data.template['headline_font'] = "Trebuchet MS";
    if(data.template['button_font'] === "Trebuchet") data.template['button_font'] = "Trebuchet MS";
    if(!data.template['button_font']) data.template['button_font'] = data.template['text_font'];
    if(data.template['text_font'] === "Trebuchet") data.template['text_font'] = "Trebuchet MS";
    
    payload['is_dark'] = data?.template?.is_dark ? data?.template?.is_dark : DefaultStyleGuide.is_dark;
    payload['primary_color'] = data.template['primary_color_new'];
    payload['secondary_color'] = data.template['secondary_color_new'];
    payload['email_color'] = data.template['background_color'];
    payload['styleguide_name'] = data.template['styleguide_name'];
    payload['styleguideName'] = data.template['styleguide_name'];
    payload['head_email_code_value'] = data.template['head_email_code'];
    payload['body_top_email_code_value'] = data.template['body_top_email_code'];
    payload['body_bottom_email_code_value'] = data.template['body_bottom_email_code'];
    payload['header_text_color'] = data.template['banner_text_color'];
    payload['email_color'] = data.template['background_color'];
    payload['primary_font'] = data.template['headline_font']?data.template['headline_font']:'';
    payload['button_font'] = data.template['button_font'] ? data.template['button_font'] : data.template['text_font'];
    payload['fallback_font'] = data.template['fallback_font']?data.template['fallback_font']:'';
    payload['website_url'] = data.template['website_url'];
    payload['hedline_color'] = data.template['headline_color'];
    payload['text_color'] = data.template['text_color'];
    payload['social_accent_color'] = data.template['social_accent_color'];
    payload['text_font'] = data.template['text_font'];
    payload['link_color'] = data.template['link_color'];
    payload['company_website'] = data.template.contact_details['company_website'];
    payload['company_tagline'] = data.template.contact_details['company_tagline'];
    payload['company_address'] = data.template.contact_details['company_address'];
    payload['company_name'] = data.template.contact_details['company_name'];
    payload['contact_phone'] = data.template.contact_details['contact_phone'];
    payload['contact_email'] = data.template.contact_details['contact_email'];
    payload['headline_size_h1'] = data.template.headline_font_size['headline_size_h1'];
    payload['headline_size_h2'] = data.template.headline_font_size['headline_size_h2'];
    payload['headline_size_h3'] = data.template.headline_font_size['headline_size_h3'];
    payload['headline_size_h4'] = data.template.headline_font_size['headline_size_h4'];
    payload['headline_lineheight_h1'] = data.template.headline_font_size['headline_lineheight_h1'];
    payload['headline_lineheight_h2'] = data.template.headline_font_size['headline_lineheight_h2'];
    payload['headline_lineheight_h3'] = data.template.headline_font_size['headline_lineheight_h3'];
    payload['headline_lineheight_h4'] = data.template.headline_font_size['headline_lineheight_h4'] ;
    payload['headline_spaceafter_h1'] = data.template.headline_font_size['headline_spaceafter_h1'] ? data.template.headline_font_size['headline_spaceafter_h1'] : DefaultStyleGuide.headline_spaceafter_h1;
    payload['headline_spaceafter_h2'] = data.template.headline_font_size['headline_spaceafter_h2'] ? data.template.headline_font_size['headline_spaceafter_h2'] : DefaultStyleGuide.headline_spaceafter_h2;
    payload['headline_spaceafter_h3'] = data.template.headline_font_size['headline_spaceafter_h3'] ? data.template.headline_font_size['headline_spaceafter_h3'] : DefaultStyleGuide.headline_spaceafter_h3;
    payload['headline_spaceafter_h4'] = data.template.headline_font_size['headline_spaceafter_h4'] ? data.template.headline_font_size['headline_spaceafter_h4'] : DefaultStyleGuide.headline_spaceafter_h4;
    payload['headline_name_h1'] = data.template.headline_font_size['headline_name_h1'] ? data.template.headline_font_size['headline_name_h1'] : DefaultStyleGuide.headline_name_h1;
    payload['headline_name_h2'] = data.template.headline_font_size['headline_name_h2'] ? data.template.headline_font_size['headline_name_h2'] : DefaultStyleGuide.headline_name_h2;
    payload['headline_name_h3'] = data.template.headline_font_size['headline_name_h3'] ? data.template.headline_font_size['headline_name_h3'] : DefaultStyleGuide.headline_name_h3;
    payload['headline_name_h4'] = data.template.headline_font_size['headline_name_h4'] ? data.template.headline_font_size['headline_name_h4'] : DefaultStyleGuide.headline_name_h4;
    payload['headline_spacebefore_h1'] = data.template.headline_font_size['headline_spacebefore_h1'] ? data.template.headline_font_size['headline_spacebefore_h1'] : DefaultStyleGuide.headline_spacebefore_h1;
    payload['headline_spacebefore_h2'] = data.template.headline_font_size['headline_spacebefore_h2'] ? data.template.headline_font_size['headline_spacebefore_h2'] : DefaultStyleGuide.headline_spacebefore_h2;
    payload['headline_spacebefore_h3'] = data.template.headline_font_size['headline_spacebefore_h3'] ? data.template.headline_font_size['headline_spacebefore_h3'] : DefaultStyleGuide.headline_spacebefore_h3;
    payload['headline_spacebefore_h4'] = data.template.headline_font_size['headline_spacebefore_h4'] ? data.template.headline_font_size['headline_spacebefore_h4'] : DefaultStyleGuide.headline_spacebefore_h4;
    payload['headline_letterspacing_h1'] = data.template.headline_font_size['headline_letterspacing_h1'] ? data.template.headline_font_size['headline_letterspacing_h1'] : DefaultStyleGuide.headline_letterspacing_h1;
    payload['headline_letterspacing_h2'] = data.template.headline_font_size['headline_letterspacing_h2'] ? data.template.headline_font_size['headline_letterspacing_h2'] : DefaultStyleGuide.headline_letterspacing_h2;
    payload['headline_letterspacing_h3'] = data.template.headline_font_size['headline_letterspacing_h3'] ? data.template.headline_font_size['headline_letterspacing_h3'] : DefaultStyleGuide.headline_letterspacing_h3;
    payload['headline_letterspacing_h4'] = data.template.headline_font_size['headline_letterspacing_h4'] ? data.template.headline_font_size['headline_letterspacing_h4'] : DefaultStyleGuide.headline_letterspacing_h4;    
    payload['headline_font_h1'] = data.template.headline_font_size['headline_font_h1'] ? data.template.headline_font_size['headline_font_h1'] : payload['primary_font'];
    payload['headline_font_h2'] = data.template.headline_font_size['headline_font_h2'] ? data.template.headline_font_size['headline_font_h2'] : payload['primary_font'];
    payload['headline_font_h3'] = data.template.headline_font_size['headline_font_h3'] ? data.template.headline_font_size['headline_font_h3'] : payload['primary_font'];
    payload['headline_font_h4'] = data.template.headline_font_size['headline_font_h4'] ? data.template.headline_font_size['headline_font_h4'] : payload['primary_font'];
    payload['headline_weight_h1'] = data.template.headline_font_size['headline_weight_h1'] ? data.template.headline_font_size['headline_weight_h1'] : DefaultStyleGuide.headline_weight_h1;
    payload['headline_weight_h2'] = data.template.headline_font_size['headline_weight_h2'] ? data.template.headline_font_size['headline_weight_h2'] : DefaultStyleGuide.headline_weight_h2;
    payload['headline_weight_h3'] = data.template.headline_font_size['headline_weight_h3'] ? data.template.headline_font_size['headline_weight_h3'] : DefaultStyleGuide.headline_weight_h3;
    payload['headline_weight_h4'] = data.template.headline_font_size['headline_weight_h4'] ? data.template.headline_font_size['headline_weight_h4'] : DefaultStyleGuide.headline_weight_h4;
    payload['text_size_body1'] = data.template.text_size['text_size_body1'];
    payload['text_size_body2'] = data.template.text_size['text_size_body2'];
    payload['text_size_legal'] = data.template.text_size['text_size_legal'];
    payload['text_lineheight_body1'] = data.template.text_size['text_lineheight_body1'];
    payload['text_lineheight_body2'] = data.template.text_size['text_lineheight_body2'];
    payload['text_lineheight_legal'] = data.template.text_size['text_lineheight_legal'];
    payload['text_spaceafter_body1'] = data.template.text_size['text_spaceafter_body1'] ? data.template.text_size['text_spaceafter_body1'] : DefaultStyleGuide.text_spaceafter_body1;
    payload['text_spaceafter_body2'] = data.template.text_size['text_spaceafter_body2'] ? data.template.text_size['text_spaceafter_body2'] : DefaultStyleGuide.text_spaceafter_body2;
    payload['text_spaceafter_legal'] = data.template.text_size['text_spaceafter_legal'] ? data.template.text_size['text_spaceafter_legal'] : DefaultStyleGuide.text_spaceafter_legal;
    payload['text_name_body1'] = data.template.text_size['text_name_body1'] ? data.template.text_size['text_name_body1'] : DefaultStyleGuide.text_name_body1;
    payload['text_name_body2'] = data.template.text_size['text_name_body2'] ? data.template.text_size['text_name_body2'] : DefaultStyleGuide.text_name_body2;
    payload['text_name_legal'] = data.template.text_size['text_name_legal'] ? data.template.text_size['text_name_legal'] : DefaultStyleGuide.text_name_legal;
    payload['text_spacebefore_body1'] = data.template.text_size['text_spacebefore_body1'] ? data.template.text_size['text_spacebefore_body1'] : DefaultStyleGuide.text_spacebefore_body1;
    payload['text_spacebefore_body2'] = data.template.text_size['text_spacebefore_body2'] ? data.template.text_size['text_spacebefore_body2'] : DefaultStyleGuide.text_spacebefore_body2;
    payload['text_spacebefore_legal'] = data.template.text_size['text_spacebefore_legal'] ? data.template.text_size['text_spacebefore_legal'] : DefaultStyleGuide.text_spacebefore_legal;
    payload['text_letterspacing_body1'] = data.template.text_size['text_letterspacing_body1'] ? data.template.text_size['text_letterspacing_body1'] : DefaultStyleGuide.text_letterspacing_body1;
    payload['text_letterspacing_body2'] = data.template.text_size['text_letterspacing_body2'] ? data.template.text_size['text_letterspacing_body2'] : DefaultStyleGuide.text_letterspacing_body2;
    payload['text_letterspacing_legal'] = data.template.text_size['text_letterspacing_legal'] ? data.template.text_size['text_letterspacing_legal'] : DefaultStyleGuide.text_letterspacing_legal;
    payload['text_font_body1'] = data.template.text_size['text_font_body1'] ? data.template.text_size['text_font_body1'] : payload['text_font'];
    payload['text_font_body2'] = data.template.text_size['text_font_body2'] ? data.template.text_size['text_font_body2'] : payload['text_font'];
    payload['text_font_legal'] = data.template.text_size['text_font_legal'] ? data.template.text_size['text_font_legal'] : payload['text_font'];
    payload['text_weight_body1'] = data.template.text_size['text_weight_body1'] ? data.template.text_size['text_weight_body1'] : DefaultStyleGuide.text_weight_body1;
    payload['text_weight_body2'] = data.template.text_size['text_weight_body2'] ? data.template.text_size['text_weight_body2'] : DefaultStyleGuide.text_weight_body2;
    payload['text_weight_legal'] = data.template.text_size['text_weight_legal'] ? data.template.text_size['text_weight_legal'] : DefaultStyleGuide.text_weight_legal;
    payload['button_text_size'] = data.template.button_font_size['button_text_size'];
    payload['button_text_lineheight'] = data.template.button_font_size['button_text_lineheight'];
    payload['button_text_weight'] = data.template.button_font_size['button_text_weight'] ? data.template.button_font_size['button_text_weight'] : DefaultStyleGuide.button_text_weight;
    payload['button_text_letterspacing'] = data.template.button_font_size['button_text_letterspacing'] ? data.template.button_font_size['button_text_letterspacing'] : DefaultStyleGuide.button_text_letterspacing;
    payload['layout_style'] = data.template.layout_details['layout_style'] ? data.template.layout_details['layout_style'] : DefaultStyleGuide.layout_style;
    payload['layout_grid_swicth'] = data.template.layout_details['layout_grid_swicth'] ? data.template.layout_details['layout_grid_swicth'] : DefaultStyleGuide.layout_grid_swicth;
    payload['layout_grid_margins'] = data.template.layout_details['layout_grid_margins'] ? data.template.layout_details['layout_grid_margins'] : DefaultStyleGuide.layout_grid_margins;
    payload['layout_grid_columns'] = data.template.layout_details['layout_grid_columns'] ? data.template.layout_details['layout_grid_columns'] : DefaultStyleGuide.layout_grid_columns;
    payload['layout_grid_gutters'] = data.template.layout_details['layout_grid_gutters'] ? data.template.layout_details['layout_grid_gutters'] : DefaultStyleGuide.layout_grid_gutters;
    payload['layout_padding_left'] = data.template.layout_details['layout_padding_left'] ? data.template.layout_details['layout_padding_left'] : DefaultStyleGuide.layout_padding_left;
    payload['layout_padding_top'] = data.template.layout_details['layout_padding_top'] ? data.template.layout_details['layout_padding_top'] : DefaultStyleGuide.layout_padding_top;
    payload['layout_padding_bottom'] = data.template.layout_details['layout_padding_bottom'] ? data.template.layout_details['layout_padding_bottom'] : DefaultStyleGuide.layout_padding_bottom;
    payload['layout_padding_right'] = data.template.layout_details['layout_padding_right'] ? data.template.layout_details['layout_padding_right'] : DefaultStyleGuide.layout_padding_right;
    payload['button_padding_top'] = data.template.button_font_size['button_padding_top'] ? data.template.button_font_size['button_padding_top'] : getPaddingForButton(payload['button_text_lineheight']);
    payload['button_padding_bottom'] = data.template.button_font_size['button_padding_bottom'] ? data.template.button_font_size['button_padding_bottom'] : getPaddingForButton(payload['button_text_lineheight']);
    payload['button_padding_left'] = data.template.button_font_size['button_padding_left'] ? data.template.button_font_size['button_padding_left'] : DefaultStyleGuide.button_padding_left;
    payload['button_padding_right'] = data.template.button_font_size['button_padding_right'] ? data.template.button_font_size['button_padding_right'] : DefaultStyleGuide.button_padding_right;
    payload['button_background_color'] = data.template['default_button_color'];
    payload['button_text_color'] = data.template['default_button_text_color'];
    payload['button_radius'] = data.template['button_shape'];
    payload['button_radius_switch'] = data.template['button_radius_switch'] ? (data.template['button_radius_switch'] === "true") : DefaultStyleGuide.button_radius_switch;
    payload['address'] = data.template['address'];
    payload['zip'] = data.template['postal_code'];
    payload['store'] = data.template['state'];
    payload['country'] = data.template['country'];    
    payload['c_logo_width'] = data.template['c_logo_width']?data.template['c_logo_width'] : 84;
    payload['menuList'] = data.menuData.map((item:any)=>({title:item.m_title,link:item.m_link}));
   // payload['ParameterList'] = data.ParametersData.map((item:any)=>({Parametertype:item.parameter_type,fieldLabel:item.field_label,ParameterName:item.parameter_name,ParameterValue:item.parameter_value}));
	payload['fontsList'] = data.fonts;
	payload['mainColorPallet'] = data.template.main_color_pallet;
	payload['google_font'] = data.template?.font_family?.google_font ? data.template.font_family.google_font : '';
	payload['custom_font'] = data.template?.font_family?.custom_font ? data.template.font_family.custom_font : '';
	payload['custom_font_name'] = data.template?.font_family?.custom_font?.family ? data.template.font_family.custom_font?.family : '';
	payload['google_font_name'] = data.template?.font_family?.google_font?.family ? data.template.font_family.google_font?.family : '';
	payload['headline_custom_font'] = data.template?.custom_font_family?.headline_custom_font ? data.template?.custom_font_family?.headline_custom_font : 'none';
	payload['body_custom_font'] = data.template?.custom_font_family?.body_custom_font ? data.template?.custom_font_family?.body_custom_font : 'none';
	payload['cta_custom_font'] = data.template?.custom_font_family?.cta_custom_font ? data.template?.custom_font_family?.cta_custom_font : 'none';

    if(data.template?.font_family?.google_font && data.template?.font_family?.google_font?.family !== '' && !data.template?.custom_font_family){
        payload['headline_custom_font'] = data.template?.font_family?.google_font?.family;
        payload['body_custom_font'] = data.template?.font_family?.google_font?.family;
        payload['cta_custom_font'] = data.template?.font_family?.google_font?.family;
    }
    if(data.template?.font_family?.custom_font && data.template?.font_family?.custom_font?.family !== ''&& !data.template?.custom_font_family){
        payload['headline_custom_font'] = data.template?.font_family?.custom_font?.family;
        payload['body_custom_font'] = data.template?.font_family?.custom_font?.family;
        payload['cta_custom_font'] = data.template?.font_family?.custom_font?.family;
    }

    payload['headline_custom_h1'] = data.template?.custom_font_family?.headline_custom_h1 ? data.template?.custom_font_family?.headline_custom_h1 : payload['headline_custom_font'];
    payload['headline_custom_h2'] = data.template?.custom_font_family?.headline_custom_h2 ? data.template.custom_font_family?.headline_custom_h2 : payload['headline_custom_font'];
    payload['headline_custom_h3'] = data.template?.custom_font_family?.headline_custom_h3 ? data.template.custom_font_family?.headline_custom_h3 : payload['headline_custom_font'];
    payload['headline_custom_h4'] = data.template?.custom_font_family?.headline_custom_h4 ? data.template.custom_font_family?.headline_custom_h4 : payload['headline_custom_font'];
    payload['headline_weight_nb_h1'] = data.template.headline_font_size['headline_weight_nb_h1'] ? data.template.headline_font_size['headline_weight_nb_h1'] : changeWeightToNumber(payload['headline_weight_h1']);
    payload['headline_weight_nb_h2'] = data.template.headline_font_size['headline_weight_nb_h2'] ? data.template.headline_font_size['headline_weight_nb_h2'] : changeWeightToNumber(payload['headline_weight_h2']);
    payload['headline_weight_nb_h3'] = data.template.headline_font_size['headline_weight_nb_h3'] ? data.template.headline_font_size['headline_weight_nb_h3'] : changeWeightToNumber(payload['headline_weight_h3']);
    payload['headline_weight_nb_h4'] = data.template.headline_font_size['headline_weight_nb_h4'] ? data.template.headline_font_size['headline_weight_nb_h4'] : changeWeightToNumber(payload['headline_weight_h4']);

    payload['text_custom_body1'] = data.template?.custom_font_family?.text_custom_body1 ? data.template.custom_font_family?.text_custom_body1 : payload['body_custom_font'];
    payload['text_custom_body2'] = data.template?.custom_font_family?.text_custom_body2 ? data.template.custom_font_family?.text_custom_body2 : payload['body_custom_font'];
    payload['text_custom_legal'] = data.template?.custom_font_family?.text_custom_legal ? data.template.custom_font_family?.text_custom_legal : payload['body_custom_font'];
    payload['text_weight_nb_body1'] = data.template.text_size['text_weight_nb_body1'] ? data.template.text_size['text_weight_nb_body1'] : changeWeightToNumber(payload['text_weight_body1']);
    payload['text_weight_nb_body2'] = data.template.text_size['text_weight_nb_body2'] ? data.template.text_size['text_weight_nb_body2'] : changeWeightToNumber(payload['text_weight_body2']);
    payload['text_weight_nb_legal'] = data.template.text_size['text_weight_nb_legal'] ? data.template.text_size['text_weight_nb_legal'] : changeWeightToNumber(payload['text_weight_legal']);


    payload['button_text_weight_nb'] = data.template.button_font_size['button_text_weight_nb'] ? data.template.button_font_size['button_text_weight_nb'] : changeWeightToNumber(payload['button_text_weight']);
    payload['button_text_fixed_lineheight'] = data.template.button_font_size['button_text_fixed_lineheight'] ? data.template.button_font_size['button_text_fixed_lineheight'] : DefaultStyleGuide.button_text_fixed_lineheight;

    const  socialLinkList:any=[]
    socialLinkItems.forEach(name=>{
        if(data.template[name] && data.template[name] !== ''){
            if(data.template.social_icon_url && data.template.social_icon_url[name]  && data.template.social_icon_url[name] !== null){
                socialLinkList.push({title:name,link:data.template[name],src:decodeURI(data.template.social_icon_url[name])});
            } else socialLinkList.push({title:name,link:data.template[name],src:""});
        }
    })

    data?.template?.main_color_pallet?.forEach((item:any)=>{
        let list = item.list_default;
        if(list && list.length > 0){
        list.forEach((element:any) => {
            if(item?.dark){
                payload[element+"_dark"] = item?.dark;
            } else {
                payload[element+"_dark"] = item?.value
            }
        });
        }     
    })
      

    data?.fonts?.forEach((option:any) => {
        if(option.name === data.template['headline_font']){            
            payload['primary_font_full'] = option.family;
        }
        if(option.name === data.template.headline_font_size['headline_font_h1']){            
            payload['headline_font_full_h1'] = option.family;
        }
        if(option.name === data.template.headline_font_size['headline_font_h2']){            
            payload['headline_font_full_h2'] = option.family;
        }
        if(option.name === data.template.headline_font_size['headline_font_h3']){            
            payload['headline_font_full_h3'] = option.family;
        }
        if(option.name === data.template.headline_font_size['headline_font_h4']){            
            payload['headline_font_full_h4'] = option.family;
        }
        
        if(option.name === data.template['text_font']){            
            payload['text_font_full'] = option.family;
        }
        if(option.name === data.template.text_size['text_font_body1']){            
            payload['text_font_full_body1'] = option.family;
        }
        if(option.name === data.template.text_size['text_font_body2']){            
            payload['text_font_full_body2'] = option.family;
        }
        if(option.name === data.template.text_size['text_font_legal']){            
            payload['text_font_full_legal'] = option.family;
        }

        if(option.name === data.template['button_font']){            
            payload['button_font_full'] = option.family;
        }
    });
    payload['socialLinkList'] = socialLinkList
    payload['logo'] = data.c_logo?{file:null,src:data.c_logo,src_url:data.c_logo}:null
	if(data.fonts){
		var i=0;
		for(i=0; i < data.fonts.length; i++){
			if(data.fonts[i].css_link){
				var head = document.head;
				var link = document.createElement("link");

				link.type = "text/css";
				link.rel = "stylesheet";
				link.href = data.fonts[i].css_link;
				head.appendChild(link);
			}
			//head.removeChild(link);
			
		}
	}

    if(data.template?.font_family?.list_custom_fonts){
        payload['list_custom_fonts'] = data.template?.font_family?.list_custom_fonts;
    } else {
        if(payload['google_font'] && payload['google_font_name']){
            payload['list_custom_fonts'] = getDefaultGoogleFontItem(payload['google_font']);
        }
        else if(payload['custom_font'] && payload['custom_font_name']){
            payload['list_custom_fonts'] = getDefaultCustomFontItem(payload['custom_font'])
        } else {
            payload['list_custom_fonts'] = [];
        }
    }

    let hasKeyCustomFont = false; 
    const custom_font_family = data.template?.custom_font_family;
    if(custom_font_family){
        Object.entries(data.template?.custom_font_family).forEach(([key, value]) => {
            if (key.startsWith("key_")) {
                payload[key] = value;
                hasKeyCustomFont = true;
            }
        });
    }

    if(!hasKeyCustomFont){ // first load when key not added yet
        DefaultCustomNameList?.forEach((item:any) => {
            let itemHasKey = false;
            payload['list_custom_fonts']?.forEach((custom:any)=>{
                if(payload[item] && custom?.family && payload[item] === custom?.family){
                    payload["key_"+item] = custom.key;
                    itemHasKey = true;
                }
            })
            if(!itemHasKey){
                payload["key_"+item] = "key_empty";
            }
        });
    }

    
   return payload
}


const socialLinkItems = ['facebook_url','tiktok_url','instagram_url','youtube_url','linkedin_url','twitter_url','pinterest_url','messenger_url','snapchat_url']
