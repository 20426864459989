import React, { memo, useEffect, useState }  from "react";
import Typography from "@material-ui/core/Typography";
import { Box, Switch } from "@material-ui/core";
import { useStyles } from "../styles";
// import GoogleFonts from "./GoogleFonts";
// import CustomFont from "./CustomFont";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";
import { DefaultStyleGuide } from "../utilities/DefaultStyleGuide";
import { addField } from "../../../../redux/actions/styleGuideActions";
import { AutoSave } from "../StyleGuideAutosave";
import CustomFontContainer from "./FullCustomFonts/CustomFontContainer";
import AddCustomFonts from "./FullCustomFonts/AddCustomFonts";
import { getDefaultGoogleFontItem, updateCustomCssStyleSheet } from "../../../../../utils/Functions";
interface Props {}

const FontFamily: React.FC<Props> = () => {
  const classes = useStyles();
  const state = useSelector((state: any) => state.styleGuide);
  const dispatch = useDispatch();
  // const [customFontsSelected, setCustomFontsSelected] = useState('googlefonts');
  // eslint-disable-next-line
  const [radioCustomFonts, setRadioCustomFonts] = useState(false);
  const [calledListCustoms, setCalledListCustoms] = useState(false);

  // const handleChangeFontStyle = (event:any) => {
  //   setCustomFontsSelected(event.target.value); 
  //       dispatch(addField("font_type", event.target.value));

  // };
  // const handleChangeGoogleFonts = () => {
  //   setCustomFontsSelected("googlefonts");
   
  // };
  // const handleChangeCustomFonts = () => {
  //   setCustomFontsSelected("customfonts");
  // };
  // const handleChangeRadioCustomFonts = (event:any) => {
  //   setRadioCustomFonts(event.target.checked); 
  //   if(event.target.checked === false && (state?.custom_font?.family === "Custom" || state?.google_font?.family !== "")){
  //     dispatch(addField("google_font", DefaultStyleGuide.google_font));
  //     // dispatch(addField("custom_font", DefaultStyleGuide.custom_font));
  //     dispatch(addField("custom_font_visible", false));
  //     dispatch(addField("headline_custom_font", "none"));  // TO delete Headline custom_font  
  //     dispatch(addField("headline_custom_h1", "none"));
  //     dispatch(addField("headline_custom_h2", "none"));
  //     dispatch(addField("headline_custom_h3", "none"));
  //     dispatch(addField("headline_custom_h4", "none"));
  //     dispatch(addField("body_custom_font", "none"));   
  //     dispatch(addField("text_custom_body1", "none"));
  //     dispatch(addField("text_custom_body2", "none"));
  //     dispatch(addField("text_custom_legal", "none"));
  //     dispatch(addField("cta_custom_font", "none"));
  //     AutoSave();
  //     setCustomFontsSelected("googlefonts");   
     
  //   }
  //   if(event.target.checked === true){      
  //     dispatch(addField("custom_font_visible", true));
  //   } 
  // };
  
  // useEffect(()=>{
  //   if(state?.custom_font?.family && state?.custom_font?.family !== "" && customFontsSelected !== "Custom"){
  //     setCustomFontsSelected("customfonts"); 
  //     setRadioCustomFonts(true);
  //     dispatch(addField("custom_font_visible", true));
  //   }
  //   // eslint-disable-next-line
  // },[state?.custom_font?.family]);

  const handleChangeRadioCustomFonts = (event:any) => {
    setRadioCustomFonts(event.target.checked); 
    if(event.target.checked === false){
      dispatch(addField("list_custom_fonts", []));
      // dispatch(addField("custom_font", DefaultStyleGuide.custom_font));
      dispatch(addField("custom_font_visible", false));
      dispatch(addField("headline_custom_font", "none"));  // TO delete Headline custom_font  
      dispatch(addField("headline_custom_h1", "none"));
      dispatch(addField("headline_custom_h2", "none"));
      dispatch(addField("headline_custom_h3", "none"));
      dispatch(addField("headline_custom_h4", "none"));
      dispatch(addField("body_custom_font", "none"));   
      dispatch(addField("text_custom_body1", "none"));
      dispatch(addField("text_custom_body2", "none"));
      dispatch(addField("text_custom_legal", "none"));
      dispatch(addField("cta_custom_font", "none"));
      AutoSave();
      // setCustomFontsSelected("googlefonts");
     
    }
    if(event.target.checked === true){      
      dispatch(addField("custom_font_visible", true));
      dispatch(addField("list_custom_fonts",getDefaultGoogleFontItem(DefaultStyleGuide.google_font)));
    } 
  };

  // useEffect(()=>{
  //   if(state?.google_font?.family){
  //     setRadioCustomFonts(true);
  //     dispatch(addField("custom_font_visible", true));
  //   }
  //   // eslint-disable-next-line
  // },[state?.google_font?.family]);

  useEffect(()=>{
    if(state?.list_custom_fonts && !calledListCustoms){
      updateCustomCssStyleSheet(state?.list_custom_fonts);
      setCalledListCustoms(true);
      if(state?.list_custom_fonts.length > 0){
        setRadioCustomFonts(true);
        dispatch(addField("custom_font_visible", true));
      }
    }
    // if(state?.list_custom_fonts){
    //   console.log("CALLED");
    //   setRadioCustomFonts(true);
    //   dispatch(addField("custom_font_visible", true));
    // }
    // eslint-disable-next-line
  },[state?.list_custom_fonts]);

  return (
        <Box display="flex" flexDirection="column" className={`${classes.section1} ${classes.section1Font}`}>
          <Box className={classes.switchCustomFonts}>
            <Typography className={`${classes.heading} ${classes.customFontsLabel}`} variant="h6"> 
                Custom Fonts 
            </Typography>
            <Switch
              checked={radioCustomFonts}
              onChange={handleChangeRadioCustomFonts}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Box>
          {(state?.list_custom_fonts?.length > 0) && (<Box className={classes.containerCustomFont}>
            <Box className={classes.alertCustomFonts}>
              <Alert severity="info">
                Custom fonts will only load on some email clients. When not supported the websafe fonts selected below will be used.
              </Alert>
            </Box>
            {/* add new list */}
            {
              state?.list_custom_fonts?.map((Item : any, index:any) => {
                return (      
                  <Box key={Item?.key ? Item?.key : index} className={classes.wrapperCustomFont}>            
                    <CustomFontContainer data={Item} /> 
                  </Box>
                )
                })
            }
            <AddCustomFonts />
            {/* <Box>
              <Typography variant="caption" className={classes.typographyText}>
                Select a Google Font or upload a Custom Font.
              </Typography>
            </Box>
            <Box className={classes.fontContainerRadio} style={{display:"flex"}}>
              <Box className={customFontsSelected !== 'googlefonts' ? classes.radioGoogleFontParent : ""} style={{display:"flex",alignItems:"center"}}>
                <Radio
                  checked={customFontsSelected === 'googlefonts'}
                  onChange={handleChangeFontStyle}
                  value="googlefonts"
                  name="radio-button-googlefonts"
                  color="primary"
                  className={classes.radioGoogleFont}
                />
                <Box style={{ paddingRight: 10, display: "flex"}}> 
                  <svg height="17" viewBox="0 0 509.58086729 397.8808683" xmlns="http://www.w3.org/2000/svg">
                    <path className={classes.googleLogo2} d="m0 387.4 245.52-385.39 131.6 83.84-192.11 301.55z" fill="#fbbc04"/>
                    <path className={classes.googleLogo3} d="m240.33 0h151.13v387.4h-151.13z" fill="#1a73e8"/>
                    <circle className={classes.googleLogo1} cx="83.72" cy="81.35" fill="#ea4335" r="74.91"/>
                    <path className={classes.googleLogo5} d="m499.1 279.76a107.64 107.64 0 0 1 -107.64 107.64v-215.27a107.64 107.64 0 0 1 107.64 107.63z" fill="#34a853"/>
                    <path className={classes.googleLogo4} d="m391.46 172.13v215.27a107.64 107.64 0 0 1 0-215.27z" fill="#0d652d"/>
                    <path className={classes.googleLogo7} d="m474.3 89.29a82.85 82.85 0 0 1 -82.84 82.84v-165.69a82.85 82.85 0 0 1 82.84 82.85z" fill="#1a73e8"/>
                    <path className={classes.googleLogo6} d="m391.46 6.44v165.69a82.85 82.85 0 0 1 0-165.69z" fill="#174ea6"/>
                  </svg>
                </Box>
                <Typography variant="body1" onClick={handleChangeGoogleFonts} style={{cursor:"pointer",marginRight: 36}}>
                  Google Fonts
                </Typography>
              </Box>
              <Box style={{display:"flex",alignItems:"center"}}>
                <Box>
                  <Radio
                    checked={customFontsSelected === 'customfonts'}
                    onChange={handleChangeFontStyle}
                    value="customfonts"
                    name="radio-button-customfonts"
                    color="primary"
                    className={classes.radioCustomFonts}
                  />
                </Box>
                <Typography variant="body1"  onClick={handleChangeCustomFonts} style={{cursor:"pointer"}}>
                  Custom Fonts
                </Typography>
              </Box>
            </Box>

            {(customFontsSelected === "googlefonts") && (
              <GoogleFonts />
            )}
            {(customFontsSelected === "customfonts" ) && (
              <CustomFont />
            )}             */}
          </Box>)}
        </Box>
  );
};

export default memo(FontFamily);
