import React, { memo} from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { SocialFacebook, SocialTwitter, SocialInstagram, SocialLinkedin, SocialYoutube, SocialPinterest, SocialTiktok, SocialMessenger, SocialSnapchat } from './SocialSVG';
import { DefaultStyleGuide } from '../utilities/DefaultStyleGuide';
import {isAllowMenu} from "../../../../../utils/AuthUtility";
import { addField } from "../../../../redux/actions/styleGuideActions";
interface Props {
  isLayoutActive?:boolean;
  isDarkActive:boolean;
}

const StylePrototype: React.FC<Props> = (props:Props) => {
  const state = useSelector((state: any) => state.styleGuide);
  // const getFontType = (link:any) => {
  //   var url = link.split('.');
  //   var type = url.pop();
  //   switch(type){
  //    case 'ttf' : return "truetype"; 
  //    default : return type;
  //   }
  // }
  const titleOptions: any = {
    facebook_url: <SocialFacebook moduleColor={state.primary_color} accentColor={state.social_accent_color}  />,
    twitter_url: <SocialTwitter moduleColor={state.primary_color} accentColor={state.social_accent_color} />,
    instagram_url: <SocialInstagram moduleColor={state.primary_color} accentColor={state.social_accent_color} />,
    linkedin_url: <SocialLinkedin moduleColor={state.primary_color} accentColor={state.social_accent_color} />,
    youtube_url: <SocialYoutube moduleColor={state.primary_color} accentColor={state.social_accent_color} />,
    pinterest_url: <SocialPinterest moduleColor={state.primary_color} accentColor={state.social_accent_color} />,
    tiktok_url: <SocialTiktok moduleColor={state.primary_color} accentColor={state.social_accent_color} />,
    messenger_url: <SocialMessenger moduleColor={state.primary_color} accentColor={state.social_accent_color} />,
    snapchat_url: <SocialSnapchat moduleColor={state.primary_color} accentColor={state.social_accent_color} />,
  };
  const useStyles = makeStyles((theme) => ({
    "@global": {
      ":root": {
        "--offset": state?.layout_grid_margins+"px",
        "--max_width": (state?.layout_grid_width - state?.layout_grid_margins*2 - state?.layout_grid_gutters*2)+"px",
        "--columns": 12,
        "--gutter": state?.layout_grid_gutters+"px",
        "--baseline": state?.layout_grid_gutters+"px",
        "--baseline-shift": "calc(var(--baseline) / 2)",
        "--line-thickness":" 1px",
        "--color":" hsla(204, 80%, 72%, 0.1)",
        "--color-text": "hsla(204, 80%, 72%, 1)",
        "--media-query": 'Desktop',
        "--repeating-width": "calc(100% / var(--columns))",
        "--column-width": "calc((100% / var(--columns)) - var(--gutter))",
        "--background-width": "calc(100% + var(--gutter))",
        "--background-columns": `repeating-linear-gradient(
          to right,
          var(--color),
          var(--color) var(--line-thickness),
          var(--color) var(--column-width),
          transparent var(--column-width),
          transparent var(--repeating-width)
        )`,
        "--background-baseline": `repeating-linear-gradient(
          to bottom,
          var(--color),
          var(--color) 1px,
          transparent 1px,
          transparent var(--baseline)
        )`,
      },
      // "@font-face": {
      //   fontFamily: state?.google_font?.family,
      //   src: state?.google_font?.files?.regular ? "url("+  state?.google_font?.files?.regular +") format('"+getFontType(state?.google_font?.files?.regular)+"')" : ""
      // },      
      // " @font-face": {
      //   fontFamily: state?.custom_font?.RegularNormal ? state?.custom_font?.family : "",
      //   fontStyle: "normal",fontWeight: "normal",
      //   src: state?.custom_font?.family ? "url('"+state?.custom_font?.RegularNormal +"') format('"+ getFontType(state?.custom_font?.RegularNormal) +"')" : "",

      // },      
      // " @font-face ": {
      //   fontFamily: state?.custom_font?.RegularItalic ? state?.custom_font?.family : "",
      //   fontStyle: "italic",fontWeight: "normal",
      //   src: state?.custom_font?.family ? "url('"+state?.custom_font?.RegularItalic +"') format('"+ getFontType(state?.custom_font?.RegularItalic) +"')" : ""
      // },      
      // " @font-face  ": {
      //   fontFamily: state?.custom_font?.BoldNormal ? state?.custom_font?.family : "",
      //   fontStyle: "normal",fontWeight: "bold",
      //   src: state?.custom_font?.family ? "url('"+state?.custom_font?.BoldNormal +"') format('"+ getFontType(state?.custom_font?.BoldNormal) +"')" : ""
      // },    
      // " @font-face   ": {
      //   fontFamily: state?.custom_font?.BoldItalic ? state?.custom_font?.family : "",
      //   fontStyle: "italic",fontWeight: "bold",
      //   src: state?.custom_font?.family ? "url('"+state?.custom_font?.BoldItalic +"') format('"+ getFontType(state?.custom_font?.BoldItalic) +"')" : ""
      // },
    },
    logoText: {
      fontSize: 36,
      fontFamily: state.primary_font_full ? state.primary_font_full : "mulish",
      fontWeight: 900,
      color: "#A0A0A0",
    },
    linkButton: {
      cursor: "pointer",
      fontSize:14,
      color: state.text_color ? state.text_color :  DefaultStyleGuide.text_color,
      marginTop: 20,
      marginLeft: 9,
      marginRight: 9,
      // fontFamily: "mulish",
    },
    visualContainer:{
      "& img":{
        height: "auto", 
        maxWidth: "580px", 
        width: "100%",
      },
      paddingTop:30
    },
    visualWrapper:{
      paddingLeft:30,
      paddingRight:30,
    },
    headerContainer: {
      margin: "1.5rem",
      height: "7rem",
      border: `1rem solid ${
        state.secondary_color ? state.secondary_color : "#EBF2FF"
      }`,
      width: "75%",
      backgroundColor: state.primary_color ? state.primary_color : "#ffffff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    headerText: {
      fontSize: state.headline_size_h1 ? state.headline_size_h1 + "px" : 30,
      fontFamily: state.primary_font_full ? state.primary_font_full : "mulish",
      fontWeight: 900,
      color: state.header_text_color ? state.header_text_color : "#fff",
    },
    headingFamilyText:{
      fontFamily: ((state?.headline_custom_font && state?.headline_custom_font !== "none" ) ? state?.headline_custom_font + ", " : "") +
        (state.primary_font_full ? state.primary_font_full : DefaultStyleGuide.primary_font),
      // fontFamily: state.primary_font_full ? state.primary_font_full : DefaultStyleGuide.primary_font,
    },
    headingFamilyText1:{
      fontFamily: ((state?.headline_custom_h1 && state?.headline_custom_h1 !== "none" ) ? state?.headline_custom_h1 + ", " : "") +
        (state.headline_font_full_h1 ? state.headline_font_full_h1 : DefaultStyleGuide.headline_font_h1),
    },
    headingFamilyText2:{
      fontFamily: ((state?.headline_custom_h2 && state?.headline_custom_h2 !== "none" ) ? state?.headline_custom_h2 + ", " : "") +
        (state.headline_font_full_h2 ? state.headline_font_full_h2 : DefaultStyleGuide.headline_font_h2),
    },
    headingFamilyText3:{
      fontFamily: ((state?.headline_custom_h3 && state?.headline_custom_h3 !== "none" ) ? state?.headline_custom_h3 + ", " : "") +
        (state.headline_font_full_h3 ? state.headline_font_full_h3 : DefaultStyleGuide.headline_font_h3),
    },
    headingFamilyText4:{
      fontFamily: ((state?.headline_custom_h4 && state?.headline_custom_h4 !== "none" ) ? state?.headline_custom_h4 + ", " : "") +
        (state.headline_font_full_h4 ? state.headline_font_full_h4 : DefaultStyleGuide.headline_font_h4),
    },

    headingText: {
      fontSize: state.headline_size_h1 ? state.headline_size_h1 + "px" : DefaultStyleGuide.headline_size_h1 + "px",
      fontWeight: state.headline_weight_nb_h1 ? state.headline_weight_nb_h1 : DefaultStyleGuide.headline_weight_nb_h1,
      marginBottom: state.headline_spaceafter_h1 ? state.headline_spaceafter_h1 + "px" : DefaultStyleGuide.headline_spaceafter_h1 + "px",
      marginTop: state.headline_spacebefore_h1 ? state.headline_spacebefore_h1 + "px" : DefaultStyleGuide.headline_spacebefore_h1 + "px",
      letterSpacing: state.headline_letterspacing_h1 ? state.headline_letterspacing_h1 + "px" : DefaultStyleGuide.headline_letterspacing_h1 + "px",
      color: state.hedline_color ? state.hedline_color : DefaultStyleGuide.hedline_color,
      textAlign: "center",
      paddingTop:20,
      lineHeight: state.headline_lineheight_h1 ? state.headline_lineheight_h1 + "px" : DefaultStyleGuide.headline_lineheight_h1 + "px"  ,
      // paddingLeft:30,
      // paddingRight:30,
    },
    headingText2:{
      paddingLeft: 20,
      paddingRight: 20,
      // paddingBottom: 20,
      paddingTop: 30,
      fontSize: state.headline_size_h2 ? state.headline_size_h2 + "px" : DefaultStyleGuide.headline_size_h2 + "px",
      // fontFamily: state.primary_font_full ? state.primary_font_full : DefaultStyleGuide.primary_font,
      color: state.hedline_color ? state.hedline_color : DefaultStyleGuide.hedline_color,
      lineHeight: state.headline_lineheight_h2 ? state.headline_lineheight_h2 + "px" : DefaultStyleGuide.headline_lineheight_h2 + "px" ,
      fontWeight: state.headline_weight_nb_h2 ? state.headline_weight_nb_h2 : DefaultStyleGuide.headline_weight_nb_h2,
      marginBottom: state.headline_spaceafter_h2 ? state.headline_spaceafter_h2 + "px" : DefaultStyleGuide.headline_spaceafter_h2 + "px",
      marginTop: state.headline_spacebefore_h2 ? state.headline_spacebefore_h2 + "px" : DefaultStyleGuide.headline_spacebefore_h2 + "px",
      letterSpacing: state.headline_letterspacing_h2 ? state.headline_letterspacing_h2 + "px" : DefaultStyleGuide.headline_letterspacing_h2 + "px",
    },
    headingText3:{
      paddingLeft: 20,
      paddingRight: 20,
      // paddingBottom: 20,
      fontSize: state.headline_size_h3 ? state.headline_size_h3 + "px" : DefaultStyleGuide.headline_size_h3 + "px",
      // fontFamily: state.primary_font_full ? state.primary_font_full : DefaultStyleGuide.primary_font,
      color: state.hedline_color ? state.hedline_color : DefaultStyleGuide.hedline_color,
      lineHeight: state.headline_lineheight_h3 ? state.headline_lineheight_h3 + "px" : DefaultStyleGuide.headline_lineheight_h3 + "px" ,
      fontWeight: state.headline_weight_nb_h3 ? state.headline_weight_nb_h3 : DefaultStyleGuide.headline_weight_nb_h3,
      marginBottom: state.headline_spaceafter_h3 ? state.headline_spaceafter_h3 + "px" : DefaultStyleGuide.headline_spaceafter_h3 + "px",
      marginTop: state.headline_spacebefore_h3 ? state.headline_spacebefore_h3 + "px" : DefaultStyleGuide.headline_spacebefore_h3 + "px",
      letterSpacing: state.headline_letterspacing_h3 ? state.headline_letterspacing_h3 + "px" : DefaultStyleGuide.headline_letterspacing_h3 + "px",
      textAlign: "center",
      paddingTop:20,
    },
    headingText4:{
      paddingLeft: 20,
      paddingRight: 20,
      // paddingBottom: 20,
      fontSize: state.headline_size_h4 ? state.headline_size_h4 + "px" : DefaultStyleGuide.headline_size_h4 + "px",
      // fontFamily: state.primary_font_full ? state.primary_font_full : DefaultStyleGuide.primary_font,
      color: state.hedline_color ? state.hedline_color : DefaultStyleGuide.hedline_color,
      lineHeight: state.headline_lineheight_h4 ? state.headline_lineheight_h4 + "px" : DefaultStyleGuide.headline_lineheight_h4 + "px" ,
      fontWeight: state.headline_weight_nb_h4 ? state.headline_weight_nb_h4 : DefaultStyleGuide.headline_weight_nb_h4,
      marginBottom: state.headline_spaceafter_h4 ? state.headline_spaceafter_h4 + "px" : DefaultStyleGuide.headline_spaceafter_h4 + "px",
      marginTop: state.headline_spacebefore_h4 ? state.headline_spacebefore_h4 + "px" : DefaultStyleGuide.headline_spacebefore_h4 + "px",
      letterSpacing: state.headline_letterspacing_h4 ? state.headline_letterspacing_h4 + "px" : DefaultStyleGuide.headline_letterspacing_h4 + "px",
      textAlign: "center",
    },
    bodyFamilyText:{      
      fontFamily: ((state?.body_custom_font && state?.body_custom_font !== "none" )? state?.body_custom_font + ", " : "") +
        (state.text_font_full ? state.text_font_full : DefaultStyleGuide.text_font),
      // fontFamily: state.text_font_full ? state.text_font_full : DefaultStyleGuide.text_font,
    },
    bodyFamilyText1:{
      fontFamily: ((state?.text_custom_body1 && state?.text_custom_body1 !== "none" ) ? state?.text_custom_body1 + ", " : "") +
        (state.text_font_full_body1 ? state.text_font_full_body1 : DefaultStyleGuide.text_font_body1),
    },
    bodyFamilyText2:{
      fontFamily: ((state?.text_custom_body2 && state?.text_custom_body2 !== "none" ) ? state?.text_custom_body2 + ", " : "") +
        (state.text_font_full_body2 ? state.text_font_full_body2 : DefaultStyleGuide.text_font_body2),
    },
    bodyFamilyLegal:{
      fontFamily: ((state?.text_custom_legal && state?.text_custom_legal !== "none" ) ? state?.text_custom_legal + ", " : "") +
        (state.text_font_full_legal ? state.text_font_full_legal : DefaultStyleGuide.text_font_legal),
    },
    bodyText: {
      // marginTop: 20,
      // paddingLeft:30,
      // paddingRight:30,
      textAlign: "center",
      color: state.text_color ? state.text_color :  DefaultStyleGuide.text_color,
      fontSize: state.text_size_body1 ? state.text_size_body1 + "px" : DefaultStyleGuide.text_size_body1 + "px",
      lineHeight: state.text_lineheight_body1 ? state.text_lineheight_body1 + "px" :  DefaultStyleGuide.text_lineheight_body1 + "px" ,
      fontWeight: state.text_weight_nb_body1 ? state.text_weight_nb_body1 : DefaultStyleGuide.text_weight_nb_body1,
      marginTop: state.text_spacebefore_body1 ? state.text_spacebefore_body1 + "px" : DefaultStyleGuide.text_spacebefore_body1 + "px",
      marginBottom: state.text_spaceafter_body1 ? state.text_spaceafter_body1 + "px" : DefaultStyleGuide.text_spaceafter_body1 + "px",
      letterSpacing: state.text_letterspacing_body1 ? state.text_letterspacing_body1 + "px" : DefaultStyleGuide.text_letterspacing_body1 + "px",
    },
    bodyText2:{
      fontSize: state.text_size_body2 ? state.text_size_body2 + "px" : DefaultStyleGuide.text_size_body2 + "px",
      lineHeight: state.text_lineheight_body2 ? state.text_lineheight_body2 + "px" :  DefaultStyleGuide.text_lineheight_body2 + "px" ,
      color: state.text_color ? state.text_color :  DefaultStyleGuide.text_color,
      paddingLeft: 20,
      paddingRight: 20,
      // paddingBottom: 24,
      // fontFamily: state.text_font_full ? state.text_font_full : DefaultStyleGuide.text_font,
      fontWeight: state.text_weight_nb_body2 ? state.text_weight_nb_body2 : DefaultStyleGuide.text_weight_nb_body2,
      marginTop: state.text_spacebefore_body2 ? state.text_spacebefore_body2 + "px" : DefaultStyleGuide.text_spacebefore_body2 + "px",
      marginBottom: state.text_spaceafter_body2 ? state.text_spaceafter_body2 + "px" : DefaultStyleGuide.text_spaceafter_body2 + "px",
      letterSpacing: state.text_letterspacing_body2 ? state.text_letterspacing_body2 + "px" : DefaultStyleGuide.text_letterspacing_body2 + "px",
    },
    footerSocial:{
      paddingTop:15,
      paddingBottom: 15,
      "& span":{
        color: "#9BA7CF", 
        marginLeft: 5, 
        marginRight: 5
      }
    },
    legalText: {
      color: state.text_color ? state.text_color :  DefaultStyleGuide.text_color,      
      // fontFamily: state.text_font_full ? state.text_font_full : DefaultStyleGuide.text_font,
      textAlign: "center",
      fontSize: state.text_size_legal ? state.text_size_legal + "px" : DefaultStyleGuide.text_size_legal + "px",
      lineHeight: state.text_lineheight_legal ? state.text_lineheight_legal + "px" :  DefaultStyleGuide.text_lineheight_legal + "px" ,
      paddingLeft:30,
      paddingRight:30,
      // paddingBottom:30
      fontWeight: state.text_weight_nb_legal ? state.text_weight_nb_legal : DefaultStyleGuide.text_weight_nb_legal,
      marginTop: state.text_spacebefore_legal ? state.text_spacebefore_legal + "px" : DefaultStyleGuide.text_spacebefore_legal + "px",
      marginBottom: state.text_spaceafter_legal ? state.text_spaceafter_legal + "px" : DefaultStyleGuide.text_spaceafter_legal + "px",
      letterSpacing: state.text_letterspacing_legal ? state.text_letterspacing_legal + "px" : DefaultStyleGuide.text_letterspacing_legal + "px",
    },
    legalText1: {
      // paddingBottom:10
      fontWeight: state.text_weight_nb_legal ? state.text_weight_nb_legal : DefaultStyleGuide.text_weight_nb_legal,
      marginTop: state.text_spacebefore_legal ? state.text_spacebefore_legal + "px" : DefaultStyleGuide.text_spacebefore_legal + "px",
      marginBottom: state.text_spaceafter_legal ? state.text_spaceafter_legal + "px" : DefaultStyleGuide.text_spaceafter_legal + "px",
    },
    link: {
      textDecoration: "underline",
      color: state.link_color ? state.link_color : "#0B6ADC",
    },
    buttonFont:{
      fontFamily: ((state?.cta_custom_font && state?.cta_custom_font !== "none" )? state?.cta_custom_font + ", " : "") +
        (state.button_font_full ? state.button_font_full : DefaultStyleGuide.button_font),
      // fontFamily: state.button_font_full ? state.button_font_full : DefaultStyleGuide.button_font,
    },
    button: {     
      fontSize: state.button_text_size ? state.button_text_size + "px" : DefaultStyleGuide.button_text_size + "px" ,
      lineHeight: state.button_text_fixed_lineheight ? state.button_text_fixed_lineheight + "px" : DefaultStyleGuide.button_text_fixed_lineheight + "px" ,
      // width: 156,12px 20px
      // padding:state.button_text_lineheight ? (state.button_text_lineheight - 24)/2 + "px 20px" : "12px 20px" ,
      // height:48,
      paddingLeft: state.button_padding_left ? state.button_padding_left + "px" : DefaultStyleGuide.button_padding_left + "px" ,
      paddingRight: state.button_padding_right ? state.button_padding_right + "px" : DefaultStyleGuide.button_padding_right + "px" ,
      paddingTop: state.button_padding_top ? state.button_padding_top + "px" : DefaultStyleGuide.button_padding_top + "px" ,
      paddingBottom: state.button_padding_bottom ? state.button_padding_bottom + "px" : DefaultStyleGuide.button_padding_bottom + "px" ,
      fontWeight: state.button_text_weight_nb ? state.button_text_weight_nb : DefaultStyleGuide.button_text_weight_nb,
      letterSpacing: state.button_text_letterspacing ? state.button_text_letterspacing + "px" : DefaultStyleGuide.button_text_letterspacing + "px",
      borderRadius: state.button_radius ? `${state.button_radius}px` : DefaultStyleGuide.button_radius  + "px" ,
      textTransform: "none",
      marginTop: 14,
      backgroundColor: state.button_background_color
        ? state.button_background_color
        : "#0B6ADC",
      color: state.button_text_color ? state.button_text_color : "#fff",
      "&:hover":{
        backgroundColor: state.button_background_color
        ? state.button_background_color
        : "#0B6ADC",
      }
    },
    // social_accent_color
    removeEvent:{
      userSelect:"none",
      pointerEvents:"none"
    },
    offerProductWrapper:{
      // paddingLeft:20,
      // paddingRight:20,  //waiting approve
      paddingLeft: state?.layout_grid_gutters ? state?.layout_grid_gutters/2 : DefaultStyleGuide.layout_grid_gutters/2,
      paddingRight: state?.layout_grid_gutters ? state?.layout_grid_gutters/2 : DefaultStyleGuide.layout_grid_gutters/2,
      display: "flex",
    },
    twoProduct:{
      margin: state?.layout_grid_gutters ? state?.layout_grid_gutters/2 : DefaultStyleGuide.layout_grid_gutters/2,
      width: "50%",
      background: state.secondary_color ? state.secondary_color : "#EBF2FF"
    },
    rowImage:{
      padding: 20,
      "& img":{
        maxWidth:280
      }
    },
    rowButton:{
      paddingBottom: 34,
      paddingLeft: 20,
      paddingRight: 20,      
      display: "flex",
      justifyContent: "center",
    },
    buttonProduct:{
      // width: "100%",
      // maxWidth:165,
      marginTop: 0
    },
    wrapper:{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width:(state?.layout_grid_width && state?.layout_grid_margins && state?.layout_grid_gutters) ? (state?.layout_grid_width - state?.layout_grid_margins*2 - state?.layout_grid_gutters * 2) : (DefaultStyleGuide.layout_grid_width - DefaultStyleGuide.layout_grid_margins*2 - DefaultStyleGuide.layout_grid_gutters * 2),
      position: "relative",
      marginLeft: "unset",
      marginRight: "unset",
      backgroundColor:state.primary_color ? state.primary_color : "#ffffff",
      paddingLeft: (state?.layout_grid_margins && state?.layout_grid_gutters) ? (state?.layout_grid_margins*1 + state?.layout_grid_gutters*1) : (DefaultStyleGuide.layout_grid_margins*1 + DefaultStyleGuide.layout_grid_gutters*1),
      paddingRight: (state?.layout_grid_margins && state?.layout_grid_gutters) ? (state?.layout_grid_margins*1 + state?.layout_grid_gutters*1) : (DefaultStyleGuide.layout_grid_margins*1 + DefaultStyleGuide.layout_grid_gutters*1),
    },
    offerProduct:{
      textAlign: "center",
      paddingTop: 20,
      paddingBottom: 10,
      paddingLeft: state?.layout_grid_margins ? (state?.layout_grid_margins*1) : (DefaultStyleGuide.layout_grid_margins*1),
      paddingRight: state?.layout_grid_margins ? (state?.layout_grid_margins*1) : (DefaultStyleGuide.layout_grid_margins*1),
      width: ((state?.layout_grid_width && state?.layout_grid_margins) ? (state?.layout_grid_width - state?.layout_grid_margins*2) : (DefaultStyleGuide.layout_grid_width - DefaultStyleGuide.layout_grid_margins*2)),
    },
    logoImg:{
      paddingTop:30
    },
    wrapperContainerEmail:{
      width:"100%",
      paddingLeft:state?.layout_padding_left + "px",
      paddingTop:state?.layout_padding_top + "px",
      paddingRight:state?.layout_padding_right + "px",
      paddingBottom:state?.layout_padding_bottom + "px",
      backgroundColor: (state?.layout_style === "classic" && state?.email_color ? state?.email_color : (state?.primary_color ? state?.primary_color : "#e3e0e0")),
    },
    layoutSelected:{

    },
    containerEmail:{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "&$layoutSelected":{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        width: state?.layout_style === "modern" ? "100%" : "auto",
        // maxWidth:700,
        // paddingTop: 40,
        // paddingBottom: 40,
        position:"relative",
        "&:before" :{
          content: '""',
          position: "absolute",
          top: 0, 
          right: 0, 
          bottom: 0, 
          left: 0,
          marginRight: "auto",
          marginLeft: "auto",
          width: "calc(100% - (2 * var(--offset)))",
          maxWidth: "var(--max_width)",
          // minHeight:" 20000px",
          backgroundImage: "var(--background-columns)",  
          backgroundSize: "var(--background-width) 100%",
          zIndex: "1000",
          pointerEvents: "none",
          outline: "var(--offset) solid #ffe3e5c9",
          borderLeft: "var(--gutter) solid transparent",
          borderRight: "var(--gutter) solid transparent",
        }
        // "& *":{
        //   fontFamily: (state?.google_font?.family ? state?.google_font?.family : "") + 
        //   (state?.custom_font?.family ? state?.custom_font?.family : "") + 
        //   ","+ (state.text_font ? state.text_font : DefaultStyleGuide.text_font) +" !important",
        // }
      }

    },    
    DarkSelected:{
      "&$wrapperContainerEmail":{
        backgroundColor: state?.email_color_dark ? state?.email_color_dark : (state?.email_color ? state?.email_color : "#e3e0e0"),
      },
      "& $headingText,& $headingText2,& $headingText3,& $headingText4":{
        color: state?.hedline_color_dark ? state?.hedline_color_dark : (state.hedline_color ? state.hedline_color : DefaultStyleGuide.hedline_color),
      },
      "& $bodyText,& $bodyText2,& $legalText":{
        color: state?.text_color_dark ? state?.text_color_dark : (state.text_color ? state.text_color :  DefaultStyleGuide.text_color),
      },
      "& $link":{
        color: state?.link_color_dark ? state?.link_color_dark : (state.link_color ? state.link_color : "#0B6ADC"),
      },
      "& $button":{
        color: state?.button_text_color_dark ? state?.button_text_color_dark : (state.button_text_color ? state.button_text_color : "#fff"),
        backgroundColor: state?.button_background_color_dark ? state?.button_background_color_dark : (state.button_background_color ? state.button_background_color : "#0B6ADC"),
      },
      "& $twoProduct":{
        background: state?.secondary_color_dark ? state?.secondary_color_dark : (state.secondary_color ? state.secondary_color : "#EBF2FF")
      },
      "& $wrapper":{
        backgroundColor: state?.primary_color_dark ? state?.primary_color_dark : (state.primary_color ? state.primary_color : "#ffffff"),
      }

    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();

  const colorDefaultList = () => {
    if(!props.isDarkActive) return;
    state?.mainColorPallet?.forEach((item:any)=>{
      let list = item.list_default;
      if(list && list.length > 0){
        list.forEach((element:any) => {
         if((state[element+"_dark"] && state[element+"_dark"] !== item?.dark)){
          dispatch(addField(element+"_dark", item?.dark ? item?.dark : item?.value));
         }
        });
      }     
    })
  }

  colorDefaultList();

  return (
  <Box className={`${classes.wrapperContainerEmail}  ${props.isDarkActive ? classes.DarkSelected : ""}`}>
    <Box
      className={`${classes.containerEmail} ${classes.removeEvent} ${props.isLayoutActive ? classes.layoutSelected : ""}` }
    >
      {state.logo && state?.logo?.src_url !== "" ? (
        <Box className={`${classes.wrapper} ${classes.logoImg}`}>
          <img
            onClick={() => {
              if (state.website_url) {
                window.open(state.website_url);
              }
            }}
            style={{ height: "auto", cursor: "pointer" }}
            alt="not available"
            src={state.logo.src}
            width={`${ Math.round(state.c_logo_width / 6.4)}%`}
          />
        </Box>
      ) : (
        <Box className={`${classes.wrapper} ${classes.logoImg}`}>
          <img 
            src={state.defaultUrl ? state.defaultUrl : `${window.globalConfig.apiOrigin}images/gallery/settings/edm-logo.png`}
            width={state.c_logo_width ? `${ Math.round(state.c_logo_width / 6.4)}%` : "24%"}
            alt=""
            style={{ height: "auto", cursor: "pointer" }}
          />
        </Box>
      )}
	  {isAllowMenu() && (
      <Box className={classes.wrapper}>
        <Box display="flex" justifyContent="center">
          {state.menuList.map((item: any) => {
            return (
              <span
                 key={(Math.random() * 100) } 
                className={`${classes.linkButton} ${classes.bodyFamilyText1}`}
                onClick={() => window.open(item.link)}
                // variant="text"
                // size="small"
              >
                {item.title}
              </span>
            );
          })}
        </Box>
      </Box>
       )}
      {/* <Box className={`${classes.visualContainer} ${classes.wrapper}`}>
        <Box className={classes.visualWrapper}>
          <img 
            src={`${window.globalConfig.apiOrigin}images/gallery/common_images/style-guide/visual.png`}
            width="100%" 
            alt=""
        />
       </Box>
      </Box> */}
      {/* <Box className={classes.headerContainer}>
        <Typography className={classes.headerText}>
           Big Strong Headline  &nbsp;
        </Typography>
      </Box> */}
      <Box className={classes.wrapper}>
        <Typography className={`${classes.headingText} ${classes.headingFamilyText1}`}>
           Headline 1 (H1) Lorem ipsum dolor sit
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.wrapper}
      >
        <Typography variant="caption" component="p" className={`${classes.bodyText} ${classes.bodyFamilyText1}`}>
          Body 1 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec lectus nibh. Praesent rhoncus, orci at porta elementum, odio ligula ultrices neque eu tincidunt.
        </Typography>
      </Box>
      {/* <Box className={classes.wrapper}>
        <Button className={classes.button}>
          Call to Action
        </Button>
      </Box> */}
      
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={`${classes.wrapper} ${classes.offerProduct}`}
      >
        <Box className={classes.offerProductWrapper}>
          <Box className={classes.twoProduct}>
            {/* <Box className={classes.rowImage}>
                <img
                    width="100%"
                    alt=""
                    src={`${window.globalConfig.apiOrigin}images/gallery/common_images/style-guide/imgproduct.png`}
                />
            </Box> */}
            <Box className={`${classes.headingText2} ${classes.headingFamilyText2}`}>
              Headline 2 (H2) Lorem ipsum dolor
            </Box>
            <Box className={`${classes.bodyText2} ${classes.bodyFamilyText2}`}>
              Body 2 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec lectus nibh. Praesent rhoncus, orci at porta elementum, odio ligula ultrices
            </Box>
            <Box className={classes.rowButton}>
              <Box className={`${classes.button} ${classes.buttonProduct} ${classes.buttonFont}`}>
                Call to Action
              </Box>
            </Box>
          </Box>        
          <Box className={classes.twoProduct}>
            {/* <Box className={classes.rowImage}>
                <img
                    width="100%"
                    alt=""
                    src={`${window.globalConfig.apiOrigin}images/gallery/common_images/style-guide/imgproduct.png`}
                />
            </Box> */}
            <Box className={`${classes.headingText2} ${classes.headingFamilyText2}`}>
             Headline 2 (H2) Lorem ipsum dolor
            </Box>
            <Box className={`${classes.bodyText2} ${classes.bodyFamilyText2}`}>
              Body 2 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec lectus nibh. Praesent rhoncus, orci at porta elementum, odio ligula ultrices
            </Box>
            <Box className={classes.rowButton}>
              <Box className={`${classes.button} ${classes.buttonProduct} ${classes.buttonFont}`}>
                Call to Action
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.wrapper}>
        <Typography className={`${classes.headingText3} ${classes.headingFamilyText3}`} >
           Headline 3 (H3) Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Typography>
      </Box>
      <Box className={classes.wrapper}>
        <Typography  className={`${classes.headingText4} ${classes.headingFamilyText4}`}>
           Headline 4 (H4) Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Typography>
      </Box>
      <Box className={classes.wrapper}>
        <Box
          display="flex"
          justifyContent="space-between"
          className={classes.footerSocial}
        >
          {state.socialLinkList.map((item: any) => {
            return (
              <span
                onClick={() => window.open(item.link)}
                key={item.id}
              >
                { item.src ? (
                <img src={item.src} width="30" height="auto" alt="" />
                ) : (titleOptions[item.title])}
                
              </span>
            );
          })}
        </Box>      
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.wrapper}
      >
        <Typography
          variant="caption"
          component="p"
          className={`${classes.legalText} ${classes.legalText1} ${classes.bodyFamilyLegal}`}
        >
          {/* {state?.address || state?.store || state?.zip || state?.country
            ? `${state?.address || ""}${
                state?.store ? "-" + state?.store : ""
              }${state?.zip ? "-" + state?.zip : ""}${
                state?.country ? "-" + state?.country : ""
              }`
            : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc lacus  eros, semper"} */}
            If you no longer wish to receive these emails, please update your <span className={classes.link}>preferences</span> or <span className={classes.link}>unsubscribe</span>.
        </Typography>
      </Box>
      
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.wrapper}
      >
        <Typography
          variant="caption"
          component="p"
          className={`${classes.legalText} ${classes.bodyFamilyLegal}`}
        >
         Legal Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec lectus nibh. Praesent rhoncus, orci at porta elementum, odio ligula ultrices neque eu tincidunt.
        </Typography>
      </Box>

      
    </Box>
  </Box>
  );
};

export default memo(StylePrototype);

