import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import LogoSVG, { SignUpSVG } from "../../../../assets/svgs/Svgs";
import { Box, Hidden, IconButton, Slide } from "@material-ui/core";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import NotInterestedIcon from "@material-ui/icons/NotInterested";


const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  svg: {
    display: "flex",
    alignItems: "center",
    background: "#f7f7fc",
  },
  formGrid: {
    display: "flex",
    justifyContent: "center",
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);'
  },
  paper: {
    margin: theme.spacing(8, 4),
    justifyContent: "center",
    maxWidth: 450,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    position: "absolute",
    top: 25,
    left: 30,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  caption: {
    marginBottom: theme.spacing(1),
    fontSize: "16px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  heading: {
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    fontSize: "31px",
  },
  InputLabel: {
    marginTop: ".7rem",
    color: '#11142D',
    marginBottom: ".2rem",
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '120%',
  },
  InputText: {
    marginTop: "0",
    "& .MuiOutlinedInput-root": {
      "& input:-webkit-autofill": {
        transition: 'background-color 5000s ease-in-out 0s',
        WebkitBoxShadow: '0 0 0px 1000px white inset'
      },
    },
  },
  errortextBorder: {
    marginTop: "0",
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
      "& input:-webkit-autofill": {
        transition: 'background-color 5000s ease-in-out 0s',
        WebkitBoxShadow: '0 0 0px 1000px white inset'
      },
    },
  },
  errorContainer: {
    margin: 10,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  errorContainer400: {
    margin: 10,
    display: "flex",
    alignItems: "center",
  },
  errorContainerText400: {
    paddingLeft: "1rem",
  },
  errorIcon: {
    color: "#fff",
    width: '26.67px',
    height: '26.67px',
  },
  errorPaper: {
    marginTop: "-5px",
    marginBottom: "5px",
    width: "100%",
    background: "#EB5757",
    padding: '2px'
  },
  errorText: {
    color: "#fff",
    fontWeight: 500,
    fontFamily: "Montserrat",
    fontSize: "0.90rem",
  },
  link: {
    color: "#FFE8E8",
    textDecoration: "underline",
    cursor: "pointer",
  },
  btn: {
    padding: '8px'
  }
}));

interface Props {
  history: any;
}
const validationSchema = yup.object({
  email: yup.string().required("Email is missing").email(),
  password: yup.string().required("Password is missing"),
  confirm_email: yup
    .string()
    .oneOf([yup.ref("email")], "Confirm email must match email")
    .required("Confirm Email is missing"),
});
const ResendActivation: React.FC<Props> = ({ history }) => {
  if (localStorage.getItem("X-Token")) history.push("/campaign");
  const [error, setError] = useState<any>(null)
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };

  const sequenceError = (errors: {}) => {
    let seqArr: any = []
    let arr = ['email', 'confirm_email', 'password']
    arr.forEach(item => {
      if (Object.keys(errors).includes(item)) {
        seqArr.push(item)
      }
    })
    return seqArr
  }

  const onSubmit = async (data: any, { setSubmitting }: any) => {
    setError(null)
    var axios = require("axios");
    let sendData: any = JSON.stringify({
      usr_password: data.password,
      usr_email: data.email,
      confirm_email: data.confirm_email
    });

    var config = {
      method: "post",
      url: `api/v1/users/resend_activation`,
      headers: {},
      data: sendData,
    };
    setSubmitting(true);
    try {
      await axios(config);
      localStorage.setItem("usr_check_email_id", data.email);
      setTimeout(() => {
        history.push({
          pathname: "/checkmail",
        });
      }, 1000);
      setSubmitting(false);
    } catch (err: any) {
      if (err.response.status === 400) {
        setError(err.response.data.message)
      } else {
        setError("Incorrect email or password")
      }
      setSubmitting(false);
    }
  };
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Hidden smDown>
          <Grid item xs={false} sm={5} md={4} className={classes.svg} style={{
            paddingLeft: "30px",
            paddingRight: "50px",
          }}>
            <SignUpSVG />
          </Grid>
        </Hidden>
        <Box className={classes.logo}>
          <Link to="/">
            <LogoSVG />
          </Link>
        </Box>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          component={Paper}
          className={classes.formGrid}
          elevation={4}
          square
        >
          <div className={classes.paper}>
            <Box>
              <Typography
                component="h1"
                align="left"
                className={classes.heading}
                variant="h5"
              >
                Resend activation email
              </Typography>
              <Typography
                component="p"
                className={classes.caption}
                align="left"
                variant="caption"
              >
                We sent you a message with a link to activate your account. If
                you didn’t receive it, you can re-enter your address or
                try another address, and we’ll send you a new activation link
              </Typography>
            </Box>
            <Formik
              initialValues={{
                email: "",
                confirm_email: "",
                password: "",
              }}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={onSubmit}
            >
              {({ values, isSubmitting, touched, errors, handleChange, handleBlur }) => (
                <Form className={classes.form}>
                  {((Object.keys(errors).length > 0 || error)) && (
                    <Paper className={classes.errorPaper}>
                      <Box className={classes.errorContainer}>
                        <NotInterestedIcon className={classes.errorIcon} />
                        <Box mx={1.5}>
                          {
                            Object.keys(errors).length > 0 ? (sequenceError(errors) as Array<keyof typeof errors>).map((item, i) => {
                              return (
                                <Typography
                                  component="h1"
                                  variant="caption"
                                  className={classes.errorText}
                                  key={item}
                                >
                                  . {errors[item]}
                                </Typography>)
                            }) : (
                              <Typography
                                component="h1"
                                variant="caption"
                                className={classes.errorText}
                              >
                                . {error}
                              </Typography>
                            )
                          }
                        </Box>
                      </Box>
                    </Paper>
                  )}
                  <Typography
                    variant="subtitle2"
                    className={classes.InputLabel}
                  >
                    Email
                  </Typography>
                  <Field
                    name="email"
                    as={TextField}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    className={Boolean(errors.email && touched.email) ? classes.errortextBorder : classes.InputText}
                    placeholder="example@email.com"
                  />
                  <Typography
                    variant="subtitle2"
                    className={classes.InputLabel}
                  >
                    Confirm Email
                  </Typography>
                  <Field
                    name="confirm_email"
                    as={TextField}
                    className={Boolean(errors.confirm_email && touched.confirm_email) ? classes.errortextBorder : classes.InputText}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    placeholder="example@email.com"
                  />
                  <Typography
                    variant="subtitle2"
                    className={classes.InputLabel}
                  >
                    Password
                  </Typography>
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    margin="dense"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={handleClick} edge="end" className={classes.btn}>
                          {showPassword ? (
                            <VisibilityOutlinedIcon style={{ color: "#DADADA" }} />
                          ) : (
                            <VisibilityOffOutlinedIcon style={{ color: "#DADADA" }} />
                          )}
                        </IconButton>
                      ),
                    }}
                    fullWidth
                    name="password"
                    className={Boolean(errors.password && touched.password) ? classes.errortextBorder : classes.InputText}
                    type={showPassword ? "text" : "password"}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isSubmitting}
                    color="primary"
                    className={classes.submit}
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default ResendActivation;
