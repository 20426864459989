import React from "react";
import { useStyles } from "../../styles/styles";
import TreeItem from "@material-ui/lab/TreeItem";
import FolderIcon from "@material-ui/icons/Folder";
import Typography from "@material-ui/core/Typography";
import CheckIcon from "@material-ui/icons/Check";
import Box from "@material-ui/core/Box";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import CreateIcon from "@material-ui/icons/Create";
import {isDesigner, isMarketer, isTrial} from "../../utils/AuthUtility";

interface Props {
	fileTreeData: RenderTree;
	dragOverFolder: any;
	selectedFoldr: any;
	selectFolderFunction: any;
	handleDragStart: any;
	handleDragOver: any;
	handleDrop: any;
	handleDragEnter: any;
	handleDragEnd: any;
	showEdit: any;
	renameFolderID: any;
	handleClickAwayEdit: any;
	renameFolder: any;
	setRenameFolder: any;
	closeEdit: any;
	openEdit: any;
	openMoveFunc: any;
	openDelete: any;
	draggingChildes: any;
	treeName : string;
}
interface RenderTree {
  id: string;
  name?: string;
  type?: string;
  folder_name?: string;
  parent_id?: string;
  company_id?: string;
  state?: {
    opened: boolean;
  };
  childes?: RenderTree[];
  allparents : any;
  allchildes: any;
}


const Tree: React.FC<Props> = ({
	fileTreeData,
	dragOverFolder,
	selectedFoldr,
	selectFolderFunction,
	handleDragStart,
	handleDragOver,
	handleDrop,
	handleDragEnter,
	handleDragEnd,
	showEdit,
	renameFolderID,
	handleClickAwayEdit,
	renameFolder,
	setRenameFolder,
	closeEdit,
	openEdit,
	openMoveFunc,
	openDelete,
	draggingChildes,
	treeName
}) => {
  const classes = useStyles();
	const parseParent = (node: any) => {
		 if(node){
			 if(node.length){
				 return node.join(',');
			 }
		 }
		 return '';
	 };

  const renderTree = (nodes: RenderTree, i: number,) => {

    return (
      <>
        {!nodes ? ("") : (
          <TreeItem
            key={nodes.id}
            nodeId={nodes.id}
            classes={{
             root:  selectedFoldr.includes(String(nodes.id)) ? classes.divRoot1 : classes.divRoot2,
              label: classes.label,
              content: (dragOverFolder === nodes.id) ? classes.content2 : classes.content,
              iconContainer: classes.iconContainer
            }}
            onLabelClick={(event) => {
              event.preventDefault();
            }}
            label={nodes?.type === "company" || nodes?.type === "archive" ? (
              ''
            ) : (
              <Box
                display="flex"
                data-comapnyId={nodes.id}
                id={nodes.id}
                className={selectedFoldr.includes(String(nodes.id)) ? classes.treeViewDiv1 : classes.treeViewDiv2}
                onDragOver={(e) => handleDragOver(e)}
                onDrop={(e) => handleDrop(e, nodes.id)}
                onDragStart={(e) => handleDragStart(e, nodes.id)}
                onDragEnter={(e) => handleDragEnter(e, nodes.id)}
                onDragEnd={(e) => handleDragEnd(e)}
				draggable={((isDesigner() || isMarketer()) && isTrial() === false) ? true : false}
              >
                <Box
                  width="100%"
                  onClick={selectFolderFunction}
                  id={nodes.id}
				  data-imp={Array.isArray(selectedFoldr)?selectedFoldr.join(','):''}
				  data-allparents={parseParent(nodes.allparents)}
				  data-allchildes={parseParent(nodes.allchildes)}
                >
                  <Box display="flex" flexDirection="row" style={{alignItems: 'center'}}>
                    <Box>
                      <FolderIcon
                        className={draggingChildes.includes(nodes.id) ? classes.treeViewFolder2 : classes.treeViewFolder}
                      />
                    </Box>
                    <Box>
                      {showEdit && String(nodes.id) === String(renameFolderID) ? (
                        <ClickAwayListener
                          onClickAway={handleClickAwayEdit}
                        >
                          <Input
                            className={classes.fileEdit}
                            value={renameFolder}
                            autoFocus
                            id={nodes.id}
                            onChange={(e) => {
                              setRenameFolder(e.currentTarget.value);
                            }}
                            endAdornment={
                              <InputAdornment position="end">
                                <CreateIcon onClick={closeEdit} />
                              </InputAdornment>
                            }
                          />
                        </ClickAwayListener>
                      ) : (
                        <Typography
                          variant="body1"
                          className={draggingChildes.includes(nodes.id) ? classes.filetreeTypography2 : classes.filetreeTypography}                          
                          title={nodes.folder_name}
                        >
                          {nodes.folder_name}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box flexShrink={0}>
                  <Box display="flex" flexDirection="row">
                    <Box>
                      <CheckIcon className={classes.tickIcon} />
                    </Box>
					{ (isDesigner() || isMarketer()) &&
                    <Box>
                      <PopupState
                        variant="popover"
                        popupId="demo-popup-menu"
                      >
                        {(popupState: any) => (
                          <React.Fragment>
                            {nodes.id !== null ? (
                              <MoreVertIcon
                                className={classes.moreVertIcon}
                                {...bindTrigger(popupState)}
                              />
                            ) : (
                              ""
                            )}
                            <Menu {...bindMenu(popupState)}
                              getContentAnchorEl={null}
                              anchorOrigin={{
                                vertical: 40,
                                horizontal: -90
                              }}
                              classes={{ paper: classes.paper }}
                            >
                              <div onClick={popupState.close}>
                                <MenuItem
                                  id={nodes.folder_name}
                                  value={nodes.id}
                                  data-id={nodes.parent_id}
                                  data-value={nodes.company_id}
                                  onClick={openEdit}
								  disabled={isTrial()}
                                >
                                  <Typography>Rename</Typography>
                                </MenuItem>
                              </div>
                              <div onClick={popupState.close}>
                                <MenuItem
                                  id={nodes.folder_name}
                                  data-id={nodes.id}
                                  onClick={openMoveFunc}
								  disabled={isTrial()}
                                >
                                  <Typography>Move</Typography>
                                </MenuItem>
                              </div>
                              <div onClick={popupState.close}>
                                <MenuItem
                                  className={classes.deleteItem}
                                  id={nodes.id}
                                  onClick={openDelete}
								  disabled={isTrial()}
                                >
                                  <Typography>Delete</Typography>
                                </MenuItem>
                              </div>
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </Box>
					}
                  </Box>
                </Box>
              </Box>
            )
            }
          >
            {Array.isArray(nodes.childes) ? nodes.childes.map((node, i) => {
              return renderTree(node, i,);
            })
              : ""}
          </TreeItem>
        )}
      </>
    );
  };
  
  return (
		<div>
        {Array.isArray(fileTreeData.childes) ? fileTreeData.childes.map((node, i) => {
			 return renderTree(node, 0);
		})
        : ""}
		</div>
          
  );
};

export default Tree;
